/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateLearningHistoryFieldsInput = {
    ACL?: ACLInput | null;
    targetFieldName?: string | null;
    path?: PathPointerInput | null;
    contentItem?: ContentItemPointerInput | null;
    author?: UserPointerInput | null;
    parentPath?: PathPointerInput | null;
    status?: string | null;
    quiz?: QuizPointerInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type PathPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathFieldsInput | null;
};
export type CreatePathFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    contentItemList?: Array<unknown | null> | null;
    author?: UserPointerInput | null;
    learnCount?: number | null;
    published?: boolean | null;
    description?: string | null;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    analytics?: AnalyticsPathPointerInput | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    isSeries?: boolean | null;
    draft?: PathDraftPointerInput | null;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type InstructionCardRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateInstructionCardFieldsInput> | null;
};
export type CreateInstructionCardFieldsInput = {
    ACL?: ACLInput | null;
    description?: string | null;
    author?: UserPointerInput | null;
    path?: unknown | null;
    sources?: Array<unknown | null> | null;
    seq?: number | null;
    optional?: boolean | null;
    pathDraft?: PathDraftPointerInput | null;
};
export type PathDraftPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathDraftFieldsInput | null;
};
export type CreatePathDraftFieldsInput = {
    ACL?: ACLInput | null;
    original: PathPointerInput;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    description?: string | null;
    isSeries?: boolean | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    progressing?: boolean | null;
};
export type MembershipPointerInput = {
    link?: string | null;
    createAndLink?: CreateMembershipFieldsInput | null;
};
export type CreateMembershipFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    author?: UserPointerInput | null;
    level?: string | null;
    role?: RolePointerInput | null;
};
export type RolePointerInput = {
    link?: string | null;
    createAndLink?: CreateRoleFieldsInput | null;
};
export type CreateRoleFieldsInput = {
    ACL?: ACLInput | null;
    name?: string | null;
    users?: UserRelationInput | null;
    roles?: RoleRelationInput | null;
};
export type UserRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateUserFieldsInput> | null;
};
export type RoleRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateRoleFieldsInput> | null;
};
export type AnalyticsPathPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsPathFieldsInput | null;
};
export type CreateAnalyticsPathFieldsInput = {
    ACL?: ACLInput | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    path?: PathPointerInput | null;
    viewCount?: number | null;
    commentCount?: number | null;
};
export type ContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentItemFieldsInput | null;
};
export type CreateContentItemFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    description?: string | null;
    author?: UserPointerInput | null;
    content?: ContentPointerInput | null;
    noteData?: Array<unknown | null> | null;
    tags?: Array<unknown | null> | null;
    published?: boolean | null;
    md?: string | null;
    analytics?: AnalyticsContentItemPointerInput | null;
};
export type ContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentFieldsInput | null;
};
export type CreateContentFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    thumbURL?: string | null;
    type?: string | null;
    link?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    rawMetadata?: unknown | null;
    analytics?: AnalyticsContentPointerInput | null;
};
export type AnalyticsContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentFieldsInput | null;
};
export type CreateAnalyticsContentFieldsInput = {
    ACL?: ACLInput | null;
    content?: ContentPointerInput | null;
    viewCount?: number | null;
    contentItemCount?: number | null;
    emojiCountMap?: unknown | null;
    contentItemAuthors?: Array<unknown | null> | null;
    emojiTotalCount?: number | null;
    commentCount?: number | null;
};
export type AnalyticsContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentItemFieldsInput | null;
};
export type CreateAnalyticsContentItemFieldsInput = {
    ACL?: ACLInput | null;
    contentItem?: ContentItemPointerInput | null;
    viewCount?: number | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    commentCount?: number | null;
};
export type QuizPointerInput = {
    link?: string | null;
    createAndLink?: CreateQuizFieldsInput | null;
};
export type CreateQuizFieldsInput = {
    ACL?: ACLInput | null;
    author?: UserPointerInput | null;
    title?: string | null;
    options?: Array<unknown | null> | null;
    correctIndexes?: Array<unknown | null> | null;
};
export type learningHistoryStateCreateMutationVariables = {
    fields?: CreateLearningHistoryFieldsInput | null;
    connections: Array<string>;
};
export type learningHistoryStateCreateMutationResponse = {
    readonly createLearningHistory: {
        readonly learningHistory: {
            readonly id: string;
            readonly objectId: string;
            readonly status: string | null;
            readonly path: {
                readonly id: string;
                readonly objectId: string;
            } | null;
            readonly contentItem: {
                readonly id: string;
                readonly objectId: string;
            } | null;
            readonly quiz: {
                readonly id: string;
                readonly objectId: string;
            } | null;
        };
    } | null;
};
export type learningHistoryStateCreateMutation = {
    readonly response: learningHistoryStateCreateMutationResponse;
    readonly variables: learningHistoryStateCreateMutationVariables;
};



/*
mutation learningHistoryStateCreateMutation(
  $fields: CreateLearningHistoryFieldsInput
) {
  createLearningHistory(input: {fields: $fields}) {
    learningHistory {
      id
      objectId
      status
      path {
        id
        objectId
      }
      contentItem {
        id
        objectId
      }
      quiz {
        id
        objectId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fields"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "fields",
        "variableName": "fields"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "LearningHistory",
  "kind": "LinkedField",
  "name": "learningHistory",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Path",
      "kind": "LinkedField",
      "name": "path",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentItem",
      "kind": "LinkedField",
      "name": "contentItem",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Quiz",
      "kind": "LinkedField",
      "name": "quiz",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "learningHistoryStateCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateLearningHistoryPayload",
        "kind": "LinkedField",
        "name": "createLearningHistory",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "learningHistoryStateCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateLearningHistoryPayload",
        "kind": "LinkedField",
        "name": "createLearningHistory",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "learningHistory",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "LearningHistoryEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7057cb8ba35005a0196e6c5c17ffaf8",
    "id": null,
    "metadata": {},
    "name": "learningHistoryStateCreateMutation",
    "operationKind": "mutation",
    "text": "mutation learningHistoryStateCreateMutation(\n  $fields: CreateLearningHistoryFieldsInput\n) {\n  createLearningHistory(input: {fields: $fields}) {\n    learningHistory {\n      id\n      objectId\n      status\n      path {\n        id\n        objectId\n      }\n      contentItem {\n        id\n        objectId\n      }\n      quiz {\n        id\n        objectId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '318f6baf77782fb57832534248483759';
export default node;
