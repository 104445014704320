import { Image, StyleSheet, ViewStyle } from 'react-native';
import React from 'react';
import LFLink from '../../components/LFLink';
import { useHistory } from 'react-router-dom';

const CloseButton: React.FC<{ style?: ViewStyle; onPress?: () => void }> = ({
  style,
  onPress,
}) => {
  const history = useHistory();
  return (
    <LFLink style={style} onPress={onPress ? onPress : () => history.goBack()}>
      <Image
        accessibilityLabel="닫기"
        source={require('../../assets/images/icon24Close@3x.png')}
        style={styles.image}
      />
    </LFLink>
  );
};

export default CloseButton;

const styles = StyleSheet.create({ image: { width: 24, height: 24 } });
