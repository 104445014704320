import { Flex } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import Parse from 'parse';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  ImageURISource,
  ActivityIndicator,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  usePaginationFragment,
} from 'react-relay';
import { useHistory } from 'react-router-dom';
import NavBar, { HistoryBackButton } from '../components/NavBar';
import NotificationItem, {
  NotificationItemProp,
} from '../components/NotificationItem';
import { useAuth } from '../hooks/auth';
import { NotificationPaginationQuery } from '../__generated__/NotificationPaginationQuery.graphql';
import { NotificationScreenMeQuery } from '../__generated__/NotificationScreenMeQuery.graphql';
import {
  NotificationScreenPaginationQuery,
  NotificationScreenPaginationQuery$key,
} from '../__generated__/NotificationScreenPaginationQuery.graphql';
import { NotificationScreenQuery } from '../__generated__/NotificationScreenQuery.graphql';
import { NotificationScreenUserUpdateMutation } from '../__generated__/NotificationScreenUserUpdateMutation.graphql';
import LFLink from '../components/LFLink';
import LFText from '../components/typo/LFText';
import BookMark from '../components/BookMark';
import LFWhiteSpace from '../components/LFWhiteSpace';
import { EMOJIS } from '../components/ActivityStreamItemView';

const NotificationScreen: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2]
  >({
    fetchPolicy: 'network-only',
    fetchKey: 0,
  });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      //@ts-ignore
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'store-and-network',
    }));
  }, [setRefreshedQueryOptions]);

  const { me } = useLazyLoadQuery<NotificationScreenMeQuery>(
    graphql`
      query NotificationScreenMeQuery($userId: ID!) {
        me: user(id: $userId) {
          objectId
          id
          createdAtOfLastSeenNotification
        }
      }
    `,
    {
      userId: user?.objectId,
    },
    refreshedQueryOptions
  );

  const notificationsResult = useLazyLoadQuery<NotificationScreenQuery>(
    graphql`
      query NotificationScreenQuery($count: Int, $after: String) {
        ...NotificationScreenPaginationQuery
      }
    `,
    { count: 20 },
    refreshedQueryOptions
  );

  const notificationPagination = usePaginationFragment<
    NotificationPaginationQuery,
    NotificationScreenPaginationQuery$key
  >(
    graphql`
      fragment NotificationScreenPaginationQuery on Query
      @refetchable(queryName: "NotificationPaginationQuery") {
        notifications(first: $count, after: $after, order: createdAt_DESC)
          @connection(key: "pagination_notifications") {
          edges {
            node {
              objectId
              id
              createdAt
              from {
                name
                objectId
              }
              sources {
                __typename
                ... on Reaction {
                  id
                  objectId
                  targetClassName
                  createdAt
                  type
                  path {
                    objectId
                    title
                  }
                  contentItem {
                    title
                    objectId
                    content {
                      title
                      objectId
                      thumbURL
                    }
                  }
                  author {
                    objectId
                    id

                    name
                  }
                }
                ... on Comment {
                  id
                  objectId
                  targetClassName
                  createdAt
                  text
                  path {
                    id
                    objectId
                    title
                  }
                  contentItem {
                    id
                    objectId
                    title
                    content {
                      id
                      objectId
                      title
                      thumbURL
                    }
                  }
                  author {
                    objectId
                    id

                    name
                  }
                  parent {
                    id
                    objectId
                  }
                }
              }
            }
          }
        }
      }
    `,
    notificationsResult
  );

  const [commitUserUpdate, isCommitUserUpdateInFlight] =
    useMutation<NotificationScreenUserUpdateMutation>(
      graphql`
        mutation NotificationScreenUserUpdateMutation(
          $id: ID!
          $createdAtOfTopNoti: Date
        ) {
          updateUser(
            input: {
              id: $id
              fields: { createdAtOfLastSeenNotification: $createdAtOfTopNoti }
            }
          ) {
            user {
              # objectId
              # id
              createdAtOfLastSeenNotification
            }
          }
        }
      `
    );

  const createdAtOfTopNoti = (_.first(
    notificationPagination?.data?.notifications?.edges
  )?.node?.createdAt || '') as string;

  useEffect(() => {
    // commitUserUpdate({
    //   variables: {
    //     id: user.objectId,
    //     createdAtOfTopNoti: createdAtOfTopNoti,
    //   },
    //   updater: () => {},
    // });
    // relay Store를 업데이트 하지 않고 server쪽으로만 mutation하는 방법을 몰라 우선 Parse.Object로

    if (createdAtOfTopNoti) {
      const userObj = Parse.User.current();
      userObj?.set(
        'createdAtOfLastSeenNotification',
        new Date(createdAtOfTopNoti)
      );
      userObj?.save();
    }
  }, [createdAtOfTopNoti]);

  const notificationNodeList = _.map(
    notificationPagination?.data?.notifications?.edges,
    (e) => e?.node
  );
  const postFixOfReactionNotificationSubtitles: {
    [key in EMOJIS]: string | React.ReactNode;
  } = {
    '👍': '님이 도움돼요 👍 를 보냈습니다.',
    '🌱': '님이 쉬워요 🌱 를 보냈습니다.',
    '👀': '님이 또 볼래요 👀 를 보냈습니다.',
    '😉': '님이 재미있어요 😉 를 보냈습니다.',
    '🤷‍♂️': '님이 어려워요 🤷‍♂️ 를 보냈습니다.',
    bookmark: (
      <Flex align="center">
        <LFText>님이 스크랩</LFText>
        <BookMark style={{ width: 16, height: 16 }} />
        <LFWhiteSpace size="xs" direction="row" />
        <LFText>했습니다</LFText>
      </Flex>
    ),
  };
  return (
    <Flex direction="column" align="stretch" style={styles.container}>
      <NavBar
        title="알림센터"
        left={<HistoryBackButton />}
        right={
          <TouchableOpacity
            onPress={() => {
              refresh();
              // notificationPagination.refetch(
              //   {},
              //   {
              //     fetchPolicy: 'store-and-network',
              //   }
              // );
            }}
          >
            <Ionicons name="reload" size={20} />
          </TouchableOpacity>
        }
      />
      <FlatList
        style={{ flex: 1 }}
        ListEmptyComponent={EmptyComponent}
        data={notificationNodeList}
        // refreshControl={<LFText>HOOOOO</LFText>}
        // refreshing={true}
        // onRefresh={() => {}}
        renderItem={({ item }) => {
          if (item) {
            const source = _.first(item.sources);
            const title =
              source?.__typename === 'Reaction' ||
              source?.__typename === 'Comment'
                ? source.targetClassName === 'Path'
                  ? `러닝패스 > ${source.path?.title}`
                  : `학습노트 > ${source.contentItem?.title}`
                : '';
            return (
              <LFLink
                testID="noticenter-noti:NotificationScreen"
                onPress={() => {
                  source?.__typename === 'Reaction' ||
                  source?.__typename === 'Comment'
                    ? source.targetClassName === 'Path'
                      ? history.push(`/path/${source.path?.objectId}`)
                      : history.push(
                          `/note/${source.contentItem?.content?.objectId}/${source.contentItem?.objectId}`
                        )
                    : null;
                }}
              >
                <NotificationItem
                  title={title}
                  subtitle={
                    <Flex>
                      {source?.__typename === 'Comment' && (
                        <>
                          {'"'}
                          <LFText numberOfLines={1} style={{ flexShrink: 1 }}>
                            {(source?.text || '').slice(0, 10) +
                              ((source?.text || '').length >= 10 ? '...' : '')}
                          </LFText>
                          {'"'}
                          <LFWhiteSpace direction="row" size={4} />
                        </>
                      )}
                      <LFLink
                        style={{
                          paddingVertical: 10,
                          marginVertical: -10,
                          flexShrink: 1,
                        }}
                        onPress={() => {
                          history.push(`/u/${item.from?.objectId}`);
                        }}
                      >
                        <LFText
                          numberOfLines={1}
                          style={{
                            color: '#2F6EE9',
                            flexShrink: 1,
                          }}
                        >
                          {item.from?.name}
                          {/* //TODO: 길어질 경우에 대한 처리는 별도로 나중에 */}
                        </LFText>
                      </LFLink>
                      <LFText style={{ flexShrink: 0 }}>
                        {source?.__typename === 'Reaction'
                          ? postFixOfReactionNotificationSubtitles[
                              (source?.__typename === 'Reaction' &&
                                source.type) as EMOJIS
                            ]
                          : source?.__typename === 'Comment'
                          ? source?.parent?.objectId
                            ? '님이 대댓글을 남겼어요.'
                            : '님이 댓글을 남겼어요.'
                          : null}
                      </LFText>
                    </Flex>
                  }
                  isNew={
                    me.createdAtOfLastSeenNotification
                      ? me.createdAtOfLastSeenNotification < item.createdAt
                      : true
                  }
                  imageURL={
                    item.sources[0]?.targetClassName === 'ContentItem'
                      ? item.sources[0]?.contentItem?.content?.thumbURL
                      : undefined
                  }
                  footer={dayjs(item.createdAt).fromNow()}
                />
              </LFLink>
            );
          } else {
            return <View />;
          }
        }}
        keyExtractor={(item) => item.id}
        onEndReached={() => {
          if (notificationPagination.hasNext) {
            notificationPagination.loadNext(20);
          }
        }}
        ListFooterComponent={() => {
          return notificationPagination?.isLoadingNext ? (
            <Flex align="center" justify="center" style={{ height: 100 }}>
              <ActivityIndicator />
            </Flex>
          ) : null;
        }}
      />
    </Flex>
  );
};

const EmptyComponent = () => (
  <View style={{ paddingTop: 70 }}>
    <LFText style={{ textAlign: 'center' }}>받은 알림 내역이 없습니다.</LFText>
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  item: { padding: 16, backgroundColor: '#FFF' },
  title: {
    fontSize: 16,
    color: '#26282c',
    lineHeight: 20,
    fontWeight: '600',
  },
  message: {
    paddingRight: 4,
    fontSize: 14,
    lineHeight: 18,
    letterSpacing: 0.4,
  },
  thumbnail: { marginLeft: 16, width: 40, height: 40, borderRadius: 4 },
  time: {
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.4,
    color: 'rgb(123,124,127)',
  },
});
export default NotificationScreen;
