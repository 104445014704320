import { Button, Modal, Toast } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ViewStyle,
  Image,
} from 'react-native';
import * as Device from 'expo-device';
import Input from '@ant-design/react-native/lib/input-item/Input';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ReactElement } from 'react';
import LFLink from './LFLink';
import { useLocation } from 'react-router-dom';
import LFText from './typo/LFText';

export interface ShareLinkModalProps {
  url?: string;
  title?: string | ReactElement;
  onClose?: () => void;
  style?: ViewStyle;
}

// alert(navigator.share);

const ShareLinkModalButton: React.FC<ShareLinkModalProps> = ({
  style,
  title,
  url,
  onClose = () => {},
}) => {
  let { pathname } = useLocation();
  url = `${window.origin}${pathname}`;
  // const route = useRoute<any>();
  // console.log(route)
  const [visible, setVisible] = useState(false);
  const onCloseShareModal = () => {
    setVisible(false);
  };

  const onPressFirstShareButton = async () => {
    try {
      await navigator
        .share({
          url,
        })
        .catch(() => {}); //ignore cancel
    } catch (err) {
      setVisible(true);
    }
  };
  return (
    <>
      <LFLink
        testID="share-btn:ShareLinkModalButton"
        style={[{ justifyContent: 'center', alignItems: 'center' }, style]}
        onPress={() => onPressFirstShareButton()}
      >
        <Image
          accessibilityLabel="공유하기"
          style={{ width: 24, height: 24 }}
          source={require('../assets/images/nav/icon24Share@3x.png')}
        />
        {typeof title === 'string' ? (
          <LFText style={styles.shareTitle}>{title}</LFText>
        ) : (
          title
        )}
      </LFLink>
      <Modal
        // title="공유하기"
        transparent
        onClose={onCloseShareModal}
        maskClosable
        visible={visible}
        // closable
        // footer={footerButtons}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <LFText style={styles.shareTitle}>공유 하기</LFText>
          <TouchableOpacity style={{}} onPress={onCloseShareModal}>
            <Ionicons name="close" size={24} />
          </TouchableOpacity>
        </View>
        <View style={{ paddingVertical: 20 }}>
          <Input
            editable={false}
            style={{
              backgroundColor: '#ddd',
              height: 40,
              borderRadius: 5,
              paddingLeft: 10,
            }}
            value={url}
          />
        </View>
        <TouchableOpacity>
          <CopyToClipboard
            text={url}
            onCopy={() => {
              Toast.success({
                content: '클립보드에 복사 되었습니다.',
                mask: false,
                duration: 1,
              });
              onCloseShareModal();
            }}
          >
            <View style={styles.button}>
              <LFText style={styles.buttonText}>링크 복사</LFText>
            </View>
          </CopyToClipboard>
        </TouchableOpacity>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    width: '100%',
    height: 40,
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {},
  shareTitle: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  shareTitle: {
    fontSize: 11,
  },
});

export default ShareLinkModalButton;
