import React from 'react';
import { Flex, ActivityIndicator } from '@ant-design/react-native';
import { StyleSheet, View, TouchableOpacity, SectionList } from 'react-native';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { useActionSheet } from '@expo/react-native-action-sheet';
import _ from 'lodash';
import { LearningDoneListScreenContentItemQuery } from '../__generated__/LearningDoneListScreenContentItemQuery.graphql';
import { LearningDoneListScreenProfileContentItemsPaginationQuery } from '../__generated__/LearningDoneListScreenProfileContentItemsPaginationQuery.graphql';
import { useAuth } from '../hooks/auth';
import NoItemFromProfileMenuView from '../components/NoItemFromProfileMenuView';
import { LearningDoneListScreenContentItemPaginationFragment$key } from '../__generated__/LearningDoneListScreenContentItemPaginationFragment.graphql';
import ListitemContentItem from '../components/ListItemContentItem';
import ListItemPath from '../components/ListItemPath';
import LFLink from '../components/LFLink';
import NavBar2 from '../components/NavBar2';

const LearningDoneListScreen: React.FC<{
  type: 'path' | 'contentItem';
  title: string;
  status: 'done' | 'in_progress';
}> = ({ type, title, status }) => {
  const history = useHistory();
  const { user } = useAuth();
  const { userObjectId: userObjectIdFromURL } =
    useParams<{ userObjectId: string }>();
  const currentUserObjectId = userObjectIdFromURL || user?.objectId;
  const location = useLocation<{ tabIdx: number }>();
  const { tabIdx } = location.state || { tabIdx: 0 };
  const isMe = currentUserObjectId === user?.objectId;
  const { showActionSheetWithOptions } = useActionSheet();

  const contentItemsQueryRef =
    useLazyLoadQuery<LearningDoneListScreenContentItemQuery>(
      graphql`
        query LearningDoneListScreenContentItemQuery(
          $first: Int
          $after: String
          $userObjectId: ID
          $type: String
          $status: String
        ) {
          ...LearningDoneListScreenContentItemPaginationFragment
        }
      `,
      {
        userObjectId: currentUserObjectId,
        first: 10,
        type: type,
        status: status || 'done',
      },
      {
        fetchPolicy: 'store-and-network',
      }
    );

  const contentItemPagination = usePaginationFragment<
    LearningDoneListScreenProfileContentItemsPaginationQuery,
    LearningDoneListScreenContentItemPaginationFragment$key
  >(
    graphql`
      fragment LearningDoneListScreenContentItemPaginationFragment on Query
      @refetchable(
        queryName: "LearningDoneListScreenProfileContentItemsPaginationQuery"
      ) {
        learningHistories(
          first: $first
          after: $after
          where: {
            author: { have: { objectId: { equalTo: $userObjectId } } }
            targetFieldName: { equalTo: $type }
            status: { equalTo: $status }
          }
          order: updatedAt_DESC
        ) @connection(key: "LeaningDoneContentItem_learningHistories") {
          edges {
            node {
              id
              objectId
              contentItem {
                __typename
                id
                objectId
                published
                content {
                  __typename
                  id
                  objectId
                }
                ...ListItemContentItem_Fragment
              }
              path {
                __typename
                id
                objectId
                published
                ...ListItemPath_Fragment
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `,
    contentItemsQueryRef
  );

  const myContentItemSection = contentItemPagination.data?.learningHistories
    .edges?.length
    ? [
        {
          data: _.chunk(
            _.map(
              contentItemPagination.data.learningHistories.edges,
              (e) => e?.node?.contentItem || e?.node.path
            ),
            2
          ),
        },
      ]
    : [];

  const sections = myContentItemSection;

  return (
    <View style={styles.container}>
      <NavBar2 title={title} />
      <SectionList
        contentContainerStyle={{ flexGrow: 1 }}
        ListEmptyComponent={
          <NoItemFromProfileMenuView
            title="완료한 학습노트가 없어요."
            description="흥미있는 노트를 찾아보세요."
            onPress={() =>
              history.push({ pathname: '/', state: { tabIdx: 2 } })
            }
            btnText="학습노트 둘러보기"
          />
        }
        sections={sections}
        keyExtractor={(item) => {
          return _.reduce(
            item,
            (sum, i) => {
              return sum + i?.objectId;
            },
            ''
          );
        }}
        renderItem={(info) => {
          return (
            <Flex
              direction="row"
              align="stretch"
              style={{
                marginHorizontal: -8,
                marginBottom: 16,
                paddingHorizontal: 16,
              }}
            >
              {_.map(_.castArray(info.item), (item) => {
                if (!item?.objectId) {
                  console.log('### invalid :', item, info.index);
                  return <View></View>;
                } else if (item?.__typename === 'ContentItem') {
                  return (
                    <LFLink
                      testID="content-item:LearningDoneListScreen"
                      style={{ width: '50%', padding: 5 }}
                      onPress={() => {
                        console.log(item);
                        history.push(
                          `/note/${item?.content?.objectId}/${item?.objectId}`
                        );
                      }}
                    >
                      <ListitemContentItem contentItemFrgmt={item} />
                    </LFLink>
                  );
                } else if (item.__typename === 'Path') {
                  return (
                    <LFLink
                      testID="learningpath:LearningDoneListScreen"
                      style={{ width: '50%' }}
                      onPress={() => {
                        history.push(`/path/${item.objectId}`);
                      }}
                    >
                      <ListItemPath
                        style={{ margin: 8 }}
                        onPressMore={undefined}
                        pathFrgmt={item}
                      />
                    </LFLink>
                  );
                }
                return <View />;
              })}
            </Flex>
          );
        }}
        onEndReached={() => {
          if (contentItemPagination && contentItemPagination.hasNext) {
            contentItemPagination.loadNext(10);
          }
        }}
        onEndReachedThreshold={0.1}
        ListFooterComponent={() => {
          return (
            <>
              {contentItemPagination?.isLoadingNext && (
                <Flex align="center" justify="center" style={{ height: 100 }}>
                  <ActivityIndicator />
                </Flex>
              )}
              <View style={{ height: 40 }} />
            </>
          );
        }}
      />
    </View>
  );
};

// 컨텐츠 아이템 UI 컴포넌트
export default LearningDoneListScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
});
