/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MembershipPlanAndPricingScreenAuthorQueryVariables = {
    userObjectId: string;
};
export type MembershipPlanAndPricingScreenAuthorQueryResponse = {
    readonly user: {
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
        readonly createdAt: unknown;
        readonly updatedAt: unknown;
        readonly description: string | null;
        readonly originProfileURL: string | null;
        readonly jobTitle: string | null;
        readonly tags: ReadonlyArray<{
            readonly value?: unknown;
        } | null> | null;
        readonly profileImage: {
            readonly url: string;
        } | null;
    };
};
export type MembershipPlanAndPricingScreenAuthorQuery = {
    readonly response: MembershipPlanAndPricingScreenAuthorQueryResponse;
    readonly variables: MembershipPlanAndPricingScreenAuthorQueryVariables;
};



/*
query MembershipPlanAndPricingScreenAuthorQuery(
  $userObjectId: ID!
) {
  user(id: $userObjectId) {
    id
    objectId
    name
    createdAt
    updatedAt
    description
    originProfileURL
    jobTitle
    tags {
      __typename
      ... on Element {
        value
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    profileImage {
      url
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userObjectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userObjectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originProfileURL",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipPlanAndPricingScreenAuthorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MembershipPlanAndPricingScreenAuthorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c809ce34e32f41cb1fb654317898cc8c",
    "id": null,
    "metadata": {},
    "name": "MembershipPlanAndPricingScreenAuthorQuery",
    "operationKind": "query",
    "text": "query MembershipPlanAndPricingScreenAuthorQuery(\n  $userObjectId: ID!\n) {\n  user(id: $userObjectId) {\n    id\n    objectId\n    name\n    createdAt\n    updatedAt\n    description\n    originProfileURL\n    jobTitle\n    tags {\n      __typename\n      ... on Element {\n        value\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    profileImage {\n      url\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e2213339b308fd154730f5a18d4c2dfc';
export default node;
