/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateCommentFieldsInput = {
    ACL?: ACLInput | null;
    text?: string | null;
    parent?: CommentPointerInput | null;
    path?: PathPointerInput | null;
    contentItem?: ContentItemPointerInput | null;
    targetAuthor?: UserPointerInput | null;
    author?: UserPointerInput | null;
    targetClassName?: string | null;
    comments?: CommentRelationInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type CommentPointerInput = {
    link?: string | null;
    createAndLink?: CreateCommentFieldsInput | null;
};
export type PathPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathFieldsInput | null;
};
export type CreatePathFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    contentItemList?: Array<unknown | null> | null;
    author?: UserPointerInput | null;
    learnCount?: number | null;
    published?: boolean | null;
    description?: string | null;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    analytics?: AnalyticsPathPointerInput | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    isSeries?: boolean | null;
    draft?: PathDraftPointerInput | null;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type InstructionCardRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateInstructionCardFieldsInput> | null;
};
export type CreateInstructionCardFieldsInput = {
    ACL?: ACLInput | null;
    description?: string | null;
    author?: UserPointerInput | null;
    path?: unknown | null;
    sources?: Array<unknown | null> | null;
    seq?: number | null;
    optional?: boolean | null;
    pathDraft?: PathDraftPointerInput | null;
};
export type PathDraftPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathDraftFieldsInput | null;
};
export type CreatePathDraftFieldsInput = {
    ACL?: ACLInput | null;
    original: PathPointerInput;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    description?: string | null;
    isSeries?: boolean | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    progressing?: boolean | null;
};
export type MembershipPointerInput = {
    link?: string | null;
    createAndLink?: CreateMembershipFieldsInput | null;
};
export type CreateMembershipFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    author?: UserPointerInput | null;
    level?: string | null;
    role?: RolePointerInput | null;
};
export type RolePointerInput = {
    link?: string | null;
    createAndLink?: CreateRoleFieldsInput | null;
};
export type CreateRoleFieldsInput = {
    ACL?: ACLInput | null;
    name?: string | null;
    users?: UserRelationInput | null;
    roles?: RoleRelationInput | null;
};
export type UserRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateUserFieldsInput> | null;
};
export type RoleRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateRoleFieldsInput> | null;
};
export type AnalyticsPathPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsPathFieldsInput | null;
};
export type CreateAnalyticsPathFieldsInput = {
    ACL?: ACLInput | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    path?: PathPointerInput | null;
    viewCount?: number | null;
    commentCount?: number | null;
};
export type ContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentItemFieldsInput | null;
};
export type CreateContentItemFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    description?: string | null;
    author?: UserPointerInput | null;
    content?: ContentPointerInput | null;
    noteData?: Array<unknown | null> | null;
    tags?: Array<unknown | null> | null;
    published?: boolean | null;
    md?: string | null;
    analytics?: AnalyticsContentItemPointerInput | null;
};
export type ContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentFieldsInput | null;
};
export type CreateContentFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    thumbURL?: string | null;
    type?: string | null;
    link?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    rawMetadata?: unknown | null;
    analytics?: AnalyticsContentPointerInput | null;
};
export type AnalyticsContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentFieldsInput | null;
};
export type CreateAnalyticsContentFieldsInput = {
    ACL?: ACLInput | null;
    content?: ContentPointerInput | null;
    viewCount?: number | null;
    contentItemCount?: number | null;
    emojiCountMap?: unknown | null;
    contentItemAuthors?: Array<unknown | null> | null;
    emojiTotalCount?: number | null;
    commentCount?: number | null;
};
export type AnalyticsContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentItemFieldsInput | null;
};
export type CreateAnalyticsContentItemFieldsInput = {
    ACL?: ACLInput | null;
    contentItem?: ContentItemPointerInput | null;
    viewCount?: number | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    commentCount?: number | null;
};
export type CommentRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateCommentFieldsInput> | null;
};
export type NotePlayerScreenCommentMutationVariables = {
    fields: CreateCommentFieldsInput;
    clientMutationId: string;
};
export type NotePlayerScreenCommentMutationResponse = {
    readonly createComment: {
        readonly clientMutationId: string | null;
        readonly comment: {
            readonly id: string;
            readonly objectId: string;
            readonly text: string | null;
            readonly author: {
                readonly id: string;
                readonly objectId: string;
            } | null;
            readonly contentItem: {
                readonly id: string;
                readonly objectId: string;
            } | null;
            readonly targetClassName: string | null;
        };
    } | null;
};
export type NotePlayerScreenCommentMutation = {
    readonly response: NotePlayerScreenCommentMutationResponse;
    readonly variables: NotePlayerScreenCommentMutationVariables;
};



/*
mutation NotePlayerScreenCommentMutation(
  $fields: CreateCommentFieldsInput!
  $clientMutationId: String!
) {
  createComment(input: {fields: $fields, clientMutationId: $clientMutationId}) {
    clientMutationId
    comment {
      id
      objectId
      text
      author {
        id
        objectId
      }
      contentItem {
        id
        objectId
      }
      targetClassName
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientMutationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fields"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "clientMutationId",
            "variableName": "clientMutationId"
          },
          {
            "kind": "Variable",
            "name": "fields",
            "variableName": "fields"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreateCommentPayload",
    "kind": "LinkedField",
    "name": "createComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "targetClassName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotePlayerScreenCommentMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotePlayerScreenCommentMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "1bccdde3809f5e0cb3bab77162ccb331",
    "id": null,
    "metadata": {},
    "name": "NotePlayerScreenCommentMutation",
    "operationKind": "mutation",
    "text": "mutation NotePlayerScreenCommentMutation(\n  $fields: CreateCommentFieldsInput!\n  $clientMutationId: String!\n) {\n  createComment(input: {fields: $fields, clientMutationId: $clientMutationId}) {\n    clientMutationId\n    comment {\n      id\n      objectId\n      text\n      author {\n        id\n        objectId\n      }\n      contentItem {\n        id\n        objectId\n      }\n      targetClassName\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b34d3c19799bc20f5d30c8cddb088483';
export default node;
