import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { WhiteSpace, Flex } from '@ant-design/react-native';
import React from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
  StyleSheet,
} from 'react-native';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { ListItemContentItem_Fragment$key } from '../__generated__/ListItemContentItem_Fragment.graphql';
import PreviewImage from './PreviewImage';
import LFText from './typo/LFText';
import LFLink from './LFLink';

interface ListitemContentItemProps {
  style?: ViewStyle;
  contentItemFrgmt: ListItemContentItem_Fragment$key;
  onPressMore?: () => void;
}

// 컨텐츠 아이템 UI 컴포넌트
const ListitemContentItem: React.FC<ListitemContentItemProps> = ({
  style,
  contentItemFrgmt,
  onPressMore,
}) => {
  const item = useFragment(
    graphql`
      fragment ListItemContentItem_Fragment on ContentItem {
        id
        objectId
        title
        published
        createdAt
        updatedAt
        author {
          id
          objectId
          name
          username
          email
        }
        content {
          id
          objectId
          thumbURL
          type
        }
      }
    `,
    contentItemFrgmt
  );

  // const { title, author, content, published} = item;
  const isVideo = item?.content?.type === 'VIDEO';
  const isPublished = item?.published;
  return (
    <View key={item?.id} style={[contentItemStyles.container, style]}>
      <View
        style={
          isPublished
            ? contentItemStyles.imageContainer
            : [contentItemStyles.imageContainer, { opacity: 0.35 }]
        }
      >
        <PreviewImage
          source={{ uri: item?.content?.thumbURL || '' }}
          style={contentItemStyles.image}
        />
        {isVideo && (
          <Image
            style={contentItemStyles.playIcon}
            source={require('../assets/images/playIcon.png')}
          />
        )}
      </View>
      <View style={contentItemStyles.subContainer}>
        <LFText
          numberOfLines={3}
          style={
            isPublished
              ? contentItemStyles.title
              : [contentItemStyles.title, { color: '#787878' }]
          }
        >
          {item?.title}
        </LFText>
        <WhiteSpace />
        <LFText numberOfLines={1} style={contentItemStyles.author}>
          {item?.author?.name}
        </LFText>
        {onPressMore && (
          <>
            <WhiteSpace />
            <Flex justify="between">
              <Flex align="center">
                <FontAwesome
                  name={isPublished ? 'unlock' : 'lock'}
                  size={12}
                  color="black"
                />
                <LFText style={{ marginLeft: 5, fontSize: 12 }}>
                  {isPublished ? '공개' : '비공개'}
                </LFText>
              </Flex>

              <LFLink onPress={onPressMore}>
                <Ionicons
                  name="ellipsis-horizontal-sharp"
                  size={12}
                  color="black"
                />
              </LFLink>
            </Flex>
          </>
        )}
      </View>
    </View>
  );
};

export default ListitemContentItem;

const contentItemStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
  },
  subContainer: { flex: 1, padding: 16 },
  imageContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '55%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  imageInPath: { width: '50%', height: 0, paddingBottom: '30%' },
  image: {
    width: '100%',
    height: 0,
    paddingBottom: '55%',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  playIcon: {
    position: 'absolute',
    width: '15%',
    height: 0,
    paddingBottom: '15%',
    left: '2.7%',
    bottom: '5%',
  },
  siderContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '40%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  siderText: { color: 'white', fontSize: 24 },
  tags: { flexDirection: 'row', flexWrap: 'wrap', margin: -2 },
  tagWrapper: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderWidth: 1,
    borderRadius: 13,
    margin: 2,
  },
  tagText: { fontSize: 14, color: '#295ECC' },
  title: { fontSize: 14, fontWeight: 'bold', color: '#000000' },
  author: { marginTop: 'auto', fontSize: 12, color: '#787878' },
  authorImage: { width: 24, height: 24, borderRadius: 12 },
  authorText: { marginLeft: 8, fontSize: 14, color: '#787878' },
  placeHolderContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  placeHolderText: { fontWeight: 'bold', fontSize: 16, color: '#C7C9CD' },
});
