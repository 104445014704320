import { StyleSheet, Text, View, Image } from 'react-native';
import React, { useRef, useState } from 'react';
import LFWhiteSpace from './LFWhiteSpace';
import LFButton from './LFButton';
import OnBoardingCard from './OnBoardingCard';
import DotPagination from './DotPagination';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { colors } from '../constants/styleGuide';
import { useAuth } from '../hooks/auth';
import LFLink from './LFLink';
import LFText from './typo/LFText';
import BottomDrawerModal from './BottomDrawerModal';

const NewNoteOnBoardingModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  noAgainId: string;
}> = ({ visible, onClose, noAgainId }) => {
  const isSlidingRef = useRef(false);
  const slideRef = useRef<Slider>(null);
  const [currentSlidingIdx, setCurrentSlidingIdx] = useState(0);
  const { user: loginUser } = useAuth();

  return (
    <BottomDrawerModal
      visible={visible}
      noAgainId={noAgainId}
      onClose={onClose}
      noAgainButtonTestID="popup3-no-again-button:NewNoteOnBoardingModal"
      closeButtonTestID="popup3-close-button:NewNoteOnBoardingModal"
    >
      <View style={{ marginHorizontal: -24 }}>
        <Slider
          ref={slideRef}
          slidesToShow={1}
          slidesToScroll={1}
          swipeToSlide
          onSwipe={() => (isSlidingRef.current = true)}
          afterChange={(currenSlide) => {
            isSlidingRef.current = false;
            setCurrentSlidingIdx(currenSlide);
          }}
          initialSlide={0}
          infinite={false}
        >
          <OnBoardingCard
            subtitle={'⚡️ 5초 만에 학습노트 만들기 '}
            title={
              <>
                어떤 콘텐츠던{'\n'}
                <LFText style={styles.primary}>URL 복. 붙</LFText> 하면 끝!
              </>
            }
            body={
              <Image
                accessibilityLabel=""
                source={require('../assets/images/onBoarding/new-note-onboarding-1.png')}
                style={{
                  width: '100%',
                  paddingBottom: (323 / 327) * 100 + '%',
                }}
              />
            }
          />
          <OnBoardingCard
            subtitle={'학습노트로 빠르게 🚀 성장하기'}
            title={
              <>
                노트를 남기다 보면{'\n'}
                어느새 <LFText style={styles.primary}>인사이트 완성!</LFText>
              </>
            }
            body={
              <Image
                accessibilityLabel=""
                source={require('../assets/images/onBoarding/new-note-onboarding-2.png')}
                style={{
                  width: '100%',
                  paddingBottom: (323 / 327) * 100 + '%',
                }}
              />
            }
          />
        </Slider>
      </View>
      <LFWhiteSpace size={'lg'} />
      <DotPagination
        style={{ alignSelf: 'center' }}
        total={2}
        active={currentSlidingIdx}
        defaultDotStyle={{
          backgroundColor: colors.PRIMARY_100,
          height: 6,
          width: 6,
          borderRadius: 3,
        }}
        activeDotStyle={{
          width: 20,
        }}
      />
      {currentSlidingIdx > 0 ? (
        <LFLink
          testID="popup3-make-note:NewNoteOnBoardingModal"
          onPress={() => {
            onClose();
          }}
        >
          <LFText style={styles.next}>학습 노트 만들기</LFText>
        </LFLink>
      ) : (
        <LFLink
          onPress={() => {
            slideRef.current?.slickNext();
          }}
        >
          <LFText style={styles.next}>다음</LFText>
        </LFLink>
      )}

      <LFWhiteSpace size={'xxl'} />
    </BottomDrawerModal>
  );
};

export default NewNoteOnBoardingModal;

const styles = StyleSheet.create({
  next: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.PRIMARY_100,
  },
  primary: {
    color: colors.PRIMARY_100,
  },
});
