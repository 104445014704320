import { Flex } from '@ant-design/react-native';
import _ from 'lodash';
import React from 'react';
import {
  Image,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import { graphql, useFragment } from 'react-relay';
import { colors } from '../../constants/styleGuide';
import { resizedImageURL } from '../../helper/resizedImageURL';
import { PathPreviewFragment$key } from '../../__generated__/PathPreviewFragment.graphql';
import LFWhiteSpace from '../LFWhiteSpace';
import LFText from '../typo/LFText';
import UserProfileImage from '../UserProfileImage';
import ContentPreview, { SimplePreview } from './ContentPreview';

const PathPreview: React.FC<{
  pathFrgmt: PathPreviewFragment$key;
  style?: ViewStyle;
  titleStyle?: TextStyle;
}> = ({ pathFrgmt, style, titleStyle }) => {
  const path = useFragment(
    graphql`
      fragment PathPreviewFragment on Path {
        id
        objectId
        title
        coverImage {
          url
        }
        author {
          name
          id
          objectId
          ...UserProfileImageFragment
        }
        instructionCards(order: [seq_ASC, createdAt_ASC]) {
          edges {
            node {
              id
              objectId
              optional
              sources {
                __typename
                ... on ContentItem {
                  content {
                    id
                    ...ContentPreviewFragment
                  }
                }
                ... on Path {
                  id
                  title
                  coverImage {
                    url
                  }
                  author {
                    id
                    name
                  }
                }
                ... on Quiz {
                  id
                  title
                }
              }
            }
          }
        }
      }
    `,
    pathFrgmt
  );

  const previewArray = _.chain(path.instructionCards.edges)
    .filter(
      (e) => !!_.first(e?.node?.sources)?.__typename && !e?.node?.optional
    )
    .map((e) => _.first(e?.node?.sources))
    .slice(0, 3)
    .value();

  return (
    <Flex direction="column" align="stretch" style={[styles.container, style]}>
      <Flex direction="row">
        <UserProfileImage size={16} userFrgmt={path.author} />
        <LFWhiteSpace direction="row" size="xs" />
        <LFText style={styles.authorName}>
          {path.author?.name}의 러닝패스
        </LFText>
      </Flex>
      <LFWhiteSpace />
      <LFText
        accessibilityRole="heading"
        aria-level={3}
        style={[styles.title, titleStyle]}
      >
        {path?.title}
      </LFText>
      <LFWhiteSpace />
      {path.coverImage?.url ? (
        <>
          <Image
            accessibilityLabel=""
            resizeMode="cover"
            style={styles.cover}
            source={{ uri: resizedImageURL(path.coverImage?.url, 1920, 1080) }}
          />
          <LFWhiteSpace size="sm" />
        </>
      ) : null}

      {/* {_.map(contentArray, (content, index) => (
        <ContentPreview
          key={content?.id}
          contentFrgmt={content}
          style={{
            marginTop: index ? 4 : 8, //최초는 margin많음
            backgroundColor: colors.SECONDARY_WHITE,
          }}
          titleStyle={styles.contentTitle}
          subTitleStyle={styles.contentSubTitle}
          hideType={true}
        />
      ))} */}
      {_.map(previewArray, (source, index) => {
        const previewStyles: {
          [key in string]: ViewStyle | TextStyle;
        } = {
          style: {
            marginTop: index ? 4 : 8, //최초는 margin많음
            backgroundColor: colors.SECONDARY_WHITE,
            padding: 8,
            borderRadius: 6,
          },
          titleStyle: styles.contentTitle,
          subTitleStyle: styles.contentSubTitle,
        };
        if (source?.__typename === 'ContentItem' && source.content) {
          const content = source.content;
          return (
            <ContentPreview
              key={content?.id}
              contentFrgmt={content}
              {...previewStyles}
              hideType={true}
            />
          );
        } else if (source?.__typename === 'Quiz') {
          return <SimplePreview title={source.title} {...previewStyles} />;
        } else if (source?.__typename === 'Path') {
          return (
            <SimplePreview
              title={source.title}
              subtitle={source.author?.name || ''}
              thumbStyle={
                source.coverImage?.url ? { width: 128, height: 72 } : null
              }
              thumbURL={resizedImageURL(source.coverImage?.url, 640, 360)}
              {...previewStyles}
            />
          );
        }
      })}
      <Image
        style={{ width: 24, height: 24, alignSelf: 'center' }}
        source={require('../../assets/images/instructionCard/arrow24@3x.png')}
      />
    </Flex>
  );
};

export default PathPreview;

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    backgroundColor: colors.BG_2,
    padding: 8,
  },
  authorName: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  title: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  cover: {
    width: '100%',
    // aspectRatio: 16 / 9,
    paddingBottom: '56.25%',
    borderRadius: 4,
    backgroundColor: '#00000019.8000066',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.BORDER_10,
  },
  contentTitle: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  contentSubTitle: {
    fontSize: 10,
    fontWeight: '300',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
});
