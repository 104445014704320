/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import MyContentItemScreenProfileContentItemsPaginationQuery from "./MyContentItemScreenProfileContentItemsPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type MyContentItemScreenContentItemPaginationFragment = {
    readonly contentItems: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly __typename: string;
                readonly id: string;
                readonly objectId: string;
                readonly published: boolean | null;
                readonly content: {
                    readonly __typename: string;
                    readonly id: string;
                    readonly objectId: string;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"ListItemContentItem_Fragment">;
            } | null;
        } | null> | null;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly hasPreviousPage: boolean;
            readonly startCursor: string | null;
        };
    };
    readonly " $refType": "MyContentItemScreenContentItemPaginationFragment";
};
export type MyContentItemScreenContentItemPaginationFragment$data = MyContentItemScreenContentItemPaginationFragment;
export type MyContentItemScreenContentItemPaginationFragment$key = {
    readonly " $data"?: MyContentItemScreenContentItemPaginationFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MyContentItemScreenContentItemPaginationFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "contentItems"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "userObjectId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": MyContentItemScreenProfileContentItemsPaginationQuery
    }
  },
  "name": "MyContentItemScreenContentItemPaginationFragment",
  "selections": [
    {
      "alias": "contentItems",
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": "updatedAt_DESC"
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "fields": [
                        {
                          "kind": "Variable",
                          "name": "equalTo",
                          "variableName": "userObjectId"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "objectId"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "have"
                }
              ],
              "kind": "ObjectValue",
              "name": "author"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "ContentItemConnection",
      "kind": "LinkedField",
      "name": "__ContentItem_contentItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "published",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Content",
                  "kind": "LinkedField",
                  "name": "content",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ListItemContentItem_Fragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '1386d790c8e31e49c30645550e4d7acb';
export default node;
