import React from 'react';
import {
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';
import { useMobileDimensions } from '../hooks/useMobileDimensions';

const FloatFullView: React.FC<{
  style?: ViewStyle;
}> = ({ style, children }) => {
  const md = useMobileDimensions();
  const win = useWindowDimensions();
  return (
    <View
      //@ts-ignore
      style={[
        {
          position: 'fixed',
          zIndex: 100,
          // backgroundColor: 'red',
          height: md.height,
          width: md.width,
          marginLeft: (win.width - md.width) / 2,
          // marginRight: (win.width - md.width) / 2,
          // borderColor: 'red',
          // borderWidth: 5,
        },
        style,
      ]}
      pointerEvents="box-none"
    >
      {children}
    </View>
  );
};

export default FloatFullView;
