import { StyleSheet, Switch, Text, View } from 'react-native';
import React, { useEffect } from 'react';
import { WhiteSpace, Flex } from '@ant-design/react-native';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import LFText from '../../components/typo/LFText';
import MultilineGrowingTextInput from '../../components/MultilineGrowingTextInput';
import { TextInput, TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import { CheckBox } from '../../components/CheckBox';
import LFLink from '../../components/LFLink';
import PathPreview from '../../components/InstructionCard/PathPreview';
import ContentPreview from '../../components/InstructionCard/ContentPreview';
import { SourcePreviewWrapView } from '../TraversalScreen/SourcePreview';
import { FolderButton, InstructionCardKeys } from '../PathEditorScreen';
import _ from 'lodash';
import { colors } from '../../constants/styleGuide';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { InstructionCardEditorFragment$key } from '../../__generated__/InstructionCardEditorFragment.graphql';
import {
  InstructionCardSourceContentItemQuery,
  InstructionCardSourceContentItemQueryResponse,
} from '../../__generated__/InstructionCardSourceContentItemQuery.graphql';
import {
  InstructionCardSourcePathQuery,
  InstructionCardSourcePathQueryResponse,
} from '../../__generated__/InstructionCardSourcePathQuery.graphql';
import Quiz from './Quiz';

// TODO: card 속성 정의
const InstructionCard: React.FC<InstructionCardProp> = ({
  selectedPathId,
  selectedContentItemId,
  first,
  last,
  prevCardSeq,
  nextCardSeq,
  description,
  optional,
  seq,
  sources,
  onChange,
  onUp,
  onDown,
  onPressRemove,
  onPressMySelector,
  onPressSearchSelector,
  onSelectPath,
  onSelectContentItem,
  onChangeQuiz,
}) => {
  const card = { optional, description, sources, seq };
  const item = _.head(card?.sources);

  const { selectedPath } = useLazyLoadQuery<InstructionCardSourcePathQuery>(
    graphql`
      query InstructionCardSourcePathQuery($id: ID!, $empty: Boolean!) {
        selectedPath: path(id: $id) @skip(if: $empty) {
          id
          __typename
          title
          description
          objectId
          author {
            id
            objectId
            name
          }
          ...PathPreviewFragment
        }
      }
    `,
    { id: selectedPathId || '', empty: !selectedPathId }
  );

  const { selectedContentItem } =
    useLazyLoadQuery<InstructionCardSourceContentItemQuery>(
      graphql`
        query InstructionCardSourceContentItemQuery(
          $id: ID!
          $empty: Boolean!
        ) {
          selectedContentItem: contentItem(id: $id) @skip(if: $empty) {
            id
            objectId
            __typename
            title
            published
            author {
              id
              name
            }
            content {
              __typename
              id
              objectId
              ...ContentPreviewFragment
            }
          }
        }
      `,
      { id: selectedContentItemId || '', empty: !selectedContentItemId }
    );

  // 카드의 내 보관함, 검색하여 입력을 누르면 활성화되는 modal 창에서 러닝패스or학습노트 선택 시
  // selectedPathId, selectedContentItemId가 갱신되는데 해당 useEffect는 그럴때마다 아래 전달된 함수를 호출합니다.

  // 1. UI 업데이트(setInstructinoCard)
  // 2. 저장하기 눌렀을 때 수정할 카드의 정보 수집(setShouldUpdateInstructionCardsRef)
  useEffect(() => {
    if (selectedPathId) {
      _.isFunction(onSelectPath) &&
        onSelectPath({
          cardId: seq,
          selectedPath: selectedPath,
          selectedPathId: selectedPathId,
        });
    }

    if (selectedContentItemId) {
      _.isFunction(onSelectContentItem) &&
        onSelectContentItem({
          cardId: seq,
          selectedContentItem: selectedContentItem,
          selectedContentItemId: selectedContentItemId,
        });
    }
  }, [selectedContentItemId, selectedPathId]);

  return (
    <Flex direction="row" key={card.seq}>
      <Flex
        direction="column"
        align="stretch"
        justify="start"
        style={styles.container}
      >
        <Flex>
          {!(item?.__typename === 'Quiz') && (
            <Flex>
              <Switch
                value={!card.optional}
                onValueChange={(value) => {
                  onChange({
                    value: !value,
                    id: card.seq,
                    field: 'optional',
                  });
                }}
              />
              <LFWhiteSpace direction="row" />
              <LFText>필수 카드</LFText>
            </Flex>
          )}
          <LFLink
            style={{ marginLeft: 'auto' }}
            onPress={() => onPressRemove(card?.seq)}
          >
            <Ionicons name="trash-outline" size={20} color="black" />
          </LFLink>
        </Flex>
        {!(item?.__typename === 'Quiz') ? (
          <Flex direction="row" align="start" style={{ paddingVertical: 5 }}>
            <MultilineGrowingTextInput
              style={styles.contentDescriptionInput}
              placeholder="콘텐츠 설명"
              value={card.description || ''}
              onChangeText={(text) => {
                onChange({
                  value: text,
                  field: 'description',
                  id: seq,
                });
              }}
            />
          </Flex>
        ) : null}
        <TouchableOpacity
          disabled={true}
          onPress={() => {
            // history.push(`/note/${item.content.id}/${item.objectId}`);
          }}
        >
          {item?.__typename === 'Quiz' ? (
            <Quiz
              style={{ marginTop: -20, marginRight: 22 }}
              key={seq}
              title={item.title}
              options={item.options.map((option: { value: string } | string) =>
                _.isObject(option) ? option.value : option
              )}
              correctIndexes={item.correctIndexes.map(
                (option: { value: number } | number) =>
                  _.isObject(option) ? option.value : option
              )}
              onChange={(quiz) => {
                onChangeQuiz({ cardId: seq, quiz });
              }}
            />
          ) : null}
          {item?.__typename === 'Path' ? (
            <>
              <LFText>[종류 : 패스]</LFText>
              <PathPreview pathFrgmt={item} />
            </>
          ) : null}

          {item?.__typename === 'ContentItem' ? (
            <Flex direction="column" align="start">
              <LFText>[종류 : 학습노트]</LFText>
              {item?.content ? (
                <>
                  <ContentPreview
                    contentFrgmt={item?.content}
                    style={{ width: '100%' }}
                  />
                  <LFText>{item.author?.name}님의 노트</LFText>
                </>
              ) : null}
            </Flex>
          ) : null}
          {!item?.__typename ? (
            <SourcePreviewWrapView
              style={{
                paddingVertical: 20,
                // paddingHorizontal: '15%',
                justifyContent: 'space-around',
              }}
            >
              <FolderButton
                testID="mkpath-my-contents:PathEditorScreen"
                title="+ 내 보관함"
                onPress={() => {
                  onPressMySelector(seq);
                }}
              />
              <FolderButton
                testID="mkpath-search-contents:PathEditorScreen"
                title="+ 검색하여 입력"
                onPress={() => {
                  onPressSearchSelector(seq);
                }}
              />
              {/* <FolderButton title="+ URL로 입력" onPress={() => {}} /> */}
            </SourcePreviewWrapView>
          ) : null}
        </TouchableOpacity>
        <WhiteSpace size="xl" />
        <Flex justify="end">
          {!first && (
            <LFLink
              onPress={() => {
                onUp({
                  targetCardSeq: seq as number,
                  prevCardSeq: prevCardSeq as number,
                });
              }}
            >
              <Ionicons name="caret-up-sharp" size={24} color="black" />
            </LFLink>
          )}
          {!last && (
            <LFLink
              onPress={() => {
                onDown({
                  targetCardSeq: seq as number,
                  nextCardSeq: nextCardSeq as number,
                });
              }}
            >
              <Ionicons name="caret-down-sharp" size={24} color="black" />
            </LFLink>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InstructionCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    borderWidth: 1,
    borderColor: 'gray',
    marginVertical: 10,
    borderRadius: 12,
  },
  quizAddButton: {
    width: 24,
    height: 24,
    backgroundColor: colors.BG_4,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentDescriptionInput: {
    flex: 1,
    minHeight: 40,
    fontSize: 16,
    borderWidth: 1,
    borderColor: 'rgb(128, 128, 128)',
    borderRadius: 4,
    padding: 8,
  },
});

interface InstructionCardProp {
  selectedPathId: string | null;
  selectedContentItemId: string | null;
  first: boolean;
  last: boolean;
  prevCardSeq: number | null;
  nextCardSeq: number | null;
  description: string;
  optional: boolean;
  seq: number;
  sources?: any[] | null;
  onChange: ({
    id,
    value,
    field,
  }: {
    id: number | undefined | null;
    value: any;
    field: InstructionCardKeys;
  }) => void;
  onUp: ({
    targetCardSeq,
    prevCardSeq,
  }: {
    targetCardSeq: number;
    prevCardSeq: number;
  }) => void;
  onDown: ({
    targetCardSeq,
    nextCardSeq,
  }: {
    targetCardSeq: number;
    nextCardSeq: number;
  }) => void;
  onPressRemove: (cardSeq?: number | null) => void;
  onPressMySelector: (cardSeq?: number | null) => void;
  onPressSearchSelector: (cardSeq?: number | null) => void;
  onSelectPath?: ({
    selectedPath,
    selectedPathId,
    cardId,
  }: {
    selectedPath: InstructionCardSourcePathQueryResponse;
    selectedPathId?: string | null;
    cardId?: number | null;
  }) => void;
  onSelectContentItem?: ({
    selectedContentItem,
    selectedContentItemId,
    cardId,
  }: {
    selectedContentItem: InstructionCardSourceContentItemQueryResponse;
    selectedContentItemId?: string | null;
    cardId?: number | null;
  }) => void;
  onChangeQuiz: ({
    cardId,
    quiz,
  }: {
    cardId: number;
    quiz: {
      title?: string;
      options?: string[];
      correctIndexes?: number[];
    };
  }) => void;
}
