import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { WhiteSpace } from '@ant-design/react-native';
import LFText from './typo/LFText';

interface NoItemFromProfileMenuViewProps {
  title: string;
  description: string;
  onPress: () => void;
  btnText: string;
  style?: ViewStyle;
}

const NoItemFromProfileMenuView: React.FC<NoItemFromProfileMenuViewProps> = ({
  title,
  description,
  onPress,
  btnText,
  style,
}) => {
  return (
    <View style={[styles.container, style]}>
      <LFText style={styles.bigTxt}>{title}</LFText>
      <WhiteSpace size="lg" />
      <LFText style={styles.smallTxt}>{description}</LFText>
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <TouchableOpacity onPress={onPress} style={styles.seePathBtnStyle}>
        <LFText style={styles.txtInSeePathBtn}>{btnText}</LFText>
      </TouchableOpacity>
    </View>
  );
};

export default NoItemFromProfileMenuView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bigTxt: {
    fontSize: 18,
  },
  smallTxt: {
    fontSize: 14,
    color: '#8E8E8E',
  },
  seePathBtnStyle: {
    alignItems: 'center',
    alignSelf: 'stretch',
    marginHorizontal: 60,
    padding: 10,
    borderRadius: 8,
    backgroundColor: '#FFDB6C',
  },
  txtInSeePathBtn: {
    fontSize: 16,
  },
});
