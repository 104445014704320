import { useEffect } from 'react';
import Parse from 'parse';
import { useAuth } from './auth';
import { ClassNames } from '@emotion/react';

interface ViewCountEffectParams {
  className: 'Path' | 'ContentItem' | 'Content' | string; //편의를 위해
  objectId?: string;
}
export const useViewCountEffect = (
  params: ViewCountEffectParams,
  dep: any[] = []
) => {
  const { sessionToken } = useAuth();
  useEffect(() => {
    if (params.className && params.objectId) {
      Parse.Cloud.run(
        'increaseViewCount',
        {
          targetClassName: params.className,
          targetClassObjectId: params.objectId,
        },
        {
          sessionToken: sessionToken,
        }
      );
    }
  }, [params.className, params.objectId, ...dep]);
};
