import { Image, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import React, { createContext, useContext, useState } from 'react';
import Comment, { CommentControls } from './Comment';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  CommentsQuery,
  CommentsQueryResponse,
  CommentWhereInput,
} from '../../__generated__/CommentsQuery.graphql';
import LFText from '../typo/LFText';
import LFLink from '../LFLink';
import { Flex, Modal } from '@ant-design/react-native';
import { useAuth } from '../../hooks/auth';
import dayjs from 'dayjs';
import LFWhiteSpace from '../LFWhiteSpace';
import { colors } from '../../constants/styleGuide';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useHistory } from 'react-router-dom';
import {
  CreateCommentFieldsInput,
  NotePlayerScreenCommentMutationResponse,
} from '../../__generated__/NotePlayerScreenCommentMutation.graphql';
import {
  NotePlayerScreenUpdateCommentMutationResponse,
  UpdateCommentFieldsInput,
} from '../../__generated__/NotePlayerScreenUpdateCommentMutation.graphql';
import { NotePlayerScreenDeleteCommentMutationResponse } from '../../__generated__/NotePlayerScreenDeleteCommentMutation.graphql';
import _ from 'lodash';
import { TraversalScreenCommentMutationResponse } from '../../__generated__/TraversalScreenCommentMutation.graphql';
import { TraversalScreenUpdateCommentMutationResponse } from '../../__generated__/TraversalScreenUpdateCommentMutation.graphql';
import { TraversalScreenDeleteCommentMutationResponse } from '../../__generated__/TraversalScreenDeleteCommentMutation.graphql';
import { NoteEditorScreenCommentMutationResponse } from '../../__generated__/NoteEditorScreenCommentMutation.graphql';
import { NoteEditorScreenDeleteCommentMutationResponse } from '../../__generated__/NoteEditorScreenDeleteCommentMutation.graphql';
import { NoteEditorScreenUpdateCommentMutationResponse } from '../../__generated__/NoteEditorScreenUpdateCommentMutation.graphql';

const commentsContext = createContext<CommentsQueryResponse | null>(null);

export const useComments = () => useContext(commentsContext);

const Comments = ({
  style,
  where,
  children,
  refreshedQueryOptions,
}: {
  style?: ViewStyle;
  where: CommentWhereInput;
  children?: React.ReactNode;
  refreshedQueryOptions: Parameters<typeof useLazyLoadQuery>[2];
}) => {
  const data = useLazyLoadQuery<CommentsQuery>(
    graphql`
      query CommentsQuery($where: CommentWhereInput!) {
        comments(where: $where, order: [createdAt_DESC]) {
          count
          edges {
            node {
              id
              objectId
              createdAt
              text
              author {
                id
                objectId
              }
              contentItem {
                id
                objectId
              }
              path {
                id
                objectId
              }
              targetClassName
              children: comments {
                count
              }
              ...CommentFragment
            }
          }
        }
      }
    `,
    { where },
    refreshedQueryOptions
  );

  return (
    <commentsContext.Provider value={data}>
      <View style={style}>{children}</View>
    </commentsContext.Provider>
  );
};

export const CommentsLabel = ({
  count = true,
  label,
  right,
  style,
  countStyle,
}: {
  count?: boolean;
  label?: string | React.ReactNode;
  right?: string | React.ReactNode;
  style?: ViewStyle;
  countStyle?: TextStyle;
}) => {
  const data = useComments();
  const sumOfChildrenCount =
    data?.comments?.edges?.reduce((prev, curr) => {
      return prev + (curr?.node?.children.count || 0);
    }, 0) || 0;
  const totalCommentCount = sumOfChildrenCount + (data?.comments?.count || 0);
  return (
    <Flex>
      <LFText style={[styles.commentsLabel, style]}>
        <LFText>{`${label ?? '댓글'}`}</LFText>
        {count && <LFText style={countStyle}>{` ${totalCommentCount}`}</LFText>}
      </LFText>
      <View style={{ marginLeft: 'auto' }}>{right}</View>
    </Flex>
  );
};

export const CommentsList: React.FC<{
  style?: ViewStyle;
  contentAuthorId: string;
  targetClassName: 'path' | 'contentItem';
  onCreate: ({
    fields,
  }: {
    fields: CreateCommentFieldsInput;
  }) => Promise<
    | TraversalScreenCommentMutationResponse
    | NotePlayerScreenCommentMutationResponse
    | NoteEditorScreenCommentMutationResponse
  >;
  onDelete: (
    id: string
  ) => Promise<
    | TraversalScreenDeleteCommentMutationResponse
    | NotePlayerScreenDeleteCommentMutationResponse
    | NoteEditorScreenDeleteCommentMutationResponse
  >;
  onEdit: (
    id: string,
    fields: UpdateCommentFieldsInput
  ) => Promise<
    | TraversalScreenUpdateCommentMutationResponse
    | NotePlayerScreenUpdateCommentMutationResponse
    | NoteEditorScreenUpdateCommentMutationResponse
  >;
  afterMutation?: () => void;
  onFocus?: () => void;
  onPressAvatar?: (userId: string) => void;
}> = ({
  style,
  contentAuthorId,
  targetClassName,
  onCreate,
  onDelete,
  onEdit,
  afterMutation,
  onFocus,
  onPressAvatar,
}) => {
  const data = useComments();
  const { user } = useAuth();
  const [editingComment, setEditingComment] = useState<{
    id: string;
    text: string;
  } | null>(null);
  const validateCommentText = (text: string) => Boolean(text.trim());

  const { showActionSheetWithOptions } = useActionSheet();
  const [newReplyText, setNewReplyText] = useState('');
  const [isReplyWriting, setIsReplyWriting] = useState<string | null>(null);
  return (
    <View style={style}>
      {data?.comments?.edges?.map((e, i, arr) => {
        const last = arr.length === i + 1;
        const commentAuthorId = e?.node?.author?.objectId;
        const isCotentAuthor = commentAuthorId === contentAuthorId;
        const authorBadgeText =
          targetClassName === 'path' ? '러닝패스 제작자' : '학습노트 제작자';
        return (
          <Comment
            key={e?.node?.objectId}
            fragmentRef={e?.node || null}
            style={{ marginBottom: last ? 0 : 16 }}
          >
            <Comment.Avatar
              onPress={onPressAvatar}
              style={{ alignItems: 'center' }}
              additionalText={[
                <LFText style={styles.commentAdditionalLabel}>
                  {dayjs((e?.node?.createdAt || '') as string).fromNow()}
                </LFText>,
                ...(isCotentAuthor ? [<Badge text={authorBadgeText} />] : []),
              ].map((text, idx) => (
                <Flex key={idx} align="center">
                  <LFWhiteSpace size="xs" direction="row" />
                  <View style={styles.dot}></View>
                  <LFWhiteSpace size="xs" direction="row" />
                  {text}
                </Flex>
              ))}
              right={
                user?.objectId === commentAuthorId && (
                  <LFLink
                    onPress={() => {
                      showActionSheetWithOptions(
                        {
                          options: e?.node?.children.count
                            ? ['수정', '취소']
                            : ['수정', '삭제', '취소'],
                          ...(!e?.node?.children.count && {
                            destructiveButtonIndex: 1,
                          }),
                          cancelButtonIndex: e?.node?.children.count ? 2 : 1,
                        },
                        (idx) => {
                          if (idx === 0) {
                            setEditingComment({
                              id: e?.node?.objectId || '',
                              text: e?.node?.text || '',
                            });
                            setIsReplyWriting(null);
                            setNewReplyText('');
                          } else if (idx === 1) {
                            !e?.node?.children.count &&
                              Modal.alert('해당 댓글을 삭제하시겠습니까?', '', [
                                {
                                  text: '취소',
                                  onPress: () => {},
                                  style: 'cancel',
                                },
                                {
                                  text: '삭제',
                                  onPress: () =>
                                    onDelete(e?.node?.objectId || '').then(
                                      afterMutation
                                    ),
                                  style: 'destructive',
                                },
                              ]);
                          }
                        }
                      );
                    }}
                  >
                    <Image
                      accessibilityLabel="댓글 관련 옵션 열기(수정, 삭제)"
                      style={{ width: 24, height: 24 }}
                      source={require('../../assets/images/nav/icon24More@3x.png')}
                    />
                  </LFLink>
                )
              }
            />
            <LFWhiteSpace size="xs" />
            {editingComment?.id === e?.node?.objectId ? (
              <View style={{ paddingLeft: 28, paddingTop: 4 }}>
                <Comment.Form
                  value={editingComment?.text}
                  onChangeText={(text) =>
                    setEditingComment((prev) => ({ id: prev?.id || '', text }))
                  }
                  controls={
                    <CommentControls
                      onPressCancelButton={() => setEditingComment(null)}
                      onPressSubbmitButton={() =>
                        onEdit(editingComment?.id || '', {
                          text: editingComment?.text,
                        }).then(() => {
                          afterMutation && afterMutation();
                          setEditingComment(null);
                        })
                      }
                      submitText="수정하기"
                      disabled={
                        !validateCommentText(editingComment?.text || '')
                      }
                      submitButtonStyle={
                        validateCommentText(editingComment?.text || '')
                          ? {
                              backgroundColor: '#236EFF1A',
                            }
                          : {}
                      }
                      submitButtonTextStyle={
                        validateCommentText(editingComment?.text || '')
                          ? {
                              color: '#236EFF',
                              fontWeight: '600',
                            }
                          : {}
                      }
                    />
                  }
                />
              </View>
            ) : (
              <View style={{ paddingLeft: 28, paddingRight: 24 }}>
                <Comment.Text />
                <LFWhiteSpace size="sm" />
                <Comment.Button
                  text="댓글 달기"
                  onPress={() => setIsReplyWriting(e?.node?.objectId || null)}
                />
              </View>
            )}
            {isReplyWriting === e?.node?.objectId && (
              <>
                <LFWhiteSpace size="sm" direction="column" />
                <Comment.Form
                  onFocus={onFocus}
                  style={{ marginLeft: 28 }}
                  value={newReplyText}
                  placeholder="댓글을 남겨주세요!"
                  onChangeText={setNewReplyText}
                  controls={
                    <CommentControls
                      onPressCancelButton={() => {
                        setIsReplyWriting(null);
                      }}
                      disabled={
                        !validateCommentText(newReplyText)
                        // isCommitCreateCommentInFlight
                      }
                      submitButtonStyle={
                        validateCommentText(newReplyText)
                          ? {
                              backgroundColor: '#236EFF1A',
                            }
                          : {}
                      }
                      onPressSubbmitButton={() => {
                        const targetClassName = _.lowerFirst(
                          e?.node?.targetClassName || ''
                        ) as 'path' | 'contentItem';
                        const contentId = e?.node?.[targetClassName]?.objectId;
                        const fields: CreateCommentFieldsInput = {
                          text: newReplyText.trim(),
                          [targetClassName]: {
                            link: contentId,
                          },
                          targetClassName: e.node?.targetClassName,
                          parent: { link: e.node?.objectId },
                        };
                        onCreate({ fields })
                          .then((res) => {
                            const createdCommentObjectId =
                              res.createComment?.comment.objectId;
                            return onEdit(e.node?.objectId || '', {
                              comments: { add: [createdCommentObjectId || ''] },
                            });
                          })
                          .then((res) => {
                            if (res.updateComment?.comment.objectId) {
                              afterMutation && afterMutation();
                              setIsReplyWriting(null);
                              setNewReplyText('');
                            }
                          });
                      }}
                      submitButtonTextStyle={
                        validateCommentText(newReplyText)
                          ? {
                              color: '#236EFF',
                              fontWeight: '600',
                            }
                          : {}
                      }
                    />
                  }
                />
              </>
            )}
            <Comment.Replies
              style={{ paddingLeft: 28 }}
              onDelete={onDelete}
              onEdit={onEdit}
              afterMutation={afterMutation}
              onClickReplyButton={(id) => setIsReplyWriting(id)}
              onPressAvatar={onPressAvatar}
            />
          </Comment>
        );
      })}
    </View>
  );
};

export default React.memo(Comments);

export function Badge({
  text,
  style,
  textStyle,
}: {
  text: string;
  style?: ViewStyle;
  textStyle?: TextStyle;
}) {
  return (
    <View style={[styles.badge, style]}>
      <LFText style={[styles.badgeText, textStyle]}>{text}</LFText>
    </View>
  );
}

const styles = StyleSheet.create({
  commentsLabel: {
    fontSize: 16,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    color: '#1A1B1E',
  },
  commentAdditionalLabel: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    color: '#1A1B1E4D',
  },
  dot: {
    width: 2,
    height: 2,
    backgroundColor: colors.BORDER_40,
  },
  badge: {
    borderRadius: 4,
    backgroundColor: '#F0F1F6',
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
  badgeText: {
    fontSize: 10,
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: 16,
    color: '#1A1B1E',
  },
});
