import React from 'react';
import { Flex } from '@ant-design/react-native';
import { Image, ImageBackground, StyleSheet, ViewStyle } from 'react-native';
import { PositionItemProps } from './type';
import LFText from '../../components/typo/LFText';
import { colors } from '../../constants/styleGuide';
import LFWhiteSpace from '../../components/LFWhiteSpace';

const PositionItem: React.FC<{
  position: PositionItemProps;
  style?: ViewStyle;
}> = ({ position, style }) => {
  return (
    <Flex key={position.id} style={[styles.container]} direction="column">
      <Image source={position.cover} style={styles.coverImage}></Image>
      <LFWhiteSpace size={2} />
      <LFText style={styles.title}>{position.title}</LFText>
    </Flex>
  );
};

export default PositionItem;
const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    backgroundColor: '#f2f4fb',
    alignItems: 'center',
    paddingVertical: 18,
  },
  coverImage: {
    width: 56,
    height: 56,
  },
  title: {
    fontSize: 17,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_100,
    textShadowColor: 'rgba(58, 69, 99, 0.2)',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 10,
  },
  titleBox: {},
});
