import { StyleSheet, Text, View, ViewStyle, Image } from 'react-native';
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { PromotionItemViewFragment$key } from '../__generated__/PromotionItemViewFragment.graphql';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import LFText from './typo/LFText';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from './LFWhiteSpace';
import { useDeviceType } from '../hooks/deviceType';
import { resizedImageURL } from '../helper/resizedImageURL';

const PromotionItemView: React.FC<{
  promotionFragment: PromotionItemViewFragment$key | null;
  style?: ViewStyle;
}> = ({ promotionFragment, style }) => {
  const promotion = useFragment(
    graphql`
      fragment PromotionItemViewFragment on HomePromotion {
        id
        objectId
        badges {
          ... on Element {
            value
          }
        }
        title
        subtitle
        titleColor
        subtitleColor
        bgColor
        image {
          url
        }
        PCModeImage {
          url
        }
      }
    `,
    promotionFragment
  );
  const promotionInfo = {
    badge: promotion?.badges?.map((badge) => badge?.value)[0] as
      | string
      | undefined,
    title: promotion?.title,
    subTitle: promotion?.subtitle,
    thumbURL: promotion?.image?.url,
    titleColor: promotion?.titleColor,
    subtitleColor: promotion?.subtitleColor,
    bgColor: promotion?.bgColor || colors.PRIMARY_100,
    thumbURLForPC: promotion?.PCModeImage?.url,
  };

  const deviceType = useDeviceType();
  return (
    <View
      style={[
        deviceType === 'MOBILE'
          ? styles.containerForMobile
          : styles.containerForPC,
        { backgroundColor: promotionInfo.bgColor },
        style,
      ]}
    >
      <LFText
        accessibilityRole="heading"
        numberOfLines={2}
        style={
          deviceType === 'MOBILE'
            ? [
                styles.titleForMobile,
                promotionInfo?.titleColor && {
                  color: promotionInfo?.titleColor,
                },
              ]
            : [
                styles.titleForPC,
                promotionInfo?.titleColor && {
                  color: promotionInfo?.titleColor,
                },
              ]
        }
      >
        {promotionInfo.title}
      </LFText>
      <LFWhiteSpace size="sm" direction="column" />
      <LFText
        numberOfLines={1}
        style={[
          styles.subTitle,
          promotionInfo?.subtitleColor
            ? { color: promotionInfo?.subtitleColor }
            : null,
        ]}
      >
        {promotionInfo.subTitle}
      </LFText>
      <LFWhiteSpace size="sm" direction="column" />
      <LFWhiteSpace size={6} direction="column" />
      <Image
        accessibilityLabel=""
        source={
          deviceType === 'MOBILE'
            ? { uri: resizedImageURL(promotionInfo.thumbURL || '', 600, 438) }
            : {
                uri: resizedImageURL(
                  promotionInfo.thumbURLForPC || '',
                  960,
                  540
                ),
              }
        }
        style={
          deviceType === 'MOBILE' ? styles.imageForMobile : styles.imageForPC
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  containerForMobile: {
    padding: 24,
    position: 'relative',
    alignSelf: 'stretch',
    height: 146,
  },
  containerForPC: {
    padding: 24,
    position: 'relative',
    alignSelf: 'stretch',
    height: 180,
  },
  titleForMobile: {
    fontSize: 22,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 26.4,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_WHITE,
    textShadowColor: 'rgba(66, 73, 89, 0.15)',
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 2,
  },
  titleForPC: {
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 40,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_WHITE,
    textShadowColor: '#42495926.4000198',
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 2,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  imageForMobile: {
    width: 200,
    height: 146,
    top: 0,
    right: 0,
    position: 'absolute',
  },
  imageForPC: {
    width: 320,
    height: 180,
    top: 0,
    right: 0,
    position: 'absolute',
  },
});

export default PromotionItemView;
