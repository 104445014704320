/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ActivityStreamItemView_Fragment = {
    readonly id: string;
    readonly objectId: string;
    readonly createdAt: unknown;
    readonly sourceClassName: string | null;
    readonly verb: string | null;
    readonly author: {
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
    } | null;
    readonly sources: ReadonlyArray<({
        readonly __typename: "Path";
        readonly id: string;
        readonly objectId: string;
        readonly createdAt: unknown;
        readonly title: string | null;
        readonly published: boolean | null;
        readonly coverImage: {
            readonly url: string;
        } | null;
        readonly author: {
            readonly name: string | null;
        } | null;
    } | {
        readonly __typename: "User";
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
    } | {
        readonly __typename: "ContentItem";
        readonly id: string;
        readonly objectId: string;
        readonly createdAt: unknown;
        readonly title: string | null;
        readonly published: boolean | null;
        readonly content: {
            readonly thumbURL: string | null;
            readonly id: string;
            readonly objectId: string;
        } | null;
        readonly author: {
            readonly name: string | null;
        } | null;
    } | {
        readonly __typename: "Reaction";
        readonly targetClassName: string | null;
        readonly type: string | null;
        readonly path: {
            readonly id: string;
            readonly objectId: string;
            readonly createdAt: unknown;
            readonly title: string | null;
            readonly published: boolean | null;
            readonly author: {
                readonly id: string;
                readonly name: string | null;
            } | null;
            readonly coverImage: {
                readonly url: string;
            } | null;
        } | null;
        readonly contentItem: {
            readonly id: string;
            readonly objectId: string;
            readonly createdAt: unknown;
            readonly title: string | null;
            readonly published: boolean | null;
            readonly author: {
                readonly id: string;
                readonly name: string | null;
            } | null;
            readonly content: {
                readonly objectId: string;
                readonly thumbURL: string | null;
            } | null;
        } | null;
    } | {
        readonly __typename: "LearningHistory";
        readonly id: string;
        readonly targetFieldName: string | null;
        readonly path: {
            readonly id: string;
            readonly createdAt: unknown;
            readonly objectId: string;
            readonly coverImage: {
                readonly url: string;
            } | null;
            readonly title: string | null;
            readonly author: {
                readonly name: string | null;
            } | null;
            readonly published: boolean | null;
        } | null;
        readonly contentItem: {
            readonly id: string;
            readonly createdAt: unknown;
            readonly objectId: string;
            readonly content: {
                readonly id: string;
                readonly objectId: string;
                readonly thumbURL: string | null;
            } | null;
            readonly author: {
                readonly name: string | null;
            } | null;
            readonly title: string | null;
            readonly published: boolean | null;
        } | null;
    } | {
        readonly __typename: "Comment";
        readonly id: string;
        readonly objectId: string;
        readonly text: string | null;
        readonly targetClassName: string | null;
        readonly createdAt: unknown;
        readonly author: {
            readonly id: string;
            readonly objectId: string;
            readonly name: string | null;
        } | null;
        readonly path: {
            readonly id: string;
            readonly createdAt: unknown;
            readonly objectId: string;
            readonly coverImage: {
                readonly url: string;
            } | null;
            readonly title: string | null;
            readonly author: {
                readonly name: string | null;
            } | null;
            readonly published: boolean | null;
        } | null;
        readonly contentItem: {
            readonly id: string;
            readonly createdAt: unknown;
            readonly objectId: string;
            readonly content: {
                readonly id: string;
                readonly objectId: string;
                readonly thumbURL: string | null;
            } | null;
            readonly author: {
                readonly name: string | null;
            } | null;
            readonly title: string | null;
            readonly published: boolean | null;
        } | null;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }) | null> | null;
    readonly __typename: "ActivityStream";
    readonly " $refType": "ActivityStreamItemView_Fragment";
};
export type ActivityStreamItemView_Fragment$data = ActivityStreamItemView_Fragment;
export type ActivityStreamItemView_Fragment$key = {
    readonly " $data"?: ActivityStreamItemView_Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ActivityStreamItemView_Fragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "coverImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbURL",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetClassName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Path",
  "kind": "LinkedField",
  "name": "path",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    (v1/*: any*/),
    (v9/*: any*/),
    (v7/*: any*/),
    (v10/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentItem",
  "kind": "LinkedField",
  "name": "contentItem",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v3/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    (v10/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityStreamItemView_Fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceClassName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verb",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sources",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "type": "Path",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "User",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Content",
              "kind": "LinkedField",
              "name": "content",
              "plural": false,
              "selections": [
                (v11/*: any*/),
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            (v10/*: any*/)
          ],
          "type": "ContentItem",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Path",
              "kind": "LinkedField",
              "name": "path",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v13/*: any*/),
                (v9/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentItem",
              "kind": "LinkedField",
              "name": "contentItem",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v13/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Content",
                  "kind": "LinkedField",
                  "name": "content",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v11/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Reaction",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "targetFieldName",
              "storageKey": null
            },
            (v14/*: any*/),
            (v15/*: any*/)
          ],
          "type": "LearningHistory",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            },
            (v12/*: any*/),
            (v3/*: any*/),
            (v6/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/)
          ],
          "type": "Comment",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ActivityStream",
  "abstractKey": null
};
})();
(node as any).hash = '9c3a86c51bdcb9ce162f332f58829442';
export default node;
