/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HomeMainTabQueryVariables = {};
export type HomeMainTabQueryResponse = {
    readonly homePromotions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly location: unknown | null;
                readonly " $fragmentRefs": FragmentRefs<"PromotionItemViewFragment">;
            } | null;
        } | null> | null;
    };
};
export type HomeMainTabQuery = {
    readonly response: HomeMainTabQueryResponse;
    readonly variables: HomeMainTabQueryVariables;
};



/*
query HomeMainTabQuery {
  homePromotions(order: order_ASC) {
    edges {
      node {
        id
        objectId
        location
        ...PromotionItemViewFragment
      }
    }
  }
}

fragment PromotionItemViewFragment on HomePromotion {
  id
  objectId
  badges {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  title
  subtitle
  titleColor
  subtitleColor
  bgColor
  image {
    url
  }
  PCModeImage {
    url
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": "order_ASC"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "location",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeMainTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "HomePromotionConnection",
        "kind": "LinkedField",
        "name": "homePromotions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HomePromotionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HomePromotion",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PromotionItemViewFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "homePromotions(order:\"order_ASC\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HomeMainTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "HomePromotionConnection",
        "kind": "LinkedField",
        "name": "homePromotions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HomePromotionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HomePromotion",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "badges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "type": "Element",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subtitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "titleColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subtitleColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bgColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileInfo",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileInfo",
                    "kind": "LinkedField",
                    "name": "PCModeImage",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "homePromotions(order:\"order_ASC\")"
      }
    ]
  },
  "params": {
    "cacheID": "5b08d7b004849b649c075ea3dd9f382e",
    "id": null,
    "metadata": {},
    "name": "HomeMainTabQuery",
    "operationKind": "query",
    "text": "query HomeMainTabQuery {\n  homePromotions(order: order_ASC) {\n    edges {\n      node {\n        id\n        objectId\n        location\n        ...PromotionItemViewFragment\n      }\n    }\n  }\n}\n\nfragment PromotionItemViewFragment on HomePromotion {\n  id\n  objectId\n  badges {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  title\n  subtitle\n  titleColor\n  subtitleColor\n  bgColor\n  image {\n    url\n  }\n  PCModeImage {\n    url\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ca4ed8a8ebbe12a2af4b27f6c20c4a56';
export default node;
