import React, { useState } from 'react';
import { WhiteSpace, Toast } from '@ant-design/react-native';

import { StyleSheet, Text, View, TextInput, ViewStyle } from 'react-native';
import NavBar from '../components/NavBar';
import { HashItem, HashList } from '../components/HashList';
import _ from 'lodash';
import LFLink from '../components/LFLink';
import LFText from '../components/typo/LFText';

interface HashEditorScreenProps {
  style?: ViewStyle;
  defaultHashes?: string[];
  onPressClose: () => void;
  onPressSave: (result: string[]) => void;
}

const HashEditorScreen: React.FC<HashEditorScreenProps> = ({
  style,
  defaultHashes = [],
  onPressClose,
  onPressSave,
}) => {
  const [hashTitle, setHashTitle] = useState<string>('');
  const [hashes, setHashes] = useState<string[]>(defaultHashes);

  // newHash가 입력될 때 세 개의 검사항목이 존재합니다.
  // 1. 중복된 hash인지
  // 2. 현재 hash의 갯수가 10개 이상인지
  // 3. ''가 아닌지
  const onHashAdd = (newHash: string) => {
    const trimmedNewHash = _.trim(newHash);
    if (trimmedNewHash === '') {
      return;
    }
    if (hashes.indexOf(trimmedNewHash) !== -1) {
      Toast.info('중복된 태그입니다.', 2);
      return;
    }
    if (hashes.length >= 10) {
      Toast.info('태그는 최대 10개까지만 입력 가능합니다.', 2);
      return;
    }
    setHashes((h) => _.uniq([...h, newHash]));
    setHashTitle('');
  };
  const onHashRemove = (hash: string) => {
    setHashes((h) => _.without(h, hash));
  };
  return (
    <View style={[styles.container, style]}>
      <NavBar
        left={
          <LFLink onPress={onPressClose}>
            <LFText>취소</LFText>
          </LFLink>
        }
        title="태그 수정"
        right={
          <LFLink
            onPress={() => {
              onPressSave(hashes);
            }}
          >
            <LFText>확인</LFText>
          </LFLink>
        }
      />
      <View style={styles.section}>
        <LFText style={{ color: '#878787' }}>
          관련 #해시태그 입력(최대 10개)
        </LFText>
        <TextInput
          autoFocus
          value={hashTitle}
          onChangeText={setHashTitle}
          style={styles.input}
          onSubmitEditing={() => {
            onHashAdd(hashTitle);
          }}
          blurOnSubmit={false}
        />
        <WhiteSpace size={'lg'} />
        <HashList>
          {_.map(hashes, (h) => (
            <HashItem key={h} title={h} onPressClose={onHashRemove} />
          ))}
        </HashList>
      </View>
    </View>
  );
};

export default HashEditorScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
  },
  section: {
    padding: 17,
  },
  input: {
    height: 40,
    borderBottomWidth: 1,
    borderColor: '#D1D1D1',
  },
  searchResultList: {
    marginTop: 1,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: '#E6E5E5',
    maxHeight: 116,
  },
  searchResultContainer: { paddingVertical: 4 },
  searchResultText: {},
});
