/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CommentFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly text: string | null;
    readonly createdAt: unknown;
    readonly author: {
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
        readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
    } | null;
    readonly path: {
        readonly id: string;
        readonly objectId: string;
        readonly author: {
            readonly id: string;
            readonly objectId: string;
        } | null;
    } | null;
    readonly contentItem: {
        readonly id: string;
        readonly objectId: string;
        readonly author: {
            readonly id: string;
            readonly objectId: string;
        } | null;
    } | null;
    readonly targetClassName: string | null;
    readonly comments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly createdAt: unknown;
                readonly text: string | null;
                readonly author: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"ReplyFragment">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "CommentFragment";
};
export type CommentFragment$data = CommentFragment;
export type CommentFragment$key = {
    readonly " $data"?: CommentFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"CommentFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileImageFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Path",
      "kind": "LinkedField",
      "name": "path",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentItem",
      "kind": "LinkedField",
      "name": "contentItem",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetClassName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            "createdAt_ASC"
          ]
        }
      ],
      "concreteType": "CommentConnection",
      "kind": "LinkedField",
      "name": "comments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CommentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Comment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v3/*: any*/),
                (v2/*: any*/),
                (v4/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReplyFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "comments(order:[\"createdAt_ASC\"])"
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();
(node as any).hash = '96b3bad84f43602c9979cd1129c7a45f';
export default node;
