import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';

const Hr = ({
  color = '#E0E0E0',
  lineWidth = StyleSheet.hairlineWidth,
  style,
}: {
  color?: string;
  lineWidth?: number;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <View
      style={[
        styles.line,
        { backgroundColor: color, height: lineWidth },
        style,
      ]}
    ></View>
  );
};

export default Hr;

const styles = StyleSheet.create({
  line: {
    width: '100%',
  },
});
