/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ItemListForContentContentItemQueryVariables = {
    contentId: string;
};
export type ItemListForContentContentItemQueryResponse = {
    readonly contentItems: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly published: boolean | null;
                readonly title: string | null;
                readonly createdAt: unknown;
                readonly author: {
                    readonly objectId: string;
                    readonly name: string | null;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type ItemListForContentContentItemQuery = {
    readonly response: ItemListForContentContentItemQueryResponse;
    readonly variables: ItemListForContentContentItemQueryVariables;
};



/*
query ItemListForContentContentItemQuery(
  $contentId: ID!
) {
  contentItems(where: {content: {have: {objectId: {equalTo: $contentId}}}}, order: updatedAt_DESC) {
    edges {
      node {
        id
        objectId
        published
        title
        createdAt
        author {
          objectId
          name
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": "updatedAt_DESC"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "equalTo",
                    "variableName": "contentId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "objectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "have"
          }
        ],
        "kind": "ObjectValue",
        "name": "content"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ItemListForContentContentItemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContentItemConnection",
        "kind": "LinkedField",
        "name": "contentItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ItemListForContentContentItemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContentItemConnection",
        "kind": "LinkedField",
        "name": "contentItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0da7e5bb5b6161e593187cbcb9f3ece",
    "id": null,
    "metadata": {},
    "name": "ItemListForContentContentItemQuery",
    "operationKind": "query",
    "text": "query ItemListForContentContentItemQuery(\n  $contentId: ID!\n) {\n  contentItems(where: {content: {have: {objectId: {equalTo: $contentId}}}}, order: updatedAt_DESC) {\n    edges {\n      node {\n        id\n        objectId\n        published\n        title\n        createdAt\n        author {\n          objectId\n          name\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '98541b25ef0a53d9b03dd08e24c4740e';
export default node;
