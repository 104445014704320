import { StyleSheet, View } from 'react-native';
import React, { useCallback, useMemo } from 'react';
import BaseHorizontalPathList from './BaseHorizontalPathList';
import SectionTitle from './SectionTitle';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { AnalyticsPathWhereInput } from '../__generated__/BasePathListQuery.graphql';
import _ from 'lodash';
import { RecommendedContentsRelatedWithPath_Fragment$key } from '../__generated__/RecommendedContentsRelatedWithPath_Fragment.graphql';
import LFWhiteSpace from './LFWhiteSpace';
import PathItemView from './PathItemView';
import { LFBoldText } from './typo/LFText';
import { RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery } from '../__generated__/RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery.graphql';
import { useAuth } from '../hooks/auth';
import { RecommendedContentsRelatedWithPathContainingThisPathQuery } from '../__generated__/RecommendedContentsRelatedWithPathContainingThisPathQuery.graphql';
import { AnalyticsPathOrder } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';

interface IRecommendedContentsRelatedWithPath {
  pathFragment: RecommendedContentsRelatedWithPath_Fragment$key | null;
  isMembershipUser: boolean;
}

// 입력한 러닝패스 fragment key와 관련된 러닝패스를 추천해주는 컴포넌트
const RecommendedContentsRelatedWithPath: React.FC<IRecommendedContentsRelatedWithPath> =
  ({ pathFragment, isMembershipUser }) => {
    const { user } = useAuth();
    const loginUserId: string | undefined = user?.objectId;

    // 러닝패스와 관련된 추천 컨텐츠를 받아오기 전에 러닝패스에 대한 정보를 불러옵니다.
    const path = useFragment(
      graphql`
        fragment RecommendedContentsRelatedWithPath_Fragment on Path {
          id
          objectId
          author {
            id
            objectId
            name
          }
          membership {
            id
            objectId
          }
        }
      `,
      pathFragment
    );
    const pathHasMembership = Boolean(path?.membership?.objectId);

    // 러닝패스 제작자의 다른 러닝패스 보유 수를 파악하기 위한 query입니다.
    const { pathsCreatedByThisAuthor } =
      useLazyLoadQuery<RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery>(
        graphql`
          query RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery(
            $userObjectId: ID!
            $pathObjectId: ID!
            $skip: Boolean!
          ) {
            pathsCreatedByThisAuthor: paths(
              where: {
                author: { have: { objectId: { equalTo: $userObjectId } } }
                published: { equalTo: true }
                objectId: { notEqualTo: $pathObjectId }
              }
            ) @skip(if: $skip) {
              count
              edges {
                node {
                  id
                  objectId
                  membership {
                    id
                    objectId
                  }
                }
              }
            }
          }
        `,
        {
          userObjectId: path?.author?.objectId || '',
          pathObjectId: path?.objectId || '',
          skip: !Boolean(path?.author?.objectId),
        }
      );
    const countOfPathsCreatedByThisAuthor =
      pathsCreatedByThisAuthor?.count ?? 0;
    const countOfFreePathsCreatedByThisAuthor =
      pathsCreatedByThisAuthor?.edges?.filter(
        (edge) => !Boolean(edge?.node?.membership)
      ).length ?? 0;

    // 러닝패스 작성자의 다른 유, 무료 러닝패스를 불러오는 where
    const freeAndMembershipRequiredAnalyticsPathsWhereCreatedByThisAuthor: AnalyticsPathWhereInput =
      {
        path: {
          have: {
            published: {
              equalTo: true,
            },
            objectId: {
              notEqualTo: path?.objectId || '',
            },
            author: {
              have: {
                objectId: {
                  equalTo: path?.author?.objectId || '',
                },
              },
            },
          },
        },
      };

    // 러닝패스 작성자의 다른 무료 러닝패스를 불러오는 where
    const freeAnalyticsPathsWhereCreatedByThisAuthor: AnalyticsPathWhereInput =
      {
        path: {
          have: {
            published: {
              equalTo: true,
            },
            membership: {
              haveNot: {
                objectId: {
                  exists: true,
                },
              },
            },
            objectId: {
              notEqualTo: path?.objectId || '',
            },
            author: {
              have: {
                objectId: {
                  equalTo: path?.author?.objectId || '',
                },
              },
            },
          },
        },
      };

    // 전체 유저의 유, 무료 러닝패스를 불러오는 where
    const freeAndMembershipRequiredAnalyticsPathsWhereCreatedByAllUsers: AnalyticsPathWhereInput =
      {
        path: {
          have: {
            objectId: {
              notEqualTo: path?.objectId || '',
            },
            published: {
              equalTo: true,
            },
            author: {
              haveNot: {
                objectId: {
                  equalTo: loginUserId,
                },
              },
            },
          },
        },
      };

    // 전체 유저의 유료 러닝패스를 불러오는 where
    const membershipRequiredAnalyticsPathsWhereCreatedByAllUsers: AnalyticsPathWhereInput =
      {
        path: {
          have: {
            membership: {
              have: {
                objectId: {
                  exists: true,
                },
              },
            },
            objectId: {
              notEqualTo: path?.objectId || '',
            },
            published: {
              equalTo: true,
            },
            author: {
              haveNot: {
                objectId: {
                  equalTo: loginUserId,
                },
              },
            },
          },
        },
      };

    // 러닝패스의 멤버십 여부, 로그인 유저의 멤버십 가입 여부, 러닝패스 작성자의 러닝패스 갯수 등을 토대로 sectionTitle, pathWhere를 결정합니다.
    const sectionTitleAndPathWhere = useMemo(
      () =>
        !pathHasMembership
          ? countOfPathsCreatedByThisAuthor
            ? {
                title: [
                  <LFBoldText>{path?.author?.name}</LFBoldText>,
                  '님의 다른 러닝패스도 경험해 보세요.',
                ],
                where:
                  freeAndMembershipRequiredAnalyticsPathsWhereCreatedByThisAuthor,
              }
            : {
                title: [<LFBoldText>이런 러닝패스</LFBoldText>, '는 어때요?'],
                where:
                  freeAndMembershipRequiredAnalyticsPathsWhereCreatedByAllUsers,
              }
          : isMembershipUser
          ? {
              title: [<LFBoldText>이런 러닝패스</LFBoldText>, '는 어때요?'],
              where: membershipRequiredAnalyticsPathsWhereCreatedByAllUsers,
            }
          : countOfFreePathsCreatedByThisAuthor
          ? {
              title: [
                <LFBoldText>{path?.author?.name}</LFBoldText>,
                '님의 무료 러닝패스를 경험해 보세요.',
              ],
              where: freeAnalyticsPathsWhereCreatedByThisAuthor,
            }
          : {
              title: [<LFBoldText>이런 러닝패스</LFBoldText>, '는 어때요?'],
              where:
                freeAndMembershipRequiredAnalyticsPathsWhereCreatedByAllUsers,
            },
      [
        pathHasMembership,
        countOfPathsCreatedByThisAuthor,
        isMembershipUser,
        countOfFreePathsCreatedByThisAuthor,
        path?.objectId,
      ]
    );

    // 해당 러닝패스를 포함하는 러닝패스 where 조건을 선언합니다.
    const analyticsPathsWhereContainingThisPath: AnalyticsPathWhereInput =
      useMemo(
        () => ({
          path: {
            have: {
              instructionCards: {
                have: {
                  sources: {
                    equalTo: [
                      {
                        __type: 'Pointer',
                        className: 'Path',
                        objectId: path?.objectId || '',
                      },
                    ],
                  },
                },
              },
              published: {
                equalTo: true,
              },
            },
          },
        }),
        [path?.objectId]
      );

    const { analyticsPathsContainingThisPath } =
      useLazyLoadQuery<RecommendedContentsRelatedWithPathContainingThisPathQuery>(
        graphql`
          query RecommendedContentsRelatedWithPathContainingThisPathQuery(
            $where: AnalyticsPathWhereInput!
          ) {
            analyticsPathsContainingThisPath: analyticsPaths(where: $where) {
              count
            }
          }
        `,
        {
          where: analyticsPathsWhereContainingThisPath,
        }
      );

    const pathItemElement = useCallback(
      (pathItemFragment) => (
        <PathItemView pathFragment={pathItemFragment} skipHistory />
      ),
      []
    );
    const onPressPathItemTo = useCallback(
      (pathObjectId) => `/path/${pathObjectId}`,
      []
    );
    const baseHorizontalPathListStyle = useMemo(() => ({ padding: 15 }), []);
    const baseHorizontalPathItemStyle = useMemo(() => ({ width: '60%' }), []);
    const baseHorizontalPathOrder = useMemo<AnalyticsPathOrder[]>(
      () => ['viewCount_DESC'],
      []
    );
    return (
      <View>
        <SectionTitle
          accessibilityRole="heading"
          accessibilityLevel={2}
          title={sectionTitleAndPathWhere.title}
          style={{ marginLeft: 18 }}
        />
        <BaseHorizontalPathList
          first={5}
          itemElement={pathItemElement}
          gap={24}
          onPressItemTo={onPressPathItemTo}
          style={baseHorizontalPathListStyle}
          itemStyle={baseHorizontalPathItemStyle}
          order={baseHorizontalPathOrder}
          where={sectionTitleAndPathWhere.where}
        />
        {Boolean(analyticsPathsContainingThisPath.count) && (
          <>
            <LFWhiteSpace size="xl" />
            <SectionTitle
              accessibilityRole="heading"
              accessibilityLevel={2}
              title={[
                <LFBoldText>이 러닝패스</LFBoldText>,
                '가 포함된 러닝패스예요.',
              ]}
              style={{ marginLeft: 18 }}
            />
            <BaseHorizontalPathList
              first={5}
              itemElement={pathItemElement}
              gap={24}
              onPressItemTo={onPressPathItemTo}
              style={baseHorizontalPathListStyle}
              itemStyle={baseHorizontalPathItemStyle}
              order={baseHorizontalPathOrder}
              where={analyticsPathsWhereContainingThisPath}
            />
          </>
        )}
      </View>
    );
  };

export default React.memo(RecommendedContentsRelatedWithPath);

const styles = StyleSheet.create({});

// 해당 컴포넌트에서 사용되는 로직 조직도

// 해당 컴포넌트에서 참조하는 러닝패스가 무료인가? => yes: 러닝패스 작성자에게 다른 러닝패스가 있는가 ? (where: 작성자의 무/유료 러닝패스 중 5개 조회수 순으로 보여줌, title: 00님의 다른 러닝패스도 경험해 보세요.) : (where: 전체 무/유료 러닝패스 중 5개 조회수 순으로 보여줌, title: 이런 러닝패스는 어때요?)
// no: 러닝패스가 유료라면 현재 로그인 유저가 그 멤버십에 가입되어있나? => yes: (where: 전체 유료 러닝패스 중 5개 조회수 순으로 보여줌, title: 이런 러닝패스는 어때요?)
// no: 러닝패스 작성자에게 무료 러닝패스가 있는가? => yes: (where: 작성자의 무료 러닝패스 중 5개 조회수 순으로 보여줌, title: 00님의 무료 러닝패스를 경험해 보세요.)
// no: (where: 전체 유, 무료 러닝패스 중 5개 조회수 순으로 보여줌, title: 이런 러닝패스는 어때요?)
