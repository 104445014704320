import { Flex, WingBlank } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import {
  Image,
  SectionList,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { graphql, useLazyLoadQuery } from 'react-relay';
import Hr from '../../components/Hr';
import LFLink from '../../components/LFLink';
import LFText from '../../components/typo/LFText';
import { useAuth } from '../../hooks/auth';
import { ItemListForContentContentItemQuery } from '../../__generated__/ItemListForContentContentItemQuery.graphql';

const ItemListForContent: React.FC<{
  myContentItemSelectorMode?: boolean;
  myContentItemTitle?: string;
  contentId: string;
  currentContentItemId?: string;
  style?: ViewStyle;
  onPress: (contentItemId: string) => void;
}> = ({
  myContentItemSelectorMode,
  myContentItemTitle,
  contentId,
  style,
  currentContentItemId,
  onPress,
}) => {
  const { user } = useAuth();
  const { contentItems } = useLazyLoadQuery<ItemListForContentContentItemQuery>(
    graphql`
      query ItemListForContentContentItemQuery($contentId: ID!) {
        contentItems(
          where: { content: { have: { objectId: { equalTo: $contentId } } } }
          order: updatedAt_DESC
        ) {
          edges {
            node {
              id
              objectId
              published
              title
              createdAt
              author {
                objectId
                name
              }
            }
          }
        }
      }
    `,
    { contentId: contentId },
    {
      fetchPolicy: 'store-and-network',
    }
  );
  const itemGroup = _.groupBy(
    _.map(contentItems.edges, (e) => e?.node),
    (n) =>
      n?.objectId === currentContentItemId
        ? 'current'
        : n?.author?.objectId === user?.objectId
        ? 'my'
        : 'latest'
  );
  const sections = [];
  if (itemGroup.my) {
    sections.push({
      title: myContentItemSelectorMode ? myContentItemTitle : '나의 다른 노트',
      data: itemGroup.my || [],
    });
  }
  if (itemGroup.latest) {
    sections.push({
      title: '다른 사용자의 최신 노트',
      data: itemGroup.latest || [],
    });
  }

  return _.reduce(sections, (sum, s) => sum + s.data.length, 0) ? (
    <SectionList
      style={[{ flex: 1 }, style]}
      sections={sections}
      keyExtractor={(item) => item?.objectId || 'x'}
      renderSectionHeader={({ section }) => {
        return (
          <Flex
            direction="row"
            style={{ padding: 16, backgroundColor: 'white' }}
          >
            <LFText style={{ fontWeight: '500', fontSize: 16 }}>
              <Ionicons name="newspaper-outline" size={16} color="black" />{' '}
              {section.title}
            </LFText>
          </Flex>
        );
      }}
      renderItem={({ item }) => {
        return (
          <LFLink
            testID="another-content-item-of-this-video:ItemListForContent"
            onPress={() => {
              onPress(item?.objectId || '');
            }}
          >
            <Flex
              direction="row"
              justify="start"
              align="center"
              style={[styles.itemWrap]}
            >
              <LFText>
                <LFText>{item?.title}</LFText>
                <LFText style={{ color: '#888' }}>
                  {' '}
                  ({dayjs(item?.createdAt as string | undefined).fromNow()})
                </LFText>
                {item?.author?.objectId !== user?.objectId && (
                  <LFText>
                    {' - '}
                    {item?.author?.name}
                  </LFText>
                )}
              </LFText>
            </Flex>
          </LFLink>
        );
      }}
      ListFooterComponent={() => <View style={{ height: 80 }} />}
    ></SectionList>
  ) : (
    <Flex style={{ flex: 1, padding: 16 }} align="center" justify="center">
      <LFText style={{ textAlign: 'center' }}>
        현재 노트 이외에 이 영상에 대한 공개 노트가 아직 없습니다. {'\n'}
      </LFText>
    </Flex>
  );
};

export default ItemListForContent;

const styles = StyleSheet.create({
  itemWrap: {
    paddingHorizontal: 16,
    height: 40,
  },
  profileWrap: {
    height: 30,
    width: 30,
    borderRadius: 30,
    overflow: 'hidden',
  },
});
