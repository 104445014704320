import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  SectionList,
  TouchableOpacity,
  FlatList,
} from 'react-native';
import { useLazyLoadQuery, graphql } from 'react-relay';
import { EmojiReactionListScreenReactionQuery } from '../__generated__/EmojiReactionListScreenReactionQuery.graphql';
import { useAuth } from '../hooks/auth';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { Flex, Modal, WhiteSpace } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import NoItemFromProfileMenuView from '../components/NoItemFromProfileMenuView';
import EmojiStampSelectorScreen from './EmojiStampSelectorScreen';
import { debug } from 'react-native-reanimated';
import ListItem404 from './ListItem404';
import ListitemContentItem from '../components/ListItemContentItem';
import ListItemPath from '../components/ListItemPath';
import LFLink from '../components/LFLink';
import NavBar2 from '../components/NavBar2';
import LFText from '../components/typo/LFText';
import { useMobileDimensions } from '../hooks/useMobileDimensions';
import BookMark from '../components/BookMark';
import LFWhiteSpace from '../components/LFWhiteSpace';

export type EmojiStampItem = {
  type: '👍' | '🌱' | '🤷‍♂️' | '👀' | '😉' | 'bookmark';
  display: string | React.ReactNode;
};
const EmojiReactionListScreen = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { width } = useMobileDimensions();
  const [isEmojiStampSelectorVisible, setIsEmojiStampSelectorVisible] =
    useState(false);
  const [emojiTypeIndex, setEmojiTypeIndex] = useState(0);
  const emojiTypes: EmojiStampItem[] = [
    {
      type: 'bookmark',
      display: (
        <Flex align="center">
          <BookMark style={{ marginLeft: -2 }} />
          <LFWhiteSpace direction="row" size={6} />
          <LFText>스크랩</LFText>
        </Flex>
      ),
    },
    { type: '👍', display: '👍  도움돼요' },
    { type: '🌱', display: '🌱  쉬워요' },
    { type: '🤷‍♂️', display: '🤷‍♂️  어려워요' },
    { type: '👀', display: '👀  또 볼래요' },
    { type: '😉', display: '😉  재미있어요' },
  ];
  const currentUserObjectId = user?.objectId;
  const { reactions } = useLazyLoadQuery<EmojiReactionListScreenReactionQuery>(
    graphql`
      query EmojiReactionListScreenReactionQuery(
        $userObjectId: ID!
        $type: String!
      ) {
        reactions(
          where: {
            author: { have: { objectId: { equalTo: $userObjectId } } }
            type: { equalTo: $type }
          }
          order: createdAt_DESC
        ) {
          edges {
            node {
              id
              objectId
              path {
                objectId
                __typename
                ...ListItemPath_Fragment
              }
              contentItem {
                objectId
                __typename
                content {
                  objectId
                }
                ...ListItemContentItem_Fragment
              }
            }
          }
        }
      }
    `,
    {
      userObjectId: currentUserObjectId,
      type: emojiTypes[emojiTypeIndex].type,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const data = _.map(reactions.edges, (e) => {
    // 값이 null, undefined 일 경우 renderItem이 호출되지 않으므로 {} 를 넣어줘야 함
    return e?.node?.contentItem || e?.node?.path || {};
  });

  return (
    <View style={styles.container}>
      <NavBar2 title="내 보관함" right={<></>} />
      <FlatList
        ListHeaderComponent={
          <View style={{ padding: 8 }}>
            <TouchableOpacity
              style={{
                alignSelf: 'flex-start',
                backgroundColor: '#FFF',
                padding: 8,
                borderRadius: 8,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              onPress={() => setIsEmojiStampSelectorVisible(true)}
            >
              <LFText style={{ marginLeft: 8 }}>
                {emojiTypes[emojiTypeIndex].display}
              </LFText>
              <Ionicons
                style={{ marginRight: 8 }}
                name="chevron-down"
                size={24}
                color="black"
              />
            </TouchableOpacity>
          </View>
        }
        ListEmptyComponent={
          <NoItemFromProfileMenuView
            title="학습 후 이모지를 남겨보세요."
            description="이모지별로 여기서 볼 수 있어요."
            onPress={() => {
              history.push({ pathname: '/notes' });
            }}
            btnText="학습노트 둘러보기"
          />
        }
        keyExtractor={(item, idx) => {
          return item?.objectId || idx.toString();
        }}
        contentContainerStyle={{ flexGrow: 1, padding: 8 }}
        data={data}
        numColumns={2}
        renderItem={({ item }) => {
          if (item) {
            if (item.__typename === 'Path') {
              return (
                <LFLink
                  testID="learningpath:EmojiReactionListScreen"
                  style={{ width: '50%' }}
                  onPress={() => {
                    history.push(`/path/${item.objectId}`);
                  }}
                >
                  <ListItemPath
                    style={{ margin: 8 }}
                    onPressMore={undefined}
                    pathFrgmt={item}
                  />
                </LFLink>
              );
            } else if (item.__typename === 'ContentItem') {
              return (
                <LFLink
                  testID="content-item:EmojiReactionListScreen"
                  style={{ width: '50%' }}
                  onPress={() => {
                    history.push(
                      `/note/${item?.content.objectId}/${item?.objectId}`
                    );
                  }}
                >
                  <ListitemContentItem
                    style={{ margin: 8 }}
                    contentItemFrgmt={item}
                    onPressMore={undefined}
                  />
                </LFLink>
              );
            }
          }
          return (
            <View style={{ width: '50%', padding: 8, minHeight: 150 }}>
              <ListItem404 />
            </View>
          );
        }}
      />
      <Modal
        popup
        visible={isEmojiStampSelectorVisible}
        animationType="slide-up"
        style={[styles.subScreenModal, { width }]}
      >
        <EmojiStampSelectorScreen
          defaultValue={emojiTypeIndex}
          emojiTypes={emojiTypes}
          onPressClose={() => {
            setIsEmojiStampSelectorVisible(false);
          }}
          onPressSave={(data: number) => {
            setIsEmojiStampSelectorVisible(false);
            setEmojiTypeIndex(data);
          }}
        />
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: { fontWeight: 'bold', fontSize: 16, lineHeight: 26, color: '#262626' },
  subScreenModal: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    overflow: 'hidden',
    margin: 'auto',
    marginBottom: 0,
    // @ts-ignore
    position: 'sticky',
    bottom: 0,
  },
});

export default EmojiReactionListScreen;
