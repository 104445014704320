import { StyleSheet } from 'react-native';
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { MembershipPaymentItemViewFragment$key } from '../../__generated__/MembershipPaymentItemViewFragment.graphql';
import { Flex } from '@ant-design/react-native';
import LFText from '../../components/typo/LFText';
import _ from 'lodash';
import dayjs from 'dayjs';

const MembershipPaymentItemView: React.FC<{
  membershipPaymentFrgmt: MembershipPaymentItemViewFragment$key;
}> = ({ membershipPaymentFrgmt }) => {
  const payment = useFragment(
    graphql`
      fragment MembershipPaymentItemViewFragment on MembershipPayment {
        id
        objectId
        paymentResult
        status
        membershipPricing {
          id
          objectId
          type
          price
          duration
        }
        membershipStatus {
          id
          membership {
            id
            title
          }
          expiredDate
          status
        }
      }
    `,
    membershipPaymentFrgmt
  );
  const paymentResult = payment.paymentResult as {
    easyPay?: string;
    approvedAt: string;
    method: string;
    card?: {
      company: string;
    };
  };
  return (
    <Flex direction="row" style={styles.container}>
      {_.map(
        [
          `${payment.membershipStatus?.membership?.title} ${
            payment.membershipPricing?.duration + '일'
          }`,
          `${dayjs(paymentResult?.approvedAt).format('YY.MM.DD')} ~ ${dayjs(
            payment.membershipStatus?.expiredDate as string
          ).format('YY.MM.DD')}`,
          `${payment.membershipPricing?.price?.toLocaleString('ko-KR')}원${
            paymentResult?.easyPay
              ? paymentResult?.easyPay
              : paymentResult.card
              ? `${paymentResult.card?.company}(${paymentResult?.method})`
              : '기타'
          }`,
          `${dayjs(paymentResult?.approvedAt).format('L LT')}`,
          `${payment.membershipStatus?.status === 'valid' ? '이용중' : '만료'}`,
        ],
        (title, idx, arr) => {
          return (
            <Flex
              align="center"
              justify="center"
              style={{ flex: arr.length === idx + 1 ? 0.5 : 1 }}
            >
              <LFText style={styles.text}>{title}</LFText>
            </Flex>
          );
        }
      )}
    </Flex>
  );
};

export const MembershipPaymentItemHeaderView: React.FC = () => {
  return (
    <Flex direction="row" style={styles.headerContainer}>
      {_.map(
        ['멤버십', '이용기간', '금액', '결제일', '상태'],
        (title, idx, arr) => {
          return (
            <Flex
              align="center"
              justify="center"
              style={{ flex: arr.length === idx + 1 ? 0.5 : 1 }}
            >
              <LFText style={styles.headerTitle}>{title}</LFText>
            </Flex>
          );
        }
      )}
    </Flex>
  );
};

export default MembershipPaymentItemView;

const styles = StyleSheet.create({
  container: {
    padding: 8,
    borderColor: 'gray',
    borderBottomWidth: 1,
  },
  headerTitle: {
    fontWeight: 'bold',
  },
  text: {},
  headerContainer: {
    borderColor: 'gray',
    borderBottomWidth: 1,
    padding: 6,
  },
});
