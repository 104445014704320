/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ContentItemAuthorSummaryQueryVariables = {
    contentObjectId: string;
    skip: boolean;
    contentItemObjectId: string;
};
export type ContentItemAuthorSummaryQueryResponse = {
    readonly contentItems?: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly author: {
                    readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type ContentItemAuthorSummaryQuery = {
    readonly response: ContentItemAuthorSummaryQueryResponse;
    readonly variables: ContentItemAuthorSummaryQueryVariables;
};



/*
query ContentItemAuthorSummaryQuery(
  $contentObjectId: ID!
  $skip: Boolean!
  $contentItemObjectId: ID!
) {
  contentItems(order: updatedAt_DESC, first: 3, where: {content: {have: {objectId: {equalTo: $contentObjectId}}}, objectId: {notEqualTo: $contentItemObjectId}}) @skip(if: $skip) {
    count
    edges {
      node {
        author {
          ...UserProfileImageFragment
          id
        }
        id
      }
    }
  }
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentItemObjectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentObjectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 3
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "updatedAt_DESC"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "equalTo",
                    "variableName": "contentObjectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "objectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "have"
          }
        ],
        "kind": "ObjectValue",
        "name": "content"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "notEqualTo",
            "variableName": "contentItemObjectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "objectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContentItemAuthorSummaryQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ContentItemConnection",
            "kind": "LinkedField",
            "name": "contentItems",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserProfileImageFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContentItemAuthorSummaryQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ContentItemConnection",
            "kind": "LinkedField",
            "name": "contentItems",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "originProfileURL",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FileInfo",
                            "kind": "LinkedField",
                            "name": "profileImage",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "d5c1f4a2d67d8aeeae7807632d8e6806",
    "id": null,
    "metadata": {},
    "name": "ContentItemAuthorSummaryQuery",
    "operationKind": "query",
    "text": "query ContentItemAuthorSummaryQuery(\n  $contentObjectId: ID!\n  $skip: Boolean!\n  $contentItemObjectId: ID!\n) {\n  contentItems(order: updatedAt_DESC, first: 3, where: {content: {have: {objectId: {equalTo: $contentObjectId}}}, objectId: {notEqualTo: $contentItemObjectId}}) @skip(if: $skip) {\n    count\n    edges {\n      node {\n        author {\n          ...UserProfileImageFragment\n          id\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = '4c4f80c8b240f6964f208991963b1c15';
export default node;
