import _, { reject } from 'lodash';
import { graphql, useMutation } from 'react-relay';
import { learningHistoryStateCreateMutation } from '../__generated__/learningHistoryStateCreateMutation.graphql';
import { learningHistoryStateUpdateMutation } from '../__generated__/learningHistoryStateUpdateMutation.graphql';

interface LearningHistoryParams {
  targetFieldName: 'path' | 'contentItem';
  targetClassObjectId?: string;
  // targetObjectAuthorId?: string;
}

type LearningStatus = 'done' | 'in_progress';

// export const useLearningHistoryState = (params: LearningHistoryParams) => {
//   const { user } = useAuth();
//   const [isUpdating, setIsUpdating] = useState(false);

//   const where: LearningHistoryWhereInput = user
//     ? {
//         author: { have: { objectId: { equalTo: user.id } } },
//         targetFieldName: {
//           equalTo: params.targetFieldName,
//         },
//         OR: [
//           {
//             [params.targetFieldName]: {
//               have: {
//                 objectId: {
//                   equalTo: params.targetClassObjectId,
//                 },
//               },
//             },
//           },
//         ],
//       }
//     : {};

//   const { learningHistories } = useLazyLoadQuery<learningHistoryStateQuery>(
//     graphql`
//       query learningHistoryStateQuery(
//         $where: LearningHistoryWhereInput
//         $skip: Boolean!
//       ) {
//         learningHistories(where: $where, first: 1) @skip(if: $skip) {
//           __id
//           edges {
//             node {
//               id
//               objectId
//               status
//               path {
//                 id
//                 objectId
//               }
//               contentItem {
//                 id
//                 objectId
//               }
//             }
//           }
//         }
//       }
//     `,
//     {
//       where,
//       skip: !user,
//     },
//     {
//       fetchPolicy: 'network-only',
//     }
//   );

//   // console.log(learningHistories.__id);
//   const lHistory = _.first(learningHistories?.edges)?.node;
//   const updateLearningHistory = (status: LearningStatus) => {
//     setIsUpdating(true);
//   };

//   // const [] = useMutation(graphql`
//   //   mutation learningHistoryUpdateMutation(
//   //     $fields: UpdateLearningHistoryInput
//   //   ) {
//   //     updateLearningHistory(input: { fields: $fields, id: $id }) {
//   //       learningHistory {
//   //         id
//   //         objectId
//   //       }
//   //     }
//   //   }
//   // `);

//   const destroyLearningHistory = () => {};
//   return [
//     lHistory,
//     {
//       updateLearningHistory,
//       destroyLearningHistory,
//     },
//   ] as const;
// };

export const useLearningHistoryMutation = ({
  pathId,
  contentItemId,
  quizId,
  learningHistoryId,
  parentPathId,
  connections,
}: {
  pathId?: string | null;
  quizId?: string | null;
  contentItemId?: string | null;
  parentPathId?: string | null;
  learningHistoryId?: string | null;
  connections?: string[];
}) => {
  const [commitUpdate, isUpdateInflight] =
    useMutation<learningHistoryStateUpdateMutation>(graphql`
      mutation learningHistoryStateUpdateMutation(
        $fields: UpdateLearningHistoryFieldsInput
        $id: ID!
      ) {
        updateLearningHistory(input: { fields: $fields, id: $id }) {
          learningHistory {
            id
            objectId
            status
            createdAt
            updatedAt
            quiz {
              id
            }
            path {
              id
            }
            contentItem {
              id
            }
            targetFieldName
            author {
              id
              objectId
            }
          }
        }
      }
    `);

  const [commitCreate, isCreateInflight] =
    useMutation<learningHistoryStateCreateMutation>(graphql`
      mutation learningHistoryStateCreateMutation(
        $fields: CreateLearningHistoryFieldsInput
        $connections: [ID!]!
      ) {
        createLearningHistory(input: { fields: $fields }) {
          learningHistory
            @appendNode(
              connections: $connections
              edgeTypeName: "LearningHistoryEdge"
            ) {
            id
            objectId
            status
            path {
              id
              objectId
            }
            contentItem {
              id
              objectId
            }
            quiz {
              id
              objectId
            }
          }
        }
      }
    `);

  const mutation = (status: LearningStatus) => {
    return new Promise((resolve, reject) => {
      const link = pathId
        ? {
            path: {
              link: pathId,
            },
          }
        : parentPathId && contentItemId
        ? {
            contentItem: {
              link: contentItemId,
            },
            parentPath: {
              link: parentPathId,
            },
          }
        : contentItemId
        ? {
            contentItem: {
              link: contentItemId,
            },
          }
        : quizId
        ? {
            quiz: {
              link: quizId,
            },
          }
        : {};

      learningHistoryId
        ? commitUpdate({
            variables: {
              id: learningHistoryId,
              fields: {
                status: status,
                ...link,
              },
            },
            onCompleted: resolve,
            onError: reject,
          })
        : commitCreate({
            variables: {
              fields: {
                status: status,
                ...link,
              },
              connections: connections,
            },
            onCompleted: resolve,
            onError: reject,
          });
    });
  };

  const isInFlight = learningHistoryId ? isUpdateInflight : isCreateInflight;
  return [mutation, isInFlight] as const;
};
