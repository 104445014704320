/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MembershipPaymentSuccessScreenAuthorQueryVariables = {
    userObjectId: string;
    paymentId: string;
};
export type MembershipPaymentSuccessScreenAuthorQueryResponse = {
    readonly user: {
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
    };
    readonly membershipPayment: {
        readonly id: string;
        readonly objectId: string;
        readonly membershipStatus: {
            readonly id: string;
            readonly objectId: string;
            readonly expiredDate: unknown | null;
        } | null;
        readonly membershipPricing: {
            readonly id: string;
            readonly price: number | null;
            readonly duration: number | null;
            readonly membership: {
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly author: {
                    readonly id: string;
                    readonly objectId: string;
                    readonly name: string | null;
                } | null;
            } | null;
        } | null;
    };
};
export type MembershipPaymentSuccessScreenAuthorQuery = {
    readonly response: MembershipPaymentSuccessScreenAuthorQueryResponse;
    readonly variables: MembershipPaymentSuccessScreenAuthorQueryVariables;
};



/*
query MembershipPaymentSuccessScreenAuthorQuery(
  $userObjectId: ID!
  $paymentId: ID!
) {
  user(id: $userObjectId) {
    id
    objectId
    name
  }
  membershipPayment(id: $paymentId) {
    id
    objectId
    membershipStatus {
      id
      objectId
      expiredDate
    }
    membershipPricing {
      id
      price
      duration
      membership {
        id
        objectId
        title
        author {
          id
          objectId
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userObjectId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "userObjectId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "paymentId"
      }
    ],
    "concreteType": "MembershipPayment",
    "kind": "LinkedField",
    "name": "membershipPayment",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MembershipStatus",
        "kind": "LinkedField",
        "name": "membershipStatus",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "expiredDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MembershipPricing",
        "kind": "LinkedField",
        "name": "membershipPricing",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "duration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Membership",
            "kind": "LinkedField",
            "name": "membership",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipPaymentSuccessScreenAuthorQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MembershipPaymentSuccessScreenAuthorQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "8f17c62707dbe0d0f8c75e01585e31db",
    "id": null,
    "metadata": {},
    "name": "MembershipPaymentSuccessScreenAuthorQuery",
    "operationKind": "query",
    "text": "query MembershipPaymentSuccessScreenAuthorQuery(\n  $userObjectId: ID!\n  $paymentId: ID!\n) {\n  user(id: $userObjectId) {\n    id\n    objectId\n    name\n  }\n  membershipPayment(id: $paymentId) {\n    id\n    objectId\n    membershipStatus {\n      id\n      objectId\n      expiredDate\n    }\n    membershipPricing {\n      id\n      price\n      duration\n      membership {\n        id\n        objectId\n        title\n        author {\n          id\n          objectId\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'aed0bb797490e67f73c911983ea5f822';
export default node;
