import { Button, Flex, WhiteSpace } from '@ant-design/react-native';
import React, { useEffect } from 'react';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Image, StyleSheet, View } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import Constants from 'expo-constants';
import { AfterAction } from './NotePlayer/NotePlayerScreen';
import { useBrowserTitleUpdateEffect } from '../hooks/browserTitle';
import LFText, { LFBoldText } from '../components/typo/LFText';
import TopRoundFlex from '../components/TopRoundFlex';
import { colors } from '../constants/styleGuide';
import LFLink from '../components/LFLink';
import LFWhiteSpace from '../components/LFWhiteSpace';
import dayjs from 'dayjs';
import CloseButton from './LoginScreen/CloseButton';
import { useLocalStorageState } from 'ahooks';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import LFMetaTags from '../components/LFMetaTags';
import { Location } from 'history';
import { subscribeStibeeEmail } from '../helper/subscribeStibeeEmail';

export function isGoogleLoginResponse(
  response: GoogleLoginResponse | GoogleLoginResponseOffline
): response is GoogleLoginResponse {
  return (response as GoogleLoginResponse).googleId !== undefined;
}
interface LoginScreenProps {
  message?: string;
  forceLogout?: boolean;
}

export type LoginScreenAccessAction =
  | 'viewMoreThanTwo'
  | 'clickReactionButton'
  | 'clickHomePopUpModal'
  | 'clickHomeLoginButton'
  | 'clickCompleteButtonOnNote'
  | 'submitURLToCreateNote'
  | 'clickMembershipPriceButton'
  | 'submitTimeStampOnNote'
  | 'clickBookMarkButton'
  | 'tryWritingComment'
  | 'default';

const LOGIN_SCREEN_ACCESS_ACTION_AND_SUBTITLES: {
  [key in LoginScreenAccessAction]: React.ReactNode | string;
} = {
  viewMoreThanTwo: [
    '로그인하고\n',
    <LFBoldText>더 많은 인사이트</LFBoldText>,
    '를 얻어보세요 😉',
  ],
  clickReactionButton: [
    '앗! 창작자에게 ',
    <LFBoldText>이모지를 남기려면</LFBoldText>,
    '\n',
    '로그인이 필요해요!',
  ],
  clickHomePopUpModal: [
    '로그인하고 ',
    <LFBoldText>랜선사수의</LFBoldText>,
    '\n',
    <LFBoldText>학습노트</LFBoldText>,
    '를 무료로 열람하세요 😉',
  ],
  clickHomeLoginButton: [
    '로그인하고 ',
    <LFBoldText>1000+개의</LFBoldText>,
    '\n',
    <LFBoldText>알짜 콘텐츠</LFBoldText>,
    '를 확인하세요 😉',
  ],
  clickCompleteButtonOnNote: [
    '노트를 모두 보셨군요!\n',
    '로그인하면 ',
    <LFBoldText>학습기록을 보관</LFBoldText>,
    '할 수 있어요!',
  ],
  submitURLToCreateNote: [
    '지금 로그인하고\n',
    <LFBoldText>나만의 노트</LFBoldText>,
    '를 만들어보세요!',
  ],
  clickMembershipPriceButton: [
    <LFBoldText>멤버십을 구매</LFBoldText>,
    '하고,\n',
    <LFBoldText>큐레이터와 소통</LFBoldText>,
    '하려면 로그인이 필요해요!',
  ],
  submitTimeStampOnNote: [
    '같은 영상으로 ',
    <LFBoldText>나만의 노트</LFBoldText>,
    '를 만들려면\n',
    '로그인이 필요해요!',
  ],
  clickBookMarkButton: [
    '로그인하고 랜선사수의\n',
    <LFBoldText>콘텐츠를 스크랩</LFBoldText>,
    '하세요 😉',
  ],
  tryWritingComment: [
    '지금 바로 ',
    <LFBoldText>로그인</LFBoldText>,
    '하고',
    '\n',
    <LFBoldText>댓글</LFBoldText>,
    '로 ',
    <LFBoldText>소통</LFBoldText>,
    '해 보세요.😉',
  ],
  default: [
    '로그인하고 ',
    <LFBoldText>1000+개의</LFBoldText>,
    '\n',
    <LFBoldText>알짜 콘텐츠</LFBoldText>,
    '를 확인하세요 😉',
  ],
};
const LoginScreen: React.FC<LoginScreenProps> = ({
  message = '로그인 후 계속 이용하 실 수 있습니다.',
  forceLogout = false,
}) => {
  useBrowserTitleUpdateEffect('로그인');
  const { user, loginWithGoogle, logout } = useAuth();
  const history = useHistory();
  const location = useLocation<{
    afterAction?: AfterAction;
    accessAction: LoginScreenAccessAction;
    from?: Location;
  }>();
  const afterAction = location?.state?.afterAction as AfterAction;
  const accessAction = location.state?.accessAction as LoginScreenAccessAction;

  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    if (forceLogout) {
      logout();
    }
  }, [forceLogout]);
  // @ts-ignore
  const from = location?.state?.from;

  const responseGoogle = (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if (!isGoogleLoginResponse(res)) return;
    const {
      googleId,
      tokenId,
      profileObj: { email, name, imageUrl },
    } = res;
    if (res.googleId && res.tokenId) {
      loginWithGoogle({
        googleId,
        tokenId,
        email,
        name,
        originProfileURL: imageUrl,
      }).then((res) => {
        // 30초 이내에 생성된 계정일 경우 GTM에 데이터 전송
        const secondsBetweenNowAndAccountCreated = dayjs(Date.now()).diff(
          res.createdAt,
          'second'
        );

        // 로그인 성공 후, 방금 회원 가입한 유저의 경우 아래 action 실행
        // 1. Google Tag Manager에 event 전송
        if (secondsBetweenNowAndAccountCreated <= 30) {
          sendDataToGTM({ event: 'customSignUp' });
        }

        if (res.jobTitle) {
          history.replace(from || { pathname: '/' }, {
            afterAction,
          });
        } else {
          history.push('/position-selector', {
            from: from || { pathname: '/' },
          });
        }
      });
    }
  };
  const clientId = Constants.manifest?.extra?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
  return (
    <TopRoundFlex style={styles.container} direction="column" align="center">
      <LFMetaTags
        title="LearnFit | 로그인"
        description="지금 로그인하고, 일잘러들의 학습레시피를 확인하세요."
      />
      <CloseButton style={styles.closeButton} />
      <View
        style={{ paddingTop: 72, alignSelf: 'stretch', paddingHorizontal: 16 }}
      >
        <LFText style={styles.title}>
          {[
            '나만의\n',
            '학습 레시핏,🍱\n',
            <LFText style={{ color: colors.PRIMARY_100 }}>런핏!</LFText>,
          ]}
        </LFText>
        <LFWhiteSpace direction="column" size="lg" />
        <LFText style={styles.subTitle}>
          {LOGIN_SCREEN_ACCESS_ACTION_AND_SUBTITLES[accessAction ?? 'default']}
        </LFText>
      </View>
      <View
        style={{
          marginTop: 'auto',
          marginBottom: 40,
          alignSelf: 'stretch',
          alignItems: 'center',
        }}
      >
        <GoogleLogin
          render={({ onClick }) => (
            <LFLink onPress={onClick} style={styles.buttonContainer}>
              <Image
                accessibilityLabel="구글 아이콘"
                source={require('../assets/images/icon24GoogleSymbol@3x.png')}
                style={{ width: 24, height: 24 }}
              />
              <LFWhiteSpace size="xs" direction="row" />
              <LFText style={styles.buttonText}>Google 로 회원가입</LFText>
            </LFLink>
          )}
          clientId={clientId}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
        />
        <LFWhiteSpace direction="column" size="xl" />
        <LFText style={styles.guideText}>
          이미 런핏 회원이신가요?{' '}
          <GoogleLogin
            render={({ onClick }) => (
              <LFLink onPress={onClick}>
                <LFText
                  style={{
                    textDecorationLine: 'underline',
                    color: colors.PRIMARY_100,
                  }}
                >
                  로그인
                </LFText>
              </LFLink>
            )}
            clientId={clientId}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
        </LFText>
        <LFWhiteSpace direction="column" size="xl" />
        <LFText style={styles.warningText}>
          {[
            '로그인은 ',
            <LFText
              style={{ textDecorationLine: 'underline' }}
              onPress={() => {
                window.location.href =
                  'https://laivdata.atlassian.net/wiki/external/225574993/MzdhMWU1OWJjZWJhNDJiNjkyMmQ2OTQ1ODkwMzhkYWE?atlOrigin=eyJpIjoiYTkyZDdlZmY1NjU5NDc4Mjk3Zjc4ZGE4ZGJmYTVjNzQiLCJwIjoiYyJ9';
              }}
            >
              개인 정보 보호 정책
            </LFText>,
            ' 및 ',
            <LFText
              onPress={() =>
                (window.location.href =
                  'https://laivdata.atlassian.net/wiki/external/225738764/MDBjZTJhYzlmZmJlNDc3YmExMzA1N2NiMjA4OGM5Y2I?atlOrigin=eyJpIjoiNjY1MmJiZGFmNTAyNGI2NTkyM2E3MjY2Mjg4YjE0NWMiLCJwIjoiYyJ9')
              }
              style={{ textDecorationLine: 'underline' }}
            >
              서비스 약관
            </LFText>,
            `에 동의하는 것을 의미하며,\n서비스 이용을 위해 이메일과 이름, 프로필 이미지를 수집합니다.`,
          ]}
        </LFText>
      </View>
    </TopRoundFlex>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    position: 'relative',
    zIndex: 1,
    backgroundColor: colors.SECONDARY_WHITE,
  },
  closeButton: { alignSelf: 'flex-end', padding: 8, margin: -8 },
  title: {
    fontSize: 40,
    fontWeight: '800',
    fontStyle: 'normal',
    lineHeight: 48,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: '300',
    fontStyle: 'normal',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingVertical: 16,
    borderRadius: 8,
    backgroundColor: colors.PRIMARY_10,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    color: colors.PRIMARY_100,
  },
  guideText: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 25.2,
    letterSpacing: 0.21,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
  warningText: {
    opacity: 0.6,
    fontSize: 10,
    fontWeight: '300',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
});
