import { StyleSheet, Text, View, Image } from 'react-native';
import React from 'react';

import ReactMarkdown from 'react-markdown';
import NavBar from '../components/NavBar2';
import { Flex } from '@ant-design/react-native';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Redirect, useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { MarkdownScreenQuery } from '../__generated__/MarkdownScreenQuery.graphql';
import 'github-markdown-css/github-markdown-light.css';
import '../github-markdown-custom.css';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from '../components/LFWhiteSpace';
import LFText from '../components/typo/LFText';
import TopRoundFlex from '../components/TopRoundFlex';
import _ from 'lodash';
import ShareLinkModalButton from '../components/ShareLinkModalButton';
import LFMetaTags from '../components/LFMetaTags';

const MarkdownScreen: React.FC<{
  mdId?: string;
}> = (props) => {
  const params = useParams<{ mdId: string }>();

  const mdId = props.mdId || params.mdId;

  const { markdowns } = useLazyLoadQuery<MarkdownScreenQuery>(
    graphql`
      query MarkdownScreenQuery($mdId: ID!, $slug: String!) {
        markdowns(
          where: {
            OR: [{ objectId: { equalTo: $mdId } }, { slug: { equalTo: $slug } }]
          }
          first: 1
        ) {
          edges {
            node {
              navTitle
              content
              id
              objectId
              title
              subtitle
              coverImage {
                url
              }
            }
          }
        }
      }
    `,
    {
      mdId,
      slug: mdId,
    }
  );

  const markdown = _.first(markdowns.edges)?.node;
  if (!markdown) {
    return <Redirect to={'/error/404'} />;
  }

  return (
    <Flex direction="column" align="stretch" style={{ flex: 1 }}>
      <NavBar
        title={markdown.navTitle || ''}
        right={<ShareLinkModalButton style={{ marginRight: 8 }} />}
      />
      <LFMetaTags
        title={markdown.navTitle || markdown.title}
        description={markdown.subtitle}
        image={markdown.coverImage?.url}
      />
      <Flex direction="column" align="start" style={styles.blueCover}>
        <Flex
          direction="row"
          justify="between"
          align="start"
          style={{ alignSelf: 'stretch' }}
        >
          <LFText accessibilityRole="heading" style={styles.title}>
            {markdown?.title}
          </LFText>
          {/* <Image
            source={require('../assets/images/icons/closeIcon@3x.png')}
            style={{ width: 24, height: 24 }}
          /> */}
        </Flex>
        <LFWhiteSpace size={'sm'} />
        <LFText
          accessibilityRole="heading"
          aria-level={2}
          style={styles.subtitle}
        >
          {markdown?.subtitle}
        </LFText>
        <LFWhiteSpace size="lg" />
        {markdown?.coverImage?.url ? (
          <>
            <Image
              accessibilityLabel={`${markdown.title} 대표 이미지`}
              source={{ uri: markdown?.coverImage?.url }}
              style={styles.cover}
            />
            <LFWhiteSpace size={'xxl'} />
          </>
        ) : null}
        {/* <Flex style={styles.descriptionWrap}>
          <LFText style={styles.description}>{tagDesc?.description}</LFText>
        </Flex> */}
      </Flex>
      <View style={{ backgroundColor: colors.BG_3 }}>
        <TopRoundFlex>
          <div
            className="markdown-body"
            style={{
              flex: 1,
              padding: '32px 24px 24px',
              flexDirection: 'column',
              display: 'flex',
              margin: 0,
              boxSizing: 'border-box',
            }}
          >
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {markdown.content || ''}
            </ReactMarkdown>
          </div>
        </TopRoundFlex>
      </View>
    </Flex>
  );
};

export default MarkdownScreen;

const styles = StyleSheet.create({
  cover: {
    width: '100%',
    paddingBottom: Math.floor((250 / 375) * 100) + '%',
    alignSelf: 'center',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 40,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  subtitle: {
    opacity: 0.6,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 25.2,
    letterSpacing: 0.21,
    color: colors.TEXT_100,
  },
  blueCover: {
    paddingTop: 48,
    paddingHorizontal: 24,
    // backgroundColor: colors.PRIMARY_100,    tag 페이지 배경색 변경
    backgroundColor: colors.BG_3,
  },
});
