import { Flex, WhiteSpace } from '@ant-design/react-native';
import _ from 'lodash';
import React, {
  useState,
  useRef,
  useEffect,
  Suspense,
  useMemo,
  useCallback,
} from 'react';
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  StyleProp,
  ImageStyle,
  Image,
} from 'react-native';
import { Modal } from '@ant-design/react-native';
import { ScrollView } from 'react-native-gesture-handler';
import {
  fetchQuery,
  graphql,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from 'react-relay';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router';
import ReactionButton from '../components/ReactionButton';
import ShareLinkModalButton from '../components/ShareLinkModalButton';
import UserProfileView from '../components/UserProfileView';
import { useAuth } from '../hooks/auth';
import LFMetaTags from '../components/LFMetaTags';
import { useTrackRecentHistoryEffect } from '../hooks/useTrackRecentHistoryEffect';
import { graphqlElementsToStringArray } from '../helper/typeTransform';
import LFButton from '../components/LFButton';
import { useLearningHistoryMutation } from '../hooks/learningHistoryState';
import dayjs from 'dayjs';
import PreviewImage from '../components/PreviewImage';
import NodeCircle from './TraversalScreen/NodeCircle';
import InstructionCard from '../components/InstructionCard/InstructionCard';
import { colors } from '../constants/styleGuide';
import { useMobileDimensions } from '../hooks/useMobileDimensions';
import { resizedImageURL } from '../helper/resizedImageURL';
import LFLink from '../components/LFLink';
import { EmojiStampItem } from './EmojiReactionListScreen';
import LFText, { LFHyperLink } from '../components/typo/LFText';
import LFWhiteSpace from '../components/LFWhiteSpace';
import NavBar2, {
  HistoryBackButton,
  HomeButton,
  MoreButton,
} from '../components/NavBar2';
import FloatFullView from '../components/FloatFullView';
import Confetti from 'react-confetti';
import MembershipPaymentGuideButton from '../components/MembershipPaymentGuideButton';
import { useLazyLoadLearningInfoOfPath } from '../hooks/useLazyLoadLearningInfoOfPath';
import { useViewCountEffect } from '../hooks/viewCountEffect';
import SuggestionLogInSignUpModal from '../components/SuggestionLogInSignUpModal';
import { usePathnameChangeCount } from '../hooks/useLocalStorageCount';
import { TraversalScreenLPQuery } from '../__generated__/TraversalScreenLPQuery.graphql';
import { TraversalScreenLearningHistoriesQuery } from '../__generated__/TraversalScreenLearningHistoriesQuery.graphql';
import { TraversalScreenPathNodeQuery } from '../__generated__/TraversalScreenPathNodeQuery.graphql';
import { TraversalScreenPathMutation } from '../__generated__/TraversalScreenPathMutation.graphql';
import {
  TraversalScreenPathDeleteMutation,
  TraversalScreenPathDeleteMutationResponse,
} from '../__generated__/TraversalScreenPathDeleteMutation.graphql';
import {
  TraversalScreenInstructionCardDeleteMutation,
  TraversalScreenInstructionCardDeleteMutationResponse,
} from '../__generated__/TraversalScreenInstructionCardDeleteMutation.graphql';
import {
  TraversalScreenQuizDeleteMutation,
  TraversalScreenQuizDeleteMutationResponse,
} from '../__generated__/TraversalScreenQuizDeleteMutation.graphql';
import RecommendedContentsRelatedWithPath from '../components/RecommendedContentsRelatedWithPath';
import Hr from '../components/Hr';
import BookMarkButton from '../components/BookMarkButton';
import Comments, {
  CommentsLabel,
  CommentsList,
} from '../components/Comment/Comments';
import { CommentControls, CommentForm } from '../components/Comment/Comment';
import { useUpdateEffect } from 'ahooks';
import { CommentWhereInput } from '../__generated__/CommentsQuery.graphql';
import { LoginScreenAccessAction } from './LoginScreen';
import {
  CreateCommentFieldsInput,
  UpdateCommentFieldsInput,
} from '../__generated__/NotePlayerScreenUpdateCommentMutation.graphql';
import {
  TraversalScreenDeleteCommentMutation,
  TraversalScreenDeleteCommentMutationResponse,
} from '../__generated__/TraversalScreenDeleteCommentMutation.graphql';
import {
  TraversalScreenUpdateCommentMutation,
  TraversalScreenUpdateCommentMutationResponse,
} from '../__generated__/TraversalScreenUpdateCommentMutation.graphql';
import {
  TraversalScreenCommentMutation,
  TraversalScreenCommentMutationResponse,
} from '../__generated__/TraversalScreenCommentMutation.graphql';
import InfoBoxNotPublishedContent from '../components/InfoBoxNotPublishedContent';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface TraversalScreenParamList {
  pathId: string;
}

function useValueOnce<T>(initialValue?: T) {
  const isFirstRef = useRef<boolean>(true);
  const [value, setValue] = useState<typeof initialValue>(initialValue);
  const setValueWhenFirst = (value: T) => {
    if (isFirstRef.current) {
      setValue(value);
      isFirstRef.current = false;
    }
  };
  return [value, setValueWhenFirst] as const;
}

const TraversalScreen: React.FC = () => {
  const params = useParams<TraversalScreenParamList>();
  const location = useLocation();
  let { pathname } = location;
  // console.log(rMatch);j
  // const pathId = params.pathId;
  const pathId = _.last(pathname.split('/'));
  const { user } = useAuth();
  const history = useHistory();

  const [isVisibleConfetti, setIsVisibleConfetti] = useState(false);
  const mDimensions = useMobileDimensions();
  // follow, following 구분을 위한 state
  const [isFollowing, setIsFollowing] = useState(false);
  const toggleFollowing = () => setIsFollowing((prev) => !prev);

  const { width: w } = useMobileDimensions();
  const [secondCardHeight, setSecondCardHeight] = useValueOnce<number>();

  // 썸네일 ~ 진행율까지 적용되는 paddingHorizontal 수치입니다.
  const paddingHorizontal = 16;

  // thumbnail image의 borderRadius 값
  const thumbnailWidth = w - paddingHorizontal * 2;
  const thumbnailBorderRadius = thumbnailWidth * 0.035;

  useTrackRecentHistoryEffect({
    targetFieldName: 'path',
    targetClassObjectId: pathId,
  });

  useViewCountEffect({
    className: 'Path',
    objectId: pathId,
  });

  const [commitUpdatePath, isUpdatePathInFlight] =
    useMutation<TraversalScreenPathMutation>(graphql`
      mutation TraversalScreenPathMutation($input: UpdatePathInput!) {
        updatePath(input: $input) {
          path {
            id
            objectId
            updatedAt
            published
          }
        }
      }
    `);

  const [commitDeletePath, isDeletePathInFlight] =
    useMutation<TraversalScreenPathDeleteMutation>(graphql`
      mutation TraversalScreenPathDeleteMutation($id: ID!) {
        deletePath(input: { id: $id }) {
          path {
            id
            objectId
            title
            instructionCards {
              edges {
                node {
                  id
                  objectId
                }
              }
            }
          }
        }
      }
    `);

  const deletePathById = ({ id }: { id: string | null | undefined }) =>
    new Promise<TraversalScreenPathDeleteMutationResponse>(
      (onSuccess, onFail) => {
        if (id) {
          commitDeletePath({
            variables: { id },
            onCompleted: (response, reject) => {
              if (response) {
                onSuccess(response);
                return;
              }
              onFail(reject);
            },
            onError: onFail,
          });
        } else {
          onFail('Path 삭제 실패: id 타입이 올바르지 않습니다.');
        }
      }
    );

  const [commitDeleteInstructionCard, isDeleteInstructionCardInFlight] =
    useMutation<TraversalScreenInstructionCardDeleteMutation>(graphql`
      mutation TraversalScreenInstructionCardDeleteMutation($id: ID!) {
        deleteInstructionCard(input: { id: $id }) {
          instructionCard {
            id
            objectId
            sources {
              __typename
              ... on Quiz {
                id
                objectId
              }
            }
          }
        }
      }
    `);

  const deleteInstructionCardById = ({
    id,
  }: {
    id: string | null | undefined;
  }) =>
    new Promise<TraversalScreenInstructionCardDeleteMutationResponse>(
      (onSuccess, onFail) => {
        if (id) {
          commitDeleteInstructionCard({
            variables: { id },
            onCompleted: (response, reject) => {
              if (response) {
                onSuccess(response);
                return;
              }
              onFail(reject);
            },
            onError: onFail,
          });
        } else {
          onFail('InstructionCard 삭제 실패: id 타입이 올바르지 않습니다.');
        }
      }
    );

  const [commitDeleteQuiz, isDeleteQuizInFlight] =
    useMutation<TraversalScreenQuizDeleteMutation>(graphql`
      mutation TraversalScreenQuizDeleteMutation($id: ID!) {
        deleteQuiz(input: { id: $id }) {
          quiz {
            id
            objectId
          }
        }
      }
    `);

  const deleteQuizById = ({ id }: { id: string | null | undefined }) =>
    new Promise<TraversalScreenQuizDeleteMutationResponse>(
      (onSuccess, onFail) => {
        if (id) {
          commitDeleteQuiz({
            variables: { id },
            onCompleted: (response, reject) => {
              if (response) {
                onSuccess(response);
                return;
              }
              onFail(reject);
            },
            onError: onFail,
          });
        } else {
          onFail('퀴즈 삭제 실패: id 타입이 올바르지 않습니다.');
        }
      }
    );

  const { path, learningHistories } = useLazyLoadQuery<TraversalScreenLPQuery>(
    graphql`
      query TraversalScreenLPQuery($pathId: ID!, $userObjectId: ID!) {
        path(id: $pathId) {
          ...useLazyLoadLearningInfoOfPathFragment
          id
          objectId
          createdAt
          updatedAt
          title
          learnCount
          published
          description
          estimate
          benefits {
            ... on Element {
              value
            }
          }
          prerequisites {
            ... on Element {
              value
            }
          }
          isSeries
          target
          coverImage {
            name
            url
          }
          author {
            id
            objectId
            name
            ...UserProfileViewUserFragment
          }
          tags {
            ... on Element {
              value
            }
          }
          membership {
            id
            objectId
            createdAt
            updatedAt
            title
            level
            role {
              users(where: { objectId: { equalTo: $userObjectId } }) {
                count
              }
            }
            ...MembershipPaymentGuideButton_Fragment
          }
          instructionCards(order: [seq_ASC, createdAt_ASC]) {
            edges {
              node {
                seq
                optional
                id
                objectId
                description
                ...InstructionCardFragment
                sources {
                  __typename
                  ... on ContentItem {
                    id
                    objectId
                    title
                    content {
                      id
                      title
                      objectId
                      thumbURL
                      type
                      link
                    }
                    author {
                      objectId
                      name
                    }
                    # ...ContentItemView_Fragment
                  }
                  ... on Content {
                    id
                    objectId
                    thumbURL
                    title
                    link
                    type
                  }
                  ... on Path {
                    id
                    objectId
                    title
                    description
                    author {
                      objectId
                      name
                    }
                  }
                  ... on Quiz {
                    id
                    objectId
                    title
                    options {
                      ... on Element {
                        value
                      }
                    }
                    correctIndexes {
                      ... on Element {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
          ...RecommendedContentsRelatedWithPath_Fragment
        }
        learningHistories(
          where: {
            author: { have: { objectId: { equalTo: $userObjectId } } }
            path: { have: { objectId: { equalTo: $pathId } } }
          }
          first: 1
        ) {
          __id
          edges {
            node {
              id
              objectId
              status
              path {
                id
                objectId
              }
              contentItem {
                id
                objectId
              }
              quiz {
                id
                objectId
              }
              createdAt
              updatedAt
            }
          }
        }
      }
    `,
    { pathId: pathId || 'NOID', userObjectId: user?.objectId || 'NOUSER' },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const learningInfo = useLazyLoadLearningInfoOfPath({
    pathFragment: path,
  });

  const learningHistory = _.first(learningHistories.edges)?.node;
  const [commitLearningHistory, isMutatingLearningHistory] =
    useLearningHistoryMutation({
      pathId: pathId,
      learningHistoryId: learningHistory?.objectId,
      connections: [learningHistories.__id],
      // parentPathId: parentPathObjectId,
    });

  const hasValidMembership = (path.membership?.role?.users.count || 0) > 0;
  const hideMembershipContent =
    path.membership &&
    !hasValidMembership &&
    path.author?.objectId !== user?.objectId;

  const data = hideMembershipContent
    ? _.chain(path.instructionCards.edges)
        .map((e) => e?.node)
        .slice(0, 2)
        .concat()
        .value()
    : _.map(path.instructionCards.edges, (e) => e?.node);

  const isMyPath = user?.objectId && user?.objectId === path.author?.objectId;

  const lastNonTextCardIndex = _.findLastIndex(
    path.instructionCards.edges,
    (e) => !!_.first(e?.node?.sources)
  );

  const isAllDone =
    learningInfo.doneRequiredCardCount === learningInfo.totalRequiredCount;

  const coverImageURL = path.coverImage?.url;

  // 리액션 현황에서 보여줄 이모지 데이터
  const emojiTypes: EmojiStampItem[] = [
    { type: '👍', display: '도움돼요' },
    { type: '🌱', display: '쉬워요' },
    { type: '🤷‍♂️', display: '어려워요' },
    { type: '👀', display: '또 볼래요' },
    { type: '😉', display: '재미있어요' },
  ];

  const [visitCount, setVisitCount] = usePathnameChangeCount({
    key: 'TraversalAndNoteplayer',
  });

  const resetVisitCount = () => {
    setVisitCount(0);
  };

  const [showSuggestionLogInSignUpModal, setShowSuggestionLogInSignUpModal] =
    useState(visitCount >= 2);

  useEffect(() => {
    if (visitCount && user) resetVisitCount();
    if (visitCount >= 2 && !user) setShowSuggestionLogInSignUpModal(true);
  }, [visitCount]);

  // 비공개인 러닝패스를 공개로 변경 시 예외처리하는 함수
  const publishedValidator = () => {
    if (!path.published) {
      if (!path.title) {
        alert('러닝패스 제목을 입력해 주세요.');
        return false;
      } else if (
        typeof path.instructionCards.edges?.length === 'number' &&
        path.instructionCards.edges?.length < 2
      ) {
        alert('2개 이상의 학습노트를 포함해 주세요.');
        return false;
      }
    }
    return true;
  };

  const pathFragment = useMemo(() => path, [path?.objectId]);

  const CommentsQuery = require('../__generated__/CommentsQuery.graphql.ts');
  const environment = useRelayEnvironment();
  const [refreshedQueryOptions, setRefreshedCommentsQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2] | null
  >(null);
  const [isCommentsRefreshing, setIsCommentsRefreshing] = useState(false);

  const commentsRefresh = useCallback(() => {
    if (isCommentsRefreshing) {
      return;
    }
    setIsCommentsRefreshing(true);
    fetchQuery(environment, CommentsQuery, { where: commentsWhere }).subscribe({
      complete: () => {
        setIsCommentsRefreshing(false);
        setRefreshedCommentsQueryOptions((prev) => ({
          fetchKey: ((prev?.fetchKey ?? 0) as number) + 1,
          fetchPolicy: 'store-only',
        }));
      },
      error: () => {
        setIsCommentsRefreshing(false);
      },
    });
  }, [path?.objectId]);

  useUpdateEffect(() => {
    commentsRefresh();
  }, [path?.objectId]);

  const commentsWhere = useMemo<CommentWhereInput>(
    () => ({
      targetClassName: {
        equalTo: 'Path',
      },
      path: {
        have: {
          objectId: {
            equalTo: path?.objectId || '',
          },
        },
      },
      parent: {
        haveNot: {
          objectId: {
            exists: true,
          },
        },
      },
    }),
    [path?.objectId || '']
  );

  const onCommentFormFocus = () => {
    !user &&
      history.push({
        pathname: '/login',
        state: {
          from: location,
          accessAction: 'tryWritingComment' as LoginScreenAccessAction,
        },
      });
  };

  const onPressCommentAvatar = (userId: string) => {
    history.push({ pathname: `/u/${userId}` });
  };

  const [newCommentText, setNewCommentText] = useState('');

  const validateCommentText = (text: string) => {
    return newCommentText.trim().length !== 0;
  };

  const [commitCreateComment, isCommitCreateCommentInFlight] =
    useMutation<TraversalScreenCommentMutation>(graphql`
      mutation TraversalScreenCommentMutation(
        $fields: CreateCommentFieldsInput!
        $clientMutationId: String!
      ) {
        createComment(
          input: { fields: $fields, clientMutationId: $clientMutationId }
        ) {
          clientMutationId
          comment {
            id
            objectId
            text
            author {
              id
              objectId
            }
            path {
              id
              objectId
            }
            targetClassName
          }
        }
      }
    `);

  const createComment = ({ fields }: { fields: CreateCommentFieldsInput }) =>
    new Promise<TraversalScreenCommentMutationResponse>((onSuccess, onFail) => {
      commitCreateComment({
        variables: {
          clientMutationId: 'commentCreateId',
          fields,
        },
        onCompleted: (res, rej) => {
          console.log(res);
          if (res) {
            onSuccess(res);
            return;
          }
          onFail(rej);
        },
        onError: (error) => {
          onFail(error);
        },
      });
    });
  const [commitUpdateComment, isCommitUpdateCommentInFlight] =
    useMutation<TraversalScreenUpdateCommentMutation>(
      graphql`
        mutation TraversalScreenUpdateCommentMutation(
          $objectId: ID!
          $fields: UpdateCommentFieldsInput!
        ) {
          updateComment(input: { id: $objectId, fields: $fields }) {
            clientMutationId
            comment {
              id
              objectId
              text
            }
          }
        }
      `
    );

  const updateComment = (id: string, fields: UpdateCommentFieldsInput) =>
    new Promise<TraversalScreenUpdateCommentMutationResponse>(
      (onSuccess, onFail) => {
        commitUpdateComment({
          variables: {
            objectId: id,
            fields,
          },
          onCompleted: (res, rej) => {
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: onFail,
        });
      }
    );

  const [commitDeleteComment, isCommitDeleteCommentInFlight] =
    useMutation<TraversalScreenDeleteCommentMutation>(
      graphql`
        mutation TraversalScreenDeleteCommentMutation($id: ID!) {
          deleteComment(input: { id: $id }) {
            comment {
              id
              objectId
            }
          }
        }
      `
    );

  const deleteComment = (id: string) =>
    new Promise<TraversalScreenDeleteCommentMutationResponse>(
      (onSuccess, onFail) =>
        commitDeleteComment({
          variables: {
            id,
          },
          onCompleted: (res, rej) => {
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: onFail,
        })
    );

  return (
    <>
      {showSuggestionLogInSignUpModal && (
        <SuggestionLogInSignUpModal
          visible={showSuggestionLogInSignUpModal}
          noAgainId={'content-login-signup-suggestion-v1'}
          onClose={() => {
            setShowSuggestionLogInSignUpModal(false);
            resetVisitCount();
          }}
          scrollable={false}
        />
      )}
      <ScrollView style={{ flex: 1, backgroundColor: colors.BG_1 }}>
        <LFMetaTags
          type="article"
          title={`${path.title} | 러닝패스 - Learnfit`}
          description={
            path.description ||
            `${path.instructionCards?.edges?.length}개의 콘텐츠로 끝내는 ${path.author?.name}님의 ${path.title} 러닝패스를 경험하세요.`
          }
          authorName={path.author?.name}
          authorId={path.author?.objectId}
          objectId={path.objectId}
          tags={graphqlElementsToStringArray(path.tags)}
          image={coverImageURL}
          ldJson={{
            headline: path?.title || '',
            dateModified: dayjs(path.updatedAt as Date).format(),
            datePublished: dayjs(path.createdAt as Date).format(),
          }}
        />

        <Flex direction="column" align="stretch">
          <NavBar2
            title={'러닝패스'}
            right={
              <>
                <ShareLinkModalButton
                  url={`${window.origin}${pathname}`}
                  title=""
                  style={{ padding: 8 }}
                />
                {isMyPath ? (
                  <MoreButton
                    isLoading={isUpdatePathInFlight || isDeletePathInFlight}
                    options={[
                      {
                        title: '수정',
                        onPress: () =>
                          history.push({
                            pathname: `/path-edit/${path.objectId}`,
                            state: { from: location },
                          }),
                      },
                      {
                        title: path.published ? '비공개로 변경' : '공개로 변경',
                        onPress: () => {
                          publishedValidator()
                            ? commitUpdatePath({
                                variables: {
                                  input: {
                                    id: pathId!,
                                    fields: {
                                      published: !path.published,
                                    },
                                  },
                                },
                              })
                            : null;
                        },
                      },
                      {
                        title: '삭제',
                        onPress: () => {
                          Modal.alert(
                            '선택하신 러닝패스 1개를 정말로 삭제하시겠습니까?',
                            '',
                            [
                              {
                                text: '취소',
                                onPress: () => {},
                                style: 'cancel',
                              },
                              {
                                text: '삭제',
                                onPress: () => {
                                  if (pathId) {
                                    deletePathById({ id: pathId })
                                      .then((res) => {
                                        const filteredEdges =
                                          res.deletePath?.path?.instructionCards?.edges?.filter(
                                            (edge) =>
                                              _.isString(edge?.node?.objectId)
                                          );
                                        const shouldDeleteInstructionCardIds =
                                          _.chain(filteredEdges)
                                            .map((edge) => edge?.node?.objectId)
                                            .value();
                                        const promisesDeletingInstructionCardAndQuiz =
                                          shouldDeleteInstructionCardIds
                                            ?.filter(_.isString)
                                            .map((objectId) =>
                                              deleteInstructionCardById({
                                                id: objectId,
                                              }).then((res) => {
                                                const sources =
                                                  res.deleteInstructionCard
                                                    ?.instructionCard.sources;
                                                const source = _.head(sources);
                                                if (
                                                  source?.__typename === 'Quiz'
                                                ) {
                                                  deleteQuizById({
                                                    id: source.objectId,
                                                  });
                                                }
                                              })
                                            );
                                        return Promise.allSettled(
                                          promisesDeletingInstructionCardAndQuiz
                                        );
                                      })
                                      .catch(console.log)
                                      .then(history.goBack);
                                  }
                                  // commitDeletePath({
                                  //   variables: {
                                  //     id: pathId,
                                  //   },
                                  //   onCompleted: () => {
                                  //     history.goBack();
                                  //   },
                                  // }),
                                },
                                style: 'destructive',
                              },
                            ]
                          );
                        },
                      },
                    ]}
                    destructiveButtonIndex={2}
                  />
                ) : null}
              </>
            }
          />

          {/* 커버이미지 ~ 학습 진행율을 커버하는 UI 영역 시작 */}
          <View style={{ paddingHorizontal, paddingVertical: 16 }}>
            {/* 커버이미지(썸네일) UI */}
            {coverImageURL && (
              <>
                <PreviewImage
                  accessibilityLabel={`${path?.title}의 대표 이미지`}
                  // box-sizing css property가 ts 에러 발생시킴
                  // @ts-ignore
                  style={[
                    styles.thumbnail,
                    { borderRadius: thumbnailBorderRadius },
                  ]}
                  source={{
                    uri: coverImageURL || '',
                  }}
                />
                <WhiteSpace size="lg" />
              </>
            )}

            {/* 유저 정보 & 팔로우 버튼 UI */}
            <Flex justify="between" align="start">
              <UserProfileView
                testID="curator-profile-link:UserProfileView"
                userFrgmt={path.author}
                style={{ alignSelf: 'flex-start' }}
              />
              <BookMarkButton targetClassName="Path" targetId={path.objectId} />
              {/* <LFLink
                style={
                  isFollowing ? styles.followingButton : styles.followButton
                }
                onPress={toggleFollowing}
              >
                <LFText
                  style={[
                    styles.buttonLabel,
                    isFollowing ? { color: 'rgba(26, 27, 30, 0.8)' } : null,
                  ]}
                >
                  {isFollowing ? '팔로잉' : '팔로우'}
                </LFText>
              </LFLink> */}
            </Flex>
            <WhiteSpace size="lg" />

            {/* 학습 완료인지, 학습 진행 중인지를 나타내는 status UI */}
            {learningHistory?.status && (
              <>
                <View style={styles.learningStatus}>
                  <LFText
                    style={[
                      styles.learningStatusLabel,
                      learningHistory?.status === 'done' && {
                        color: 'rgba(26, 27, 30, 0.6)',
                      },
                    ]}
                  >
                    {learningHistory?.status === 'done'
                      ? `🏅학습완료 ${dayjs(
                          learningHistory?.updatedAt as string | undefined
                        ).format('YYYY.MM.DD')}`
                      : '✍️학습중'}
                  </LFText>
                </View>
                <WhiteSpace size="xl" />
              </>
            )}

            {/* path title UI */}
            <LFText style={styles.title} selectable accessibilityRole="heading">
              {path.title}
            </LFText>
            <InfoBoxNotPublishedContent
              onPublishButtonPress={(status) =>
                commitUpdatePath({
                  variables: {
                    input: {
                      id: pathId || '',
                      fields: {
                        published: status,
                      },
                    },
                  },
                })
              }
              published={path.published}
            />
            <WhiteSpace size="md" />

            {/* path가 시리즈인지, 무료인지, 멤버쉽 전용인지 나타내는 badge UI */}
            <Flex>
              <Flex
                align="center"
                style={[
                  styles.badgeItem,
                  {
                    backgroundColor: path?.membership
                      ? 'rgba(35, 110, 255, 0.2)'
                      : '#FCDC33',
                    paddingRight: 6,
                  },
                ]}
              >
                <View style={styles.badgeLogo}>
                  <PreviewImage
                    accessibilityLabel=""
                    style={styles.badgeImage as StyleProp<ImageStyle>}
                    source={require('../assets/images/free-lf-logo.png')}
                  />
                </View>
                <LFText
                  style={[
                    styles.badgeLabel,
                    path?.membership ? { color: '#236EFF' } : null,
                  ]}
                >
                  {path.membership ? '멤버십' : '무료'}
                </LFText>
              </Flex>
              {path?.isSeries && (
                <View
                  style={[
                    styles.badgeItem,
                    {
                      marginLeft: 4,
                      borderRadius: 4,
                      paddingVertical: 4,
                      paddingHorizontal: 8,
                    },
                  ]}
                >
                  <LFText style={styles.badgeLabel}>시리즈</LFText>
                </View>
              )}
            </Flex>

            {/* path의 특징을 나타내는 UI 1 */}
            {Boolean(path?.estimate) && (
              <>
                <WhiteSpace size="xl" />
                <LFText
                  accessibilityRole="heading"
                  aria-level={2}
                  style={styles.salesPointList}
                >
                  {`⚡ ${path.instructionCards?.edges?.length}개의 콘텐츠로 ${path.estimate}시간이면 이런걸 할 수 있어요!`}
                </LFText>
              </>
            )}

            {path?.benefits && path.benefits.length ? (
              <>
                <WhiteSpace size="sm" />
                <View style={styles.salesPointItem}>
                  {path.benefits.map((item) => (
                    <Flex key={item?.value as string} align="start">
                      <LFText style={{ width: 'max-content', flexShrink: 0 }}>
                        {'• '}
                      </LFText>
                      <LFText
                        accessibilityRole="paragraph"
                        style={{
                          fontStyle: 'normal',
                          fontWeight: '600',
                          fontSize: 14,
                          lineHeight: 24,
                          color: 'rgba(26, 27, 30, 0.8)',
                          // wordBreak css속성을 못잡아내서 ts 오류 발생
                          // @ts-ignore
                          wordBreak: 'keep-all',
                        }}
                      >
                        {item?.value as string}
                      </LFText>
                    </Flex>
                  ))}
                </View>
              </>
            ) : null}

            {/* path의 특징을 나타내는 UI 2 */}
            <WhiteSpace size="xl" />
            <View style={styles.meritList}>
              {path?.target && (
                <Flex justify="between" style={styles.meritItem}>
                  <LFText style={styles.meritTitle}>
                    이 분들은 꼭 보세요.
                  </LFText>
                  <LFText style={styles.meritDescription}>{path.target}</LFText>
                </Flex>
              )}
              {path?.prerequisites && path.prerequisites.length ? (
                <Flex justify="between" style={styles.meritItem}>
                  <LFText style={styles.meritTitle}>
                    미리 알고 있어야 해요.
                  </LFText>
                  <LFText style={styles.meritDescription}>
                    {path.prerequisites
                      .map((item) => item?.value as string)
                      .join(', ')}
                  </LFText>
                </Flex>
              ) : null}
            </View>
            <WhiteSpace size="xl" />
            <View
              style={[
                styles.borderLine,
                { marginHorizontal: -paddingHorizontal },
              ]}
            ></View>

            <WhiteSpace size="xl" />
            <WhiteSpace size="sm" />
            <View>
              <div
                className="markdown-body"
                style={{
                  fontSize: 16,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 1.6,
                  textAlign: 'left',
                  color: 'rgba(26, 27, 30, 0.8)',
                  // wordBreak css속성을 못잡아내서 ts 오류 발생
                  // @ts-ignore
                  wordBreak: 'keep-all',
                }}
              >
                <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                  {path?.description || ''}
                </ReactMarkdown>
              </div>
            </View>
            <WhiteSpace size="xl" />
            <WhiteSpace size="sm" />
            <View
              style={[
                styles.borderLine,
                { marginHorizontal: -paddingHorizontal },
              ]}
            ></View>
            <WhiteSpace size="xl" />

            {/* path 학습 진행율을 알려주는 UI */}
            {learningHistory?.status === 'done' ||
            learningHistory?.status === 'in_progress' ? (
              <>
                <WhiteSpace size="sm" />
                <Flex justify="between" align="center">
                  <LFText style={styles.progressTitle}>나의 학습 진행율</LFText>
                  <LFText style={styles.progressPercentage}>
                    {/* progressNumber(숫자)에 파란색이 적용되는 조건: 0보다 클 것 */}
                    <LFText
                      style={[
                        styles.progressNumber,
                        learningInfo.progressPercent
                          ? { color: '#236EFF' }
                          : null,
                      ]}
                    >
                      {learningInfo.progressPercent}
                    </LFText>
                    {' %'}
                  </LFText>
                </Flex>
                <WhiteSpace size="sm" />
                <View style={styles.progressBar}>
                  <View
                    style={[
                      styles.progressFill,
                      { width: `${learningInfo.progressPercent}%` },
                    ]}
                  ></View>
                </View>
                <WhiteSpace size="xl" />
              </>
            ) : null}
          </View>
          {/* 커버이미지 ~ 학습 진행율을 커버하는 UI 영역 끝 */}
        </Flex>
        {/* <PathNodeView /> */}

        {_.map(data, (card, idx, cards) => {
          const active = idx === 1;
          const item = _.first(card?.sources);

          const learningStatus = learningInfo.learningStatusMap[item?.id];

          const isPreviousOptional = idx > 0 && cards[idx - 1]?.optional;
          const isNextOptional =
            _.last(cards) !== card && cards[idx + 1]?.optional;
          return (
            <View
              pointerEvents={hideMembershipContent ? 'none' : 'auto'}
              onLayout={(e) => {
                active && setSecondCardHeight(e.nativeEvent.layout.height);
              }}
              style={[
                { paddingHorizontal: 16 },
                active && hideMembershipContent && secondCardHeight
                  ? { height: secondCardHeight / 2, overflow: 'hidden' }
                  : null,
              ]}
              key={card?.id}
            >
              <Flex direction="row">
                {/* {card.optional ? (
          <NodeCircle
            status={
              highlightCardObjectId &&
              highlightCardObjectId === card?.objectId
                ? 'highlight'
                : learningStatus
            }
            hideLine={_.last(data) === card}
            hideCircle={true}
          />
        ) : null} */}
                <NodeCircle
                  style={{ marginRight: 8 }}
                  status={
                    learningInfo.highlightCardObjectId &&
                    learningInfo.highlightCardObjectId === card?.objectId
                      ? 'highlight'
                      : learningStatus
                  }
                  hideLine={lastNonTextCardIndex <= idx}
                  hideCircle={!item}
                  optional={card?.optional}
                />
                <Flex direction="column" align="stretch" style={{ flex: 1 }}>
                  {card?.optional ? (
                    <>
                      {!isPreviousOptional ? (
                        <View
                          style={{
                            height: 32,
                            backgroundColor: 'white',
                            overflow: 'hidden',
                            marginLeft: -16,
                          }}
                        >
                          <Image
                            accessibilityLabel=""
                            style={{
                              width: 28,
                              height: 101,
                              position: 'absolute',
                              top: 0,
                              left: 0,
                            }}
                            source={require('../assets/images/instructionCard/optional-head.png')}
                          />
                        </View>
                      ) : null}
                      <Flex direction="row" align="center">
                        {/* optional 용 circle */}
                        <NodeCircle
                          hideLine={true}
                          status={
                            learningInfo.highlightCardObjectId &&
                            learningInfo.highlightCardObjectId ===
                              card?.objectId
                              ? 'highlight'
                              : learningStatus
                          }
                        />
                        <LFWhiteSpace direction="row" size="lg" />
                        {!isPreviousOptional ? (
                          <Flex
                            style={{
                              padding: 8,
                              borderRadius: 12,
                              backgroundColor: colors.BG_2,
                              flex: 1,
                              marginTop: -22,
                            }}
                          >
                            <LFText
                              style={{
                                fontSize: 24,
                                fontWeight: '600',
                                fontStyle: 'normal',
                                lineHeight: 36,
                                letterSpacing: 0,
                                textAlign: 'center',
                                color: '#000000',
                              }}
                            >
                              💡
                            </LFText>
                            <LFWhiteSpace size="xs" direction="row" />
                            <LFText
                              style={{
                                fontSize: 14,
                                fontWeight: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 24,
                                letterSpacing: 0,
                                color: colors.TEXT_80,
                              }}
                            >
                              이 부분은 심화 학습 이예요.{'\n'}
                              조금 더 알아보고 싶다면 학습해보세요!
                            </LFText>
                          </Flex>
                        ) : null}
                      </Flex>
                      <LFWhiteSpace size="lg" />
                    </>
                  ) : null}
                  <InstructionCard
                    cardFrgmt={card}
                    highlight={
                      learningInfo.highlightCardObjectId === card?.objectId
                    }
                    // contentContainerStyle={{
                    //   opacity: learningStatus === 'done' ? 0.5 : 1,
                    // }}
                    learningStatus={learningStatus}
                    learningHistoriesConnectionId={learningInfo.connectionId}
                  />
                  {card?.optional && !isNextOptional ? (
                    <View
                      style={{
                        backgroundColor: 'white',
                        marginTop: 5,
                        marginLeft: -16,
                      }}
                    >
                      <Image
                        accessibilityLabel=""
                        style={{
                          width: 27,
                          height: 47,
                        }}
                        source={require('../assets/images/instructionCard/optional-tail.png')}
                      />
                    </View>
                  ) : (
                    <View style={{ height: 32 }} />
                  )}
                </Flex>
              </Flex>
            </View>
          );
        })}
        {hideMembershipContent ? (
          <Suspense fallback={<ActivityIndicator />}>
            <MembershipPaymentGuideButton
              onPress={() =>
                history.push({
                  pathname: `/u/${path.author?.objectId}/membership`,
                  state: { from: location },
                })
              }
              fragmentRef={path.membership}
            />
            {user ? null : (
              <Flex align="center" justify="center" direction="column">
                <LFText>
                  이미 멤버십 회원이라면{' '}
                  <LFText
                    style={styles.linkStyle}
                    onPress={() => {
                      history.push({
                        pathname: `/login`,
                        state: { from: location },
                      });
                    }}
                  >
                    로그인
                  </LFText>
                  하세요.
                </LFText>
                <LFWhiteSpace size="lg" />
              </Flex>
            )}
          </Suspense>
        ) : isAllDone ? (
          <View
            style={{
              paddingBottom: 30,
              paddingHorizontal: 16,
            }}
          >
            <LFButton
              testID="learningpath-done:TraversalScreen"
              onPress={() => {
                commitLearningHistory('done')
                  .then(() => {
                    // alert('완료 처리 성공');
                    setIsVisibleConfetti(true);
                  })
                  .catch((error) => console.error('#ERR', error));
              }}
              isLoading={isMutatingLearningHistory}
              onlyLoginUser={true}
              // disabled={!isAllDone || learningHistory?.status === 'done'}
              disabled={learningHistory?.status === 'done'}
              type="check"
            >
              {learningHistory?.status === 'done'
                ? `🏅학습완료 ${dayjs(
                    learningHistory?.updatedAt as string | undefined
                  ).format('YYYY.MM.DD')}`
                : '러닝패스 학습 완료'}
            </LFButton>
            {/* <LFText style={styles.pathDescription}>
            꼬리글 Lorem ipsum dolor, sit amet consectetur adipisicing elit.
            Consequuntur pariatur facilis voluptas, tempore, vero nostrum,
            voluptatibus fugit unde ratione atque quod expedita possimus
            tempora illo? Magnam inventore voluptate at explicabo?
          </LFText> */}
          </View>
        ) : null}

        <WhiteSpace size="xl" />
        <View
          style={[styles.borderLine, { marginHorizontal: -paddingHorizontal }]}
        ></View>

        {/* path에 대해 reaction 체크, 확인 할 수 있게 해주는 UI */}
        <WhiteSpace size="xl" />
        <LFText style={styles.reactionQustionTitle}>
          이 러닝패스가 어땠나요?
        </LFText>
        <LFText style={styles.reactionQustionAdditionalLabel}>
          (
          <LFLink
            testID="learningpath-goto-my-emoji:TraversalScreen"
            onPress={() => {
              history.push('/me/emoji');
            }}
          >
            <LFText style={styles.linkStyle}>내 보관함</LFText>
          </LFLink>
          에서 다시 볼 수 있어요)
        </LFText>
        <WhiteSpace size="md" />
        <Flex justify="between">
          {emojiTypes.map(({ type, display }) => {
            return (
              <ReactionButton
                key={type}
                display={display}
                targetClassName="Path"
                targetId={path.objectId}
                type={type}
              />
            );
          })}
        </Flex>
        <WhiteSpace size="xl" />
        <WhiteSpace size="md" />
        <View
          style={[styles.borderLine, { marginHorizontal: -paddingHorizontal }]}
        ></View>
        <LFWhiteSpace size="xl" />
        <Comments
          where={commentsWhere}
          style={{ paddingHorizontal: 16 }}
          refreshedQueryOptions={refreshedQueryOptions || {}}
        >
          <CommentsLabel />
          <LFWhiteSpace size="sm" />
          <CommentForm
            value={newCommentText}
            placeholder="댓글을 남겨주세요!"
            onFocus={onCommentFormFocus}
            onChangeText={setNewCommentText}
            controls={
              <CommentControls
                onPressCancelButton={() => {
                  setNewCommentText('');
                }}
                disabled={
                  !validateCommentText(newCommentText) ||
                  isCommitCreateCommentInFlight
                }
                submitButtonStyle={
                  validateCommentText(newCommentText)
                    ? {
                        backgroundColor: '#236EFF1A',
                      }
                    : {}
                }
                onPressSubbmitButton={() => {
                  const fields = {
                    text: newCommentText.trim(),
                    path: {
                      link: path.objectId,
                    },
                    targetClassName: 'Path',
                  };
                  createComment({ fields }).then((_) => {
                    setNewCommentText('');
                    commentsRefresh();
                  });
                }}
                submitButtonTextStyle={
                  validateCommentText(newCommentText)
                    ? {
                        color: '#236EFF',
                        fontWeight: '600',
                      }
                    : {}
                }
              />
            }
          />
          <LFWhiteSpace size="xl" />
          <CommentsList
            onCreate={createComment}
            onDelete={deleteComment}
            onEdit={updateComment}
            afterMutation={commentsRefresh}
            contentAuthorId={path.author?.objectId || ''}
            targetClassName="path"
            onFocus={onCommentFormFocus}
            onPressAvatar={onPressCommentAvatar}
          />
        </Comments>
        <LFWhiteSpace size="xl" direction="column" />
        <Hr />
        <LFWhiteSpace size="xl" direction="column" />
        <Suspense fallback={<ActivityIndicator />}>
          {/* 입력한 러닝패스 프래그먼트 키와 관련된 러닝패스를 추천해주는 컴포넌트 */}
          <RecommendedContentsRelatedWithPath
            pathFragment={pathFragment}
            isMembershipUser={
              !Boolean(hideMembershipContent) && Boolean(path.membership)
            }
          />
        </Suspense>
        <LFWhiteSpace size="xl" direction="column" />
      </ScrollView>
      <FloatFullView style={{ marginLeft: 0 }}>
        {isVisibleConfetti ? (
          <Confetti
            width={mDimensions.width}
            height={mDimensions.height}
            // tweenDuration={20000}
            recycle={false}
          />
        ) : null}
      </FloatFullView>
      {!learningHistory &&
      learningInfo.doneRequiredCardCount &&
      !hideMembershipContent ? (
        <Flex
          direction="column"
          //@ts-ignore
          style={{
            position: 'sticky',
            bottom: 16,
            left: 0,
            right: 0,
            opacity: 0.95,
            borderRadius: 12,
            backgroundColor: colors.BG_WHITE,
            shadowColor: '#3a456333',
            shadowOffset: {
              width: 0,
              height: 4,
            },
            shadowRadius: 10,
            shadowOpacity: 0.5,
            padding: 8,
            margin: 16,
          }}
          align="end"
        >
          <Flex
            direction="row"
            justify="start"
            align="start"
            style={{
              alignSelf: 'flex-start',
            }}
          >
            <LFText
              style={{
                fontSize: 24,
                fontWeight: 'normal',
                color: '#000000',
              }}
            >
              🖐
            </LFText>
            <LFText
              style={{
                fontSize: 14,
                fontWeight: 'normal',
                fontStyle: 'normal',
                lineHeight: 24,
                letterSpacing: 0,
                textAlign: 'left',
                color: colors.TEXT_80,
              }}
            >
              이미 학습한 내용이 포함되어 있습니다.{'\n'}이 러닝패스를 이어서
              학습할까요?
            </LFText>
          </Flex>
          {isMutatingLearningHistory ? (
            <ActivityIndicator color={'gray'} />
          ) : (
            <LFLink
              testID="learningpath-start-when-already-completed-card:TraversalScreen"
              style={{
                // borderRadius: 5,
                // padding: 5,
                // borderColor: '#260101',
                flexDirection: 'row',
              }}
              onPress={() => {
                commitLearningHistory('in_progress')
                  .then(() => {
                    // alert('완료 처리 성공');
                  })
                  .catch((error) => console.error('#ERR', error));
              }}
            >
              <Image
                accessibilityLabel=""
                style={{ width: 24, height: 24 }}
                source={require('../assets/images/icons/icon24Check@3x.png')}
              />
              <LFText
                style={{
                  fontSize: 14,
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 24,
                  letterSpacing: 0,
                  textAlign: 'right',
                  color: colors.TEXT_DARK,
                }}
              >
                이 러닝패스 학습하기
              </LFText>
            </LFLink>
          )}
        </Flex>
      ) : null}
    </>
  );
};

const PathNodeView = ({ idx = 0 }) => {
  let { url } = useRouteMatch();
  let { pathId } = useParams<TraversalScreenParamList>();

  const { path } = useLazyLoadQuery<TraversalScreenPathNodeQuery>(
    graphql`
      query TraversalScreenPathNodeQuery($pathId: ID!) {
        path(id: $pathId) {
          id
          objectId
          title
          learnCount
          published
          author {
            id
            objectId
            name
          }
          tags {
            ... on Element {
              value
            }
          }
          contentItemList {
            ... on ContentItem {
              id
              objectId
              title
              content {
                id
                objectId
                thumbURL
              }
              ...ContentItemView_Fragment
            }
          }
        }
      }
    `,
    { pathId: pathId }
  );

  return (
    <Flex direction="column" align="start">
      <View
        style={{
          position: 'absolute',
          left: 17,
          width: 6,
          height: '100%',
          backgroundColor: '#ccc',
        }}
      />
      <Flex direction="row" style={{ marginVertical: 5 }}>
        <View
          style={{
            height: 40,
            width: 40,
            borderRadius: 20,
            backgroundColor: '#ccc',
            marginRight: 10,
          }}
        ></View>
        {/* <LFText>{idx + 1}.</LFText> */}
        <LFText selectable>{path.title}</LFText>
      </Flex>
      <Switch>
        <Route path={`${url}/:pathId`}>
          <PathNodeView idx={idx + 1} />
        </Route>
      </Switch>
    </Flex>
  );
};

export default TraversalScreen;

const styles = StyleSheet.create({
  title: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 36,
    color: '#1A1B1E',
    // wordBreak이 ts 에러 발생시킴
    // @ts-ignore
    wordBreak: 'keep-all',
  },
  pathDescription: {
    fontWeight: '400',
    fontSize: 14,
    color: '#333',
    opacity: 0.4,
  },
  itemHeadDesc: {
    fontSize: 16,
    color: '#333',
    fontWeight: '400',
  },
  thumbnail: {
    // box-sizing이 ts에러 발생시킴
    // @ts-ignore
    boxSizing: 'border-box',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  followButton: {
    marginLeft: 'auto',
    backgroundColor: '#236EFF',
    borderWidth: 1,
    borderColor: '#236EFF',
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  followingButton: {
    marginLeft: 'auto',
    borderWidth: 1,
    borderColor: 'rgba(26, 27, 30, 0.1)',
    backgroundColor: 'rgba(0,0,0,0)',
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonLabel: {
    color: 'white',

    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 24,
  },
  learningStatus: {
    justifyContent: 'center',
    width: 'max-content',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: '#F0F1F7',
    borderRadius: 100,
  },
  learningStatusLabel: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    lineHeight: 20,
    textTransform: 'uppercase',
    color: '#1A1B1E',
  },
  badgeItem: {
    padding: 4,
    backgroundColor: '#F0F1F6',
    borderRadius: 100,
  },
  badgeLabel: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    lineHeight: 16,
    // transform이 ts 에러 발생시킴
    // @ts-ignore
    textFransform: 'uppercase',
    color: '#1A1B1E',
  },
  badgeLogo: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 2,
  },
  badgeImage: {
    width: 10,
    height: 10,
  },
  salesPointList: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 27,
    color: '#1A1B1E',
  },
  salesPointItem: {
    paddingHorizontal: 8,
    paddingVertical: 12,
    backgroundColor: '#F6F6FA',
    borderRadius: 8,
  },
  meritList: {
    marginVertical: -4,
  },
  meritItem: { paddingVertical: 4 },
  meritTitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 24,
    color: 'rgba(26, 27, 30, 0.6)',
    paddingHorizontal: 12,
  },
  meritDescription: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: 24,
  },
  borderLine: {
    height: 1,
    borderTopWidth: 1,
    borderColor: 'rgba(26, 27, 30, 0.1)',
  },
  reactionQustionTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: '#1A1B1E',
    marginBottom: 4,
    paddingHorizontal: 12,
  },
  reactionQustionAdditionalLabel: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 18,
    color: 'rgba(26, 27, 30, 0.6)',
    marginBottom: 4,
    paddingHorizontal: 12,
  },
  reactionItem: { alignItems: 'center' },
  reactionEmoji: { fontSize: 32 },
  reactionLabel: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,

    textAlign: 'center',
    color: 'rgba(26, 27, 30, 0.6)',
  },
  reactionCount: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 29,
    textAlign: 'left',
    color: 'rgba(26, 27, 30, 0.8)',
    // wordBreak css속성을 못잡아내서 ts 오류 발생
    // @ts-ignore
    wordBreak: 'keep-all',
  },
  progressTitle: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 20,
    textAlign: 'left',
    color: 'rgba(26, 27, 30, 0.8)',
  },
  progressNumber: {
    color: 'rgba(26, 27, 30, 0.3)',
  },
  progressPercentage: {
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 36,
    color: 'rgba(26, 27, 30, 0.8)',
  },
  progressBar: {
    position: 'relative',
    width: '100%',
    height: 8,
    backgroundColor: '   rgba(26, 27, 30, 0.1)',
    borderRadius: 24,
  },
  progressFill: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#236EFF',
    borderRadius: 24,
  },
  linkStyle: { color: '#236EFF', textDecorationLine: 'underline' },
});
