/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TraversalScreenDeleteCommentMutationVariables = {
    id: string;
};
export type TraversalScreenDeleteCommentMutationResponse = {
    readonly deleteComment: {
        readonly comment: {
            readonly id: string;
            readonly objectId: string;
        };
    } | null;
};
export type TraversalScreenDeleteCommentMutation = {
    readonly response: TraversalScreenDeleteCommentMutationResponse;
    readonly variables: TraversalScreenDeleteCommentMutationVariables;
};



/*
mutation TraversalScreenDeleteCommentMutation(
  $id: ID!
) {
  deleteComment(input: {id: $id}) {
    comment {
      id
      objectId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteCommentPayload",
    "kind": "LinkedField",
    "name": "deleteComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "comment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TraversalScreenDeleteCommentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TraversalScreenDeleteCommentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b447fbe299b72187886c652ef1f90b81",
    "id": null,
    "metadata": {},
    "name": "TraversalScreenDeleteCommentMutation",
    "operationKind": "mutation",
    "text": "mutation TraversalScreenDeleteCommentMutation(\n  $id: ID!\n) {\n  deleteComment(input: {id: $id}) {\n    comment {\n      id\n      objectId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd43b699d62b4a606131cbc335755832a';
export default node;
