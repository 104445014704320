/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PathOrder = "ACL_ASC" | "ACL_DESC" | "analytics_ASC" | "analytics_DESC" | "author_ASC" | "author_DESC" | "benefits_ASC" | "benefits_DESC" | "contentItemList_ASC" | "contentItemList_DESC" | "coverImage_ASC" | "coverImage_DESC" | "createdAt_ASC" | "createdAt_DESC" | "description_ASC" | "description_DESC" | "draft_ASC" | "draft_DESC" | "estimate_ASC" | "estimate_DESC" | "id_ASC" | "id_DESC" | "instructionCards_ASC" | "instructionCards_DESC" | "isSeries_ASC" | "isSeries_DESC" | "learnCount_ASC" | "learnCount_DESC" | "membership_ASC" | "membership_DESC" | "objectId_ASC" | "objectId_DESC" | "prerequisites_ASC" | "prerequisites_DESC" | "published_ASC" | "published_DESC" | "tags_ASC" | "tags_DESC" | "target_ASC" | "target_DESC" | "title_ASC" | "title_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "%future added value";
export type PathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    contentItemList?: ArrayWhereInput | null;
    author?: UserRelationWhereInput | null;
    learnCount?: NumberWhereInput | null;
    published?: BooleanWhereInput | null;
    description?: StringWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    analytics?: AnalyticsPathRelationWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    draft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathWhereInput> | null;
    AND?: Array<PathWhereInput> | null;
    NOR?: Array<PathWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type UserRelationWhereInput = {
    have?: UserWhereInput | null;
    haveNot?: UserWhereInput | null;
    exists?: boolean | null;
};
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type InstructionCardRelationWhereInput = {
    have?: InstructionCardWhereInput | null;
    haveNot?: InstructionCardWhereInput | null;
    exists?: boolean | null;
};
export type InstructionCardWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    path?: unknown | null;
    sources?: ArrayWhereInput | null;
    seq?: NumberWhereInput | null;
    optional?: BooleanWhereInput | null;
    pathDraft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<InstructionCardWhereInput> | null;
    AND?: Array<InstructionCardWhereInput> | null;
    NOR?: Array<InstructionCardWhereInput> | null;
};
export type PathDraftRelationWhereInput = {
    have?: PathDraftWhereInput | null;
    haveNot?: PathDraftWhereInput | null;
    exists?: boolean | null;
};
export type PathDraftWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    original?: PathRelationWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    description?: StringWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    progressing?: BooleanWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathDraftWhereInput> | null;
    AND?: Array<PathDraftWhereInput> | null;
    NOR?: Array<PathDraftWhereInput> | null;
};
export type PathRelationWhereInput = {
    have?: PathWhereInput | null;
    haveNot?: PathWhereInput | null;
    exists?: boolean | null;
};
export type MembershipRelationWhereInput = {
    have?: MembershipWhereInput | null;
    haveNot?: MembershipWhereInput | null;
    exists?: boolean | null;
};
export type MembershipWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    level?: StringWhereInput | null;
    role?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<MembershipWhereInput> | null;
    AND?: Array<MembershipWhereInput> | null;
    NOR?: Array<MembershipWhereInput> | null;
};
export type RoleRelationWhereInput = {
    have?: RoleWhereInput | null;
    haveNot?: RoleWhereInput | null;
    exists?: boolean | null;
};
export type RoleWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    name?: StringWhereInput | null;
    users?: UserRelationWhereInput | null;
    roles?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<RoleWhereInput> | null;
    AND?: Array<RoleWhereInput> | null;
    NOR?: Array<RoleWhereInput> | null;
};
export type AnalyticsPathRelationWhereInput = {
    have?: AnalyticsPathWhereInput | null;
    haveNot?: AnalyticsPathWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsPathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    path?: PathRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsPathWhereInput> | null;
    AND?: Array<AnalyticsPathWhereInput> | null;
    NOR?: Array<AnalyticsPathWhereInput> | null;
};
export type RendertronPathListScreenQueryVariables = {
    where: PathWhereInput;
    order?: Array<PathOrder> | null;
    skip: number;
    first: number;
};
export type RendertronPathListScreenQueryResponse = {
    readonly paths: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
            } | null;
        } | null> | null;
    };
};
export type RendertronPathListScreenQuery = {
    readonly response: RendertronPathListScreenQueryResponse;
    readonly variables: RendertronPathListScreenQueryVariables;
};



/*
query RendertronPathListScreenQuery(
  $where: PathWhereInput!
  $order: [PathOrder!]
  $skip: Int!
  $first: Int!
) {
  paths(where: $where, skip: $skip, first: $first, order: $order) {
    count
    edges {
      node {
        id
        objectId
        title
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "skip",
        "variableName": "skip"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "PathConnection",
    "kind": "LinkedField",
    "name": "paths",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PathEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Path",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RendertronPathListScreenQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RendertronPathListScreenQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "87bdbedaade6b13c7542611ca9a46f9a",
    "id": null,
    "metadata": {},
    "name": "RendertronPathListScreenQuery",
    "operationKind": "query",
    "text": "query RendertronPathListScreenQuery(\n  $where: PathWhereInput!\n  $order: [PathOrder!]\n  $skip: Int!\n  $first: Int!\n) {\n  paths(where: $where, skip: $skip, first: $first, order: $order) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cb558a9b3e0d7d6f434f555528806803';
export default node;
