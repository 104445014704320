/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InstructionCardSourcePathQueryVariables = {
    id: string;
    empty: boolean;
};
export type InstructionCardSourcePathQueryResponse = {
    readonly selectedPath?: {
        readonly id: string;
        readonly __typename: string;
        readonly title: string | null;
        readonly description: string | null;
        readonly objectId: string;
        readonly author: {
            readonly id: string;
            readonly objectId: string;
            readonly name: string | null;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"PathPreviewFragment">;
    };
};
export type InstructionCardSourcePathQuery = {
    readonly response: InstructionCardSourcePathQueryResponse;
    readonly variables: InstructionCardSourcePathQueryVariables;
};



/*
query InstructionCardSourcePathQuery(
  $id: ID!
  $empty: Boolean!
) {
  selectedPath: path(id: $id) @skip(if: $empty) {
    id
    __typename
    title
    description
    objectId
    author {
      id
      objectId
      name
    }
    ...PathPreviewFragment
  }
}

fragment ContentPreviewFragment on Content {
  __typename
  id
  objectId
  type
  thumbURL
  link
  title
}

fragment PathPreviewFragment on Path {
  id
  objectId
  title
  coverImage {
    url
  }
  author {
    name
    id
    objectId
    ...UserProfileImageFragment
  }
  instructionCards(order: [seq_ASC, createdAt_ASC]) {
    edges {
      node {
        id
        objectId
        optional
        sources {
          __typename
          ... on ContentItem {
            content {
              id
              ...ContentPreviewFragment
            }
          }
          ... on Path {
            id
            title
            coverImage {
              url
            }
            author {
              id
              name
            }
          }
          ... on Quiz {
            id
            title
          }
          ... on Node {
            __isNode: __typename
            id
          }
        }
      }
    }
  }
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "empty"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "coverImage",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InstructionCardSourcePathQuery",
    "selections": [
      {
        "condition": "empty",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "selectedPath",
            "args": (v2/*: any*/),
            "concreteType": "Path",
            "kind": "LinkedField",
            "name": "path",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PathPreviewFragment"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InstructionCardSourcePathQuery",
    "selections": [
      {
        "condition": "empty",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "selectedPath",
            "args": (v2/*: any*/),
            "concreteType": "Path",
            "kind": "LinkedField",
            "name": "path",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "originProfileURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileInfo",
                    "kind": "LinkedField",
                    "name": "profileImage",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": [
                      "seq_ASC",
                      "createdAt_ASC"
                    ]
                  }
                ],
                "concreteType": "InstructionCardConnection",
                "kind": "LinkedField",
                "name": "instructionCards",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructionCardEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructionCard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "optional",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "sources",
                            "plural": true,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Content",
                                    "kind": "LinkedField",
                                    "name": "content",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v4/*: any*/),
                                      (v7/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "type",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "thumbURL",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "link",
                                        "storageKey": null
                                      },
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "ContentItem",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/),
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "author",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "Path",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "type": "Quiz",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "instructionCards(order:[\"seq_ASC\",\"createdAt_ASC\"])"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b538bcf5dba36ded1d9ad62571e01d6b",
    "id": null,
    "metadata": {},
    "name": "InstructionCardSourcePathQuery",
    "operationKind": "query",
    "text": "query InstructionCardSourcePathQuery(\n  $id: ID!\n  $empty: Boolean!\n) {\n  selectedPath: path(id: $id) @skip(if: $empty) {\n    id\n    __typename\n    title\n    description\n    objectId\n    author {\n      id\n      objectId\n      name\n    }\n    ...PathPreviewFragment\n  }\n}\n\nfragment ContentPreviewFragment on Content {\n  __typename\n  id\n  objectId\n  type\n  thumbURL\n  link\n  title\n}\n\nfragment PathPreviewFragment on Path {\n  id\n  objectId\n  title\n  coverImage {\n    url\n  }\n  author {\n    name\n    id\n    objectId\n    ...UserProfileImageFragment\n  }\n  instructionCards(order: [seq_ASC, createdAt_ASC]) {\n    edges {\n      node {\n        id\n        objectId\n        optional\n        sources {\n          __typename\n          ... on ContentItem {\n            content {\n              id\n              ...ContentPreviewFragment\n            }\n          }\n          ... on Path {\n            id\n            title\n            coverImage {\n              url\n            }\n            author {\n              id\n              name\n            }\n          }\n          ... on Quiz {\n            id\n            title\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = 'ea8a0abd2414af1c173f5fca6d4bb385';
export default node;
