import { Flex } from '@ant-design/react-native';
import Ionicons from '@expo/vector-icons/build/Ionicons';
import React from 'react';
import { Image, StyleSheet, View, ViewStyle } from 'react-native';
import { colors } from '../../constants/styleGuide';
import { LinearGradient } from 'expo-linear-gradient';

const NodeCircle: React.FC<{
  status: 'highlight' | 'done' | string;
  style?: ViewStyle;
  hideLine?: boolean;
  hideCircle?: boolean;
  optional?: boolean | null;
}> = ({ status, style, hideLine, hideCircle, optional }) => {
  return (
    <Flex
      direction="column"
      align="center"
      style={[{ alignSelf: 'stretch', width: 20 }, style]}
    >
      {!hideCircle && !optional ? (
        <WhiteCircleWrap>
          {status === 'highlight' ? (
            <View style={styles.highlightCircle} />
          ) : status === 'done' ? (
            <Image
              accessibilityLabel=""
              style={{ height: 20, width: 20 }}
              source={require('../../assets/images/studyState@3x.png')}
            />
          ) : (
            <View style={styles.defaultCircle} />
          )}
        </WhiteCircleWrap>
      ) : null}

      {!hideLine ? (
        <>
          {status === 'done' ? (
            <View
              style={[
                styles.verticalBar,
                { backgroundColor: colors.BORDER_40 },
              ]}
            />
          ) : status === 'highlight' ? (
            <LinearGradient
              style={styles.verticalBar}
              colors={['#236EFF', '#236EFF', '#ABBDE0']}
              locations={[0, 0.65, 1]}
            />
          ) : (
            <View
              style={[
                styles.verticalBar,
                { backgroundColor: colors.BORDER_20 },
              ]}
            />
          )}
        </>
      ) : null}
      {optional ? (
        <View
          style={{
            width: 0,
            flex: 1,
            borderStyle: 'dashed',
            borderWidth: 1,
            position: 'absolute',
            left: 37,
            top: 0,
            bottom: 0,
            borderColor: colors.BORDER_20,
          }}
        ></View>
      ) : null}
    </Flex>
  );
};

export default NodeCircle;

const styles = StyleSheet.create({
  verticalBar: {
    width: 2,
    flex: 1,
  },
  defaultCircle: {
    width: 20,
    height: 20,
    backgroundColor: colors.SECONDARY_WHITE,
    borderWidth: 6,
    borderRadius: 10,
    borderColor: colors.BG_4,
  },
  highlightCircle: {
    width: 20,
    height: 20,
    backgroundColor: colors.SECONDARY_WHITE,
    borderStyle: 'solid',
    borderWidth: 6,
    borderRadius: 10,
    borderColor: colors.PRIMARY_100,
  },
});

const WhiteCircleWrap: React.FC = ({ children }) => (
  <View
    style={{
      marginTop: -4,
      borderColor: 'white',
      borderWidth: 4,
      borderRadius: 12,
    }}
  >
    {children}
  </View>
);
