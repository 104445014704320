import { Ionicons } from '@expo/vector-icons';
import _ from 'lodash';
import React from 'react';
import { StyleSheet, TouchableOpacity, Text, View } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';
import { ContentNoteItem } from '../hooks/contentItem';
import LFLink from './LFLink';
import LFText from './typo/LFText';

export interface TimeNoteProps extends ContentNoteItem {
  onPress: (item: ContentNoteItem) => void;
  editable?: boolean;
  selected?: boolean;
  status?: 'playing' | 'played' | 'none';
  onPressDeleteButton?: () => void;
  onPressNote?: () => void;
}

const TimeNote: React.FC<TimeNoteProps> = React.memo(
  ({
    note,
    time,
    onPress,
    status = 'none',
    selected = false,
    editable = false,
    onPressDeleteButton = () => {},
    onPressNote = () => {},
  }) => {
    return (
      <View
        style={[
          styles.timeNoteWrap,
          selected ? styles.selectedWrap : null,
          status === 'played' ? styles.statusPlayed : null,
        ]}
      >
        {_.isNumber(time) ? (
          <LFLink
            testID="time-stamp:TimeNote"
            onPress={() => {
              onPress({ note, time });
            }}
          >
            <LFText style={styles.timeNoteTime}>{secToHHMMSS(time)}</LFText>
          </LFLink>
        ) : null}

        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={onPressNote}
          disabled={!editable}
        >
          <LFText style={styles.timeNoteContent}>{note}</LFText>
        </TouchableOpacity>
        {editable ? (
          <TouchableOpacity
            style={{ alignSelf: 'flex-start' }}
            onPress={() => {
              onPressDeleteButton();
            }}
          >
            <Ionicons name="trash-outline" size={20} color="black" />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
);

export default TimeNote;

const styles = StyleSheet.create({
  selectedWrap: {
    backgroundColor: '#ccc',
    shadowColor: '#ccc',
    shadowOpacity: 0.5,
    shadowRadius: 5,
    shadowOffset: { height: 0, width: 0 },
  },
  statusPlayed: { borderLeftColor: 'blue', borderLeftWidth: 3 },
  timeNoteTime: {
    width: 60,
    fontFamily: 'space-mono',
    color: '#295ECC',
    fontWeight: 'bold',
  },
  timeNoteContent: {
    flex: 1,
    lineHeight: 20,
  },
  timeNoteWrap: {
    flexDirection: 'row',
    paddingVertical: 5,
    paddingLeft: 5,
    // justifyContent: 'flex-start'
  },
});

export const secToHHMMSS = (secs: number) => {
  var sec_num = secs; //parseInt(secs, 10)
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};
