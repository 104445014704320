/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AnalyticsContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    contentItem?: ContentItemRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentItemWhereInput> | null;
    AND?: Array<AnalyticsContentItemWhereInput> | null;
    NOR?: Array<AnalyticsContentItemWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type ContentItemRelationWhereInput = {
    have?: ContentItemWhereInput | null;
    haveNot?: ContentItemWhereInput | null;
    exists?: boolean | null;
};
export type ContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    content?: ContentRelationWhereInput | null;
    noteData?: ArrayWhereInput | null;
    tags?: ArrayWhereInput | null;
    published?: BooleanWhereInput | null;
    md?: StringWhereInput | null;
    analytics?: AnalyticsContentItemRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentItemWhereInput> | null;
    AND?: Array<ContentItemWhereInput> | null;
    NOR?: Array<ContentItemWhereInput> | null;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type UserRelationWhereInput = {
    have?: UserWhereInput | null;
    haveNot?: UserWhereInput | null;
    exists?: boolean | null;
};
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ContentRelationWhereInput = {
    have?: ContentWhereInput | null;
    haveNot?: ContentWhereInput | null;
    exists?: boolean | null;
};
export type ContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    thumbURL?: StringWhereInput | null;
    type?: StringWhereInput | null;
    link?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    rawMetadata?: ObjectWhereInput | null;
    analytics?: AnalyticsContentRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentWhereInput> | null;
    AND?: Array<ContentWhereInput> | null;
    NOR?: Array<ContentWhereInput> | null;
};
export type AnalyticsContentRelationWhereInput = {
    have?: AnalyticsContentWhereInput | null;
    haveNot?: AnalyticsContentWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    content?: ContentRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    contentItemCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    contentItemAuthors?: ArrayWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentWhereInput> | null;
    AND?: Array<AnalyticsContentWhereInput> | null;
    NOR?: Array<AnalyticsContentWhereInput> | null;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type AnalyticsContentItemRelationWhereInput = {
    have?: AnalyticsContentItemWhereInput | null;
    haveNot?: AnalyticsContentItemWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsPathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    path?: PathRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsPathWhereInput> | null;
    AND?: Array<AnalyticsPathWhereInput> | null;
    NOR?: Array<AnalyticsPathWhereInput> | null;
};
export type PathRelationWhereInput = {
    have?: PathWhereInput | null;
    haveNot?: PathWhereInput | null;
    exists?: boolean | null;
};
export type PathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    contentItemList?: ArrayWhereInput | null;
    author?: UserRelationWhereInput | null;
    learnCount?: NumberWhereInput | null;
    published?: BooleanWhereInput | null;
    description?: StringWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    analytics?: AnalyticsPathRelationWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    draft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathWhereInput> | null;
    AND?: Array<PathWhereInput> | null;
    NOR?: Array<PathWhereInput> | null;
};
export type InstructionCardRelationWhereInput = {
    have?: InstructionCardWhereInput | null;
    haveNot?: InstructionCardWhereInput | null;
    exists?: boolean | null;
};
export type InstructionCardWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    path?: unknown | null;
    sources?: ArrayWhereInput | null;
    seq?: NumberWhereInput | null;
    optional?: BooleanWhereInput | null;
    pathDraft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<InstructionCardWhereInput> | null;
    AND?: Array<InstructionCardWhereInput> | null;
    NOR?: Array<InstructionCardWhereInput> | null;
};
export type PathDraftRelationWhereInput = {
    have?: PathDraftWhereInput | null;
    haveNot?: PathDraftWhereInput | null;
    exists?: boolean | null;
};
export type PathDraftWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    original?: PathRelationWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    description?: StringWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    progressing?: BooleanWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathDraftWhereInput> | null;
    AND?: Array<PathDraftWhereInput> | null;
    NOR?: Array<PathDraftWhereInput> | null;
};
export type MembershipRelationWhereInput = {
    have?: MembershipWhereInput | null;
    haveNot?: MembershipWhereInput | null;
    exists?: boolean | null;
};
export type MembershipWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    level?: StringWhereInput | null;
    role?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<MembershipWhereInput> | null;
    AND?: Array<MembershipWhereInput> | null;
    NOR?: Array<MembershipWhereInput> | null;
};
export type RoleRelationWhereInput = {
    have?: RoleWhereInput | null;
    haveNot?: RoleWhereInput | null;
    exists?: boolean | null;
};
export type RoleWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    name?: StringWhereInput | null;
    users?: UserRelationWhereInput | null;
    roles?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<RoleWhereInput> | null;
    AND?: Array<RoleWhereInput> | null;
    NOR?: Array<RoleWhereInput> | null;
};
export type AnalyticsPathRelationWhereInput = {
    have?: AnalyticsPathWhereInput | null;
    haveNot?: AnalyticsPathWhereInput | null;
    exists?: boolean | null;
};
export type MyStaticsPathAndNoteQueryVariables = {
    noteCount: number;
    pathCount: number;
    noteWhere: AnalyticsContentItemWhereInput;
    pathWhere: AnalyticsPathWhereInput;
};
export type MyStaticsPathAndNoteQueryResponse = {
    readonly analyticsContentItems: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly viewCount: number | null;
                readonly emojiTotalCount: number | null;
            } | null;
        } | null> | null;
    };
    readonly analyticsPaths: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly viewCount: number | null;
                readonly emojiTotalCount: number | null;
            } | null;
        } | null> | null;
    };
};
export type MyStaticsPathAndNoteQuery = {
    readonly response: MyStaticsPathAndNoteQueryResponse;
    readonly variables: MyStaticsPathAndNoteQueryVariables;
};



/*
query MyStaticsPathAndNoteQuery(
  $noteCount: Int!
  $pathCount: Int!
  $noteWhere: AnalyticsContentItemWhereInput!
  $pathWhere: AnalyticsPathWhereInput!
) {
  analyticsContentItems(first: $noteCount, where: $noteWhere) {
    edges {
      node {
        id
        objectId
        viewCount
        emojiTotalCount
      }
    }
  }
  analyticsPaths(first: $pathCount, where: $pathWhere) {
    edges {
      node {
        id
        objectId
        viewCount
        emojiTotalCount
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "noteCount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "noteWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathCount"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathWhere"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "emojiTotalCount",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "noteCount"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "noteWhere"
      }
    ],
    "concreteType": "AnalyticsContentItemConnection",
    "kind": "LinkedField",
    "name": "analyticsContentItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AnalyticsContentItemEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalyticsContentItem",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "pathCount"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "pathWhere"
      }
    ],
    "concreteType": "AnalyticsPathConnection",
    "kind": "LinkedField",
    "name": "analyticsPaths",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AnalyticsPathEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalyticsPath",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyStaticsPathAndNoteQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyStaticsPathAndNoteQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "9a0291f0afd46b6b179ae5aa2fffac6c",
    "id": null,
    "metadata": {},
    "name": "MyStaticsPathAndNoteQuery",
    "operationKind": "query",
    "text": "query MyStaticsPathAndNoteQuery(\n  $noteCount: Int!\n  $pathCount: Int!\n  $noteWhere: AnalyticsContentItemWhereInput!\n  $pathWhere: AnalyticsPathWhereInput!\n) {\n  analyticsContentItems(first: $noteCount, where: $noteWhere) {\n    edges {\n      node {\n        id\n        objectId\n        viewCount\n        emojiTotalCount\n      }\n    }\n  }\n  analyticsPaths(first: $pathCount, where: $pathWhere) {\n    edges {\n      node {\n        id\n        objectId\n        viewCount\n        emojiTotalCount\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8060d7ce31e24ccc72a5ec039a9105d7';
export default node;
