/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type QuizFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly title: string | null;
    readonly options: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly correctIndexes: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly " $refType": "QuizFragment";
};
export type QuizFragment$data = QuizFragment;
export type QuizFragment$key = {
    readonly " $data"?: QuizFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"QuizFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "type": "Element",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "options",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "correctIndexes",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Quiz",
  "abstractKey": null
};
})();
(node as any).hash = 'd5397f9c81d26e2304f0c1e3e6e7e690';
export default node;
