import * as React from 'react';
import {
  useState,
  useEffect,
  Suspense,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Ionicons } from '@expo/vector-icons';
import {
  WhiteSpace,
  Flex,
  Modal,
  WingBlank,
  Toast,
} from '@ant-design/react-native';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  ScrollView,
  Switch,
  TextInput,
  Image,
  ActivityIndicator,
} from 'react-native';
import NavBar, { HistoryBackButton } from '../components/NavBar';
import { useHistory, useLocation, useParams } from 'react-router';
import _, { max } from 'lodash';
import ItemSelectorScreen, {
  contentItemFragmentQuery,
} from './ItemSelectorScreen';
import LFLink from '../components/LFLink';

import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';
import { useAuth } from '../hooks/auth';
import { PathEditorScreenPathQuery } from '../__generated__/PathEditorScreenPathQuery.graphql';

import { ItemSelectorScreen_Fragment$key } from '../__generated__/ItemSelectorScreen_Fragment.graphql';
import SuspenseFallbackScreen from './SuspenseFallbackScreen';
import MultilineGrowingTextInput from '../components/MultilineGrowingTextInput';
import {
  CreateInstructionCardFieldsInput,
  PathEditorScreenCreatePathMutation,
} from '../__generated__/PathEditorScreenCreatePathMutation.graphql';
import {
  PathEditorScreenUpdatePathMutation,
  PathEditorScreenUpdatePathMutationResponse,
  UpdatePathFieldsInput,
} from '../__generated__/PathEditorScreenUpdatePathMutation.graphql';
import {
  PathEditorScreenUpdateInstructionCardMutation,
  PathEditorScreenUpdateInstructionCardMutationResponse,
  UpdateInstructionCardFieldsInput,
} from '../__generated__/PathEditorScreenUpdateInstructionCardMutation.graphql';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { ReactElement } from 'react-router/node_modules/@types/react';
import SearchScreen from './SearchScreen';
import { useBrowserTitleUpdateEffect } from '../hooks/browserTitle';
import LFMetaTags from '../components/LFMetaTags';
import LFText from '../components/typo/LFText';
import LFWhiteSpace from '../components/LFWhiteSpace';
import { useMobileDimensions } from '../hooks/useMobileDimensions';
import { PathEditorScreenMembershipQuery } from '../__generated__/PathEditorScreenMembershipQuery.graphql';
import FileInput from '../components/FileInput';
import { colors } from '../constants/styleGuide';
import { UploadableMap } from 'relay-runtime';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import {
  CreateQuizFieldsInput,
  PathEditorScreenCreateQuizMutation,
  PathEditorScreenCreateQuizMutationResponse,
} from '../__generated__/PathEditorScreenCreateQuizMutation.graphql';
import {
  PathEditorScreenUpdateQuizMutation,
  PathEditorScreenUpdateQuizMutationResponse,
  UpdateQuizFieldsInput,
} from '../__generated__/PathEditorScreenUpdateQuizMutation.graphql';
import InstructionCard from './PathEditorScreen/InstructionCard';
import { InstructionCardSourcePathQueryResponse } from '../__generated__/InstructionCardSourcePathQuery.graphql';
import { InstructionCardSourceContentItemQueryResponse } from '../__generated__/InstructionCardSourceContentItemQuery.graphql';
import {
  PathEditorScreenPathDeleteMutation,
  PathEditorScreenPathDeleteMutationResponse,
} from '../__generated__/PathEditorScreenPathDeleteMutation.graphql';
import {
  PathEditorScreenCreateInstructionCardMutation,
  PathEditorScreenCreateInstructionCardMutationResponse,
} from '../__generated__/PathEditorScreenCreateInstructionCardMutation.graphql';
import {
  PathEditorScreenInstructionCardDeleteMutation,
  PathEditorScreenInstructionCardDeleteMutationResponse,
} from '../__generated__/PathEditorScreenInstructionCardDeleteMutation.graphql';
import {
  PathEditorScreenQuizDeleteMutation,
  PathEditorScreenQuizDeleteMutationResponse,
} from '../__generated__/PathEditorScreenQuizDeleteMutation.graphql';

const MAX_FILE_SIZE = 1000 * 1000 * 1; // 단위 MB

// 참고 자료
// https://github.com/relay-tools/relay-hooks/issues/57
//

export type InstructionCardKeys = keyof InstructionCardProp;
interface SourceProp {
  __type: 'Pointer';
  objectId: string;
  className: 'ContentItem' | 'Path' | 'Quiz';
}
interface InstructionCardProp {
  // id: string;
  // objectId: string;
  // sources: SourceProp[];
  description: string;
  optional: boolean;
  // path: PathPointerInput;
  seq: number;
}

const PathEditorScreen: React.FC = () => {
  // 사이트 나가기 전 팝업 띄워서 물어보는 용도
  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  // 주어지는 정보들
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const userObjectId = user?.objectId;
  const { pathId } = useParams<{ pathId: string }>();
  const mobileDimensions = useMobileDimensions();
  const modalHeight = mobileDimensions.height - 66;

  // hooks
  const { showActionSheetWithOptions } = useActionSheet();
  const [isSearchSelectorVisible, setIsSearchSelectorVisible] = useState(false);
  const [isMySelectorVisible, setIsMySelectorVisible] = useState(false);
  const [currentCardId, setCurrentCardId] = useState<number | null>(null);
  // relay
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2]
  >({
    // 함수의 params를 typing : https://www.typescriptlang.org/docs/handbook/utility-types.html#parameterstype
    // fetchPolicy: 'store-and-network',
    fetchKey: 0,
  });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      //@ts-ignore
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'store-and-network',
    }));
  }, [setRefreshedQueryOptions]);

  useEffect(() => {
    if (!pathId) {
      commitCreatePath({
        variables: {
          fields: {
            tags: ['전체'],
          },
          clientMutationId: 'only-first-please',
        },
        onCompleted: ({ createPath }) => {
          const pathObjectId = createPath?.path.objectId;
          if (pathObjectId) {
            history.replace(location.pathname + '/' + pathObjectId);
          }
        },
      });
    }
  }, []);

  // relay queries
  const { path } = useLazyLoadQuery<PathEditorScreenPathQuery>(
    graphql`
      query PathEditorScreenPathQuery($pathId: ID!, $skip: Boolean!) {
        path(id: $pathId) @skip(if: $skip) {
          id
          objectId
          title
          learnCount
          published
          description
          isSeries
          draft {
            id
            objectId
            instructionCards {
              edges {
                node {
                  id
                  objectId
                }
              }
            }
          }
          benefits {
            ... on Element {
              value
            }
          }
          prerequisites {
            ... on Element {
              value
            }
          }
          target
          estimate
          coverImage {
            url
          }
          author {
            id
            objectId
            name
          }
          tags {
            ... on Element {
              value
            }
          }
          membership {
            id
            objectId
          }
          instructionCards(order: [seq_ASC, createdAt_ASC]) {
            edges {
              node {
                id
                seq
                objectId
                description
                optional
                sources {
                  __typename
                  ... on ContentItem {
                    id
                    objectId
                    title
                    published
                    content {
                      __typename
                      id
                      objectId
                      ...ContentPreviewFragment
                    }
                    author {
                      objectId
                      name
                    }
                  }
                  ... on Content {
                    id
                    objectId
                    thumbURL
                    title
                    link
                    type
                    ...ContentPreviewFragment
                  }
                  ... on Path {
                    id
                    objectId
                    title
                    description
                    author {
                      objectId
                      name
                    }
                    ...PathPreviewFragment
                  }
                  ... on Quiz {
                    objectId
                    title
                    correctIndexes {
                      ... on Element {
                        value
                      }
                    }
                    options {
                      ... on Element {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    { pathId: pathId, skip: !pathId },
    refreshedQueryOptions
  );

  const [selectedPathId, setSelectedPathId] = useState<string | null>(null);

  const [selectedContentItemId, setSelectedContentItemId] = useState<
    string | null
  >(null);

  const { memberships } = useLazyLoadQuery<PathEditorScreenMembershipQuery>(
    graphql`
      query PathEditorScreenMembershipQuery($userObjectId: ID!) {
        memberships(
          where: { author: { have: { objectId: { equalTo: $userObjectId } } } }
        ) {
          edges {
            node {
              id
              objectId
              title
            }
          }
        }
      }
    `,
    { userObjectId: user?.objectId }
  );

  const pathOwnerMemberships = memberships.edges?.map((edge) => edge?.node);

  //현재로서는 저장 방식때문에 생성과 수정을 구분하는건 데이터로만 판단히기 애매한 부분이 있으니 중의적인 의미로 "러닝패스 작성"이라고 한시적 적용
  useBrowserTitleUpdateEffect('러닝패스 작성');
  const [commitCreatePath, isCommitCreatePathInFlight] =
    useMutation<PathEditorScreenCreatePathMutation>(graphql`
      mutation PathEditorScreenCreatePathMutation(
        $fields: CreatePathFieldsInput!
        $clientMutationId: String!
      ) {
        createPath(
          input: { fields: $fields, clientMutationId: $clientMutationId }
        ) {
          clientMutationId
          path {
            id
            objectId
            title
            learnCount
            description
            author {
              id
              objectId
              name
            }
            tags {
              ... on Element {
                value
              }
            }
          }
        }
      }
    `);

  const [commitCreateQuiz, isCommitCreateQuizInFlight] =
    useMutation<PathEditorScreenCreateQuizMutation>(
      graphql`
        mutation PathEditorScreenCreateQuizMutation(
          $fields: CreateQuizFieldsInput! # $clientMutationId: String!
        ) {
          createQuiz(input: { fields: $fields }) {
            clientMutationId
            quiz {
              id
              objectId
              title
              options {
                ... on Element {
                  value
                }
              }
              correctIndexes {
                ... on Element {
                  value
                }
              }
            }
          }
        }
      `
    );

  const [commitUpdateQuiz, isCommitUpdateQuizInFlight] =
    useMutation<PathEditorScreenUpdateQuizMutation>(
      graphql`
        mutation PathEditorScreenUpdateQuizMutation(
          $objectId: ID!
          $fields: UpdateQuizFieldsInput!
        ) {
          updateQuiz(input: { id: $objectId, fields: $fields }) {
            clientMutationId
            quiz {
              id
              objectId
              title
              options {
                ... on Element {
                  value
                }
              }
              correctIndexes {
                ... on Element {
                  value
                }
              }
            }
          }
        }
      `
    );

  const [commitDeleteQuiz, isCommitQuizPathInFlight] =
    useMutation<PathEditorScreenQuizDeleteMutation>(
      graphql`
        mutation PathEditorScreenQuizDeleteMutation($id: ID!) {
          deleteQuiz(input: { id: $id }) {
            quiz {
              id
              objectId
            }
          }
        }
      `
    );

  const createQuiz = ({ fields }: { fields: CreateQuizFieldsInput }) =>
    new Promise<PathEditorScreenCreateQuizMutationResponse>(
      (onSuccess, onFail) => {
        commitCreateQuiz({
          variables: {
            fields,
          },
          onCompleted: (response, reject) => {
            if (response) {
              onSuccess(response);
              return;
            }
            onFail(reject);
          },
          onError: (error) => {
            onFail(error);
          },
        });
      }
    );
  const updateQuiz = ({
    id,
    fields,
  }: {
    id: string;
    fields: UpdateQuizFieldsInput;
  }) =>
    new Promise<PathEditorScreenUpdateQuizMutationResponse>(
      (onSuccess, onFail) => {
        commitUpdateQuiz({
          variables: {
            objectId: id,
            fields,
          },
          onCompleted: (response, reject) => {
            if (response) {
              onSuccess(response);
              return;
            }
            onFail(reject);
          },
          onError: (error) => {
            onFail(error);
          },
        });
      }
    );

  const deleteQuizById = ({ id }: { id: string | null | undefined }) =>
    new Promise<PathEditorScreenQuizDeleteMutationResponse>(
      (onSuccess, onFail) => {
        if (id) {
          commitDeleteQuiz({
            variables: { id },
            onCompleted: (response, reject) => {
              if (response) {
                onSuccess(response);
                return;
              }
              onFail(reject);
            },
            onError: onFail,
          });
          return;
        }
        onFail('Quiz 삭제 실패: id 타입이 올바르지 않습니다.');
      }
    );

  const [commitUpdatePath, isCommitUpdatePathInFlight] =
    useMutation<PathEditorScreenUpdatePathMutation>(graphql`
      mutation PathEditorScreenUpdatePathMutation(
        $objectId: ID!
        $fields: UpdatePathFieldsInput!
      ) {
        updatePath(input: { id: $objectId, fields: $fields }) {
          clientMutationId
          path {
            id
            objectId
            title
            learnCount
            description
            coverImage {
              url
              name
            }
            target
            estimate
            benefits {
              ... on Element {
                value
              }
            }
            prerequisites {
              ... on Element {
                value
              }
            }
            author {
              id
              objectId
              name
            }
            tags {
              ... on Element {
                value
              }
            }
            membership {
              id
              title
              objectId
            }
            draft {
              id
              objectId
              instructionCards {
                edges {
                  node {
                    id
                    objectId
                  }
                }
              }
            }
            instructionCards(order: [seq_ASC, createdAt_ASC]) {
              edges {
                node {
                  id
                  seq
                  objectId
                  description
                  optional
                  sources {
                    __typename
                    ... on ContentItem {
                      id
                      objectId
                      title
                      description
                      content {
                        id
                        title
                        objectId
                        thumbURL
                        type
                        ...ContentPreviewFragment
                      }
                      author {
                        objectId
                        name
                      }
                      # ...ContentItemView_Fragment
                    }
                    ... on Path {
                      id
                      objectId
                      title
                      description
                      author {
                        objectId
                        name
                      }
                    }
                    ... on Quiz {
                      id
                      objectId
                      title
                      options {
                        ... on Element {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `);

  const updatePath = ({
    id,
    fields,
    uploadables,
  }: {
    id: string | null | undefined;
    fields: UpdatePathFieldsInput;
    uploadables?: UploadableMap | undefined;
  }) =>
    new Promise<PathEditorScreenUpdatePathMutationResponse>(
      (onSuccess, onFail) => {
        if (id) {
          commitUpdatePath({
            variables: {
              objectId: id,
              fields,
            },
            uploadables,
            onCompleted: (response, reject) => {
              if (response) {
                onSuccess(response);
                return;
              }
              onFail(reject);
            },
            onError: (error) => {
              onFail(error);
            },
          });
        }
      }
    );
  const [commitDeletePath, isCommitDeletePathInFlight] =
    useMutation<PathEditorScreenPathDeleteMutation>(
      graphql`
        mutation PathEditorScreenPathDeleteMutation($id: ID!) {
          deletePath(input: { id: $id }) {
            path {
              id
              objectId
              draft {
                id
                objectId
              }
            }
          }
        }
      `
    );
  const [commitCreateInstructionCard, isCommitCreateInstructionCardInFlight] =
    useMutation<PathEditorScreenCreateInstructionCardMutation>(graphql`
      mutation PathEditorScreenCreateInstructionCardMutation(
        $fields: CreateInstructionCardFieldsInput
      ) {
        createInstructionCard(input: { fields: $fields }) {
          instructionCard {
            id
            objectId
            optional
            description
            seq
            sources {
              __typename
              ... on Path {
                id
                objectId
                title
                coverImage {
                  url
                }
              }
              ... on ContentItem {
                id
                objectId
                title
                description
                content {
                  id
                  objectId
                  thumbURL
                }
              }
              ... on Quiz {
                id
                objectId
                options {
                  ... on Element {
                    value
                  }
                }
                title
                correctIndexes {
                  ... on Element {
                    value
                  }
                }
              }
            }
          }
        }
      }
    `);
  const [commitUpdateInstructionCard, isCommitUpdateInstructionCardInFlight] =
    useMutation<PathEditorScreenUpdateInstructionCardMutation>(graphql`
      mutation PathEditorScreenUpdateInstructionCardMutation(
        $fields: UpdateInstructionCardFieldsInput!
        $objectId: ID!
      ) {
        updateInstructionCard(input: { id: $objectId, fields: $fields }) {
          instructionCard {
            seq
            description
            id
            objectId
            optional
            sources {
              __typename
              ... on ContentItem {
                id
                objectId
                title
                content {
                  id
                  title
                  objectId
                  thumbURL
                  type
                  ...ContentPreviewFragment
                }
                author {
                  objectId
                  id
                  name
                }
                # ...ContentItemView_Fragment
              }
              ... on Content {
                id
                objectId
                thumbURL
                title
                link
                type
                ...ContentPreviewFragment
              }
              ... on Path {
                id
                objectId
                title
                description
                author {
                  objectId
                  name
                }
              }
              ... on Quiz {
                id
                objectId
                title
                options {
                  ... on Element {
                    value
                  }
                }
                correctIndexes {
                  ... on Element {
                    value
                  }
                }
              }
            }
          }
        }
      }
    `);
  const [commitDeleteInstructionCard, isCommitDeleteInstructionCardInFlight] =
    useMutation<PathEditorScreenInstructionCardDeleteMutation>(
      graphql`
        mutation PathEditorScreenInstructionCardDeleteMutation($id: ID!) {
          deleteInstructionCard(input: { id: $id }) {
            instructionCard {
              id
              objectId
              sources {
                __typename
                ... on Quiz {
                  id
                  objectId
                }
              }
            }
          }
        }
      `
    );

  const createInstructionCard = ({
    fields,
  }: {
    fields: CreateInstructionCardFieldsInput;
  }) =>
    new Promise<PathEditorScreenCreateInstructionCardMutationResponse>(
      (onSuccess, onFail) => {
        commitCreateInstructionCard({
          variables: {
            fields,
          },
          onCompleted: (response, reject) => {
            if (response) {
              onSuccess(response);
              return;
            }
            onFail(reject);
          },
          onError: (error) => {
            onFail(error);
          },
        });
      }
    );
  const updateInstructionCard = ({
    id,
    fields,
  }: {
    id: string | null | undefined;
    fields: UpdateInstructionCardFieldsInput;
  }) =>
    new Promise<PathEditorScreenUpdateInstructionCardMutationResponse>(
      (onSuccess, onFail) => {
        if (id) {
          commitUpdateInstructionCard({
            variables: {
              objectId: id,
              fields,
            },
            onCompleted: (response, reject) => {
              if (response) {
                onSuccess(response);
                return;
              }
              onFail(reject);
            },
            onError: (error) => {
              onFail(error);
            },
          });
        }
      }
    );

  const deleteInstructionCardById = ({
    id,
  }: {
    id: string | null | undefined;
  }) =>
    new Promise<PathEditorScreenInstructionCardDeleteMutationResponse>(
      (onSuccess, onFail) => {
        if (id) {
          commitDeleteInstructionCard({
            variables: { id },
            onCompleted: (response, reject) => {
              if (response) {
                onSuccess(response);
                return;
              }
              onFail(reject);
            },
            onError: onFail,
          });
          return;
        }
        onFail('instructionCard 삭제 실패: id 타입이 올바르지 않습니다.');
      }
    );

  // saveValidation: 러닝패스 작성 페이지에서 어떠한 것도 입력하지 않았다면 저장 불가능
  const saveValidator = () => {
    // path가 공개상태일 경우 제목, 카드 2개 이상 존재가 동시에 만족해야만 save가 가능합니다.
    if (path?.published) {
      return title.trim() !== '' && Boolean(instructionCards.length >= 2);
    }

    // path가 비공개상태일 경우 하나의 field라도 채워져야 save가 가능합니다.
    return (
      pathImageURL ||
      title.trim() ||
      needMembership ||
      benefits.some((benefit) => benefit.trim()) ||
      estimate.trim() ||
      target.trim() ||
      prerequisites.trim() ||
      description.trim() ||
      Boolean(instructionCards?.length)
    );
  };

  const [title, setTitle] = useState(path?.title || '');
  const [description, setDescription] = useState(path?.description || '');

  const onChangeQuiz = ({
    cardId,
    quiz,
  }: {
    cardId: number;
    quiz: {
      title?: string;
      options?: string[];
      correctIndexes?: number[];
    };
  }) => {
    if (typeof cardId === 'number') {
      // @ts-ignore
      setInstructionCards((cards) =>
        cards.map((card) =>
          card?.seq === cardId
            ? ((function () {
                const source = _.head(card.sources);
                if (
                  source &&
                  source.__typename === 'Quiz' &&
                  source.objectId !== 'undefined'
                ) {
                  setShouldUpdateQuizsRef({
                    objectId: source.objectId,
                    fields: quiz,
                  });
                }
              })(),
              {
                ...card,
                sources: [{ ...card.sources[0], ...quiz }],
              })
            : card
        )
      );
    }
  };

  const deletePathById = (pathId: string) =>
    new Promise<PathEditorScreenPathDeleteMutationResponse>(
      (onSuccess, onFail) =>
        commitDeletePath({
          variables: {
            id: pathId || '',
          },
          onCompleted: (response, reject) => {
            response ? onSuccess(response) : onFail(reject);
          },
          onError: (error) => {
            onFail(error);
          },
        })
    );

  const fromDetailPage = location.state;

  const onClose = () => {
    // 저장하기 버튼을 한 번도 누르지 않았을 경우 close 버튼 클릭 시 러닝패스를 삭제합니다.
    newPathChecker()
      ? Modal.alert('작성 중인 내용은 저장되지 않습니다.', '', [
          {
            text: '취소',
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: '확인',
            onPress: () => {
              pathId &&
                deletePathById(pathId)
                  .then((res) => {
                    fromDetailPage
                      ? history.push(`/u/${userObjectId}`)
                      : history.goBack();
                  })
                  .catch((error) => {
                    console.log(
                      '러닝패스 및 러닝패스 드래프트 삭제 실패 ',
                      error
                    );
                  });
            },
            style: 'destructive',
          },
        ])
      : Modal.alert('작성 중인 내용은 저장되지 않습니다.', '', [
          {
            text: '취소',
            onPress: () => {},
            style: 'cancel',
          },
          {
            text: '확인',
            onPress: () => {
              history.goBack();
            },
            style: 'destructive',
          },
        ]);
  };

  const newPathChecker = () => {
    return (
      !path?.coverImage?.url &&
      !(path?.title && path.title.trim() !== '') &&
      !path?.membership &&
      !(
        path?.benefits &&
        !path?.benefits
          ?.map((benefit) => (benefit?.value as string).trim())
          ?.every((item) => item === '')
      ) &&
      !path?.estimate &&
      !(path?.target && path.target.trim() !== '') &&
      !(path?.prerequisites && Boolean(path.prerequisites.length)) &&
      !(path?.description && path.description.trim() !== '') &&
      !(path?.instructionCards && Boolean(path?.instructionCards.edges?.length))
    );
  };

  const originalInstructionCards = path?.instructionCards.edges?.map(
    (edge) => edge?.node
  );
  const [instructionCards, setInstructionCards] = useState(
    _.sortBy(originalInstructionCards, 'seq') || []
  );

  // UI에서 새로 만든 카드들을 의미합니다. 즉 서버에 생성 요청을 보내야하는 카드들입니다.
  const shouldCreateInstructionCards = useMemo(() => {
    return instructionCards.filter((card) => card?.objectId === 'undefined');
  }, [instructionCards]);

  // UI에서 제거된 카드들을 의미합니다. 즉 서버에 삭제 요청을 보내야하는 카드들입니다.
  const shouldDeleteInstructionCardsRef = useRef<string[]>([]);
  const setShouldDeleteInstructionCardsRef = ({
    targetCardId,
  }: {
    targetCardId: string;
  }) => {
    const SDICards = shouldDeleteInstructionCardsRef.current;
    shouldDeleteInstructionCardsRef.current = [...SDICards, targetCardId];

    const SUICards = shouldUpdateInstructionCardsRef.current;
    shouldUpdateInstructionCardsRef.current = SUICards.filter(
      (SUICard) => SUICard.objectId !== targetCardId
    );
  };

  // UI에서 수정된 카드들을 의미합니다. 즉 서버에 수정 요청을 보내야하는 카드들입니다.
  const shouldUpdateInstructionCardsRef = useRef<{ [key: string]: any }[]>([]);
  const setShouldUpdateInstructionCardsRef = ({
    targetCard,
    field,
    value,
  }: {
    targetCard: {
      objectId: string;
      seq?: number | null;
      description?: string | null;
      optional?: boolean | null;
    };
    field: 'seq' | 'description' | 'optional' | 'sources';
    value: number | string | boolean | SourceProp[];
  }) => {
    const SUICards = shouldUpdateInstructionCardsRef.current;
    const include = SUICards.map(({ objectId }) => objectId).includes(
      targetCard.objectId
    );
    shouldUpdateInstructionCardsRef.current = include
      ? SUICards.map((SUICard) =>
          SUICard.objectId === targetCard.objectId
            ? { ...SUICard, [field]: value }
            : SUICard
        )
      : [...SUICards, { objectId: targetCard.objectId, [field]: value }];
  };

  const shouldUpdateQuizsRef = useRef<
    {
      objectId: string;
      fields: {
        title?: string;
        options?: string[];
        correctOptions?: number[];
      };
    }[]
  >([]);
  const setShouldUpdateQuizsRef = ({
    objectId,
    fields,
  }: {
    objectId: string;
    fields: { title?: string; options?: string[]; correctOptions?: number[] };
  }) => {
    const SUQuizs = shouldUpdateQuizsRef.current;
    const newShouldUpdateQuiz = !SUQuizs.map(
      (SUQuiz) => SUQuiz.objectId
    ).includes(objectId);

    newShouldUpdateQuiz
      ? (shouldUpdateQuizsRef.current = [...SUQuizs, { objectId, fields }])
      : (shouldUpdateQuizsRef.current = SUQuizs.map((SUQuiz) =>
          SUQuiz.objectId === objectId
            ? { ...SUQuiz, fields: { ...SUQuiz.fields, ...fields } }
            : SUQuiz
        ));
  };

  const shouldDeleteQuizIdsRef = useRef<string[]>([]);
  const setShouldDeleteQuizIdsRef = ({ id }: { id: string }) => {
    const SDQuizIds = shouldDeleteQuizIdsRef.current;
    shouldDeleteQuizIdsRef.current = [...SDQuizIds, id];

    const SUQuizs = shouldUpdateQuizsRef.current;
    shouldUpdateQuizsRef.current = SUQuizs.filter(
      (SUQuiz) => SUQuiz.objectId !== id
    );
  };

  const maxSeq = useMemo(
    () => _.max(_.map(instructionCards.map((card) => card?.seq))),
    [instructionCards.length]
  );
  const newInstructionCardTemplate = useMemo(
    () => ({
      id: 'undefined',
      objectId: 'undefined',
      sources: [],
      description: '',
      optional: false,
      path: { link: pathId },
      seq: typeof maxSeq === 'number' ? maxSeq + 1 : 0,
      cardFragmentKey: null,
    }),
    [instructionCards.length]
  );

  const onPressCardAdd = () => {
    setInstructionCards((cards) => [...cards, newInstructionCardTemplate]);
  };

  const onChangeInstructionCard = ({
    id,
    value,
    field,
  }: {
    id: number | undefined | null;
    value: any;
    field: InstructionCardKeys;
  }) => {
    if (typeof id === 'number') {
      setInstructionCards((cards) =>
        cards.map((card) => {
          const isTarget = card?.seq === id;
          isTarget &&
            card.objectId !== 'undefined' &&
            setShouldUpdateInstructionCardsRef({
              targetCard: card,
              field,
              value,
            });
          return isTarget ? { ...card, [field]: value } : card;
        })
      );
    }
  };

  const onPressQuizAdd = () => {
    // @ts-ignore
    setInstructionCards((cards) => [
      ...cards,
      {
        ...newInstructionCardTemplate,
        sources: [
          {
            id: 'undefined',
            objectId: 'undefined',
            __typename: 'Quiz',
            options: ['', ''],
            correctIndexes: [0],
            title: '',
          },
        ],
      },
    ]);
  };

  const onPressCardRemove = (cardSeq: number) => {
    Modal.alert('선택하신 콘텐츠 1개를 정말로 삭제하시겠습니까?', '', [
      { text: '취소', onPress: () => {}, style: 'cancel' },
      {
        text: '삭제',
        onPress: () => {
          setInstructionCards((cards) =>
            cards.filter((card) => {
              card?.objectId &&
                card.objectId !== 'undefined' &&
                card?.seq === cardSeq &&
                (setShouldDeleteInstructionCardsRef({
                  targetCardId: card.objectId,
                }),
                (function () {
                  const source = _.head(card.sources);
                  if (
                    source &&
                    source.__typename === 'Quiz' &&
                    source.objectId !== 'undefined'
                  ) {
                    setShouldDeleteQuizIdsRef({ id: source.objectId });
                  }
                })());
              return card?.seq !== cardSeq;
            })
          );
        },
        style: 'destructive',
      },
    ]);
  };
  const onPressCardMore = (cardSeq: number | undefined | null) => {
    if (typeof cardSeq === 'number') {
      const options = ['삭제', '취소'];
      showActionSheetWithOptions(
        {
          options,
          destructiveButtonIndex: 0,
          cancelButtonIndex: 1,
        },
        (idx) => {
          if (options[idx] === '삭제') {
            Modal.alert('선택하신 콘텐츠 1개를 정말로 삭제하시겠습니까?', '', [
              { text: '취소', onPress: () => {}, style: 'cancel' },
              {
                text: '삭제',
                onPress: () => onPressCardRemove(cardSeq),
                style: 'destructive',
              },
            ]);
          }
        }
      );
    }
  };

  const onPressMySelector = (cardSeq?: number | null) => {
    if (typeof cardSeq === 'number') {
      setCurrentCardId(cardSeq);
      setIsMySelectorVisible(true);
    }
  };

  const onPressSearchSelector = (cardSeq?: number | null) => {
    if (typeof cardSeq === 'number') {
      setCurrentCardId(cardSeq);
      setIsSearchSelectorVisible(true);
    }
  };

  const onSelectPath = ({
    selectedPath,
    selectedPathId,
    cardId,
  }: {
    selectedPath: InstructionCardSourcePathQueryResponse;
    selectedPathId?: string | null;
    cardId?: number | null;
  }) => {
    // @ts-ignore
    setInstructionCards((cards) =>
      cards.map((card) =>
        card?.seq === cardId
          ? (card?.objectId !== 'undefined' &&
              setShouldUpdateInstructionCardsRef({
                targetCard: card,
                field: 'sources',
                value: [
                  {
                    __type: 'Pointer',
                    className: 'Path',
                    objectId: selectedPathId,
                  },
                ],
              }),
            { ...card, sources: [{ ...selectedPath }] })
          : card
      )
    );
    setCurrentCardId(null);
    setSelectedPathId(null);
  };

  const onSelectContentItem = ({
    selectedContentItem,
    selectedContentItemId,
    cardId,
  }: {
    selectedContentItem: InstructionCardSourceContentItemQueryResponse;
    selectedContentItemId?: string | null;
    cardId?: number | null;
  }) => {
    // @ts-ignore
    setInstructionCards((cards) =>
      cards.map((card) => {
        return card?.seq === cardId
          ? (card?.objectId !== 'undefined' &&
              setShouldUpdateInstructionCardsRef({
                targetCard: card,
                field: 'sources',
                value: [
                  {
                    __type: 'Pointer',
                    className: 'ContentItem',
                    objectId: selectedContentItemId,
                  },
                ],
              }),
            { ...card, sources: [{ ...selectedContentItem }] })
          : card;
      })
    );
    setCurrentCardId(null);
    setSelectedContentItemId(null);
  };

  // 자동 저장 방식에서는 needMembership에 대한 state 관리 필요없어서 주석처리
  const [needMembership, setNeedMembership] = useState(
    Boolean(path?.membership)
  );

  const needMembershipChangeHandler = () => {
    setNeedMembership((prev) => !prev);
  };
  const notifyConditionChangingNeedMembership = () => {
    Toast.info({
      content: '발행한 멤버십이 없습니다.',
      duration: 1,
    });
  };

  const needMembershipTurnOnValidator = () => {
    return Boolean(pathOwnerMemberships?.length);
  };

  useEffect(() => {
    const seqs = instructionCards.map((card) => card?.seq);
    const groupedSeqs = _.groupBy(seqs);
    const needSeqsInit =
      Object.keys(groupedSeqs).some((k) => groupedSeqs[k].length >= 2) ||
      Object.keys(groupedSeqs).includes('null') ||
      Object.keys(groupedSeqs).includes('undefined');
    if (needSeqsInit) {
      instructionCards.forEach((card, index) =>
        commitUpdateInstructionCard({
          variables: {
            objectId: card?.objectId || '',
            fields: {
              seq: index,
            },
          },
          onCompleted: (response, reject) => {
            console.log({ response, reject });
          },
          onError: (e) => {
            console.log(
              '러닝패스 내의 카드들에 대해 seq 초기화 적용 로직에 에러 발생 ',
              e
            );
          },
        })
      );
    }
    return;
  }, []);

  const onUpCard = ({
    targetCardSeq,
    prevCardSeq,
  }: {
    targetCardSeq: number;
    prevCardSeq: number;
  }) => {
    setInstructionCards((cards) =>
      _.chain(cards)
        .map((card) =>
          card?.seq === targetCardSeq
            ? (card.objectId !== 'undefined' &&
                setShouldUpdateInstructionCardsRef({
                  targetCard: card,
                  field: 'seq',
                  value: prevCardSeq,
                }),
              { ...card, seq: prevCardSeq })
            : card?.seq === prevCardSeq
            ? (card.objectId !== 'undefined' &&
                setShouldUpdateInstructionCardsRef({
                  targetCard: card,
                  field: 'seq',
                  value: targetCardSeq,
                }),
              { ...card, seq: targetCardSeq })
            : card
        )
        .sortBy('seq')
        .value()
    );
  };

  const onDownCard = ({
    targetCardSeq,
    nextCardSeq,
  }: {
    targetCardSeq: number;
    nextCardSeq: number;
  }) => {
    setInstructionCards((cards) =>
      _.chain(cards)
        .map((card) =>
          card?.seq === targetCardSeq
            ? (card.objectId !== 'undefined' &&
                setShouldUpdateInstructionCardsRef({
                  targetCard: card,
                  field: 'seq',
                  value: nextCardSeq,
                }),
              { ...card, seq: nextCardSeq })
            : card?.seq === nextCardSeq
            ? (card.objectId !== 'undefined' &&
                setShouldUpdateInstructionCardsRef({
                  targetCard: card,
                  field: 'seq',
                  value: targetCardSeq,
                }),
              { ...card, seq: targetCardSeq })
            : card
        )
        .sortBy('seq')
        .value()
    );
  };

  const [benefits, setBenefits] = useState<string[]>(
    path?.benefits && path?.benefits.length
      ? path?.benefits?.map((b) => b?.value as string)
      : ['']
  );
  const onCreateBenefit = () => setBenefits((prev) => [...prev, '']);
  const onEditBenefit = (text: string, id: number) => {
    setBenefits((prev) =>
      prev.map((benefit, idx) => (idx === id ? text : benefit))
    );
  };
  const onDeleteBenefit = (id: number) => {
    setBenefits((prev) => prev.filter((_, idx) => idx !== id));
  };

  const [estimate, setEstimate] = useState(
    path?.estimate ? String(path.estimate) : ''
  );

  // 숫자만 입력 가능 and 소숫점 한자리까지 허용하는 validation
  const estimateValidator = (text: string) => {
    const isFloatingReg = /^\d*[.]\d{1}$/;
    const isNumberReg = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
    return (
      isFloatingReg.test(text) ||
      isNumberReg.test(text) ||
      text === '' ||
      (text.slice(-1) === '.' &&
        !(text.split('').filter((t) => t === '.').length >= 2))
    );
  };

  const onChangeEstimate = (text: string) => {
    estimateValidator(text) && setEstimate(text);
  };

  const [target, setTarget] = useState(path?.target || '');

  const [prerequisites, setPrerequisites] = useState(
    path?.prerequisites?.map((p) => p?.value as string).join(', ') || ''
  );

  const mountRef = useRef(false);
  const [pathImageFile, setPathImageFile] = useState<File | null>(null);
  const [pathImageURL, setPathImageURL] = useState<string | null>(
    path?.coverImage?.url || null
  );
  const uploadables: UploadableMap | undefined = useMemo(
    () =>
      pathImageFile
        ? {
            'variables.fields.coverImage.upload': pathImageFile,
          }
        : undefined,
    [pathImageFile]
  );

  const onClickPreviewImage = () => {
    const options = ['이미지 제거', '취소'];
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: 1,
      },
      (idx) => {
        if (typeof idx === 'number' && options[idx] === '이미지 제거') {
          pathImageFile ? setPathImageFile(null) : setPathImageURL(null);
        }
      }
    );
  };

  useEffect(() => {
    if (!mountRef.current) {
      mountRef.current = true;
      return;
    }
    setPathImageURL(pathImageFile ? URL.createObjectURL(pathImageFile) : null);
  }, [pathImageFile]);

  const sendDataToGTM = useGTMDispatch();

  const onSave = async (pathId: string) => {
    if (pathId) {
      const fields = await getFormattedFields();
      updatePath({ id: pathId, fields, uploadables })
        .then((res) => {
          const SUICards = shouldUpdateInstructionCardsRef.current;
          const SUQuizs = shouldUpdateQuizsRef.current;
          const SDICardIds = shouldDeleteInstructionCardsRef.current;
          const SDQuizIds = shouldDeleteQuizIdsRef.current;

          // updatePath에서 카드 추가가 이뤄지고
          // 수정된 카드들에 대한 업데이트는 아래 코드에서 진행됩니다.
          const promisesUpdatingInstructionCards = SUICards.map(
            (SUICard) => SUICard.objectId
          ).map((SUICardId) => {
            const fields: UpdateInstructionCardFieldsInput = {};
            const SUICard = SUICards.find(
              (card) => card.objectId === SUICardId
            );
            typeof SUICard?.seq === 'number' && (fields.seq = SUICard.seq);
            SUICard?.description && (fields.description = SUICard.description);
            _.isBoolean(SUICard?.optional) &&
              (fields.optional = SUICard?.optional || false);
            SUICard?.sources && (fields.sources = SUICard.sources);
            return updateInstructionCard({ fields, id: SUICardId });
          });

          // 수정된 퀴즈들에 대한 업데이트는 아래 코드에서 진행됩니다.
          const promisesUpdatingQuizs = SUQuizs.map((SUQuiz) =>
            updateQuiz({ id: SUQuiz.objectId, fields: SUQuiz.fields })
          );

          // 삭제할 카드들, 퀴즈들에 대한 삭제는 아래 코드에서 진행됩니다.
          const promisesDeletingInstructionCardAndQuiz = SDICardIds.map(
            (SDICardId) => {
              const promiseDeleteInstructionCard = deleteInstructionCardById({
                id: SDICardId,
              });
              return promiseDeleteInstructionCard.then((res) => {
                const sources =
                  res.deleteInstructionCard?.instructionCard.sources;
                const source = _.head(sources);
                if (source?.__typename === 'Quiz') {
                  deleteQuizById({ id: source.objectId });
                }
              });
            }
          );

          Promise.allSettled([
            ...promisesUpdatingInstructionCards,
            ...promisesUpdatingQuizs,
            ...promisesDeletingInstructionCardAndQuiz,
          ]).then((res) => {
            Modal.alert('저장되었습니다.', '', [
              {
                text: '확인',
                onPress: () => history.goBack(),
              },
            ]);
          });
        })
        .catch((error) => {
          console.log(error);
          alert('에러 발생(console 확인)');
        });
    }
  };

  // 새로 생성해야할 card들을 생성하고 생성된 card의 id 배열을 반환합니다.
  const createInstructionCards = async () => {
    const promisesCreatingCard = shouldCreateInstructionCards.map((card) => {
      const source = _.head(card?.sources);
      return source?.__typename === 'Quiz'
        ? createQuiz({
            fields: {
              correctIndexes: source.correctIndexes,
              options: source.options,
              title: source.title,
            },
          })
            .then((res) => {
              const sourceObjectId = res.createQuiz?.quiz.objectId;
              return createInstructionCard({
                fields: {
                  description: card?.description,
                  optional: card?.optional,
                  seq: card?.seq,
                  sources: [
                    {
                      objectId: sourceObjectId,
                      __type: 'Pointer',
                      className: source.__typename,
                    },
                  ],
                },
              });
            })
            .then((res) => res.createInstructionCard?.instructionCard.objectId)
            .catch(console.log)
        : createInstructionCard({
            fields: {
              description: card?.description,
              optional: card?.optional,
              seq: card?.seq,
              sources: source
                ? [
                    {
                      objectId: source.objectId,
                      __type: 'Pointer',
                      className: source.__typename,
                    },
                  ]
                : source,
            },
          })
            .then((res) => res.createInstructionCard?.instructionCard.objectId)
            .catch(console.log);
    });

    const beCreatedInstructionCardIds = await Promise.all(promisesCreatingCard);
    return beCreatedInstructionCardIds.filter(_.isString);
  };

  const getFormattedFields: () => Promise<UpdatePathFieldsInput> = async () => {
    // state에서 수정한 field만 담기 위해 우선 빈 객체 생성 (추후 여기에 수정한 field들 채워짐)
    const fields: UpdatePathFieldsInput = {};

    const formattedTarget = target
      .split(',')
      .map((c) => c.trim())
      .filter((c) => c)
      .join(', ');
    const formattedPrerequisites = prerequisites
      .split(',')
      .map((w) => w.trim())
      .filter((w) => w);

    title !== path?.title && (fields.title = title);
    description !== path?.description && (fields.description = description);
    needMembership !== Boolean(path?.membership?.objectId) &&
      (fields.membership = needMembership
        ? { link: _.head(pathOwnerMemberships)?.objectId }
        : { link: null });
    parseFloat(estimate) !== path?.estimate &&
      (fields.estimate = parseFloat(estimate));
    !_.isEqual(
      benefits,
      path?.benefits?.map((benefit) => benefit?.value)
    ) && (fields.benefits = benefits);
    formattedTarget !== path?.target && (fields.target = formattedTarget);
    !_.isEqual(
      formattedPrerequisites,
      path?.prerequisites?.map((prerequisite) => prerequisite?.value)
    ) && (fields.prerequisites = formattedPrerequisites);
    !pathImageURL &&
      path?.coverImage?.url &&
      (fields.coverImage = { file: null });
    if (
      shouldDeleteInstructionCardsRef.current.length > 0 ||
      shouldCreateInstructionCards.length > 0
    ) {
      fields.instructionCards = {
        remove: shouldDeleteInstructionCardsRef.current,
        add: (await createInstructionCards()) || [],
        //   createAndAdd:
        //     shouldCreateInstructionCards.map((card) => ({
        //       path: { link: pathId },
        //       description: card?.description,
        //       optional: card?.optional,
        //       seq: card?.seq,
        //       sources: card?.sources?.length
        //         ? [
        //             {
        //               objectId: _.head(card?.sources)?.objectId,
        //               __type: 'Pointer',
        //               className: _.head(card?.sources)?.__typename,
        //             },
        //           ]
        //         : [],
        //     })) || [],
      };
    }
    return fields;
  };
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{ alignItems: 'stretch' }}
    >
      {path && user ? (
        <LFMetaTags
          type="article"
          title="러닝패스 작성"
          description={''}
          authorName={user.name}
          authorId={user.objectId}
          objectId={pathId}
        />
      ) : null}
      <NavBar
        style={{
          //@ts-ignore
          position: 'sticky',
          top: 0,
        }}
        title={'러닝패스 작성'}
        left={
          isCommitUpdatePathInFlight ||
          isCommitCreateInstructionCardInFlight ||
          isCommitCreateQuizInFlight ||
          isCommitUpdateInstructionCardInFlight ||
          isCommitUpdateQuizInFlight ? (
            <></>
          ) : (
            <HistoryBackButton
              onPress={onClose}
              testID="mkpath-close:PathEditorScreen"
              icon={<Ionicons name="close-outline" size={30} color="black" />}
            />
          )
        }
        right={
          <Flex justify="end" direction="row">
            {isCommitUpdatePathInFlight ||
            isCommitCreateInstructionCardInFlight ||
            isCommitCreateQuizInFlight ||
            isCommitUpdateInstructionCardInFlight ||
            isCommitUpdateQuizInFlight ||
            isCommitDeletePathInFlight ? (
              <ActivityIndicator />
            ) : saveValidator() ? (
              <LFLink
                testID="mkpath-save:PathEditorScreen"
                onPress={() => onSave(pathId)}
              >
                <LFText style={{ fontSize: 16 }}>저장하기</LFText>
              </LFLink>
            ) : (
              <LFText style={{ fontSize: 16, color: colors.TEXT_30 }}>
                저장하기
              </LFText>
            )}
          </Flex>
        }
      />
      <WingBlank>
        <WhiteSpace />
        {pathImageURL ? (
          <LFLink onPress={onClickPreviewImage}>
            <Image source={{ uri: pathImageURL }} style={styles.previewImage} />
          </LFLink>
        ) : (
          <FileInput
            testID="mkpath-put-image:PathEditorScreen"
            accept="image/*"
            onChange={(file) => {
              if (file.size > MAX_FILE_SIZE) {
                alert(
                  `${
                    MAX_FILE_SIZE / (1000 * 1000)
                  }MB 이하의 이미지만 등록 가능합니다.`
                );
                return;
              }
              setPathImageFile(file);
            }}
          >
            <View style={styles.imageSelectorContainer}>
              <View style={styles.imageSelectorSubContainer}>
                <Ionicons
                  name="image-outline"
                  size={68}
                  color={colors.BORDER_80}
                />
                <LFText style={{ color: colors.TEXT_60 }}>
                  + 대표 이미지 등록
                </LFText>
                <LFText style={{ color: colors.TEXT_60, fontSize: 10 }}>
                  * 대표 이미지는 16:9 비율로 등록하세요.
                </LFText>
              </View>
            </View>
          </FileInput>
        )}
        <WhiteSpace />
        <Flex justify="end">
          <LFText>멤버십</LFText>
          <LFWhiteSpace direction="row" />
          <Switch
            value={needMembership}
            onValueChange={(value: boolean) => {
              // value가 true이면 Switch Off에서 On으로 변환 시도하는 것을 의미
              if (value)
                needMembershipTurnOnValidator()
                  ? (needMembershipChangeHandler(),
                    sendDataToGTM({ event: `customOnSwitchPathMembership:on` }))
                  : (notifyConditionChangingNeedMembership(),
                    sendDataToGTM({
                      event: `customOnSwitchPathMembership:fail`,
                    }));
              else
                needMembershipChangeHandler(),
                  sendDataToGTM({ event: `customOnSwitchPathMembership:off` });
            }}
          />
        </Flex>
        <WhiteSpace />
        <MultilineGrowingTextInput
          style={styles.titleInput}
          value={title}
          placeholder={'러닝 패스 제목'}
          onChangeText={setTitle}
        />
        <WhiteSpace />
        <View style={styles.pathDetailInfoContainer}>
          <WhiteSpace />
          <LFText>학습 후 이런 것을 할 수 있어요</LFText>
          {benefits.map((benefit, index, benefits) => {
            const first = index === 0;
            const last = index === benefits.length - 1;
            const isMoreThanOne = benefits.length > 1;
            return (
              <React.Fragment key={index}>
                <LFWhiteSpace size={'xs'} direction="column" />
                <Flex style={{ position: 'relative' }}>
                  <TextInput
                    value={benefit}
                    onChangeText={(text) => onEditBenefit(text, index)}
                    style={[
                      styles.benefitForm,
                      isMoreThanOne ? { paddingRight: 24 } : null,
                    ]}
                    placeholder="(예) 리액트로 Todo 앱을 만들수 있어요"
                  />
                  {isMoreThanOne && (
                    <LFLink
                      onPress={() => onDeleteBenefit(index)}
                      style={{ position: 'absolute', right: 34 }}
                    >
                      <Ionicons name="trash-outline" size={20} color="black" />
                    </LFLink>
                  )}
                  <LFWhiteSpace size={'sm'} direction="row" />
                  {last ? (
                    <LFLink
                      style={styles.benefitAddButton}
                      onPress={onCreateBenefit}
                    >
                      <Ionicons name="add" size={20} color="black" />
                    </LFLink>
                  ) : (
                    <LFWhiteSpace direction="row" size="xl" />
                  )}
                </Flex>
              </React.Fragment>
            );
          })}

          <LFWhiteSpace size={'lg'} direction="column" />
          <Flex justify="between" align="center">
            <LFText>학습 예상 소요 시간</LFText>
            <TextInput
              value={estimate}
              onChangeText={onChangeEstimate}
              keyboardType="decimal-pad"
              style={{ borderWidth: 1, borderColor: 'black', padding: 4 }}
              placeholder="(예) 1"
            />
          </Flex>
          <LFWhiteSpace size={'sm'} direction="column" />
          <Flex justify="between" align="center">
            <LFText>러닝패스 학습 대상</LFText>
            <TextInput
              value={target}
              onChangeText={setTarget}
              style={{ borderWidth: 1, borderColor: 'black', padding: 4 }}
              placeholder="(예) 리액트 입문자"
            />
          </Flex>
          <LFWhiteSpace size={'sm'} direction="column" />
          <Flex justify="between" align="center">
            <LFText>선수 학습 내용</LFText>
            <TextInput
              value={prerequisites}
              onChangeText={setPrerequisites}
              style={{ borderWidth: 1, borderColor: 'black', padding: 4 }}
              placeholder="(예) 리액트 기초"
            />
          </Flex>
        </View>
        <WhiteSpace />
        <MultilineGrowingTextInput
          style={styles.descriptionInput}
          defaultValue={description}
          placeholder="러닝 패스 설명"
          onChangeText={setDescription}
        />
        <WhiteSpace size="xl" />
        {_.map(instructionCards, (card, idx, arr) => {
          const first = idx === 0;
          const last = idx === arr.length - 1;
          const prevCardSeq = first ? null : arr[idx - 1]?.seq;
          const nextCardSeq = last ? null : arr[idx + 1]?.seq;
          const selected = currentCardId === card?.seq;
          return (
            <Suspense key={card?.seq} fallback={<ActivityIndicator />}>
              <InstructionCard
                selectedPathId={
                  selected && selectedPathId ? selectedPathId : null
                }
                selectedContentItemId={
                  selected && selectedContentItemId
                    ? selectedContentItemId
                    : null
                }
                cardFragmentKey={card || null}
                first={first}
                last={last}
                prevCardSeq={prevCardSeq}
                nextCardSeq={nextCardSeq}
                seq={card?.seq}
                description={card?.description}
                optional={card?.optional}
                sources={card?.sources}
                onChange={onChangeInstructionCard}
                onPressRemove={onPressCardRemove}
                onUp={onUpCard}
                onDown={onDownCard}
                onPressMySelector={onPressMySelector}
                onPressSearchSelector={onPressSearchSelector}
                onSelectPath={onSelectPath}
                onSelectContentItem={onSelectContentItem}
                onChangeQuiz={onChangeQuiz}
              />
            </Suspense>
          );
        })}
        <Flex direction="row" justify="between">
          <TouchableOpacity
            testID="mkpath-add-card:PathEditorScreen"
            onPress={() => {
              onPressCardAdd();
            }}
          >
            <Flex direction="row" style={{ alignSelf: 'stretch' }}>
              <View
                style={[
                  {
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    backgroundColor: '#e0e0e0',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 16,
                  },
                ]}
              >
                <Ionicons
                  name="add"
                  size={30}
                  color="#888"
                  style={{ textAlign: 'center' }}
                />
              </View>
              <LFText style={{ color: '#4F4F4F', fontSize: 16 }}>
                콘텐츠 추가
              </LFText>
            </Flex>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              onPressQuizAdd();
            }}
          >
            <Flex direction="row" style={{ alignSelf: 'stretch' }}>
              <View
                style={[
                  {
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                    backgroundColor: '#e0e0e0',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 16,
                  },
                ]}
              >
                <Ionicons
                  name="add"
                  size={30}
                  color="#888"
                  style={{ textAlign: 'center' }}
                />
              </View>
              <LFText style={{ color: '#4F4F4F', fontSize: 16 }}>
                퀴즈 추가
              </LFText>
            </Flex>
          </TouchableOpacity>
        </Flex>
        <WhiteSpace size="xl" style={{ height: 100 }} />
      </WingBlank>
      <Modal
        // transparent={true}
        popup
        visible={isMySelectorVisible}
        animationType="slide-up"
        style={[
          styles.subScreenModal,
          {
            height: modalHeight,
            width: mobileDimensions.width,
            margin: 'auto',
          },
        ]}
        // onDismiss={() => {}}
      >
        <Suspense
          fallback={
            <SuspenseFallbackScreen
              style={{ height: modalHeight, flex: undefined }}
            />
          }
        >
          <ItemSelectorScreen
            style={{ height: modalHeight }}
            // defaultContentItemIds={_.map(
            //   selectedContentItems,
            //   (i) => i.objectId
            // )}
            singleSelection
            onPressClose={() => {
              setIsMySelectorVisible(false);
              setCurrentCardId(null);
            }}
            onPressSave={(result) => {
              setIsMySelectorVisible(false);
              if (typeof currentCardId === 'number') {
                setSelectedContentItemId(result[0].objectId);
              }
              //TODO: state 핸들링
              // setSelectedContentItems(result);
            }}
          />
        </Suspense>
      </Modal>
      <Modal
        // transparent={true}
        popup
        visible={isSearchSelectorVisible}
        animationType="slide-up"
        style={[
          styles.subScreenModal,
          {
            height: modalHeight,
            width: mobileDimensions.width,
            margin: 'auto',
          },
        ]}
        bodyStyle={{ height: modalHeight }}
        // onDismiss={() => {}}
      >
        <Suspense
          fallback={
            <SuspenseFallbackScreen
              style={{ height: modalHeight, flex: undefined }}
            />
          }
        >
          <SearchScreen
            placeholder="검색어를 입력해주세요."
            onPressClose={() => {
              setIsSearchSelectorVisible(false);
              setCurrentCardId(null);
            }}
            onPressContentItem={(id, contentId) => {
              setIsSearchSelectorVisible(false);
              if (typeof currentCardId === 'number') {
                setSelectedContentItemId(id);
              }
            }}
            onPressPath={(id) => {
              setIsSearchSelectorVisible(false);
              if (typeof currentCardId === 'number') {
                setSelectedPathId(id);
              }
            }}
          />
        </Suspense>
      </Modal>
    </ScrollView>
  );
};

interface ContentItemProps {
  contentItemFrgmt: ItemSelectorScreen_Fragment$key;
}
const ContentItemView: React.FC<ContentItemProps> = ({ contentItemFrgmt }) => {
  const item = useFragment(contentItemFragmentQuery, contentItemFrgmt);
  return (
    <View style={styles.contentItemWrapper}>
      {/* 컨텐츠 아이템 썸네일 */}
      <View style={styles.contentItemThumbNailWrapper}>
        <ImageBackground
          resizeMode="cover"
          source={{ uri: item?.content?.thumbURL }}
          style={{ flex: 1 }}
        />
      </View>
      {/* 컨텐츠 아이템 제목, 작성자명, 작성자 닉네임 */}

      <View style={{ flexDirection: 'column', paddingLeft: 8, flex: 1 }}>
        {/* maxWidth: 50%를 flex: 1로 변경, 이유: thumbnail 이외에 부모의 남은 여백을 해당 View로 채우게 하려고  */}
        <LFText numberOfLines={2} style={styles.contentItemTitle}>
          {item.title}
        </LFText>

        <WhiteSpace />

        <View style={{ flexDirection: 'row' }}>
          <LFText numberOfLines={1} style={styles.userName}>
            {item.author?.name}
          </LFText>
          <LFText numberOfLines={1} style={styles.userNickName}></LFText>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleInput: {
    fontSize: 24,
    fontWeight: 'bold',
    minHeight: 30,
  },
  descriptionInput: {
    fontSize: 14,
    lineHeight: 20,
    color: '#666',
    minHeight: 82,
    borderWidth: 1,
    borderColor: 'rgb(128, 128, 128)',
    borderRadius: 12,
    padding: 8,
  },
  container: {
    flex: 1,
    backgroundColor: '#f8f8f8',
  },
  section: { padding: 18 },
  guideText: { color: '#878787' },
  pathTitleLength: { color: '#B6B5B5', alignSelf: 'flex-end', marginBottom: 8 },
  addContentBtn: {
    borderWidth: 1,
    paddingVertical: 13,
    textAlign: 'center',
    borderColor: '#A3A3A3',
    marginBottom: 34,
  },
  input: { height: 40, borderBottomWidth: 1, borderColor: '#D1D1D1' },
  contentItemList: { marginBottom: -25 },
  contentItemWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    // flex: 1을 추가, 이유: list, close icon 사이에 껴있는 contentItemWrapper의 width가 부모 element의 남은 여백을 채우게 하기 위함
  },
  contentItemThumbNailWrapper: {
    width: 88,
    height: 52,
  },
  contentItemTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  userName: {
    color: '#565656',
    marginRight: 5,
  },
  userNickName: {
    color: '#565656',
  },
  subScreenModal: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: 'hidden',
    // position: 'sticky',
    height: '90%',
  },
  pathDetailInfoContainer: {
    padding: 16,
    backgroundColor: colors.BG_1,
    borderRadius: 12,
  },
  benefitForm: {
    flex: 1,
    borderWidth: 1,
    borderColor: 'black',
    padding: 4,
  },
  benefitAddButton: {
    width: 24,
    height: 24,
    backgroundColor: colors.BG_4,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageSelectorContainer: {
    height: 0,
    paddingBottom: '56.25%',
    position: 'relative',
  },
  imageSelectorSubContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderWidth: 2,
    borderColor: colors.BORDER_40,
    borderStyle: 'dashed',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewImage: {
    height: 0,
    paddingBottom: '56.25%',
    borderRadius: 12,
  },
});

export default PathEditorScreen;

export const FolderButton: React.FC<{
  title: string;
  icon?: ReactElement;
  onPress: () => void;
  testID: string;
}> = ({ title, icon, onPress, testID }) => (
  <TouchableOpacity testID={testID} onPress={onPress}>
    <Flex direction="column" justify="center" align="center">
      {icon ? (
        icon
      ) : (
        <Ionicons name="folder-outline" size={30} color="#4F4F4F" />
      )}
      <WhiteSpace size="xs" />
      <LFText style={{ color: '#828282', fontSize: 14 }}>{title}</LFText>
    </Flex>
  </TouchableOpacity>
);
