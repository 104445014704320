/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MyContentItemScreenContentItemQueryVariables = {
    first?: number | null;
    after?: string | null;
    userObjectId?: string | null;
};
export type MyContentItemScreenContentItemQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"MyContentItemScreenContentItemPaginationFragment">;
};
export type MyContentItemScreenContentItemQuery = {
    readonly response: MyContentItemScreenContentItemQueryResponse;
    readonly variables: MyContentItemScreenContentItemQueryVariables;
};



/*
query MyContentItemScreenContentItemQuery(
  $first: Int
  $after: String
  $userObjectId: ID
) {
  ...MyContentItemScreenContentItemPaginationFragment
}

fragment ListItemContentItem_Fragment on ContentItem {
  id
  objectId
  title
  published
  createdAt
  updatedAt
  author {
    id
    objectId
    name
    username
    email
  }
  content {
    id
    objectId
    thumbURL
    type
  }
}

fragment MyContentItemScreenContentItemPaginationFragment on Query {
  contentItems(first: $first, after: $after, where: {author: {have: {objectId: {equalTo: $userObjectId}}}}, order: updatedAt_DESC) {
    edges {
      node {
        __typename
        id
        objectId
        published
        content {
          __typename
          id
          objectId
        }
        ...ListItemContentItem_Fragment
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userObjectId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "updatedAt_DESC"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "equalTo",
                    "variableName": "userObjectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "objectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "have"
          }
        ],
        "kind": "ObjectValue",
        "name": "author"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyContentItemScreenContentItemQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MyContentItemScreenContentItemPaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyContentItemScreenContentItemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ContentItemConnection",
        "kind": "LinkedField",
        "name": "contentItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "published",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Content",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbURL",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "where",
          "order"
        ],
        "handle": "connection",
        "key": "ContentItem_contentItems",
        "kind": "LinkedHandle",
        "name": "contentItems"
      }
    ]
  },
  "params": {
    "cacheID": "e47f1c39d55fa88911684ee0f9b56efc",
    "id": null,
    "metadata": {},
    "name": "MyContentItemScreenContentItemQuery",
    "operationKind": "query",
    "text": "query MyContentItemScreenContentItemQuery(\n  $first: Int\n  $after: String\n  $userObjectId: ID\n) {\n  ...MyContentItemScreenContentItemPaginationFragment\n}\n\nfragment ListItemContentItem_Fragment on ContentItem {\n  id\n  objectId\n  title\n  published\n  createdAt\n  updatedAt\n  author {\n    id\n    objectId\n    name\n    username\n    email\n  }\n  content {\n    id\n    objectId\n    thumbURL\n    type\n  }\n}\n\nfragment MyContentItemScreenContentItemPaginationFragment on Query {\n  contentItems(first: $first, after: $after, where: {author: {have: {objectId: {equalTo: $userObjectId}}}}, order: updatedAt_DESC) {\n    edges {\n      node {\n        __typename\n        id\n        objectId\n        published\n        content {\n          __typename\n          id\n          objectId\n        }\n        ...ListItemContentItem_Fragment\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9a2acc7c68f46ba0ec96dfdd3b098aaa';
export default node;
