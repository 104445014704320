import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { Flex } from '@ant-design/react-native';
import LFText from './typo/LFText';
import LFWhiteSpace from './LFWhiteSpace';
import { graphql, useFragment } from 'react-relay';
import { MembershipPricingCardFragment$key } from '../__generated__/MembershipPricingCardFragment.graphql';

const MembershipPricingCard: React.FC<{
  fragment: MembershipPricingCardFragment$key | null;
}> = ({ fragment }) => {
  const pricing = useFragment(
    graphql`
      fragment MembershipPricingCardFragment on MembershipPricing {
        id
        objectId
        duration
        price
        author {
          id
          objectId
          name
        }
        membership {
          title
        }
      }
    `,
    fragment || null
  );
  return (
    <View style={styles.container}>
      <LFWhiteSpace size="sm" />
      <Flex justify="between">
        <LFText
          style={styles.planTitle}
        >{`⚡ ${pricing?.membership?.title} ${pricing?.duration}일 이용권`}</LFText>
        <LFText style={styles.planCost}>{`${(
          pricing?.price || 0
        ).toLocaleString('ko-KR')}원`}</LFText>
      </Flex>
      <LFWhiteSpace size="sm" />
      {/* <LFText style={styles.planDescription}>
        {`${pricing?.author?.name || ''}님의 멤버십 전용 콘텐츠를 ${
          pricing?.duration
        }일 동안 학습할 수 있습니다.`}
      </LFText> */}
    </View>
  );
};

export default MembershipPricingCard;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: 'rgb(237,242,255)',
  },
  planTitle: { fontWeight: 'bold' },
  planCost: { fontWeight: 'bold' },
  planDescription: { color: '#787878', fontSize: 12 },
});
