/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MarkdownScreenQueryVariables = {
    mdId: string;
    slug: string;
};
export type MarkdownScreenQueryResponse = {
    readonly markdowns: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly navTitle: string | null;
                readonly content: string | null;
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly subtitle: string | null;
                readonly coverImage: {
                    readonly url: string;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type MarkdownScreenQuery = {
    readonly response: MarkdownScreenQueryResponse;
    readonly variables: MarkdownScreenQueryVariables;
};



/*
query MarkdownScreenQuery(
  $mdId: ID!
  $slug: String!
) {
  markdowns(where: {OR: [{objectId: {equalTo: $mdId}}, {slug: {equalTo: $slug}}]}, first: 1) {
    edges {
      node {
        navTitle
        content
        id
        objectId
        title
        subtitle
        coverImage {
          url
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mdId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "fields": [
          {
            "items": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "equalTo",
                        "variableName": "mdId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "objectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "OR.0"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "equalTo",
                        "variableName": "slug"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "slug"
                  }
                ],
                "kind": "ObjectValue",
                "name": "OR.1"
              }
            ],
            "kind": "ListValue",
            "name": "OR"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "MarkdownConnection",
    "kind": "LinkedField",
    "name": "markdowns",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MarkdownEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Markdown",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "navTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "content",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subtitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FileInfo",
                "kind": "LinkedField",
                "name": "coverImage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkdownScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkdownScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "196ef48b5b124d485fb62e12b102e407",
    "id": null,
    "metadata": {},
    "name": "MarkdownScreenQuery",
    "operationKind": "query",
    "text": "query MarkdownScreenQuery(\n  $mdId: ID!\n  $slug: String!\n) {\n  markdowns(where: {OR: [{objectId: {equalTo: $mdId}}, {slug: {equalTo: $slug}}]}, first: 1) {\n    edges {\n      node {\n        navTitle\n        content\n        id\n        objectId\n        title\n        subtitle\n        coverImage {\n          url\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7db478c3d47ca33ae9ad9b1e10041080';
export default node;
