import React from 'react';
import { Image, ImageProps } from 'react-native';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

const PreviewImage: React.FC<Optional<ImageProps, 'source'>> = ({
  // defaultSource = { uri: require('../assets/images/defaultSource.png') },
  source = { uri: '' },
  ...props
}) => {
  // https://github.com/necolas/react-native-web/blob/master/packages/react-native-web/src/exports/Image/index.js#L95
  const newSource =
    source != null &&
    !Array.isArray(source) &&
    typeof source === 'object' &&
    !source.uri
      ? require('../assets/images/defaultSource.png')
      : source;
  // TODO: newSource가 {uri: ''} 일 때 챙겨져야할 이미지는 아직 존재하지 않기 때문에 추후에 추가해야할 것을 보입니다.
  return <Image resizeMode="cover" source={newSource} {...props} />;
};

export default PreviewImage;
