// In App.js in a new project
import * as React from 'react';
import { View, Text } from 'react-native';
import { Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from 'react-router-dom';
import LFIntroScreen from './screens/LFIntroScreen';
import { StatusBar } from 'expo-status-bar';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import { useFonts } from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import {
  Provider as AntRnProvider,
  Drawer,
  Flex,
} from '@ant-design/react-native';

import { AuthProvider, PrivateRoute, useAuth } from './hooks/auth';
import Parse from 'parse';
import Constants from 'expo-constants';
import LoginScreen from './screens/LoginScreen';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import RelayEnvironment from './data/RelayEnvironment';
import SuspenseFallbackScreen from './screens/SuspenseFallbackScreen';
import { ReactElement } from 'react-router/node_modules/@types/react';
import PathEditorScreen from './screens/PathEditorScreen';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import FloatingCreateButton from './components/FloatingCreateButton';
import FloatFullView from './components/FloatFullView';
import TraversalScreen from './screens/TraversalScreen';
import NotePlayerScreen from './screens/NotePlayer/NotePlayerScreen';
import HomeScreen from './screens/HomeScreen';
import SearchScreen from './screens/SearchScreen';
import DrawerScreen, { isDrawerOpenState } from './screens/DrawerScreen';
import { RecoilRoot, useRecoilState } from 'recoil';
import NewNoteScreen from './screens/NewNoteScreen';
import { isMobile } from 'react-device-detect';

import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.locale('ko'); // global로 한국어 locale 사용
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
import enUS from '@ant-design/react-native/lib/locale-provider/en_US';
import NotificationScreen from './screens/NotificationScreen';
import ProfileEditorScreen from './screens/ProfileEditorScreen';
import EmojiReactionListScreen from './screens/EmojiReactionListScreen';
import RecentHistoryScreen from './screens/RecentHistoryScreen';
import MyPathScreen from './screens/MyPathScreen';
import MyContentItemScreen from './screens/MyContentItemScreen';
import BookmarkletScreen from './screens/BookmarkletScreen';
import LearningDoneListScreen from './screens/LearningDoneListScreen';
import { Helmet } from 'react-helmet';
import MembershipPlanAndPricingScreen from './screens/MembershipPlanAndPricingScreen';
import MembershipPaymentSuccessScreen from './screens/MembershipPaymentSuccessScreen';
import MembershipStatusScreen from './screens/MembershipStatusScreen/MembershipStatusScreen';
import MarkdownScreen from './screens/MarkdownScreen';
import NoteScreen from './screens/NoteScreen';
import TagScreen from './screens/TagScreen';
import LFText from './components/typo/LFText';
import AllPathListScreen from './screens/AllPathListScreen';
import AllContentItemListScreen from './screens/AllContentItemListScreen';
import ProfileScreen from './screens/ProfileScreen';
import UserPathListScreen from './screens/UserPathListScreen';
import UserContentItemListScreen from './screens/UserContentItemListScreen';
import MembershipManagementScreen from './screens/MembershipManagementScreen';
import ErrorScreen from './screens/ErrorScreen/ErrorScreen';
import PositionSelectorScreen from './screens/PositionSelectorScreen';
import InterestingSelectorScreen from './screens/InterestingSelectorScreen';
import RendertronPathListScreen from './screens/RendertronPathListScreen';
import RendertronContentItemListScreen from './screens/RendertronContentItemListScreen';
import DeviceTypeProvider from './hooks/deviceType';
import _ from 'lodash';

console.log(enUS);
//@ts-ignore
Parse.initialize(Constants.manifest?.extra?.REACT_APP_PARSE_APP_ID);
//@ts-ignore
Parse.serverURL = Constants.manifest?.extra?.REACT_APP_PARSE_SERVER_URL;
// Parse.serverURL = "https://api-staging.laivdata.kr/api";
//@ts-ignore
Parse.Object.disableSingleInstance();

function App() {
  if (process.env.NODE_ENV === 'production') {
    console.log = () => {};
  }

  // const isLoadingComplete = useCachedResources();
  const [isFontLoaded] = useFonts({
    'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
    Pretendard: require('./assets/fonts/Pretendard-Regular.otf'),
    ...Ionicons.font,
  });

  useEffect(() => {
    let wakeLockPromise: any;
    if (isMobile && (navigator as any).wakeLock) {
      wakeLockPromise = (navigator as any).wakeLock.request('screen');
    }
    return () => {
      if (wakeLockPromise) {
        wakeLockPromise.then((r: any) => r.release && r.release());
      }
    };
  }, []);

  //@ts-ignore
  if (!isFontLoaded) {
    return null;
  } else {
    return (
      <GTMProvider
        state={{
          id: Constants.manifest?.extra?.REACT_APP_GTM_ID,
          environment: {
            gtm_auth: Constants.manifest?.extra?.REACT_APP_GTM_ENV_AUTH,
            gtm_preview: Constants.manifest?.extra?.REACT_APP_GTM_ENV_PREVIEW,
          },
        }}
      >
        <Helmet>
          <meta name="theme-color" content="#fff" />
        </Helmet>
        <RecoilRoot>
          <RelayEnvironmentProvider environment={RelayEnvironment}>
            <MainReactRouter />
          </RelayEnvironmentProvider>
        </RecoilRoot>
      </GTMProvider>
    );
  }
}

const MainReactRouter = () => {
  return (
    <Router>
      <DeviceTypeProvider>
        <Suspense fallback={<SuspenseFallbackScreen showNavLayout />}>
          <AuthProvider>
            <ErrorBoundary
              noDataFallback={
                // <ErrorScreen
                //   style={{ maxWidth: 720, width: '100%', margin: 'auto' }}
                //   title="앗! 콘텐츠를 찾을 수 없어요."
                //   description={`“콘텐츠가 삭제되었거나, 비공개 처리되었어요.\n다른 학습콘텐츠를 찾아보세요.”`}
                //   iconType="content"
                // />
                (retry) => (
                  <ErrorScreen
                    style={{ maxWidth: 720, width: '100%', margin: 'auto' }}
                    title="앗! 콘텐츠를 찾을 수 없어요."
                    description={`“콘텐츠가 삭제되었거나, 비공개 처리되었어요.\n다른 학습콘텐츠를 찾아보세요.”`}
                    iconType="content"
                    rightButtonOptions={{
                      onPress: () => {
                        // window.history.pushState(null, '', '/');
                        retry();
                        window.location.href = 'https://www.learnfit.ai';
                      },
                    }}
                  />
                )
                // <Flex style={{ flex: 1 }} align="center" justify="center">
                //   <LFText>비공개 이거나 존재하지 않는 페이지</LFText>
                // </Flex>
              }
              fallback={(retry, errorMessage) => (
                <ErrorScreen
                  style={{ maxWidth: 720, width: '100%', margin: 'auto' }}
                  title="앗! 문제가 발생했어요."
                  description={`“😱문제가 발생했습니다.잠시 후에 다시 시도해 주세요.🙇‍♂️"\n ${errorMessage.slice(
                    0,
                    100
                  )}`}
                  iconType="network"
                  rightButtonOptions={{
                    onPress: () => {
                      // window.history.pushState(null, '', '/');
                      retry();
                      window.location.href = 'https://www.learnfit.ai';
                    },
                  }}
                />
              )}
              invalidSessionTokenFallback={
                <LoginScreen forceLogout={true} message="로그인 문제 발생" />
              }
            >
              <ScrollToTop />
              <AntRnProvider
                locale={{
                  ...enUS,
                  Modal: {
                    buttonText: 'Button',
                    okText: '확인',
                    cancelText: '취소',
                  },
                }}
                theme={{
                  v_spacing_xs: 4,
                  v_spacing_sm: 8,
                  v_spacing_md: 12,
                  v_spacing_lg: 16,
                  v_spacing_xl: 24,
                  v_spacing_xxl: 32,
                  v_spacing_xxxl: 40,
                }}
              >
                <PcWrap exceptPath="/note/:contentId/:contentItemId">
                  <ActionSheetProvider>
                    <Suspense
                      fallback={<SuspenseFallbackScreen showNavLayout />}
                    >
                      <Switch>
                        <Route path="/" exact>
                          <HomeScreen />
                        </Route>
                        <PrivateRoute path="/path-edit/:pathId?">
                          <PathEditorScreen />
                        </PrivateRoute>
                        <PrivateRoute path="/u/:userObjectId/membership/status">
                          <MembershipStatusScreen />
                        </PrivateRoute>
                        <Route path="/u/:userObjectId/membership">
                          <MembershipPlanAndPricingScreen />
                        </Route>
                        <PrivateRoute path="/u/:userObjectId/payment-success">
                          <MembershipPaymentSuccessScreen />
                        </PrivateRoute>
                        <PrivateRoute path="/u/:userObjectId/payment-fail">
                          <View>
                            <LFText>
                              결제에 실패했습니다. 나중에 다시 시도해주세요.
                            </LFText>
                          </View>
                        </PrivateRoute>

                        <Route
                          path="/u/:userObjectId/paths"
                          component={UserPathListScreen}
                        />
                        <Route
                          path="/u/:userObjectId/notes"
                          component={UserContentItemListScreen}
                        />
                        <Route path="/u/:userObjectId">
                          <ProfileScreen />
                        </Route>
                        <Route
                          path="/notes/curation"
                          component={AllContentItemListScreen}
                        />
                        <Route
                          path="/paths/curation"
                          component={AllPathListScreen}
                        />
                        <Route path="/notes" component={HomeScreen} />
                        <Route path="/paths" component={HomeScreen} />
                        <MeRoute path="/me" />
                        <Route path="/path/:pathId">
                          <TraversalScreen />
                        </Route>
                        <Route path="/login" component={LoginScreen} />
                        <PrivateRoute path="/position-selector">
                          <PositionSelectorScreen />
                        </PrivateRoute>
                        <PrivateRoute path="/interesting-selector/:positionId">
                          <InterestingSelectorScreen />
                        </PrivateRoute>
                        <Route path="/rendertron/paths">
                          <RendertronPathListScreen />
                        </Route>
                        <Route path="/rendertron/notes">
                          <RendertronContentItemListScreen />
                        </Route>

                        <Route path="/">
                          <Wrap100vh>
                            <Switch>
                              <Route path="/note/:contentId/:contentItemId">
                                <NoteScreen />
                              </Route>

                              <Route path="/search">
                                <SearchScreen />
                              </Route>
                              <Route path="/about">
                                <MarkdownScreen mdId="about" />
                              </Route>
                              <Route path="/newsletter">
                                <MarkdownScreen mdId="newsletter" />
                              </Route>
                              {/* <PrivateRoute path="/note/:contentItemId?">
                          <NoteScreen />
                        </PrivateRoute> */}

                              <Route path="/new-note">
                                <NewNoteScreen />
                              </Route>

                              <Route path="/login" component={LoginScreen} />
                              <PrivateRoute path="/notification">
                                <NotificationScreen />
                              </PrivateRoute>
                              {/* https://www.youtube.com/feed/history */}
                              <Route
                                path="/bookmarklet"
                                component={BookmarkletScreen}
                              />
                              <Route
                                path="/post/:mdId"
                                component={MarkdownScreen}
                              />
                              <Route path="/tag/:tag" component={TagScreen} />
                              <Route
                                path="/error/404"
                                render={() => (
                                  <ErrorScreen
                                    title="페이지를 찾을 수 없습니다."
                                    statusCode="404"
                                    description={
                                      '“지금 입력하신 주소의 페이지는\n사라졌거나 변경되었습니다. 주소를 다시 확인해 주세요.”'
                                    }
                                  />
                                )}
                              />
                              <Route
                                path="*"
                                render={() => (
                                  <ErrorScreen
                                    title="페이지를 찾을 수 없습니다."
                                    statusCode="404"
                                    description={
                                      '“지금 입력하신 주소의 페이지는\n사라졌거나 변경되었습니다. 주소를 다시 확인해 주세요.”'
                                    }
                                  />
                                )}
                              />
                            </Switch>
                          </Wrap100vh>
                        </Route>
                      </Switch>
                    </Suspense>
                  </ActionSheetProvider>
                </PcWrap>
              </AntRnProvider>
              <StatusBar />
            </ErrorBoundary>
          </AuthProvider>
        </Suspense>
      </DeviceTypeProvider>
    </Router>
  );
};

const Wrap100vh: React.FC = ({ children }) => (
  <View style={{ height: '100vh' }}>{children}</View>
);

const MeRoute: React.FC<{
  path: string;
}> = ({ path }) => {
  const { user } = useAuth();
  return (
    <PrivateRoute path={path}>
      <Switch>
        <Route path="/me/edit">
          <ProfileEditorScreen />
        </Route>
        <Route path="/me/note">
          <MyContentItemScreen />
        </Route>
        <Route path="/me/emoji">
          <EmojiReactionListScreen />
        </Route>
        <Route path="/me/path">
          <MyPathScreen />
        </Route>
        <Route path="/me/recent">
          <RecentHistoryScreen />
        </Route>
        <Route path="/me/done-note">
          <LearningDoneListScreen
            title="완료한 학습노트"
            type="contentItem"
            status="done"
          />
        </Route>
        <Route path="/me/done-path">
          <LearningDoneListScreen
            title="완료한 러닝패스"
            type="path"
            status="done"
          />
        </Route>
        <Route path="/me/in-progress-path">
          <LearningDoneListScreen
            title="진행중인 러닝패스"
            type="path"
            status="in_progress"
          />
        </Route>
        <Route path="/me/membership">
          <MembershipManagementScreen />
        </Route>

        <Redirect from="/me" to={`/u/${user?.objectId}`} />
      </Switch>
    </PrivateRoute>
  );
};
const PcWrap: React.FC<{
  exceptPath: string;
}> = ({ children, exceptPath }) => {
  return (
    <Switch>
      {exceptPath ? <Route path={exceptPath}>{children}</Route> : null}
      <Route path="*">
        <View
          style={{
            maxWidth: 720,
            margin: 'auto',
            flex: 1,
            alignItems: 'stretch',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </View>
      </Route>
    </Switch>
  );
  // return shouldWrap ? (
  //   <View
  //     style={{
  //       flex: 1,
  //       height: '100vh',
  //       justifyContent: 'center',
  //       flexDirection: 'row',
  //     }}
  //   >
  //     <View
  //       style={{
  //         width: 720,
  //         height: '100vh',
  //         borderLeftWidth: 1,
  //         borderRightWidth: 1,
  //         borderColor: '#ccc',
  //       }}
  //     >
  //       {children}
  //     </View>
  //     {
  //       //bannerVisible ? Banner : null
  //     }
  //   </View>
  // ) : (
  //   // <SafeAreaView style={{ backgroundColor: 'white' }}>{children}</SafeAreaView>
  //   // <View style={{ height: window.height }}>{children}</View>
  //   children
  // );
};

const Banner = () => {
  return (
    <View
      style={{
        width: 360,
        padding: 40,
      }}
    >
      <View
        style={{
          width: '100%',
          height: 280,
          borderColor: '#ccc',
          borderWidth: 1,
        }}
      ></View>
    </View>
  );
};

class ErrorBoundary extends React.Component<{
  noDataFallback?: ReactElement | ((retry: () => void) => ReactElement);
  fallback?:
    | ReactElement
    | ((retry: () => void, errorMessage: string) => ReactElement);
  invalidSessionTokenFallback?: ReactElement;
}> {
  state = { hasError: false, error: null };
  //@ts-ignore
  static getDerivedStateFromError(error) {
    console.debug(error);
    return {
      hasError: true,
      error,
    };
  }
  render() {
    if (this.state.hasError) {
      console.log(this.state);
      //@ts-ignore
      if (this.state.error?.messageFormat?.startsWith('No data')) {
        return _.isFunction(this.props.noDataFallback)
          ? this.props.noDataFallback(() => {
              this.setState({ error: null, hasError: false });
            })
          : this.props.noDataFallback;
        //@ts-ignore
      } else if (this.state.error.code === Parse.Error.INVALID_SESSION_TOKEN) {
        return this.props.invalidSessionTokenFallback;
      }
      return this.props.fallback(() => {
        this.setState({ error: null, hasError: false });
      }, this.state.error?.stack);
    }
    return this.props.children;
  }
}

function ScrollToTop() {
  const history = useHistory();
  React.useEffect(() => {
    const unListen = history.listen((location, action) => {
      console.log('ROUTER ACTION', action);
      if (action !== 'POP') {
        window.scrollTo(0, 0);
      }
    });
    return () => unListen();
  }, []);
  return null;
}

export default App;
