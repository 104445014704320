import React from 'react';
import { Linking, StyleSheet, Text, TextProps, View } from 'react-native';
import HyperLink from 'react-native-hyperlink';
import { OsTypes } from 'react-device-detect';

const LFText: React.FC<TextProps> = ({ style, children, ...props }) => {
  return (
    <Text style={[styles.default, style]} selectable {...props}>
      {children}
    </Text>
  );
};

export const LFHyperLink: React.FC = ({ children }) => {
  return (
    <HyperLink
      onPress={(url) => {
        Linking.canOpenURL(url)
          .then(() => {
            window.open(url, '_blank')?.focus();
          })
          .catch(() => {
            console.log('열 수 없는 URL');
          });
      }}
      // @ts-ignore
      linkStyle={{ color: '#2F80ED', display: 'inline' }}
    >
      {children}
    </HyperLink>
  );
};

export const LFBoldText: React.FC = ({ children }) => {
  return <LFText style={{ fontWeight: 'bold' }}>{children}</LFText>;
};

export default LFText;

const styles = StyleSheet.create({
  default: OsTypes.IOS
    ? {}
    : {
        fontFamily: 'Pretendard',
      },
});
