import { ActivityIndicator, StyleSheet, View, ViewStyle } from 'react-native';
import React, { Suspense, useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import _ from 'lodash';
import { Flex } from '@ant-design/react-native';
import HomeSectionView from '../screens/HomeScreen/HomeSectionView';
import LFWhiteSpace from './LFWhiteSpace';
import {
  BaseHomeSectionListPaginationFragmentQuery,
  HomeSectionOrder,
  HomeSectionWhereInput,
} from '../__generated__/BaseHomeSectionListPaginationFragmentQuery.graphql';
import { BaseHomeSectionListPaginationFragment$key } from '../__generated__/BaseHomeSectionListPaginationFragment.graphql';
import { BaseHomeSectionListQuery } from '../__generated__/BaseHomeSectionListQuery.graphql';

export const MORE_NUMBER = 20;

const BaseHomeSectionList: React.FC<{
  initialScrollTop?: number;
  where: HomeSectionWhereInput;
  order: HomeSectionOrder[];
  first?: number;
  noLoadNext?: boolean;
  style?: ViewStyle;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  onChangePagination?: (count: number) => void;
}> = ({
  initialScrollTop = 0,
  where,
  order,
  first = 20,
  noLoadNext,
  style,
  ListEmptyComponent,
  onChangePagination,
}) => {
  const homeSectionsRef = useLazyLoadQuery<BaseHomeSectionListQuery>(
    graphql`
      query BaseHomeSectionListQuery(
        $where: HomeSectionWhereInput!
        $order: [HomeSectionOrder!]
        $first: Int
        $after: String
      ) {
        ...BaseHomeSectionListPaginationFragment
      }
    `,
    {
      where,
      first,
      order,
    }
  );

  const homeSectionPagination = usePaginationFragment<
    BaseHomeSectionListPaginationFragmentQuery,
    BaseHomeSectionListPaginationFragment$key
  >(
    graphql`
      fragment BaseHomeSectionListPaginationFragment on Query
      @refetchable(queryName: "BaseHomeSectionListPaginationFragmentQuery") {
        homeSections(
          where: $where
          first: $first
          after: $after
          order: $order
        ) @connection(key: "pagination_base_homeSections") {
          count
          edges {
            node {
              id
              objectId
              ...HomeSectionView_Fragment
            }
          }
        }
      }
    `,
    homeSectionsRef
  );

  const homeSections = _.map(
    homeSectionPagination.data.homeSections?.edges,
    (e) => e?.node
  );

  const onHomeSectionEndReached = () => {
    if (homeSectionPagination.hasNext) {
      homeSectionPagination.loadNext(first);
    }
  };

  const renderHomeSection = (index: number, item: typeof homeSections[0]) => {
    const isLastRow = _.last(homeSections) === item;
    const isSuspenseFree = [0, 1].includes(_.indexOf(homeSections, item));
    return isSuspenseFree ? (
      <HomeSectionView key={index} fragmentKey={item ?? null} />
    ) : (
      <Suspense
        fallback={
          <>
            <LFWhiteSpace size={96} />
            <ActivityIndicator />
            <LFWhiteSpace size={96} />
          </>
        }
      >
        <HomeSectionView key={index} fragmentKey={item ?? null} />
        {!isLastRow && <LFWhiteSpace size="xl" />}
      </Suspense>
    );
  };

  const emptyElement = ListEmptyComponent ? (
    React.isValidElement(ListEmptyComponent) ? (
      ListEmptyComponent
    ) : (
      <ListEmptyComponent />
    )
  ) : null;

  useEffect(() => {
    _.isFunction(onChangePagination) &&
      onChangePagination(
        homeSectionPagination.data.homeSections.edges?.length || first
      );
  }, [homeSectionPagination.data.homeSections.edges]);

  return homeSections.length === 0 ? (
    emptyElement
  ) : noLoadNext ? (
    <Flex direction="column" align="stretch" style={style}>
      {_.map(homeSections, (item, index) => renderHomeSection(index, item))}
    </Flex>
  ) : (
    <View style={style}>
      <Virtuoso
        initialScrollTop={initialScrollTop}
        useWindowScroll
        data={homeSections}
        endReached={onHomeSectionEndReached}
        itemContent={renderHomeSection}
      />
    </View>
  );
};

export default BaseHomeSectionList;

const styles = StyleSheet.create({});
