import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { View, Text } from 'react-native';
import { Helmet } from 'react-helmet';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

interface LdJson {
  headline: string; // content title
  datePublished: string; // content created Date
  dateModified: string; // content updated Date
}

// https://ogp.me/
export interface LFMetaTagsProps {
  title: string | null;
  description: string | null;
  image?: string | null;
  url?: string;
  type?: 'website' | 'article' | 'profile';
  twitterCard?: string;
  authorName?: string | null;
  authorId?: string | null;
  objectId?: string | null;
  tags?: string[] | null;
  ldJson?: LdJson;
  canonicalLink?: string;
}

const LFMetaTags: React.FC<LFMetaTagsProps> = ({
  title,
  description,
  image,
  type = 'website',
  twitterCard = 'summary',
  url,
  authorName,
  authorId,
  objectId,
  tags,
  ldJson,
  canonicalLink,
}) => {
  // url || window.location.origin + window.location.pathname;
  const activeRef = useRef(false);
  const sendDataToGTM = useGTMDispatch();
  const addPostFix = ({
    original,
    postFix = '...',
    maxLength = 0,
  }: {
    original: string;
    postFix?: string;
    maxLength?: number;
  }) => {
    if (original.length >= maxLength) {
      return original.slice(0, maxLength - postFix.length) + postFix;
    }
    return original;
  };

  return (
    <Helmet
      onChangeClientState={() => {
        if (activeRef.current) return;
        sendDataToGTM({
          event: 'customRenderingComplete',
          gtm: { videoCurrentTime: null, videoPercent: null },
        });
        activeRef.current = true;
      }}
    >
      <title>
        {title ? addPostFix({ original: title, maxLength: 65 }) : null}
      </title>
      {description ? (
        <meta
          name="description"
          content={addPostFix({ original: description, maxLength: 300 })}
        />
      ) : null}

      {/* @ts-ignore // twitter 카드 형식 : https://developer.twitter.com/en/docs/twitter-for-websites/cards/guides/getting-started */}
      <meta name="twitter:card" value={twitterCard} />

      {/* <!-- Open Graph data --> */}
      <meta
        property="og:title"
        content={
          title ? addPostFix({ original: title, maxLength: 65 }) : 'LearnFit'
        }
      />
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta
        property="og:image"
        content={
          image ||
          'https://file.learnfit.ai/u/ef6f73fb22bfb59b6b016302d5c3b3ff_image%403x.png'
        }
      />
      <meta
        property="og:description"
        content={
          description
            ? addPostFix({ original: description, maxLength: 300 })
            : '오직 나에게 맞춘 지식 공유 플랫폼'
        }
      />

      {authorName ? (
        <meta property={`${type}:author`} content={authorName} />
      ) : null}
      {authorId ? (
        <meta property={`${type}:author_id`} content={authorId} />
      ) : null}
      {objectId ? (
        <meta property={`${type}:object_id`} content={objectId} />
      ) : null}
      {tags ? <meta property={`${type}:tag`} content={tags.join(',')} /> : null}
      {ldJson && (
        <script type="application/ld+json">
          {JSON.stringify({
            ...{
              '@context': 'https://schema.org',
              '@type': 'LearningResource',
            },
            ...ldJson,
          })}
        </script>
      )}

      {canonicalLink && <link rel="canonical" href={canonicalLink}></link>}
    </Helmet>
  );
};

export default React.memo(LFMetaTags);
