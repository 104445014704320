import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import { useAuth } from '../hooks/auth';
import { useHistory, useLocation } from 'react-router';
import GoogleLogin, { GoogleLoginResponse } from 'react-google-login';
import Constants from 'expo-constants';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { Ionicons } from '@expo/vector-icons';
import LFLink from '../components/LFLink';
import { resizedImageURL } from '../helper/resizedImageURL';
import LFText from '../components/typo/LFText';

export const isDrawerOpenState = atom({
  key: 'isDrawerOpen', // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value)
});

const DrawerScreen: React.FC = () => {
  const { user, logout, loginWithGoogle } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  const clientId = Constants.manifest?.extra?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(isDrawerOpenState);

  const responseGoogle = (res: GoogleLoginResponse) => {
    const {
      googleId,
      tokenId,
      profileObj: { email, name, imageUrl },
    } = res;
    if (res.googleId && res.tokenId) {
      loginWithGoogle({
        googleId,
        tokenId,
        email,
        name,
        originProfileURL: imageUrl,
      }).then(() => {
        setIsDrawerOpen(false);
        // history.replace(from || { pathname: '/' });
      });
    }
  };

  return (
    <Flex
      direction="column"
      align="stretch"
      style={{ padding: 15, backgroundColor: '#fff', height: '100%' }}
    >
      <TouchableOpacity
        style={{
          position: 'absolute',
          right: 0,
          top: 0,
          width: 60,
          height: 60,
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
        onPress={() => {
          setIsDrawerOpen(false);
        }}
      >
        <Ionicons name="close-outline" size={30} color="black" />
      </TouchableOpacity>
      {user ? (
        <>
          <Flex direction="row" justify="start" align="start">
            <View
              style={{
                width: 72,
                height: 75,
                marginVertical: 12,
              }}
            >
              <Image
                accessibilityLabel=""
                style={styles.userImage}
                source={
                  user?.profileImage || user?.originProfileURL
                    ? resizedImageURL(
                        user.profileImage?.url,
                        200,
                        200,
                        user.originProfileURL
                      )
                    : require('../assets/images/userProfile.png')
                }
              />
            </View>
            <Flex direction="column" style={{ marginTop: 28, marginLeft: 12 }}>
              <View style={{ marginBottom: 5 }}>
                <LFText>안녕하세요.</LFText>
              </View>
              <Flex direction="row">
                <LFText style={[styles.highLightTxt, { marginRight: 8 }]}>
                  {user?.name}
                </LFText>
                <LFText>님</LFText>
              </Flex>
            </Flex>
          </Flex>

          <WhiteSpace size="lg" />

          {/* 구분선 */}
          <View
            style={{
              width: '100%',
              borderBottomWidth: 0.5,
              borderBottomColor: 'gray',
            }}
          />

          <WhiteSpace size="lg" />

          <LFLink
            testID="menu-mypage:DrawerScreen"
            onPress={() => {
              setIsDrawerOpen(false);
              history.push('/me');
            }}
          >
            <LFText style={styles.highLightTxt}>내 보관함</LFText>
          </LFLink>
        </>
      ) : (
        <>
          <GoogleLogin
            // testID="google-login-btn:DrawerScreen"
            clientId={clientId}
            //@ts-ignore
            buttonText={<LFText>구글 계정으로 로그인</LFText>}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            style={{ width: '100%' }}
          />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
          <WhiteSpace size="lg" />
        </>
      )}

      <WhiteSpace size="lg" />

      {/* 구분선 */}
      <View
        style={{
          width: '100%',
          borderBottomWidth: 0.5,
          borderBottomColor: 'gray',
        }}
      />

      <WhiteSpace size="lg" />

      <LFLink
        testID="menu-home:DrawerScreen"
        onPress={() => {
          setIsDrawerOpen(false);
          history.push('/');
        }}
      >
        <LFText>홈</LFText>
      </LFLink>

      <WhiteSpace size="lg" />

      {/* 구분선 */}
      <View
        style={{
          width: '100%',
          borderBottomWidth: 0.5,
          borderBottomColor: 'gray',
        }}
      />

      <WhiteSpace size="md" />

      {/* TODO: 런핏 홈화면에서 러닝패스 탭 눌렀을 때 위치 */}
      <LFLink
        testID="menu-learningpath:DrawerScreen"
        onPress={() => {
          setIsDrawerOpen(false);
          pathname === '/'
            ? history.replace({ pathname: '/', state: { tabIdx: 1 } })
            : history.push({ pathname: '/', state: { tabIdx: 1 } });
        }}
      >
        <LFText>러닝패스</LFText>
      </LFLink>

      <WhiteSpace size="lg" />

      {/* TODO: 런핏 홈화면에서 학습노트 탭 눌렀을 때 위치 */}
      <LFLink
        testID="menu-content-item:DrawerScreen"
        onPress={() => {
          setIsDrawerOpen(false);
          pathname === '/'
            ? history.replace({ pathname: '/', state: { tabIdx: 2 } })
            : history.push({ pathname: '/', state: { tabIdx: 2 } });
        }}
      >
        <LFText>학습노트</LFText>
      </LFLink>

      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />

      {user ? (
        <>
          {/* 구분선 */}
          <View
            style={{
              width: '100%',
              borderBottomWidth: 0.5,
              borderBottomColor: 'gray',
            }}
          />

          <WhiteSpace size="lg" />

          <LFLink
            testID="menu-logout:DrawerScreen"
            onPress={() => {
              logout();
            }}
          >
            <LFText>로그아웃</LFText>
          </LFLink>
        </>
      ) : null}
    </Flex>
  );
};

export default DrawerScreen;

const styles = StyleSheet.create({
  userImage: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    borderRadius: 50,
  },
  highLightTxt: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#263238',
  },
});
