/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CommentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    text?: StringWhereInput | null;
    parent?: CommentRelationWhereInput | null;
    path?: PathRelationWhereInput | null;
    contentItem?: ContentItemRelationWhereInput | null;
    targetAuthor?: UserRelationWhereInput | null;
    author?: UserRelationWhereInput | null;
    targetClassName?: StringWhereInput | null;
    comments?: CommentRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<CommentWhereInput> | null;
    AND?: Array<CommentWhereInput> | null;
    NOR?: Array<CommentWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type CommentRelationWhereInput = {
    have?: CommentWhereInput | null;
    haveNot?: CommentWhereInput | null;
    exists?: boolean | null;
};
export type PathRelationWhereInput = {
    have?: PathWhereInput | null;
    haveNot?: PathWhereInput | null;
    exists?: boolean | null;
};
export type PathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    contentItemList?: ArrayWhereInput | null;
    author?: UserRelationWhereInput | null;
    learnCount?: NumberWhereInput | null;
    published?: BooleanWhereInput | null;
    description?: StringWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    analytics?: AnalyticsPathRelationWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    draft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathWhereInput> | null;
    AND?: Array<PathWhereInput> | null;
    NOR?: Array<PathWhereInput> | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type UserRelationWhereInput = {
    have?: UserWhereInput | null;
    haveNot?: UserWhereInput | null;
    exists?: boolean | null;
};
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type InstructionCardRelationWhereInput = {
    have?: InstructionCardWhereInput | null;
    haveNot?: InstructionCardWhereInput | null;
    exists?: boolean | null;
};
export type InstructionCardWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    path?: unknown | null;
    sources?: ArrayWhereInput | null;
    seq?: NumberWhereInput | null;
    optional?: BooleanWhereInput | null;
    pathDraft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<InstructionCardWhereInput> | null;
    AND?: Array<InstructionCardWhereInput> | null;
    NOR?: Array<InstructionCardWhereInput> | null;
};
export type PathDraftRelationWhereInput = {
    have?: PathDraftWhereInput | null;
    haveNot?: PathDraftWhereInput | null;
    exists?: boolean | null;
};
export type PathDraftWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    original?: PathRelationWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    description?: StringWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    progressing?: BooleanWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathDraftWhereInput> | null;
    AND?: Array<PathDraftWhereInput> | null;
    NOR?: Array<PathDraftWhereInput> | null;
};
export type MembershipRelationWhereInput = {
    have?: MembershipWhereInput | null;
    haveNot?: MembershipWhereInput | null;
    exists?: boolean | null;
};
export type MembershipWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    level?: StringWhereInput | null;
    role?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<MembershipWhereInput> | null;
    AND?: Array<MembershipWhereInput> | null;
    NOR?: Array<MembershipWhereInput> | null;
};
export type RoleRelationWhereInput = {
    have?: RoleWhereInput | null;
    haveNot?: RoleWhereInput | null;
    exists?: boolean | null;
};
export type RoleWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    name?: StringWhereInput | null;
    users?: UserRelationWhereInput | null;
    roles?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<RoleWhereInput> | null;
    AND?: Array<RoleWhereInput> | null;
    NOR?: Array<RoleWhereInput> | null;
};
export type AnalyticsPathRelationWhereInput = {
    have?: AnalyticsPathWhereInput | null;
    haveNot?: AnalyticsPathWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsPathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    path?: PathRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsPathWhereInput> | null;
    AND?: Array<AnalyticsPathWhereInput> | null;
    NOR?: Array<AnalyticsPathWhereInput> | null;
};
export type ContentItemRelationWhereInput = {
    have?: ContentItemWhereInput | null;
    haveNot?: ContentItemWhereInput | null;
    exists?: boolean | null;
};
export type ContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    content?: ContentRelationWhereInput | null;
    noteData?: ArrayWhereInput | null;
    tags?: ArrayWhereInput | null;
    published?: BooleanWhereInput | null;
    md?: StringWhereInput | null;
    analytics?: AnalyticsContentItemRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentItemWhereInput> | null;
    AND?: Array<ContentItemWhereInput> | null;
    NOR?: Array<ContentItemWhereInput> | null;
};
export type ContentRelationWhereInput = {
    have?: ContentWhereInput | null;
    haveNot?: ContentWhereInput | null;
    exists?: boolean | null;
};
export type ContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    thumbURL?: StringWhereInput | null;
    type?: StringWhereInput | null;
    link?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    rawMetadata?: ObjectWhereInput | null;
    analytics?: AnalyticsContentRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentWhereInput> | null;
    AND?: Array<ContentWhereInput> | null;
    NOR?: Array<ContentWhereInput> | null;
};
export type AnalyticsContentRelationWhereInput = {
    have?: AnalyticsContentWhereInput | null;
    haveNot?: AnalyticsContentWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    content?: ContentRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    contentItemCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    contentItemAuthors?: ArrayWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentWhereInput> | null;
    AND?: Array<AnalyticsContentWhereInput> | null;
    NOR?: Array<AnalyticsContentWhereInput> | null;
};
export type AnalyticsContentItemRelationWhereInput = {
    have?: AnalyticsContentItemWhereInput | null;
    haveNot?: AnalyticsContentItemWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    contentItem?: ContentItemRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentItemWhereInput> | null;
    AND?: Array<AnalyticsContentItemWhereInput> | null;
    NOR?: Array<AnalyticsContentItemWhereInput> | null;
};
export type CommentsQueryVariables = {
    where: CommentWhereInput;
};
export type CommentsQueryResponse = {
    readonly comments: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly createdAt: unknown;
                readonly text: string | null;
                readonly author: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
                readonly contentItem: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
                readonly path: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
                readonly targetClassName: string | null;
                readonly children: {
                    readonly count: number;
                };
                readonly " $fragmentRefs": FragmentRefs<"CommentFragment">;
            } | null;
        } | null> | null;
    };
};
export type CommentsQuery = {
    readonly response: CommentsQueryResponse;
    readonly variables: CommentsQueryVariables;
};



/*
query CommentsQuery(
  $where: CommentWhereInput!
) {
  comments(where: $where, order: [createdAt_DESC]) {
    count
    edges {
      node {
        id
        objectId
        createdAt
        text
        author {
          id
          objectId
        }
        contentItem {
          id
          objectId
        }
        path {
          id
          objectId
        }
        targetClassName
        children: comments {
          count
        }
        ...CommentFragment
      }
    }
  }
}

fragment CommentFragment on Comment {
  id
  objectId
  text
  createdAt
  author {
    id
    objectId
    name
    ...UserProfileImageFragment
  }
  path {
    id
    objectId
    author {
      id
      objectId
    }
  }
  contentItem {
    id
    objectId
    author {
      id
      objectId
    }
  }
  targetClassName
  comments(order: [createdAt_ASC]) {
    edges {
      node {
        id
        objectId
        createdAt
        text
        ...ReplyFragment
        author {
          id
          objectId
        }
      }
    }
  }
}

fragment ReplyFragment on Comment {
  id
  objectId
  createdAt
  text
  author {
    id
    objectId
    name
    ...UserProfileImageFragment
  }
  parent {
    id
    objectId
    text
  }
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      "createdAt_DESC"
    ]
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetClassName",
  "storageKey": null
},
v10 = {
  "alias": "children",
  "args": null,
  "concreteType": "CommentConnection",
  "kind": "LinkedField",
  "name": "comments",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originProfileURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FileInfo",
      "kind": "LinkedField",
      "name": "profileImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentConnection",
        "kind": "LinkedField",
        "name": "comments",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CommentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentItem",
                    "kind": "LinkedField",
                    "name": "contentItem",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Path",
                    "kind": "LinkedField",
                    "name": "path",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CommentFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CommentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CommentConnection",
        "kind": "LinkedField",
        "name": "comments",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CommentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Comment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentItem",
                    "kind": "LinkedField",
                    "name": "contentItem",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Path",
                    "kind": "LinkedField",
                    "name": "path",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "order",
                        "value": [
                          "createdAt_ASC"
                        ]
                      }
                    ],
                    "concreteType": "CommentConnection",
                    "kind": "LinkedField",
                    "name": "comments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CommentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Comment",
                                "kind": "LinkedField",
                                "name": "parent",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "comments(order:[\"createdAt_ASC\"])"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "664dee0fc2dbbe9506d779c8778fdf9c",
    "id": null,
    "metadata": {},
    "name": "CommentsQuery",
    "operationKind": "query",
    "text": "query CommentsQuery(\n  $where: CommentWhereInput!\n) {\n  comments(where: $where, order: [createdAt_DESC]) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        createdAt\n        text\n        author {\n          id\n          objectId\n        }\n        contentItem {\n          id\n          objectId\n        }\n        path {\n          id\n          objectId\n        }\n        targetClassName\n        children: comments {\n          count\n        }\n        ...CommentFragment\n      }\n    }\n  }\n}\n\nfragment CommentFragment on Comment {\n  id\n  objectId\n  text\n  createdAt\n  author {\n    id\n    objectId\n    name\n    ...UserProfileImageFragment\n  }\n  path {\n    id\n    objectId\n    author {\n      id\n      objectId\n    }\n  }\n  contentItem {\n    id\n    objectId\n    author {\n      id\n      objectId\n    }\n  }\n  targetClassName\n  comments(order: [createdAt_ASC]) {\n    edges {\n      node {\n        id\n        objectId\n        createdAt\n        text\n        ...ReplyFragment\n        author {\n          id\n          objectId\n        }\n      }\n    }\n  }\n}\n\nfragment ReplyFragment on Comment {\n  id\n  objectId\n  createdAt\n  text\n  author {\n    id\n    objectId\n    name\n    ...UserProfileImageFragment\n  }\n  parent {\n    id\n    objectId\n    text\n  }\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = '42448a76f2e49885bfc32caa95a78103';
export default node;
