import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useDropzone } from 'react-dropzone';
import { TouchableOpacity } from 'react-native-gesture-handler';

export interface FileInputProp {
  onChange: (file: File) => void;
  accept: string;
}
const FileInput: React.FC<FileInputProp> = ({ accept, onChange, children }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      // const reader = new FileReader();

      // reader.onabort = () => console.log('file reading was aborted');
      // reader.onerror = () => console.log('file reading has failed');
      // reader.onload = () => {
      //   // Do whatever you want with the file contents
      //   const binaryStr = reader.result;
      //   console.log(binaryStr);
      // };
      // reader.readAsArrayBuffer(file);

      onChange(file);
    });
  }, []);
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    multiple: false,
    accept: accept,
  });
  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {/* <p>Drag 'n' drop some files here</p> */}
      <TouchableOpacity onPress={open}>{children}</TouchableOpacity>
    </div>
  );
};

export default FileInput;

const styles = StyleSheet.create({});
