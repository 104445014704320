import { StyleSheet, Image, Text, View, LayoutRectangle } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { Flex, Modal } from '@ant-design/react-native';
import LFText from './typo/LFText';
import { useMobileDimensions } from '../hooks/useMobileDimensions';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from './LFWhiteSpace';
import TopRoundFlex from './TopRoundFlex';
import LFLink from './LFLink';

import { useAuth } from '../hooks/auth';
import { ModalProps } from '@ant-design/react-native/lib/modal/Modal';
import { useLocalStorageState } from 'ahooks';
import _ from 'lodash';

interface BottomDrawerModalProps extends ModalProps {
  noAgainId?: string;
  visible: boolean;
  scrollable?: boolean;
  noAgainButtonTestID?: string;
  closeButtonTestID?: string;
  onLayout?: (layout: LayoutRectangle) => void;
}
const BottomDrawerModal: React.FC<BottomDrawerModalProps> = ({
  noAgainId,
  children,
  visible = true,
  scrollable,
  noAgainButtonTestID,
  closeButtonTestID,
  onLayout,
  ...rest
}) => {
  const { width, height } = useMobileDimensions(); // modal 사이즈 결정에 사용

  const [noAgain, setNoAgain] = useLocalStorageState(
    'BottomDrawerModal_noAgainId_' + noAgainId,
    false
  );

  useEffect(() => {
    if (scrollable || noAgain) return;
    document.body.style.overflow = visible ? 'hidden' : 'auto';
    // safari에서 fixed가 되어야 overflow 적용됨
    document.body.style.position = visible ? 'fixed' : 'static';
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.position = 'static';
    };
  }, [visible]);

  return (
    <Modal
      // @ts-ignore
      style={{
        width: Math.min(width, 500),
        backgroundColor: 'transparent',
        margin: 'auto',
        marginBottom: 0,

        // mobile에서 모달이 url bar에 붙어있게 하기 위해서 적용
        position: 'sticky',
        bottom: 0,
      }}
      maskClosable={false}
      popup
      transparent={false}
      animationType="slide-up"
      {...rest}
      visible={noAgain ? false : visible}
    >
      <TopRoundFlex
        onLayout={(e) => {
          _.isFunction(onLayout) && onLayout(e.nativeEvent.layout);
        }}
        style={{
          paddingTop: 24,
          paddingHorizontal: 24,
        }}
      >
        <Flex direction="row" justify="between">
          {noAgainId ? (
            <LFLink
              testID={noAgainButtonTestID}
              onPress={() => {
                setNoAgain(true);
                rest.onClose && rest.onClose();
              }}
            >
              <LFText style={styles.noAgain}>다시 보지않기</LFText>
            </LFLink>
          ) : (
            <View />
          )}

          <LFLink testID={closeButtonTestID} onPress={rest.onClose}>
            <Image
              accessibilityLabel="모달 창 닫기"
              source={require('../assets/images/icons/closeIconBlack@3x.png')}
              style={{ width: 24, height: 24 }}
            />
          </LFLink>
        </Flex>
        <LFWhiteSpace size={'xl'} />
        {children}
      </TopRoundFlex>
    </Modal>
  );
};

export default BottomDrawerModal;

const styles = StyleSheet.create({
  noAgain: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
});
