import { StyleSheet } from 'react-native';
import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { NoteScreenQuery } from '../__generated__/NoteScreenQuery.graphql';
import { useParams } from 'react-router-dom';
import NotePlayerScreen from './NotePlayer/NotePlayerScreen';
import NoteEditorScreen from './NoteEditorScreen';
import { useAuth } from '../hooks/auth';
import ErrorScreen from './ErrorScreen/ErrorScreen';

const NoteScreen = () => {
  const { user } = useAuth();
  const { contentId, contentItemId } =
    useParams<{ contentId: string; contentItemId: string }>();
  const { content, contentItem } = useLazyLoadQuery<NoteScreenQuery>(
    graphql`
      query NoteScreenQuery($contentId: ID!, $contentItemId: ID!) {
        content(id: $contentId) {
          id
          type
        }
        contentItem(id: $contentItemId) {
          id
          published
          author {
            id
            objectId
          }
        }
      }
    `,
    {
      contentId,
      contentItemId,
    }
  );

  return contentItem.published === false &&
    contentItem.author?.objectId !== (user?.objectId || 'NO_LOGIN') ? (
    <ErrorScreen
      style={{ maxWidth: 720, width: '100%', margin: 'auto' }}
      title="앗! 비공개 학습노트 입니다."
      description={
        '작성자에 의해 비공개 상태로 변경되었습니다.\n다른 학습콘텐츠를 찾아보세요.'
      }
      iconType="content"
    />
  ) : content.type === 'VIDEO' ? (
    <NotePlayerScreen />
  ) : (
    <NoteEditorScreen
      style={{ maxWidth: 720, width: '100%', margin: 'auto' }}
    />
  );
};

export default NoteScreen;

const styles = StyleSheet.create({});
