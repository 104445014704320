import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  ViewStyle,
} from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import { ContentItemWhereInput } from '../__generated__/ReactionButtonQuery.graphql';
import LFText from './typo/LFText';
import _ from 'lodash';
import { BaseContentItemListQuery } from '../__generated__/BaseContentItemListQuery.graphql';
import { Flex } from '@ant-design/react-native';
import { BaseContentItemListPaginationFragment$key } from '../__generated__/BaseContentItemListPaginationFragment.graphql';
import {
  AnalyticsContentItemOrder,
  AnalyticsContentItemWhereInput,
  BaseContentItemListPaginationFragmentQuery,
} from '../__generated__/BaseContentItemListPaginationFragmentQuery.graphql';
import ContentItemView2 from './ContentItemView2';
import { colors } from '../constants/styleGuide';
import LFLink, { LFLinkTo } from './LFLink';
import ContentItemView3 from './ContentItemView3';
import {
  BaseContentItemListMyBookMarkReactionsQuery,
  ReactionWhereInput,
} from '../__generated__/BaseContentItemListMyBookMarkReactionsQuery.graphql';
import { useAuth } from '../hooks/auth';

export const MORE_NUMBER = 20;

const BaseContentItemList: React.FC<{
  initialScrollTop?: number;
  where: AnalyticsContentItemWhereInput;
  order: AnalyticsContentItemOrder[];
  first?: number;
  noLoadNext?: boolean;
  style?: ViewStyle;
  onPressItem?: (
    objectId: string | undefined,
    contentObjectId: string | undefined
  ) => void;
  onPressItemTo?: (
    objectId: string | undefined,
    contentObjectId: string | undefined
  ) => LFLinkTo;
  gutter?: number | [number, number];
  onChangeCount?: (count: number) => void;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  testID?: string;
  onChangePagination?: (count: number) => void;
  columnsPerRow?: number;
}> = ({
  initialScrollTop = 0,
  first = 20,
  order,
  noLoadNext,
  where,
  onPressItem,
  onPressItemTo,
  gutter = [9, 12],
  onChangeCount,
  style,
  ListEmptyComponent,
  testID,
  onChangePagination,
  columnsPerRow = 2,
}) => {
  const { user } = useAuth();
  const [horizontalGutter, verticalGutter] = [
    ..._.castArray(gutter),
    ..._.castArray(gutter),
  ];
  const contentItemQueryRef = useLazyLoadQuery<BaseContentItemListQuery>(
    graphql`
      query BaseContentItemListQuery(
        $where: AnalyticsContentItemWhereInput!
        $first: Int
        $after: String
        $order: [AnalyticsContentItemOrder!]
      ) {
        ...BaseContentItemListPaginationFragment
      }
    `,
    {
      where,
      first,
      order,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const analyticsContentItemPagination = usePaginationFragment<
    BaseContentItemListPaginationFragmentQuery,
    BaseContentItemListPaginationFragment$key
  >(
    graphql`
      fragment BaseContentItemListPaginationFragment on Query
      @refetchable(queryName: "BaseContentItemListPaginationFragmentQuery") {
        analyticsContentItems(
          where: $where
          first: $first
          after: $after
          order: $order
        ) @connection(key: "pagination_base_analyticsContentItems") {
          count
          edges {
            node {
              id
              objectId
              contentItem {
                id
                objectId
                content {
                  objectId
                  id
                }
                ...ContentItemView2_Fragment
                ...ContentItemView3_Fragment
              }
            }
          }
        }
      }
    `,
    contentItemQueryRef
  );

  const analyticsContentItemTotalCounts =
    analyticsContentItemPagination?.data?.analyticsContentItems?.count || 0;
  useEffect(() => {
    _.isFunction(onChangeCount) &&
      onChangeCount(analyticsContentItemTotalCounts);
  }, [analyticsContentItemTotalCounts]);

  const analyticsContentItemPaginationTotalCounts =
    analyticsContentItemPagination.data.analyticsContentItems?.edges?.length ||
    MORE_NUMBER;
  useEffect(() => {
    _.isFunction(onChangePagination) &&
      onChangePagination(analyticsContentItemPaginationTotalCounts);
  }, [analyticsContentItemPaginationTotalCounts]);

  const onEndReached = () => {
    if (analyticsContentItemPagination.hasNext) {
      analyticsContentItemPagination.loadNext(MORE_NUMBER);
    }
  };
  const data = _.chunk(
    _.map(
      analyticsContentItemPagination.data.analyticsContentItems?.edges,
      (e) => e?.node?.contentItem
    ),
    columnsPerRow
  );

  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
  });

  const refetch = useCallback(() => {
    setQueryArgs((prev) => ({
      options: {
        fetchKey: (prev?.options.fetchKey ?? 0) + 1,
      },
    }));
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  const myBookMarkReactionsWhere: ReactionWhereInput = {
    author: {
      have: {
        objectId: {
          equalTo: user?.objectId || '',
        },
      },
    },
    type: {
      equalTo: 'bookmark',
    },
    targetClassName: {
      equalTo: 'ContentItem',
    },
  };
  const { myBookMarkReactions } =
    useLazyLoadQuery<BaseContentItemListMyBookMarkReactionsQuery>(
      graphql`
        query BaseContentItemListMyBookMarkReactionsQuery(
          $where: ReactionWhereInput!
          $skip: Boolean!
        ) {
          myBookMarkReactions: reactions(where: $where, first: 1000)
            @skip(if: $skip) {
            edges {
              node {
                id
                objectId
                contentItem {
                  id
                  objectId
                }
              }
            }
          }
        }
      `,
      { where: myBookMarkReactionsWhere, skip: !Boolean(user?.objectId) },
      { fetchPolicy: 'store-and-network', fetchKey: queryArgs.options.fetchKey }
    );

  const renderItem = (index: number, item: typeof data[0]) => {
    const lastRow = _.last(data) === item;
    const contentItemCardWidth = `${100 / columnsPerRow}%`;
    return (
      <Flex
        direction="row"
        align={'stretch'}
        key={index}
        style={{
          marginRight: -horizontalGutter,
          paddingBottom: lastRow ? 0 : verticalGutter,
        }}
      >
        {_.map(_.castArray(item), (contentItem, index) => {
          if (!contentItem?.objectId) {
            return (
              <View key={index} style={{ width: contentItemCardWidth }}></View>
            );
          } else {
            const bookMarkReaction = _.find(
              myBookMarkReactions?.edges,
              (edge) =>
                edge?.node?.contentItem?.objectId === contentItem.objectId
            );
            const scraped = {
              status: Boolean(bookMarkReaction),
              reactionId: bookMarkReaction?.node?.objectId,
            };
            return (
              <View
                key={index}
                style={{
                  width: contentItemCardWidth,
                  paddingRight: horizontalGutter,
                }}
              >
                <LFLink
                  testID={testID}
                  onPress={
                    _.isFunction(onPressItem)
                      ? () => {
                          onPressItem(
                            contentItem?.objectId,
                            contentItem.content?.objectId
                          );
                        }
                      : undefined
                  }
                  to={
                    _.isFunction(onPressItemTo)
                      ? onPressItemTo(
                          contentItem?.objectId,
                          contentItem.content?.objectId
                        )
                      : ''
                  }
                >
                  <ContentItemView3
                    contentItemFragment={contentItem}
                    numberOfNotes={2}
                    simpleMode={!Boolean(noLoadNext)}
                    scraped={scraped}
                    onScrape={refetch}
                  />
                </LFLink>
              </View>
            );
          }
        })}
      </Flex>
    );
  };

  const emptyElement = ListEmptyComponent ? (
    React.isValidElement(ListEmptyComponent) ? (
      ListEmptyComponent
    ) : (
      <ListEmptyComponent />
    )
  ) : null;

  return data.length === 0 ? (
    emptyElement
  ) : noLoadNext ? (
    <View style={style}>
      {_.map(data, (item, index) => renderItem(index, item))}
    </View>
  ) : (
    <View style={style}>
      <Virtuoso
        initialScrollTop={initialScrollTop}
        // emptyComponent={emptyComponent ? () => emptyComponent : undefined}
        useWindowScroll
        data={data}
        endReached={onEndReached}
        itemContent={renderItem}
      />
      {analyticsContentItemPagination.isLoadingNext ? (
        <Flex align="center" justify="center" style={{ height: 100 }}>
          <ActivityIndicator color={'gray'} />
        </Flex>
      ) : null}
    </View>
  );
};

export default React.memo(BaseContentItemList);

const styles = StyleSheet.create({});
