/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type RecommendedContentsRelatedWithNote_Fragment = {
    readonly id: string;
    readonly objectId: string;
    readonly author: {
        readonly objectId: string;
        readonly name: string | null;
    } | null;
    readonly " $refType": "RecommendedContentsRelatedWithNote_Fragment";
};
export type RecommendedContentsRelatedWithNote_Fragment$data = RecommendedContentsRelatedWithNote_Fragment;
export type RecommendedContentsRelatedWithNote_Fragment$key = {
    readonly " $data"?: RecommendedContentsRelatedWithNote_Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"RecommendedContentsRelatedWithNote_Fragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecommendedContentsRelatedWithNote_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentItem",
  "abstractKey": null
};
})();
(node as any).hash = '676ca4febdea1bc13208810d8083b109';
export default node;
