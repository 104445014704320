import { ImageProps, StyleSheet } from 'react-native';
import React, { useRef } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import PositionItem, { Position } from './PositionItem';
import { Flex } from '@ant-design/react-native';
import _ from 'lodash';

interface IPositionSelector2 {
  scrolling?: boolean;
  style?: React.CSSProperties;
  data: {
    text: Position;
    imageAlt?: string;
    defaultImageSource: ImageProps;
    activeImageSource?: ImageProps;
  }[];
  selectedPosition?: Position;
  onPressPositionItem?: (position: Position) => void;
  gap?: number;
  justify?: 'start' | 'end' | 'center' | 'between' | 'around';
}

const PositionSelector2: React.FC<IPositionSelector2> = ({
  scrolling = false,
  style,
  data,
  selectedPosition,
  onPressPositionItem,
  gap = 0,
  justify = 'start',
}) => {
  const isSlidingRef = useRef(false);
  const onStartScroll = () => (isSlidingRef.current = true);
  const onEndScroll = () => {
    setTimeout(() => (isSlidingRef.current = false), 0);
  };
  const gapAfterContainer = scrolling
    ? pxToNumber(style?.paddingRight || style?.padding)
    : 0;

  return (
    <ScrollContainer
      style={style}
      onStartScroll={onStartScroll}
      onEndScroll={onEndScroll}
    >
      <Flex wrap={scrolling ? 'nowrap' : 'wrap'} justify={justify}>
        {data.map((item, index, array) => (
          <PositionItem
            key={item.text}
            {...item}
            selected={item.text === selectedPosition}
            onPress={() => {
              _.isFunction(onPressPositionItem) &&
                !isSlidingRef.current &&
                onPressPositionItem(item.text);
            }}
            style={{ marginRight: array.length === index + 1 ? 0 : gap }}
          />
        ))}
        {Boolean(gapAfterContainer) && (
          <Flex style={{ width: gapAfterContainer, height: 1 }} />
        )}
      </Flex>
    </ScrollContainer>
  );
};

export default PositionSelector2;

const styles = StyleSheet.create({});

function pxToNumber(data?: string | number | null) {
  if (!data) return 0;
  return typeof data === 'number' ? data : Number(data.replace('px', ''));
}
