import { Flex } from '@ant-design/react-native';
import React from 'react';
import { ActivityIndicator, View, ViewStyle } from 'react-native';
import { useLocation } from 'react-router-dom';
import NavBar2 from '../components/NavBar2';
import { colors } from '../constants/styleGuide';
import { useDeviceType } from '../hooks/deviceType';
import HomeTabSelector from './HomeScreen/HomeTabSelector';

const SuspenseFallbackScreen: React.FC<{
  style?: ViewStyle;
  showNavLayout?: boolean;
}> = ({ style, showNavLayout = false }) => {
  const location = useLocation();
  const homeMode = location.pathname === '/';
  const deviceType = useDeviceType();
  return (
    <Flex
      direction="column"
      align="stretch"
      justify="start"
      style={{ flex: 1, backgroundColor: colors.BG_WHITE }}
    >
      {showNavLayout ? (
        <NavBar2
          title={''}
          right={<View />}
          homeMode={homeMode}
          style={homeMode ? { backgroundColor: colors.BG_WHITE } : undefined}
        />
      ) : null}
      {Boolean(homeMode) ? (
        <>
          <HomeTabSelector
            currentTab="홈"
            onSelectTab={() => {}}
            style={{ paddingHorizontal: deviceType === 'PC' ? 72 : 24 }}
          />
        </>
      ) : (
        <View
          style={[
            { alignItems: 'center', justifyContent: 'center', flex: 1 },
            style,
          ]}
        >
          <ActivityIndicator color={'gray'} />
        </View>
      )}
    </Flex>
  );
};

export default SuspenseFallbackScreen;
