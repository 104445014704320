import { StyleSheet, ScrollView, View, Image } from 'react-native';
import React, { useRef, useState } from 'react';
import LFWhiteSpace from './LFWhiteSpace';
import LFButton from './LFButton';
import OnBoardingCard from './OnBoardingCard';
import DotPagination from './DotPagination';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { colors } from '../constants/styleGuide';
import { useAuth } from '../hooks/auth';
import LFText from './typo/LFText';
import BottomDrawerModal from './BottomDrawerModal';
import { isGoogleLoginResponse } from '../screens/LoginScreen';
import { useHistory, useLocation } from 'react-router-dom';
import LFLink from './LFLink';
import Constants from 'expo-constants';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import dayjs from 'dayjs';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { AfterAction } from '../screens/NotePlayer/NotePlayerScreen';

const OnBoardingModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  noAgainId: string;
  scrollable: boolean;
}> = ({ visible, onClose, noAgainId, scrollable }) => {
  const isSlidingRef = useRef(false);
  const slideRef = useRef<Slider>(null);
  const [currentSlidingIdx, setCurrentSlidingIdx] = useState(0);
  const { user: loginUser, loginWithGoogle } = useAuth();
  const sendDataToGTM = useGTMDispatch();
  const location = useLocation<{
    afterAction?: AfterAction;
    from?: Location;
  }>();
  const afterAction = location?.state?.afterAction as AfterAction;
  const history = useHistory();
  const from = location?.state?.from;
  const responseGoogle = (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if (!isGoogleLoginResponse(res)) return;
    const {
      googleId,
      tokenId,
      profileObj: { email, name, imageUrl },
    } = res;
    if (res.googleId && res.tokenId) {
      loginWithGoogle({
        googleId,
        tokenId,
        email,
        name,
        originProfileURL: imageUrl,
      }).then((res) => {
        // 30초 이내에 생성된 계정일 경우 GTM에 데이터 전송
        const secondsBetweenNowAndAccountCreated = dayjs(Date.now()).diff(
          res.createdAt,
          'second'
        );

        if (secondsBetweenNowAndAccountCreated <= 30)
          sendDataToGTM({ event: 'customSignUp' });

        if (res.jobTitle) {
          history.replace(from || { pathname: '/' }, {
            afterAction,
          });
        } else {
          history.push('/position-selector', {
            from: from || { pathname: '/' },
          });
        }
      });
    }
  };
  const clientId = Constants.manifest?.extra?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;

  const [scrollViewHeight, setScrollViewHeight] = useState<number | null>(null);
  return (
    <BottomDrawerModal
      noAgainButtonTestID="popup-no-again-button:OnBoardingModal"
      closeButtonTestID="popup-close-button:OnBoardingModal"
      visible={visible}
      noAgainId={noAgainId}
      onClose={onClose}
      scrollable={scrollable}
      onLayout={(layout) => {
        const onBoardingModalHeight = layout.height;
        const deviceHeight = window.visualViewport.height;
        {
          /* 아래의 174는 다시보지 않기, X버튼이 포함된 상단 영역 높이와 
          하단 고정영역(DotPagination ~ 버튼) 높이의 합 */
        }
        onBoardingModalHeight >= deviceHeight &&
          setScrollViewHeight(deviceHeight - 174);
      }}
    >
      <ScrollView
        style={[
          { marginHorizontal: -24 },
          typeof scrollViewHeight === 'number' && { height: scrollViewHeight },
        ]}
      >
        <Slider
          ref={slideRef}
          slidesToShow={1}
          slidesToScroll={1}
          swipeToSlide
          onSwipe={() => (isSlidingRef.current = true)}
          afterChange={(currenSlide) => {
            isSlidingRef.current = false;
            setCurrentSlidingIdx(currenSlide);
          }}
          initialSlide={0}
          infinite={false}
        >
          <OnBoardingCard
            subtitle={'💡 런핏에 가입하고'}
            title={
              <>
                랜선사수가 만든{'\n'}
                <LFText style={styles.primary}>학습노트</LFText>를 확인하세요!
              </>
            }
            body={
              <Image
                accessibilityLabel=""
                source={require('../assets/images/onBoarding/onboarding1@3x.png')}
                style={{
                  width: '100%',
                  paddingBottom: (323 / 327) * 100 + '%',
                }}
              />
            }
          />

          <OnBoardingCard
            subtitle={'✏️️ 학습노트란?'}
            title={
              <>
                유튜브로 만드는 {`\n`}
                나만의 <LFText style={styles.primary}>학습기록</LFText>
              </>
            }
            body={
              <Image
                accessibilityLabel=""
                source={require('../assets/images/onBoarding/onboarding2@3x.png')}
                style={{
                  width: '100%',
                  paddingBottom: (323 / 327) * 100 + '%',
                }}
              />
            }
          />

          <OnBoardingCard
            subtitle={'🚀 러닝패스란?'}
            title={
              <>
                <LFText style={styles.primary}>주제별로</LFText>
                <LFWhiteSpace direction="row" size={'sm'} />
                모아보는{'\n'} 학습노트 시리즈
              </>
            }
            body={
              <Image
                accessibilityLabel=""
                source={require('../assets/images/onBoarding/onboarding3@3x.png')}
                style={{
                  width: '100%',
                  paddingBottom: (323 / 327) * 100 + '%',
                }}
              />
            }
          />
        </Slider>
      </ScrollView>

      {/* 102는 DotPagination ~ 버튼 영역의 고정 높이 */}
      <View style={{ height: 102 }}>
        <LFWhiteSpace size={'sm'} />
        <DotPagination
          style={{ alignSelf: 'center' }}
          total={3}
          active={currentSlidingIdx}
          defaultDotStyle={{
            backgroundColor: colors.PRIMARY_100,
            height: 6,
            width: 6,
            borderRadius: 3,
          }}
          activeDotStyle={{
            width: 20,
          }}
        />
        <LFWhiteSpace size={'lg'} />
        {loginUser ? (
          <LFButton
            testID={
              currentSlidingIdx === 0
                ? 'popup-goto-note-button:OnBoardingModal'
                : currentSlidingIdx === 1
                ? 'popup-goto-note-button:OnBoardingModal'
                : currentSlidingIdx === 2
                ? 'popup-goto-path-button:OnBoardingModal'
                : ''
            }
            style={{ alignSelf: 'stretch' }}
            onPress={() => {
              onClose();
              history.push(
                currentSlidingIdx === 0
                  ? '/notes'
                  : currentSlidingIdx === 1
                  ? '/notes'
                  : currentSlidingIdx === 2
                  ? '/paths'
                  : '/'
              );
            }}
          >
            {currentSlidingIdx === 0
              ? '학습노트 둘러보기'
              : currentSlidingIdx === 1
              ? '학습노트 둘러보기'
              : currentSlidingIdx === 2
              ? '러닝패스 둘러보기'
              : ''}
          </LFButton>
        ) : (
          <GoogleLogin
            render={({ onClick }) => (
              <LFLink onPress={onClick} style={styles.buttonContainer}>
                <Image
                  source={require('../assets/images/icon24GoogleSymbol@3x.png')}
                  style={{ width: 24, height: 24 }}
                />
                <LFWhiteSpace size="xs" direction="row" />
                <LFText style={styles.buttonText}>Google 로 회원가입</LFText>
              </LFLink>
            )}
            clientId={clientId}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
        )}

        <LFWhiteSpace size={'lg'} />
      </View>
    </BottomDrawerModal>
  );
};

export default OnBoardingModal;

const styles = StyleSheet.create({
  next: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.PRIMARY_100,
  },
  primary: {
    color: colors.PRIMARY_100,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    paddingVertical: 16,
    borderRadius: 8,
    backgroundColor: colors.PRIMARY_10,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    color: colors.PRIMARY_100,
  },
});
