import React from 'react';
import {
  StyleSheet,
  View,
  Image,
  ViewStyle,
  TextStyle,
  ImageStyle,
} from 'react-native';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { graphql, useFragment } from 'react-relay';
import PreviewImage from '../PreviewImage';
import LFText from '../typo/LFText';
import { colors } from '../../constants/styleGuide';
import {
  ContentPreviewFragment,
  ContentPreviewFragment$key,
} from '../../__generated__/ContentPreviewFragment.graphql';

export interface ContentPreviewProps {
  // thumbURL: string;
  // title: string;
  // subtitle?: string;
  // type: 'VIDEO' | 'BOOK' | 'LINK';
  title?: string | null;
  contentFrgmt: ContentPreviewFragment$key;
  style?: ViewStyle;
  titleStyle?: TextStyle;
  subTitleStyle?: TextStyle;
  hideType?: boolean;
  published?: boolean;
  fullMode?: boolean;
}
const ContentPreview: React.FC<ContentPreviewProps> = ({
  title,
  children,
  contentFrgmt,
  style,
  titleStyle,
  subTitleStyle,
  hideType,
  published = true,
  fullMode = false,
}) => {
  const content = useFragment(
    graphql`
      fragment ContentPreviewFragment on Content {
        __typename
        id
        objectId
        type
        thumbURL
        link
        title
      }
    `,
    contentFrgmt
  );

  const typeInfo = {
    VIDEO: {
      title: 'Video',
      thumbSize: {
        width: fullMode ? '100%' : 128,
        height: fullMode ? 0 : 72,
        paddingBottom: fullMode ? '56.25%' : 0,
      },
    },
    BOOK: {
      title: 'Book',
      thumbSize: {
        width: fullMode ? '100%' : 72,
        height: fullMode ? '100%' : 96,
        paddingBottom: fullMode ? '56.25%' : 0,
      },
    },
    LINK: {
      title: 'LINK',
      thumbSize: {
        width: fullMode ? '100%' : 72,
        height: fullMode ? '100%' : 72,
        paddingBottom: fullMode ? '56.25%' : 0,
      },
    },
  }[(content.type || 'LINK') as 'VIDEO' | 'BOOK' | 'LINK'];

  return (
    <SimplePreview
      style={style}
      tagTitle={
        hideType ? null : typeInfo.title === 'LINK' ? 'POST' : typeInfo.title
      }
      title={title ?? content.title}
      subtitle={`출처: ${content.link}`}
      titleStyle={titleStyle}
      subtitleStyle={subTitleStyle}
      thumbStyle={typeInfo.thumbSize}
      thumbURL={content?.thumbURL}
      badge={published ? null : '비공개'}
      fullMode={fullMode}
    >
      {children}
    </SimplePreview>
  );
  // return (
  //   <Flex direction="column" align="stretch" style={style}>
  //     <Flex direction="row" align="start">
  //       <View>
  //         <View
  //           style={{
  //             opacity: 0.9,
  //             borderRadius: 4,
  //             backgroundColor: colors.SECONDARY_BLACK,
  //             position: 'absolute',
  //             left: 0,
  //             top: 0,
  //             zIndex: 10,
  //             padding: 4,
  //           }}
  //         >
  //           <LFText
  //             style={{
  //
  //               fontSize: 10,
  //               fontWeight: '500',
  //               fontStyle: 'normal',
  //               lineHeight: 16,
  //               letterSpacing: 0,
  //               textAlign: 'left',
  //               color: colors.TEXT_WHITE,
  //             }}
  //           >
  //             {typeInfo.title}
  //           </LFText>
  //         </View>
  //         <PreviewImage
  //           style={{
  //             ...typeInfo.thumbSize,
  //             borderRadius: 4,
  //             backgroundColor: '#00000019.8000066',
  //             borderWidth: 1,
  //             borderColor: colors.BORDER_10,
  //             marginRight: 8,
  //           }}
  //           source={{ uri: content.thumbURL || '' }}
  //         />
  //       </View>
  //       <Flex align="start" direction="column" style={{ flex: 1 }}>
  //         <LFText style={[styles.title, titleStyle]} numberOfLines={2}>
  //           {content.title}
  //         </LFText>
  //         <LFText style={[styles.subtitle]} numberOfLines={1}>
  //           {`출처: ${content.link}`}
  //         </LFText>
  //       </Flex>
  //     </Flex>
  //     <Flex style={{ alignSelf: 'flex-start' }}>
  //       {/* <LFText>노트작성자 영역</LFText> */}
  //       {children}
  //     </Flex>
  //   </Flex>
  // );
};

export const SimplePreview: React.FC<{
  title?: string | null;
  subtitle?: string;
  style?: ViewStyle;
  titleStyle?: TextStyle;
  subtitleStyle?: TextStyle;
  thumbStyle?: ImageStyle;
  thumbURL?: string | null;
  tagTitle?: string | null;
  badge?: string | null;
  fullMode?: boolean;
}> = ({
  style,
  title,
  subtitle,
  titleStyle,
  subtitleStyle,
  thumbStyle,
  thumbURL,
  children,
  tagTitle,
  badge,
  fullMode,
}) => {
  return (
    <Flex direction="column" align="stretch" style={style}>
      {tagTitle ? (
        <View
          style={{
            padding: 4,
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 10,
            opacity: 0.9,
            borderRadius: 4,
            backgroundColor: colors.SECONDARY_BLACK,
          }}
        >
          <LFText
            style={{
              fontSize: 10,
              fontWeight: '500',
              fontStyle: 'normal',
              lineHeight: 16,
              letterSpacing: 0,
              textAlign: 'left',
              color: colors.TEXT_WHITE,
            }}
          >
            {tagTitle}
          </LFText>
        </View>
      ) : null}
      <Flex direction={fullMode ? 'column' : 'row'} align="start">
        {/* {fullMode || thumbURL ? ( */}
        {/* 영상부 컨테이너 : video 타입 + 썸네일 -> full */}
        {thumbURL && (
          <View
            style={{
              alignSelf: 'stretch',
              justifyContent: 'center',
              borderWidth: 1,
              borderColor: colors.BORDER_10,
              borderRadius: 8,
              backgroundColor: '#00000019.8000066',
              overflow: 'hidden',
            }}
          >
            <PreviewImage
              accessibilityLabel=""
              source={{ uri: thumbURL || '' }}
              style={{
                ...thumbStyle,
              }}
            />
            {Boolean(fullMode && tagTitle === 'Video') && (
              <Image
                accessibilityLabel="영상 재생 모양 아이콘"
                source={require('../../assets/images/playButton@3x.png')}
                style={{
                  position: 'absolute',
                  alignSelf: 'center',
                  width: 64,
                  height: 64,
                }}
              />
            )}
          </View>
        )}
        <Flex
          align="start"
          direction="column"
          style={[
            thumbURL
              ? { flex: 1, width: '100%', marginLeft: 5 }
              : { flex: 1, width: '100%', marginTop: 29 },
          ]}
        >
          <LFText
            style={[styles.title, titleStyle]}
            numberOfLines={subtitle ? 2 : 3}
            accessibilityRole="heading"
            aria-level={3}
          >
            {title}
          </LFText>
          <LFText style={[styles.subtitle, subtitleStyle]} numberOfLines={1}>
            {subtitle}
          </LFText>
          {badge && (
            <>
              <WhiteSpace size="sm" />
              <View style={styles.privateBadgeContainer}>
                <LFText style={styles.privateBadgeText}>{badge}</LFText>
              </View>
            </>
          )}
        </Flex>
      </Flex>
      <Flex style={{ alignSelf: 'flex-start' }}>
        {/* <LFText>노트작성자 영역</LFText> */}
        {children}
      </Flex>
    </Flex>
  );
};

export const ContentPreviewWrapView: React.FC<{
  style?: ViewStyle;
}> = ({ style, children }) => (
  <Flex
    direction="row"
    align="stretch"
    style={[
      {
        flex: 1,
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: colors.BG_2,
        padding: 8,
      },
      style,
    ]}
  >
    {children}
  </Flex>
);
export default ContentPreview;

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    color: colors.TEXT_80,
  },
  subtitle: {
    fontSize: 10,
    fontWeight: '300',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    color: colors.TEXT_60,
  },
  privateBadgeContainer: {
    borderRadius: 100,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.BORDER_20,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  privateBadgeText: {
    fontSize: 10,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
});
