/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ContentPreview2Fragment = {
    readonly id: string;
    readonly objectId: string;
    readonly type: string | null;
    readonly thumbURL: string | null;
    readonly title: string | null;
    readonly createdAt: unknown;
    readonly __typename: "Content";
    readonly " $refType": "ContentPreview2Fragment";
};
export type ContentPreview2Fragment$data = ContentPreview2Fragment;
export type ContentPreview2Fragment$key = {
    readonly " $data"?: ContentPreview2Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ContentPreview2Fragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentPreview2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "Content",
  "abstractKey": null
};
(node as any).hash = '43fab5470e522f999dab2addb3b0fdc8';
export default node;
