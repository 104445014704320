import { Flex } from '@ant-design/react-native';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { colors } from '../../constants/styleGuide';
import LFText from '../typo/LFText';

const TypeBadge: React.FC<{
  type: 'ContentItem' | 'Path' | 'Quiz' | '%other' | undefined;
}> = ({ type }) => {
  const typeInfo = {
    ContentItem: {
      icon: require('../../assets/images/instructionCard/icon24Note@3x.png'),
      title: '큐레이터의 노트 소개',
    },
    Path: {
      icon: require('../../assets/images/instructionCard/icon24Path@3x.png'),
      title: '큐레이터의 러닝패스 소개',
    },
    Quiz: {
      icon: require('../../assets/images/instructionCard/icon24Quiz@3x.png'),
      title: '큐레이터의 퀴즈',
      color: colors.PRIMARY_10,
      fontColor: colors.PRIMARY_100,
    },
    '%other': {},
  }[type || '%other'];
  if (!typeInfo) {
    return (
      <View
        style={{
          backgroundColor: colors.SECONDARY_BLACK,
          width: 32,
          height: 1,
        }}
      />
    );
  }
  return (
    <Flex
      style={[
        {
          paddingVertical: 6,
          paddingHorizontal: 12,
          backgroundColor: typeInfo?.color || colors.BG_2,
          borderRadius: 100,
          alignSelf: 'flex-start',
        },
      ]}
    >
      <Image
        accessibilityLabel=""
        style={{ width: 16, height: 16, marginRight: 5 }}
        source={typeInfo?.icon}
      />
      <LFText
        style={{
          fontSize: 12,
          fontWeight: '600',
          fontStyle: 'normal',
          lineHeight: 20,
          letterSpacing: 0,
          textAlign: 'left',
          color: typeInfo?.fontColor || colors.SECONDARY_BLACK,
        }}
      >
        {typeInfo?.title}
      </LFText>
    </Flex>
  );
};

export default TypeBadge;

const styles = StyleSheet.create({});
