/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useLazyLoadLearningInfoOfPathFragment = {
    readonly instructionCards: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly seq: number | null;
                readonly optional: boolean | null;
                readonly sources: ReadonlyArray<({
                    readonly __typename: "ContentItem";
                    readonly id: string;
                    readonly objectId: string;
                    readonly published: boolean | null;
                } | {
                    readonly __typename: "Content";
                    readonly id: string;
                    readonly objectId: string;
                } | {
                    readonly __typename: "Path";
                    readonly id: string;
                    readonly objectId: string;
                    readonly published: boolean | null;
                } | {
                    readonly __typename: "Quiz";
                    readonly id: string;
                    readonly objectId: string;
                } | {
                    /*This will never be '%other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                }) | null> | null;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "useLazyLoadLearningInfoOfPathFragment";
};
export type useLazyLoadLearningInfoOfPathFragment$data = useLazyLoadLearningInfoOfPathFragment;
export type useLazyLoadLearningInfoOfPathFragment$key = {
    readonly " $data"?: useLazyLoadLearningInfoOfPathFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"useLazyLoadLearningInfoOfPathFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "published",
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useLazyLoadLearningInfoOfPathFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            "seq_ASC",
            "createdAt_ASC"
          ]
        }
      ],
      "concreteType": "InstructionCardConnection",
      "kind": "LinkedField",
      "name": "instructionCards",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstructionCardEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstructionCard",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "seq",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "optional",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "sources",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v2/*: any*/),
                      "type": "ContentItem",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v3/*: any*/),
                      "type": "Content",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v2/*: any*/),
                      "type": "Path",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v3/*: any*/),
                      "type": "Quiz",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "instructionCards(order:[\"seq_ASC\",\"createdAt_ASC\"])"
    }
  ],
  "type": "Path",
  "abstractKey": null
};
})();
(node as any).hash = '2a58e8df841aa9a085b6b90e96e1306b';
export default node;
