import { useLocalStorageState } from 'ahooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// const useLocalStorageCount(예시) input: 초기값, key, dep / return: count
function useLocalStorageCount<T>({
  init = 0,
  key,
  dep,
}: {
  init: number;
  key: string;
  dep: T;
}) {
  const [localState, setLocalState] = useLocalStorageState(key, init);
  useEffect(() => {
    setLocalState((prev) => (typeof prev === 'number' ? prev + 1 : 0));
  }, [dep]);
  return [localState, setLocalState] as const;
}

export function usePathnameChangeCount({ key }: { key: string }) {
  const location = useLocation();
  const [pathnameChangeCount, setPathnameChangeCount] =
    useLocalStorageCount<string>({
      init: 0,
      key: 'pathnameChangeCount' + key,
      dep: location?.pathname,
    });
  return [pathnameChangeCount, setPathnameChangeCount] as const;
}

export default useLocalStorageCount;
