import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import { WhiteSpace } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import { colors } from '../constants/styleGuide';
import { useAuth } from '../hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import Confetti from 'react-confetti';
import { QuizFragment$key } from '../__generated__/QuizFragment.graphql';
import { graphql, useFragment } from 'react-relay';
import {
  graphqlElementsToNumberArray,
  graphqlElementsToStringArray,
} from '../helper/typeTransform';
import LFLink from './LFLink';
import LFText from './typo/LFText';
// Confetti는 퀴즈 정답 맞출 시, 나타나는 애니메이션 관련 라이브러리
// https://www.npmjs.com/package/react-confetti

interface QuizProps {
  style?: ViewStyle;
  quizFrgmt: QuizFragment$key;
  alreadySuccess?: boolean;
  onSuccess?: () => void;
}

const Quiz: React.FC<QuizProps> = ({
  style,
  quizFrgmt,
  onSuccess,
  alreadySuccess,
}) => {
  const quiz = useFragment(
    graphql`
      fragment QuizFragment on Quiz {
        id
        objectId
        title
        options {
          ... on Element {
            value
          }
        }
        correctIndexes {
          ... on Element {
            value
          }
        }
      }
    `,
    quizFrgmt
  );

  const { title, objectId } = quiz;
  const correctIndexes = graphqlElementsToNumberArray(quiz.correctIndexes);
  const options = graphqlElementsToStringArray(quiz.options);
  const [initialAlreadySuccess] = useState(alreadySuccess);
  const [accSelectedOptionIdx, setAccSelectedOptionIdx] = useState<number[]>(
    alreadySuccess ? [...correctIndexes] : []
  );
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { pathname } = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  // 리액트 네이티브 View가 렌더링될 때마다 사이즈를 새로 측정해서 width, height 값을 저장하기 위한 함수
  const onLayout = (event: any) => {
    const { width, height } = event.nativeEvent.layout;
    setDimensions({ width: width, height: height });
  };

  const onPress = (idx: number) => {
    if (!user) {
      history.push('/login', {
        from: pathname,
      });
    } else {
      setAccSelectedOptionIdx((prev) => [...prev, idx]);
    }
  };

  const isSelectedAllCorrect =
    _.difference(correctIndexes, accSelectedOptionIdx).length === 0;

  useEffect(() => {
    if (isSelectedAllCorrect && onSuccess && !alreadySuccess) {
      onSuccess();
    }
  }, [isSelectedAllCorrect]);
  return (
    <View style={[styles.contentContainer, style]} onLayout={onLayout}>
      <LFText
        selectable
        style={styles.title}
        accessibilityRole={'heading'}
        aria-level={2}
      >
        {title}
      </LFText>
      <WhiteSpace size="xl" />
      {options?.map((option, idx) => {
        const status = _.includes(accSelectedOptionIdx, idx)
          ? _.includes(correctIndexes, idx)
            ? 'correct'
            : 'wrong'
          : 'yet';
        return (
          <View key={quiz.id + idx}>
            <LFLink
              testID={`learningpath-quiz-choice:Quiz#objectID:${objectId}#correct:${_.includes(
                correctIndexes,
                idx
              )}`}
              onPress={() => {
                onPress(idx);
              }}
              disabled={isSelectedAllCorrect}
              key={option}
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <LFText
                selectable
                style={[
                  styles.defaultOptionTxt,
                  status === 'correct'
                    ? styles.correctAnswerTxt
                    : status === 'wrong'
                    ? styles.wrongAnswerTxt
                    : {},
                ]}
              >
                {option}
              </LFText>
              {status === 'correct' ? (
                <Ionicons
                  name="checkmark-circle"
                  size={24}
                  color={colors.PRIMARY_100}
                />
              ) : status === 'wrong' ? (
                <Ionicons
                  name="close-circle-outline"
                  size={24}
                  color={colors.BORDER_80}
                />
              ) : (
                <Ionicons name="radio-button-off" size={24} color="#1A1B1E66" />
              )}
            </LFLink>
            <WhiteSpace size="lg" />
          </View>
        );
      })}
      {isSelectedAllCorrect && !initialAlreadySuccess ? (
        <Confetti
          width={dimensions.width}
          height={dimensions.height}
          tweenDuration={20000}
          recycle={false}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  defaultOptionTxt: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  correctAnswerTxt: {
    color: colors.PRIMARY_100,
  },
  wrongAnswerTxt: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
});

export default Quiz;
