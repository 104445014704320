import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import { graphql, useFragment } from 'react-relay';
import { colors } from '../constants/styleGuide';
import { resizedImageURL } from '../helper/resizedImageURL';
import { UserProfileImageFragment$key } from '../__generated__/UserProfileImageFragment.graphql';

const UserProfileImage: React.FC<{
  userFrgmt: UserProfileImageFragment$key | null | undefined;
  style?: ImageStyle;
  size: number;
}> = ({ userFrgmt, size, style }) => {
  const user = useFragment(
    graphql`
      fragment UserProfileImageFragment on User {
        originProfileURL
        profileImage {
          url
        }
        name
      }
    `,
    userFrgmt || null
  );
  const imageSize = size > 50 ? 200 : size < 100 ? 300 : size < 200 ? 600 : 600;
  return (
    <Image
      accessibilityLabel={`${user?.name || ''}의 프로필 이미지`}
      style={[
        {
          width: size,
          height: size,
          borderRadius: size / 2,
          borderWidth: 1,
          borderColor: colors.BORDER_WHITE,
        },
        style,
      ]}
      source={{
        uri: resizedImageURL(
          user?.profileImage?.url,
          imageSize,
          imageSize,
          user?.originProfileURL
        ),
      }}
    />
  );
};

export default UserProfileImage;
