import { Image, ImageStyle, StyleSheet, Text, View } from 'react-native';
import React from 'react';

interface IBookMark {
  style?: ImageStyle;
  selected?: boolean;
}

const BookMark: React.FC<IBookMark> = ({ style, selected = true }) => {
  return (
    <Image
      accessibilityLabel="스크랩"
      style={[styles.container, style]}
      source={
        selected
          ? require('../assets/images/bookmarkIcon@3x.png')
          : require('../assets/images/emptyBookmarkIcon@3x.png')
      }
    />
  );
};

export default BookMark;

const styles = StyleSheet.create({
  container: {
    width: 24,
    height: 24,
  },
});
