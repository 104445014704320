/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HomeSectionOrder = "ACL_ASC" | "ACL_DESC" | "count_ASC" | "count_DESC" | "createdAt_ASC" | "createdAt_DESC" | "id_ASC" | "id_DESC" | "manuallyPickedObjectIds_ASC" | "manuallyPickedObjectIds_DESC" | "objectId_ASC" | "objectId_DESC" | "order_ASC" | "order_DESC" | "period_ASC" | "period_DESC" | "priority_ASC" | "priority_DESC" | "published_ASC" | "published_DESC" | "subTitle_ASC" | "subTitle_DESC" | "targetClassName_ASC" | "targetClassName_DESC" | "title_ASC" | "title_DESC" | "to_ASC" | "to_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "where_ASC" | "where_DESC" | "%future added value";
export type HomeSectionWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    subTitle?: StringWhereInput | null;
    published?: BooleanWhereInput | null;
    targetClassName?: StringWhereInput | null;
    where?: ObjectWhereInput | null;
    priority?: NumberWhereInput | null;
    order?: ArrayWhereInput | null;
    manuallyPickedObjectIds?: ArrayWhereInput | null;
    to?: StringWhereInput | null;
    count?: NumberWhereInput | null;
    period?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<HomeSectionWhereInput> | null;
    AND?: Array<HomeSectionWhereInput> | null;
    NOR?: Array<HomeSectionWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type BaseHomeSectionListQueryVariables = {
    where: HomeSectionWhereInput;
    order?: Array<HomeSectionOrder> | null;
    first?: number | null;
    after?: string | null;
};
export type BaseHomeSectionListQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"BaseHomeSectionListPaginationFragment">;
};
export type BaseHomeSectionListQuery = {
    readonly response: BaseHomeSectionListQueryResponse;
    readonly variables: BaseHomeSectionListQueryVariables;
};



/*
query BaseHomeSectionListQuery(
  $where: HomeSectionWhereInput!
  $order: [HomeSectionOrder!]
  $first: Int
  $after: String
) {
  ...BaseHomeSectionListPaginationFragment
}

fragment BaseHomeSectionListPaginationFragment on Query {
  homeSections(where: $where, first: $first, after: $after, order: $order) {
    count
    edges {
      node {
        id
        objectId
        ...HomeSectionView_Fragment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment HomeSectionView_Fragment on HomeSection {
  id
  objectId
  title
  subTitle
  priority
  targetClassName
  to
  where
  count
  period
  order {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  manuallyPickedObjectIds {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = [
  (v7/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "type": "Element",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v6/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseHomeSectionListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "BaseHomeSectionListPaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BaseHomeSectionListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "HomeSectionConnection",
        "kind": "LinkedField",
        "name": "homeSections",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "HomeSectionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HomeSection",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objectId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetClassName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "to",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "where",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "period",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "order",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "manuallyPickedObjectIds",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "where",
          "order"
        ],
        "handle": "connection",
        "key": "pagination_base_homeSections",
        "kind": "LinkedHandle",
        "name": "homeSections"
      }
    ]
  },
  "params": {
    "cacheID": "e8a4e1587f824355c818a5022e1fd62e",
    "id": null,
    "metadata": {},
    "name": "BaseHomeSectionListQuery",
    "operationKind": "query",
    "text": "query BaseHomeSectionListQuery(\n  $where: HomeSectionWhereInput!\n  $order: [HomeSectionOrder!]\n  $first: Int\n  $after: String\n) {\n  ...BaseHomeSectionListPaginationFragment\n}\n\nfragment BaseHomeSectionListPaginationFragment on Query {\n  homeSections(where: $where, first: $first, after: $after, order: $order) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        ...HomeSectionView_Fragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment HomeSectionView_Fragment on HomeSection {\n  id\n  objectId\n  title\n  subTitle\n  priority\n  targetClassName\n  to\n  where\n  count\n  period\n  order {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  manuallyPickedObjectIds {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8d849574f9712a0711472a95a02ee136';
export default node;
