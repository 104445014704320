// 출력 :
// highlight해야할 instruction id
// 학습해야할 instructionCard(text, optional 제외) 개수와 학습 완료한 instructionCard
//

import _ from 'lodash';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { useLazyLoadLearningInfoOfPathFragment$key } from '../__generated__/useLazyLoadLearningInfoOfPathFragment.graphql';
import { useLazyLoadLearningInfoOfPathQuery } from '../__generated__/useLazyLoadLearningInfoOfPathQuery.graphql';
import { useAuth } from './auth';

export const useLazyLoadLearningInfoOfPath = ({
  pathFragment,
}: {
  pathFragment: useLazyLoadLearningInfoOfPathFragment$key | null;
}) => {
  const { user: loginUser } = useAuth();
  const path = useFragment(
    graphql`
      fragment useLazyLoadLearningInfoOfPathFragment on Path {
        instructionCards(order: [seq_ASC, createdAt_ASC]) {
          edges {
            node {
              id
              objectId
              seq
              optional
              sources {
                __typename
                ... on ContentItem {
                  id
                  objectId
                  published
                }
                ... on Content {
                  id
                  objectId
                }
                ... on Path {
                  id
                  objectId
                  published
                }
                ... on Quiz {
                  id
                  objectId
                }
              }
            }
          }
        }
      }
    `,
    pathFragment
  );

  const groupedCardSource = _.chain(path?.instructionCards.edges)
    .map((e) => _.first(e?.node?.sources))
    .groupBy((s) => s?.__typename)
    .value();

  const { allLHistory } = useLazyLoadQuery<useLazyLoadLearningInfoOfPathQuery>(
    graphql`
      query useLazyLoadLearningInfoOfPathQuery(
        $pathIds: [ID]
        $contentItemIds: [ID] # $quizIds: [ID]
        $userObjectId: ID
        $isNotLogin: Boolean!
      ) {
        allLHistory: learningHistories(
          first: 1000
          where: {
            OR: [
              { path: { have: { objectId: { in: $pathIds } } } }
              { contentItem: { have: { objectId: { in: $contentItemIds } } } }
              { quiz: { exists: true } }
              # { quiz: { have: { objectId: { in: $quizIds } } } }
            ]
            author: { have: { objectId: { equalTo: $userObjectId } } }
          }
        ) @skip(if: $isNotLogin) {
          __id
          edges {
            node {
              id
              objectId
              status
              path {
                id
                objectId
              }
              contentItem {
                id
                objectId
              }
              quiz {
                id
                objectId
              }
            }
          }
        }
      }
    `,
    {
      pathIds: _.map(groupedCardSource['Path'], (s) => _.get(s, 'objectId')),
      contentItemIds: _.map(groupedCardSource['ContentItem'], (s) =>
        _.get(s, 'objectId')
      ),
      userObjectId: loginUser?.objectId,
      isNotLogin: !loginUser,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );
  const totalRequiredCount = _.filter(
    path?.instructionCards.edges,
    (e) => !e?.node?.optional && _.get(_.first(e?.node?.sources), 'id')
  ).length;

  if (!allLHistory) {
    return {
      doneRequiredCardCount: undefined,
      totalRequiredCount: undefined,
      highlightCardObjectId: undefined,
      learningStatusMap: {},
      connectionId: 'NOT_YET_CONNECTED',
    };
  }

  const learningStatusMap =
    _.reduce(
      allLHistory.edges,
      (result, e) => {
        if (
          e?.node?.path?.id ||
          e?.node?.contentItem?.id ||
          e?.node?.quiz?.id
        ) {
          result[
            e.node.path?.id ||
              e.node.contentItem?.id ||
              e?.node?.quiz?.id ||
              'none'
          ] = e.node.status;
        }
        return result;
      },
      {} as {
        [index: string]: string | null;
      }
    ) || {};

  const doneRequiredCardCount = _.filter(path?.instructionCards.edges, (e) => {
    const sourceId: string = _.get(_.first(e?.node?.sources), 'id');
    return learningStatusMap[sourceId] === 'done' && !e?.node?.optional;
  }).length;

  const highlightCard = _.find(path?.instructionCards.edges, (e) => {
    const sourceId: string = _.get(_.first(e?.node?.sources), 'id');
    // 학습상태가 'done'이 아니면서 source가 있어야 함(=text만 있는 것이 아님)
    return (
      learningStatusMap[sourceId] !== 'done' && sourceId && !e?.node?.optional
    );
  });
  const highlightCardObjectId: string | undefined =
    highlightCard?.node?.objectId;
  return {
    doneRequiredCardCount,
    totalRequiredCount,
    highlightCardObjectId,
    learningStatusMap,
    connectionId: allLHistory.__id,
    progressPercent: totalRequiredCount
      ? Math.floor((doneRequiredCardCount / totalRequiredCount) * 100)
      : 0,
  };
};
