/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NoteScreenQueryVariables = {
    contentId: string;
    contentItemId: string;
};
export type NoteScreenQueryResponse = {
    readonly content: {
        readonly id: string;
        readonly type: string | null;
    };
    readonly contentItem: {
        readonly id: string;
        readonly published: boolean | null;
        readonly author: {
            readonly id: string;
            readonly objectId: string;
        } | null;
    };
};
export type NoteScreenQuery = {
    readonly response: NoteScreenQueryResponse;
    readonly variables: NoteScreenQueryVariables;
};



/*
query NoteScreenQuery(
  $contentId: ID!
  $contentItemId: ID!
) {
  content(id: $contentId) {
    id
    type
  }
  contentItem(id: $contentItemId) {
    id
    published
    author {
      id
      objectId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentItemId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "contentId"
      }
    ],
    "concreteType": "Content",
    "kind": "LinkedField",
    "name": "content",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "contentItemId"
      }
    ],
    "concreteType": "ContentItem",
    "kind": "LinkedField",
    "name": "contentItem",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "published",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NoteScreenQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NoteScreenQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "543f2cb63b0495fbdb80b5e71e64639e",
    "id": null,
    "metadata": {},
    "name": "NoteScreenQuery",
    "operationKind": "query",
    "text": "query NoteScreenQuery(\n  $contentId: ID!\n  $contentItemId: ID!\n) {\n  content(id: $contentId) {\n    id\n    type\n  }\n  contentItem(id: $contentItemId) {\n    id\n    published\n    author {\n      id\n      objectId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '00b88b561e5b6981b44329e02cc00ba7';
export default node;
