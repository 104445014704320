/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PathPreviewFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly title: string | null;
    readonly coverImage: {
        readonly url: string;
    } | null;
    readonly author: {
        readonly name: string | null;
        readonly id: string;
        readonly objectId: string;
        readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
    } | null;
    readonly instructionCards: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly optional: boolean | null;
                readonly sources: ReadonlyArray<({
                    readonly __typename: "ContentItem";
                    readonly content: {
                        readonly id: string;
                        readonly " $fragmentRefs": FragmentRefs<"ContentPreviewFragment">;
                    } | null;
                } | {
                    readonly __typename: "Path";
                    readonly id: string;
                    readonly title: string | null;
                    readonly coverImage: {
                        readonly url: string;
                    } | null;
                    readonly author: {
                        readonly id: string;
                        readonly name: string | null;
                    } | null;
                } | {
                    readonly __typename: "Quiz";
                    readonly id: string;
                    readonly title: string | null;
                } | {
                    /*This will never be '%other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                }) | null> | null;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "PathPreviewFragment";
};
export type PathPreviewFragment$data = PathPreviewFragment;
export type PathPreviewFragment$key = {
    readonly " $data"?: PathPreviewFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PathPreviewFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "coverImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PathPreviewFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileImageFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": [
            "seq_ASC",
            "createdAt_ASC"
          ]
        }
      ],
      "concreteType": "InstructionCardConnection",
      "kind": "LinkedField",
      "name": "instructionCards",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstructionCardEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstructionCard",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "optional",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "sources",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Content",
                          "kind": "LinkedField",
                          "name": "content",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ContentPreviewFragment"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "ContentItem",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "author",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Path",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/)
                      ],
                      "type": "Quiz",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "instructionCards(order:[\"seq_ASC\",\"createdAt_ASC\"])"
    }
  ],
  "type": "Path",
  "abstractKey": null
};
})();
(node as any).hash = 'bb617441dc12a06422a1ebbcef324de5';
export default node;
