/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MembershipStatusScreenQueryVariables = {
    ownerUserObjectId: string;
    loginUserID: string;
};
export type MembershipStatusScreenQueryResponse = {
    readonly membershipStatuses: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly status: string | null;
                readonly " $fragmentRefs": FragmentRefs<"MembershipStatusCardFragment">;
            } | null;
        } | null> | null;
    };
    readonly membershipPayments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly " $fragmentRefs": FragmentRefs<"MembershipPaymentItemViewFragment">;
            } | null;
        } | null> | null;
    };
    readonly owner: {
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
        readonly createdAt: unknown;
        readonly updatedAt: unknown;
        readonly description: string | null;
        readonly originProfileURL: string | null;
        readonly jobTitle: string | null;
        readonly tags: ReadonlyArray<{
            readonly value?: unknown;
        } | null> | null;
        readonly profileImage: {
            readonly url: string;
        } | null;
    };
};
export type MembershipStatusScreenQuery = {
    readonly response: MembershipStatusScreenQueryResponse;
    readonly variables: MembershipStatusScreenQueryVariables;
};



/*
query MembershipStatusScreenQuery(
  $ownerUserObjectId: ID!
  $loginUserID: ID!
) {
  membershipStatuses(where: {membership: {have: {author: {have: {objectId: {equalTo: $ownerUserObjectId}}}}}, member: {have: {objectId: {equalTo: $loginUserID}}}}) {
    edges {
      node {
        id
        objectId
        status
        ...MembershipStatusCardFragment
      }
    }
  }
  membershipPayments(where: {membershipPricing: {have: {membership: {have: {author: {have: {objectId: {equalTo: $ownerUserObjectId}}}}}}}, member: {have: {objectId: {equalTo: $loginUserID}}}, status: {notEqualTo: "initial"}}, order: updatedAt_DESC) {
    edges {
      node {
        id
        objectId
        ...MembershipPaymentItemViewFragment
      }
    }
  }
  owner: user(id: $ownerUserObjectId) {
    id
    objectId
    name
    createdAt
    updatedAt
    description
    originProfileURL
    jobTitle
    tags {
      __typename
      ... on Element {
        value
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    profileImage {
      url
    }
  }
}

fragment MembershipPaymentItemViewFragment on MembershipPayment {
  id
  objectId
  paymentResult
  status
  membershipPricing {
    id
    objectId
    type
    price
    duration
  }
  membershipStatus {
    id
    membership {
      id
      title
    }
    expiredDate
    status
  }
}

fragment MembershipStatusCardFragment on MembershipStatus {
  id
  objectId
  status
  expiredDate
  membership {
    id
    objectId
    title
    author {
      id
      objectId
      name
      ...UserProfileImageFragment
    }
  }
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loginUserID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ownerUserObjectId"
},
v2 = {
  "fields": [
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "equalTo",
              "variableName": "loginUserID"
            }
          ],
          "kind": "ObjectValue",
          "name": "objectId"
        }
      ],
      "kind": "ObjectValue",
      "name": "have"
    }
  ],
  "kind": "ObjectValue",
  "name": "member"
},
v3 = {
  "fields": [
    {
      "fields": [
        {
          "fields": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "equalTo",
                      "variableName": "ownerUserObjectId"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "objectId"
                }
              ],
              "kind": "ObjectValue",
              "name": "have"
            }
          ],
          "kind": "ObjectValue",
          "name": "author"
        }
      ],
      "kind": "ObjectValue",
      "name": "have"
    }
  ],
  "kind": "ObjectValue",
  "name": "membership"
},
v4 = [
  {
    "fields": [
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": "updatedAt_DESC"
  },
  {
    "fields": [
      (v2/*: any*/),
      {
        "fields": [
          {
            "fields": [
              (v3/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "have"
          }
        ],
        "kind": "ObjectValue",
        "name": "membershipPricing"
      },
      {
        "kind": "Literal",
        "name": "status",
        "value": {
          "notEqualTo": "initial"
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ownerUserObjectId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originProfileURL",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiredDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipStatusScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "MembershipStatusConnection",
        "kind": "LinkedField",
        "name": "membershipStatuses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipStatusEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipStatus",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MembershipStatusCardFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "MembershipPaymentConnection",
        "kind": "LinkedField",
        "name": "membershipPayments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipPaymentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipPayment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MembershipPaymentItemViewFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "owner",
        "args": (v9/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MembershipStatusScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "MembershipStatusConnection",
        "kind": "LinkedField",
        "name": "membershipStatuses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipStatusEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipStatus",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Membership",
                    "kind": "LinkedField",
                    "name": "membership",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v10/*: any*/),
                          (v14/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "MembershipPaymentConnection",
        "kind": "LinkedField",
        "name": "membershipPayments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipPaymentEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipPayment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "paymentResult",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembershipPricing",
                    "kind": "LinkedField",
                    "name": "membershipPricing",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembershipStatus",
                    "kind": "LinkedField",
                    "name": "membershipStatus",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Membership",
                        "kind": "LinkedField",
                        "name": "membership",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v18/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "owner",
        "args": (v9/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v16/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v5/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b78a85bef0c3bd0a7fe51ca700fe1d35",
    "id": null,
    "metadata": {},
    "name": "MembershipStatusScreenQuery",
    "operationKind": "query",
    "text": "query MembershipStatusScreenQuery(\n  $ownerUserObjectId: ID!\n  $loginUserID: ID!\n) {\n  membershipStatuses(where: {membership: {have: {author: {have: {objectId: {equalTo: $ownerUserObjectId}}}}}, member: {have: {objectId: {equalTo: $loginUserID}}}}) {\n    edges {\n      node {\n        id\n        objectId\n        status\n        ...MembershipStatusCardFragment\n      }\n    }\n  }\n  membershipPayments(where: {membershipPricing: {have: {membership: {have: {author: {have: {objectId: {equalTo: $ownerUserObjectId}}}}}}}, member: {have: {objectId: {equalTo: $loginUserID}}}, status: {notEqualTo: \"initial\"}}, order: updatedAt_DESC) {\n    edges {\n      node {\n        id\n        objectId\n        ...MembershipPaymentItemViewFragment\n      }\n    }\n  }\n  owner: user(id: $ownerUserObjectId) {\n    id\n    objectId\n    name\n    createdAt\n    updatedAt\n    description\n    originProfileURL\n    jobTitle\n    tags {\n      __typename\n      ... on Element {\n        value\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    profileImage {\n      url\n    }\n  }\n}\n\nfragment MembershipPaymentItemViewFragment on MembershipPayment {\n  id\n  objectId\n  paymentResult\n  status\n  membershipPricing {\n    id\n    objectId\n    type\n    price\n    duration\n  }\n  membershipStatus {\n    id\n    membership {\n      id\n      title\n    }\n    expiredDate\n    status\n  }\n}\n\nfragment MembershipStatusCardFragment on MembershipStatus {\n  id\n  objectId\n  status\n  expiredDate\n  membership {\n    id\n    objectId\n    title\n    author {\n      id\n      objectId\n      name\n      ...UserProfileImageFragment\n    }\n  }\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = 'c3f6e158f4b722c4a70645964f46f12c';
export default node;
