import React from 'react';
import { View } from 'react-native';
import { useLocation } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'relay-runtime';
import { PathWhereInput } from '../__generated__/BasePathListQuery.graphql';
import {
  PathOrder,
  RendertronPathListScreenQuery,
} from '../__generated__/RendertronPathListScreenQuery.graphql';
import LFLink from '../components/LFLink';
import LFText from '../components/typo/LFText';

// 러닝패스 전체 목록에 대응하는 a링크 목록을 가진 render tron list 컴포넌트
const RendertronPathListScreen = () => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const page = Number(urlSearchParams.get('page') || 1); // URL의 params로 부터 몇 번째 페이지인지 나타내는 값을 읽어옵니다. default value: 1
  const first = Number(urlSearchParams.get('first') || 100); // URL의 params로 부터 몇 개의 데이터를 가져올지에 대한 값을 읽어옵니다. default value: 100
  const skip = (page - 1) * first; // page와 first를 토대로 데이터 fetching 시에 skip 할 갯수를 알아냅니다. default value: 0

  // 모든 공개 러닝패스를 가져오기 위한 where
  const pathsWhere: PathWhereInput = {
    published: {
      equalTo: true,
    },
  };

  // 생성일 기준 내림차순으로 러닝패스를 정렬하기 위한 order
  const pathsOrder: PathOrder[] = ['createdAt_DESC'];

  // where, order, first(갯수), skip(건너뛸 갯수)를 토대로 paths를 fetching 합니다.
  const { paths } = useLazyLoadQuery<RendertronPathListScreenQuery>(
    graphql`
      query RendertronPathListScreenQuery(
        $where: PathWhereInput!
        $order: [PathOrder!]
        $skip: Int!
        $first: Int!
      ) {
        paths(where: $where, skip: $skip, first: $first, order: $order) {
          count
          edges {
            node {
              id
              objectId
              title
            }
          }
        }
      }
    `,
    { where: pathsWhere, order: pathsOrder, skip, first }
  );

  // 더 fetching 해 올 paths가 있는지를 나타내는 변수입니다.
  const existNextPage: boolean = paths.count > page * first; // 예시: paths.count===92, page===9, first===100일 때 92 > 90이므로 아직 더 가져올 데이터가 남아있음
  return (
    <View>
      {/* path의 링크가 연결된 러닝패스 제목을 나열합니다. */}
      {paths?.edges?.map((edge) => (
        <LFLink key={edge?.node?.objectId} to={`/path/${edge?.node?.objectId}`}>
          <LFText style={{ color: '#2F80ED' }}>{edge?.node?.title}</LFText>
        </LFLink>
      ))}
      {/* page를 1 더하고 first는 유지한채로 다시 해당 컴포넌트를 호출합니다.(다음 페이지의 러닝패스 목록 나열을 위함) */}
      {existNextPage && (
        <LFLink to={`/rendertron/paths?page=${page + 1}&first=${first}`}>
          <LFText>다음 페이지</LFText>
        </LFLink>
      )}
    </View>
  );
};

export default RendertronPathListScreen;
