/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import BaseActivityStreamListPaginationFragmentQuery from "./BaseActivityStreamListPaginationFragmentQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type BaseActivityStreamListPaginationFragment = {
    readonly activityStreams: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly sources: ReadonlyArray<{
                    readonly __typename: string;
                } | null> | null;
                readonly " $fragmentRefs": FragmentRefs<"ActivityStreamItemView_Fragment">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "BaseActivityStreamListPaginationFragment";
};
export type BaseActivityStreamListPaginationFragment$data = BaseActivityStreamListPaginationFragment;
export type BaseActivityStreamListPaginationFragment$key = {
    readonly " $data"?: BaseActivityStreamListPaginationFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"BaseActivityStreamListPaginationFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "activityStreams"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": BaseActivityStreamListPaginationFragmentQuery
    }
  },
  "name": "BaseActivityStreamListPaginationFragment",
  "selections": [
    {
      "alias": "activityStreams",
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": "updatedAt_DESC"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "ActivityStreamConnection",
      "kind": "LinkedField",
      "name": "__pagination_base_activityStreams_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivityStreamEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ActivityStream",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "objectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "sources",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ActivityStreamItemView_Fragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '7637fe2c5748c8cac8274d9dbfa26a42';
export default node;
