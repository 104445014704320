/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProfileEditorScreenViewerQueryVariables = {};
export type ProfileEditorScreenViewerQueryResponse = {
    readonly viewer: {
        readonly user: {
            readonly id: string;
            readonly objectId: string;
            readonly name: string | null;
            readonly description: string | null;
            readonly originProfileURL: string | null;
            readonly jobTitle: string | null;
            readonly tags: ReadonlyArray<{
                readonly value?: unknown;
            } | null> | null;
            readonly profileImage: {
                readonly url: string;
                readonly __typename: string;
            } | null;
        };
    };
};
export type ProfileEditorScreenViewerQuery = {
    readonly response: ProfileEditorScreenViewerQueryResponse;
    readonly variables: ProfileEditorScreenViewerQueryVariables;
};



/*
query ProfileEditorScreenViewerQuery {
  viewer {
    user {
      id
      objectId
      name
      description
      originProfileURL
      jobTitle
      tags {
        __typename
        ... on Element {
          value
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
      profileImage {
        url
        __typename
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originProfileURL",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileEditorScreenViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileEditorScreenViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13b7dd387b5fba57e6dc4e093ff282b2",
    "id": null,
    "metadata": {},
    "name": "ProfileEditorScreenViewerQuery",
    "operationKind": "query",
    "text": "query ProfileEditorScreenViewerQuery {\n  viewer {\n    user {\n      id\n      objectId\n      name\n      description\n      originProfileURL\n      jobTitle\n      tags {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      profileImage {\n        url\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c8ff279de53af4807449b3f290b5032c';
export default node;
