import React, { useState, useRef, useEffect, Suspense } from 'react';
import { useLocalStorageState } from 'ahooks';
import { HistoryBackButton } from '../components/NavBar';
import {
  View,
  StyleSheet,
  Image,
  SectionList,
  RefreshControl,
  TouchableOpacity,
  TextInput,
  ScrollView,
} from 'react-native';
import _ from 'lodash';
import { Ionicons } from '@expo/vector-icons';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from 'react-relay';
import { SearchScreenPathQuery } from '../__generated__/SearchScreenPathQuery.graphql';
import { SearchPathPaginationQuery } from '../__generated__/SearchPathPaginationQuery.graphql';
import { SearchContentItemPaginationQuery } from '../__generated__/SearchContentItemPaginationQuery.graphql';
import { SearchScreenContentItemView_Fragment$key } from '../__generated__/SearchScreenContentItemView_Fragment.graphql';
import { SearchScreenContentItemQuery } from '../__generated__/SearchScreenContentItemQuery.graphql';
import { SearchScreenPathItemView_Fragment$key } from '../__generated__/SearchScreenPathItemView_Fragment.graphql';
import { useHistory, useLocation } from 'react-router';
import { atom } from 'recoil';
import SuspenseFallbackScreen from './SuspenseFallbackScreen';
import PreviewImage from '../components/PreviewImage';
import { useBrowserTitleUpdateEffect } from '../hooks/browserTitle';
import LFLink from '../components/LFLink';
import { resizedImageURL } from '../helper/resizedImageURL';
import LFText from '../components/typo/LFText';

export const isSearchFiredState = atom({
  key: 'isSearchFired',
  default: false,
});

export interface SearchScreenProps {
  placeholder?: string;
  onPressPath?: (id: string) => void;
  onPressContentItem?: (id: string, contentId: string) => void;
  onPressClose?: () => void;
}
const SearchScreen: React.FC<SearchScreenProps> = ({
  placeholder = '배우고 싶은 것 무엇이든 검색해보세요.',
  onPressPath,
  onPressContentItem,
  onPressClose,
}) => {
  useBrowserTitleUpdateEffect('검색');
  const history = useHistory();
  const searchBarRef = useRef<TextInput>(null);
  const { pathname, search } = useLocation();
  const queryFromQueryString =
    new URLSearchParams(decodeURIComponent(search)).get('q') || '';
  const [searchTextParam, setSearchTextParam] = useState(queryFromQueryString);
  const [searchText, setSearchText] = useState(searchTextParam);
  const goSearch = (k: string) => {
    // setIsSearchFired(!isSearchFired);
    const trimmedk = k.trim();
    history.replace({
      pathname: pathname,
      search: k ? `?q=${encodeURIComponent(trimmedk)}` : null,
    });
    setSearchTextParam(trimmedk);
  };

  return (
    <Flex
      direction="column"
      align="stretch"
      justify="start"
      style={{ flex: 1 }}
    >
      <WhiteSpace size="lg" />
      <Flex style={{ paddingHorizontal: 16 }}>
        <HistoryBackButton
          onPress={() => {
            _.isFunction(onPressClose) ? onPressClose() : history.goBack();
          }}
        />
        <Flex
          direction="row"
          style={{
            flex: 1,
            borderWidth: 1,
            borderRadius: 14,
            borderColor: '#C6C6C6',
          }}
          align="center"
        >
          <Ionicons
            name="search"
            size={18}
            color="#C6C6C6"
            style={{ marginLeft: 10 }}
          />
          <TextInput
            multiline={false}
            onChangeText={setSearchText}
            value={searchText}
            onSubmitEditing={() => {
              goSearch(searchText);
            }}
            placeholder={placeholder}
            ref={searchBarRef}
            style={styles.searchBarInput}
            autoFocus
          />

          {searchText ? (
            <TouchableOpacity
              onPress={() =>
                searchText === ''
                  ? null
                  : (searchBarRef.current?.clear(), setSearchText(''))
              }
              style={styles.txtEraseBtn}
            >
              <Ionicons name="close-circle" size={24} color="#C4C4C4" />
            </TouchableOpacity>
          ) : null}
        </Flex>
      </Flex>
      <WhiteSpace size="lg" />
      <Suspense fallback={<SuspenseFallbackScreen />}>
        <SearchResultView
          searchTextParam={searchTextParam}
          onPressRecentSearchText={(k) => {
            setSearchText(k);
            goSearch(k);
          }}
          onPressPath={(id) => {
            _.isFunction(onPressPath)
              ? onPressPath(id)
              : history.push(`/path/${id}`);
          }}
          onPressContentItem={(id, contentId) => {
            _.isFunction(onPressContentItem)
              ? onPressContentItem(id, contentId)
              : history.push(`/note/${contentId}/${id}`);
          }}
        />
      </Suspense>
    </Flex>
  );
};
const SearchResultView: React.FC<{
  searchTextParam: string;
  onPressRecentSearchText: (k: string) => void;
  onPressPath: (id: string) => void;
  onPressContentItem: (id: string, contentId: string) => void;
  recentKeywordStorageKey: string;
}> = ({
  searchTextParam,
  onPressRecentSearchText,
  onPressPath,
  onPressContentItem,
  recentKeywordStorageKey = 'myRecentSearchKeyword',
}) => {
  const [recentKeyword, setRecentKeyword] = useLocalStorageState<String[]>(
    recentKeywordStorageKey,
    []
  );

  const sectionListRef = useRef<SectionList>(null);

  // const goSearch = () => {
  //   // setIsSearchFired(!isSearchFired);
  //   history.replace({
  //     pathname: '/search',
  //     search: `?query=${encodeURIComponent(searchText)}`,
  //   });
  // };

  // useLocalStorageState의 setter함수로 updater함수를 사용한 이유는 'myRecentSearchKeyword'라는 key를 갖는 배열에 검색창에서 받은 입력값을 넣어주기 위함
  useEffect(() => {
    if (searchTextParam !== '') {
      setRecentKeyword((prevState = []) => [searchTextParam, ...prevState]);
      // 'searchTextParam'을 useEffect의 dependency로 사용한 이유는 해당 변수가 검색창이 눌렸을 때 입력된 value를 받고 있기 때문
    }
  }, [searchTextParam]);

  const paths = useLazyLoadQuery<SearchScreenPathQuery>(
    graphql`
      query SearchScreenPathQuery(
        $count: Int
        $after: String
        $searchText: String
      ) {
        ...SearchScreenPathPaginationQuery
      }
    `,
    { count: 6, searchText: '(?i)' + searchTextParam },
    { fetchPolicy: 'store-and-network' }
  );

  const pathPagination = usePaginationFragment<
    SearchPathPaginationQuery,
    SearchScreenPathItemView_Fragment$key
  >(
    //
    graphql`
      fragment SearchScreenPathPaginationQuery on Query
      @refetchable(queryName: "SearchPathPaginationQuery") {
        paths(
          first: $count
          after: $after
          where: {
            AND: [
              { published: { equalTo: true } }
              {
                OR: [
                  { title: { matchesRegex: $searchText } }
                  { author: { have: {name: {matchesRegex: $searchText}}}}
                ]
              }
            ]
          }
          order: updatedAt_DESC
        ) @connection(key: "Path_paths") {
          count
          edges {
            node {
              objectId
              title
              ...SearchScreenPathItemView_Fragment
            }
          }
        }
      }
    `,
    paths
  );

  const contentItemsQueryRef = useLazyLoadQuery<SearchScreenContentItemQuery>(
    graphql`
      query SearchScreenContentItemQuery(
        $count: Int
        $after: String
        $searchText: String
      ) {
        ...SearchScreenContentItemPaginationQuery
      }
    `,
    { count: 6, searchText: '(?i)' + searchTextParam },
    { fetchPolicy: 'store-and-network' }
  );

  const contentItemPagination = usePaginationFragment<
    SearchContentItemPaginationQuery,
    SearchScreenContentItemView_Fragment$key
  >(
    graphql`
      fragment SearchScreenContentItemPaginationQuery on Query
      @refetchable(queryName: "SearchContentItemPaginationQuery") {
        contentItems(
          first: $count
          after: $after
          where: {
            AND: [
              { published: { equalTo: true } }
              {
                OR: [
                  { title: { matchesRegex: $searchText } }
                  { author: { have: {name: {matchesRegex: $searchText}}}}
                ]
              }
            ]
          }
          order: updatedAt_DESC
        ) @connection(key: "ContentItem_contentItems") {
          count
          edges {
            node {
              objectId
              content {
                objectId
              }
              ...SearchScreenContentItemView_Fragment
            }
          }
        }
      }
    `,
    contentItemsQueryRef
  );

  const sectionsAll = [
    {
      title: `러닝패스 (${pathPagination.data.paths.count})`,
      data: _.chunk(
        _.map(pathPagination.data.paths.edges, (e) => e.node),
        2
      ),
      footerType: 'Path',
    },
    {
      title: `학습노트 (${contentItemPagination.data.contentItems.count})`,
      data: _.chunk(
        _.map(contentItemPagination.data.contentItems.edges, (e) => e.node),
        2
      ),
      footerType: 'ContentItem',
    },
  ];

  const currentSectionData = sectionsAll;
  const searchResultCount =
    pathPagination.data.paths.count +
    contentItemPagination.data.contentItems.count;

  return (
    <View style={{ flex: 1 }}>
      {!searchTextParam ? (
        <ScrollView>
          {recentKeyword?.length === 0 ? (
            // 검색_default 와이어프레임
            <Flex
              direction="column"
              align="stretch"
              style={{ paddingHorizontal: 16 }}
            >
              <LFText style={{ fontWeight: 'bold' }}>최근 검색어</LFText>
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <WhiteSpace size="lg" />
              <Flex justify="center">
                <LFText>최근 검색 내역이 없습니다.</LFText>
              </Flex>
            </Flex>
          ) : (
            // 검색_최근 검색어 예시 와이어프레임
            <Flex
              direction="column"
              align="stretch"
              style={{ paddingHorizontal: 16 }}
            >
              <Flex justify="between">
                <LFText style={{ fontWeight: 'bold' }}>최근 검색어</LFText>
                <TouchableOpacity
                  // 전체삭제 클릭 시, 검색_default 화면이 노출되도록 localStorage의 'myRecentSearchKeyword' key의 value를 ['']로 초기화하고, 검색어를 받는 상태도 빈문자열로 초기화
                  onPress={() => {
                    setRecentKeyword([]);
                    // setSearchText('');
                  }}
                >
                  <LFText style={{ fontSize: 11 }}>전체 삭제</LFText>
                </TouchableOpacity>
              </Flex>
              <WhiteSpace size="lg" />

              {_.map(recentKeyword, (k: string, idx) => {
                const removeSearchText = (id: number) => {
                  const newSearchText = recentKeyword.filter(
                    (w, index) => index !== id
                  );
                  setRecentKeyword(newSearchText);
                };
                return (
                  <>
                    <Flex key={idx} justify="between">
                      <Flex>
                        <View style={{ marginRight: 9 }}>
                          <Ionicons
                            name="time-outline"
                            size={20}
                            color="#565555"
                          />
                        </View>
                        <TouchableOpacity
                          onPress={() => onPressRecentSearchText(k)}
                          // 한글 기준 20글자 넘어갈 시 '...'처리 되도록 width 값 조정
                          style={{ width: 254 }}
                        >
                          <LFText numberOfLines={1}>{k}</LFText>
                        </TouchableOpacity>
                      </Flex>
                      <TouchableOpacity
                        onPress={() => {
                          removeSearchText(idx);
                        }}
                      >
                        <LFText style={{ fontSize: 14, color: '#565555' }}>
                          X
                        </LFText>
                      </TouchableOpacity>
                    </Flex>
                    <WhiteSpace size="lg" />
                  </>
                );
              })}
            </Flex>
          )}
        </ScrollView>
      ) : (
        <>
          <WhiteSpace size="lg" />
          <>
            {searchResultCount > 0 ? (
              <Flex style={{ paddingHorizontal: 16 }}>
                <LFText style={styles.highlightBlue}>{searchTextParam}</LFText>
                <LFText>에 대한&nbsp;</LFText>
                <LFText style={styles.highlightBlue}>
                  {searchResultCount}
                </LFText>
                <LFText>개의 검색결과</LFText>
              </Flex>
            ) : (
              <Flex style={{ paddingHorizontal: 16 }}>
                <LFText style={styles.highlightBlue}>{searchTextParam}</LFText>
                <LFText>에 대한&nbsp;</LFText>
                <LFText>검색 결과가 없습니다</LFText>
              </Flex>
            )}
          </>
          <WhiteSpace size="lg" />
          <SectionList
            ref={sectionListRef}
            style={{ flex: 1 }}
            sections={currentSectionData}
            keyExtractor={(item) => {
              return _.reduce(
                _.castArray(item),
                (sum, item) => {
                  return sum + item.objectId;
                },
                ''
              );
            }}
            contentContainerStyle={{ paddingHorizontal: 16 }}
            renderItem={(info) => {
              return (
                <Flex
                  direction="row"
                  align="stretch"
                  style={{ marginHorizontal: -8, marginBottom: 16 }}
                >
                  {_.map(_.castArray(info.item), (item) => {
                    if (!item.objectId) {
                      console.log('### invalid :', item, info.index);
                      return <View></View>;
                    } else if (item.__typename === 'Path') {
                      return (
                        <LFLink
                          testID="learningpath:SearchScreen"
                          style={{ flex: 1, marginHorizontal: 8 }}
                          onPress={() => {
                            // history.push(`/learn/path/${item.objectId}/0`);
                            onPressPath(item.objectId);
                          }}
                        >
                          <PathItemView
                            isSimpleMode={
                              info.section.footerType ? false : true
                            }
                            pathFrgmt={item}
                          />
                        </LFLink>
                      );
                    } else if (item.__typename === 'ContentItem') {
                      return (
                        <LFLink
                          testID="content-item:SearchScreen"
                          style={{ flex: 1, marginHorizontal: 8 }}
                          onPress={() => {
                            onPressContentItem(
                              item.objectId,
                              item.content.objectId
                            );
                            // history.push(
                            //   `/note/${item.content.objectId}/${item.objectId}`
                            // );
                          }}
                        >
                          <ContentItemView itemFrgmt={item} />
                        </LFLink>
                      );
                    }
                    return <View />;
                  })}
                  {info.section.footerType !== 'createPath' &&
                    _.castArray(info.item).length === 1 && (
                      <View style={{ width: '50%' }} />
                    )}
                </Flex>
              );
            }}
            refreshing={true}
            refreshControl={
              <RefreshControl refreshing={true} onRefresh={() => {}} />
            }
            renderSectionHeader={({ section }) => {
              return (
                <>
                  <LFText style={styles.title}>{section.title}</LFText>
                  {section.title && <WhiteSpace size="lg" />}
                </>
              );
            }}
            onEndReached={() => {}}
            onEndReachedThreshold={0.1}
            renderSectionFooter={({ section }) => {
              if (section.footerType === 'Path') {
                if (pathPagination.data.paths.count === 0) {
                  return (
                    <Flex align="start" direction="column">
                      <LFText>검색 결과가 없습니다.</LFText>
                      <WhiteSpace size="xl" />
                    </Flex>
                  );
                }

                return (
                  <>
                    {pathPagination && pathPagination.hasNext && (
                      <TouchableOpacity
                        onPress={() => {
                          pathPagination.loadNext(
                            pathPagination.data.paths.count
                          );
                        }}
                        style={styles.moreBtn}
                      >
                        <LFText style={styles.moreBtnTxt}>더보기</LFText>
                        {/* TODO: 검색어가 포함된 학습노트 전체 노출 개수 중 6개를 제외한 나머지 검색결과 개수 */}
                        <Ionicons
                          name="chevron-down"
                          size={18}
                          color="#979292"
                        />
                      </TouchableOpacity>
                    )}
                  </>
                );
              } else if (section.footerType === 'ContentItem') {
                if (contentItemPagination.data.contentItems.count === 0) {
                  return (
                    <Flex align="start" direction="column">
                      <LFText>검색 결과가 없습니다.</LFText>
                      <WhiteSpace size="xl" />
                    </Flex>
                  );
                }

                return (
                  <>
                    {contentItemPagination && contentItemPagination.hasNext && (
                      <TouchableOpacity
                        onPress={() => {
                          contentItemPagination.loadNext(
                            contentItemPagination.data.contentItems.count
                          );
                        }}
                        style={styles.moreBtn}
                      >
                        <LFText style={styles.moreBtnTxt}>더보기</LFText>
                        {/* TODO: 검색어가 포함된 학습노트 전체 노출 개수 중 6개를 제외한 나머지 검색결과 개수 */}
                        <Ionicons
                          name="chevron-down"
                          size={18}
                          color="#979292"
                        />
                      </TouchableOpacity>
                    )}
                  </>
                );
              }
              return null;
            }}
          />
        </>
      )}
    </View>
  );
};

interface PathItemProp {
  pathFrgmt: SearchScreenPathItemView_Fragment$key;
  isSimpleMode?: boolean;
}
const PathItemView: React.FC<PathItemProp> = ({
  pathFrgmt,
  isSimpleMode = false,
}) => {
  const path = useFragment(
    graphql`
      fragment SearchScreenPathItemView_Fragment on Path {
        id
        title
        contentItemList {
          ... on ContentItem {
            content {
              thumbURL
            }
          }
        }
        author {
          objectId
          name
          originProfileURL
          jobTitle
          profileImage {
            url
          }
        }
        tags {
          ... on Element {
            value
          }
        }
        instructionCards(order: updatedAt_DESC) {
          edges {
            node {
              id
              objectId
              description
              sources {
                __typename
                ... on ContentItem {
                  id
                  objectId
                  title
                  content {
                    id
                    title
                    objectId
                    thumbURL
                    type
                  }
                  author {
                    objectId
                    name
                  }
                  # ...ContentItemView_Fragment
                }
                ... on Content {
                  id
                  objectId
                  thumbURL
                  title
                  link
                  type
                }
                ... on Path {
                  id
                  objectId
                  title
                  description
                  author {
                    objectId
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    pathFrgmt
  );
  const cardsWithContent = path.instructionCards?.edges?.filter((edge) => {
    // content or contentItem이 존재하지 않는 instruction card를 걸러냅니다.
    return edge?.node?.sources && edge?.node?.sources?.length > 0;
  });
  return (
    <View
      style={
        isSimpleMode ? simplePathItemStyles.container : pathItemStyles.container
      }
    >
      <View style={pathItemStyles.subContainer}>
        {!isSimpleMode && (
          <>
            <LFText numberOfLines={1} style={pathItemStyles.tagText}>
              {path.tags?.map((tag) => `#${tag?.value}`).join(' ')}
            </LFText>
            <WhiteSpace />
          </>
        )}
        <LFText
          style={
            isSimpleMode ? simplePathItemStyles.title : pathItemStyles.title
          }
          numberOfLines={3}
        >
          {path?.title}
        </LFText>
        <WhiteSpace />
        <Flex style={pathItemStyles.author} justify="start" align="center">
          {/* TODO: default profile image 추가하기 */}
          {!isSimpleMode && (
            <Image
              accessibilityLabel=""
              source={{
                uri: resizedImageURL(
                  path.author?.profileImage?.url,
                  200,
                  200,
                  path?.author?.originProfileURL
                ),
              }}
              style={pathItemStyles.authorImage}
            />
          )}
          <LFText
            numberOfLines={1}
            style={
              isSimpleMode
                ? simplePathItemStyles.authorText
                : pathItemStyles.authorText
            }
          >{`${path?.author?.name} ${
            path?.author?.jobTitle && !isSimpleMode
              ? ` ・ ${path?.author?.jobTitle}`
              : ''
          }`}</LFText>
        </Flex>
      </View>
      <WhiteSpace />
      <View style={pathItemStyles.imageContainer}>
        {cardsWithContent
          ?.concat([{}, {}, {}, {}])
          .slice(0, 4)
          .map((card, index) => {
            const source = _.isEmpty(card) ? {} : card?.node?.sources[0];
            const thumbURL =
              source?.__typename === 'ContentItem'
                ? source.content?.thumbURL
                : source.thumbURL;
            return (
              <PreviewImage
                key={index}
                style={pathItemStyles.imageInPath}
                source={{ uri: thumbURL || '' }}
              />
            );
          })}
        <View style={pathItemStyles.siderContainer}>
          <LFText style={pathItemStyles.siderText}>
            {cardsWithContent.length}
          </LFText>
          <Ionicons name="git-branch" size={24} color="white" />
        </View>
      </View>
    </View>
  );
};

interface ContentItemProp {
  // title: string;
  // author: { name: string };
  // thumbnailURL: string;
  // tags: string[];
  itemFrgmt: SearchScreenContentItemView_Fragment$key;
}

const ContentItemView: React.FC<ContentItemProp> = ({ itemFrgmt }) => {
  const item = useFragment(
    graphql`
      fragment SearchScreenContentItemView_Fragment on ContentItem {
        id
        objectId
        title
        description
        content {
          id
          objectId
          type
          title
          description
          thumbURL
          link
        }
        author {
          objectId
          name
        }
        noteData {
          ... on Element {
            value
          }
        }
      }
    `,
    itemFrgmt
  );

  const { title, author, content, tags } = item;
  return (
    <View style={contentItemStyles.container}>
      <View style={contentItemStyles.imageContainer}>
        <PreviewImage
          accessibilityLabel=""
          source={{ uri: content?.thumbURL || '' }}
          style={contentItemStyles.image}
        />
        <Image
          accessibilityLabel="영상 재생 모양 아이콘"
          style={contentItemStyles.playIcon}
          source={require('../assets/images/playIcon.png')}
        />
      </View>
      <View style={contentItemStyles.subContainer}>
        <LFText numberOfLines={3} style={contentItemStyles.title}>
          {title}
        </LFText>
        <WhiteSpace />
        <LFText numberOfLines={1} style={contentItemStyles.author}>
          {author?.name}
        </LFText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: '#FFFFFF' },
  title: { fontWeight: 'bold', fontSize: 16, lineHeight: 26, color: '#262626' },
  searchBarInput: {
    flex: 1,
    // marginRight: 8,
    padding: 8,
  },
  searchBarIconWrapper: {
    position: 'absolute',
    paddingLeft: 5,
  },
  moreBtn: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: 16,
    paddingVertical: 12,
    borderWidth: 1,
    borderColor: '#D9CECE',
  },
  moreBtnTxt: {
    color: '#979292',
  },
  txtEraseBtn: {
    padding: 2,
    paddingHorizontal: 5,
  },
  highlightBlue: {
    fontWeight: 'bold',
    color: '#2BA3F3',
  },
});

const pathItemStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: '#FFF',
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
  },
  subContainer: { flex: 1 },
  imageContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '55%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  imageInPath: { width: '50%', height: 0, paddingBottom: '27.5%' },
  image: { width: '100%', height: 0, paddingBottom: '55%' },
  playIcon: {
    position: 'absolute',
    width: 40,
    height: 40,
    left: 0,
    bottom: 0,
  },
  siderContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '40%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  siderText: { color: 'white', fontSize: 24 },
  tags: { flexDirection: 'row', flexWrap: 'wrap', margin: -2 },
  tagWrapper: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderWidth: 1,
    borderRadius: 13,
    margin: 2,
  },
  tagText: { fontSize: 14, color: '#295ECC' },
  title: { fontSize: 24, lineHeight: 32, fontWeight: 'bold', color: '#262626' },
  author: { marginTop: 'auto' },
  authorImage: {
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: 8,
    borderWidth: 1,
    borderColor: 'rgb(239, 239, 239)',
  },
  authorText: { fontSize: 14, color: '#787878' },
});

const simplePathItemStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 14,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
  },
  title: { fontSize: 14, fontWeight: 'bold', color: '#000000' },
  authorText: { fontSize: 12, color: '#787878' },
});

const contentItemStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
  },
  subContainer: { flex: 1, padding: 16 },
  imageContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '55%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  imageInPath: { width: '50%', height: 0, paddingBottom: '30%' },
  image: {
    width: '100%',
    height: 0,
    paddingBottom: '55%',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  playIcon: {
    position: 'absolute',
    width: '15%',
    height: 0,
    paddingBottom: '15%',
    left: '2.7%',
    bottom: '5%',
  },
  siderContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '40%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  siderText: { color: 'white', fontSize: 24 },
  tags: { flexDirection: 'row', flexWrap: 'wrap', margin: -2 },
  tagWrapper: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderWidth: 1,
    borderRadius: 13,
    margin: 2,
  },
  tagText: { fontSize: 14, color: '#295ECC' },
  title: { fontSize: 14, fontWeight: 'bold', color: '#000000' },
  author: { marginTop: 'auto', fontSize: 12, color: '#787878' },
  authorImage: { width: 24, height: 24, borderRadius: 12 },
  authorText: { marginLeft: 8, fontSize: 14, color: '#787878' },
});

export default SearchScreen;
