import { useEffect } from 'react';

const useDeviceWidthChangeEffect = ({
  effect,
}: {
  effect: (width: number) => void;
}) => {
  useEffect(() => {
    const onResize = (e: UIEvent) => {
      e.preventDefault();
      effect(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
};

export default useDeviceWidthChangeEffect;
