/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdatePathInput = {
    id: string;
    fields?: UpdatePathFieldsInput | null;
    clientMutationId?: string | null;
};
export type UpdatePathFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    contentItemList?: Array<unknown | null> | null;
    author?: UserPointerInput | null;
    learnCount?: number | null;
    published?: boolean | null;
    description?: string | null;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    analytics?: AnalyticsPathPointerInput | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    isSeries?: boolean | null;
    draft?: PathDraftPointerInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type InstructionCardRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateInstructionCardFieldsInput> | null;
};
export type CreateInstructionCardFieldsInput = {
    ACL?: ACLInput | null;
    description?: string | null;
    author?: UserPointerInput | null;
    path?: unknown | null;
    sources?: Array<unknown | null> | null;
    seq?: number | null;
    optional?: boolean | null;
    pathDraft?: PathDraftPointerInput | null;
};
export type PathDraftPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathDraftFieldsInput | null;
};
export type CreatePathDraftFieldsInput = {
    ACL?: ACLInput | null;
    original: PathPointerInput;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    description?: string | null;
    isSeries?: boolean | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    progressing?: boolean | null;
};
export type PathPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathFieldsInput | null;
};
export type CreatePathFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    contentItemList?: Array<unknown | null> | null;
    author?: UserPointerInput | null;
    learnCount?: number | null;
    published?: boolean | null;
    description?: string | null;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    analytics?: AnalyticsPathPointerInput | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    isSeries?: boolean | null;
    draft?: PathDraftPointerInput | null;
};
export type MembershipPointerInput = {
    link?: string | null;
    createAndLink?: CreateMembershipFieldsInput | null;
};
export type CreateMembershipFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    author?: UserPointerInput | null;
    level?: string | null;
    role?: RolePointerInput | null;
};
export type RolePointerInput = {
    link?: string | null;
    createAndLink?: CreateRoleFieldsInput | null;
};
export type CreateRoleFieldsInput = {
    ACL?: ACLInput | null;
    name?: string | null;
    users?: UserRelationInput | null;
    roles?: RoleRelationInput | null;
};
export type UserRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateUserFieldsInput> | null;
};
export type RoleRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateRoleFieldsInput> | null;
};
export type AnalyticsPathPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsPathFieldsInput | null;
};
export type CreateAnalyticsPathFieldsInput = {
    ACL?: ACLInput | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    path?: PathPointerInput | null;
    viewCount?: number | null;
    commentCount?: number | null;
};
export type MyPathScreenPathUpdateMutationVariables = {
    input: UpdatePathInput;
};
export type MyPathScreenPathUpdateMutationResponse = {
    readonly updatePath: {
        readonly path: {
            readonly id: string;
            readonly objectId: string;
            readonly published: boolean | null;
        };
    } | null;
};
export type MyPathScreenPathUpdateMutation = {
    readonly response: MyPathScreenPathUpdateMutationResponse;
    readonly variables: MyPathScreenPathUpdateMutationVariables;
};



/*
mutation MyPathScreenPathUpdateMutation(
  $input: UpdatePathInput!
) {
  updatePath(input: $input) {
    path {
      id
      objectId
      published
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdatePathPayload",
    "kind": "LinkedField",
    "name": "updatePath",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Path",
        "kind": "LinkedField",
        "name": "path",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "published",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyPathScreenPathUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyPathScreenPathUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c18f35a9776678a64c642219f3c48f06",
    "id": null,
    "metadata": {},
    "name": "MyPathScreenPathUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MyPathScreenPathUpdateMutation(\n  $input: UpdatePathInput!\n) {\n  updatePath(input: $input) {\n    path {\n      id\n      objectId\n      published\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd94cdde7d8ff8bd07cc2c33f414781c5';
export default node;
