import {
  Image,
  ImageStyle,
  StyleSheet,
  TextInputProps,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import React, { createContext, useContext, useState } from 'react';
import { graphql } from 'relay-runtime';
import { useFragment } from 'react-relay';
import UserProfileImage from '../UserProfileImage';
import {
  CommentFragment,
  CommentFragment$key,
} from '../../__generated__/CommentFragment.graphql';
import LFText, { LFHyperLink } from '../typo/LFText';
import MultilineGrowingTextInput from '../MultilineGrowingTextInput';
import LFLink from '../LFLink';
import LFWhiteSpace from '../LFWhiteSpace';
import { useAuth } from '../../hooks/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginScreenAccessAction } from '../../screens/LoginScreen';
import { Flex, Modal } from '@ant-design/react-native';
import Reply from './Reply';
import dayjs from 'dayjs';
import { Badge } from './Comments';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { NotePlayerScreenDeleteCommentMutationResponse } from '../../__generated__/NotePlayerScreenDeleteCommentMutation.graphql';
import { colors } from '../../constants/styleGuide';
import _ from 'lodash';
import {
  NotePlayerScreenUpdateCommentMutationResponse,
  UpdateCommentFieldsInput,
} from '../../__generated__/NotePlayerScreenUpdateCommentMutation.graphql';

const CommentContext = createContext<CommentFragment | null>(null);

const useComment = () => {
  return useContext(CommentContext);
};

interface Comment {
  children: React.ReactNode;
  fragmentRef: CommentFragment$key | null;
  props?: Partial<ViewProps>;
  style?: ViewStyle;
}

function Comment({ fragmentRef, children, style, ...props }: Comment) {
  const data = useFragment(
    graphql`
      fragment CommentFragment on Comment {
        id
        objectId
        text
        createdAt
        author {
          id
          objectId
          name
          ...UserProfileImageFragment
        }
        path {
          id
          objectId
          author {
            id
            objectId
          }
        }
        contentItem {
          id
          objectId
          author {
            id
            objectId
          }
        }
        targetClassName
        comments(order: [createdAt_ASC]) {
          edges {
            node {
              id
              objectId
              createdAt
              text
              ...ReplyFragment
              author {
                id
                objectId
              }
            }
          }
        }
      }
    `,
    fragmentRef
  );

  return (
    <CommentContext.Provider value={data} {...props}>
      <View style={style}>{children}</View>
    </CommentContext.Provider>
  );
}

export default Comment;

function Avatar({
  additionalText,
  right,
  style,
  size,
  textStyle,
  imageStyle,
  onPress,
}: {
  additionalText?: string | React.ReactNode;
  right?: React.ReactNode;
  style?: ViewStyle;
  size?: number;
  textStyle?: TextStyle;
  imageStyle?: ImageStyle;
  onPress?: (userId: string) => void;
}) {
  const data = useComment();

  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      <LFLink
        style={{ flexDirection: 'row', alignItems: 'center' }}
        onPress={() =>
          onPress && data?.author?.objectId && onPress(data.author.objectId)
        }
      >
        <UserProfileImage
          userFrgmt={data?.author || null}
          size={size || 24}
          style={{
            borderWidth: 1,
            borderColor: 'rgba(26, 27, 30, 0.1)',
            backgroundColor: 'white',
            ...(imageStyle || []),
          }}
        />
        <LFWhiteSpace size="xs" direction="row" />
        <LFText style={[styles.userNameText, textStyle]}>
          {data?.author?.name}
        </LFText>
      </LFLink>
      {additionalText}
      <LFWhiteSpace size={1} direction="row" style={{ marginLeft: 'auto' }} />
      {right}
    </View>
  );
}

function Text({
  style,
  textStyle,
}: {
  style?: ViewStyle;
  textStyle?: TextStyle;
}) {
  const data = useComment();
  return (
    <View style={style}>
      <LFHyperLink>
        <LFText style={[textStyle]}>{data?.text}</LFText>
      </LFHyperLink>
    </View>
  );
}

interface CommentForm extends TextInputProps {
  controls: React.ReactNode;
  style?: TextStyle;
}

export function CommentForm({ controls, style, ...props }: CommentForm) {
  return (
    <View>
      <MultilineGrowingTextInput
        {...props}
        style={[styles.commentForm, style]}
      />
      {controls}
    </View>
  );
}

export function CommentControls({
  onPressCancelButton,
  onPressSubbmitButton,
  style,
  cancelText = '취소',
  submitText = '작성하기',
  cancelButtonStyle,
  submitButtonStyle,
  cancelButtonTextStyle,
  submitButtonTextStyle,
  disabled,
}: {
  onPressCancelButton?: () => void;
  onPressSubbmitButton?: () => void;
  style?: ViewStyle;
  cancelText?: string;
  submitText?: string;
  cancelButtonStyle?: ViewStyle;
  submitButtonStyle?: ViewStyle;
  cancelButtonTextStyle?: TextStyle;
  submitButtonTextStyle?: TextStyle;
  disabled?: boolean;
}) {
  return (
    <Flex style={[styles.commentFormButtonContainer, style]}>
      <LFLink
        style={[styles.commentFormButton, cancelButtonStyle]}
        onPress={onPressCancelButton}
      >
        <LFText
          style={[styles.commentFormButtonCancelText, cancelButtonTextStyle]}
        >
          {cancelText}
        </LFText>
      </LFLink>
      <LFWhiteSpace size="xs" direction="row" />
      <LFLink
        disabled={disabled}
        style={[styles.commentFormButton, submitButtonStyle]}
        onPress={onPressSubbmitButton}
      >
        <LFText
          style={[styles.commentFormButtonSubmitText, submitButtonTextStyle]}
        >
          {submitText}
        </LFText>
      </LFLink>
    </Flex>
  );
}

function Button({
  style,
  text,
  onPress,
  textStyle,
}: {
  style?: ViewStyle;
  text: string;
  onPress?: () => void;
  textStyle?: TextStyle;
}) {
  return (
    <LFLink onPress={onPress} style={style}>
      <LFText style={[styles.buttonText, textStyle]}>{text}</LFText>
    </LFLink>
  );
}

interface Replies {
  style?: ViewStyle;
  onClickReplyButton: (id: string) => void;
  onDelete: (
    id: string,
    onCompleted?: (res: NotePlayerScreenDeleteCommentMutationResponse) => void
  ) => void;
  onEdit: (
    id: string,
    fields: UpdateCommentFieldsInput
  ) => Promise<NotePlayerScreenUpdateCommentMutationResponse>;
  afterMutation?: () => void;
  onPressAvatar?: (userId: string) => void;
}

function Replies({
  style,
  onClickReplyButton,
  onDelete,
  onEdit,
  afterMutation,
  onPressAvatar,
}: Replies) {
  const data = useComment();
  const contentAuthorId =
    data?.[
      _.lowerFirst(data?.targetClassName || 'ContentItem') as
        | 'path'
        | 'contentItem'
    ]?.author?.objectId;
  const authorBadgeText =
    _.lowerFirst(data?.targetClassName || '') === 'path'
      ? '러닝패스 제작자'
      : '학습노트 제작자';
  data;
  const { showActionSheetWithOptions } = useActionSheet();
  const [editingReply, setEditingReply] = useState<{
    id: string;
    text: string;
  } | null>(null);
  const validateCommentText = (text: string) => Boolean(text.trim());
  const { user } = useAuth();
  return (
    <View style={style}>
      {data?.comments.edges?.map((edge, index) => {
        const comment = edge?.node;
        return (
          <Reply
            style={{ paddingTop: 16 }}
            key={index}
            fragmentRef={comment || null}
          >
            <Reply.Avatar
              onPress={onPressAvatar}
              style={{ alignItems: 'center' }}
              additionalText={[
                <LFText style={styles.commentAdditionalLabel}>
                  {dayjs((comment?.createdAt || '') as string).fromNow()}
                </LFText>,
                ...(contentAuthorId === comment?.author?.objectId
                  ? [<Badge text={authorBadgeText} />]
                  : []),
              ].map((text, idx) => (
                <Flex key={idx} align="center">
                  <LFWhiteSpace size="xs" direction="row" />
                  <View style={styles.dot}></View>
                  <LFWhiteSpace size="xs" direction="row" />
                  {text}
                </Flex>
              ))}
              right={
                user?.objectId === comment?.author?.objectId && (
                  <LFLink
                    onPress={() => {
                      showActionSheetWithOptions(
                        {
                          options: ['수정', '삭제', '취소'],
                          destructiveButtonIndex: 1,
                          cancelButtonIndex: 2,
                        },
                        (idx) => {
                          if (idx === 0) {
                            setEditingReply({
                              id: comment?.objectId || '',
                              text: comment?.text || '',
                            });
                          } else if (idx === 1) {
                            Modal.alert('해당 댓글을 삭제하시겠습니까?', '', [
                              {
                                text: '취소',
                                onPress: () => {},
                                style: 'cancel',
                              },
                              {
                                text: '삭제',
                                onPress: () =>
                                  onEdit(data.objectId || '', {
                                    comments: {
                                      remove: [comment?.objectId || ''],
                                    },
                                  })
                                    .then((res) => {
                                      if (res.updateComment?.comment.objectId) {
                                        return onDelete(
                                          comment?.objectId || ''
                                        );
                                      }
                                      return null;
                                    })
                                    .then((res) => {
                                      if (res) {
                                        afterMutation && afterMutation();
                                      }
                                    }),
                                style: 'destructive',
                              },
                            ]);
                          }
                        }
                      );
                    }}
                  >
                    <Image
                      accessibilityLabel="답댓글 관련 옵션 열기(수정, 삭제)"
                      style={{ width: 24, height: 24 }}
                      source={require('../../assets/images/nav/icon24More@3x.png')}
                    />
                  </LFLink>
                )
              }
            />
            <LFWhiteSpace size="xs" />
            {editingReply?.id === comment?.objectId ? (
              <View style={{ paddingLeft: 28, paddingTop: 4 }}>
                <Comment.Form
                  value={editingReply?.text}
                  onChangeText={(text) =>
                    setEditingReply((prev) => ({ id: prev?.id || '', text }))
                  }
                  controls={
                    <CommentControls
                      onPressCancelButton={() => {
                        setEditingReply(null);
                      }}
                      onPressSubbmitButton={() =>
                        onEdit(editingReply?.id || '', {
                          text: editingReply?.text,
                        }).then(() => {
                          afterMutation && afterMutation();
                          setEditingReply(null);
                        })
                      }
                      submitText="수정하기"
                      disabled={!validateCommentText(editingReply?.text || '')}
                      submitButtonStyle={
                        validateCommentText(editingReply?.text || '')
                          ? {
                              backgroundColor: '#236EFF1A',
                            }
                          : {}
                      }
                      submitButtonTextStyle={
                        validateCommentText(editingReply?.text || '')
                          ? {
                              color: '#236EFF',
                              fontWeight: '600',
                            }
                          : {}
                      }
                    />
                  }
                />
              </View>
            ) : (
              <View style={{ paddingLeft: 28, paddingRight: 24 }}>
                <Reply.Text />
                <LFWhiteSpace size="sm" />
                {/* <Reply.Button
                  text="댓글 달기"
                  onPress={() => onClickReplyButton(data.objectId)}
                /> */}
              </View>
            )}
          </Reply>
        );
      })}
    </View>
  );
}

Comment.Avatar = Avatar;
Comment.Text = Text;
Comment.Form = CommentForm;
Comment.Button = Button;
Comment.Replies = Replies;

const styles = StyleSheet.create({
  commentForm: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderColor: 'rgba(26, 27, 30, 0.1)',
    borderWidth: 1,
    borderRadius: 12,
    minHeight: 100,
  },
  userNameText: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    color: '#1A1B1E',
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 18,
    color: '#1A1B1E99',
  },
  commentFormButtonContainer: { marginLeft: 'auto', marginTop: 8 },
  commentFormButton: {
    borderRadius: 8,
    backgroundColor: '#F0F1F6',
    paddingVertical: 8,
    paddingHorizontal: 24,
  },
  commentFormButtonCancelText: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    textAlign: 'center',
    color: '#1A1B1E',
  },
  commentFormButtonSubmitText: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    color: '#1A1B1E4D',
  },
  commentAdditionalLabel: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    color: '#1A1B1E4D',
  },
  dot: {
    width: 2,
    height: 2,
    backgroundColor: colors.BORDER_40,
  },
});
