import { Image, ImageProps, StyleSheet, ViewStyle } from 'react-native';
import React from 'react';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import LFText from '../../components/typo/LFText';
import { colors } from '../../constants/styleGuide';
import LFLink from '../../components/LFLink';
import _ from 'lodash';
import { useDeviceType } from '../../hooks/deviceType';

export type Position =
  | '전체'
  | '내 관심사'
  | '개발자'
  | 'P.O/기획자'
  | '디자이너'
  | 'D.S/D.A'
  | '마케터';

interface IPositionItem {
  text: Position;
  defaultImageSource: ImageProps;
  activeImageSource?: ImageProps;
  imageAlt?: string;
  selected: boolean;
  onPress?: () => void;
  style?: ViewStyle;
}

const PositionItem: React.FC<IPositionItem> = ({
  text,
  defaultImageSource,
  activeImageSource,
  imageAlt = '',
  selected,
  onPress,
  style,
}) => {
  const deviceType = useDeviceType();
  return (
    <LFLink
      style={style}
      onPress={() => {
        _.isFunction(onPress) && onPress();
      }}
    >
      <Image
        accessibilityLabel={imageAlt}
        source={
          selected
            ? activeImageSource || defaultImageSource
            : defaultImageSource
        }
        style={[
          deviceType === 'MOBILE' ? styles.imageForMobile : styles.imageForPC,
        ]}
      />
      <LFWhiteSpace size={2} direction="column" />
      <LFText
        style={[
          deviceType === 'MOBILE' ? styles.textForMobile : styles.textForPC,
          selected && styles.highlightText,
        ]}
      >
        {text}
      </LFText>
    </LFLink>
  );
};

export default PositionItem;

const styles = StyleSheet.create({
  imageForMobile: {
    width: 56,
    height: 56,
    borderRadius: 16,
  },
  imageForPC: {
    width: 64,
    height: 64,
    borderRadius: 16,
  },
  highlightImage: {
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: colors.PRIMARY_100,
  },
  textForMobile: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
  textForPC: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_100,
  },
  highlightText: {
    color: colors.PRIMARY_100,
    fontWeight: '600',
  },
});
