/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import SearchContentItemPaginationQuery from "./SearchContentItemPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type SearchScreenContentItemPaginationQuery = {
    readonly contentItems: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly objectId: string;
                readonly content: {
                    readonly objectId: string;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"SearchScreenContentItemView_Fragment">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "SearchScreenContentItemPaginationQuery";
};
export type SearchScreenContentItemPaginationQuery$data = SearchScreenContentItemPaginationQuery;
export type SearchScreenContentItemPaginationQuery$key = {
    readonly " $data"?: SearchScreenContentItemPaginationQuery$data;
    readonly " $fragmentRefs": FragmentRefs<"SearchScreenContentItemPaginationQuery">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "contentItems"
],
v1 = [
  {
    "kind": "Variable",
    "name": "matchesRegex",
    "variableName": "searchText"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "searchText"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": SearchContentItemPaginationQuery
    }
  },
  "name": "SearchScreenContentItemPaginationQuery",
  "selections": [
    {
      "alias": "contentItems",
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": "updatedAt_DESC"
        },
        {
          "fields": [
            {
              "items": [
                {
                  "kind": "Literal",
                  "name": "AND.0",
                  "value": {
                    "published": {
                      "equalTo": true
                    }
                  }
                },
                {
                  "fields": [
                    {
                      "items": [
                        {
                          "fields": [
                            {
                              "fields": (v1/*: any*/),
                              "kind": "ObjectValue",
                              "name": "title"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "OR.0"
                        },
                        {
                          "fields": [
                            {
                              "fields": [
                                {
                                  "fields": [
                                    {
                                      "fields": (v1/*: any*/),
                                      "kind": "ObjectValue",
                                      "name": "name"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "have"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "author"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "OR.1"
                        }
                      ],
                      "kind": "ListValue",
                      "name": "OR"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "AND.1"
                }
              ],
              "kind": "ListValue",
              "name": "AND"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "ContentItemConnection",
      "kind": "LinkedField",
      "name": "__ContentItem_contentItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Content",
                  "kind": "LinkedField",
                  "name": "content",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SearchScreenContentItemView_Fragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '8dbd226622985386b491357684723df8';
export default node;
