/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ContentItemView3_Fragment = {
    readonly id: string;
    readonly objectId: string;
    readonly title: string | null;
    readonly published: boolean | null;
    readonly analytics: {
        readonly viewCount: number | null;
        readonly emojiTotalCount: number | null;
        readonly emojiCountMap: unknown | null;
    } | null;
    readonly content: {
        readonly type: string | null;
        readonly link: string | null;
        readonly thumbURL: string | null;
        readonly analytics: {
            readonly viewCount: number | null;
            readonly contentItemCount: number | null;
            readonly emojiTotalCount: number | null;
            readonly emojiCountMap: unknown | null;
            readonly contentItemAuthors: ReadonlyArray<{
                readonly id?: string;
                readonly objectId?: string;
                readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
            } | null> | null;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"ContentPreview2Fragment">;
    } | null;
    readonly author: {
        readonly objectId: string;
        readonly name: string | null;
        readonly originProfileURL: string | null;
        readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
    } | null;
    readonly noteData: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly " $refType": "ContentItemView3_Fragment";
};
export type ContentItemView3_Fragment$data = ContentItemView3_Fragment;
export type ContentItemView3_Fragment$key = {
    readonly " $data"?: ContentItemView3_Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ContentItemView3_Fragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emojiTotalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emojiCountMap",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserProfileImageFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentItemView3_Fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnalyticsContentItem",
      "kind": "LinkedField",
      "name": "analytics",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "link",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AnalyticsContent",
          "kind": "LinkedField",
          "name": "analytics",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contentItemCount",
              "storageKey": null
            },
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "contentItemAuthors",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v5/*: any*/)
                  ],
                  "type": "User",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentPreview2Fragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originProfileURL",
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "noteData",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "type": "Element",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentItem",
  "abstractKey": null
};
})();
(node as any).hash = 'd1840cd1e93aba87e92bc7143698b582';
export default node;
