/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CuratorItemViewQueryVariables = {
    userObjectId: string;
};
export type CuratorItemViewQueryResponse = {
    readonly user: {
        readonly id: string;
        readonly objectId: string;
        readonly username: string | null;
        readonly name: string | null;
        readonly jobTitle: string | null;
        readonly description: string | null;
        readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
    };
};
export type CuratorItemViewQuery = {
    readonly response: CuratorItemViewQueryResponse;
    readonly variables: CuratorItemViewQueryVariables;
};



/*
query CuratorItemViewQuery(
  $userObjectId: ID!
) {
  user(id: $userObjectId) {
    id
    objectId
    username
    name
    jobTitle
    description
    ...UserProfileImageFragment
  }
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userObjectId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userObjectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CuratorItemViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserProfileImageFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CuratorItemViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "originProfileURL",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FileInfo",
            "kind": "LinkedField",
            "name": "profileImage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a11d84a0c785e787ed8709a4c06ff6a8",
    "id": null,
    "metadata": {},
    "name": "CuratorItemViewQuery",
    "operationKind": "query",
    "text": "query CuratorItemViewQuery(\n  $userObjectId: ID!\n) {\n  user(id: $userObjectId) {\n    id\n    objectId\n    username\n    name\n    jobTitle\n    description\n    ...UserProfileImageFragment\n  }\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = 'a48df4c84df7a1c272c4e84be8bc0559';
export default node;
