/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateContentItemInput = {
    id: string;
    fields?: UpdateContentItemFieldsInput | null;
    clientMutationId?: string | null;
};
export type UpdateContentItemFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    description?: string | null;
    author?: UserPointerInput | null;
    content?: ContentPointerInput | null;
    noteData?: Array<unknown | null> | null;
    tags?: Array<unknown | null> | null;
    published?: boolean | null;
    md?: string | null;
    analytics?: AnalyticsContentItemPointerInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type ContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentFieldsInput | null;
};
export type CreateContentFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    thumbURL?: string | null;
    type?: string | null;
    link?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    rawMetadata?: unknown | null;
    analytics?: AnalyticsContentPointerInput | null;
};
export type AnalyticsContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentFieldsInput | null;
};
export type CreateAnalyticsContentFieldsInput = {
    ACL?: ACLInput | null;
    content?: ContentPointerInput | null;
    viewCount?: number | null;
    contentItemCount?: number | null;
    emojiCountMap?: unknown | null;
    contentItemAuthors?: Array<unknown | null> | null;
    emojiTotalCount?: number | null;
    commentCount?: number | null;
};
export type AnalyticsContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentItemFieldsInput | null;
};
export type CreateAnalyticsContentItemFieldsInput = {
    ACL?: ACLInput | null;
    contentItem?: ContentItemPointerInput | null;
    viewCount?: number | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    commentCount?: number | null;
};
export type ContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentItemFieldsInput | null;
};
export type CreateContentItemFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    description?: string | null;
    author?: UserPointerInput | null;
    content?: ContentPointerInput | null;
    noteData?: Array<unknown | null> | null;
    tags?: Array<unknown | null> | null;
    published?: boolean | null;
    md?: string | null;
    analytics?: AnalyticsContentItemPointerInput | null;
};
export type NotePlayerScreenContentItemTitleAndMDMutationVariables = {
    input: UpdateContentItemInput;
};
export type NotePlayerScreenContentItemTitleAndMDMutationResponse = {
    readonly updateContentItem: {
        readonly clientMutationId: string | null;
        readonly contentItem: {
            readonly id: string;
            readonly title: string | null;
            readonly author: {
                readonly id: string;
                readonly objectId: string;
            } | null;
            readonly md: string | null;
        };
    } | null;
};
export type NotePlayerScreenContentItemTitleAndMDMutation = {
    readonly response: NotePlayerScreenContentItemTitleAndMDMutationResponse;
    readonly variables: NotePlayerScreenContentItemTitleAndMDMutationVariables;
};



/*
mutation NotePlayerScreenContentItemTitleAndMDMutation(
  $input: UpdateContentItemInput!
) {
  updateContentItem(input: $input) {
    clientMutationId
    contentItem {
      id
      title
      author {
        id
        objectId
      }
      md
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateContentItemPayload",
    "kind": "LinkedField",
    "name": "updateContentItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentItem",
        "kind": "LinkedField",
        "name": "contentItem",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "md",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotePlayerScreenContentItemTitleAndMDMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotePlayerScreenContentItemTitleAndMDMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d0fec506f384898c1044f0bac9f0d445",
    "id": null,
    "metadata": {},
    "name": "NotePlayerScreenContentItemTitleAndMDMutation",
    "operationKind": "mutation",
    "text": "mutation NotePlayerScreenContentItemTitleAndMDMutation(\n  $input: UpdateContentItemInput!\n) {\n  updateContentItem(input: $input) {\n    clientMutationId\n    contentItem {\n      id\n      title\n      author {\n        id\n        objectId\n      }\n      md\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1132a510286132cf34311bb50a350e6e';
export default node;
