/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ItemSelectorScreenSearchQueryVariables = {
    searchText?: string | null;
    userObjectId?: string | null;
};
export type ItemSelectorScreenSearchQueryResponse = {
    readonly contentItems: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly __typename: string;
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly " $fragmentRefs": FragmentRefs<"ItemSelectorScreen_Fragment">;
            } | null;
        } | null> | null;
    };
};
export type ItemSelectorScreenSearchQuery = {
    readonly response: ItemSelectorScreenSearchQueryResponse;
    readonly variables: ItemSelectorScreenSearchQueryVariables;
};



/*
query ItemSelectorScreenSearchQuery(
  $searchText: String
  $userObjectId: ID
) {
  contentItems(where: {AND: [{author: {have: {objectId: {equalTo: $userObjectId}}}}, {title: {matchesRegex: $searchText}}]}, first: 1000, order: [createdAt_DESC]) {
    edges {
      node {
        __typename
        id
        objectId
        title
        ...ItemSelectorScreen_Fragment
      }
    }
  }
}

fragment ItemSelectorScreen_Fragment on ContentItem {
  id
  objectId
  title
  description
  content {
    id
    objectId
    type
    title
    description
    thumbURL
    link
  }
  author {
    objectId
    name
    id
  }
  noteData {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  tags {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchText"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userObjectId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": [
      "createdAt_DESC"
    ]
  },
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "equalTo",
                            "variableName": "userObjectId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "objectId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "have"
                  }
                ],
                "kind": "ObjectValue",
                "name": "author"
              }
            ],
            "kind": "ObjectValue",
            "name": "AND.0"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "matchesRegex",
                    "variableName": "searchText"
                  }
                ],
                "kind": "ObjectValue",
                "name": "title"
              }
            ],
            "kind": "ObjectValue",
            "name": "AND.1"
          }
        ],
        "kind": "ListValue",
        "name": "AND"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "type": "Element",
    "abstractKey": null
  },
  {
    "kind": "InlineFragment",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ItemSelectorScreenSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContentItemConnection",
        "kind": "LinkedField",
        "name": "contentItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ItemSelectorScreen_Fragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ItemSelectorScreenSearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContentItemConnection",
        "kind": "LinkedField",
        "name": "contentItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Content",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbURL",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "noteData",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "tags",
                    "plural": true,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb16d90f5064f81592328199bb04d5da",
    "id": null,
    "metadata": {},
    "name": "ItemSelectorScreenSearchQuery",
    "operationKind": "query",
    "text": "query ItemSelectorScreenSearchQuery(\n  $searchText: String\n  $userObjectId: ID\n) {\n  contentItems(where: {AND: [{author: {have: {objectId: {equalTo: $userObjectId}}}}, {title: {matchesRegex: $searchText}}]}, first: 1000, order: [createdAt_DESC]) {\n    edges {\n      node {\n        __typename\n        id\n        objectId\n        title\n        ...ItemSelectorScreen_Fragment\n      }\n    }\n  }\n}\n\nfragment ItemSelectorScreen_Fragment on ContentItem {\n  id\n  objectId\n  title\n  description\n  content {\n    id\n    objectId\n    type\n    title\n    description\n    thumbURL\n    link\n  }\n  author {\n    objectId\n    name\n    id\n  }\n  noteData {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  tags {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '035acda007de6cfe27716866c2951d3a';
export default node;
