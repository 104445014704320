/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type useLazyLoadLearningInfoOfPathQueryVariables = {
    pathIds?: Array<string | null> | null;
    contentItemIds?: Array<string | null> | null;
    userObjectId?: string | null;
    isNotLogin: boolean;
};
export type useLazyLoadLearningInfoOfPathQueryResponse = {
    readonly allLHistory?: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly status: string | null;
                readonly path: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
                readonly contentItem: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
                readonly quiz: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type useLazyLoadLearningInfoOfPathQuery = {
    readonly response: useLazyLoadLearningInfoOfPathQueryResponse;
    readonly variables: useLazyLoadLearningInfoOfPathQueryVariables;
};



/*
query useLazyLoadLearningInfoOfPathQuery(
  $pathIds: [ID]
  $contentItemIds: [ID]
  $userObjectId: ID
  $isNotLogin: Boolean!
) {
  allLHistory: learningHistories(first: 1000, where: {OR: [{path: {have: {objectId: {in: $pathIds}}}}, {contentItem: {have: {objectId: {in: $contentItemIds}}}}, {quiz: {exists: true}}], author: {have: {objectId: {equalTo: $userObjectId}}}}) @skip(if: $isNotLogin) {
    edges {
      node {
        id
        objectId
        status
        path {
          id
          objectId
        }
        contentItem {
          id
          objectId
        }
        quiz {
          id
          objectId
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentItemIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNotLogin"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userObjectId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  {
    "condition": "isNotLogin",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": "allLHistory",
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          },
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "in",
                                    "variableName": "pathIds"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "objectId"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "have"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "path"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "OR.0"
                  },
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "in",
                                    "variableName": "contentItemIds"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "objectId"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "have"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "contentItem"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "OR.1"
                  },
                  {
                    "kind": "Literal",
                    "name": "OR.2",
                    "value": {
                      "quiz": {
                        "exists": true
                      }
                    }
                  }
                ],
                "kind": "ListValue",
                "name": "OR"
              },
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "equalTo",
                            "variableName": "userObjectId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "objectId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "have"
                  }
                ],
                "kind": "ObjectValue",
                "name": "author"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "LearningHistoryConnection",
        "kind": "LinkedField",
        "name": "learningHistories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearningHistoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearningHistory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Path",
                    "kind": "LinkedField",
                    "name": "path",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentItem",
                    "kind": "LinkedField",
                    "name": "contentItem",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Quiz",
                    "kind": "LinkedField",
                    "name": "quiz",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLazyLoadLearningInfoOfPathQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useLazyLoadLearningInfoOfPathQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "a01fd89475f28667ebc9551886280859",
    "id": null,
    "metadata": {},
    "name": "useLazyLoadLearningInfoOfPathQuery",
    "operationKind": "query",
    "text": "query useLazyLoadLearningInfoOfPathQuery(\n  $pathIds: [ID]\n  $contentItemIds: [ID]\n  $userObjectId: ID\n  $isNotLogin: Boolean!\n) {\n  allLHistory: learningHistories(first: 1000, where: {OR: [{path: {have: {objectId: {in: $pathIds}}}}, {contentItem: {have: {objectId: {in: $contentItemIds}}}}, {quiz: {exists: true}}], author: {have: {objectId: {equalTo: $userObjectId}}}}) @skip(if: $isNotLogin) {\n    edges {\n      node {\n        id\n        objectId\n        status\n        path {\n          id\n          objectId\n        }\n        contentItem {\n          id\n          objectId\n        }\n        quiz {\n          id\n          objectId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5f2a45ff6ed1d341e9b7a5b0c6d7480a';
export default node;
