import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { Flex } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import LFText from '../../components/typo/LFText';
import _ from 'lodash';
import { CheckBox } from '../../components/CheckBox';
import LFLink from '../../components/LFLink';
import { colors } from '../../constants/styleGuide';
import MultilineGrowingTextInput from '../../components/MultilineGrowingTextInput';

interface QuizProp {
  title: string;
  options: string[];
  correctIndexes: number[];
  onChange: (quiz: {
    title?: string;
    options?: string[];
    correctIndexes?: number[];
  }) => void;
  style?: ViewStyle;
}

const Quiz: React.FC<QuizProp> = ({
  title,
  options,
  correctIndexes,
  onChange,
  style,
}) => {
  const quizInfo = _.map(options, (option, index) => ({
    id: index,
    option,
    checked: correctIndexes.includes(index),
  }));

  const isOptsMoreThanTwo = quizInfo.length >= 3;
  return (
    <View style={style}>
      <Flex direction="row" justify="between">
        <MultilineGrowingTextInput
          value={title || ''}
          onChangeText={(text) => {
            onChange({ title: text });
          }}
          style={{ flex: 1 }}
          placeholder="퀴즈 질문 입력"
        />
      </Flex>
      <LFWhiteSpace size="md" />

      <LFText style={{ fontWeight: 'bold' }}>정답</LFText>
      {_.map(quizInfo, (quizObj, index, quizObjs) => {
        const last = index === quizObjs.length - 1;
        const quizCorrectArr = quizObjs.filter((qo) => qo.checked === true);
        return (
          <React.Fragment key={index}>
            <LFWhiteSpace size={'xs'} direction="column" />
            <Flex>
              <CheckBox
                checked={quizObj.checked}
                onPress={(checked) => {
                  // false면 uncheck > check라는 뜻
                  console.log({ checked });
                  const newCorrects = quizObjs
                    .map((obj) =>
                      obj.id === index
                        ? {
                            ...obj,
                            checked:
                              checked === true && quizCorrectArr.length === 1
                                ? checked
                                : !checked,
                          }
                        : obj
                    )
                    .filter((obj) => obj.checked)
                    .map((row) => row.id);

                  onChange({ correctIndexes: newCorrects });
                }}
                style={{
                  marginLeft: 5,
                  marginRight: 12,
                }}
              />

              <TextInput
                value={quizObj.option}
                onChangeText={(text) => {
                  const newQuizOptions = _.map(quizObjs, (obj) => {
                    return obj.id === index ? text : obj.option;
                  });

                  onChange({ options: newQuizOptions });
                }}
                style={[
                  {
                    flex: 1,
                    borderWidth: 1,
                    borderColor: 'black',
                    padding: 4,
                  },
                  isOptsMoreThanTwo ? { paddingRight: 24 } : null,
                ]}
                placeholder="보기 입력"
              />

              {isOptsMoreThanTwo && (
                <LFLink
                  onPress={() => {
                    const removedQuizInfo = quizObjs.filter(
                      (obj) => obj.id !== index
                    );
                    const newQuizOptions = removedQuizInfo.map(
                      (obj) => obj.option
                    );
                    const newQuizCorrects = removedQuizInfo
                      .map((obj, index) => (obj.checked ? index : null))
                      .filter((item) => typeof item === 'number');

                    const isLastCheckedOption =
                      quizCorrectArr.length === 1 && quizObj.checked;

                    isLastCheckedOption
                      ? alert('정답은 최소 1개 이상 선택 되어 있어야 합니다.')
                      : onChange({
                          options: newQuizOptions,
                          correctIndexes: newQuizCorrects as number[],
                        });
                  }}
                  style={{ position: 'absolute', right: 34 }}
                >
                  <Ionicons name="trash-outline" size={20} color="black" />
                </LFLink>
              )}
              <LFWhiteSpace size={'sm'} direction="row" />

              {last && options.length !== 5 ? (
                <LFLink
                  style={styles.quizAddButton}
                  onPress={() => {
                    onChange({ options: [...options, ''] });
                  }}
                >
                  <Ionicons name="add" size={20} color="black" />
                </LFLink>
              ) : (
                <LFWhiteSpace direction="row" size="xl" />
              )}
            </Flex>
          </React.Fragment>
        );
      })}
    </View>
  );
};

export default Quiz;

const styles = StyleSheet.create({
  quizAddButton: {
    width: 24,
    height: 24,
    backgroundColor: colors.BG_4,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
