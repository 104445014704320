import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import LFText from './typo/LFText';

interface HashItemProps {
  onPressClose?: (title: string) => void;
  title: string;
  style?: ViewStyle;
  titleStyle?: TextStyle;
}
export const HashItem: React.FC<HashItemProps> = ({
  style,
  titleStyle,
  title,
  onPressClose,
}) => {
  return (
    <View style={[styles.hashContainer, style]}>
      <View style={{ paddingLeft: 2 }}></View>
      <LFText numberOfLines={1} style={[styles.hashName, titleStyle]}>
        {title}
      </LFText>
      {onPressClose && (
        <TouchableOpacity
          onPress={() => onPressClose(title)}
          style={{ padding: 4, marginRight: -4 }}
        >
          <Ionicons name="close" size={11} color="black" />
        </TouchableOpacity>
      )}
      <View style={{ paddingRight: 2 }}></View>
    </View>
  );
};

interface HashListProp {
  wrapperStyle?: ViewStyle;
}

export const HashList: React.FC<HashListProp> = ({
  wrapperStyle,
  children,
}) => <View style={[styles.hashList, wrapperStyle]}>{children}</View>;

const styles = StyleSheet.create({
  hashList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin: -3,
  },
  hashContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
    margin: 3,
    maxWidth: '100%',
    backgroundColor: 'rgb(240, 241, 243)',
  },
  hashName: {
    fontSize: 11,
  },
});
