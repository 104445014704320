import { ViewStyle } from 'react-native';
import React from 'react';
import Selector, { TextColor, TextScale } from '../../components/Selector';

type SortingKeys = ['최신순', '조회순', '이모지순'];
export type SortingKey = '최신순' | '조회순' | '이모지순';

interface SortingKeySelectorProp {
  currentOption: SortingKey;
  style?: ViewStyle;
  onSelect: (option: SortingKey) => void;
  textScale?: TextScale;
  textColor?: TextColor;
}
const SortingKeySelector: React.FC<SortingKeySelectorProp> = ({
  currentOption,
  onSelect,
  style,
  textScale,
  textColor,
}) => {
  const options: SortingKeys = ['최신순', '조회순', '이모지순'];
  return (
    <Selector
      style={style}
      options={options}
      currentOption={currentOption}
      onSelect={onSelect}
      textScale={textScale}
      textColor={textColor}
    />
  );
};

export default SortingKeySelector;
