import { Flex, WhiteSpace } from '@ant-design/react-native';
import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import LFText, { LFBoldText } from './typo/LFText';
import LinearGradient from 'react-native-linear-gradient';
import LFLink from './LFLink';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { MembershipPaymentGuideButton_Fragment$key } from '../__generated__/MembershipPaymentGuideButton_Fragment.graphql';
import {
  MembershipPaymentGuideButtonQuery,
  MembershipPricingOrder,
} from '../__generated__/MembershipPaymentGuideButtonQuery.graphql';
import { MembershipPricingWhereInput } from '../__generated__/MembershipManagementScreenQuery.graphql';
import { first } from 'lodash';

const MembershipPaymentGuideButton: React.FC<{
  fragmentRef: MembershipPaymentGuideButton_Fragment$key | null;
  style?: ViewStyle;
  onPress: () => void;
}> = ({ style, onPress, fragmentRef }) => {
  const membership = useFragment(
    graphql`
      fragment MembershipPaymentGuideButton_Fragment on Membership {
        id
        objectId
        author {
          id
          objectId
          name
        }
      }
    `,
    fragmentRef
  );

  const membershipPricingWhere: MembershipPricingWhereInput = {
    membership: {
      have: {
        objectId: {
          equalTo: membership?.objectId,
        },
      },
    },
  };

  const membershipPricingOrder: MembershipPricingOrder[] = ['price_ASC'];

  const { membershipPricings } =
    useLazyLoadQuery<MembershipPaymentGuideButtonQuery>(
      graphql`
        query MembershipPaymentGuideButtonQuery(
          $where: MembershipPricingWhereInput!
          $order: [MembershipPricingOrder!]
        ) {
          membershipPricings(where: $where, order: $order) {
            edges {
              node {
                id
                objectId
                price
                duration
              }
            }
          }
        }
      `,
      { where: membershipPricingWhere, order: membershipPricingOrder }
    );

  const lowestMembershipPricing = first(membershipPricings.edges)?.node;

  return (
    <Flex
      direction="column"
      align="center"
      style={[
        {
          backgroundColor: 'white',
          paddingHorizontal: 16,
          position: 'relative',
        },
        style,
      ]}
    >
      <LinearGradient
        colors={['rgba(255,255,255,0), rgba(255,255,255,1)']}
        style={{
          height: 100,
          position: 'absolute',
          top: -100,
          left: 0,
          right: 0,
        }}
      />
      <View style={{ height: 25, backgroundColor: 'white' }}></View>
      <LFText
        style={{
          fontWeight: '600',
          fontSize: 18,
          lineHeight: 27,
          color: '#1A1B1E',
        }}
      >{`${
        membership?.author?.name || ''
      }님의 멤버십 전용 콘텐츠에요.`}</LFText>
      <WhiteSpace size="xs" />
      <LFText
        style={{
          fontSize: 14,
          lineHeight: 24,
          color: 'rgba(26, 27, 30, 0.6)',
        }}
      >
        지금 가입하고 큐레이터의 꿀팁을 확인하세요!
      </LFText>
      <WhiteSpace size="lg" />
      <LFLink
        testID="goto-membership-plan:MembershipPaymentGuideButton"
        onPress={onPress}
        style={{
          backgroundColor: '#236EFF',
          borderRadius: 8,
          paddingVertical: 16,
          alignSelf: 'stretch',
          alignItems: 'center',
        }}
      >
        <LFText
          style={{
            fontSize: 16,
            lineHeight: 24,
            color: '#FFFFFF',
          }}
        >
          {`${lowestMembershipPricing?.duration || 0}일 이용 멤버십 `}
          <LFBoldText>
            {`${(lowestMembershipPricing?.price || 0).toLocaleString()}원`}
          </LFBoldText>
        </LFText>
      </LFLink>
      <WhiteSpace size="lg" />
    </Flex>
  );
};

export default MembershipPaymentGuideButton;

const styles = StyleSheet.create({});
