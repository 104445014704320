/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SearchScreenPathItemView_Fragment = {
    readonly id: string;
    readonly title: string | null;
    readonly contentItemList: ReadonlyArray<{
        readonly content?: {
            readonly thumbURL: string | null;
        } | null;
    } | null> | null;
    readonly author: {
        readonly objectId: string;
        readonly name: string | null;
        readonly originProfileURL: string | null;
        readonly jobTitle: string | null;
        readonly profileImage: {
            readonly url: string;
        } | null;
    } | null;
    readonly tags: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly instructionCards: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly description: string | null;
                readonly sources: ReadonlyArray<({
                    readonly __typename: "ContentItem";
                    readonly id: string;
                    readonly objectId: string;
                    readonly title: string | null;
                    readonly content: {
                        readonly id: string;
                        readonly title: string | null;
                        readonly objectId: string;
                        readonly thumbURL: string | null;
                        readonly type: string | null;
                    } | null;
                    readonly author: {
                        readonly objectId: string;
                        readonly name: string | null;
                    } | null;
                } | {
                    readonly __typename: "Content";
                    readonly id: string;
                    readonly objectId: string;
                    readonly thumbURL: string | null;
                    readonly title: string | null;
                    readonly link: string | null;
                    readonly type: string | null;
                } | {
                    readonly __typename: "Path";
                    readonly id: string;
                    readonly objectId: string;
                    readonly title: string | null;
                    readonly description: string | null;
                    readonly author: {
                        readonly objectId: string;
                        readonly name: string | null;
                    } | null;
                } | {
                    /*This will never be '%other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                }) | null> | null;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "SearchScreenPathItemView_Fragment";
};
export type SearchScreenPathItemView_Fragment$data = SearchScreenPathItemView_Fragment;
export type SearchScreenPathItemView_Fragment$key = {
    readonly " $data"?: SearchScreenPathItemView_Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"SearchScreenPathItemView_Fragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbURL",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchScreenPathItemView_Fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "contentItemList",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Content",
              "kind": "LinkedField",
              "name": "content",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "ContentItem",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originProfileURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FileInfo",
          "kind": "LinkedField",
          "name": "profileImage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "type": "Element",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": "updatedAt_DESC"
        }
      ],
      "concreteType": "InstructionCardConnection",
      "kind": "LinkedField",
      "name": "instructionCards",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InstructionCardEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InstructionCard",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "sources",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Content",
                          "kind": "LinkedField",
                          "name": "content",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v3/*: any*/),
                            (v2/*: any*/),
                            (v6/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v7/*: any*/)
                      ],
                      "type": "ContentItem",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        (v2/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "link",
                          "storageKey": null
                        },
                        (v6/*: any*/)
                      ],
                      "type": "Content",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        (v1/*: any*/),
                        (v5/*: any*/),
                        (v7/*: any*/)
                      ],
                      "type": "Path",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "instructionCards(order:\"updatedAt_DESC\")"
    }
  ],
  "type": "Path",
  "abstractKey": null
};
})();
(node as any).hash = '04768036e70e821c6ebba37ed929d132';
export default node;
