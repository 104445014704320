import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import NavBar from '../components/NavBar2';
import { Flex } from '@ant-design/react-native';
import SectionTitle from '../components/SectionTitle';
import LFText from '../components/typo/LFText';
import LFButton from '../components/LFButton';
import LFWhiteSpace from '../components/LFWhiteSpace';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { MembershipManagementScreenQuery } from '../__generated__/MembershipManagementScreenQuery.graphql';
import { useAuth } from '../hooks/auth';
import _ from 'lodash';
import MembershipStatusCard from './MembershipStatusScreen/MembershipStatusCard';
import MembershipPricingCard from '../components/MembershipPricingCard';
import LFLink from '../components/LFLink';

const MembershipManagementScreen = () => {
  const { user: loginUser } = useAuth();
  const { membershipPricings, membershipStatuses } =
    useLazyLoadQuery<MembershipManagementScreenQuery>(
      graphql`
        query MembershipManagementScreenQuery(
          $pricingWhere: MembershipPricingWhereInput
          $statusWhere: MembershipStatusWhereInput
        ) {
          membershipPricings(where: $pricingWhere, order: price_ASC) {
            edges {
              node {
                id
                objectId
                price
                duration

                membership {
                  id
                  objectId
                  title
                  level
                }
                ...MembershipPricingCardFragment
              }
            }
          }
          membershipStatuses(where: $statusWhere, order: updatedAt_DESC) {
            edges {
              node {
                id
                objectId
                membership {
                  author {
                    id
                    objectId
                  }
                }
                ...MembershipStatusCardFragment
              }
            }
          }
        }
      `,
      {
        pricingWhere: {
          author: { have: { objectId: { equalTo: loginUser?.objectId } } },
        },
        statusWhere: {
          member: {
            have: { objectId: { equalTo: loginUser?.objectId } },
          },
        },
      }
    );

  return (
    <View>
      <NavBar title={'멤버십 관리'} sticky />
      <Flex direction="column" style={styles.container} align="stretch">
        <SectionTitle title="내 멤버십 가격 정책" />
        <LFWhiteSpace />
        {membershipPricings.edges?.length ? (
          _.map(membershipPricings.edges, (e) => {
            return (
              <>
                <MembershipPricingCard
                  fragment={e?.node || null}
                  key={e?.node?.id}
                />
                {_.last(membershipPricings.edges) !== e ? (
                  <LFWhiteSpace />
                ) : null}
              </>
            );
          })
        ) : (
          <Flex align="stretch" direction="column">
            <LFText style={{ alignSelf: 'center' }}>
              나의 유료 멤버십 플랜을 만들고,{'\n'}가입자를 받아 수익을 창출할
              수 있어요.
            </LFText>
            <LFWhiteSpace />
            <LFButton onPress={() => {}} disabled>
              서비스 준비중 입니다.
            </LFButton>
          </Flex>
        )}
        <LFWhiteSpace size="xxl" />
        <SectionTitle title="가입한 멤버십" />
        <LFWhiteSpace />
        {membershipStatuses.edges?.length ? (
          _.map(membershipStatuses.edges, (e) => {
            return (
              <LFLink
                key={e?.node?.id}
                to={`/u/${e?.node?.membership?.author?.objectId}/membership/status`}
              >
                <MembershipStatusCard membershipStatusFrgmt={e?.node || null} />
                {_.last(membershipStatuses.edges) !== e ? (
                  <LFWhiteSpace />
                ) : null}
              </LFLink>
            );
          })
        ) : (
          <Flex justify="center">
            <LFText>
              가입한 멤버십이 없어요.{'\n'}지금 가입하고 큐레이터의 꿀팁을
              확인하세요!
            </LFText>
          </Flex>
        )}
      </Flex>
    </View>
  );
};

export default MembershipManagementScreen;

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
});
