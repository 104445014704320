/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type RecommendedContentsRelatedWithPathsCreatedByThisAuthorQueryVariables = {
    userObjectId: string;
    pathObjectId: string;
    skip: boolean;
};
export type RecommendedContentsRelatedWithPathsCreatedByThisAuthorQueryResponse = {
    readonly pathsCreatedByThisAuthor?: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly membership: {
                    readonly id: string;
                    readonly objectId: string;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery = {
    readonly response: RecommendedContentsRelatedWithPathsCreatedByThisAuthorQueryResponse;
    readonly variables: RecommendedContentsRelatedWithPathsCreatedByThisAuthorQueryVariables;
};



/*
query RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery(
  $userObjectId: ID!
  $pathObjectId: ID!
  $skip: Boolean!
) {
  pathsCreatedByThisAuthor: paths(where: {author: {have: {objectId: {equalTo: $userObjectId}}}, published: {equalTo: true}, objectId: {notEqualTo: $pathObjectId}}) @skip(if: $skip) {
    count
    edges {
      node {
        id
        objectId
        membership {
          id
          objectId
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathObjectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userObjectId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v5 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": "pathsCreatedByThisAuthor",
        "args": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "equalTo",
                            "variableName": "userObjectId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "objectId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "have"
                  }
                ],
                "kind": "ObjectValue",
                "name": "author"
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "notEqualTo",
                    "variableName": "pathObjectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "objectId"
              },
              {
                "kind": "Literal",
                "name": "published",
                "value": {
                  "equalTo": true
                }
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "PathConnection",
        "kind": "LinkedField",
        "name": "paths",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PathEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Path",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Membership",
                    "kind": "LinkedField",
                    "name": "membership",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "6377b89dff8d90a158d024fec4eca9d3",
    "id": null,
    "metadata": {},
    "name": "RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery",
    "operationKind": "query",
    "text": "query RecommendedContentsRelatedWithPathsCreatedByThisAuthorQuery(\n  $userObjectId: ID!\n  $pathObjectId: ID!\n  $skip: Boolean!\n) {\n  pathsCreatedByThisAuthor: paths(where: {author: {have: {objectId: {equalTo: $userObjectId}}}, published: {equalTo: true}, objectId: {notEqualTo: $pathObjectId}}) @skip(if: $skip) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        membership {\n          id\n          objectId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0e8e4d7c1431b9df25842cc047ffb243';
export default node;
