/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnalyticsContentItemOrder = "ACL_ASC" | "ACL_DESC" | "commentCount_ASC" | "commentCount_DESC" | "contentItem_ASC" | "contentItem_DESC" | "createdAt_ASC" | "createdAt_DESC" | "emojiCountMap_ASC" | "emojiCountMap_DESC" | "emojiTotalCount_ASC" | "emojiTotalCount_DESC" | "id_ASC" | "id_DESC" | "objectId_ASC" | "objectId_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "viewCount_ASC" | "viewCount_DESC" | "%future added value";
export type AnalyticsContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    contentItem?: ContentItemRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentItemWhereInput> | null;
    AND?: Array<AnalyticsContentItemWhereInput> | null;
    NOR?: Array<AnalyticsContentItemWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type ContentItemRelationWhereInput = {
    have?: ContentItemWhereInput | null;
    haveNot?: ContentItemWhereInput | null;
    exists?: boolean | null;
};
export type ContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    content?: ContentRelationWhereInput | null;
    noteData?: ArrayWhereInput | null;
    tags?: ArrayWhereInput | null;
    published?: BooleanWhereInput | null;
    md?: StringWhereInput | null;
    analytics?: AnalyticsContentItemRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentItemWhereInput> | null;
    AND?: Array<ContentItemWhereInput> | null;
    NOR?: Array<ContentItemWhereInput> | null;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type UserRelationWhereInput = {
    have?: UserWhereInput | null;
    haveNot?: UserWhereInput | null;
    exists?: boolean | null;
};
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ContentRelationWhereInput = {
    have?: ContentWhereInput | null;
    haveNot?: ContentWhereInput | null;
    exists?: boolean | null;
};
export type ContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    thumbURL?: StringWhereInput | null;
    type?: StringWhereInput | null;
    link?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    rawMetadata?: ObjectWhereInput | null;
    analytics?: AnalyticsContentRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentWhereInput> | null;
    AND?: Array<ContentWhereInput> | null;
    NOR?: Array<ContentWhereInput> | null;
};
export type AnalyticsContentRelationWhereInput = {
    have?: AnalyticsContentWhereInput | null;
    haveNot?: AnalyticsContentWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    content?: ContentRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    contentItemCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    contentItemAuthors?: ArrayWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentWhereInput> | null;
    AND?: Array<AnalyticsContentWhereInput> | null;
    NOR?: Array<AnalyticsContentWhereInput> | null;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type AnalyticsContentItemRelationWhereInput = {
    have?: AnalyticsContentItemWhereInput | null;
    haveNot?: AnalyticsContentItemWhereInput | null;
    exists?: boolean | null;
};
export type BaseHorizontalContentItemListQueryVariables = {
    where?: AnalyticsContentItemWhereInput | null;
    order?: Array<AnalyticsContentItemOrder> | null;
    first?: number | null;
};
export type BaseHorizontalContentItemListQueryResponse = {
    readonly analyticsContentItems: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly contentItem: {
                    readonly id: string;
                    readonly objectId: string;
                    readonly content: {
                        readonly id: string;
                        readonly objectId: string;
                    } | null;
                    readonly " $fragmentRefs": FragmentRefs<"ContentItemView3_Fragment">;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type BaseHorizontalContentItemListQuery = {
    readonly response: BaseHorizontalContentItemListQueryResponse;
    readonly variables: BaseHorizontalContentItemListQueryVariables;
};



/*
query BaseHorizontalContentItemListQuery(
  $where: AnalyticsContentItemWhereInput
  $order: [AnalyticsContentItemOrder!]
  $first: Int
) {
  analyticsContentItems(where: $where, order: $order, first: $first) {
    count
    edges {
      node {
        id
        objectId
        contentItem {
          id
          objectId
          content {
            id
            objectId
          }
          ...ContentItemView3_Fragment
        }
      }
    }
  }
}

fragment ContentItemView3_Fragment on ContentItem {
  id
  objectId
  title
  published
  analytics {
    viewCount
    emojiTotalCount
    emojiCountMap
    id
  }
  content {
    type
    link
    thumbURL
    ...ContentPreview2Fragment
    analytics {
      viewCount
      contentItemCount
      emojiTotalCount
      emojiCountMap
      contentItemAuthors {
        __typename
        ... on User {
          id
          objectId
          ...UserProfileImageFragment
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
      id
    }
    id
  }
  author {
    objectId
    name
    originProfileURL
    ...UserProfileImageFragment
    id
  }
  noteData {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment ContentPreview2Fragment on Content {
  __typename
  id
  objectId
  type
  thumbURL
  title
  createdAt
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v3 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emojiTotalCount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emojiCountMap",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "originProfileURL",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "profileImage",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseHorizontalContentItemListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AnalyticsContentItemConnection",
        "kind": "LinkedField",
        "name": "analyticsContentItems",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalyticsContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalyticsContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentItem",
                    "kind": "LinkedField",
                    "name": "contentItem",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Content",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ContentItemView3_Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BaseHorizontalContentItemListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AnalyticsContentItemConnection",
        "kind": "LinkedField",
        "name": "analyticsContentItems",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalyticsContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalyticsContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContentItem",
                    "kind": "LinkedField",
                    "name": "contentItem",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Content",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "link",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbURL",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AnalyticsContent",
                            "kind": "LinkedField",
                            "name": "analytics",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contentItemCount",
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "contentItemAuthors",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v5/*: any*/),
                                      (v6/*: any*/),
                                      (v12/*: any*/),
                                      (v13/*: any*/),
                                      (v14/*: any*/)
                                    ],
                                    "type": "User",
                                    "abstractKey": null
                                  },
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "published",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnalyticsContentItem",
                        "kind": "LinkedField",
                        "name": "analytics",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v14/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "noteData",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              }
                            ],
                            "type": "Element",
                            "abstractKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "55ea661015b11412344f5f03c2542b41",
    "id": null,
    "metadata": {},
    "name": "BaseHorizontalContentItemListQuery",
    "operationKind": "query",
    "text": "query BaseHorizontalContentItemListQuery(\n  $where: AnalyticsContentItemWhereInput\n  $order: [AnalyticsContentItemOrder!]\n  $first: Int\n) {\n  analyticsContentItems(where: $where, order: $order, first: $first) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        contentItem {\n          id\n          objectId\n          content {\n            id\n            objectId\n          }\n          ...ContentItemView3_Fragment\n        }\n      }\n    }\n  }\n}\n\nfragment ContentItemView3_Fragment on ContentItem {\n  id\n  objectId\n  title\n  published\n  analytics {\n    viewCount\n    emojiTotalCount\n    emojiCountMap\n    id\n  }\n  content {\n    type\n    link\n    thumbURL\n    ...ContentPreview2Fragment\n    analytics {\n      viewCount\n      contentItemCount\n      emojiTotalCount\n      emojiCountMap\n      contentItemAuthors {\n        __typename\n        ... on User {\n          id\n          objectId\n          ...UserProfileImageFragment\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n  author {\n    objectId\n    name\n    originProfileURL\n    ...UserProfileImageFragment\n    id\n  }\n  noteData {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment ContentPreview2Fragment on Content {\n  __typename\n  id\n  objectId\n  type\n  thumbURL\n  title\n  createdAt\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = '16cccac306d28e558c62b165bdfb9b58';
export default node;
