/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TraversalScreenPathDeleteMutationVariables = {
    id: string;
};
export type TraversalScreenPathDeleteMutationResponse = {
    readonly deletePath: {
        readonly path: {
            readonly id: string;
            readonly objectId: string;
            readonly title: string | null;
            readonly instructionCards: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly objectId: string;
                    } | null;
                } | null> | null;
            };
        };
    } | null;
};
export type TraversalScreenPathDeleteMutation = {
    readonly response: TraversalScreenPathDeleteMutationResponse;
    readonly variables: TraversalScreenPathDeleteMutationVariables;
};



/*
mutation TraversalScreenPathDeleteMutation(
  $id: ID!
) {
  deletePath(input: {id: $id}) {
    path {
      id
      objectId
      title
      instructionCards {
        edges {
          node {
            id
            objectId
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeletePathPayload",
    "kind": "LinkedField",
    "name": "deletePath",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Path",
        "kind": "LinkedField",
        "name": "path",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructionCardConnection",
            "kind": "LinkedField",
            "name": "instructionCards",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "InstructionCardEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructionCard",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TraversalScreenPathDeleteMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TraversalScreenPathDeleteMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "8fb4adb1a3b10833368752e4b9ac17f6",
    "id": null,
    "metadata": {},
    "name": "TraversalScreenPathDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TraversalScreenPathDeleteMutation(\n  $id: ID!\n) {\n  deletePath(input: {id: $id}) {\n    path {\n      id\n      objectId\n      title\n      instructionCards {\n        edges {\n          node {\n            id\n            objectId\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5d552222f41c76f9f40340037b799abc';
export default node;
