import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import LFText from './typo/LFText';

const MembershipPrivacyPolicy = () => {
  return (
    <LFText>{`주식회사 라이브데이터(이하 ‘회사’)은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 보호정책을 두고 있습니다.

    1. 수집하는 개인정보의 항목 및 수집방법
    1. 수집하는 개인정보의 항목
    회사는 회원가입, 원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 최소한의 개인정보를 필수항목으로 수집하고 있습니다.필수항목 : 이메일, 비밀번호, 이름(또는 별명)
    
    회사는 다른 소셜 서비스 계정을 이용해 회원가입하는 경우 아래와 같은 개인정보를 필수항목으로 추가 수집하고 있습니다.
    
    서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
    
    IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 접속 기기 정보
    
    결제 예약 및 결제 시 아래의 정보가 수집됩니다.
    
    결제일시, 결제금액, 통화, 이름, 이메일, 연락처, 신용카드 정보(카드 번호, 유효기간, 생년월일, 사업자등록번호, 비밀번호 앞 두 자리)
    
    결제 취소 또는 환불 시 아래의 정보가 수집됩니다.
    
    계좌 정보, 증빙 서류(결제 명세서 사본)
    
    큐레이터 수익금 정산시 부가가치세법에 따라 아래의 필수 정보가 수집됩니다.
    
    개인인 경우 : 주민등록번호, 이름, 계좌 정보, 증빙 서류(신분증 사본, 통장 사본)
    
    사업자인 경우 : 사업자등록번호, 사업자명, 대표자명, 과세 형태, 계좌 정보, 증빙 서류(사업자등록증 사본, 통장 사본)
    
    고객상담 문의 등록 시 아래의 정보가 수집됩니다.
    
    이메일 주소, 런핏 로그인 이메일, 기기 이용 환경 및 접속 위치
    
    2. 개인정보 수집방법
    회사는 다음과 같은 방법으로 개인정보를 수집합니다.
    
    홈페이지, 상담 게시판, 이메일, 생성정보 수집 툴을 통한 수집
    
    수집한 개인정보의 일부를 삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼 수 없도록 처리
    
    2. 개인정보의 수집 및 이용목적
    회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.
    
    1. 홈페이지 회원가입 및 관리
    회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
    
    2. 재화 또는 서비스 제공
    서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 정산 등을 목적으로 개인정보를 처리합니다.
    
    3. 통계 작성 및 연구 등
    서비스 및 콘텐츠 관련 통계 작성(상업적 목적 포함) 및 연구(산업적 연구 포함) 등을 목적으로 개인정보를 가명처리해 이용합니다.
    
    3. 개인정보의 보유 및 이용기간
    이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 보존 이유를 위해 명시한 기간 동안 보존합니다.
    
    1. 회사 내부 방침에 의한 정보보유 사유
    부정이용기록(부정가입, 징계기록 등의 비정상적 서비스 이용기록)
    
    보존 항목 : IP Address, 접속일시, 접속 기기 정보
    
    보유기간 : 5년
    
    2. 관련법령에 의한 정보보유 사유
    상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며, 주요 보관 정보, 보관 목적 및 보존기간은 아래와 같습니다.
    
    계약 또는 청약철회 등에 관한 기록
    
    보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
    
    보유기간 : 5년
    
    대금결제 및 재화 등의 공급에 관한 기록
    
    보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
    
    보유기간 : 5년
    
    소비자의 불만 또는 분쟁처리에 관한 기록
    
    보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
    
    보유기간 : 3년
    
    웹사이트 방문기록
    
    보유근거 : 통신비밀보호법
    
    보유기간 : 3개월
    
    영수증 등 각 세법에서 규정하는 납세자의 모든 거래에 관한 장부 및 증거서류
    
    보유근거 : 국세기본법
    
    보유기간 : 5년
    
    전자금융 거래에 관한 기록
    
    보유근거 : 전자금융거래법
    
    보유기간 : 5년
    
    표시∙광고에 관한 기록
    
    보유근거 : 전자상거래 등에서의 소비자보호에 관한 법률
    
    보유기간 : 6개월
    
    4. 개인정보의 3자 제공
    회사는 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다. 단, 이용자가 서비스를 이용하기 위하여 개인정보 제공에 직접 동의한 경우, 관계 법령에 의거해 회사에 개인정보 제출 의무가 발생한 경우, 이용자 또는 제3자의 생명이나 안전에 급박한 위험이 확인되어 이를 해소하기 위한 경우, 통계작성(상업적 목적 포함), 연구(산업적 연구 포함), 공익적 기록보존 목적 등을 위해 가명처리하여 제공하는 경우에 한하여 개인정보를 제공하고 있습니다.
    
    이용자 동의 후 개인정보 제공이 발생하는 경우
    회사의 중개로 회원 간 유료서비스를 제공하기로 한 경우(구매자의 정보를 큐레이터에게 제공하는 경우)
    
    개인정보 대상: 유료서비스를 제공받기로 한 회원(구매자)
    
    개인정보를 제공받는 자 : 유료서비스를 제공하기로 한 회원(큐레이터)
    
    제공받는 자의 개인정보 이용 목적 : 유료서비스 제공, 대가 지급(전용 콘텐츠 제공 등), 교환・환불 등 의무 이행, 진행에 관한 공지 및 민원・분쟁 처리
    
    큐레이터는 구매자의 정보를 어떠한 경우에도 서비스에 명시된 목적 이외의 목적으로 활용하여서는 안 됩니다.
    
    큐레이터는 목적 달성을 위한 개인정보 이용 과정에서 개인정보 위탁이 발생할 경우 개인정보보호법을 준수해 개인정보를 관리하여야 합니다.
    
    제공하는 개인정보 항목
    
    결제 시: 아이디, 닉네임, 이메일
    
    제공받는 자의 보유・이용 기간 : 큐레이터는 대가 제공이 완료된 즉시 제공된 구매자의 개인정보를 파기(관계 법령에 의해 보존할 필요가 있는 경우 해당 기간만큼 보관) 하여야 합니다.
    
    회사의 중개로 회원 간 유료서비스를 제공받기로 한 경우(큐레이터의 정보를 구매자에게 제공하는 경우)
    
    개인정보 대상: 유료서비스를 제공하기로 한 회원(큐레이터)
    
    개인정보를 제공받는 자 : 유료서비스를 제공받기로 한 회원(구매자)
    
    제공받는 자의 개인정보 이용 목적 : 유료서비스 민원 문의・상담・분쟁 제기
    
    제공하는 개인정보 항목 : 이름(사업자가 법인인 경우에는 그 명칭과 대표자 이름), 이메일(개인)
    
    제공받는 자의 보유・이용 기간 : 구매자는 목적달성이 완료된 즉시 제공된 작가의 개인정보를 파기(관계 법령에 의해 보존할 필요가 있는 경우 해당 기간만큼 보관) 하여야 합니다.
    
    5. 개인정보의 위탁
    회사는 원활한 서비스를 위하여 개인정보 처리를 타인에게 위탁할 수 있습니다. 이 경우 관계법령에 따라 위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고 있습니다. 또한 위탁처리하는 정보는 원활한 서비스를 제공하기 위하여 필요한 최소한의 정보에 국한합니다. 수탁업체와 위탁 목적은 다음과 같습니다.
    
    수탁 업체의 명칭 / 위탁하는 업무의 내용
    토스페이먼츠 / 유료 서비스 이용 결제 처리 및 관리
    
    Amazon Web Services, Inc., Google Inc. / 데이터 보관
    
    6. 개인정보의 국외 이전
    
    
    
    이전받는 자 및 연락처
    
    이전되는 국가
    
    이전 일시 및 방법
    
    이전 항목
    
    이용목적 및 처리 기간
    
     
    
    Amazon Web Services, Inc.
    
    연락처:
    
    aws-korea-privacy@amazon.com
    
    미국
    
    회원가입 즉시 정보통신망을 통해 업무 필요시에 수시로 이전
    
    개인정보의 수집 및 이용 항목에 있는 개인정보 항목
    
    회원 탈퇴 또는 위탁 계약 종료 시까지
    
     
    
    Google Inc.
    
    연락처:
    
    googlekrsupport@google.com
    
    미국
    
     
    
     
    
     
    
    
    
    
    이용자는 회사의 개인정보보호책임자 및 담당부서를 통해 개인정보의 국외 이전을 거부할 수 있습니다. 이용자가 개인정보의 국외 이전을 거부하는 경우 회사는 해당 이용자의 개인정보를 국외 이전 대상에서 제외합니다. 다만, 이 경우 회사의 서비스 중 개인정보 국외 이전이 필수적으로 수반되는 서비스의 이용이 제한될 수 있습니다.
    
    7. 개인정보의 파기
    회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
    
    1. 파기절차
    이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.
    
    2. 파기방법
    전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
    
    8. 이용자의 권리·의무 및 그 행사방법
    1. 이용자의 권리·의무
    이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 정보 삭제 및 처리 정지, 개인정보보호법에 따른 개인정보의 전송을 요구할 수도 있습니다.
    
    회사는 다음의 어느 하나에 해당하는 경우 이용자에게 그 사유를 알리고 개인정보의 열람, 정정ㆍ삭제, 처리 정지를 제한하거나 거절할 수 있습니다.
    
    법률에 따라 열람이 금지되거나 제한되는 경우
    
    다른 사람의 생명ㆍ신체를 해할 우려가 있거나 다른 사람의 재산과 그 밖의 이익을 부당하게 침해할 우려가 있는 경우
    
    2. 권리행사의 방법 및 절차
    이용자는 개인정보의 열람, 정정ㆍ삭제, 처리 정지 요구를 대리인(이용자의 법정대리인, 이용자로부터 위임을 받은 자)에게 대신하게 할 수 있습니다. 이 경우, 대리인은 회사에 대리인의 위임장을 제출해야 합니다.
    
    만 14세 미만 아동의 법정대리인은 회사에게 그 아동의 개인정보 열람, 정정ㆍ삭제, 처리 정지 요구를 할 수 있습니다.
    
    9. 인터넷 접속정보 파일 등 개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부
    1. 쿠키의 사용 목적
    회사는 이용자에게 보다 빠르고 편리한 웹사이트 사용을 지원하고 맞춤형 서비스를 제공하기 위해 쿠키(cookie)를 사용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터에 저장됩니다.
    
    2. 쿠키의 설치/운영 및 거부
    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹 브라우저에서 쿠키 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 서비스는 이용할 수 없습니다.
    
    Chrome 쿠키 설정 방법
    
    Safari 쿠키 설정 방법
    
    Firefox 쿠키 설정 방법
    
    Edge 쿠키 설정 방법
    
    10. 개인정보의 안전성 확보 조치
    회사는 개인정보보호법 제29조 등 관계 법령에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 조치를 하고 있습니다.
    
    1. 개인정보 취급 직원의 최소화 및 교육
    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
    
    2. 내부관리계획의 수립 및 시행
    개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
    
    3. 개인정보의 암호화
    이용자의 비밀번호는 암호화되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.
    
    4. 해킹 등에 대비한 기술적 대책
    회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신ㆍ점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
    
    5. 가명정보 처리 관련
    회사는 수집한 개인정보의 일부를 삭제하거나 일부 또는 전부를 대체하는 등의 방법으로 추가 정보가 없이는 특정 개인을 알아볼 수 없도록 가명처리하여 이용하는 경우, 원래의 상태로 복원하기 위한 추가 정보를 별도로 분리하여 보관ㆍ관리하는 등 해당 정보가 분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손되지 않도록 안전성 확보에 필요한 기술적ㆍ관리적 및 물리적 조치를 취하고 있으며, 가명정보의 처리 목적, 제3자 제공 시 제공받는 자 등 가명정보의 처리 내용을 관리하기 위하여 관련 기록을 작성하여 보관하고 있습니다.
    
    11. 개인정보보호책임자
    정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제, 개인정보보호법에 따른 개인정보의 전송 요구 등에 관한 사항을 개인정보보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해 드릴 것입니다.
    
    개인정보보호책임자 및 담당부서
    이름 : 최진형
    
    부서명 : 개인정보보호팀
    
    연락처 : learnfit@laivdata.com
    
    기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
    
    개인정보침해 신고센터
    (국번없이) 118
    
    http://privacy.kisa.or.kr
    
    대검찰청 사이버범죄수사단
    02-3480-3571
    
    cybercid@spo.go.kr
    
    경찰청 사이버테러대응센터
    (국번없이)182
    
    http://cyberbureau.police.go.kr
    
    12. 개인정보 보호정책 변경
    이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항(또는 개별 공지)을 통하여 고지할 것입니다.
    
    개인정보처리방침 시행일자 : 2022. 01. 01.`}</LFText>
  );
};

export default MembershipPrivacyPolicy;
