import { StyleSheet, View, ViewProps } from 'react-native';
import React from 'react';
import { colors } from '../constants/styleGuide';

const TopRoundFlex: React.FC<ViewProps> = ({ style, children, ...rest }) => {
  return (
    <View style={[styles.container, style]} {...rest}>
      {children}
    </View>
  );
};

export default TopRoundFlex;

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    backgroundColor: colors.SECONDARY_WHITE,
    overflow: 'hidden',
  },
});
