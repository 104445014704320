import ReactNative, { Image, StyleSheet, View, ViewStyle } from 'react-native';
import React from 'react';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from './LFWhiteSpace';
import LFText from './typo/LFText';
import { useSpring, animated } from 'react-spring';
import * as H from 'history';
import LFLink from './LFLink';

interface IFloatButton {
  to?: string | H.LocationDescriptorObject<{}>;
  delay?: number;
  style?: ViewStyle;
  testID?: string;
}

type Position = React.CSSProperties['position'];
type ReactNativeView = React.ComponentClass<
  ReactNative.ViewProps & { children: React.ReactNode }
>;
const CreateContentItemButton: React.FC<IFloatButton> = ({
  to,
  delay,
  style,
  testID,
}) => {
  const viewProps = useSpring({
    delay: delay ?? 1000,
    from: {
      width: 116,
      padding: 16,
      height: 56,
      borderRadius: 28,
      backgroundColor: colors.PRIMARY_100,
      shadowRadius: 10,
      shadowOpacity: 0.2,
      shadowOffset: {
        height: 4,
        width: 0,
      },
    },
    to: {
      width: 56,
    },
  });
  const textProps = useSpring({
    delay: delay ? delay - 100 : 900,
    from: {
      opacity: 1,
      fontSize: 16,
      lineHeight: 24,
      color: colors.TEXT_WHITE,
      position: 'absolute' as Position,
      right: 16,
    },
    to: {
      opacity: 0,
    },
  });

  const AnimatedView = animated(View as ReactNativeView);
  const AnimatedText = animated(LFText);

  return (
    <LFLink testID={testID} to={to ?? '/new-note'} style={style}>
      <AnimatedView style={viewProps}>
        <Image
          accessibilityLabel="학습노트 작성하기"
          style={{ width: 24, height: 24 }}
          source={require('../assets/images/Icon24NoteAdd@3x.png')}
        />
        <LFWhiteSpace size="xs" direction="row" />
        <AnimatedText style={textProps}>학습노트</AnimatedText>
      </AnimatedView>
    </LFLink>
  );
};

export default CreateContentItemButton;
