import { StyleSheet, View, ViewStyle } from 'react-native';
import React, { useCallback, useMemo } from 'react';
import SectionTitle from './SectionTitle';
import BaseHorizontalPathList from './BaseHorizontalPathList';
import LFWhiteSpace from './LFWhiteSpace';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { RecommendedContentsRelatedWithNote_Fragment$key } from '../__generated__/RecommendedContentsRelatedWithNote_Fragment.graphql';
import BaseContentItemList from './BaseContentItemList';
import { RecommendedContentsRelatedWithNoteQuery } from '../__generated__/RecommendedContentsRelatedWithNoteQuery.graphql';
import { AnalyticsPathWhereInput } from '../__generated__/BaseHorizontalPathListQuery.graphql';
import {
  AnalyticsContentItemOrder,
  AnalyticsContentItemWhereInput,
} from '../__generated__/BaseContentItemListQuery.graphql';
import PathItemView from '../components/PathItemView';
import { LFBoldText } from './typo/LFText';
import { PathItemView_Fragment$key } from '../__generated__/PathItemView_Fragment.graphql';
import { AnalyticsPathOrder } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';

interface IRecommendedContentsRelatedWithNote {
  style?: ViewStyle;
  contentItemFragment: RecommendedContentsRelatedWithNote_Fragment$key | null;
}

// 입력한 학습노트 fragment key와 관련된 러닝패스, 학습노트를 추천해주는 컴포넌트
const RecommendedContentsRelatedWithNote: React.FC<IRecommendedContentsRelatedWithNote> =
  ({ style, contentItemFragment }) => {
    // 노트와 관련된 추천 컨텐츠를 받아오기 전에 노트에 대한 정보를 불러옵니다.
    const contentItem = useFragment(
      graphql`
        fragment RecommendedContentsRelatedWithNote_Fragment on ContentItem {
          id
          objectId
          author {
            objectId
            name
          }
        }
      `,
      contentItemFragment
    );

    // 노트 정보를 토대로 해당 노트를 포함하는 러닝패스 where 조건을 선언합니다.
    const analyticsPathsWhereContainingThisNote: AnalyticsPathWhereInput = {
      path: {
        have: {
          instructionCards: {
            have: {
              sources: {
                equalTo: [
                  {
                    __type: 'Pointer',
                    className: 'ContentItem',
                    objectId: contentItem?.objectId || '',
                  },
                ],
              },
            },
          },
          published: {
            equalTo: true,
          },
        },
      },
    };

    // 위 조건에 부합하는 러닝패스가 없을 경우 사용될 러닝패스 where 조건을 선언합니다.
    const backUpAnalyticsPathsWhere: AnalyticsPathWhereInput = {
      path: {
        have: {
          published: {
            equalTo: true,
          },
          membership: {
            haveNot: {
              objectId: {
                exists: true,
              },
            },
          },
        },
      },
    };

    // 노트 정보를 토대로 해당 노트를 작성한 유저의 다른 학습노트 where 조건을 선언합니다.
    const analyticsContentItemsWhereCreatedByThisNoteOwner: AnalyticsContentItemWhereInput =
      {
        contentItem: {
          have: {
            author: {
              have: {
                objectId: {
                  equalTo: contentItem?.author?.objectId || '',
                },
              },
            },
            published: {
              equalTo: true,
            },
            objectId: {
              notEqualTo: contentItem?.objectId || '',
            },
          },
        },
      };

    // 위 조건에 부합하는 학습노트가 없을 경우 사용될 러닝패스 where 조건을 선언합니다.
    const backUpAnalyticsContentItemsWhere: AnalyticsContentItemWhereInput = {
      contentItem: {
        have: {
          published: {
            equalTo: true,
          },
          objectId: {
            notEqualTo: contentItem?.objectId || '',
          },
        },
      },
    };

    // 위에서 선언해둔 Where들을 토대로 조건에 부합하는 러닝패스, 학습노트의 갯수를 불러옵니다.
    const { analyticsPaths, analyticsContentItems } =
      useLazyLoadQuery<RecommendedContentsRelatedWithNoteQuery>(
        graphql`
          query RecommendedContentsRelatedWithNoteQuery(
            $analyticsPathWhere: AnalyticsPathWhereInput!
            $analyticsContentItemWhere: AnalyticsContentItemWhereInput!
          ) {
            analyticsPaths(where: $analyticsPathWhere) {
              count
            }
            analyticsContentItems(where: $analyticsContentItemWhere) {
              count
            }
          }
        `,
        {
          analyticsPathWhere: analyticsPathsWhereContainingThisNote,
          analyticsContentItemWhere:
            analyticsContentItemsWhereCreatedByThisNoteOwner,
        }
      );

    const pathItemElement = useCallback(
      (pathItemFragment: PathItemView_Fragment$key | null) => (
        <PathItemView pathFragment={pathItemFragment} skipHistory />
      ),
      []
    );
    const onPressPathItemTo = useCallback(
      (pathObjectId: string) => `/path/${pathObjectId}`,
      []
    );
    const baseHorizontalPathListStyle = useMemo(() => ({ padding: 10 }), []);
    const baseHorizontalPathItemStyle = useMemo(() => ({ width: '60%' }), []);
    const baseHorizontalPathWhere = useMemo(
      () =>
        analyticsPaths.count
          ? analyticsPathsWhereContainingThisNote
          : backUpAnalyticsPathsWhere,
      [analyticsPaths.count, contentItem?.objectId]
    );
    const baseHorizontalPathOrder = useMemo<AnalyticsPathOrder[]>(
      () => ['viewCount_DESC'],
      []
    );

    const onPressContentItemTo = useCallback(
      (contentItemId, contentId) => `/note/${contentId}/${contentItemId}`,
      []
    );
    const baseContentItemListStyle = useMemo(
      () => ({
        paddingHorizontal: 10,
      }),
      []
    );

    const baseContentItemListWhere = useMemo(
      () =>
        analyticsContentItems.count
          ? analyticsContentItemsWhereCreatedByThisNoteOwner
          : backUpAnalyticsContentItemsWhere,
      [analyticsContentItems.count, contentItem?.objectId]
    );
    const baseContentItemListOrder = useMemo<AnalyticsContentItemOrder[]>(
      () => ['viewCount_DESC'],
      []
    );

    return (
      <View style={style}>
        <LFWhiteSpace direction="column" size="xl" />
        <SectionTitle
          accessibilityRole="heading"
          accessibilityLevel={2}
          title={
            analyticsPaths.count >= 1
              ? [
                  <LFBoldText>이 학습노트</LFBoldText>,
                  '가 포함된 러닝패스예요.',
                ]
              : [<LFBoldText>이런 러닝패스</LFBoldText>, '는 어때요?']
          }
          style={{ paddingHorizontal: 16 }}
        />
        <BaseHorizontalPathList
          first={5}
          itemElement={pathItemElement}
          gap={24}
          onPressItemTo={onPressPathItemTo}
          style={baseHorizontalPathListStyle}
          itemStyle={baseHorizontalPathItemStyle}
          order={baseHorizontalPathOrder}
          where={baseHorizontalPathWhere}
        />
        <LFWhiteSpace size="xl" />
        <SectionTitle
          accessibilityRole="heading"
          accessibilityLevel={2}
          title={
            analyticsContentItems.count >= 1
              ? [
                  <LFBoldText>{contentItem?.author?.name}</LFBoldText>,
                  '님의 다른 노트는 어때요?',
                ]
              : [<LFBoldText>이런 학습노트</LFBoldText>, '는 어때요?']
          }
          style={{ paddingHorizontal: 16 }}
        />
        <BaseContentItemList
          gutter={8}
          onPressItemTo={onPressContentItemTo}
          style={baseContentItemListStyle}
          noLoadNext
          first={2}
          order={baseContentItemListOrder}
          where={baseContentItemListWhere}
        />
      </View>
    );
  };

// React.memo 덕에 prop이 동일하면 리렌더링 발생안함
export default React.memo(RecommendedContentsRelatedWithNote);

const styles = StyleSheet.create({});
