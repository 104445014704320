import {
  Image,
  ImageStyle,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import LFWhiteSpace from './LFWhiteSpace';
import { useAuth } from '../hooks/auth';
import LFText from './typo/LFText';
import { colors } from '../constants/styleGuide';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import PreviewImage from './PreviewImage';
import dayjs from 'dayjs';
import _ from 'lodash';
import LFLink from './LFLink';
import { PathItemViewHistoryQuery } from '../__generated__/PathItemViewHistoryQuery.graphql';
import { PathItemView_Fragment$key } from '../__generated__/PathItemView_Fragment.graphql';
import UserProfileImage from './UserProfileImage';
import getPathImagenameUsingTimestamp from '../helper/getPathImagenameUsingTimestamp';

const PathItemView: React.FC<{
  pathFragment: PathItemView_Fragment$key | null;
  style?: ViewStyle;
  skipHistory?: boolean;
}> = ({ pathFragment, style, skipHistory = false }) => {
  const path = useFragment(
    graphql`
      fragment PathItemView_Fragment on Path {
        id
        objectId
        title
        isSeries
        published
        createdAt
        membership {
          id
          objectId
        }
        coverImage {
          url
        }
        author {
          id
          objectId
          name
          originProfileURL
          profileImage {
            url
          }
          ...UserProfileImageFragment
        }
      }
    `,
    pathFragment || null
  );
  const { user } = useAuth();
  const { pathHistory } = useLazyLoadQuery<PathItemViewHistoryQuery>(
    graphql`
      query PathItemViewHistoryQuery(
        $pathId: ID
        $userId: ID
        $skipHistory: Boolean!
      ) {
        pathHistory: learningHistories(
          where: {
            AND: [
              { path: { have: { objectId: { equalTo: $pathId } } } }
              { author: { have: { objectId: { equalTo: $userId } } } }
            ]
            author: { have: { objectId: { equalTo: $userId } } }
          }
        ) @skip(if: $skipHistory) {
          edges {
            node {
              id
              objectId
              status
              updatedAt
            }
          }
        }
      }
    `,
    {
      pathId: path?.objectId,
      userId: user?.objectId || 'NOUSER',
      skipHistory,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const pathInfo = {
    thumbURL: path?.coverImage?.url,
    title: path?.title,
    author: path?.author,
    isMembership: path?.membership ?? false,
    isSeries: path?.isSeries ?? false,
    learningHistory: pathHistory?.edges?.map((edge) => edge?.node)[0],
  };
  const history = useHistory();
  return (
    <View style={[styles.featuredPathContainer, style]}>
      <View style={styles.featuredPathThumbnailContainer}>
        <PreviewImage
          accessibilityLabel=""
          style={styles.featuredPathThumbnail}
          source={
            path?.coverImage?.url
              ? {
                  uri: path?.coverImage?.url,
                }
              : require(`../assets/images/${getPathImagenameUsingTimestamp({
                  seed: dayjs(path?.createdAt as string).valueOf(),
                })}`)
          }
        />
        {pathInfo.learningHistory?.status && (
          <LFText style={styles.featuredPathLearningStatus}>
            {pathInfo.learningHistory?.status === 'done'
              ? `🏅학습완료 ${dayjs(
                  pathInfo.learningHistory.updatedAt as string | undefined
                ).format('YYYY.MM.DD')}`
              : '✍️학습중'}
          </LFText>
        )}
      </View>
      <View style={{ padding: 12 }}>
        <Flex align="center" wrap={'wrap'}>
          <Flex
            align="center"
            style={[
              styles.badgeItem,
              {
                backgroundColor: pathInfo.isMembership
                  ? colors.PRIMARY_20
                  : colors.SECONDARY_Y_100,
                paddingRight: 6,
              },
            ]}
          >
            <View style={styles.badgeLogo}>
              <PreviewImage
                style={styles.badgeImage as StyleProp<ImageStyle>}
                source={require('../assets/images/free-lf-logo.png')}
              />
            </View>
            <LFText
              style={[
                styles.badgeLabel,
                pathInfo.isMembership ? { color: colors.TEXT_DARK } : null,
              ]}
            >
              {pathInfo.isMembership ? '멤버십' : '무료'}
            </LFText>
          </Flex>
          {pathInfo.isSeries && (
            <View
              style={[
                styles.badgeItem,
                {
                  marginLeft: 4,
                  borderRadius: 4,
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                },
              ]}
            >
              <LFText style={styles.badgeLabel}>시리즈</LFText>
            </View>
          )}
          {!path?.published && (
            <>
              <LFWhiteSpace size="xs" direction="row" />
              <View style={styles.privateBadgeContainer}>
                <LFText style={styles.privateBadgeText}>비공개</LFText>
              </View>
            </>
          )}
        </Flex>
        <WhiteSpace size="sm" />
        <Flex align="center">
          <UserProfileImage
            userFrgmt={path?.author}
            size={16}
            style={{ borderWidth: 1, borderColor: colors.BORDER_10 }}
          />
          <LFWhiteSpace size="xs" direction="row" />
          <Flex style={{ flex: 1 }}>
            <LFText
              numberOfLines={1}
              style={[styles.featuredPathAuthorName, { flexShrink: 1 }]}
            >
              {path?.author?.name}
            </LFText>
            <LFText style={[styles.featuredPathAuthorName, { flexShrink: 0 }]}>
              의 러닝패스
            </LFText>
          </Flex>
        </Flex>
        <WhiteSpace size="sm" />
        <View style={{ height: 68 }}>
          <LFText
            numberOfLines={3}
            style={styles.featuredPathTitle}
            accessibilityRole="heading"
            aria-level={3}
          >
            {path?.title}
          </LFText>
        </View>
      </View>
    </View>
  );
};

export default PathItemView;

const styles = StyleSheet.create({
  badgeItem: {
    backgroundColor: colors.BG_3,
    padding: 4,
    borderRadius: 100,
    alignSelf: 'flex-start',
  },
  badgeImage: {
    width: 10,
    height: 10,
  },
  badgeLogo: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 2,
  },
  badgeLabel: {
    fontSize: 10,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    // transform이 ts 에러 발생시킴
    // @ts-ignore
    textFransform: 'uppercase',
  },
  featuredPathContainer: {
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    shadowColor: 'rgba(58, 69, 99, 0.2)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
  },
  featuredPathThumbnailContainer: {
    position: 'relative',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  featuredPathThumbnail: {
    width: '100%',
    paddingBottom: '55%',
    height: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  featuredPathLearningStatus: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_WHITE,
    textShadowColor: 'rgba(58, 69, 99, 0.2)',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 10,
  },
  featuredPathAuthorName: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  featuredPathTitle: {
    wordBreak: 'keep-all',
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  privateBadgeContainer: {
    borderRadius: 100,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.BORDER_20,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  privateBadgeText: {
    fontSize: 10,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
});
