/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateQuizFieldsInput = {
    ACL?: ACLInput | null;
    author?: UserPointerInput | null;
    title?: string | null;
    options?: Array<unknown | null> | null;
    correctIndexes?: Array<unknown | null> | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type PathEditorScreenUpdateQuizMutationVariables = {
    objectId: string;
    fields: UpdateQuizFieldsInput;
};
export type PathEditorScreenUpdateQuizMutationResponse = {
    readonly updateQuiz: {
        readonly clientMutationId: string | null;
        readonly quiz: {
            readonly id: string;
            readonly objectId: string;
            readonly title: string | null;
            readonly options: ReadonlyArray<{
                readonly value?: unknown;
            } | null> | null;
            readonly correctIndexes: ReadonlyArray<{
                readonly value?: unknown;
            } | null> | null;
        };
    } | null;
};
export type PathEditorScreenUpdateQuizMutation = {
    readonly response: PathEditorScreenUpdateQuizMutationResponse;
    readonly variables: PathEditorScreenUpdateQuizMutationVariables;
};



/*
mutation PathEditorScreenUpdateQuizMutation(
  $objectId: ID!
  $fields: UpdateQuizFieldsInput!
) {
  updateQuiz(input: {id: $objectId, fields: $fields}) {
    clientMutationId
    quiz {
      id
      objectId
      title
      options {
        __typename
        ... on Element {
          value
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
      correctIndexes {
        __typename
        ... on Element {
          value
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fields"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "fields",
        "variableName": "fields"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "objectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v7/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      (v4/*: any*/)
    ],
    "type": "Node",
    "abstractKey": "__isNode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PathEditorScreenUpdateQuizMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateQuizPayload",
        "kind": "LinkedField",
        "name": "updateQuiz",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Quiz",
            "kind": "LinkedField",
            "name": "quiz",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "correctIndexes",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PathEditorScreenUpdateQuizMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateQuizPayload",
        "kind": "LinkedField",
        "name": "updateQuiz",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Quiz",
            "kind": "LinkedField",
            "name": "quiz",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "options",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "correctIndexes",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "944d4dcc13481a8c28fd74dc2e55ebe8",
    "id": null,
    "metadata": {},
    "name": "PathEditorScreenUpdateQuizMutation",
    "operationKind": "mutation",
    "text": "mutation PathEditorScreenUpdateQuizMutation(\n  $objectId: ID!\n  $fields: UpdateQuizFieldsInput!\n) {\n  updateQuiz(input: {id: $objectId, fields: $fields}) {\n    clientMutationId\n    quiz {\n      id\n      objectId\n      title\n      options {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      correctIndexes {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '49362e4048494e37b6106393dc1ceeb8';
export default node;
