import { StyleSheet } from 'react-native';
import React from 'react';
import TopRoundFlex from '../components/TopRoundFlex';
import { HistoryBackButton } from '../components/NavBar2';
import { Flex } from '@ant-design/react-native';
import LFText from '../components/typo/LFText';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from '../components/LFWhiteSpace';
import _ from 'lodash';
import PositionList from './PositionSelectorScreen/PositionList';
import { PositionItemProps } from './PositionSelectorScreen/type';
import { useHistory, useLocation } from 'react-router-dom';

export const POSITIONS: PositionItemProps[] = [
  {
    id: 'developer',
    title: '개발자',
    cover: require('../assets/images/jobtitledeveloper@3x.png'),
  },
  {
    id: 'pp',
    title: 'P.O / 기획자',
    cover: require('../assets/images/jobtitlepo@3x.png'),
  },
  {
    id: 'designer',
    title: '디자이너',
    cover: require('../assets/images/jobtitledesigner@3x.png'),
  },
  {
    id: 'dataScientist',
    title: '데이터 사이언티스트',
    cover: require('../assets/images/jobtitleds@3x.png'),
  },
  {
    id: 'marketer',
    title: '마케터',
    cover: require('../assets/images/jobtitlemarketer@3x.png'),
  },
  {
    id: 'other',
    title: '기타',
    cover: require('../assets/images/jobtitleetc@3x.png'),
  },
];

interface CustomLocation extends Location {
  state: {
    from?: Location;
  };
}

const PositionSelectorScreen = () => {
  const location = useLocation() as CustomLocation;
  const history = useHistory();
  const goBackToLoginAccessScreen = () => {
    history.push(location.state?.from || { pathname: '/' });
  };

  return (
    <TopRoundFlex style={styles.container}>
      <Flex justify="between" style={{ alignSelf: 'stretch' }}>
        <HistoryBackButton style={{ margin: -8 }} />
        <LFText onPress={goBackToLoginAccessScreen} style={styles.laterText}>
          다음에 할게요
        </LFText>
      </Flex>
      <LFWhiteSpace direction="column" size="xl" />
      <LFWhiteSpace direction="column" size="xs" />
      <LFText style={styles.descriptionOne}>
        👋 관심사에 맞게 학습콘텐츠를 추천해 드려요.
      </LFText>
      <LFWhiteSpace direction="column" size="xl" />
      <LFText style={styles.title}>어떤 일을 하시나요?</LFText>
      <LFWhiteSpace size="sm" direction="column" />
      <LFText style={styles.descriptionTwo}>
        또는 하고 싶은 일을 선택해 주세요.
      </LFText>
      <LFWhiteSpace direction="column" size="xl" />
      <LFWhiteSpace direction="column" size="lg" />
      <PositionList
        positions={POSITIONS}
        onPressItemTo={(id) => ({
          pathname: `/interesting-selector/${id}`,
          state: location.state,
        })}
      />
    </TopRoundFlex>
  );
};

export default PositionSelectorScreen;

const styles = StyleSheet.create({
  container: { paddingTop: 12, paddingHorizontal: 16, flex: 1 },
  laterText: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.TEXT_60,
  },
  descriptionOne: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 40,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
  descriptionTwo: {
    opacity: 0.6,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
});
