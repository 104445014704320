import { useWindowDimensions } from 'react-native';

export function useMobileDimensions(maxWidth = 720) {
  const window = useWindowDimensions();

  const windowMin = Math.min(window.width, window.height);
  const windowMax = Math.max(window.width, window.height);
  const shouldWrap = windowMin > maxWidth && windowMax > maxWidth;

  return {
    width: Math.min(window.width, maxWidth),
    height: window.height,
    shouldWrap,
  };
}
