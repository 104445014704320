/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateContentItemInput = {
    id: string;
    fields?: UpdateContentItemFieldsInput | null;
    clientMutationId?: string | null;
};
export type UpdateContentItemFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    description?: string | null;
    author?: UserPointerInput | null;
    content?: ContentPointerInput | null;
    noteData?: Array<unknown | null> | null;
    tags?: Array<unknown | null> | null;
    published?: boolean | null;
    md?: string | null;
    analytics?: AnalyticsContentItemPointerInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type ContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentFieldsInput | null;
};
export type CreateContentFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    thumbURL?: string | null;
    type?: string | null;
    link?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    rawMetadata?: unknown | null;
    analytics?: AnalyticsContentPointerInput | null;
};
export type AnalyticsContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentFieldsInput | null;
};
export type CreateAnalyticsContentFieldsInput = {
    ACL?: ACLInput | null;
    content?: ContentPointerInput | null;
    viewCount?: number | null;
    contentItemCount?: number | null;
    emojiCountMap?: unknown | null;
    contentItemAuthors?: Array<unknown | null> | null;
    emojiTotalCount?: number | null;
    commentCount?: number | null;
};
export type AnalyticsContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentItemFieldsInput | null;
};
export type CreateAnalyticsContentItemFieldsInput = {
    ACL?: ACLInput | null;
    contentItem?: ContentItemPointerInput | null;
    viewCount?: number | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    commentCount?: number | null;
};
export type ContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentItemFieldsInput | null;
};
export type CreateContentItemFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    description?: string | null;
    author?: UserPointerInput | null;
    content?: ContentPointerInput | null;
    noteData?: Array<unknown | null> | null;
    tags?: Array<unknown | null> | null;
    published?: boolean | null;
    md?: string | null;
    analytics?: AnalyticsContentItemPointerInput | null;
};
export type NotePlayerScreenContentItemMutationVariables = {
    input: UpdateContentItemInput;
};
export type NotePlayerScreenContentItemMutationResponse = {
    readonly updateContentItem: {
        readonly contentItem: {
            readonly id: string;
            readonly noteData: ReadonlyArray<{
                readonly value?: unknown;
            } | null> | null;
            readonly published: boolean | null;
            readonly author: {
                readonly id: string;
                readonly objectId: string;
            } | null;
        };
    } | null;
};
export type NotePlayerScreenContentItemMutationRawResponse = {
    readonly updateContentItem: ({
        readonly contentItem: {
            readonly id: string;
            readonly noteData: ReadonlyArray<({
                readonly __typename: "Element";
                readonly __isNode: "Element";
                readonly id: string;
                readonly value: unknown;
            } | {
                readonly __typename: string;
                readonly __isNode: string;
                readonly id: string;
            }) | null> | null;
            readonly published: boolean | null;
            readonly author: ({
                readonly id: string;
                readonly objectId: string;
            }) | null;
        };
    }) | null;
};
export type NotePlayerScreenContentItemMutation = {
    readonly response: NotePlayerScreenContentItemMutationResponse;
    readonly variables: NotePlayerScreenContentItemMutationVariables;
    readonly rawResponse: NotePlayerScreenContentItemMutationRawResponse;
};



/*
mutation NotePlayerScreenContentItemMutation(
  $input: UpdateContentItemInput!
) {
  updateContentItem(input: $input) {
    contentItem {
      id
      noteData {
        __typename
        ... on Element {
          value
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
      published
      author {
        id
        objectId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotePlayerScreenContentItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContentItemPayload",
        "kind": "LinkedField",
        "name": "updateContentItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "noteData",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotePlayerScreenContentItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateContentItemPayload",
        "kind": "LinkedField",
        "name": "updateContentItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "noteData",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "190c2fd074088954e20b4b0212159afe",
    "id": null,
    "metadata": {},
    "name": "NotePlayerScreenContentItemMutation",
    "operationKind": "mutation",
    "text": "mutation NotePlayerScreenContentItemMutation(\n  $input: UpdateContentItemInput!\n) {\n  updateContentItem(input: $input) {\n    contentItem {\n      id\n      noteData {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      published\n      author {\n        id\n        objectId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fcae63824e5f550f29d7a595be0f0e28';
export default node;
