import { StyleSheet, ViewStyle } from 'react-native';
import React from 'react';
import { graphql, useMutation } from 'react-relay';
import _ from 'lodash';
import { useDebounceFn } from 'ahooks';
import { useHistory, useLocation } from 'react-router-dom';
import {
  BookMarkButtonForPreviewCreateReactionMutation,
  CreateReactionFieldsInput,
} from '../__generated__/BookMarkButtonForPreviewCreateReactionMutation.graphql';
import { BookMarkButtonForPreviewDeleteReactionMutation } from '../__generated__/BookMarkButtonForPreviewDeleteReactionMutation.graphql';
import { useAuth } from '../hooks/auth';
import { LoginScreenAccessAction } from '../screens/LoginScreen';
import BookMark from './BookMark';
import LFLink from './LFLink';

const TYPE = 'bookmark';
interface IBookMarkButtonForPreview {
  style?: ViewStyle;
  targetClassName: 'Path' | 'ContentItem';
  targetId: string;
  scraped?: { status: boolean; reactionId?: string };
  onCompleted?: () => void;
  disableMode?: boolean;
}
const BookMarkButtonForPreview: React.FC<IBookMarkButtonForPreview> = ({
  style,
  targetClassName,
  targetId,
  scraped,
  onCompleted,
  disableMode = false,
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [commitCreateReaction] =
    useMutation<BookMarkButtonForPreviewCreateReactionMutation>(
      graphql`
        mutation BookMarkButtonForPreviewCreateReactionMutation(
          $fields: CreateReactionFieldsInput!
        ) {
          createReaction(input: { fields: $fields }) {
            reaction {
              id
              objectId
              contentItem {
                id
                objectId
              }
            }
          }
        }
      `
    );

  const [commitDeleteReaction] =
    useMutation<BookMarkButtonForPreviewDeleteReactionMutation>(graphql`
      mutation BookMarkButtonForPreviewDeleteReactionMutation($id: ID!) {
        deleteReaction(input: { id: $id }) {
          reaction {
            id
            objectId
            contentItem {
              id
              objectId
            }
          }
        }
      }
    `);

  const onCreateReaction = () => {
    const fields: CreateReactionFieldsInput = {
      type: TYPE,
      targetClassName,
      [_.lowerFirst(targetClassName)]: {
        link: targetId,
      },
    };
    commitCreateReaction({
      variables: {
        fields,
      },
      onCompleted,
      onError: console.log,
    });
  };

  const onDeleteReaction = () => {
    commitDeleteReaction({
      variables: {
        id: scraped?.reactionId || '',
      },
      onCompleted,
      onError: console.log,
    });
  };

  const { run: onPress } = useDebounceFn(
    () => {
      if (!user) {
        history.push({
          pathname: '/login',
          state: {
            from: location,
            accessAction: 'clickBookMarkButton' as LoginScreenAccessAction,
          },
        });
        return;
      }
      scraped?.reactionId ? onDeleteReaction() : onCreateReaction();
    },
    { wait: 500 }
  );
  return (
    <LFLink disabled={disableMode} style={style} onPress={onPress}>
      <BookMark selected={Boolean(scraped?.status)} />
    </LFLink>
  );
};

export default BookMarkButtonForPreview;

const styles = StyleSheet.create({});
