import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import React, { useEffect } from 'react';
import LFText from '../components/typo/LFText';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import LFButton from '../components/LFButton';
import NavBar, { HistoryBackButton } from '../components/NavBar2';
import Footer from '../components/Footer';
import Hr from '../components/Hr';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { MembershipPaymentSuccessScreenAuthorQuery } from '../__generated__/MembershipPaymentSuccessScreenAuthorQuery.graphql';
import dayjs from 'dayjs';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const MembershipPaymentSuccessScreen = () => {
  const { userObjectId } = useParams<{ userObjectId: string }>();
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const amount = parseInt(urlSearchParams.get('amount') || '0', 10);
  const paymentId = urlSearchParams.get('paymentId') || '';
  const fromPathname = urlSearchParams.get('fromPathname');
  const { user, membershipPayment } =
    useLazyLoadQuery<MembershipPaymentSuccessScreenAuthorQuery>(
      graphql`
        query MembershipPaymentSuccessScreenAuthorQuery(
          $userObjectId: ID!
          $paymentId: ID!
        ) {
          user(id: $userObjectId) {
            id
            objectId
            name
          }
          membershipPayment(id: $paymentId) {
            id
            objectId
            membershipStatus {
              id
              objectId
              expiredDate
            }
            membershipPricing {
              id
              price
              duration
              membership {
                id
                objectId
                title
                author {
                  id
                  objectId
                  name
                }
              }
            }
          }
        }
      `,
      {
        userObjectId,
        paymentId,
      },
      { fetchPolicy: 'store-and-network' }
    );
  console.log(membershipPayment.membershipStatus?.expiredDate);

  const sendDataToGTM = useGTMDispatch();
  useEffect(() => {
    sendDataToGTM({
      event: 'purchase',
      ecommerce: { MembershipPaymentId: membershipPayment?.objectId },
    });
  }, []);
  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <NavBar
        left={
          <>
            <HistoryBackButton />
          </>
        }
        title={'멤버십'}
        right={<></>}
      />
      <View style={{ padding: 16 }}>
        <LFText style={{ fontSize: 20 }}>결제가 완료되었습니다.</LFText>
        <WhiteSpace size="xl" />
        <LFText>
          {user.name} 님의 인사이트가 담긴 멤버십 전용 콘텐츠를{' '}
          {/* 유효기간은 만료일보다 하루 전임 */}
          {dayjs(membershipPayment.membershipStatus?.expiredDate as string)
            .subtract(1, 'days')
            .format('LL')}
          까지 열람할 수 있습니다.
        </LFText>
        <WhiteSpace size="xl" />
        <View
          style={{
            borderWidth: 1,
            borderColor: '#236EFF',
            paddingTop: 32,
            paddingBottom: 48,
            paddingHorizontal: 8,
          }}
        >
          <Image
            accessibilityLabel=""
            resizeMode="cover"
            source={require('../assets/images/new-payment-welcome-logo.png')}
            style={{
              width: '35%',
              height: 0,
              paddingBottom: '35%',
              margin: 'auto',
            }}
          />
          <WhiteSpace size="xl" />

          <Flex align="center" justify="between">
            <LFText style={{ color: '#787878' }}>구매이용권</LFText>
            <LFText>
              {membershipPayment.membershipPricing?.duration}일 이용권
            </LFText>
          </Flex>
          <WhiteSpace size="sm" />
          <Hr />
          <WhiteSpace size="sm" />
          <Flex align="center" justify="between">
            <LFText style={{ color: '#787878' }}>결제 금액</LFText>
            <LFText>
              {membershipPayment.membershipPricing?.price?.toLocaleString(
                'ko-KR'
              )}
              원
            </LFText>
          </Flex>
          <WhiteSpace size="sm" />
          <Hr />
          <WhiteSpace size="sm" />
          <Flex align="center" justify="between">
            <LFText style={{ color: '#787878' }}>멤버십 유효기간</LFText>
            <LFText>
              {/* 유효기간은 만료일보다 하루 전임 */}
              {dayjs(membershipPayment.membershipStatus?.expiredDate as string)
                .subtract(1, 'day')
                .format('YYYY.MM.DD')}
              까지
            </LFText>
          </Flex>
          <WhiteSpace size="sm" />
          <Hr />
          <WhiteSpace size="sm" />
          {/* <LFText style={{ color: '#787878' }}>
            이용권 가입 정보는 My Page에서 가능합니다.
          </LFText> */}
        </View>
        <WhiteSpace size="md" />
        <LFButton
          onPress={() => {
            if (fromPathname) {
              history.push(fromPathname);
            } else {
              history.push(`/u/${userObjectId}`);
            }
          }}
        >
          {fromPathname?.startsWith('/path') ||
          fromPathname?.startsWith('/note')
            ? '멤버십 전용 콘텐츠 이어서 학습하기'
            : '큐레이터 페이지가기'}
        </LFButton>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default MembershipPaymentSuccessScreen;

const styles = StyleSheet.create({});
