import React from 'react';
import { Flex } from '@ant-design/react-native';
import { StyleSheet, View, ViewStyle } from 'react-native';
import LFLink, { LFLinkTo } from '../../components/LFLink';
import { PositionItemProps } from './type';
import _ from 'lodash';
import PositionItem from './PositionItem';

const PositionList: React.FC<{
  style?: ViewStyle;
  positions: PositionItemProps[];
  onPressItemTo: (id: string) => LFLinkTo;
  gutter?: number | [number, number];
}> = ({ style, positions, onPressItemTo, gutter = 16 }) => {
  const [horizontalGutter, verticalGutter] = [
    ..._.castArray(gutter),
    ..._.castArray(gutter),
  ];
  const positionRows = _.chunk(positions, 2);
  return (
    <View style={[{ width: '100%' }, style]}>
      {positionRows.map((positionRow, i) => {
        const last = positionRows.length === i + 1;
        return (
          <Flex
            style={{
              marginBottom: last ? undefined : verticalGutter,
            }}
          >
            {positionRow.map((position, i) => (
              <LFLink
                to={onPressItemTo(position.id)}
                style={{
                  width: '50%',
                  ['padding' + (i % 2 === 0 ? 'Right' : 'Left')]:
                    horizontalGutter / 2,
                }}
              >
                <PositionItem key={position.id} position={position} />
              </LFLink>
            ))}
          </Flex>
        );
      })}
    </View>
  );
};

export default PositionList;
const styles = StyleSheet.create({});
