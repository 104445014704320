/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NotificationScreenUserUpdateMutationVariables = {
    id: string;
    createdAtOfTopNoti?: unknown | null;
};
export type NotificationScreenUserUpdateMutationResponse = {
    readonly updateUser: {
        readonly user: {
            readonly createdAtOfLastSeenNotification: unknown | null;
        };
    } | null;
};
export type NotificationScreenUserUpdateMutation = {
    readonly response: NotificationScreenUserUpdateMutationResponse;
    readonly variables: NotificationScreenUserUpdateMutationVariables;
};



/*
mutation NotificationScreenUserUpdateMutation(
  $id: ID!
  $createdAtOfTopNoti: Date
) {
  updateUser(input: {id: $id, fields: {createdAtOfLastSeenNotification: $createdAtOfTopNoti}}) {
    user {
      createdAtOfLastSeenNotification
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "createdAtOfTopNoti"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "createdAtOfLastSeenNotification",
            "variableName": "createdAtOfTopNoti"
          }
        ],
        "kind": "ObjectValue",
        "name": "fields"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAtOfLastSeenNotification",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationScreenUserUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotificationScreenUserUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "99fac2973f9ca31ddc6c3239e2c3150a",
    "id": null,
    "metadata": {},
    "name": "NotificationScreenUserUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationScreenUserUpdateMutation(\n  $id: ID!\n  $createdAtOfTopNoti: Date\n) {\n  updateUser(input: {id: $id, fields: {createdAtOfLastSeenNotification: $createdAtOfTopNoti}}) {\n    user {\n      createdAtOfLastSeenNotification\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f0440be7d7c4535be0d607b4f4118627';
export default node;
