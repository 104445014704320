import React from 'react';
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import _ from 'lodash';

interface CheckBoxProps {
  key?: number;
  checked?: boolean;
  onPress?: (checked: boolean) => void;
  size?: number;
  color?: string;
  style?: ViewStyle;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
  key,
  checked,
  onPress,
  size,
  color,
  style,
}) => {
  return (
    <TouchableOpacity
      key={key}
      onPress={() => {
        _.isFunction(onPress) && onPress(Boolean(checked));
      }}
      style={[styles.defaultStyle, style]}
    >
      {checked ? (
        <Ionicons
          name="checkmark-sharp"
          size={!size ? 13 : size}
          color={!color ? 'black' : color}
        />
      ) : null}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  defaultStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 14,
    height: 14,
    borderWidth: 1,
  },
});
