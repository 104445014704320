import { Flex, WingBlank } from '@ant-design/react-native';
import { Ionicons, Entypo } from '@expo/vector-icons';
import _ from 'lodash';
import React from 'react';
import {
  StyleSheet,
  Text,
  Touchable,
  View,
  Image,
  ViewStyle,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useHistory } from 'react-router';
import { ReactElement } from 'react-router/node_modules/@types/react';
import { useAuth } from '../hooks/auth';
import LFLink from './LFLink';

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { isDrawerOpenState } from '../screens/DrawerScreen';
import { isSearchFiredState } from '../screens/SearchScreen';
import NotificationNavButton from './NotificationNavButton';
import LFText from './typo/LFText';

interface NavBarProps {
  style?: ViewStyle;
  title?: string | ReactElement;
  right?: ReactElement;
  left?: ReactElement;
}
const NavBar: React.FC<NavBarProps> = ({
  style,
  title = 'LearnFit',
  right,
  left,
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(isDrawerOpenState);
  const [isSearchFired, setIsSearchFired] = useRecoilState(isSearchFiredState);

  return (
    <View style={[styles.container, style]}>
      <View style={styles.leftWrap}>
        {_.isUndefined(left) ? (
          <LFLink
            testID="header-menu:NavBar"
            onPress={() => {
              setIsDrawerOpen(true);
            }}
          >
            <Ionicons name="menu-outline" size={24} color="black" />
          </LFLink>
        ) : (
          left
        )}
      </View>
      <View style={styles.titleWrap}>
        {typeof title === 'string' ? (
          title === 'LearnFit' ? (
            <LFLink
              testID="header-home:NavBar"
              onPress={() => {
                if (history.location.pathname !== '/') {
                  history.push('/');
                }
              }}
            >
              <LFText style={styles.title}>{title}</LFText>
            </LFLink>
          ) : (
            <LFText style={styles.title}>{title}</LFText>
          )
        ) : (
          { title }
        )}
      </View>
      <View style={styles.rightWrap}>
        {_.isUndefined(right) ? (
          <Flex>
            <LFLink
              testID="header-search:NavBar"
              onPress={() => {
                setIsSearchFired(false);
                history.push('/search');
              }}
              style={{ marginRight: 24 }}
            >
              <Ionicons name="search" size={20} color="#26282C" />
            </LFLink>
            <NotificationNavButton />
          </Flex>
        ) : (
          right
        )}
      </View>
    </View>
  );
};

interface HistoryBackButtonProps {
  onPress?: () => void;
  icon?: ReactElement;
  testID?: string;
}
export const HistoryBackButton: React.FC<HistoryBackButtonProps> = ({
  icon,
  onPress,
  testID,
}) => {
  const history = useHistory();
  return (
    <LFLink testID={testID} onPress={onPress ? onPress : () => history.goBack()}>
      {icon ? (
        icon
      ) : (
        <Ionicons name="chevron-back-sharp" size={24} color="black" />
      )}
    </LFLink>
  );
};

export default NavBar;

const styles = StyleSheet.create({
  container: {
    // width: '100%',
    zIndex: 10,
    backgroundColor: '#fff',
    height: 66,
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '600',
  },
  titleWrap: {
    flex: 1,
  },
  leftWrap: {
    // flex: 1,
    paddingLeft: 24,
    minWidth: '20%',
  },
  rightWrap: {
    // flex: 1,
    minWidth: '20%',
    paddingRight: 24,
    alignItems: 'flex-end',
  },
});
