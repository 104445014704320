/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UserOrder = "ACL_ASC" | "ACL_DESC" | "authData_ASC" | "authData_DESC" | "category_ASC" | "category_DESC" | "createdAtOfLastSeenNotification_ASC" | "createdAtOfLastSeenNotification_DESC" | "createdAt_ASC" | "createdAt_DESC" | "description_ASC" | "description_DESC" | "emailVerified_ASC" | "emailVerified_DESC" | "email_ASC" | "email_DESC" | "id_ASC" | "id_DESC" | "introduce_ASC" | "introduce_DESC" | "jobTitle_ASC" | "jobTitle_DESC" | "name_ASC" | "name_DESC" | "objectId_ASC" | "objectId_DESC" | "originProfileURL_ASC" | "originProfileURL_DESC" | "password_ASC" | "password_DESC" | "profileImage_ASC" | "profileImage_DESC" | "tags_ASC" | "tags_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "username_ASC" | "username_DESC" | "%future added value";
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type BaseHorizontalCuratorItemListQueryVariables = {
    where?: UserWhereInput | null;
    order?: Array<UserOrder> | null;
    first?: number | null;
};
export type BaseHorizontalCuratorItemListQueryResponse = {
    readonly curators: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
            } | null;
        } | null> | null;
    };
};
export type BaseHorizontalCuratorItemListQuery = {
    readonly response: BaseHorizontalCuratorItemListQueryResponse;
    readonly variables: BaseHorizontalCuratorItemListQueryVariables;
};



/*
query BaseHorizontalCuratorItemListQuery(
  $where: UserWhereInput
  $order: [UserOrder!]
  $first: Int
) {
  curators: users(where: $where, order: $order, first: $first) {
    count
    edges {
      node {
        id
        objectId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v3 = [
  {
    "alias": "curators",
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseHorizontalCuratorItemListQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BaseHorizontalCuratorItemListQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "95dd044427f8895addf0179a8d09fc18",
    "id": null,
    "metadata": {},
    "name": "BaseHorizontalCuratorItemListQuery",
    "operationKind": "query",
    "text": "query BaseHorizontalCuratorItemListQuery(\n  $where: UserWhereInput\n  $order: [UserOrder!]\n  $first: Int\n) {\n  curators: users(where: $where, order: $order, first: $first) {\n    count\n    edges {\n      node {\n        id\n        objectId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '443cf5e97b8c4c73e3ba7f870f02c0b3';
export default node;
