import { Flex } from '@ant-design/react-native';
import _ from 'lodash';
import React from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { colors } from '../../constants/styleGuide';
import { ContentItemAuthorSummaryFragment$key } from '../../__generated__/ContentItemAuthorSummaryFragment.graphql';
import { ContentItemAuthorSummaryQuery } from '../../__generated__/ContentItemAuthorSummaryQuery.graphql';
import LFWhiteSpace from '../LFWhiteSpace';
import LFText from '../typo/LFText';
import UserProfileImage from '../UserProfileImage';

const ContentItemAuthorSummary: React.FC<{
  style: ViewStyle;
  contentItemFrgmt: ContentItemAuthorSummaryFragment$key;
  skipQuery: boolean;
}> = ({ style, contentItemFrgmt, skipQuery }) => {
  const contentItem = useFragment(
    graphql`
      fragment ContentItemAuthorSummaryFragment on ContentItem {
        id
        objectId
        content {
          id
          objectId
        }
        author {
          ...UserProfileImageFragment
          name
          profileImage {
            url
          }
          originProfileURL
          id
          objectId
        }
      }
    `,
    contentItemFrgmt
  );

  const { contentItems } = useLazyLoadQuery<ContentItemAuthorSummaryQuery>(
    graphql`
      query ContentItemAuthorSummaryQuery(
        $contentObjectId: ID!
        $skip: Boolean!
        $contentItemObjectId: ID!
      ) {
        contentItems(
          order: updatedAt_DESC
          first: 3
          where: {
            content: { have: { objectId: { equalTo: $contentObjectId } } }
            objectId: { notEqualTo: $contentItemObjectId }
          }
        ) @skip(if: $skip) {
          count
          edges {
            node {
              author {
                ...UserProfileImageFragment
              }
            }
          }
        }
      }
    `,
    {
      skip: skipQuery || !contentItem.content?.objectId,
      contentObjectId: contentItem.content?.objectId || '',
      contentItemObjectId: contentItem.objectId,
    }
  );
  return (
    <Flex direction="row" justify="between" align="center">
      <Flex direction="row" align="center">
        <UserProfileImage userFrgmt={contentItem.author} size={24} />
        <LFWhiteSpace size="xs" direction="row" />
        <LFText style={styles.authorName}>
          {contentItem.author?.name} 님의 학습노트
          {contentItems?.count ? (
            <LFText> 외 {contentItems?.count}개</LFText>
          ) : null}
        </LFText>
      </Flex>
      <Flex direction="row">
        {_.map(contentItems?.edges, (e, idx) => {
          return (
            <UserProfileImage
              key={idx}
              style={{ marginLeft: -4 }}
              size={16}
              userFrgmt={e?.node?.author}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export default ContentItemAuthorSummary;

const styles = StyleSheet.create({
  authorName: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
});
