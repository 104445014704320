import {
  ImageStyle,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import React, { createContext, useContext } from 'react';
import { graphql, useFragment } from 'react-relay';
import { ViewProps } from 'react-device-detect';
import {
  ReplyFragment,
  ReplyFragment$key,
} from '../../__generated__/ReplyFragment.graphql';
import UserProfileImage from '../UserProfileImage';
import LFWhiteSpace from '../LFWhiteSpace';
import LFText, { LFHyperLink } from '../typo/LFText';
import LFLink from '../LFLink';

const ReplyContext = createContext<ReplyFragment | null>(null);

const useReply = () => useContext(ReplyContext);

interface Reply {
  fragmentRef: ReplyFragment$key | null;
  children: React.ReactNode;
  props?: Partial<ViewProps>;
  style?: ViewStyle;
}

const Reply = ({ fragmentRef, children, style, ...props }: Reply) => {
  const data = useFragment(
    graphql`
      fragment ReplyFragment on Comment {
        id
        objectId
        createdAt
        text
        author {
          id
          objectId
          name
          id
          objectId
          name
          ...UserProfileImageFragment
        }
        parent {
          id
          objectId
          text
        }
      }
    `,
    fragmentRef
  );
  return (
    <ReplyContext.Provider value={data} {...props}>
      <View style={style}>{children}</View>
    </ReplyContext.Provider>
  );
};

function Avatar({
  additionalText,
  right,
  style,
  size,
  textStyle,
  imageStyle,
  onPress,
}: {
  additionalText?: string | React.ReactNode;
  right?: React.ReactNode;
  style?: ViewStyle;
  size?: number;
  textStyle?: TextStyle;
  imageStyle?: ImageStyle;
  onPress?: (userId: string) => void;
}) {
  const data = useReply();

  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      <LFLink
        style={{ flexDirection: 'row', alignItems: 'center' }}
        onPress={() =>
          onPress && data?.author?.objectId && onPress(data.author.objectId)
        }
      >
        <UserProfileImage
          userFrgmt={data?.author || null}
          size={size || 24}
          style={{
            borderWidth: 1,
            borderColor: 'rgba(26, 27, 30, 0.1)',
            backgroundColor: 'white',
            ...(imageStyle || []),
          }}
        />
        <LFWhiteSpace size="xs" direction="row" />
        <LFText style={[styles.userNameText, textStyle]}>
          {data?.author?.name}
        </LFText>
      </LFLink>
      {additionalText}
      <LFWhiteSpace size={1} direction="row" style={{ marginLeft: 'auto' }} />
      {right}
    </View>
  );
}

function Text({
  style,
  textStyle,
}: {
  style?: ViewStyle;
  textStyle?: TextStyle;
}) {
  const data = useReply();
  return (
    <View style={style}>
      <LFHyperLink>
        <LFText style={[textStyle]}>{data?.text}</LFText>
      </LFHyperLink>
    </View>
  );
}

export function Button({
  style,
  text,
  onPress,
  textStyle,
}: {
  style?: ViewStyle;
  text: string;
  onPress?: () => void;
  textStyle?: TextStyle;
}) {
  return (
    <LFLink onPress={onPress} style={style}>
      <LFText style={[styles.buttonText, textStyle]}>{text}</LFText>
    </LFLink>
  );
}

Reply.Avatar = Avatar;
Reply.Text = Text;
Reply.Button = Button;

export default Reply;

const styles = StyleSheet.create({
  commentForm: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderColor: 'rgba(26, 27, 30, 0.1)',
    borderWidth: 1,
    borderRadius: 12,
    minHeight: 100,
  },
  userNameText: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    color: '#1A1B1E',
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 18,
    color: '#1A1B1E99',
  },
  commentFormButtonContainer: { marginLeft: 'auto', marginTop: 8 },
  commentFormButton: {
    borderRadius: 8,
    backgroundColor: '#F0F1F6',
    paddingVertical: 8,
    paddingHorizontal: 24,
  },
  commentFormButtonCancelText: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    textAlign: 'center',
    color: '#1A1B1E',
  },
  commentFormButtonSubmitText: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    color: '#1A1B1E4D',
  },
});
