/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateContentItemFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    description?: string | null;
    author?: UserPointerInput | null;
    content?: ContentPointerInput | null;
    noteData?: Array<unknown | null> | null;
    tags?: Array<unknown | null> | null;
    published?: boolean | null;
    md?: string | null;
    analytics?: AnalyticsContentItemPointerInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type ContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentFieldsInput | null;
};
export type CreateContentFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    thumbURL?: string | null;
    type?: string | null;
    link?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    rawMetadata?: unknown | null;
    analytics?: AnalyticsContentPointerInput | null;
};
export type AnalyticsContentPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentFieldsInput | null;
};
export type CreateAnalyticsContentFieldsInput = {
    ACL?: ACLInput | null;
    content?: ContentPointerInput | null;
    viewCount?: number | null;
    contentItemCount?: number | null;
    emojiCountMap?: unknown | null;
    contentItemAuthors?: Array<unknown | null> | null;
    emojiTotalCount?: number | null;
    commentCount?: number | null;
};
export type AnalyticsContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsContentItemFieldsInput | null;
};
export type CreateAnalyticsContentItemFieldsInput = {
    ACL?: ACLInput | null;
    contentItem?: ContentItemPointerInput | null;
    viewCount?: number | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    commentCount?: number | null;
};
export type ContentItemPointerInput = {
    link?: string | null;
    createAndLink?: CreateContentItemFieldsInput | null;
};
export type NewNoteScreenCreateContentItemMutationVariables = {
    inputFields: CreateContentItemFieldsInput;
};
export type NewNoteScreenCreateContentItemMutationResponse = {
    readonly createContentItem: {
        readonly clientMutationId: string | null;
        readonly contentItem: {
            readonly id: string;
            readonly objectId: string;
        };
    } | null;
};
export type NewNoteScreenCreateContentItemMutation = {
    readonly response: NewNoteScreenCreateContentItemMutationResponse;
    readonly variables: NewNoteScreenCreateContentItemMutationVariables;
};



/*
mutation NewNoteScreenCreateContentItemMutation(
  $inputFields: CreateContentItemFieldsInput!
) {
  createContentItem(input: {fields: $inputFields}) {
    clientMutationId
    contentItem {
      id
      objectId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "inputFields"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "fields",
            "variableName": "inputFields"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreateContentItemPayload",
    "kind": "LinkedField",
    "name": "createContentItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentItem",
        "kind": "LinkedField",
        "name": "contentItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewNoteScreenCreateContentItemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewNoteScreenCreateContentItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f34f6e61f6e009cca7810a580ee91c6c",
    "id": null,
    "metadata": {},
    "name": "NewNoteScreenCreateContentItemMutation",
    "operationKind": "mutation",
    "text": "mutation NewNoteScreenCreateContentItemMutation(\n  $inputFields: CreateContentItemFieldsInput!\n) {\n  createContentItem(input: {fields: $inputFields}) {\n    clientMutationId\n    contentItem {\n      id\n      objectId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '80b14ce12a60f0caed7c292311e706e7';
export default node;
