import React, { ReactElement } from 'react';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import { ImageURISource, View, Text, StyleSheet } from 'react-native';
import LFLink from './LFLink';
import PreviewImage from './PreviewImage';
import LFText from './typo/LFText';

export interface NotificationItemProp {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  footer: string | ReactElement;
  imageURL?: string;
  isNew: boolean;
}

const NotificationItem: React.FC<NotificationItemProp> = ({
  title,
  subtitle,
  isNew,
  imageURL,
  footer,
}) => {
  return (
    <Flex
      direction="column"
      align="stretch"
      style={[
        styles.container,
        isNew ? { backgroundColor: '#f5f8fe' } : undefined,
      ]}
    >
      <Flex justify="between" align="start" direction="row">
        <LFText numberOfLines={2} style={styles.title}>
          {title}
        </LFText>

        {imageURL && (
          <PreviewImage
            accessibilityLabel=""
            resizeMode="cover"
            source={{ uri: imageURL }}
            style={styles.thumbnail}
          />
        )}
      </Flex>
      <WhiteSpace />
      {subtitle}
      {/* <Flex>
        <LFText style={styles.message}>{subtitle}</LFText>
      </Flex> */}
      <WhiteSpace />
      <LFText style={styles.time}>{footer}</LFText>
    </Flex>
  );
};
const styles = StyleSheet.create({
  container: { padding: 16, backgroundColor: '#FFF' },
  title: {
    fontSize: 16,
    color: '#26282c',
    lineHeight: 20,
    fontWeight: '600',
  },
  message: {
    fontSize: 14,
    // lineHeight: 18,
    // letterSpacing: 0.4,
  },
  thumbnail: { marginLeft: 16, width: 40, height: 40, borderRadius: 4 },
  time: {
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: 0.4,
    color: 'rgb(123,124,127)',
  },
});
export default NotificationItem;
