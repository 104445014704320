/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreatePathFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    contentItemList?: Array<unknown | null> | null;
    author?: UserPointerInput | null;
    learnCount?: number | null;
    published?: boolean | null;
    description?: string | null;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    analytics?: AnalyticsPathPointerInput | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    isSeries?: boolean | null;
    draft?: PathDraftPointerInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type InstructionCardRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateInstructionCardFieldsInput> | null;
};
export type CreateInstructionCardFieldsInput = {
    ACL?: ACLInput | null;
    description?: string | null;
    author?: UserPointerInput | null;
    path?: unknown | null;
    sources?: Array<unknown | null> | null;
    seq?: number | null;
    optional?: boolean | null;
    pathDraft?: PathDraftPointerInput | null;
};
export type PathDraftPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathDraftFieldsInput | null;
};
export type CreatePathDraftFieldsInput = {
    ACL?: ACLInput | null;
    original: PathPointerInput;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    description?: string | null;
    isSeries?: boolean | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    progressing?: boolean | null;
};
export type PathPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathFieldsInput | null;
};
export type MembershipPointerInput = {
    link?: string | null;
    createAndLink?: CreateMembershipFieldsInput | null;
};
export type CreateMembershipFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    author?: UserPointerInput | null;
    level?: string | null;
    role?: RolePointerInput | null;
};
export type RolePointerInput = {
    link?: string | null;
    createAndLink?: CreateRoleFieldsInput | null;
};
export type CreateRoleFieldsInput = {
    ACL?: ACLInput | null;
    name?: string | null;
    users?: UserRelationInput | null;
    roles?: RoleRelationInput | null;
};
export type UserRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateUserFieldsInput> | null;
};
export type RoleRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateRoleFieldsInput> | null;
};
export type AnalyticsPathPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsPathFieldsInput | null;
};
export type CreateAnalyticsPathFieldsInput = {
    ACL?: ACLInput | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    path?: PathPointerInput | null;
    viewCount?: number | null;
    commentCount?: number | null;
};
export type PathEditorScreenCreatePathMutationVariables = {
    fields: CreatePathFieldsInput;
    clientMutationId: string;
};
export type PathEditorScreenCreatePathMutationResponse = {
    readonly createPath: {
        readonly clientMutationId: string | null;
        readonly path: {
            readonly id: string;
            readonly objectId: string;
            readonly title: string | null;
            readonly learnCount: number | null;
            readonly description: string | null;
            readonly author: {
                readonly id: string;
                readonly objectId: string;
                readonly name: string | null;
            } | null;
            readonly tags: ReadonlyArray<{
                readonly value?: unknown;
            } | null> | null;
        };
    } | null;
};
export type PathEditorScreenCreatePathMutation = {
    readonly response: PathEditorScreenCreatePathMutationResponse;
    readonly variables: PathEditorScreenCreatePathMutationVariables;
};



/*
mutation PathEditorScreenCreatePathMutation(
  $fields: CreatePathFieldsInput!
  $clientMutationId: String!
) {
  createPath(input: {fields: $fields, clientMutationId: $clientMutationId}) {
    clientMutationId
    path {
      id
      objectId
      title
      learnCount
      description
      author {
        id
        objectId
        name
      }
      tags {
        __typename
        ... on Element {
          value
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientMutationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fields"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "clientMutationId",
        "variableName": "clientMutationId"
      },
      {
        "kind": "Variable",
        "name": "fields",
        "variableName": "fields"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "learnCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PathEditorScreenCreatePathMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePathPayload",
        "kind": "LinkedField",
        "name": "createPath",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Path",
            "kind": "LinkedField",
            "name": "path",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PathEditorScreenCreatePathMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePathPayload",
        "kind": "LinkedField",
        "name": "createPath",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Path",
            "kind": "LinkedField",
            "name": "path",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bd45a580fe69fabdbb3f48677e87df8",
    "id": null,
    "metadata": {},
    "name": "PathEditorScreenCreatePathMutation",
    "operationKind": "mutation",
    "text": "mutation PathEditorScreenCreatePathMutation(\n  $fields: CreatePathFieldsInput!\n  $clientMutationId: String!\n) {\n  createPath(input: {fields: $fields, clientMutationId: $clientMutationId}) {\n    clientMutationId\n    path {\n      id\n      objectId\n      title\n      learnCount\n      description\n      author {\n        id\n        objectId\n        name\n      }\n      tags {\n        __typename\n        ... on Element {\n          value\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f908e54d40b3c81e0c2cbc74042d25ac';
export default node;
