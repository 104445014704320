/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MembershipPaymentItemViewFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly paymentResult: unknown | null;
    readonly status: string | null;
    readonly membershipPricing: {
        readonly id: string;
        readonly objectId: string;
        readonly type: string | null;
        readonly price: number | null;
        readonly duration: number | null;
    } | null;
    readonly membershipStatus: {
        readonly id: string;
        readonly membership: {
            readonly id: string;
            readonly title: string | null;
        } | null;
        readonly expiredDate: unknown | null;
        readonly status: string | null;
    } | null;
    readonly " $refType": "MembershipPaymentItemViewFragment";
};
export type MembershipPaymentItemViewFragment$data = MembershipPaymentItemViewFragment;
export type MembershipPaymentItemViewFragment$key = {
    readonly " $data"?: MembershipPaymentItemViewFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MembershipPaymentItemViewFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipPaymentItemViewFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentResult",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipPricing",
      "kind": "LinkedField",
      "name": "membershipPricing",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MembershipStatus",
      "kind": "LinkedField",
      "name": "membershipStatus",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Membership",
          "kind": "LinkedField",
          "name": "membership",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expiredDate",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "MembershipPayment",
  "abstractKey": null
};
})();
(node as any).hash = '760185777a69f358456daf37cf13e32f';
export default node;
