import { Flex } from '@ant-design/react-native';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useMobileDimensions } from '../hooks/useMobileDimensions';
import Parse from 'parse';
// import NavBar, { HistoryBackButton } from '../components/NavBar';
import { graphql, useMutation } from 'react-relay';
import { CloudFunctionCreateContentIfNotExist } from '../helper/types';
import { NewNoteScreenCreateContentItemMutation } from '../__generated__/NewNoteScreenCreateContentItemMutation.graphql';
import LFText from '../components/typo/LFText';
import NewNoteOnBoardingModal from '../components/NewNoteOnBoardingModal';
import NavBar, { NavTextButton } from '../components/NavBar2';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from '../components/LFWhiteSpace';
import LFLink from '../components/LFLink';
import LFMetaTags from '../components/LFMetaTags';
import LFTextInput from '../components/LFTextInput';
import TipBox from '../components/TipBox';
import { useAuth } from '../hooks/auth';
import { LoginScreenAccessAction } from './LoginScreen';

const useIsValidUrl = (url: string) => {
  const isValidUrl = useMemo(() => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  }, [url]);
  return isValidUrl;
};

const NewNoteScreen = () => {
  const [visibleOnBoardingModal, setVisibleOnBoardingModal] = useState(true);
  // player 관련

  //사이즈 레이아웃 관련
  const mobileSize = useMobileDimensions();
  const wrapperPadding = 0;
  const previewHeight = (mobileSize.width / 16) * 9 - wrapperPadding;

  // router 관련
  const history = useHistory();
  const { pathname, search } = useLocation();

  const params = new URLSearchParams(search);
  const urlFromQueryParam = params.get('url');
  const [typedUrl, setTypedUrl] = useState(urlFromQueryParam || '');

  const { user } = useAuth();

  useLayoutEffect(() => {
    if (urlFromQueryParam) {
      onSubmitUrlEdit();
    }
  }, [urlFromQueryParam]);

  const [commitCreateContentItem, isCreateContentItemInFight] =
    useMutation<NewNoteScreenCreateContentItemMutation>(graphql`
      mutation NewNoteScreenCreateContentItemMutation(
        $inputFields: CreateContentItemFieldsInput!
      ) {
        createContentItem(input: { fields: $inputFields }) {
          clientMutationId
          contentItem {
            id
            objectId
          }
        }
      }
    `);

  const isValidUrl = useIsValidUrl(typedUrl);
  const [isInVAlidUrlResponse, setIsInVAlidUrlResponse] = useState(false);

  const [isSubmitInFlight, setIsSubmitInFlight] = useState(false);
  const onSubmitUrlEdit = () => {
    if (!isValidUrl) return;
    // console.log(typedUrl);

    setIsSubmitInFlight(true);
    setIsInVAlidUrlResponse(false);
    Parse.Cloud.run<CloudFunctionCreateContentIfNotExist>(
      'createContentIfNotExist',
      {
        link: typedUrl,
      }
    )
      .then((content) => {
        console.log(content);
        const contentTags = content?.tags || [];
        commitCreateContentItem({
          variables: {
            inputFields: {
              title: content.title,
              content: {
                link: content.objectId,
              },
              tags: _.uniq([...contentTags, '전체']),
            },
          },
          onCompleted: (response) => {
            const newContentItemId =
              response?.createContentItem?.contentItem?.objectId;
            if (newContentItemId) {
              history.push({
                pathname: `/note/${content.objectId}/${newContentItemId}`,
                search: content.type === 'VIDEO' ? '' : 'mode=edit',
              });
            }
          },
          onError: (error) => {
            setIsSubmitInFlight(false);
          },
        });
      })
      .catch((e) => {
        // console.log('#error', JSON.stringify(e));
        // console.log('#error', e.message);
        if (e?.message?.indexOf('Invalid URL') > -1) {
          setIsInVAlidUrlResponse(true);
        }
        setIsSubmitInFlight(false);
      });
  };

  return (
    <View style={styles.container}>
      <LFMetaTags
        title={'학습노트 만들기 | Learnfit'}
        description={'유튜브 영상으로 만드는 나만의 학습노트'}
      />
      <NavBar
        sticky
        title="학습노트 만들기"
        right={
          <NavTextButton
            onPress={() =>
              !user
                ? history.push('/login', {
                    from: {
                      search: 'url=' + typedUrl,
                      pathname,
                    },
                    accessAction:
                      'submitURLToCreateNote' as LoginScreenAccessAction,
                  })
                : onSubmitUrlEdit()
            }
            textStyle={{ fontSize: 18 }}
            disabled={!(isValidUrl && typedUrl.length)}
            isLoading={isSubmitInFlight}
          >
            만들기
          </NavTextButton>
        }
      />
      <Flex
        style={{ paddingHorizontal: 24 }}
        direction="column"
        align="stretch"
      >
        <LFWhiteSpace size="xxxl" />
        <View>
          <LFText style={styles.title}>
            학습노트를 남길{'\n'}
            <LFText style={{ color: colors.PRIMARY_100 }}>URL</LFText>을
            넣어주세요.
          </LFText>
        </View>
        <LFWhiteSpace size="xxxl" />
        <LFTextInput
          placeholder="URL 붙여넣기"
          value={typedUrl}
          onChangeText={(t) => setTypedUrl(t)}
          editable={!isSubmitInFlight}
          onSubmitEditing={onSubmitUrlEdit}
          errorMessage={
            (!isValidUrl && typedUrl.length) || isInVAlidUrlResponse
              ? '잘못된 링크입니다. 입력하신 링크를 확인해주세요.'
              : null
          }
        />
        {visibleOnBoardingModal === false ? (
          <>
            <LFWhiteSpace size={'sm'} />
            <TipBox justify="between">
              <Flex direction="row">
                <LFText style={{ fontSize: 24, alignSelf: 'flex-start' }}>
                  👉
                </LFText>
                <LFWhiteSpace direction="row" size={'xs'} />
                <LFText style={styles.tipText}>
                  어떻게 하는지 잘 모르겠다면?{'\n'}
                  <LFText style={{ fontWeight: 'bold' }}>예시용 URL</LFText>을
                  넣어보세요!
                </LFText>
                <LFLink
                  style={{ marginLeft: 16, alignSelf: 'flex-end' }}
                  onPress={() => {
                    setTypedUrl('https://youtu.be/wwJMPhSUjgA');
                  }}
                >
                  <LFText style={styles.tipActionText}>URL 넣기</LFText>
                </LFLink>
              </Flex>
              {/* <LFLink
                onPress={() => {}}
                style={{ alignSelf: 'flex-start', padding: 16, margin: -16 }}
              >
                <Image
                  style={{ width: 16, height: 16 }}
                  source={require('../assets/images/icon24Close@3x.png')}
                />
              </LFLink> */}
            </TipBox>
          </>
        ) : null}
      </Flex>
      {/* {visibleOnBoardingModal === false ? ( */}
      <NewNoteOnBoardingModal
        noAgainId="new-note-onboarding-v1"
        visible={visibleOnBoardingModal}
        onClose={() => {
          setVisibleOnBoardingModal(false);
        }}
      />
    </View>
  );
};

export default NewNoteScreen;

const styles = StyleSheet.create({
  tipText: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  tipActionText: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.PRIMARY_100,
    textDecorationLine: 'underline',
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 40,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
  linkInput: {
    flex: 1,
    height: 33,
    marginRight: 10,
    paddingVertical: 12,
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  hashTagText: {
    marginRight: 3,
    width: '100%',
  },
  scrollContainerStyle: {
    paddingBottom: 20,
    // paddingHorizontal: 16
  },
  inputWrap: {
    borderColor: '#ccc',
    borderTopWidth: 1,
    // flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 16,
  },
  inputInnerWrap: { height: 40, flexDirection: 'row' },
  textInput: {
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 5,
    height: '100%',
    flex: 1,
    paddingHorizontal: 5,
  },
  noteInputButton: {
    marginLeft: 10,
    height: '100%',
  },
  noteTimeBtn: {
    height: 30,
    padding: 5,
    fontSize: 14,
  },
});
