import { Flex, WingBlank } from '@ant-design/react-native';
import { Ionicons, Entypo } from '@expo/vector-icons';
import _ from 'lodash';
import React from 'react';
import {
  StyleSheet,
  Text,
  Touchable,
  View,
  Image,
  ViewStyle,
  TextStyle,
  ActivityIndicator,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useHistory } from 'react-router';
import { ReactElement } from 'react-router/node_modules/@types/react';
import { useAuth } from '../hooks/auth';
import LFLink, { LFLinkTo } from './LFLink';

import NotificationNavButton from './NotificationNavButton';
import LFText from './typo/LFText';
import { useActionSheet } from '@expo/react-native-action-sheet';
import NotificationNavButtonWithSuspense from './NotificationNavButton';
import { resizedImageURL } from '../helper/resizedImageURL';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import Constants from 'expo-constants';
import {
  isGoogleLoginResponse,
  LoginScreenAccessAction,
} from '../screens/LoginScreen';
import { useLocation } from 'react-router-dom';
import { AfterAction } from '../screens/NotePlayer/NotePlayerScreen';
import LFWhiteSpace from './LFWhiteSpace';
import { colors } from '../constants/styleGuide';

// NavBar의 버튼은 내부에 상하좌우 8의 padding을 갖는다.
const navButtonInnerPadding = 8;
interface NavBarProps {
  style?: ViewStyle;
  title?: string | ReactElement;
  right?: ReactElement | null;
  left?: ReactElement;
  homeMode?: boolean;
  sticky?: boolean;
}
const NavBar: React.FC<NavBarProps> = ({
  style,
  title = 'LearnFit',
  right,
  left,
  homeMode = false,
  sticky = false,
}) => {
  const history = useHistory();
  const { user, loginWithGoogle } = useAuth();
  const paddingHorizontal = homeMode ? 24 : 16;
  return (
    <View
      style={[
        styles.container,
        style,
        {
          height: homeMode ? 64 : 48,
        },
        //@ts-ignore
        sticky ? { position: 'sticky', top: 0 } : null,
      ]}
    >
      <Flex
        direction="row"
        style={{ paddingHorizontal: paddingHorizontal - navButtonInnerPadding }}
      >
        {_.isUndefined(left) ? (
          homeMode ? (
            <HomeLogoButton />
          ) : (
            <>
              <HistoryBackButton />
              <HomeButton />
            </>
          )
        ) : (
          left
        )}
      </Flex>
      <View style={styles.centerWrap}>
        {typeof title === 'string' ? (
          <LFText style={styles.title}>{title}</LFText>
        ) : (
          { title }
        )}
      </View>
      <Flex
        direction="row"
        justify="end"
        style={{ paddingHorizontal: paddingHorizontal - navButtonInnerPadding }}
      >
        {right}
      </Flex>
    </View>
  );
};

export const SettingNavButton: React.FC<MoreButtonProps> = (props) => {
  return (
    <MoreButton
      icon={
        <Image
          style={{ width: 24, height: 24 }}
          source={require(`../assets/images/icon24Setting@3x.png`)}
        />
      }
      {...props}
    />
  );
};

export const CreateNavButton: React.FC = () => {
  const history = useHistory();
  const { showActionSheetWithOptions } = useActionSheet();
  return (
    <LFLink
      style={{ padding: 8 }}
      onPress={() => {
        showActionSheetWithOptions(
          {
            options: ['학습노트 만들기', '러닝패스 만들기', '취소'],
            cancelButtonIndex: 2,
          },
          (idx) => {
            if (idx === 0) {
              history.push('/new-note');
            } else if (idx === 1) {
              history.push('/path-edit');
            }
          }
        );
      }}
    >
      <Image
        accessibilityLabel="작성하기"
        style={{ width: 24, height: 24 }}
        source={require('../assets/images/nav/createContent@3x.png')}
      />
    </LFLink>
  );
};

export const SearchNavButton: React.FC<{ color?: 'black' | 'white' }> = ({
  color = 'white',
}) => {
  const history = useHistory();
  return (
    <LFLink
      testID="header-search:NavBar"
      onPress={() => {
        history.push('/search');
      }}
      style={{ padding: 8 }}
    >
      <Image
        accessibilityLabel="콘텐츠 검색하기"
        source={
          color === 'white'
            ? require('../assets/images/icon@3x.png')
            : require('../assets/images/lensIcon@3x.png')
        }
        style={{ width: 24, height: 24 }}
      />
    </LFLink>
  );
};
export const UserProfileNavButton: React.FC = () => {
  const { user, loginWithGoogle } = useAuth();
  const history = useHistory();
  const location = useLocation<{
    afterAction: AfterAction;
    from: any;
  }>();

  const afterAction = location?.state?.afterAction as AfterAction;
  const from = location?.state?.from;

  const clientId = Constants.manifest?.extra?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
  const responseGoogle = (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if (!isGoogleLoginResponse(res)) return;
    const {
      googleId,
      tokenId,
      profileObj: { email, name, imageUrl },
    } = res;
    if (res.googleId && res.tokenId) {
      loginWithGoogle({
        googleId,
        tokenId,
        email,
        name,
        originProfileURL: imageUrl,
      }).then(() => {
        history.replace(from || { pathname: '/' }, {
          afterAction,
        });
      });
    }
  };
  return user ? (
    <>
      <LFLink
        style={{ padding: 8 }}
        onPress={() => {
          history.push('/me');
        }}
      >
        <Image
          accessibilityLabel={`${user?.name || ''}의 프로필 이미지`}
          style={styles.userImage}
          source={
            user?.profileImage || user?.originProfileURL
              ? resizedImageURL(
                  user.profileImage?.url,
                  72,
                  72,
                  user.originProfileURL
                )
              : require('../assets/images/userProfile.png')
          }
        />
      </LFLink>
    </>
  ) : (
    <LFLink
      testID="header-login:NavBar2"
      style={{ padding: 8 }}
      onPress={() =>
        history.push({
          pathname: '/login',
          state: {
            accessAction: 'clickHomeLoginButton' as LoginScreenAccessAction,
          },
        })
      }
    >
      <LFText
        style={{
          fontWeight: '600',
          fontSize: 14,
          lineHeight: 24,
          color: colors.TEXT_100,
        }}
      >
        로그인
      </LFText>
    </LFLink>
  );
};

export const NavTextButton: React.FC<{
  onPress: () => void;
  to?: LFLinkTo;
  testId?: string;
  textStyle?: TextStyle;
  isLoading?: boolean;
  disabled?: boolean;
}> = ({ children, onPress, testId, textStyle, isLoading, to, disabled }) => {
  if (_.isString(children)) {
    children = <LFText>{children}</LFText>;
  }
  return (
    <LFLink
      style={{ padding: 8 }}
      disabled={isLoading}
      onPress={onPress}
      to={to}
    >
      {isLoading ? (
        <ActivityIndicator color={'gray'} />
      ) : (
        <LFText
          testID={testId}
          style={[
            {
              fontWeight: '600',
              fontSize: 14,
              lineHeight: 24,
              color: disabled ? colors.TEXT_30 : colors.PRIMARY_100,
            },
            textStyle,
          ]}
        >
          {children}
        </LFText>
      )}
    </LFLink>
  );
};
interface HistoryBackButtonProps {
  onPress?: () => void;
  icon?: ReactElement;
  style?: ViewStyle;
}
export const HistoryBackButton: React.FC<HistoryBackButtonProps> = ({
  icon,
  onPress,
  style,
}) => {
  const history = useHistory();
  return (
    <LFLink
      style={[style, { padding: 8 }]}
      onPress={onPress ? onPress : () => history.goBack()}
    >
      {icon ? (
        icon
      ) : (
        <Image
          accessibilityLabel="뒤로 가기"
          style={{ width: 24, height: 24 }}
          source={require('../assets/images/nav/arrow24@3x.png')}
        />
      )}
    </LFLink>
  );
};

export const HomeButton: React.FC<{
  onPress?: () => void;
}> = ({ onPress }) => {
  const history = useHistory();
  return (
    <LFLink
      testID="header-home-icon:NavBar2"
      style={{ padding: 8 }}
      to={'/'}
      onPress={onPress}
    >
      <Image
        accessibilityLabel="홈으로 이동"
        style={{ width: 24, height: 24 }}
        source={require('../assets/images/nav/icon24Home@3x.png')}
      />
    </LFLink>
  );
};

export const HomeLogoButton: React.FC = () => {
  const history = useHistory();

  return (
    <LFLink style={{ padding: 8 }} to={'/'}>
      <Image
        accessibilityLabel="런핏 브랜드 로고"
        style={{ width: 112, height: 24 }}
        source={require('../assets/images/logoCut@3x.png')}
      />
    </LFLink>
  );
};

interface MoreButtonProps {
  options: {
    title: string;
    onPress: () => void;
    destructive?: boolean;
  }[];
  isLoading?: boolean;
  destructiveButtonIndex?: number | number[];
  icon?: React.ReactElement;
  onPress?: () => void;
  testID?: string;
}
export const MoreButton: React.FC<MoreButtonProps> = ({
  icon,
  onPress,
  destructiveButtonIndex,
  options,
  isLoading,
  testID,
}) => {
  const { showActionSheetWithOptions } = useActionSheet();
  return (
    <LFLink
      testID={testID}
      style={{ padding: 8 }}
      onPress={() => {
        onPress
          ? onPress()
          : showActionSheetWithOptions(
              {
                options: [..._.map(options, (i) => i.title), '취소'],
                destructiveButtonIndex: destructiveButtonIndex,
                cancelButtonIndex: options.length,
              },
              (idx) => {
                _.isFunction(options[idx]?.onPress) && options[idx].onPress();
              }
            );
      }}
      disabled={isLoading}
    >
      {isLoading ? (
        <ActivityIndicator color={'gray'} />
      ) : icon ? (
        icon
      ) : (
        <Image
          style={{ width: 24, height: 24 }}
          source={require('../assets/images/nav/icon24More@3x.png')}
        />
      )}
    </LFLink>
  );
};

export default NavBar;

const styles = StyleSheet.create({
  container: {
    // width: '100%',
    zIndex: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.BG_WHITE,
  },
  title: {
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'center',
    color: '#04021d',
  },
  centerWrap: {
    flex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1,
  },
  rightWrap: {
    // flex: 1,
    minWidth: '20%',
    paddingRight: 8,
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  userImage: {
    width: 24,
    height: 24,
    margin: 'auto',
    borderRadius: 50,
    backgroundColor: colors.SECONDARY_WHITE,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: colors.BORDER_10,
  },
});
