import { Image, StyleSheet, View, ViewStyle } from 'react-native';
import React, { useState, useRef, useEffect } from 'react';
import { Flex } from '@ant-design/react-native';
import { colors } from '../../constants/styleGuide';
import LFLink from '../../components/LFLink';
import LFText from '../../components/typo/LFText';
import ScrollContainer from 'react-indiana-drag-scroll';
import LinearGradient from 'react-native-linear-gradient';
import ToggleButton from '../../components/ToggleButton';

const TagSelector: React.FC<{
  options: string[];
  onTagPress: (tag: string) => void;
  style?: ViewStyle;
  selected?: string | null;
}> = ({ options, onTagPress, style, selected }) => {
  const [tagsExtended, setTagsExtended] = useState(false);
  const onTagsExtendOrShrink = () => setTagsExtended((prev) => !prev);
  const activeTagStyle = {
    backgroundColor: colors.PRIMARY_10,
    color: colors.PRIMARY_100,
  };
  const normalTagStyle = {
    backgroundColor: colors.BG_2,
    color: colors.TEXT_60,
  };
  const isSlidingRef = useRef(false);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLElement | null>(null);
  const [activeExpandOrShrinkButton, setActiveExpandOrShrinkButton] =
    useState(false);
  useEffect(() => {
    setActiveExpandOrShrinkButton(
      (scrollContainerRef.current?.scrollWidth || 0) > containerWidth
        ? true
        : false
    );
  }, [options, containerWidth, setActiveExpandOrShrinkButton]);

  return (
    <View
      style={{ flexDirection: 'row', justifyContent: 'flex-start' }}
      onLayout={(e) => setContainerWidth(e.nativeEvent.layout.width)}
    >
      <ScrollContainer
        innerRef={scrollContainerRef}
        style={{ margin: -2 }}
        onStartScroll={() => {
          isSlidingRef.current = true;
        }}
        onEndScroll={() => {
          setTimeout(() => {
            isSlidingRef.current = false;
          }, 0);
        }}
      >
        <Flex
          style={[
            styles.container,
            tagsExtended ? { flexWrap: 'wrap' } : null,
            style,
          ]}
        >
          {options.map((option) => {
            const active = option === selected;
            return (
              <LFLink
                style={[
                  styles.tag,
                  {
                    backgroundColor: active
                      ? activeTagStyle.backgroundColor
                      : normalTagStyle.backgroundColor,
                  },
                ]}
                onPress={() => !isSlidingRef.current && onTagPress(option)}
                key={option}
              >
                <LFText
                  style={[
                    styles.tagText,
                    {
                      color: active
                        ? activeTagStyle.color
                        : normalTagStyle.color,
                    },
                  ]}
                >
                  {option}
                </LFText>
              </LFLink>
            );
          })}
        </Flex>
      </ScrollContainer>
      {activeExpandOrShrinkButton && (
        <View style={{ position: 'relative', width: 20, height: 40 }}>
          <LinearGradient
            style={styles.linearGradient}
            colors={['rgba(0, 0, 0, 0)', 'rgba(255, 255, 255, 1)']}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          />
          <ToggleButton
            isActive={tagsExtended}
            onPress={onTagsExtendOrShrink}
          />
        </View>
      )}
    </View>
  );
};

export default React.memo(TagSelector);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tag: {
    margin: 2,
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 100,
  },
  tagText: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  extendButton: {
    padding: 7,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.BG_WHITE,

    shadowColor: 'rgba(66, 73, 89, 0.15)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: colors.BORDER_10,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  linearGradient: {
    position: 'absolute',
    top: 0,
    right: 20,
    width: 52,
    height: 40,
  },
});
