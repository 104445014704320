import { StyleSheet, View, ViewStyle } from 'react-native';
import React, {
  useEffect,
  ReactNode,
  useRef,
  useState,
  useCallback,
} from 'react';
import { AnalyticsPathOrder } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { AnalyticsPathWhereInput } from '../__generated__/BaseHorizontalPathListQuery.graphql';
import _ from 'lodash';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Flex } from '@ant-design/react-native';
import { usePrevious } from 'ahooks';
import { PathItemView2_Fragment$key } from '../__generated__/PathItemView2_Fragment.graphql';
import { BaseHorizontalPathList2Query } from '../__generated__/BaseHorizontalPathList2Query.graphql';
import PathItemView2 from './PathItemView2';
import PaginationButton from './PaginationButton';
import { useDeviceType } from '../hooks/deviceType';
import LFWhiteSpace from './LFWhiteSpace';
import { useAuth } from '../hooks/auth';
import { BaseHorizontalPathList2MyBookMarkReactionsQuery } from '../__generated__/BaseHorizontalPathList2MyBookMarkReactionsQuery.graphql';

interface IBaseHorizontalPathList2 {
  where: AnalyticsPathWhereInput; // 데이터 조건
  order: AnalyticsPathOrder[]; // 데이터 순서
  first: number; // 데이터 갯수
  onChangeCount?: (count: number) => void; // fetch 후 count를 외부로 전달
  gap?: number; // 각 아이템의 간격
  style?: React.CSSProperties; // 리스트에 대한 style
  itemStyle?: ViewStyle; // 각 아이템에 대한 style
  paginationButton?: boolean; // 페이지네이션 버튼 노출
}

const BaseHorizontalPathList2: React.FC<IBaseHorizontalPathList2> = ({
  where,
  order,
  first,
  onChangeCount,
  gap = 0,
  style,
  itemStyle,
  paginationButton = true,
}) => {
  const { user } = useAuth();
  const { analyticsPaths } = useLazyLoadQuery<BaseHorizontalPathList2Query>(
    graphql`
      query BaseHorizontalPathList2Query(
        $where: AnalyticsPathWhereInput
        $order: [AnalyticsPathOrder!]
        $first: Int
      ) {
        analyticsPaths(where: $where, order: $order, first: $first) {
          count
          edges {
            node {
              id
              objectId
              path {
                id
                objectId
                ...PathItemView2_Fragment
              }
            }
          }
        }
      }
    `,
    { where, first, order }
  );

  useEffect(() => {
    _.isFunction(onChangeCount) && onChangeCount(analyticsPaths.count);
  }, [analyticsPaths.count]);

  const pathNodes = analyticsPaths.edges?.map((edge) => edge?.node?.path) || [];
  const gapAfterContainer = style?.paddingRight || style?.padding;
  const scrollContainerRef = useRef<HTMLElement | null>(null);
  const [isSliding, setIsSliding] = useState(false);
  const previousIsSliding = usePrevious(isSliding);

  const [scrollEnabled, setScrollEnabled] = useState(true);
  const deviceType = useDeviceType();

  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
  });

  const refetch = useCallback(() => {
    setQueryArgs((prev) => ({
      options: {
        fetchKey: (prev?.options.fetchKey ?? 0) + 1,
      },
    }));
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  const myBookMarkReactionsWhere = {
    author: {
      have: {
        objectId: {
          equalTo: user?.objectId || '',
        },
      },
    },
    type: {
      equalTo: 'bookmark',
    },
    targetClassName: {
      equalTo: 'Path',
    },
  };
  const { myBookMarkReactions } =
    useLazyLoadQuery<BaseHorizontalPathList2MyBookMarkReactionsQuery>(
      graphql`
        query BaseHorizontalPathList2MyBookMarkReactionsQuery(
          $where: ReactionWhereInput!
          $skip: Boolean!
        ) {
          myBookMarkReactions: reactions(where: $where, first: 1000)
            @skip(if: $skip) {
            edges {
              node {
                id
                objectId
                path {
                  id
                  objectId
                }
              }
            }
          }
        }
      `,
      { where: myBookMarkReactionsWhere, skip: !Boolean(user?.objectId) },
      {
        fetchPolicy: 'store-and-network',
        fetchKey: queryArgs.options.fetchKey,
      }
    );
  return (
    <>
      {paginationButton && deviceType === 'PC' && (
        <View>
          <PaginationButton
            isPath
            numberOfPath={pathNodes.length}
            scrollRef={scrollContainerRef}
          />
          <LFWhiteSpace size={'md'} />
        </View>
      )}
      <ScrollContainer
        horizontal={scrollEnabled}
        nativeMobileScroll
        innerRef={scrollContainerRef}
        onStartScroll={() => {
          setIsSliding(true);
        }}
        onEndScroll={() => {
          setTimeout(() => {
            setIsSliding(false);
          }, 0);
        }}
        style={style}
      >
        <Flex align="stretch">
          {pathNodes.map((pathNode, index) => {
            const bookMarkReaction = _.find(
              myBookMarkReactions?.edges,
              (edge) => edge?.node?.path?.objectId === pathNode?.objectId
            );
            const scraped = {
              status: Boolean(bookMarkReaction),
              reactionId: bookMarkReaction?.node?.objectId,
            };
            return (
              <View
                key={pathNode?.objectId}
                style={[
                  itemStyle,
                  { marginRight: pathNodes.length === index + 1 ? 0 : gap },
                ]}
              >
                <PathItemView2
                  scraped={scraped}
                  onScrape={refetch}
                  pathFragment={pathNode || null}
                  onScrollStart={() => {
                    setScrollEnabled(false);
                  }}
                  onScrollEnd={() => {
                    setScrollEnabled(true);
                  }}
                />
              </View>
            );
          })}
          {/* 
          스크롤 가능한 UI 컨테이너에서 끝쪽 margin, padding은 스크롤 컨테이너 내부에서 적용되지 않는 점을 알게 되어
          별도로 element를 두어 paddingRight, marginRight의 역할을 대신하도록 조치했습니다.
          링크: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout 
        */}
          {gapAfterContainer && (
            <Flex style={{ width: Number(gapAfterContainer), height: 1 }} />
          )}
        </Flex>
      </ScrollContainer>
    </>
  );
};

export default BaseHorizontalPathList2;

const styles = StyleSheet.create({});
