import { useLocalStorageState } from 'ahooks';
import { useEffect, useRef } from 'react';

function useLocalStorageUpdateOnlyUnmount<T>({
  init,
  key,
}: {
  init: T;
  key: string;
}) {
  const [state, setState] = useLocalStorageState<T>(key, init);
  const trackingValueRef = useRef<T>(state);

  const setter = (value: T) => {
    trackingValueRef.current = value;
  };
  useEffect(() => {
    return () => {
      setState(trackingValueRef.current);
    };
  }, []);

  return { state, setState: setter };
}

export default useLocalStorageUpdateOnlyUnmount;
