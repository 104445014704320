/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MembershipPricingWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    price?: NumberWhereInput | null;
    duration?: NumberWhereInput | null;
    author?: UserRelationWhereInput | null;
    disabled?: BooleanWhereInput | null;
    type?: StringWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<MembershipPricingWhereInput> | null;
    AND?: Array<MembershipPricingWhereInput> | null;
    NOR?: Array<MembershipPricingWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type MembershipRelationWhereInput = {
    have?: MembershipWhereInput | null;
    haveNot?: MembershipWhereInput | null;
    exists?: boolean | null;
};
export type MembershipWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    level?: StringWhereInput | null;
    role?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<MembershipWhereInput> | null;
    AND?: Array<MembershipWhereInput> | null;
    NOR?: Array<MembershipWhereInput> | null;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type UserRelationWhereInput = {
    have?: UserWhereInput | null;
    haveNot?: UserWhereInput | null;
    exists?: boolean | null;
};
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type RoleRelationWhereInput = {
    have?: RoleWhereInput | null;
    haveNot?: RoleWhereInput | null;
    exists?: boolean | null;
};
export type RoleWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    name?: StringWhereInput | null;
    users?: UserRelationWhereInput | null;
    roles?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<RoleWhereInput> | null;
    AND?: Array<RoleWhereInput> | null;
    NOR?: Array<RoleWhereInput> | null;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type MembershipStatusWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    member?: UserRelationWhereInput | null;
    membershipAuthor?: UserRelationWhereInput | null;
    status?: StringWhereInput | null;
    totalDays?: NumberWhereInput | null;
    expiredDate?: DateWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<MembershipStatusWhereInput> | null;
    AND?: Array<MembershipStatusWhereInput> | null;
    NOR?: Array<MembershipStatusWhereInput> | null;
};
export type MembershipManagementScreenQueryVariables = {
    pricingWhere?: MembershipPricingWhereInput | null;
    statusWhere?: MembershipStatusWhereInput | null;
};
export type MembershipManagementScreenQueryResponse = {
    readonly membershipPricings: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly price: number | null;
                readonly duration: number | null;
                readonly membership: {
                    readonly id: string;
                    readonly objectId: string;
                    readonly title: string | null;
                    readonly level: string | null;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"MembershipPricingCardFragment">;
            } | null;
        } | null> | null;
    };
    readonly membershipStatuses: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly membership: {
                    readonly author: {
                        readonly id: string;
                        readonly objectId: string;
                    } | null;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"MembershipStatusCardFragment">;
            } | null;
        } | null> | null;
    };
};
export type MembershipManagementScreenQuery = {
    readonly response: MembershipManagementScreenQueryResponse;
    readonly variables: MembershipManagementScreenQueryVariables;
};



/*
query MembershipManagementScreenQuery(
  $pricingWhere: MembershipPricingWhereInput
  $statusWhere: MembershipStatusWhereInput
) {
  membershipPricings(where: $pricingWhere, order: price_ASC) {
    edges {
      node {
        id
        objectId
        price
        duration
        membership {
          id
          objectId
          title
          level
        }
        ...MembershipPricingCardFragment
      }
    }
  }
  membershipStatuses(where: $statusWhere, order: updatedAt_DESC) {
    edges {
      node {
        id
        objectId
        membership {
          author {
            id
            objectId
          }
          id
        }
        ...MembershipStatusCardFragment
      }
    }
  }
}

fragment MembershipPricingCardFragment on MembershipPricing {
  id
  objectId
  duration
  price
  author {
    id
    objectId
    name
  }
  membership {
    title
    id
  }
}

fragment MembershipStatusCardFragment on MembershipStatus {
  id
  objectId
  status
  expiredDate
  membership {
    id
    objectId
    title
    author {
      id
      objectId
      name
      ...UserProfileImageFragment
    }
  }
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pricingWhere"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "statusWhere"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": "price_ASC"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "pricingWhere"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Membership",
  "kind": "LinkedField",
  "name": "membership",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": "updatedAt_DESC"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "statusWhere"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipManagementScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MembershipPricingConnection",
        "kind": "LinkedField",
        "name": "membershipPricings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipPricingEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipPricing",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MembershipPricingCardFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "MembershipStatusConnection",
        "kind": "LinkedField",
        "name": "membershipStatuses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipStatusEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipStatus",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Membership",
                    "kind": "LinkedField",
                    "name": "membership",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MembershipStatusCardFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MembershipManagementScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MembershipPricingConnection",
        "kind": "LinkedField",
        "name": "membershipPricings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipPricingEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipPricing",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "MembershipStatusConnection",
        "kind": "LinkedField",
        "name": "membershipStatuses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipStatusEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipStatus",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Membership",
                    "kind": "LinkedField",
                    "name": "membership",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "originProfileURL",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FileInfo",
                            "kind": "LinkedField",
                            "name": "profileImage",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expiredDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7f51374099ff42d319d79aa0a43d1a7",
    "id": null,
    "metadata": {},
    "name": "MembershipManagementScreenQuery",
    "operationKind": "query",
    "text": "query MembershipManagementScreenQuery(\n  $pricingWhere: MembershipPricingWhereInput\n  $statusWhere: MembershipStatusWhereInput\n) {\n  membershipPricings(where: $pricingWhere, order: price_ASC) {\n    edges {\n      node {\n        id\n        objectId\n        price\n        duration\n        membership {\n          id\n          objectId\n          title\n          level\n        }\n        ...MembershipPricingCardFragment\n      }\n    }\n  }\n  membershipStatuses(where: $statusWhere, order: updatedAt_DESC) {\n    edges {\n      node {\n        id\n        objectId\n        membership {\n          author {\n            id\n            objectId\n          }\n          id\n        }\n        ...MembershipStatusCardFragment\n      }\n    }\n  }\n}\n\nfragment MembershipPricingCardFragment on MembershipPricing {\n  id\n  objectId\n  duration\n  price\n  author {\n    id\n    objectId\n    name\n  }\n  membership {\n    title\n    id\n  }\n}\n\nfragment MembershipStatusCardFragment on MembershipStatus {\n  id\n  objectId\n  status\n  expiredDate\n  membership {\n    id\n    objectId\n    title\n    author {\n      id\n      objectId\n      name\n      ...UserProfileImageFragment\n    }\n  }\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = 'd2fe9871ed89a0a2e02ebe269e4380a1';
export default node;
