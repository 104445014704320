/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import MyPathsPaginationQuery from "./MyPathsPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type MyPathScreenPathPaginationQuery = {
    readonly paths: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly __typename: string;
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly updatedAt: unknown;
                readonly published: boolean | null;
                readonly " $fragmentRefs": FragmentRefs<"ListItemPath_Fragment">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "MyPathScreenPathPaginationQuery";
};
export type MyPathScreenPathPaginationQuery$data = MyPathScreenPathPaginationQuery;
export type MyPathScreenPathPaginationQuery$key = {
    readonly " $data"?: MyPathScreenPathPaginationQuery$data;
    readonly " $fragmentRefs": FragmentRefs<"MyPathScreenPathPaginationQuery">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "paths"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "userObjectId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": MyPathsPaginationQuery
    }
  },
  "name": "MyPathScreenPathPaginationQuery",
  "selections": [
    {
      "alias": "paths",
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": "updatedAt_DESC"
        },
        {
          "fields": [
            {
              "fields": [
                {
                  "fields": [
                    {
                      "fields": [
                        {
                          "kind": "Variable",
                          "name": "equalTo",
                          "variableName": "userObjectId"
                        }
                      ],
                      "kind": "ObjectValue",
                      "name": "objectId"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "have"
                }
              ],
              "kind": "ObjectValue",
              "name": "author"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "PathConnection",
      "kind": "LinkedField",
      "name": "__Path_paths_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PathEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Path",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "objectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "published",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ListItemPath_Fragment"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '19ae3f646a18d77138303152225481db';
export default node;
