import { Image, StyleSheet, ViewStyle } from 'react-native';
import React from 'react';
import LFLink from './LFLink';
import _ from 'lodash';
import LFText from './typo/LFText';
import LFWhiteSpace from './LFWhiteSpace';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { colors } from '../constants/styleGuide';

export type TextScale = 'sm' | 'md' | 'lg' | 'xl';
export type TextColor = 'TEXT_30' | 'TEXT_60' | 'TEXT_80' | 'TEXT_100';

interface SelectorProp<T> {
  options: T[];
  currentOption: T;
  onSelect: (option: T) => void;
  style?: ViewStyle;
  textScale?: TextScale;
  textColor?: TextColor;
}

const Selector = <T extends string>({
  options,
  onSelect,
  currentOption,
  style,
  textScale = 'md',
  textColor = 'TEXT_100',
}: SelectorProp<T>) => {
  function convertScaleToNumber(scale: TextScale) {
    switch (scale) {
      case 'sm':
        return 8;
      case 'md':
        return 12;
      case 'lg':
        return 16;
      case 'xl':
        return 24;
      default:
        return 12;
    }
  }
  const fontSize = convertScaleToNumber(textScale);
  const { showActionSheetWithOptions } = useActionSheet();
  return (
    <LFLink
      onPress={() => {
        showActionSheetWithOptions(
          {
            options: [...options, '취소'],
            cancelButtonIndex: options.length,
          },
          (idx) => {
            _.isNumber(idx) &&
              _.isString(options[idx]) &&
              onSelect(options[idx]);
          }
        );
      }}
      style={[styles.container, style]}
    >
      <LFText style={[styles.option, { fontSize, color: colors[textColor] }]}>
        {[currentOption]}
      </LFText>
      <LFWhiteSpace size="xs" direction="row" />
      <Image
        source={require('../assets/images/arrowDown25@3x.png')}
        style={{
          width: fontSize,
          height: fontSize,
        }}
      />
    </LFLink>
  );
};

export default Selector;

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center' },
  option: {
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 27,
  },
});
