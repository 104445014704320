import { Image, StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { ProfileScreenUserQuery } from '../__generated__/ProfileScreenUserQuery.graphql';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import UserProfileImage from '../components/UserProfileImage';
import LFText, { LFHyperLink } from '../components/typo/LFText';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from '../components/LFWhiteSpace';
import NavBar, { SettingNavButton } from '../components/NavBar2';
import { useAuth } from '../hooks/auth';
import CountOfPathsAndContentItems from './ProfileScreen/CountOfPathsAndContentItems';
import LFLink from '../components/LFLink';
import { ProfileScreenMembershipQuery } from '../__generated__/ProfileScreenMembershipQuery.graphql';
import Hr from '../components/Hr';
import SectionTitle from '../components/SectionTitle';
import BasePathList from '../components/BasePathList';
import BaseContentItemList from '../components/BaseContentItemList';
import { AnalyticsContentItemWhereInput } from '../__generated__/BaseContentItemListQuery.graphql';
import EmptyList from '../components/EmptyList';
import BaseActivityStreamList from '../components/BaseActivityStreamList';
import { useScroll } from 'ahooks';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { AnalyticsPathWhereInput } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';
import LFMetaTags from '../components/LFMetaTags';
import URLON from 'urlon';
import MyStatics from '../components/MyStatics';

const TARGET_SCROLL_Y_POINT = 50;

const ProfileScreen = () => {
  const { top: scrollY } = useScroll();
  const profileOwnerNamsIsHide = scrollY >= TARGET_SCROLL_Y_POINT;
  const { user: loginUser, logout } = useAuth();
  const { userObjectId } = useParams<{ userObjectId: string }>();

  const profileUserId = userObjectId ?? loginUser?.objectId;
  const location = useLocation();
  const [pathsCount, setPathsCount] = useState<number>(0);
  const [contentItemsCount, setContentItemsCount] = useState<number>(0);
  const [activitiesCount, setActivitiesCount] = useState<number>(1);

  const { user: profileUser } = useLazyLoadQuery<ProfileScreenUserQuery>(
    graphql`
      query ProfileScreenUserQuery($userObjectId: ID!) {
        user(id: $userObjectId) {
          id
          objectId
          username
          name
          jobTitle
          tags {
            ... on Element {
              value
            }
          }
          description
          ...UserProfileImageFragment
        }
      }
    `,
    { userObjectId: userObjectId ?? loginUser?.objectId },
    { fetchPolicy: 'store-and-network' }
  );

  const isMe = loginUser?.objectId === profileUser.objectId;

  const pathWhere: AnalyticsPathWhereInput = {
    path: {
      have: {
        author: {
          have: {
            objectId: {
              equalTo: profileUserId,
            },
          },
        },
      },
    },
  };
  const contentItemWhere: AnalyticsContentItemWhereInput = {
    contentItem: {
      have: {
        author: {
          have: {
            objectId: {
              equalTo: profileUserId,
            },
          },
        },
      },
    },
  };

  if (isMe === false) {
    pathWhere.path!.have!.published = { equalTo: true };
    contentItemWhere.contentItem!.have!.published = { equalTo: true };
  }

  const { memberships, membershipStatuses } =
    useLazyLoadQuery<ProfileScreenMembershipQuery>(
      graphql`
        query ProfileScreenMembershipQuery(
          $profileUserId: ID!
          $loginUserId: ID!
          $isMe: Boolean!
        ) {
          memberships(
            where: {
              author: { have: { objectId: { equalTo: $profileUserId } } }
            }
          ) @skip(if: $isMe) {
            count
          }
          membershipStatuses(
            where: {
              AND: [
                { member: { have: { objectId: { equalTo: $loginUserId } } } }
                { status: { equalTo: "valid" } }
                {
                  membership: {
                    have: {
                      author: {
                        have: { objectId: { equalTo: $profileUserId } }
                      }
                    }
                  }
                }
              ]
            }
          ) @skip(if: $isMe) {
            count
          }
        }
      `,
      {
        profileUserId: profileUser.objectId,
        loginUserId: loginUser?.objectId || '',
        isMe,
      },
      { fetchPolicy: 'store-and-network' }
    );

  const userPathsTo = {
    pathname: '/paths/curation',
    search:
      'where=' +
      URLON.stringify(pathWhere).replaceAll('&', '*') +
      `&title=${isMe ? '내 러닝패스' : `${profileUser.name}의 러닝패스`}`,
  };

  const userNotesTo = {
    pathname: '/notes/curation',
    search:
      'where=' +
      URLON.stringify(contentItemWhere).replaceAll('&', '*') +
      `&title=${isMe ? '내 학습노트' : `${profileUser.name}의 학습노트`}`,
  };

  const sendDataToGTM = useGTMDispatch();
  return (
    <View style={{ backgroundColor: colors.SECONDARY_WHITE }}>
      <LFMetaTags
        title={`${profileUser.name}의 프로필 | Learnfit`}
        description={
          profileUser?.description || `${profileUser?.name}님의 프로필입니다.`
        }
      />
      <NavBar
        sticky
        title={profileOwnerNamsIsHide ? profileUser.name ?? '' : ''}
        right={
          isMe ? (
            <SettingNavButton
              testID="mypage-setting:ProfileScreen"
              options={[
                {
                  title: '로그아웃',
                  onPress: async () => {
                    try {
                      await logout();
                      await sendDataToGTM({ event: 'customOnClickLogout' });
                    } catch (error) {
                      console.log('로그아웃에 실패했습니다. ', error);
                    }
                  },
                },
              ]}
              destructiveButtonIndex={[0]}
            />
          ) : // <SettingNavButton
          //   onPress={() => {
          //     logout();
          //   }}
          // />
          null
        }
      />
      <View style={{ paddingHorizontal: 16 }}>
        <WhiteSpace size="sm" />
        <Flex align="center">
          <UserProfileImage
            size={80}
            userFrgmt={profileUser}
            style={{
              borderWidth: 3,
              borderColor: colors.BORDER_10,
            }}
          />
          <LFWhiteSpace direction="row" size="sm" />
          <View>
            <LFText style={styles.nickName}>{profileUser.name}</LFText>
            <WhiteSpace size="xs" />
            <Flex align="center">
              <LFText style={styles.username}>
                {profileUser.username
                  ? `@${profileUser.username.slice(0, 10)}`
                  : ''}
              </LFText>
              <LFWhiteSpace size="xs" direction="row" />
              <View
                style={{
                  width: 2,
                  height: 2,
                  backgroundColor: colors.BORDER_40,
                }}
              ></View>
              <LFWhiteSpace size="xs" direction="row" />
              <LFText style={styles.jobTitle}>{profileUser.jobTitle}</LFText>
            </Flex>
          </View>
        </Flex>
        <WhiteSpace size="lg" />
        <CountOfPathsAndContentItems
          userObjectId={profileUser.objectId}
          isMe={isMe}
          pathsCountTo={userPathsTo}
          notesCountTo={userNotesTo}
        />
        <WhiteSpace size="lg" />
        <View>
          <LFHyperLink>
            <LFText style={styles.description} numberOfLines={2}>
              {profileUser.description}
            </LFText>
          </LFHyperLink>
        </View>
        <WhiteSpace size="sm" />
        <Flex wrap="wrap">
          {profileUser.tags?.map((item, index, array) => {
            const tag = item ? (item.value as string) : null;
            const isLast = index + 1 === array.length;
            return tag ? (
              <React.Fragment key={index}>
                <LFLink to={`/tag/${tag}`}>
                  <LFText
                    testID="mypage-tags:ProfileScreen"
                    style={styles.tag}
                  >{`#${tag}`}</LFText>
                </LFLink>
                {!isLast && <LFWhiteSpace size="xs" direction="row" />}
              </React.Fragment>
            ) : null;
          })}
        </Flex>
        <WhiteSpace size="lg" />
        {/* 나의 프로필 화면일 때 */}
        {isMe && (
          <>
            <Flex>
              <LFLink
                testID="mypage-membership:ProfileScreen"
                style={{ flex: 1 }}
                to="/me/membership"
              >
                <Flex style={styles.membershipBtnContainer}>
                  <Image
                    accessibilityLabel=""
                    source={require('../assets/images/icon24Symbol@3x.png')}
                    style={{ width: 16, height: 16 }}
                  />
                  <LFWhiteSpace size="xs" direction="row" />
                  <LFText style={styles.membershipBtnText}>멤버십 관리</LFText>
                </Flex>
              </LFLink>
              <LFWhiteSpace direction="row" size="sm" />
              <LFLink
                testID="mypage-edit-profile:ProfileScreen"
                style={{ flex: 1 }}
                to={'/me/edit'}
              >
                <Flex style={styles.membershipBtnContainer}>
                  <LFText style={styles.membershipBtnText}>프로필 수정</LFText>
                </Flex>
              </LFLink>
            </Flex>
            <WhiteSpace size="xl" />
            <MyStatics userObjectId={userObjectId} />
            <WhiteSpace size="xl" />
            <View style={{ marginHorizontal: -16 }}>
              <Hr lineWidth={8} color={colors.BG_2} />
            </View>
            <View>
              {[
                {
                  title: '학습중인 러닝패스',
                  to: '/me/in-progress-path',
                  testID: 'mypage-inprogress-path:ProfileScreen',
                },
                {
                  title: '완료한 러닝패스',
                  to: '/me/done-path',
                  testID: 'mypage-done-path:ProfileScreen',
                },
                {
                  title: '완료한 학습노트',
                  to: '/me/done-note',
                  testID: 'mypage-done-note:ProfileScreen',
                },
                {
                  title: '최근',
                  to: '/me/recent',
                  testID: 'recent:ProfileScreen',
                },
                { title: '내 보관함', to: '/me/emoji' },
                // { title: '통계', to: null },
              ].map((sectionInfo) => (
                <SectionTitle
                  testID={sectionInfo.testID}
                  key={sectionInfo.title}
                  title={sectionInfo.title}
                  to={sectionInfo.to}
                  arrow
                  style={{ paddingVertical: 16 }}
                />
                // <LFLink key={sectionInfo.title} to={sectionInfo.to ?? ''}>
                //   <Flex justify="between" style={{ paddingVertical: 16 }}>
                //     <LFText style={styles.myProfileSectionTitle}>
                //       {sectionInfo.title}
                //     </LFText>
                //     <Image
                //       source={require('../assets/images/arrow24@3x.png')}
                //       style={{ width: 16, height: 16 }}
                //     />
                //   </Flex>
                // </LFLink>
              ))}
            </View>
          </>
        )}
        {/* 타인 프로필 화면일 때: 타인에게 멤버십이 존재하고 그 멤버십에 가입한 경우  */}
        {!isMe && memberships?.count !== 0 && membershipStatuses?.count !== 0 && (
          <>
            <LFLink
              to={{
                pathname: `/u/${profileUser.objectId}/membership/status`,
              }}
            >
              <Flex style={[styles.membershipBtnContainer]}>
                <Image
                  accessibilityLabel=""
                  source={require('../assets/images/icon24Symbol@3x.png')}
                  style={{ width: 16, height: 16 }}
                />
                <LFWhiteSpace size="xs" direction="row" />
                <LFText
                  style={[styles.membershipBtnText, { color: colors.TEXT_60 }]}
                >
                  {'멤버십 가입중'}
                </LFText>
              </Flex>
            </LFLink>
            <WhiteSpace size="xl" />
          </>
        )}
        {/* 타인 프로필 화면일 때: 타인에게 멤버십이 존재하지만 그 멤버십에 가입하지 않은 경우  */}
        {!isMe && memberships?.count !== 0 && membershipStatuses?.count === 0 && (
          <>
            <LFLink
              testID="join-membership-btn:ProfileScreen"
              to={{
                pathname: `/u/${profileUser.objectId}/membership`,
                state: { from: location },
              }}
            >
              <Flex
                style={[
                  styles.membershipBtnContainer,
                  { backgroundColor: colors.PRIMARY_10, borderWidth: 0 },
                ]}
              >
                <Image
                  accessibilityLabel=""
                  source={require('../assets/images/icon24Symbol@3x.png')}
                  style={{ width: 16, height: 16 }}
                />
                <LFWhiteSpace size="xs" direction="row" />
                <LFText
                  style={[
                    styles.membershipBtnText,
                    { color: colors.PRIMARY_100 },
                  ]}
                >
                  {'멤버십 가입'}
                </LFText>
              </Flex>
            </LFLink>
            <WhiteSpace size="xl" />
          </>
        )}
      </View>
      <Hr lineWidth={8} color={colors.BG_2} />
      <View style={{ padding: 16 }}>
        <SectionTitle
          testID="more-learningpath:ProfileScreen"
          title="러닝패스"
          arrow={pathsCount > 2}
          count={pathsCount > 2 ? pathsCount : undefined}
          create={isMe ? 'path' : undefined}
          to={
            pathsCount > 2 ? userPathsTo : undefined
            // ? `/u/${profileUserId}/paths`
          }
        />
        <LFWhiteSpace size="md" />
        <BasePathList
          testID="base-path-list:ProfileScreen"
          ListEmptyComponent={<EmptyList />}
          where={pathWhere}
          order={['createdAt_DESC']}
          first={2}
          onPressItemTo={(pathObjectId) => `/path/${pathObjectId}`}
          noLoadNext
          onChangeCount={setPathsCount}
        />
        <LFWhiteSpace size="xxl" />
        <SectionTitle
          testID="more-note:ProfileScreen"
          title="학습노트"
          arrow={contentItemsCount > 2}
          count={contentItemsCount > 2 ? contentItemsCount : undefined}
          create={isMe ? 'note' : undefined}
          to={
            contentItemsCount > 2 ? userNotesTo : undefined
            // ? `/u/${profileUserId}/notes`
          }
        />
        <LFWhiteSpace size="md" />
        <BaseContentItemList
          testID="base-note-list:ProfileScreen"
          ListEmptyComponent={<EmptyList />}
          where={contentItemWhere}
          order={['createdAt_DESC']}
          first={2}
          onPressItemTo={(contentItemId, contentId) =>
            `/note/${contentId}/${contentItemId}`
          }
          noLoadNext
          onChangeCount={setContentItemsCount}
        />
        <LFWhiteSpace size="xxl" />
        {Boolean(activitiesCount) && (
          <>
            <SectionTitle title="액티비티" />
            <LFWhiteSpace size="md" />
            <BaseActivityStreamList
              testID="mypage-activity:ProfileScreen"
              gutter={24}
              onChangeCount={setActivitiesCount}
              where={{
                author: { have: { objectId: { equalTo: profileUserId } } },
              }}
            />
          </>
        )}
      </View>
    </View>
  );
};

export default ProfileScreen;

const styles = StyleSheet.create({
  nickName: {
    fontSize: 24,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 36,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  username: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  jobTitle: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  description: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
    wordBreak: 'break-all',
  },
  tag: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_DARK,
  },
  membershipBtnContainer: {
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.BORDER_10,
    paddingVertical: 12,
    justifyContent: 'center',
  },
  membershipBtnText: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_80,
  },
  myProfileSectionTitle: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
});
