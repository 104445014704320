import { StyleSheet, ViewStyle } from 'react-native';
import React, { useCallback, useState } from 'react';
import LFLink from './LFLink';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import _ from 'lodash';
import { useAuth } from '../hooks/auth';
import { useDebounceFn } from 'ahooks';
import {
  BookMarkButtonCreateReactionMutation,
  CreateReactionFieldsInput,
} from '../__generated__/BookMarkButtonCreateReactionMutation.graphql';
import {
  BookMarkButtonQuery,
  ReactionWhereInput,
} from '../__generated__/BookMarkButtonQuery.graphql';
import { BookMarkButtonDeleteReactionMutation } from '../__generated__/BookMarkButtonDeleteReactionMutation.graphql';
import BookMark from './BookMark';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginScreenAccessAction } from '../screens/LoginScreen';
import useQueryStringChanger from '../hooks/queryString';
import useQueryStringEffect from '../hooks/useQueryStringEffect';

const TYPE = 'bookmark';

interface IBookMarkButton {
  style?: ViewStyle;
  targetClassName: 'Path' | 'ContentItem';
  targetId: string;
  disableMode?: boolean;
}

const BookMarkButton: React.FC<IBookMarkButton> = ({
  style,
  targetClassName,
  targetId,
  disableMode = false,
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();
  // const { removeQueryString } = useQueryStringChanger();

  // URL의 쿼리스트링이 변할 때마다 호출될 effect를 넘겨주는 hook입니다.
  // 쿼리스트링에 from이 포함되어있고 로그인 상태일 때 북마크를 create합니다.
  // 쿼리스트링에 from이 포함되어있지 않거나 비로그인 상태인 경우는 아무것도 하지 않습니다.
  // 성공, 실패의 경우 모두에 대해 queryString에서 from key pair를 지워줍니다.
  // useQueryStringEffect((urlSearchParams) => {
  //   urlSearchParams.get('from') === 'login' && user ? onCreateReaction() : null;
  //   removeQueryString({ key: 'from' });
  // });
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2]
  >({
    // 함수의 params를 typing : https://www.typescriptlang.org/docs/handbook/utility-types.html#parameterstype
    fetchPolicy: 'network-only',
    fetchKey: 0,
  });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      //@ts-ignore
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'store-and-network',
    }));
  }, [setRefreshedQueryOptions]);

  const reactionsWhere: ReactionWhereInput = {
    targetClassName: {
      equalTo: targetClassName,
    },
    [_.lowerFirst(targetClassName)]: {
      have: {
        objectId: {
          equalTo: targetId,
        },
      },
    },
    type: {
      equalTo: TYPE,
    },
    author: {
      have: {
        objectId: {
          equalTo: user?.objectId,
        },
      },
    },
  };
  const { reactions } = useLazyLoadQuery<BookMarkButtonQuery>(
    graphql`
      query BookMarkButtonQuery($where: ReactionWhereInput!) {
        reactions(where: $where) {
          edges {
            node {
              id
              objectId
            }
          }
        }
      }
    `,
    { where: reactionsWhere },
    refreshedQueryOptions
  );

  const [commitCreateReaction] =
    useMutation<BookMarkButtonCreateReactionMutation>(
      graphql`
        mutation BookMarkButtonCreateReactionMutation(
          $fields: CreateReactionFieldsInput!
        ) {
          createReaction(input: { fields: $fields }) {
            reaction {
              id
              objectId
            }
          }
        }
      `
    );

  const [commitDeleteReaction] =
    useMutation<BookMarkButtonDeleteReactionMutation>(graphql`
      mutation BookMarkButtonDeleteReactionMutation($id: ID!) {
        deleteReaction(input: { id: $id }) {
          reaction {
            id
            objectId
          }
        }
      }
    `);

  const myReaction = _.get(_.first(reactions?.edges), 'node');

  const onCreateReaction = () => {
    const fields: CreateReactionFieldsInput = {
      type: TYPE,
      targetClassName,
      [_.lowerFirst(targetClassName)]: {
        link: targetId,
      },
    };
    commitCreateReaction({
      variables: {
        fields,
      },
      onCompleted: refresh,
      onError: console.log,
    });
  };

  const onDeleteReaction = () => {
    commitDeleteReaction({
      variables: {
        id: myReaction?.objectId || '',
      },
      onCompleted: refresh,
      onError: console.log,
    });
  };

  const { run: onPress } = useDebounceFn(
    () => {
      if (!user) {
        history.push({
          pathname: '/login',
          state: {
            // from: { pathname: location.pathname, search: 'from=login' },
            from: location,
            accessAction: 'clickBookMarkButton' as LoginScreenAccessAction,
          },
        });
        return;
      }
      myReaction ? onDeleteReaction() : onCreateReaction();
    },
    { wait: 500 }
  );

  return (
    <LFLink disabled={disableMode} style={style} onPress={onPress}>
      <BookMark selected={Boolean(myReaction)} />
    </LFLink>
  );
};

export default React.memo(BookMarkButton);

const styles = StyleSheet.create({});
