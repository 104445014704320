import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  AnalyticsContentItemWhereInput,
  AnalyticsPathWhereInput,
  MyStaticsTotalCountAllQuery,
} from '../__generated__/MyStaticsTotalCountAllQuery.graphql';
import { MyStaticsPathAndNoteQuery } from '../__generated__/MyStaticsPathAndNoteQuery.graphql';
import { Flex } from 'antd-mobile';
import LFText from './typo/LFText';
import LFWhiteSpace from './LFWhiteSpace';
import { colors } from '../constants/styleGuide';
import Hr from './Hr';
import { useDeviceType } from '../hooks/deviceType';

interface MyStatics {
  userObjectId: string;
}

const MyStatics: React.FC<MyStatics> = ({ userObjectId }) => {
  const noteWhere: AnalyticsContentItemWhereInput = {
    contentItem: {
      have: {
        author: { have: { objectId: { equalTo: userObjectId } } },
      },
    },
  };
  const pathWhere: AnalyticsPathWhereInput = {
    path: {
      have: {
        author: { have: { objectId: { equalTo: userObjectId } } },
      },
    },
  };
  const {
    analyticsContentItems: { count: noteCount },
    analyticsPaths: { count: pathCount },
  } = useLazyLoadQuery<MyStaticsTotalCountAllQuery>(
    graphql`
      query MyStaticsTotalCountAllQuery(
        $noteWhere: AnalyticsContentItemWhereInput!
        $pathWhere: AnalyticsPathWhereInput!
      ) {
        analyticsContentItems(where: $noteWhere) {
          count
        }
        analyticsPaths(where: $pathWhere) {
          count
        }
      }
    `,
    { noteWhere, pathWhere }
  );
  const { analyticsContentItems, analyticsPaths } =
    useLazyLoadQuery<MyStaticsPathAndNoteQuery>(
      graphql`
        query MyStaticsPathAndNoteQuery(
          $noteCount: Int!
          $pathCount: Int!
          $noteWhere: AnalyticsContentItemWhereInput!
          $pathWhere: AnalyticsPathWhereInput!
        ) {
          analyticsContentItems(first: $noteCount, where: $noteWhere) {
            edges {
              node {
                id
                objectId
                viewCount
                emojiTotalCount
              }
            }
          }
          analyticsPaths(first: $pathCount, where: $pathWhere) {
            edges {
              node {
                id
                objectId
                viewCount
                emojiTotalCount
              }
            }
          }
        }
      `,
      // { userObjectId: userObjectId, noteCount: noteCount, pathCount: pathCount } 아래로 축약
      { noteCount, pathCount, noteWhere, pathWhere }
    );
  //   console.log(analyticsContentItems, analyticsPaths);
  //   const allNoteViewCount = analyticsContentItems?.edges?.map(
  //     (edge) => edge?.node?.viewCount || 0
  //   ) || [0];
  //   const totalNoteViewCount = _.sum(allNoteViewCount);
  //   console.log(totalNoteViewCount);

  //   const allNoteEmojiCount = analyticsContentItems?.edges?.map(
  //     (edge) => edge?.node?.emojiTotalCount || 0
  //   ) || [0];
  //   const totalNoteEmojiCount = _.sum(allNoteEmojiCount);
  //   console.log(totalNoteEmojiCount);

  //   const allPathViewCount = analyticsPaths?.edges?.map(
  //     (edge) => edge?.node?.viewCount || 0
  //   ) || [0];
  //   const totalPathViewCount = _.sum(allPathViewCount);
  //   console.log(totalPathViewCount);

  //   const allPathEmojiCount = analyticsPaths?.edges?.map(
  //     (edge) => edge?.node?.emojiTotalCount || 0
  //   ) || [0];
  //   const totalPathEmojiCount = _.sum(allPathEmojiCount);
  //   console.log(totalPathEmojiCount);

  const initialTotalCountMap = { totalEmojiCount: 0, totalViewCount: 0 };
  const noteTotalCountMap =
    analyticsContentItems?.edges?.reduce((prev, curr) => {
      const totalEmojiCount =
        prev.totalEmojiCount + (curr?.node?.emojiTotalCount || 0);
      const totalViewCount = prev.totalViewCount + (curr?.node?.viewCount || 0);
      return { totalEmojiCount, totalViewCount };
    }, initialTotalCountMap) || initialTotalCountMap;

  const pathTotalCountMap =
    analyticsPaths?.edges?.reduce((prev, curr) => {
      const totalEmojiCount =
        prev.totalEmojiCount + (curr?.node?.emojiTotalCount || 0);
      const totalViewCount = prev.totalViewCount + (curr?.node?.viewCount || 0);
      return { totalEmojiCount, totalViewCount };
    }, initialTotalCountMap) || initialTotalCountMap;

  console.log(noteTotalCountMap, pathTotalCountMap);

  const deviceType = useDeviceType();

  return (
    <Flex>
      <View style={{ marginHorizontal: -16 }}>
        <Hr lineWidth={8} color={colors.BG_2} />
      </View>
      <LFWhiteSpace size="xl" />
      <LFText style={styles.myStaticsSectionTitle}>
        🔥 내 콘텐츠 누적 통계
      </LFText>
      <LFWhiteSpace size="xl" />
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        <View
          style={
            deviceType === 'MOBILE'
              ? styles.staticsItemForMobile
              : styles.staticsItemForPC
          }
        >
          <LFText style={styles.titleText}>학습노트 조회수</LFText>
          <LFText style={styles.countText}>
            {noteTotalCountMap.totalViewCount}
          </LFText>
        </View>
        <View
          style={
            deviceType === 'MOBILE'
              ? styles.staticsItemForMobile
              : styles.staticsItemForPC
          }
        >
          <LFText style={styles.titleText}>학습노트 이모지수</LFText>
          <LFText style={styles.countText}>
            {noteTotalCountMap.totalEmojiCount}
          </LFText>
        </View>
        <View
          style={
            deviceType === 'MOBILE'
              ? styles.staticsItemForMobile
              : styles.staticsItemForPC
          }
        >
          <LFText style={styles.titleText}>러닝패스 조회수</LFText>
          <LFText style={styles.countText}>
            {pathTotalCountMap.totalViewCount}
          </LFText>
        </View>
        <View
          style={
            deviceType === 'MOBILE'
              ? styles.staticsItemForMobile
              : styles.staticsItemForPC
          }
        >
          <LFText style={styles.titleText}>러닝패스 이모지수</LFText>
          <LFText style={styles.countText}>
            {pathTotalCountMap.totalEmojiCount}
          </LFText>
        </View>
      </View>
    </Flex>
  );
};

export default MyStatics;

const styles = StyleSheet.create({
  staticsItemForMobile: {
    width: '50%',
    alignItems: 'center',
    marginBottom: 30,
  },
  staticsItemForPC: {
    width: '25%',
    alignItems: 'center',
    marginBottom: 20,
  },
  titleText: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
    marginBottom: 10,
  },
  countText: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  myStaticsSectionTitle: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
});
