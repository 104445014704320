import { Image, StyleSheet, Text, View, ViewStyle } from 'react-native';
import React from 'react';
import LFText from './typo/LFText';
import LFWhiteSpace from './LFWhiteSpace';

const EmptyList: React.FC<{
  message?: string;
  style?: ViewStyle;
}> = ({ message = '"공유 중인 지식이 없습니다"', style }) => {
  return (
    <View style={[style, styles.container]}>
      <Image
        accessibilityLabel=""
        source={require('../assets/images/emptyContents@3x.png')}
        style={styles.image}
      />
      <LFWhiteSpace direction="column" size="sm" />
      <LFText style={styles.message}>{message}</LFText>
    </View>
  );
};
export default EmptyList;

const styles = StyleSheet.create({
  container: { alignItems: 'center' },
  image: {
    width: 140,
    height: 140,
  },
  message: {
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    color: 'rgba(26, 27, 30, 0.6)',
  },
});
