/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteContentItemInput = {
    id: string;
    clientMutationId?: string | null;
};
export type MyContentItemScreenContentItemDeleteMutationVariables = {
    input: DeleteContentItemInput;
    connections: Array<string>;
};
export type MyContentItemScreenContentItemDeleteMutationResponse = {
    readonly deleteContentItem: {
        readonly contentItem: {
            readonly id: string;
            readonly objectId: string;
            readonly title: string | null;
        };
    } | null;
};
export type MyContentItemScreenContentItemDeleteMutation = {
    readonly response: MyContentItemScreenContentItemDeleteMutationResponse;
    readonly variables: MyContentItemScreenContentItemDeleteMutationVariables;
};



/*
mutation MyContentItemScreenContentItemDeleteMutation(
  $input: DeleteContentItemInput!
) {
  deleteContentItem(input: $input) {
    contentItem {
      id
      objectId
      title
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyContentItemScreenContentItemDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteContentItemPayload",
        "kind": "LinkedField",
        "name": "deleteContentItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyContentItemScreenContentItemDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteContentItemPayload",
        "kind": "LinkedField",
        "name": "deleteContentItem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "96357c9f646c7453dd9ff94f85dde2b2",
    "id": null,
    "metadata": {},
    "name": "MyContentItemScreenContentItemDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation MyContentItemScreenContentItemDeleteMutation(\n  $input: DeleteContentItemInput!\n) {\n  deleteContentItem(input: $input) {\n    contentItem {\n      id\n      objectId\n      title\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4de0413357844eb90471bea50042ebcd';
export default node;
