import React from 'react';
import { Text, View, ViewStyle, StyleSheet } from 'react-native';
import LFText from '../components/typo/LFText';

const ListItem404: React.FC<{ style?: ViewStyle }> = ({ style }) => {
  return (
    <View
      style={[
        contentItemStyles.container,
        { alignContent: 'center', justifyContent: 'center', padding: 16 },
        style,
      ]}
    >
      <LFText style={{ color: '#888' }}>
        비공개 상태이거나{'\n'}삭제된 콘텐츠입니다.
      </LFText>
    </View>
  );
};

export default ListItem404;

const contentItemStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    elevation: 12,
  },
});
