import React, { useState } from 'react';
import {
  StyleSheet,
  View,
  TextInput,
  Text,
  ImageBackground,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { WhiteSpace } from '@ant-design/react-native';
import { Ionicons } from '@expo/vector-icons';
import NavBar from '../components/NavBar';
import { CheckBox } from '../components/CheckBox';
import _ from 'lodash';
import LFLink from '../components/LFLink';
import { useAuth } from '../hooks/auth';
import {
  graphql,
  useLazyLoadQuery,
  useFragment,
  useMutation,
} from 'react-relay';
import {
  ItemSelectorScreen_Fragment,
  ItemSelectorScreen_Fragment$key,
} from '../__generated__/ItemSelectorScreen_Fragment.graphql';
import {
  ItemSelectorScreenSearchQuery,
  ItemSelectorScreenSearchQueryResponse,
} from '../__generated__/ItemSelectorScreenSearchQuery.graphql';
import LFText from '../components/typo/LFText';

interface ItemSelectorScreenProps {
  style?: ViewStyle;
  defaultContentItemIds?: string[];
  singleSelection?: boolean;
  onPressClose: () => void;
  onPressSave: (items: ItemSelectorScreen_Fragment$key[]) => void;
}

const ItemSelectorScreen: React.FC<ItemSelectorScreenProps> = ({
  defaultContentItemIds,
  onPressClose,
  onPressSave,
  style,
  singleSelection,
}) => {
  // const selectedItemIds = useState([]);
  const { user } = useAuth();

  const [searchText, setSearchText] = useState('');

  const userObjectId = user?.objectId || '';

  const responseData = useLazyLoadQuery<ItemSelectorScreenSearchQuery>(
    graphql`
      query ItemSelectorScreenSearchQuery(
        $searchText: String
        $userObjectId: ID
      ) {
        contentItems(
          where: {
            AND: [
              { author: { have: { objectId: { equalTo: $userObjectId } } } }
              { title: { matchesRegex: $searchText } }
            ]
          }
          first: 1000
          order: [createdAt_DESC]
        ) {
          edges {
            node {
              __typename
              id
              objectId
              title
              ...ItemSelectorScreen_Fragment
            }
          }
        }
      }
    `,
    { searchText: '', userObjectId: userObjectId }
    // { searchText: searchText, userObjectId: userObjectId }
  );

  // const defaultItems = _.filter(responseData.contentItems.edges, (e) => {
  //   return defaultContentItemFragments?.find(
  //     (n) => n.objectId === e?.node?.objectId
  //   );
  // });

  const defaultArr = _.filter(
    _.map(responseData.contentItems.edges, (e) => e?.node),
    (n) => {
      return _.includes(defaultContentItemIds, n?.objectId);
    }
  );
  const [selectedContentItems, setSelectedContentItems] = useState(defaultArr);
  // console.log(responseData);
  // console.log(selectedContentItems);
  // console.log(defaultContentItemFragments);

  const onSelectContentItem = (
    contentItemObjectId: string,
    checked: boolean
  ) => {
    console.log('contentItemObjectId', contentItemObjectId);
    console.log('checked', checked);
    //setSelectIds(selectIds => selectIds.concat(contentItemObjectId))
  };

  return (
    <View style={style}>
      <NavBar
        left={
          <LFLink onPress={onPressClose}>
            <Ionicons name="close-outline" size={30} color="black" />
          </LFLink>
        }
        title="콘텐츠 담기"
        right={
          singleSelection ? null : (
            <LFLink
              onPress={() => {
                onPressSave(selectedContentItems);
              }}
            >
              <LFText>확인</LFText>
            </LFLink>
          )
        }
      />

      <ScrollView contentContainerStyle={styles.contentContainer}>
        <View style={{ paddingHorizontal: 17 }}>
          <WhiteSpace size="lg" />

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: 9,
              borderBottomWidth: 2,
              borderBottomColor: '#7D7D7D',
            }}
          >
            <View>
              <LFText style={styles.myStorageTitle}>내 보관함</LFText>
            </View>
            {/* <TouchableOpacity style={styles.createNoteBtn}>
              <SimpleLineIcons name="note" size={12} color="#FFFFFF" />
              <LFText style={styles.createNoteTitle}>신규 노트 작성</LFText>
            </TouchableOpacity> */}
          </View>

          <WhiteSpace size="md" />

          <View style={styles.searchBarWrapper}>
            <TextInput
              style={styles.searchBarInput}
              onChangeText={(searchText) => setSearchText(searchText)}
              defaultValue={searchText}
            />
            <TouchableOpacity style={styles.searchBarIconWrapper}>
              <Ionicons name="search-outline" size={18} color="#C6C6C6" />
            </TouchableOpacity>
          </View>

          <WhiteSpace size="lg" />
          {/* UI 레이아웃 테스트를 위해 임의의 갯수만큼 콘텐츠 아이템 컴포넌트 이터레이션 */}
          {/*
          _.map(_.range(3), (idx) => {
            return <ContentItemView  key={idx} style={{ marginBottom: 14 }} />;
          })
          */}
          {_.chain(responseData.contentItems.edges)
            .map((e) => e.node)
            .filter((n) => {
              return (
                n?.title?.match(new RegExp(searchText, 'i')) ||
                selectedContentItems.includes(n)
              );
            })
            .map((node) => {
              const selected = _.includes(selectedContentItems, node);

              const itemView = (
                <ContentItemView
                  singleSelection={singleSelection}
                  contentItemFrgmt={node}
                  key={node?.objectId}
                  style={{ marginBottom: 14 }}
                  selected={selected}
                  onPressCheckbox={() => {
                    if (selected) {
                      setSelectedContentItems((c) => _.without(c, node));
                    } else {
                      setSelectedContentItems((c) => _.uniq([...c, node]));
                    }
                  }}
                />
              );
              return singleSelection ? (
                <TouchableOpacity
                  onPress={() => {
                    onPressSave([node]);
                  }}
                >
                  {itemView}
                </TouchableOpacity>
              ) : (
                itemView
              );
            })
            .value()}
        </View>
      </ScrollView>
    </View>
  );
};

// 싱글 콘텐츠는 컴포넌트 별도 분리
interface ContentItemProps {
  contentItemFrgmt: ItemSelectorScreen_Fragment$key;
  style?: ViewStyle; // 추가 스타일 지정 위해
  selected?: boolean; //선태여부
  singleSelection?: boolean;
  onPressCheckbox?: () => void; // 이 컴포넌트 위부에서 선택 여부 변화에 따른 처리 가능하도록
}

export const contentItemFragmentQuery = graphql`
  fragment ItemSelectorScreen_Fragment on ContentItem {
    id
    objectId
    title
    description
    content {
      id
      objectId
      type
      title
      description
      thumbURL
      link
    }
    author {
      objectId
      name
    }
    noteData {
      ... on Element {
        value
      }
    }
    tags {
      ... on Element {
        value
      }
    }
  }
`;
const ContentItemView: React.FC<ContentItemProps> = ({
  contentItemFrgmt,
  style,
  selected = false,
  singleSelection = false,
  onPressCheckbox = () => {},
}) => {
  const item: ItemSelectorScreen_Fragment = useFragment(
    contentItemFragmentQuery,
    contentItemFrgmt
  );
  return (
    <>
      <View style={[styles.contentItemWrapper, style]}>
        {/* 체크박스와 우측 썸네일 간의 간격은 체크박스 컴포넌트 외부에서 마진값 조정 */}

        {/* 체크박스 */}
        {singleSelection ? null : (
          <View style={{ marginRight: 10 }}>
            {/* 체크박스에 대한 클릭여부, 체크표시를 위한 onPress handler, 스타일 속성 등을 props로 받음 */}
            <CheckBox
              selected={selected}
              onPress={onPressCheckbox}
              size={13}
              color="#000000"
            />
          </View>
        )}

        {/* 컨텐츠 아이템 썸네일 */}

        {/* 컨텐츠 아이템 썸네일 사이즈는 고정  */}
        <View style={styles.contentItemThumbNailWrapper}>
          <ImageBackground
            resizeMode="cover"
            source={{ uri: item?.content?.thumbURL }}
            style={{ flex: 1 }}
          />
        </View>

        {/* 컨텐츠 아이템 제목, 작성자명, 작성자 닉네임 */}

        <View style={{ flexDirection: 'column', paddingLeft: 8, flex: 1 }}>
          <LFText numberOfLines={2} style={styles.contentItemTitle}>
            {item?.title}
          </LFText>

          <WhiteSpace />

          <View style={{ flexDirection: 'row' }}>
            {/* userName이라는 Text 컴포넌트에 margin 값을 그대로 할당하기보다 그 위에 감싸는 View 만들어서 margin 넣는게 더 바람직할까요?  */}
            <LFText numberOfLines={1} style={styles.userName}>
              {item?.author?.name}
            </LFText>
            {/* <LFText numberOfLines={1} style={styles.userNickName}>
              Jaesoek
            </LFText> */}
          </View>
        </View>
      </View>
    </>
  );
};

export default ItemSelectorScreen;

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: '#fff',
    fontFamily: 'Noto Sans KR',
  },
  myStorageTitle: {
    fontSize: 16,
    fontWeight: '700',
  },
  createNoteBtn: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // 신규 노트 작성 버튼은 고정 사이즈(내용 바뀌지 않음)
    width: 110,
    height: 26,
    borderRadius: 16.5,
    backgroundColor: '#A3A1A1',
  },
  createNoteTitle: {
    fontSize: 12,
    color: '#FFFFFF',
  },
  searchBarWrapper: {
    paddingBottom: 11,
    borderBottomWidth: 1,
    borderColor: '#E0E0E0',
  },
  searchBarInput: {
    paddingVertical: 5,
    borderWidth: 1,
    borderRadius: 7,
    borderColor: '#C6C6C6',
  },
  searchBarIconWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 5,
  },
  contentItemWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  contentItemThumbNailWrapper: {
    width: 88,
    height: 52,
  },
  contentItemTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  userName: {
    color: '#565656',
    marginRight: 5,
  },
  userNickName: {
    color: '#565656',
  },
});
