import _ from 'lodash';

export const graphqlElementsToStringArray = (
  elms: readonly ({ readonly value?: unknown } | null)[] | null
) => {
  return _.map(elms, (t) => _.toString(t?.value || ''));
};

export const graphqlElementsToNumberArray = (
  elms: readonly ({ readonly value?: unknown } | null)[] | null
) => {
  return _.map(elms, (t) => _.toNumber(t?.value || ''));
};
