import { StyleSheet, View } from 'react-native';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import BasePathList from '../components/BasePathList';
import NavBar from '../components/NavBar2';
import { colors } from '../constants/styleGuide';
import { useHistory, useLocation } from 'react-router-dom';
import URLON from 'urlon';
import { AnalyticsPathWhereInput } from '../__generated__/BasePathListQuery.graphql';
import Footer from '../components/Footer';
import LFWhiteSpace from '../components/LFWhiteSpace';
import EmptyList from '../components/EmptyList';
import LFMetaTags from '../components/LFMetaTags';
import useLocalStorageUpdateOnlyUnmount from '../hooks/useLocalStorageUpdateOnlyUnmount';
import { useUpdateEffect } from 'ahooks';
import { MORE_NUMBER } from '../components/BasePathList';
import { ActivityIndicator, Flex } from '@ant-design/react-native';
import _ from 'lodash';
import TagSelector from './AllPathListScreen/TagSelector';
import { useAuth } from '../hooks/auth';
import { POSITIONS as POSITIONS_WITH_ID } from './PositionSelectorScreen';
import SortingKeySelector, {
  SortingKey,
} from './AllPathListScreen/SortingKeySelector';
import useQueryStringChanger from '../hooks/queryString';
import { AnalyticsPathOrder } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';
import useScrollEffect from '../hooks/useScrollEffect';
import LFText from '../components/typo/LFText';
import PositionSelector2 from './HomeScreen/PositionSelector2';
import { Position } from './HomeScreen/PositionItem';
import LinearGradient from 'react-native-linear-gradient';
import { useDeviceType } from '../hooks/deviceType';
export const POSITIONS: { [K in Position]: string[] } = {
  '내 관심사': [],
  전체: [
    '프론트엔드',
    '백엔드',
    '데이터베이스',
    '데브옵스',
    '모바일 개발',
    '통계',
    '머신러닝',
    '데이터인프라',
    '데이터 분석',
    'SQL',
    '서비스기획',
    '협업/조직관리',
    '트랜드',
    '업무생산성',
    '스타트업',
    '마케팅',
    '브랜드마케팅',
    '그로스해킹',
    '콘텐츠',
    '카피라이팅',
    'UI/UX',
    '그래픽디자인',
    '브랜드디자인',
    '영상/모션그래픽',
    '캐릭터디자인',
  ],
  개발자: ['프론트엔드', '백엔드', '데이터베이스', '데브옵스', '모바일 개발'],
  'D.S/D.A': ['통계', '머신러닝', '데이터인프라', '데이터 분석', 'SQL'],
  'P.O/기획자': [
    '서비스기획',
    '협업/조직관리',
    '트랜드',
    '업무생산성',
    '스타트업',
  ],
  마케터: ['마케팅', '브랜드마케팅', '그로스해킹', '콘텐츠', '카피라이팅'],
  디자이너: [
    'UI/UX',
    '그래픽디자인',
    '브랜드디자인',
    '영상/모션그래픽',
    '캐릭터디자인',
  ],
};

const AllPathListScreen: React.FC<{ screenMode?: boolean }> = ({
  screenMode = true,
}) => {
  const { user } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  let whereFromSearchParams;
  const { mutateQueryString, removeQueryString } = useQueryStringChanger();
  try {
    whereFromSearchParams = URLON.parse(
      urlSearchParams.get('where')?.replaceAll('*', '&') ?? ''
    );
  } catch (error) {
    // do nothing
  }

  const accessFromHistoryBack = history.action === 'POP';
  const { state: localStorageScrollTop, setState: setLocalStorageScrollTop } =
    useLocalStorageUpdateOnlyUnmount<number>({
      init: 0,
      key: 'allPathListScrollPosition',
    });

  const [listControllerContainerHeight, setListControllerContainerHeight] =
    useState<number>(0);

  useScrollEffect((scrollTop) => {
    setLocalStorageScrollTop(scrollTop - listControllerContainerHeight || 0);
  });

  const {
    state: localStorageScrollPagination,
    setState: setLocalStorageScrollPagination,
  } = useLocalStorageUpdateOnlyUnmount<number>({
    init: MORE_NUMBER,
    key: 'allPathListPaginationCount',
  });

  const positionInURLQueryString = urlSearchParams.get('position');
  const [selectedPosition, setSelectedPosition] = useState<Position>(
    positionInURLQueryString &&
      Object.keys(POSITIONS).includes(positionInURLQueryString)
      ? (positionInURLQueryString as Position)
      : '전체'
  );

  const onPositionSelect = (position: Position) => {
    // 선택한 관심사가 '내 관심사'가 아닐 때
    if (position !== '내 관심사') {
      setSelectedPosition(position);
      return;
    }

    // 선택한 관심사가 '내 관심사'이며
    // 비로그인 상태일 때
    if (!user) {
      history.push({
        pathname: '/login',
        state: { from: `${location.pathname}?position=${position}` },
      });
      return;
    }

    // 선택한 관심사가 '내 관심사'이며
    // 로그인 상태인데
    // 유저의 직군 정보가 없을 경우
    if (!user.jobTitle) {
      history.push({
        pathname: `/position-selector`,
        state: { from: `${location.pathname}?position=${position}` },
      });
      return;
    }

    // 선택한 관심사가 '내 관심사'이며
    // 로그인 상태이며
    // 유저의 직군 정보도 있지만
    // 유저의 관심사 정보가 없을 경우
    if (!user.tags?.length) {
      history.push({
        pathname: `/interesting-selector/${
          POSITIONS_WITH_ID.find((p) => p.title === user.jobTitle)?.id
        }`,
        state: { from: `${location.pathname}?position=${position}` },
      });
      return;
    }

    // 선택한 관심사가 '내 관심사'이며
    // 로그인 상태이며
    // 유저의 직군 정보도 있고
    // 유저의 관심사 정보도 있을 경우
    setSelectedPosition(position);
  };

  // effect: 직군 필터에서 직군이 선택될 때마다 url의 query string에 관련 정보 입력
  // 그리고 관심사 태그 초기화(첫 렌더링 제외)
  useUpdateEffect(() => {
    mutateQueryString([{ key: 'position', value: selectedPosition }]);
    setSelectedTag(null);
  }, [selectedPosition]);

  const tagInURLQueryString = urlSearchParams.get('tag');
  const [selectedTag, setSelectedTag] = useState<null | string>(
    tagInURLQueryString
  );
  const onTagPress = useCallback(
    (tag: string) => {
      setSelectedTag(tag === selectedTag ? null : tag);
    },
    [setSelectedTag]
  );

  // effect: 관심사 태그가 변경될 때마다 url의 query string에 관련 정보 입력
  useUpdateEffect(() => {
    selectedTag
      ? mutateQueryString([{ key: 'tag', value: selectedTag }])
      : removeQueryString({ key: 'tag' });
  }, [selectedTag]);

  const userTags = user?.tags?.map((tag: any) => tag.value) || [];
  const displayingTags = useMemo(
    () =>
      selectedPosition === '내 관심사' ? userTags : POSITIONS[selectedPosition],
    [selectedPosition]
  );

  const sortingKeyInURLQueryString = urlSearchParams.get(
    'sorting'
  ) as SortingKey | null;
  const [currentSortingKey, setCurrentSortingKey] = useState<SortingKey>(
    sortingKeyInURLQueryString ?? '최신순'
  );

  const onSelectSortingKey = (sortingKey: SortingKey) => {
    setCurrentSortingKey(sortingKey);
  };

  // effect: 정렬 기준 필터에서 기준이 선택될 때마다 url의 query string에 관련 정보 입력
  useUpdateEffect(() => {
    mutateQueryString([{ key: 'sorting', value: currentSortingKey }]);
  }, [currentSortingKey]);

  // P.O/기획자, D.S/D.A에 대해 P.O / 기획자, 데이터 사이언티스트 둘다에 대해 tag 지정을 하는 이유:
  // 개편 이전의 사이트에서는 유저에게 각각 P.O / 기획자, 데이터 사이언티스트라는 이름으로 포지션 필터명이 제공되었기 때문에
  // 과거의 필터링 정보까지 포함하는 것
  const positionForWhere = ['내 관심사', 'P.O/기획자', 'D.S/D.A'].includes(
    selectedPosition
  )
    ? selectedPosition === '내 관심사'
      ? '전체'
      : selectedPosition === 'P.O/기획자'
      ? ['P.O / 기획자', 'P.O/기획자']
      : ['데이터 사이언티스트', 'D.S/D.A']
    : selectedPosition;

  const where: AnalyticsPathWhereInput = whereFromSearchParams || {
    path: {
      have: {
        published: {
          equalTo: true,
        },
        objectId: {
          exists: true,
        },
      },
    },
    AND: [
      {
        path: {
          have: {
            tags: {
              in: _.castArray(positionForWhere),
            },
          },
        },
      },
      {
        path: {
          have: {
            tags: {
              in: _.castArray(selectedTag ? selectedTag : positionForWhere),
            },
          },
        },
      },
    ],
  };

  const order: AnalyticsPathOrder[] = [
    currentSortingKey === '최신순'
      ? 'createdAt_DESC'
      : currentSortingKey === '이모지순'
      ? 'emojiTotalCount_DESC'
      : 'viewCount_DESC',
  ];

  const [pathCount, setPathCount] = useState<number>(0);

  const deviceType = useDeviceType();

  return (
    <View style={styles.container}>
      {screenMode && (
        <>
          <LFMetaTags
            title={
              `${urlSearchParams.get('title')} | 런핏` ||
              '러닝패스 - 커리어 성장시키는 나만의 학습 조합 | 런핏'
            }
            description="중요 학습노트로 구성한 알짜 지식콘텐츠!"
          />
          <NavBar title={urlSearchParams.get('title') || '러닝패스'} sticky />
        </>
      )}

      <View
        style={{ paddingHorizontal: 16 }}
        onLayout={(event) => {
          const layout = event.nativeEvent.layout;
          const h = layout.height;
          const y = layout.y;
          const t = layout.top;
          setListControllerContainerHeight(h + y + (screenMode ? 0 : t));
        }}
      >
        {!whereFromSearchParams && (
          <>
            <LFWhiteSpace size="md" direction="column" />
            <PositionSelector2
              justify={deviceType === 'PC' ? 'between' : 'start'}
              scrolling={deviceType === 'PC' ? false : true}
              style={{
                marginLeft: -16,
                marginRight: -16,
                paddingLeft: 16,
                paddingRight: 16,
              }}
              selectedPosition={selectedPosition}
              gap={8}
              onPressPositionItem={(position) => onPositionSelect(position)}
              data={[
                {
                  text: '전체',
                  imageAlt: '전체 콘텐츠 탐색',
                  defaultImageSource: require('../assets/images/newPositionAll@3x.png'),
                  activeImageSource: require('../assets/images/newPositionAll_selected@3x.png'),
                },
                {
                  text: '내 관심사',
                  imageAlt: '내 관심사 콘텐츠 탐색',
                  defaultImageSource: require('../assets/images/newPositionMy@3x.png'),
                  activeImageSource: require('../assets/images/newPositionMy_selected@3x.png'),
                },
                {
                  text: '개발자',
                  imageAlt: '개발자 콘텐츠 탐색',
                  defaultImageSource: require('../assets/images/newPositionDeveloper@3x.png'),
                  activeImageSource: require('../assets/images/newPositionDeveloper_selected@3x.png'),
                },
                {
                  text: 'P.O/기획자',
                  imageAlt: 'P.O/기획자 콘텐츠 탐색',
                  defaultImageSource: require('../assets/images/newPositionPO@3x.png'),
                  activeImageSource: require('../assets/images/newPositionPO_selected@3x.png'),
                },
                {
                  text: '디자이너',
                  imageAlt: '디자이너 콘텐츠 탐색',
                  defaultImageSource: require('../assets/images/newPositionDesigner@3x.png'),
                  activeImageSource: require('../assets/images/newPositionDesigner_selected@3x.png'),
                },
                {
                  text: 'D.S/D.A',
                  imageAlt: 'D.S/D.A 콘텐츠 탐색',
                  defaultImageSource: require('../assets/images/newPositionDS@3x.png'),
                  activeImageSource: require('../assets/images/newPositionDS_selected@3x.png'),
                },
                {
                  text: '마케터',
                  imageAlt: '마케터 콘텐츠 탐색',
                  defaultImageSource: require('../assets/images/newPositionMarketer@3x.png'),
                  activeImageSource: require('../assets/images/newPositionMarketer_selected@3x.png'),
                },
              ]}
            />
            <LFWhiteSpace size={'md'} direction="column" />
            <TagSelector
              selected={selectedTag}
              options={displayingTags}
              onTagPress={onTagPress}
            />
          </>
        )}
        <LinearGradient
          colors={[colors.BG_WHITE, colors.BG_2]}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          style={{ marginHorizontal: -16 }}
        >
          <LFWhiteSpace size="md" direction="column" />
          <Flex align="center" style={{ paddingHorizontal: 16 }}>
            {/* <LFText
              style={styles.pathCount}
            >{`러닝패스 ${pathCount.toLocaleString()}개`}</LFText> */}
            <SortingKeySelector
              currentOption={currentSortingKey}
              style={{ marginLeft: 'auto' }}
              onSelect={onSelectSortingKey}
              textColor="TEXT_80"
              textScale="md"
            />
          </Flex>
          <LFWhiteSpace size="md" />
        </LinearGradient>
      </View>
      <Suspense fallback={<ActivityIndicator />}>
        {/* BasePathList는 이전에 rendering 했던 갯수만큼 path를 가져옵니다 */}
        {/* 단, 뒤로 가기 버튼으로 접근한게 아니라면 path를 최대 20개만 가져옵니다 */}
        <BasePathList
          columnsPerRow={deviceType === 'MOBILE' ? 1 : 2}
          initialScrollTop={accessFromHistoryBack ? localStorageScrollTop : 0}
          first={
            accessFromHistoryBack ? localStorageScrollPagination : MORE_NUMBER
          }
          onChangePagination={(count) => {
            if (count < MORE_NUMBER) {
              setLocalStorageScrollPagination(MORE_NUMBER);
            } else {
              setLocalStorageScrollPagination(count);
            }
          }}
          onChangeCount={setPathCount}
          style={{ paddingHorizontal: 16, backgroundColor: colors.BG_2 }}
          where={where}
          order={order}
          onPressItemTo={(objectId) => `/path/${objectId}`}
          ListEmptyComponent={<EmptyList />}
          gutter={[8, 16]}
        />
      </Suspense>
      {screenMode && (
        <>
          <LFWhiteSpace size="xl" />
          <Footer />
        </>
      )}
    </View>
  );
};

export default AllPathListScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.BG_WHITE,
  },
  pathCount: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
});
