import { StyleSheet, View } from 'react-native';
import React, { useState } from 'react';
import NavBar2, {
  CreateNavButton,
  SearchNavButton,
  UserProfileNavButton,
} from '../components/NavBar2';
import { Flex } from '@ant-design/react-native';
import LFWhiteSpace from '../components/LFWhiteSpace';
import NotificationNavButtonWithSuspense from '../components/NotificationNavButton';
import { useAuth } from '../hooks/auth';
import _ from 'lodash';
import Footer from '../components/Footer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../App.css';
import LFMetaTags from '../components/LFMetaTags';
import CreateContentItemButton from '../components/CreateContentItemButton';
import OnBoardingModal from '../components/OnBoardingModal';
import HomeMainTab from './HomeScreen/HomeMainTab';
import HomeTabSelector, { Tab } from './HomeScreen/HomeTabSelector';
import { useHistory, useLocation } from 'react-router-dom';
import AllContentItemListScreen from './AllContentItemListScreen';
import AllPathListScreen from './AllPathListScreen';
import { colors } from '../constants/styleGuide';
import { useDeviceType } from '../hooks/deviceType';
import getEncodedQueryString from '../helper/getEncodedQueryString';

const HomeScreen = () => {
  const { user } = useAuth();
  const [showOnBoardingModal, setShowOnBoardingModal] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const currentTab =
    location.pathname === '/paths'
      ? '러닝패스'
      : location.pathname === '/notes'
      ? '학습노트'
      : '홈';
  const currentQueryString = getEncodedQueryString(location.search);
  const deviceType = useDeviceType();
  return (
    <View style={styles.container}>
      <LFMetaTags
        title={
          currentTab === '러닝패스'
            ? '러닝패스 - 커리어 성장시키는 나만의 학습 조합 | 런핏'
            : currentTab === '학습노트'
            ? '학습노트 - 차곡차곡 기록하는 나만의 학습 재료 | 런핏'
            : '런핏 - 나만의 학습 레시핏 | LearnFiT'
        }
        description="취준생, 일잘러들을 위한 1,000개의 학습 레시피를 확인하고, 나만의 학습 레시피를 공유해서 수익을 창출하세요!"
        canonicalLink={
          currentTab === '러닝패스'
            ? 'https://www.learnfit.ai/paths' + currentQueryString
            : currentTab === '학습노트'
            ? 'https://www.learnfit.ai/notes' + currentQueryString
            : 'https://www.learnfit.ai'
        }
      />
      <NavBar2
        style={{ backgroundColor: colors.BG_WHITE }}
        homeMode={true}
        title={''}
        sticky
        right={
          <Flex>
            <CreateNavButton />
            <LFWhiteSpace direction="row" size="xs" />
            <SearchNavButton color="black" />
            <LFWhiteSpace direction="row" size="xs" />
            {user ? (
              <>
                <NotificationNavButtonWithSuspense />
                <LFWhiteSpace direction="row" size="xs" />
              </>
            ) : null}
            <UserProfileNavButton />
          </Flex>
        }
      />
      <HomeTabSelector
        style={
          deviceType === 'MOBILE'
            ? styles.tabSelectorForMobile
            : styles.tabSelectorForPC
        }
        currentTab={currentTab}
        onSelectTab={(tab) => {
          history.push({
            pathname:
              tab === '러닝패스'
                ? '/paths'
                : tab === '학습노트'
                ? '/notes'
                : '/',
          });
        }}
      />
      {currentTab === '홈' && <HomeMainTab />}
      {currentTab === '학습노트' && (
        <AllContentItemListScreen screenMode={false} />
      )}
      {currentTab === '러닝패스' && <AllPathListScreen screenMode={false} />}
      <Footer />
      <Flex style={styles.floatingButtonWrap}>
        <CreateContentItemButton
          testID="floating-plus-btn:FloatingCreateButton"
          delay={5000}
        />
      </Flex>
      {showOnBoardingModal && (
        <OnBoardingModal
          visible={showOnBoardingModal}
          noAgainId={'home-welcome-onboarding-v1'}
          onClose={() => {
            setShowOnBoardingModal(false);
          }}
          scrollable={false}
        />
      )}
    </View>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: { backgroundColor: colors.BG_WHITE, flex: 1 },
  tabSelectorForMobile: {
    // @ts-ignore
    position: 'sticky',
    top: 64,
    zIndex: 10000,
    paddingHorizontal: 24,
    backgroundColor: colors.BG_WHITE,
  },
  tabSelectorForPC: {
    // @ts-ignore
    position: 'sticky',
    top: 64,
    zIndex: 10000,
    paddingHorizontal: 72,
    backgroundColor: colors.BG_WHITE,
  },
  floatingButtonWrap: {
    paddingRight: 24,
    paddingBottom: 24,
    //@ts-ignore
    position: 'sticky',
    marginTop: -80,
    flexDirection: 'row',
    bottom: 0,
    zIndex: 1,
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
  },

  gradientBackground: {
    position: 'absolute',
    bottom: -60,
    left: 0,
    right: 0,
    marginHorizontal: -12,
    zIndex: -1,
  },
});
