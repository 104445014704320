import _ from 'lodash';
import React, { useCallback } from 'react';
import { Platform, StyleSheet, TextInputProps, TextInput } from 'react-native';

const adjustGrowingHeight = (ref: any) => {
  const polyfillGrowing = Platform.select({
    web: () => {
      ref.style.height = '';
      ref.style.height = ref.scrollHeight + 2 + 'px';
    },
  });
  _.isFunction(polyfillGrowing) && polyfillGrowing();
};
const MultilineGrowingTextInput: React.FC<TextInputProps> = ({
  onChange,
  ref,
  style,
  ...props
}) => {
  const adjustInitialHeight = useCallback(
    _.once((ref: any) => {
      adjustGrowingHeight(ref);
    }),
    []
  );
  return (
    <TextInput
      ref={(element) => {
        if (element && element.style) {
          adjustInitialHeight(element);
        }
        if (ref) {
          ref.current = element;
        }
      }}
      {...props}
      style={[style]}
      onChange={(e) => {
        adjustGrowingHeight(e.target);
        _.isFunction(onChange) && onChange(e);
      }}
      // onContentSizeChange={(e) => {
      //   // setHeight(e.nativeEvent.contentSize.height);
      // }}
      multiline={true}
    />
  );
};

export default MultilineGrowingTextInput;

const styles = StyleSheet.create({});
