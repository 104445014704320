/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ContentItemAuthorSummaryFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly content: {
        readonly id: string;
        readonly objectId: string;
    } | null;
    readonly author: {
        readonly name: string | null;
        readonly profileImage: {
            readonly url: string;
        } | null;
        readonly originProfileURL: string | null;
        readonly id: string;
        readonly objectId: string;
        readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
    } | null;
    readonly " $refType": "ContentItemAuthorSummaryFragment";
};
export type ContentItemAuthorSummaryFragment$data = ContentItemAuthorSummaryFragment;
export type ContentItemAuthorSummaryFragment$key = {
    readonly " $data"?: ContentItemAuthorSummaryFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ContentItemAuthorSummaryFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentItemAuthorSummaryFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FileInfo",
          "kind": "LinkedField",
          "name": "profileImage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originProfileURL",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileImageFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContentItem",
  "abstractKey": null
};
})();
(node as any).hash = '3d7acc3b2c8805fa12bbacc5ec3798d7';
export default node;
