export const colors = {
  PRIMARY_10: 'rgba(35, 110, 255, 0.1)',
  SECONDARY_G_100: '#c9f963',
  SECONDARY_G_20: 'rgba(201, 246, 106, 0.2)',
  SECONDARY_BLACK: '#1a1b1e',
  SECONDARY_Y_20: 'rgba(246, 210, 21, 0.2)',
  SECONDARY_Y_100: '#fcdc33',
  SECONDARY_WHITE: '#ffffff',
  TEXT_WHITE: '#ffffff',
  PRIMARY_20: 'rgba(35, 110, 255, 0.2)',
  PRIMARY_100: '#236eff',
  TEXT_100: '#1a1b1e',
  TEXT_80: 'rgba(26, 27, 30, 0.8)',
  TEXT_30: 'rgba(26, 27, 30, 0.3)',
  TEXT_60: 'rgba(26, 27, 30, 0.6)',
  TEXT_DARK: '#236eff',
  BG_WHITE: '#ffffff',
  BG_1: '#fdfdfd',
  BG_2: '#f6f6fa',
  BG_4: '#e0e2ec',
  BUTTON_SECONDARY_ACTIVE: '#f8f8fa',
  BUTTON_DISABLE: '#eff0f1',
  BORDER_WHITE: '#ffffff',
  BORDER_10: 'rgba(26, 27, 30, 0.1)',
  BORDER_20: 'rgba(26, 27, 30, 0.2)',
  BORDER_40: 'rgba(26, 27, 30, 0.4)',
  BORDER_80: 'rgba(26, 27, 30, 0.8)',
  BG_3: '#f0f1f6',
  GRADATION_BG: '#ffffff',
  RED: '#ff2e00',
};
