import { Dimensions, Image, StyleSheet, ViewStyle } from 'react-native';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Flex } from '@ant-design/react-native';
import LFLink from './LFLink';
import { colors } from '../constants/styleGuide';
import LFText from './typo/LFText';
import _ from 'lodash';
import { useUpdateEffect } from 'ahooks';
interface IPaginationButton {
  scrollRef?: React.MutableRefObject<HTMLElement | null>;
  isNote?: boolean;
  isPath?: boolean;
  numberOfNote?: number | undefined;
  numberOfPath?: number | undefined;
  style?: ViewStyle;
}

export const PaginationButton: React.FC<IPaginationButton> = ({
  scrollRef,
  isNote,
  isPath,
  numberOfNote,
  numberOfPath,
  style,
}) => {
  const [scrollLeftValue, setScrollLeftValue] = useState<number>(0);
  let scrollLeftOfRefElement = scrollRef?.current?.scrollLeft || 0;
  let totalScrollWidthOfNote =
    numberOfNote === 3
      ? 48
      : numberOfNote && numberOfNote > 3
      ? 248 * (numberOfNote - 3) + 48
      : null;
  // MEMO: (카드 기본 width + padding or margin width) * (학습노트 카드 수 - 4) + 48
  // 48은 카드 제외한 나머지 공간에 대한 width
  // 학습노트 4개 이상일 때부터 스크롤이 248 만큼 우측 이동하기 때문에 -3 추가
  // 학습노트 3개 일때는 일부만 보이는 노트의 가려진 폭 만큼만 스크롤 이동하기 때문에 48 고정
  let totalScrollWidthOfPath =
    numberOfPath && numberOfPath > 2 && 348 * (numberOfPath - 2);
  // MEMO: (카드 기본 width + padding or margin width) * 러닝패스 카드 수 - 2
  // 러닝패스 3개 이상일 때부터 스크롤이 동작(348 만큼 우측 이동)하기 때문에 -2 추가

  const scrollValue = {
    note: {
      goLeft: -(248 * 2),
      goRight: 248 * 2,
      // MEMO: 248: 학습노트 카드 width + 노트 카드 paddingRight 또는 marginRight value
    },

    path: {
      goLeft: -(348 * 2),
      goRight: 348 * 2,
      // MEMO: 348: 러닝패스 카드 width + 러닝패스 카드 paddingRight 또는 marginRight value
    },
  };

  const onScroll = (scrollOffset: number) => {
    setScrollLeftValue(() => (scrollLeftOfRefElement += scrollOffset));
  };

  useEffect(() => {
    scrollRef?.current?.scrollTo({ left: scrollLeftValue, behavior: 'smooth' });
  }, [scrollLeftValue]);

  return (
    <Flex style={[{ alignSelf: 'flex-end' }, style]}>
      <LFLink
        disabled={scrollLeftOfRefElement === 0}
        onPress={() => {
          isNote
            ? onScroll(scrollValue.note.goLeft)
            : onScroll(scrollValue.path.goLeft);
        }}
        style={
          scrollLeftOfRefElement === 0
            ? styles.inActivePaginationLeftButton
            : styles.paginationLeftButton
        }
      >
        <Image
          accessibilityLabel="왼쪽 방향으로 스크롤"
          style={
            scrollLeftOfRefElement === 0
              ? styles.inActiveArrowLeftImage
              : styles.arrowLeftImage
          }
          source={require('../assets/images/arrowLeftOfPagination@3x.png')}
        />
      </LFLink>
      <LFLink
        disabled={
          (numberOfNote && numberOfNote < 4) ||
          scrollLeftOfRefElement === totalScrollWidthOfNote ||
          (numberOfPath && numberOfPath < 3) ||
          scrollLeftOfRefElement === totalScrollWidthOfPath
        }
        onPress={() => {
          isNote
            ? onScroll(scrollValue.note.goRight)
            : onScroll(scrollValue.path.goRight);
        }}
        style={
          isPath &&
          ((numberOfPath && numberOfPath < 3) ||
            scrollLeftOfRefElement === totalScrollWidthOfPath)
            ? styles.inActivePaginationRightButton
            : isNote &&
              ((numberOfNote && numberOfNote < 4) ||
                scrollLeftOfRefElement === totalScrollWidthOfNote)
            ? styles.inActivePaginationRightButton
            : styles.paginationRightButton
        }
      >
        <Image
          accessibilityLabel="오른쪽 방향으로 스크롤"
          style={
            isPath &&
            ((numberOfPath && numberOfPath < 3) ||
              scrollLeftOfRefElement === totalScrollWidthOfPath)
              ? styles.inActiveArrowRightImage
              : isNote &&
                ((numberOfNote && numberOfNote < 4) ||
                  scrollLeftOfRefElement === totalScrollWidthOfNote)
              ? styles.inActiveArrowRightImage
              : styles.arrowRightImage
          }
          source={require('../assets/images/arrowRightOfPagination@3x.png')}
        />
      </LFLink>
    </Flex>
  );
};

export default PaginationButton;

export const PaginationButton2: React.FC<{
  scrollContainerRef?: React.MutableRefObject<HTMLElement | null>;
  scrollContainerDragging?: boolean;
}> = ({ scrollContainerRef, scrollContainerDragging = false }) => {
  // 현재 스크롤된 수치
  const [scrollLeft, setScrollLeft] = useState(0);
  useUpdateEffect(() => {
    scrollContainerRef?.current?.scrollTo({
      left: scrollLeft,
      behavior: 'smooth',
    });
  }, [scrollLeft]);

  // 화면에 보이는 스크롤 영역
  const [scrollViewportWidth, setScrollViewportWidth] = useState(0);

  // 스크롤 가능한 전체 크기
  const [scrollTotalWidth, setScrollTotalWidth] = useState(0);

  const setScrollInfo = () => {
    setScrollLeft(scrollContainerRef?.current?.scrollLeft || 0);
    setScrollViewportWidth(scrollContainerRef?.current?.offsetWidth || 0);
    setScrollTotalWidth(scrollContainerRef?.current?.scrollWidth || 0);
  };

  useEffect(() => {
    !scrollContainerDragging && setScrollInfo();
  }, [scrollContainerDragging]);

  const onClickLeft = () => {
    setScrollLeft((prev) => {
      const move = Math.max(prev - scrollViewportWidth, 0);
      return move;
    });
  };
  const onClickRight = (scrollOffset: number, scrollTotalWidth: number) => {
    setScrollLeft((prev) => {
      const scrollableWidth = scrollTotalWidth - (prev + scrollOffset);
      const move = prev + Math.min(scrollableWidth, scrollOffset);
      return move;
    });
  };

  const isLeftButtonInActive = scrollLeft === 0;
  const isRightButtonInActive =
    scrollLeft + scrollViewportWidth >= scrollTotalWidth;

  // scrollInfo 초기 설정
  // Pagination2가 쓰이는 ProgressingPaths는 HomeMainTab에서 호출됩니다.
  // ProgressingPaths는 Suspense에 묶여서 호출됩니다.
  // ProgressingPaths는 다른 컴포넌트의 로딩을 기다리는 동안 Skeleton UI 뒤에 숨어 기다립니다.
  // 이때, ProgressingPaths 내부에서 scrollContainerRef에 대한 계산이 이뤄지지만
  // 스켈레톤 UI 때문에 아직 화면에 나오기 전이므로 scrollLeft, scrollTotalWidth, scrollViewportWidth가 계산이 되질 않습니다. <= 아래 useEffect를 작성하게 된 계기
  // 그래서 setInterval을 통해 0.1초 마다 scroll의 값을 읽기 시도 후 값이 확인되면 그때 scroll 관련 값들의 셋팅을 합니다.
  // scroll 관련 값들이 셋팅되면 setInterval의 간격을 1분으로 바꿉니다. 그리고 더 이상 setScrollInfo의 호출은 이뤄지지 않게 됩니다.
  useEffect(() => {
    const renderingYet = scrollTotalWidth === 0;
    const ms = renderingYet ? 1000 * 0.1 : 1000 * 60;
    const timeId = setInterval(() => {
      renderingYet && setScrollInfo();
    }, ms);
    return () => {
      clearInterval(timeId);
    };
  }, [scrollTotalWidth]);
  return (
    <Flex style={[{ alignSelf: 'flex-end' }]}>
      <LFLink
        disabled={isLeftButtonInActive}
        onPress={onClickLeft}
        style={
          isLeftButtonInActive
            ? styles.inActivePaginationLeftButton
            : styles.paginationLeftButton
        }
      >
        <Image
          accessibilityLabel="왼쪽 방향으로 스크롤"
          style={
            isLeftButtonInActive
              ? styles.inActiveArrowLeftImage
              : styles.arrowLeftImage
          }
          source={require('../assets/images/arrowLeftOfPagination@3x.png')}
        />
      </LFLink>
      <LFLink
        disabled={isRightButtonInActive}
        onPress={() => {
          onClickRight(scrollViewportWidth, scrollTotalWidth);
        }}
        style={
          isRightButtonInActive
            ? styles.inActivePaginationRightButton
            : styles.paginationRightButton
        }
      >
        <Image
          accessibilityLabel="오른쪽 방향으로 스크롤"
          style={
            isRightButtonInActive
              ? styles.inActiveArrowRightImage
              : styles.arrowRightImage
          }
          source={require('../assets/images/arrowRightOfPagination@3x.png')}
        />
      </LFLink>
    </Flex>
  );
};

interface IInfinitePaginationButton {
  onPrevClick: () => void;
  onNextClick: () => void;
  currentPromoCardIdx: number;
  totalNumOfPromoCard: number | undefined;
  style?: ViewStyle;
}

export const InfinitePaginationButton: React.FC<IInfinitePaginationButton> = ({
  currentPromoCardIdx,
  totalNumOfPromoCard,
  onNextClick,
  onPrevClick,
  style,
}) => {
  return (
    <Flex justify="between" style={[styles.Container, style]}>
      <LFText style={styles.pageNumber}>
        {currentPromoCardIdx + 1}/{totalNumOfPromoCard}
      </LFText>
      <LFLink onPress={onPrevClick} style={styles.infinitePaginationLeftButton}>
        <Image
          accessibilityLabel="이전 프로모션 페이지로 가기"
          style={{ width: 24, height: 24 }}
          source={require('../assets/images/arrowLeftOfPagination@3x.png')}
        />
      </LFLink>
      <LFLink
        onPress={onNextClick}
        style={styles.infinitePaginationRightButton}
      >
        <Image
          accessibilityLabel="다음 프로모션 페이지로 가기"
          style={{ width: 24, height: 24 }}
          source={require('../assets/images/arrowRightOfPagination@3x.png')}
        />
      </LFLink>
    </Flex>
  );
};

interface IPaginationSliderButton {
  onPrevClick: () => void | undefined;
  onNextClick: () => void | undefined;
  first?: boolean;
  last?: boolean;
  numberOfSlides?: number | undefined;
  style?: ViewStyle;
}

export const PaginationSliderButton: React.FC<IPaginationSliderButton> = ({
  onPrevClick,
  onNextClick,
  first,
  last,
  numberOfSlides = 0,
  style,
}) => {
  return (
    <Flex style={[{ alignSelf: 'flex-end' }, style]}>
      <LFLink
        disabled={first || numberOfSlides < 2}
        onPress={onPrevClick}
        style={
          first
            ? styles.inActivePaginationLeftButton
            : styles.paginationLeftButton
        }
      >
        <Image
          style={first ? styles.inActiveArrowLeftImage : styles.arrowLeftImage}
          source={require('../assets/images/arrowLeftOfPagination@3x.png')}
        />
      </LFLink>
      <LFLink
        disabled={last || numberOfSlides < 2}
        onPress={onNextClick}
        style={
          last || numberOfSlides < 2
            ? styles.inActivePaginationRightButton
            : styles.paginationRightButton
        }
      >
        <Image
          style={
            last || numberOfSlides < 2
              ? styles.inActiveArrowRightImage
              : styles.arrowRightImage
          }
          source={require('../assets/images/arrowRightOfPagination@3x.png')}
        />
      </LFLink>
    </Flex>
  );
};

const styles = StyleSheet.create({
  Container: {
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 5,
    width: 114,
    height: 32,
    alignSelf: 'flex-end',
    backgroundColor: colors.SECONDARY_BLACK,
    borderRadius: 50,
  },

  pageNumber: {
    width: 38,
    height: 23,
    opacity: 0.9,
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.SECONDARY_WHITE,
    textShadowColor: '#3a456333.00000cc',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 10,
  },

  infinitePaginationLeftButton: {
    width: 24,
    height: 24,
    opacity: 0.8,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  infinitePaginationRightButton: {
    width: 24,
    height: 24,
    opacity: 0.8,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  paginationLeftButton: {
    width: 36,
    height: 32,
    opacity: 0.8,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  paginationRightButton: {
    width: 36,
    height: 32,
    opacity: 0.8,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  inActivePaginationLeftButton: {
    width: 36,
    height: 32,
    opacity: 0.3,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  inActivePaginationRightButton: {
    width: 36,
    height: 32,
    opacity: 0.3,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  arrowLeftImage: {
    width: 24,
    height: 24,
    opacity: 0.8,
  },
  arrowRightImage: {
    width: 24,
    height: 24,
    opacity: 0.8,
  },
  inActiveArrowLeftImage: {
    width: 24,
    height: 24,
    opacity: 0.3,
  },
  inActiveArrowRightImage: {
    width: 24,
    height: 24,
    opacity: 0.3,
  },
});
