/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type NotificationScreenMeQueryVariables = {
    userId: string;
};
export type NotificationScreenMeQueryResponse = {
    readonly me: {
        readonly objectId: string;
        readonly id: string;
        readonly createdAtOfLastSeenNotification: unknown | null;
    };
};
export type NotificationScreenMeQuery = {
    readonly response: NotificationScreenMeQueryResponse;
    readonly variables: NotificationScreenMeQueryVariables;
};



/*
query NotificationScreenMeQuery(
  $userId: ID!
) {
  me: user(id: $userId) {
    objectId
    id
    createdAtOfLastSeenNotification
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": "me",
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "userId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "objectId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAtOfLastSeenNotification",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationScreenMeQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationScreenMeQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4c04552ebc0e18cc35c017eb8d423f72",
    "id": null,
    "metadata": {},
    "name": "NotificationScreenMeQuery",
    "operationKind": "query",
    "text": "query NotificationScreenMeQuery(\n  $userId: ID!\n) {\n  me: user(id: $userId) {\n    objectId\n    id\n    createdAtOfLastSeenNotification\n  }\n}\n"
  }
};
})();
(node as any).hash = '931cb612b3cded8c44a05c7c6ce184a4';
export default node;
