import { Flex } from '@ant-design/react-native';
import _ from 'lodash';
import React from 'react';
import { StyleSheet, Text, View, Image, ViewStyle } from 'react-native';
import { graphql, useFragment } from 'react-relay';
import { useHistory } from 'react-router';
import { resizedImageURL } from '../helper/resizedImageURL';
import { UserProfileViewUserFragment$key } from '../__generated__/UserProfileViewUserFragment.graphql';
import LFLink from './LFLink';
import LFText from './typo/LFText';

export interface UserProfileViewProps {
  userFrgmt: UserProfileViewUserFragment$key;
  onPress?: () => void;
  disabled: boolean;
  testID: string;
  style?: ViewStyle;
}
const UserProfileView: React.FC<UserProfileViewProps> = ({
  userFrgmt,
  onPress,
  disabled = false,
  testID,
  style,
}) => {
  const history = useHistory();
  const user = useFragment(
    graphql`
      fragment UserProfileViewUserFragment on User {
        id
        objectId
        name
        originProfileURL
        jobTitle
        profileImage {
          url
        }
      }
    `,
    userFrgmt
  );
  return (
    <LFLink
      disabled={disabled}
      testID={testID}
      style={styles.container}
      onPress={() => {
        _.isFunction(onPress) ? onPress() : history.push(`/u/${user.objectId}`);
      }}
    >
      <Image
        accessibilityLabel={`${user?.name || ''}의 프로필 이미지`}
        style={styles.profileImage}
        source={{
          uri: resizedImageURL(
            user?.profileImage?.url,
            200,
            200,
            user?.originProfileURL
          ),
        }}
      />
      <View>
        <LFText style={styles.userName}>{user.name}</LFText>
        <LFText style={styles.userJob}>{user.jobTitle}</LFText>
      </View>
    </LFLink>
  );
};

export default UserProfileView;

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center' },
  userProfile: { width: 24, height: 24, borderRadius: 12, marginRight: 5 },
  userNameInNoteList: {
    fontSize: 15,
    fontWeight: 'bold',
    color: '#787878',
  },
  profileImage: {
    width: 36,
    height: 36,
    borderRadius: 18,
    marginRight: 8,
  },
  userName: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 24,
    color: 'rgba(26, 27, 30, 0.8)',
  },
  userJob: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 10,
    lineHeight: 16,
    color: 'rgba(26, 27, 30, 0.8)',
  },
});
