/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PathItemViewHistoryQueryVariables = {
    pathId?: string | null;
    userId?: string | null;
    skipHistory: boolean;
};
export type PathItemViewHistoryQueryResponse = {
    readonly pathHistory?: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly status: string | null;
                readonly updatedAt: unknown;
            } | null;
        } | null> | null;
    };
};
export type PathItemViewHistoryQuery = {
    readonly response: PathItemViewHistoryQueryResponse;
    readonly variables: PathItemViewHistoryQueryVariables;
};



/*
query PathItemViewHistoryQuery(
  $pathId: ID
  $userId: ID
  $skipHistory: Boolean!
) {
  pathHistory: learningHistories(where: {AND: [{path: {have: {objectId: {equalTo: $pathId}}}}, {author: {have: {objectId: {equalTo: $userId}}}}], author: {have: {objectId: {equalTo: $userId}}}}) @skip(if: $skipHistory) {
    edges {
      node {
        id
        objectId
        status
        updatedAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pathId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipHistory"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "fields": [
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "equalTo",
              "variableName": "userId"
            }
          ],
          "kind": "ObjectValue",
          "name": "objectId"
        }
      ],
      "kind": "ObjectValue",
      "name": "have"
    }
  ],
  "kind": "ObjectValue",
  "name": "author"
},
v4 = [
  {
    "condition": "skipHistory",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": "pathHistory",
        "args": [
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "equalTo",
                                    "variableName": "pathId"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "objectId"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "have"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "path"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "AND.0"
                  },
                  {
                    "fields": [
                      (v3/*: any*/)
                    ],
                    "kind": "ObjectValue",
                    "name": "AND.1"
                  }
                ],
                "kind": "ListValue",
                "name": "AND"
              },
              (v3/*: any*/)
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "LearningHistoryConnection",
        "kind": "LinkedField",
        "name": "learningHistories",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LearningHistoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LearningHistory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objectId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PathItemViewHistoryQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PathItemViewHistoryQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a1ba720d3420f5efe7e76f8d303890da",
    "id": null,
    "metadata": {},
    "name": "PathItemViewHistoryQuery",
    "operationKind": "query",
    "text": "query PathItemViewHistoryQuery(\n  $pathId: ID\n  $userId: ID\n  $skipHistory: Boolean!\n) {\n  pathHistory: learningHistories(where: {AND: [{path: {have: {objectId: {equalTo: $pathId}}}}, {author: {have: {objectId: {equalTo: $userId}}}}], author: {have: {objectId: {equalTo: $userId}}}}) @skip(if: $skipHistory) {\n    edges {\n      node {\n        id\n        objectId\n        status\n        updatedAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '91a6d42c82730ebe3319b7667ee35f9e';
export default node;
