import { StyleSheet, View } from 'react-native';
import React from 'react';
import NavBar from '../components/NavBar2';
import { colors } from '../constants/styleGuide';
import { useHistory, useParams } from 'react-router-dom';
import BaseContentItemList from '../components/BaseContentItemList';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { UserContentItemListScreenUserQuery } from '../__generated__/UserContentItemListScreenUserQuery.graphql';
import { useAuth } from '../hooks/auth';
import EmptyList from '../components/EmptyList';
import { AnalyticsContentItemWhereInput } from '../__generated__/BaseContentItemListPaginationFragmentQuery.graphql';

const UserContentItemListScreen = () => {
  const history = useHistory();
  const { userObjectId } = useParams<{ userObjectId: string }>();
  const { user } = useLazyLoadQuery<UserContentItemListScreenUserQuery>(
    graphql`
      query UserContentItemListScreenUserQuery($userObjectId: ID!) {
        user(id: $userObjectId) {
          id
          name
        }
      }
    `,
    { userObjectId },
    {
      fetchPolicy: 'store-and-network',
    }
  );
  const { user: loginUser } = useAuth();
  const isMe = loginUser?.objectId === userObjectId;
  const where: AnalyticsContentItemWhereInput = {
    contentItem: {
      have: {
        author: { have: { objectId: { equalTo: userObjectId } } },
      },
    },
  };
  if (isMe === false) {
    where.contentItem!.have!.published = {
      equalTo: true,
    };
  }
  return (
    <View style={styles.container}>
      <NavBar
        title={isMe ? '내 학습노트' : `${user.name}님의 학습노트`}
        sticky
      />
      <BaseContentItemList
        ListEmptyComponent={
          <EmptyList
            style={{
              // @ts-ignore
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: 'center',
              zIndex: -1,
            }}
          />
        }
        where={where}
        order={['updatedAt_DESC']}
        onPressItem={(objectId, contentObjectId) => {
          history.push(`/note/${contentObjectId}/${objectId}`);
        }}
      />
    </View>
  );
};

export default UserContentItemListScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.BG_WHITE,
  },
});
