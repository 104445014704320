import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import LFText from './typo/LFText';
import { Flex } from '@ant-design/react-native';
import LFWhiteSpace from './LFWhiteSpace';
import LFLink from './LFLink';
import { colors } from '../constants/styleGuide';

const InfoBoxNotPublishedContent: React.FC<{
  published: boolean | null;
  onPublishButtonPress: (status: true) => void;
}> = ({ published, onPublishButtonPress }) => {
  return !published ? (
    <Flex
      style={{
        padding: 16,
        backgroundColor: '#ffd165',
        borderRadius: 12,
        marginVertical: 10,
      }}
    >
      <LFText>❗️ 현재 비공개 상태입니다.</LFText>
      <LFWhiteSpace size="sm" direction="row"></LFWhiteSpace>
      <LFLink onPress={() => onPublishButtonPress(!published)}>
        <LFText style={{ color: colors.PRIMARY_100 }}>공개하기</LFText>
      </LFLink>
    </Flex>
  ) : null;
};

export default InfoBoxNotPublishedContent;

const styles = StyleSheet.create({});
