import { StyleSheet, View, ViewStyle } from 'react-native';
import React, { useEffect, ReactNode, useRef, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import _ from 'lodash';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Flex } from '@ant-design/react-native';
import { usePrevious } from 'ahooks';
import {
  BaseHorizontalCuratorItemListQuery,
  UserOrder,
  UserWhereInput,
} from '../__generated__/BaseHorizontalCuratorItemListQuery.graphql';
import CuratorItemView from './CuratorItemView';
import { useDeviceType } from '../hooks/deviceType';
import LFWhiteSpace from './LFWhiteSpace';
import PaginationButton from './PaginationButton';
interface IBaseHorizontalCuratorItemList {
  where: UserWhereInput; // 데이터 조건
  order: UserOrder[]; // 데이터 순서
  first: number; // 데이터 갯수
  onChangeCount?: (count: number) => void; // fetch 후 count를 외부로 전달
  gap?: number; // 각 아이템의 간격
  style?: React.CSSProperties; // 리스트에 대한 style
  itemStyle?: ViewStyle; // 각 아이템에 대한 style
  paginationButton?: boolean; // 페이지네이션 버튼 노출
}

const BaseHorizontalCuratorItemList: React.FC<IBaseHorizontalCuratorItemList> =
  ({
    where,
    order,
    first,
    onChangeCount,
    gap,
    style,
    itemStyle,
    paginationButton = true,
  }) => {
    const { curators } = useLazyLoadQuery<BaseHorizontalCuratorItemListQuery>(
      graphql`
        query BaseHorizontalCuratorItemListQuery(
          $where: UserWhereInput
          $order: [UserOrder!]
          $first: Int
        ) {
          curators: users(where: $where, order: $order, first: $first) {
            count
            edges {
              node {
                id
                objectId
              }
            }
          }
        }
      `,
      { where, first, order }
    );

    useEffect(() => {
      _.isFunction(onChangeCount) && onChangeCount(curators.count);
    }, [curators.count]);

    const curatorIds =
      curators.edges?.map((edge) => edge?.node?.objectId) || [];
    const gapAfterContainer = style?.paddingRight || style?.padding;
    const scrollContainerRef = useRef<HTMLElement | null>(null);
    const [isSliding, setIsSliding] = useState(false);
    const previousIsSliding = usePrevious(isSliding);

    // BaseHorizontalPathList 컴포넌트가 리렌더링 될 때마다 가로 스크롤 위치 초기화
    // useEffect(() => {
    //   // 예외: isSliding 값 수정으로인한 리렌더링 시엔 가로스크롤 위치 초기화x
    //   if (isSliding !== previousIsSliding) return;
    //   setTimeout(() => {
    //     if (scrollContainerRef.current) {
    //       scrollContainerRef.current.scrollTo({ left: 0 });
    //     }
    //   }, 100);
    // });

    const [scrollEnabled, setScrollEnabled] = useState(true);
    const deviceType = useDeviceType();
    return (
      <>
        {paginationButton && deviceType === 'PC' && (
          <View>
            <PaginationButton
              isPath
              numberOfPath={curatorIds.length}
              scrollRef={scrollContainerRef}
            />
            <LFWhiteSpace size={'md'} />
          </View>
        )}
        <ScrollContainer
          horizontal={scrollEnabled}
          nativeMobileScroll
          innerRef={scrollContainerRef}
          onStartScroll={() => {
            setIsSliding(true);
          }}
          onEndScroll={() => {
            setTimeout(() => {
              setIsSliding(false);
            }, 0);
          }}
          style={style}
        >
          <Flex align="stretch">
            {curatorIds.map((curatorId, index) => (
              <View
                key={index}
                style={[
                  itemStyle,
                  { marginRight: curatorIds.length === index + 1 ? 0 : gap },
                ]}
              >
                <CuratorItemView
                  disableMode={isSliding}
                  userObjectId={curatorId || ''}
                  onScrollStart={() => {
                    setScrollEnabled(false);
                  }}
                  onScrollEnd={() => {
                    setScrollEnabled(true);
                  }}
                />
              </View>
            ))}
            {/* 
            스크롤 가능한 UI 컨테이너에서 끝쪽 margin, padding은 스크롤 컨테이너 내부에서 적용되지 않는 점을 알게 되어
            별도로 element를 두어 paddingRight, marginRight의 역할을 대신하도록 조치했습니다.
            링크: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout 
          */}
            {gapAfterContainer && (
              <Flex style={{ width: Number(gapAfterContainer), height: 1 }} />
            )}
          </Flex>
        </ScrollContainer>
      </>
    );
  };

export default BaseHorizontalCuratorItemList;
