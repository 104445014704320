import {
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  ScrollView,
  Linking,
  ViewStyle,
  ActivityIndicator,
} from 'react-native';
import { Flex, Modal } from '@ant-design/react-native';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import NavBar2, { MoreButton, NavTextButton } from '../components/NavBar2';
import {
  fetchQuery,
  graphql,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from 'react-relay';
import { NoteEditorScreenQuery } from '../__generated__/NoteEditorScreenQuery.graphql';
import { useAuth } from '../hooks/auth';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ContentPreview from '../components/InstructionCard/ContentPreview';
import { colors } from '../constants/styleGuide';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-light.css';
import ReactMarkdown from 'react-markdown';
import LFWhiteSpace from '../components/LFWhiteSpace';
import MultilineGrowingTextInput from '../components/MultilineGrowingTextInput';
import { NoteEditorScreenContentItemMutation } from '../__generated__/NoteEditorScreenContentItemMutation.graphql';
import InfoBoxNotPublishedContent from '../components/InfoBoxNotPublishedContent';
import { useLearningHistoryMutation } from '../hooks/learningHistoryState';
import _ from 'lodash';
import LFButton from '../components/LFButton';
import dayjs from 'dayjs';
import LFLink from '../components/LFLink';
import LFText from '../components/typo/LFText';
import { useViewCountEffect } from '../hooks/viewCountEffect';
import { NoteEditorScreenContentItemDeleteMutation } from '../__generated__/NoteEditorScreenContentItemDeleteMutation.graphql';
import Comments, {
  CommentsLabel,
  CommentsList,
} from '../components/Comment/Comments';
import { CommentControls, CommentForm } from '../components/Comment/Comment';
import { useUpdateEffect } from 'ahooks';
import { CommentWhereInput } from '../__generated__/CommentsQuery.graphql';
import { LoginScreenAccessAction } from './LoginScreen';
import {
  CreateCommentFieldsInput,
  UpdateCommentFieldsInput,
} from '../__generated__/NotePlayerScreenUpdateCommentMutation.graphql';
import {
  NoteEditorScreenDeleteCommentMutation,
  NoteEditorScreenDeleteCommentMutationResponse,
} from '../__generated__/NoteEditorScreenDeleteCommentMutation.graphql';
import {
  NoteEditorScreenUpdateCommentMutation,
  NoteEditorScreenUpdateCommentMutationResponse,
} from '../__generated__/NoteEditorScreenUpdateCommentMutation.graphql';
import {
  NoteEditorScreenCommentMutation,
  NoteEditorScreenCommentMutationResponse,
} from '../__generated__/NoteEditorScreenCommentMutation.graphql';
import RecommendedContentsRelatedWithNote from '../components/RecommendedContentsRelatedWithNote';
import LFMetaTags from '../components/LFMetaTags';
import { graphqlElementsToStringArray } from '../helper/typeTransform';
import { Ionicons } from '@expo/vector-icons';
import markdownToTxt from 'markdown-to-txt';
import ReactionButton from '../components/ReactionButton';
import BookMarkButton from '../components/BookMarkButton';
import UserProfileImage from '../components/UserProfileImage';

const NoteEditorScreen: React.FC<{ style?: ViewStyle }> = ({ style }) => {
  const { user } = useAuth();
  const { contentId, contentItemId } =
    useParams<{ contentId: string; contentItemId: string }>();
  const windowDimensions = useWindowDimensions();
  const layoutMode =
    windowDimensions.width >= 1014 ? 'two-column' : 'one-column';

  const history = useHistory();
  const location = useLocation<{
    mode?: 'edit' | undefined;
  }>();
  const urlSearchParams = new URLSearchParams(location.search);
  const mode = urlSearchParams.get('mode');

  const [commitUpdateContentItem, isUpdateContentItemInFlight] =
    useMutation<NoteEditorScreenContentItemMutation>(graphql`
      mutation NoteEditorScreenContentItemMutation(
        $input: UpdateContentItemInput!
      ) @raw_response_type {
        updateContentItem(input: $input) {
          # clientMutationId
          contentItem {
            id
            objectId
            updatedAt
            md
            published
            title
            author {
              id
              objectId
            }
          }
        }
      }
    `);

  const [commitDeleteContentItem, isCommitDeleteContentItemInFlight] =
    useMutation<NoteEditorScreenContentItemDeleteMutation>(
      graphql`
        mutation NoteEditorScreenContentItemDeleteMutation($id: ID!) {
          deleteContentItem(input: { id: $id }) {
            contentItem {
              id
              objectId
              title
            }
          }
        }
      `
    );

  const [commitLearningHistory, isMutatingLearningHistory] =
    useLearningHistoryMutation({
      contentItemId: contentItemId,
    });
  const { content, contentItem, contentItems, learningHistories } =
    useLazyLoadQuery<NoteEditorScreenQuery>(
      graphql`
        query NoteEditorScreenQuery(
          $contentItemId: ID!
          $contentId: ID!
          $userObjectId: ID
          $isNotLoggedIn: Boolean!
        ) {
          content(id: $contentId) {
            type
            thumbURL
            link
            title
            id
            objectId
            tags {
              ... on Element {
                value
              }
            }
            ...ContentPreviewFragment
          }
          contentItem(id: $contentItemId) {
            id
            objectId
            createdAt
            updatedAt
            title
            published
            content {
              type
              thumbURL
              link
              title
              tags {
                ... on Element {
                  value
                }
              }
            }
            noteData {
              __typename
              ... on Element {
                __typename
                value
              }
            }
            author {
              objectId
              name
              originProfileURL
              ...UserProfileImageFragment
            }
            tags {
              ... on Element {
                value
              }
            }
            md
            ...RecommendedContentsRelatedWithNote_Fragment
          }
          contentItems(
            where: {
              AND: [
                { content: { have: { objectId: { equalTo: $contentId } } } }
                { author: { have: { objectId: { equalTo: $userObjectId } } } }
              ]
            }
          ) @skip(if: $isNotLoggedIn) {
            edges {
              node {
                id
                objectId
                published
                title
                author {
                  objectId
                  name
                }
              }
            }
          }
          learningHistories(
            where: {
              author: { have: { objectId: { equalTo: $userObjectId } } }
              contentItem: { have: { objectId: { equalTo: $contentItemId } } }
            }
            first: 1
          ) @skip(if: $isNotLoggedIn) {
            __id
            edges {
              node {
                id
                objectId
                status
                path {
                  id
                  objectId
                }
                contentItem {
                  id
                  objectId
                }
                createdAt
                updatedAt
              }
            }
          }
        }
      `,
      {
        contentItemId: contentItemId,
        contentId: contentId,
        userObjectId: user?.objectId,
        isNotLoggedIn: !user,
      }
    );
  useViewCountEffect({
    className: 'ContentItem',
    objectId: contentItemId,
  });
  useViewCountEffect({
    className: 'Content',
    objectId: contentId,
  });
  const learningHistory = _.first(learningHistories?.edges)?.node;
  const [md, setMd] = useState(contentItem.md);
  const onPressSave = () => {
    commitUpdateContentItem({
      variables: {
        input: {
          id: contentItemId,
          fields: {
            md: md,
          },
        },
      },
      onCompleted: () => {
        history.replace({
          pathname: location.pathname,
          search: '',
        });
      },
    });
  };

  const onPressEdit = () => {
    history.push({
      pathname: location.pathname,
      search: 'mode=edit',
    });
  };

  const CommentsQuery = require('../__generated__/CommentsQuery.graphql.ts');
  const environment = useRelayEnvironment();
  const [refreshedQueryOptions, setRefreshedCommentsQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2] | null
  >(null);
  const [isCommentsRefreshing, setIsCommentsRefreshing] = useState(false);

  const commentsRefresh = useCallback(() => {
    if (isCommentsRefreshing) {
      return;
    }
    setIsCommentsRefreshing(true);
    fetchQuery(environment, CommentsQuery, { where: commentsWhere }).subscribe({
      complete: () => {
        setIsCommentsRefreshing(false);
        setRefreshedCommentsQueryOptions((prev) => ({
          fetchKey: ((prev?.fetchKey ?? 0) as number) + 1,
          fetchPolicy: 'store-only',
        }));
      },
      error: () => {
        setIsCommentsRefreshing(false);
      },
    });
  }, [contentItem?.objectId]);

  useUpdateEffect(() => {
    commentsRefresh();
  }, [contentItem?.objectId]);

  const commentsWhere = useMemo<CommentWhereInput>(
    () => ({
      targetClassName: {
        equalTo: 'ContentItem',
      },
      contentItem: {
        have: {
          objectId: {
            equalTo: contentItem?.objectId || '',
          },
        },
      },
      parent: {
        haveNot: {
          objectId: {
            exists: true,
          },
        },
      },
    }),
    [contentItem?.objectId || '']
  );

  const onCommentFormFocus = () => {
    !user &&
      history.push({
        pathname: '/login',
        state: {
          from: location,
          accessAction: 'tryWritingComment' as LoginScreenAccessAction,
        },
      });
  };

  const onPressCommentAvatar = (userId: string) => {
    history.push({ pathname: `/u/${userId}` });
  };

  const [newCommentText, setNewCommentText] = useState('');

  const validateCommentText = (text: string) => {
    return newCommentText.trim().length !== 0;
  };

  const [commitCreateComment, isCommitCreateCommentInFlight] =
    useMutation<NoteEditorScreenCommentMutation>(graphql`
      mutation NoteEditorScreenCommentMutation(
        $fields: CreateCommentFieldsInput!
        $clientMutationId: String!
      ) {
        createComment(
          input: { fields: $fields, clientMutationId: $clientMutationId }
        ) {
          clientMutationId
          comment {
            id
            objectId
            text
            author {
              id
              objectId
            }
            contentItem {
              id
              objectId
            }
            targetClassName
          }
        }
      }
    `);

  const createComment = ({ fields }: { fields: CreateCommentFieldsInput }) =>
    new Promise<NoteEditorScreenCommentMutationResponse>(
      (onSuccess, onFail) => {
        commitCreateComment({
          variables: {
            clientMutationId: 'commentCreateId',
            fields,
          },
          onCompleted: (res, rej) => {
            console.log(res);
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: (error) => {
            onFail(error);
          },
        });
      }
    );
  const [commitUpdateComment, isCommitUpdateCommentInFlight] =
    useMutation<NoteEditorScreenUpdateCommentMutation>(
      graphql`
        mutation NoteEditorScreenUpdateCommentMutation(
          $objectId: ID!
          $fields: UpdateCommentFieldsInput!
        ) {
          updateComment(input: { id: $objectId, fields: $fields }) {
            clientMutationId
            comment {
              id
              objectId
              text
            }
          }
        }
      `
    );

  const updateComment = (id: string, fields: UpdateCommentFieldsInput) =>
    new Promise<NoteEditorScreenUpdateCommentMutationResponse>(
      (onSuccess, onFail) => {
        commitUpdateComment({
          variables: {
            objectId: id,
            fields,
          },
          onCompleted: (res, rej) => {
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: onFail,
        });
      }
    );

  const [commitDeleteComment, isCommitDeleteCommentInFlight] =
    useMutation<NoteEditorScreenDeleteCommentMutation>(
      graphql`
        mutation NoteEditorScreenDeleteCommentMutation($id: ID!) {
          deleteComment(input: { id: $id }) {
            comment {
              id
              objectId
            }
          }
        }
      `
    );

  const deleteComment = (id: string) =>
    new Promise<NoteEditorScreenDeleteCommentMutationResponse>(
      (onSuccess, onFail) =>
        commitDeleteComment({
          variables: {
            id,
          },
          onCompleted: (res, rej) => {
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: onFail,
        })
    );
  const contentItemFragment = useMemo(() => contentItem, [contentItemId]);
  const isMyContentItem = user?.objectId === contentItem.author?.objectId;
  const contentItemDescription = md
    ? markdownToTxt(md).replaceAll(`\n`, '')
    : `${contentItem.author?.name} 님의 "${contentItem.content?.title}"에 대한 학습 노트`;
  const bookMarkButtonStyle = useMemo(() => ({ padding: 8 }), []);
  return (
    <View style={[styles.container, style]}>
      <LFMetaTags
        type="article"
        title={`${contentItem.title} | 학습노트 - Learnfit`}
        description={contentItemDescription}
        authorName={contentItem.author?.name}
        authorId={contentItem.author?.objectId}
        objectId={contentItem.objectId}
        tags={graphqlElementsToStringArray(contentItem.tags)}
        ldJson={{
          headline: contentItem?.title || '',
          dateModified: dayjs(contentItem.updatedAt as Date).format(),
          datePublished: dayjs(contentItem.createdAt as Date).format(),
        }}
        image={contentItem.content?.thumbURL}
      />
      <NavBar2
        title="노트"
        right={
          contentItem.author?.objectId === user?.objectId ? (
            mode === 'edit' ? (
              <NavTextButton
                onPress={mode === 'edit' ? onPressSave : onPressEdit}
                isLoading={isUpdateContentItemInFlight}
              >
                저장
              </NavTextButton>
            ) : (
              <MoreButton
                isLoading={isUpdateContentItemInFlight}
                options={[
                  {
                    title: '수정',
                    onPress: onPressEdit,
                  },
                  {
                    title: contentItem.published
                      ? '비공개로 변경'
                      : '공개로 변경',
                    onPress: () => {
                      commitUpdateContentItem({
                        variables: {
                          input: {
                            id: contentItemId,
                            fields: {
                              published: !contentItem.published,
                            },
                          },
                        },
                      });
                    },
                  },
                  {
                    title: '삭제',
                    onPress: () => {
                      Modal.alert(
                        '선택하신 학습노트 1개를 정말로 삭제하시겠습니까?',
                        '',
                        [
                          {
                            text: '취소',
                            onPress: () => {},
                            style: 'cancel',
                          },

                          {
                            text: '삭제',
                            onPress: () =>
                              commitDeleteContentItem({
                                variables: {
                                  id: contentItemId,
                                },
                                onCompleted: () => {
                                  history.goBack();
                                },
                              }),
                            style: 'destructive',
                          },
                        ]
                      );
                    },
                  },
                ]}
                destructiveButtonIndex={2}
              />
            )
          ) : null
        }
      />
      <ScrollView style={{ flex: 1 }}>
        <Flex direction="column" align="stretch" style={{ padding: 16 }}>
          <LFLink
            style={{
              flexDirection: 'column',
              alignItems: 'stretch',
              borderRadius: 12,
              backgroundColor: colors.BG_2,
              padding: 16,
              borderWidth: 1,
              overflow: 'hidden',
            }}
            onPress={() => {
              const url = content.link || '';
              Linking.canOpenURL(url)
                .then(() => {
                  window.open(url, '_blank')?.focus();
                })
                .catch(() => {
                  console.log('열 수 없는 URL');
                });
            }}
          >
            <ContentPreview contentFrgmt={content} />
          </LFLink>
          <LFWhiteSpace size="lg" />
          <Flex
            direction={layoutMode === 'two-column' ? 'row-reverse' : 'column'}
            align={layoutMode === 'two-column' ? 'start' : 'stretch'}
          >
            {contentItem.author?.objectId === user?.objectId &&
            mode === 'edit' ? (
              <View
                style={{
                  width: layoutMode === 'two-column' ? '50%' : '100%',
                }}
              >
                <MultilineGrowingTextInput
                  multiline={true}
                  style={[
                    styles.textInput,
                    layoutMode === 'two-column'
                      ? { marginLeft: 16 }
                      : { marginBottom: 16 },
                  ]}
                  onChangeText={(text) => {
                    setMd(text);
                  }}
                  placeholder={'여기에 노트를 작성하세요.'}
                  value={md || ''}
                  editable={!isUpdateContentItemInFlight}
                />
              </View>
            ) : null}
            <div
              className="markdown-body"
              style={{
                // whiteSpace: 'pre-wrap',
                padding: 16,
                borderRadius: 16,
                flexGrow: 1,
                boxSizing: 'border-box',
                width: layoutMode === 'two-column' ? '50%' : '100%',
              }}
            >
              {mode == 'edit' ? (
                <LFText style={{ fontSize: 14, fontWeight: 'bold' }}>
                  미리 보기
                </LFText>
              ) : learningHistory ? (
                <>
                  <LFWhiteSpace size="lg" />
                  <Flex
                    justify="center"
                    align="center"
                    style={{
                      borderRadius: 8,
                      backgroundColor: '#F0F1F3',
                      padding: 8,
                      alignSelf: 'flex-start',
                      width: 200,
                    }}
                  >
                    <LFText style={{ fontSize: 14, fontWeight: '700' }}>
                      ✔ 학습 완료{' '}
                      <LFText style={{ fontSize: 12, fontWeight: '400' }}>
                        ({dayjs(learningHistory.updatedAt as string).fromNow()})
                      </LFText>
                    </LFText>
                  </Flex>
                </>
              ) : null}
              <InfoBoxNotPublishedContent
                onPublishButtonPress={(status) =>
                  commitUpdateContentItem({
                    variables: {
                      input: {
                        id: contentItemId,
                        fields: {
                          published: status,
                        },
                      },
                    },
                  })
                }
                published={contentItem.published}
              />
              <LFWhiteSpace />
              {/* 사용자 프로필 정보 및 이모지 리액션 영역 */}
              <Flex justify="between">
                <View>
                  <Flex direction="row">
                    <UserProfileImage
                      userFrgmt={contentItem?.author}
                      size={24}
                      style={{ borderWidth: 1, borderColor: colors.BORDER_10 }}
                    />
                    <LFWhiteSpace direction="row" size="xs" />
                    <LFLink
                      onPress={() => {
                        history.push(`/u/${contentItem?.author?.objectId}`);
                      }}
                    >
                      <LFText style={{ fontWeight: '500' }}>
                        {contentItem?.author?.name}
                      </LFText>
                    </LFLink>
                    <LFText>의 학습노트</LFText>
                  </Flex>
                </View>
                <Flex>
                  <ReactionButton
                    dataSet={{ tour: 'step-2' }}
                    type="👍"
                    targetClassName="ContentItem"
                    targetId={contentItem.objectId}
                  />
                  <BookMarkButton
                    targetClassName="ContentItem"
                    targetId={contentItem.objectId}
                    style={bookMarkButtonStyle}
                  />
                </Flex>
              </Flex>
              <LFText
                accessibilityRole="heading"
                // @ts-ignore
                style={{ display: 'inline-block' }}
              >
                {contentItem.title}
                {isMyContentItem ? (
                  <Ionicons
                    testID="content-item-change-title-btn:NotePlayerScreen"
                    name="pencil"
                    color="black"
                    size={18}
                    style={{ marginLeft: 10 }}
                    selectable={false}
                    onPress={() => {
                      Modal.prompt(
                        '제목 변경',
                        null,
                        (title) => {
                          commitUpdateContentItem({
                            variables: {
                              input: {
                                id: contentItemId,
                                fields: {
                                  title,
                                },
                              },
                            },
                          });
                        },
                        'default',
                        contentItem?.title || ''
                      );
                    }}
                  />
                ) : null}
              </LFText>

              <LFWhiteSpace />
              <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                {md || ''}
              </ReactMarkdown>
              {learningHistory || mode === 'edit' ? null : (
                <>
                  <LFWhiteSpace size="xl" />
                  <LFButton
                    testID="content-item-done:NotePlayerScreen"
                    onPress={() => {
                      commitLearningHistory('done').then(() => {
                        // alert('완료 처리 성공');
                      });
                    }}
                    isLoading={isMutatingLearningHistory}
                    onlyLoginUser={true}
                  >
                    ✔ 학습 완료
                  </LFButton>
                </>
              )}
            </div>
          </Flex>
          {mode !== 'edit' && (
            <>
              <LFWhiteSpace size="xl" direction="column" />
              <Comments
                style={styles.commentContainer}
                where={commentsWhere}
                refreshedQueryOptions={refreshedQueryOptions || {}}
              >
                <CommentsLabel />
                <LFWhiteSpace size="sm" />
                <CommentForm
                  value={newCommentText}
                  placeholder="댓글을 남겨주세요!"
                  onFocus={onCommentFormFocus}
                  onChangeText={setNewCommentText}
                  controls={
                    <CommentControls
                      onPressCancelButton={() => {
                        setNewCommentText('');
                      }}
                      disabled={
                        !validateCommentText(newCommentText) ||
                        isCommitCreateCommentInFlight
                      }
                      submitButtonStyle={
                        validateCommentText(newCommentText)
                          ? {
                              backgroundColor: '#236EFF1A',
                            }
                          : {}
                      }
                      onPressSubbmitButton={() => {
                        const fields = {
                          text: newCommentText.trim(),
                          contentItem: {
                            link: contentItem.objectId,
                          },
                          targetClassName: 'ContentItem',
                        };
                        createComment({ fields }).then((_) => {
                          setNewCommentText('');
                          commentsRefresh();
                        });
                      }}
                      submitButtonTextStyle={
                        validateCommentText(newCommentText)
                          ? {
                              color: '#236EFF',
                              fontWeight: '600',
                            }
                          : {}
                      }
                    />
                  }
                />
                <LFWhiteSpace size="xl" />
                <CommentsList
                  onCreate={createComment}
                  onDelete={deleteComment}
                  onEdit={updateComment}
                  afterMutation={commentsRefresh}
                  contentAuthorId={contentItem.author?.objectId || ''}
                  targetClassName="contentItem"
                  onFocus={onCommentFormFocus}
                  onPressAvatar={onPressCommentAvatar}
                />
              </Comments>
              <LFWhiteSpace size="xl" />
              <Suspense fallback={<ActivityIndicator />}>
                {/* 입력한 학습노트와 관련된 러닝패스, 학습노트를 추천해주는 컴포넌트 */}
                <RecommendedContentsRelatedWithNote
                  style={styles.recommendedContentsContainer}
                  contentItemFragment={contentItemFragment}
                />
              </Suspense>
            </>
          )}
        </Flex>
      </ScrollView>
    </View>
  );
};

export default NoteEditorScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  textInput: {
    borderWidth: 1,
    padding: 16,
    borderRadius: 12,
  },
  commentContainer: {
    backgroundColor: colors.BG_WHITE,
    borderRadius: 12,
    padding: 16,
  },
  recommendedContentsContainer: {
    backgroundColor: colors.BG_WHITE,
    borderRadius: 12,
    paddingBottom: 8,
  },
});
