import { ActivityIndicator, StyleSheet, View, ViewStyle } from 'react-native';
import React, { useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import _ from 'lodash';
import {
  AnalyticsPathWhereInput,
  BasePathListQuery,
} from '../__generated__/BasePathListQuery.graphql';
import PathItemView from './PathItemView';
import { Flex } from '@ant-design/react-native';
import { BasePathListPaginationFragment$key } from '../__generated__/BasePathListPaginationFragment.graphql';
import {
  AnalyticsPathOrder,
  BasePathListPaginationFragmentQuery,
} from '../__generated__/BasePathListPaginationFragmentQuery.graphql';
import LFLink, { LFLinkTo } from './LFLink';
import PathItemView2 from './PathItemView2';
import { useAuth } from '../hooks/auth';
import {
  BasePathListMyBookMarkReactionsQuery,
  ReactionWhereInput,
} from '../__generated__/BasePathListMyBookMarkReactionsQuery.graphql';

export const MORE_NUMBER = 20;

const BasePathList: React.FC<{
  initialScrollTop?: number;
  where: AnalyticsPathWhereInput;
  order: AnalyticsPathOrder[];
  first?: number;
  noLoadNext?: boolean;
  style?: ViewStyle;
  onPressItem?: (objectId: string) => void;
  onPressItemTo?: (objectId: string | undefined) => LFLinkTo;
  gutter?: number | [number, number]; //각 item별 spacing을 정의 배열로 넣으면 [horizontal, vertical]
  onChangeCount?: (count: number) => void;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  testID?: string;
  onChangePagination?: (count: number) => void;
  columnsPerRow?: number;
}> = ({
  initialScrollTop = 0,
  where,
  order,
  onPressItem,
  onPressItemTo,
  first = 20,
  noLoadNext,
  gutter = [8, 12],
  style,
  onChangeCount,
  ListEmptyComponent,
  testID,
  onChangePagination,
  columnsPerRow = 1,
}) => {
  const { user } = useAuth();
  const [horizontalGutter, verticalGutter] = [
    ..._.castArray(gutter),
    ..._.castArray(gutter),
  ]; // 하나의 숫자만 들어와도 배열이 되어 hori, verti 로 사용되도록
  const pathQueryRef = useLazyLoadQuery<BasePathListQuery>(
    graphql`
      query BasePathListQuery(
        $where: AnalyticsPathWhereInput!
        $order: [AnalyticsPathOrder!]
        $first: Int
        $after: String
      ) {
        ...BasePathListPaginationFragment
      }
    `,
    {
      where,
      first,
      order,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const analyticPathPagination = usePaginationFragment<
    BasePathListPaginationFragmentQuery,
    BasePathListPaginationFragment$key
  >(
    graphql`
      fragment BasePathListPaginationFragment on Query
      @refetchable(queryName: "BasePathListPaginationFragmentQuery") {
        analyticsPaths(
          where: $where
          first: $first
          after: $after
          order: $order
        ) @connection(key: "pagination_base_analyticsPaths") {
          count
          edges {
            node {
              id
              objectId
              path {
                id
                objectId
                ...PathItemView_Fragment
                ...PathItemView2_Fragment
              }
            }
          }
        }
      }
    `,
    pathQueryRef
  );

  const onEndReached = () => {
    if (analyticPathPagination.hasNext) {
      analyticPathPagination.loadNext(MORE_NUMBER);
    }
  };
  const data = _.chunk(
    _.map(
      analyticPathPagination.data.analyticsPaths?.edges,
      (e) => e?.node?.path
    ),
    noLoadNext ? 2 : columnsPerRow
  );

  useEffect(() => {
    _.isFunction(onChangeCount) &&
      onChangeCount(analyticPathPagination.data.analyticsPaths.count);
  }, [analyticPathPagination.data.analyticsPaths.count]);

  useEffect(() => {
    _.isFunction(onChangePagination) &&
      onChangePagination(
        analyticPathPagination.data.analyticsPaths.edges?.length || MORE_NUMBER
      );
  }, [analyticPathPagination.data.analyticsPaths.edges]);

  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0 },
  });

  const refetch = useCallback(() => {
    setQueryArgs((prev) => ({
      options: {
        fetchKey: (prev?.options.fetchKey ?? 0) + 1,
      },
    }));
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  const myBookMarkReactionsWhere: ReactionWhereInput = {
    author: {
      have: {
        objectId: {
          equalTo: user?.objectId || '',
        },
      },
    },
    type: {
      equalTo: 'bookmark',
    },
    targetClassName: {
      equalTo: 'Path',
    },
  };
  const { myBookMarkReactions } =
    useLazyLoadQuery<BasePathListMyBookMarkReactionsQuery>(
      graphql`
        query BasePathListMyBookMarkReactionsQuery(
          $where: ReactionWhereInput!
          $skip: Boolean!
        ) {
          myBookMarkReactions: reactions(where: $where, first: 1000)
            @skip(if: $skip) {
            edges {
              node {
                id
                objectId
                path {
                  id
                  objectId
                }
              }
            }
          }
        }
      `,
      { where: myBookMarkReactionsWhere, skip: !Boolean(user?.objectId) },
      { fetchPolicy: 'store-and-network', fetchKey: queryArgs.options.fetchKey }
    );

  const renderItem = (index: number, item: typeof data[0]) => {
    const lastRow = _.last(data) === item;
    const pathItemWidth = noLoadNext ? '50%' : `${100 / columnsPerRow}%`;
    return (
      <Flex
        direction="row"
        align={'stretch'}
        style={{
          paddingBottom: lastRow ? 0 : verticalGutter,
          marginRight: noLoadNext ? 0 : -horizontalGutter,
        }}
      >
        {_.map(_.castArray(item), (path, index) => {
          if (!path?.objectId) {
            return <View key={index} style={{ width: pathItemWidth }}></View>;
          } else {
            const bookMarkReaction = _.find(
              myBookMarkReactions?.edges,
              (edge) => edge?.node?.path?.objectId === path.objectId
            );
            const scraped = {
              status: Boolean(bookMarkReaction),
              reactionId: bookMarkReaction?.node?.objectId,
            };
            return (
              <View
                key={index}
                style={[
                  { width: pathItemWidth },
                  noLoadNext
                    ? {
                        ['padding' + (index === 0 ? 'Right' : 'Left')]:
                          horizontalGutter / 2,
                      }
                    : { paddingRight: horizontalGutter },
                ]}
              >
                {noLoadNext ? (
                  <LFLink
                    testID={testID}
                    onPress={
                      _.isFunction(onPressItem)
                        ? () => {
                            onPressItem(path?.objectId);
                          }
                        : undefined
                    }
                    to={
                      _.isFunction(onPressItemTo)
                        ? onPressItemTo(path?.objectId)
                        : ''
                    }
                  >
                    <PathItemView pathFragment={path} skipHistory={true} />
                  </LFLink>
                ) : (
                  <PathItemView2
                    pathFragment={path}
                    scraped={scraped}
                    onScrape={refetch}
                  />
                )}
              </View>
            );
          }
        })}
      </Flex>
    );
  };

  const emptyElement = ListEmptyComponent ? (
    React.isValidElement(ListEmptyComponent) ? (
      ListEmptyComponent
    ) : (
      <ListEmptyComponent />
    )
  ) : null;

  return data.length === 0 ? (
    emptyElement
  ) : noLoadNext ? (
    <Flex direction="column" align="stretch" style={style}>
      {_.map(data, (item, index) => renderItem(index, item))}
    </Flex>
  ) : (
    <View style={style}>
      <Virtuoso
        initialScrollTop={initialScrollTop}
        // emptyComponent={emptyComponent ? () => emptyComponent : undefined}
        useWindowScroll
        data={data}
        endReached={onEndReached}
        itemContent={renderItem}
      />
      {analyticPathPagination.isLoadingNext ? (
        <Flex align="center" justify="center" style={{ height: 100 }}>
          <ActivityIndicator color={'gray'} />
        </Flex>
      ) : null}
    </View>
  );
  // return (

  // );
};
const ItemContainer: React.FC = ({ children }) => {
  return (
    <View style={{ borderWidth: 10, borderColor: 'blue' }}>{children}</View>
  );
};
const ListContainer: React.FC = ({ children }) => {
  return <View style={{ borderWidth: 1, borderColor: 'red' }}>{children}</View>;
};

export default BasePathList;

const styles = StyleSheet.create({});
