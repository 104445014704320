import { ActivityIndicator, StyleSheet, View, ViewStyle } from 'react-native';
import React, { useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import _ from 'lodash';
import { Flex } from '@ant-design/react-native';
import LFLink, { LFLinkTo } from './LFLink';
import {
  ActivityStreamWhereInput,
  BaseActivityStreamListPaginationFragmentQuery,
} from '../__generated__/BaseActivityStreamListPaginationFragmentQuery.graphql';
import { BaseActivityStreamListQuery } from '../__generated__/BaseActivityStreamListQuery.graphql';
import { BaseActivityStreamListPaginationFragment$key } from '../__generated__/BaseActivityStreamListPaginationFragment.graphql';
import ActivityStreamItemView from './ActivityStreamItemView';

const BaseActivityStreamList: React.FC<{
  where: ActivityStreamWhereInput;
  first?: number;
  noLoadNext?: boolean;
  style?: ViewStyle;
  onPressItem?: (objectId: string) => void;
  onPressItemTo?: (objectId: string | undefined) => LFLinkTo;
  gutter?: number;
  onChangeCount?: (count: number) => void;
  testID?: string;
}> = ({
  where,
  onPressItem,
  onPressItemTo,
  first = 20,
  noLoadNext,
  gutter = 24,
  style,
  onChangeCount,
  testID,
}) => {
  const activityStreamQueryRef = useLazyLoadQuery<BaseActivityStreamListQuery>(
    graphql`
      query BaseActivityStreamListQuery(
        $where: ActivityStreamWhereInput!
        $first: Int
        $after: String
      ) {
        ...BaseActivityStreamListPaginationFragment
      }
    `,
    {
      where,
      first,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const activityStreamPagination = usePaginationFragment<
    BaseActivityStreamListPaginationFragmentQuery,
    BaseActivityStreamListPaginationFragment$key
  >(
    graphql`
      fragment BaseActivityStreamListPaginationFragment on Query
      @refetchable(queryName: "BaseActivityStreamListPaginationFragmentQuery") {
        activityStreams(
          where: $where
          first: $first
          after: $after
          order: updatedAt_DESC
        ) @connection(key: "pagination_base_activityStreams") {
          count
          edges {
            node {
              id
              objectId
              sources {
                __typename
              }
              ...ActivityStreamItemView_Fragment
            }
          }
        }
      }
    `,
    activityStreamQueryRef
  );

  const onEndReached = () => {
    if (activityStreamPagination.hasNext) {
      activityStreamPagination.loadNext(first);
    }
  };

  const data = _.chain(activityStreamPagination.data.activityStreams.edges)
    .map((e) => e?.node)
    .filter((n) => Boolean(n?.sources?.length))
    .value();

  useEffect(() => {
    _.isFunction(onChangeCount) &&
      onChangeCount(activityStreamPagination.data.activityStreams.count);
  }, [activityStreamPagination.data.activityStreams.count]);

  const renderItem = (index: number, item: typeof data[0]) => {
    const lastRow = _.last(data) === item;
    return (
      <View
        style={{
          paddingBottom: lastRow ? 0 : gutter,
        }}
      >
        <LFLink
          testID={testID}
          onPress={
            _.isFunction(onPressItem)
              ? () => {
                  onPressItem(item?.objectId as string);
                }
              : undefined
          }
          to={_.isFunction(onPressItemTo) ? onPressItemTo(item?.objectId) : ''}
        >
          <Flex direction="column" align={'stretch'}>
            <ActivityStreamItemView activityStreamFragment={item || null} />
          </Flex>
        </LFLink>
      </View>
    );
  };
  return noLoadNext ? (
    <Flex direction="column" align="stretch" style={style}>
      {_.map(data, (item, index) => renderItem(index, item))}
    </Flex>
  ) : (
    <View style={style}>
      <Virtuoso
        useWindowScroll
        data={data}
        endReached={onEndReached}
        itemContent={renderItem}
        footer={() => {
          return activityStreamPagination.isLoadingNext ? (
            <Flex align="center" justify="center" style={{ height: 100 }}>
              <ActivityIndicator color={'gray'} />
            </Flex>
          ) : null;
        }}
      />
    </View>
  );
};

export default BaseActivityStreamList;

const styles = StyleSheet.create({});
