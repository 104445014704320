/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MembershipPlanAndPricingScreenPaymentMutationVariables = {
    userObjectId: string;
    pricingId: string;
    orderId: string;
    price: number;
};
export type MembershipPlanAndPricingScreenPaymentMutationResponse = {
    readonly createMembershipPayment: {
        readonly membershipPayment: {
            readonly id: string;
            readonly objectId: string;
            readonly status: string | null;
        };
    } | null;
};
export type MembershipPlanAndPricingScreenPaymentMutation = {
    readonly response: MembershipPlanAndPricingScreenPaymentMutationResponse;
    readonly variables: MembershipPlanAndPricingScreenPaymentMutationVariables;
};



/*
mutation MembershipPlanAndPricingScreenPaymentMutation(
  $userObjectId: ID!
  $pricingId: ID!
  $orderId: String!
  $price: Float!
) {
  createMembershipPayment(input: {fields: {member: {link: $userObjectId}, membershipPricing: {link: $pricingId}, orderId: $orderId, price: $price}}) {
    membershipPayment {
      id
      objectId
      status
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "price"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pricingId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userObjectId"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "link",
                    "variableName": "userObjectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "member"
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "link",
                    "variableName": "pricingId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "membershipPricing"
              },
              {
                "kind": "Variable",
                "name": "orderId",
                "variableName": "orderId"
              },
              {
                "kind": "Variable",
                "name": "price",
                "variableName": "price"
              }
            ],
            "kind": "ObjectValue",
            "name": "fields"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreateMembershipPaymentPayload",
    "kind": "LinkedField",
    "name": "createMembershipPayment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MembershipPayment",
        "kind": "LinkedField",
        "name": "membershipPayment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipPlanAndPricingScreenPaymentMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "MembershipPlanAndPricingScreenPaymentMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0b5fde9dc49db606494ce5ab17a11e51",
    "id": null,
    "metadata": {},
    "name": "MembershipPlanAndPricingScreenPaymentMutation",
    "operationKind": "mutation",
    "text": "mutation MembershipPlanAndPricingScreenPaymentMutation(\n  $userObjectId: ID!\n  $pricingId: ID!\n  $orderId: String!\n  $price: Float!\n) {\n  createMembershipPayment(input: {fields: {member: {link: $userObjectId}, membershipPricing: {link: $pricingId}, orderId: $orderId, price: $price}}) {\n    membershipPayment {\n      id\n      objectId\n      status\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '09db1445991889c50bf5fbb693532a54';
export default node;
