import {
  StyleSheet,
  Text,
  View,
  Image,
  ViewStyle,
  TextProps,
} from 'react-native';
import React from 'react';
import LFLink, { LFLinkTo } from './LFLink';
import { Flex } from '@ant-design/react-native';
import LFText from './typo/LFText';
import LFButton from './LFButton';
import { colors } from '../constants/styleGuide';
import { Ionicons } from '@expo/vector-icons';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

type Create = 'path' | 'note';

const SectionTitle: React.FC<{
  accessibilityRole?: TextProps['accessibilityRole'];
  accessibilityLevel?: TextProps['accessibilityLevel'];
  title: string | React.ReactNode;
  to?: LFLinkTo;
  arrow?: boolean;
  count?: number | string;
  create?: Create;
  style?: ViewStyle;
  testID?: string;
}> = ({
  title,
  to,
  arrow,
  count,
  create,
  style,
  testID,
  accessibilityRole,
  accessibilityLevel,
}) => {
  const history = useHistory();

  return (
    <LFLink testID={testID} to={to} disabled={!to} style={style}>
      <Flex justify="start">
        <View>
          <LFText
            aria-level={accessibilityLevel}
            accessibilityRole={accessibilityRole}
            style={styles.subHeaderTitle}
          >
            {title}
          </LFText>
        </View>

        {create === 'path' ? (
          <LFButton
            testID="mypage-make-path:SectionTitle"
            onPress={() => {
              history.push('/path-edit');
            }}
            style={styles.createButton}
          >
            <Ionicons name="add" size={20} color="black" />
            <LFText style={styles.createButtonText}>만들기</LFText>
          </LFButton>
        ) : null}
        {create === 'note' ? (
          <LFButton
            testID="mypage-make-note:SectionTitle"
            onPress={() => {
              history.push('/new-note');
            }}
            style={styles.createButton}
          >
            <Ionicons name="add" size={20} color="black" />
            <LFText style={styles.createButtonText}>만들기</LFText>
          </LFButton>
        ) : null}
        <Flex direction="row" style={{ marginLeft: 'auto' }}>
          {!_.isNative(count) ? (
            <LFText style={{ color: colors.TEXT_60, fontSize: 14 }}>
              {count}
            </LFText>
          ) : null}
          {arrow ? (
            <Image
              source={require('../assets/images/arrow24@3x.png')}
              style={styles.arrow}
            />
          ) : null}
        </Flex>
      </Flex>
    </LFLink>
  );
};

export default SectionTitle;

const styles = StyleSheet.create({
  subHeaderTitle: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#26282c',
  },
  arrow: { width: 16, height: 16 },
  createButton: {
    minHeight: 0,
    marginLeft: 8,
    paddingHorizontal: 15,
    backgroundColor: colors.BG_2,
    borderRadius: 25,
  },
  createButtonText: {
    fontSize: 12,
    fontWeight: '600',
  },
});
