import React, { createContext, useContext, useState } from 'react';
import { Dimensions } from 'react-native';
import useDeviceWidthChangeEffect from './useDeviceWidthChangeEffect';

const MAXIMUM_MOBILE_SIZE = 639;
type DeviceType = 'PC' | 'MOBILE';

const DeviceWidthContext = createContext<DeviceType | null>(null);
export const useDeviceType = () => {
  return useContext(DeviceWidthContext);
};

const DeviceTypeProvider: React.FC = ({ children }) => {
  const [deviceType, setDeviceType] = useState<DeviceType>(
    Dimensions.get('screen').width > MAXIMUM_MOBILE_SIZE ? 'PC' : 'MOBILE'
  );
  useDeviceWidthChangeEffect({
    effect: (width) => {
      if (width > MAXIMUM_MOBILE_SIZE) setDeviceType('PC');
      else setDeviceType('MOBILE');
    },
  });
  return (
    <DeviceWidthContext.Provider value={deviceType}>
      {children}
    </DeviceWidthContext.Provider>
  );
};

export default DeviceTypeProvider;
