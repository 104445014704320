/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TraversalScreenQuizDeleteMutationVariables = {
    id: string;
};
export type TraversalScreenQuizDeleteMutationResponse = {
    readonly deleteQuiz: {
        readonly quiz: {
            readonly id: string;
            readonly objectId: string;
        };
    } | null;
};
export type TraversalScreenQuizDeleteMutation = {
    readonly response: TraversalScreenQuizDeleteMutationResponse;
    readonly variables: TraversalScreenQuizDeleteMutationVariables;
};



/*
mutation TraversalScreenQuizDeleteMutation(
  $id: ID!
) {
  deleteQuiz(input: {id: $id}) {
    quiz {
      id
      objectId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteQuizPayload",
    "kind": "LinkedField",
    "name": "deleteQuiz",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Quiz",
        "kind": "LinkedField",
        "name": "quiz",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "objectId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TraversalScreenQuizDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TraversalScreenQuizDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a282c1336dd2747d327c955f04442eb9",
    "id": null,
    "metadata": {},
    "name": "TraversalScreenQuizDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TraversalScreenQuizDeleteMutation(\n  $id: ID!\n) {\n  deleteQuiz(input: {id: $id}) {\n    quiz {\n      id\n      objectId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c45ae438f5cf1e43ab5b2864993be07b';
export default node;
