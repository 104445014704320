import { Flex } from '@ant-design/react-native';
import React from 'react';
import { StyleSheet, View, Image, ViewStyle } from 'react-native';
import PreviewImage from '../../components/PreviewImage';
import LFText from '../../components/typo/LFText';
import { colors } from '../../constants/styleGuide';

export interface SourcePreviewProps {
  thumbURL: string;
  title: string;
  subtitle?: string;
  style: ViewStyle;
  type: 'VIDEO' | 'BOOK' | 'LINK';
}
const SourcePreview: React.FC<SourcePreviewProps> = ({
  thumbURL,
  title,
  subtitle,
  style,
  type = 'LINK',
}) => {
  const typeInfo = {
    VIDEO: {
      title: 'Video',
      thumbSize: {
        width: 128,
        height: 72,
      },
    },
    BOOK: {
      title: 'Book',
      thumbSize: {
        width: 72,
        height: 96,
      },
    },
    LINK: {
      title: 'URL',
      thumbSize: {
        width: 72,
        height: 72,
      },
    },
  }[type];
  return (
    <Flex
      direction="column"
      align="stretch"
      style={[
        {
          borderRadius: 12,
          backgroundColor: colors.BG_2,
          padding: 8,
        },
        style,
      ]}
    >
      <Flex direction="row" align="start">
        <View>
          <View
            style={{
              opacity: 0.9,
              borderRadius: 4,
              backgroundColor: colors.SECONDARY_BLACK,
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: 10,
              padding: 4,
            }}
          >
            <LFText
              style={{
                fontSize: 10,
                fontWeight: '500',
                fontStyle: 'normal',
                lineHeight: 16,
                letterSpacing: 0,
                textAlign: 'left',
                color: colors.TEXT_WHITE,
              }}
            >
              {typeInfo.title}
            </LFText>
          </View>
          <PreviewImage
            accessibilityLabel=""
            style={{
              ...typeInfo.thumbSize,
              borderRadius: 4,
              backgroundColor: '#00000019.800두0066',
              borderWidth: 1,
              borderColor: colors.BORDER_10,
              marginRight: 8,
            }}
            source={{ uri: thumbURL || '' }}
          />
        </View>
        <Flex align="start" direction="column" style={{ flex: 1 }}>
          <LFText style={styles.title} numberOfLines={2}>
            {title}
          </LFText>
          <LFText style={styles.subtitle} numberOfLines={1}>
            {subtitle}
          </LFText>
        </Flex>
      </Flex>
      {/* <Flex style={{ alignSelf: 'flex-start' }}>
        <LFText>노트작성자 영역</LFText>
      </Flex> */}
    </Flex>
  );
};

export const SourcePreviewWrapView: React.FC<{
  style?: ViewStyle;
}> = ({ children, style }) => (
  <Flex
    direction="row"
    align="stretch"
    style={[
      {
        flex: 1,
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: colors.BG_2,
        padding: 8,
      },
      style,
    ]}
  >
    {children}
  </Flex>
);
export default SourcePreview;

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    color: colors.TEXT_80,
  },
  subtitle: {
    fontSize: 10,
    fontWeight: '300',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
});
