import {
  Image,
  ImageRequireSource,
  ImageURISource,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import LFText, { LFBoldText } from './typo/LFText';
import _ from 'lodash';
import { colors } from '../constants/styleGuide';
import LFWhiteSpace from './LFWhiteSpace';
import { graphql, useFragment } from 'react-relay';
import { ActivityStreamItemView_Fragment$key } from '../__generated__/ActivityStreamItemView_Fragment.graphql';
import dayjs from 'dayjs';
import getPathImagenameUsingTimestamp from '../helper/getPathImagenameUsingTimestamp';
import LFLink from './LFLink';
import BookMark from './BookMark';

const ActivityStreamItemView: React.FC<{
  style?: ViewStyle;
  activityStreamFragment: ActivityStreamItemView_Fragment$key | null;
}> = ({ style, activityStreamFragment }) => {
  const activityStream = useFragment(
    graphql`
      fragment ActivityStreamItemView_Fragment on ActivityStream {
        id
        objectId
        __typename
        createdAt
        sourceClassName
        verb
        author {
          id
          objectId
          name
        }
        sources {
          __typename
          ... on Path {
            id
            objectId
            createdAt
            title
            published
            coverImage {
              url
            }
            author {
              name
            }
          }
          ... on User {
            id
            objectId
            name
          }
          ... on ContentItem {
            id
            objectId
            createdAt
            title
            published
            content {
              thumbURL
              id
              objectId
            }
            author {
              name
            }
          }
          ... on Reaction {
            targetClassName
            type
            path {
              id
              objectId
              createdAt
              title
              published
              author {
                id
                name
              }
              coverImage {
                url
              }
            }
            contentItem {
              id
              objectId
              createdAt
              title
              published
              author {
                id
                name
              }
              content {
                objectId
                thumbURL
              }
            }
          }
          ... on LearningHistory {
            id
            targetFieldName
            path {
              id
              createdAt
              objectId
              coverImage {
                url
              }
              title
              author {
                name
              }
              published
            }
            contentItem {
              id
              createdAt
              objectId
              content {
                id
                objectId
                thumbURL
              }
              author {
                name
              }
              title
              published
            }
          }
          ... on Comment {
            id
            objectId
            text
            targetClassName
            createdAt
            author {
              id
              objectId
              name
            }
            path {
              id
              createdAt
              objectId
              coverImage {
                url
              }
              title
              author {
                name
              }
              published
            }
            contentItem {
              id
              createdAt
              objectId
              content {
                id
                objectId
                thumbURL
              }
              author {
                name
              }
              title
              published
            }
          }
        }
      }
    `,
    activityStreamFragment
  );

  const source = _.head(activityStream?.sources);
  const data =
    source?.__typename === 'Comment' && activityStream?.verb === 'CREATE'
      ? {
          icon: ACTIVITY_ICONS.comment,
          title: '댓글을 남겼어요!',
          subTitle: source.text,
          to:
            source.targetClassName === 'Path'
              ? `/path/${source.path?.objectId}`
              : `/note/${source.contentItem?.content?.objectId}/${source.contentItem?.objectId}`,
          preview: {
            published: source.path
              ? source.path?.published
              : source.contentItem?.published,
            title: source.path
              ? source.path.published
                ? source.path?.title
                : '⚠️작성자에 의해 비공개 상태로 변경되었습니다.'
              : source.contentItem?.published
              ? source.contentItem.title
              : '⚠️작성자에 의해 비공개 상태로 변경되었습니다.',
            subTitle:
              source.targetClassName === 'Path'
                ? `제작자: ${source.path?.author?.name}`
                : `제작자: ${source.contentItem?.author?.name}`,
            thumbURL:
              source.targetClassName === 'Path'
                ? source.path?.coverImage?.url
                : source.contentItem?.content?.thumbURL,
            type: source.targetClassName,
            createdAt:
              source.targetClassName === 'Path'
                ? source.path?.createdAt
                : source.contentItem?.createdAt,
          },
        }
      : source?.__typename === 'Reaction' && activityStream?.verb === 'CREATE'
      ? {
          icon: ACTIVITY_ICONS.reaction,
          title: EMOJI_KEY_VALUE[source.type as EMOJIS][1],
          subTitle: [
            `${activityStream?.author?.name}님이 ${
              source.targetClassName === 'Path' ? '러닝패스' : '학습노트'
            }`,
            EMOJI_KEY_VALUE[source.type as EMOJIS][0],
          ],
          to:
            source.targetClassName === 'Path'
              ? `/path/${source.path?.objectId}`
              : `/note/${source.contentItem?.content?.objectId}/${source.contentItem?.objectId}`,
          preview: {
            published: source.path
              ? source.path?.published
              : source.contentItem?.published,
            title: source.path
              ? source.path.published
                ? source.path?.title
                : '⚠️작성자에 의해 비공개 상태로 변경되었습니다.'
              : source.contentItem?.published
              ? source.contentItem.title
              : '⚠️작성자에 의해 비공개 상태로 변경되었습니다.',
            subTitle:
              source.targetClassName === 'Path'
                ? `제작자: ${source.path?.author?.name}`
                : `제작자: ${source.contentItem?.author?.name}`,
            thumbURL:
              source.targetClassName === 'Path'
                ? source.path?.coverImage?.url
                : source.contentItem?.content?.thumbURL,
            type: source.targetClassName,
            createdAt:
              source.targetClassName === 'Path'
                ? source.path?.createdAt
                : source.contentItem?.createdAt,
          },
        }
      : source?.__typename === 'User' && activityStream?.verb === 'CREATE'
      ? {
          icon: ACTIVITY_ICONS.notification,
          title: '환영해요!',
          subTitle: [
            `${activityStream?.author?.name}님이 `,
            <strong>가입</strong>,
            '했어요.',
          ],

          preview: null,
        }
      : source?.__typename === 'LearningHistory' &&
        activityStream?.verb === 'START'
      ? {
          icon: ACTIVITY_ICONS.start,
          title: '시작이 반이죠',
          subTitle: [
            `${source.targetFieldName === 'path' ? '러닝패스' : '학습노트'}를 `,
            <strong>학습 시작</strong>,
            '했어요',
          ],
          to:
            source.targetFieldName === 'path'
              ? `/path/${source.path?.objectId}`
              : `/note/${source.contentItem?.content?.objectId}/${source.contentItem?.objectId}`,
          preview: {
            published: source.path
              ? source.path.published
              : source.contentItem?.published,
            title:
              source.targetFieldName === 'path'
                ? source.path?.title
                : source.contentItem?.title,
            subTitle:
              source.targetFieldName === 'path'
                ? `제작자: ${source.path?.author?.name}`
                : `제작자: ${source.contentItem?.author?.name}`,
            thumbURL:
              source.targetFieldName === 'path'
                ? source.path?.coverImage?.url
                : source.contentItem?.content?.thumbURL,
            type: source.targetFieldName,
            createdAt:
              source.targetFieldName === 'path'
                ? source.path?.createdAt
                : source.contentItem?.createdAt,
          },
        }
      : source?.__typename === 'LearningHistory' &&
        activityStream?.verb === 'FINISH'
      ? {
          icon: ACTIVITY_ICONS.finish,
          title: `${
            source.targetFieldName === 'path' ? '짝짝! 대단해요!' : '야호~!'
          }`,
          subTitle: [
            `${source.targetFieldName === 'path' ? '러닝패스' : '학습노트'}를 `,
            <strong>학습 완료</strong>,
            '했어요',
          ],
          to:
            source.targetFieldName === 'path'
              ? `/path/${source.path?.objectId}`
              : `/note/${source.contentItem?.content?.objectId}/${source.contentItem?.objectId}`,

          preview: {
            published: source.path
              ? source.path.published
              : source.contentItem?.published,
            title:
              source.targetFieldName === 'path'
                ? source.path?.title
                : source.contentItem?.title,
            subTitle:
              source.targetFieldName === 'path'
                ? `제작자: ${source.path?.author?.name}`
                : `제작자: ${source.contentItem?.author?.name}`,
            thumbURL:
              source.targetFieldName === 'path'
                ? source.path?.coverImage?.url
                : source.contentItem?.content?.thumbURL,
            type: source.targetFieldName,
            createdAt:
              source.targetFieldName === 'path'
                ? source.path?.createdAt
                : source.contentItem?.createdAt,
          },
        }
      : source?.__typename === 'Path' && activityStream?.verb === 'PUBLISH'
      ? {
          icon: ACTIVITY_ICONS.notification,
          title: '공개',
          subTitle: ['러닝패스를 ', <strong>공개</strong>, '했어요'],
          to: `/path/${source.objectId}`,
          preview: {
            published: source.published,
            title: source.title,
            subTitle: `제작자: ${source.author?.name}`,
            thumbURL: source.coverImage?.url,
            type: 'Path',
            createdAt: source.createdAt,
          },
        }
      : source?.__typename === 'ContentItem' &&
        activityStream?.verb === 'PUBLISH'
      ? {
          icon: ACTIVITY_ICONS.notification,
          title: '공개',
          subTitle: ['학습노트를 ', <strong>공개</strong>, '했어요'],
          to: `/note/${source.content?.objectId}/${source.objectId}`,
          preview: {
            published: source.published,
            title: source.title,
            subTitle: `제작자: ${source.author?.name}`,
            thumbURL: source.content?.thumbURL,
            type: 'ContentItem',
            createdAt: source.createdAt,
          },
        }
      : null;
  const timeStamp = dayjs(activityStream?.createdAt as string).fromNow();
  return data ? (
    <LFLink
      onPress={
        !(source?.__typename === 'User' && activityStream?.verb === 'CREATE')
          ? data.preview?.published
            ? undefined
            : () => alert('비공개된 콘텐츠입니다.')
          : null
      }
      to={data.to}
    >
      <Flex style={style} align="start">
        <Image
          accessibilityLabel=""
          style={{ width: 40, height: 40 }}
          source={data.icon}
        />
        <LFWhiteSpace size="sm" direction="row" />
        <View style={{ flex: 1 }}>
          <Flex justify="between">
            <LFText style={styles.title}>{data.title}</LFText>
            <WhiteSpace size="xs" />
            <LFText style={styles.timeStamp}>{timeStamp}</LFText>
          </Flex>
          <LFText style={styles.subTitle}>{data.subTitle}</LFText>
          {data.preview && (
            <>
              <WhiteSpace size="sm" />
              <ContentPreviewComponent
                title={data.preview.title || ''}
                subTitle={data.preview.subTitle}
                imageSource={
                  data.preview.thumbURL
                    ? { uri: data.preview.thumbURL }
                    : ['path', 'Path'].includes(data.preview.type || '')
                    ? require(`../assets/images/${getPathImagenameUsingTimestamp(
                        {
                          seed: dayjs(
                            data.preview.createdAt as number
                          ).valueOf(),
                        }
                      )}`)
                    : null
                }
              />
            </>
          )}
        </View>
      </Flex>
    </LFLink>
  ) : null;
};

export default ActivityStreamItemView;

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: '#1a1b1e',
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  timeStamp: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    textAlign: 'right',
    color: 'rgba(26, 27, 30, 0.3)',
  },
});

const ContentPreviewComponent: React.FC<{
  imageSource: ImageRequireSource | ImageURISource | null;
  title: string;
  subTitle: string;
}> = ({ imageSource, title, subTitle }) => {
  return (
    <Flex style={{ flex: 1, alignItems: 'flex-start' }}>
      {imageSource && (
        <>
          <Image
            accessibilityLabel=""
            source={imageSource}
            style={tempStyles.thumbnail}
          />
          <LFWhiteSpace size="sm" direction="row" />
        </>
      )}
      <View style={{ flex: 1 }}>
        <LFText numberOfLines={2} style={tempStyles.title}>
          {title}
        </LFText>
        <WhiteSpace size="xs" />
        <LFText style={tempStyles.subTitle}>{subTitle}</LFText>
      </View>
    </Flex>
  );
};

const tempStyles = StyleSheet.create({
  thumbnail: {
    width: 64,
    height: 64,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.BORDER_10,
  },
  title: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  subTitle: {
    fontSize: 10,
    fontWeight: '300',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    color: 'rgba(26, 27, 30, 0.6)',
  },
});

const ACTIVITY_ICONS = {
  start: require('../assets/images/image40ActivityStart@3x.png'),
  finish: require('../assets/images/image40ActivityComplete@3x.png'),
  notification: require('../assets/images/image40ActivityNotification@3x.png'),
  reaction: require('../assets/images/image40ActivityReaction@3x.png'),
  comment: require('../assets/images/image40ActivityComment@3x.png'),
};

export type EMOJIS = '👍' | '🌱' | '🤷‍♂️' | '👀' | '😉' | 'bookmark';
type EMOJIS_VALUE = { [K in EMOJIS]: [React.ReactNode, React.ReactNode] };
const EMOJI_KEY_VALUE: EMOJIS_VALUE = {
  '👍': [
    ['에 ', <LFBoldText>도움돼요</LFBoldText>, '를 보냈어요'],
    '👍 정말 고마워요!️',
  ],
  '🌱': [
    ['에 ', <LFBoldText>쉬워요</LFBoldText>, '를 보냈어요'],
    '🌱 이것쯤이야~',
  ],
  '🤷‍♂️': [
    ['에 ', <LFBoldText>어려워요</LFBoldText>, '를 보냈어요'],
    '🤷‍♂️ 휴.. 겨우 끝냈다.',
  ],
  '👀': [
    ['에 ', <LFBoldText>또 볼래요</LFBoldText>, '를 보냈어요'],
    '👀 이건 소장각!',
  ],
  '😉': [
    ['에 ', <LFBoldText>재미있어요</LFBoldText>, '를 보냈어요'],
    '😉 시간가는 줄 몰랐네요!',
  ],
  bookmark: [
    ['를 ', <LFBoldText>스크랩</LFBoldText>, '했어요'],
    <Flex>
      <BookMark style={{ width: 20, height: 20 }} />
      <LFWhiteSpace size="xs" direction="row" />
      <LFText>나중에 봐야지!</LFText>
    </Flex>,
  ],
};
