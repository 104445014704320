/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AnalyticsPathOrder = "ACL_ASC" | "ACL_DESC" | "commentCount_ASC" | "commentCount_DESC" | "createdAt_ASC" | "createdAt_DESC" | "emojiCountMap_ASC" | "emojiCountMap_DESC" | "emojiTotalCount_ASC" | "emojiTotalCount_DESC" | "id_ASC" | "id_DESC" | "objectId_ASC" | "objectId_DESC" | "path_ASC" | "path_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "viewCount_ASC" | "viewCount_DESC" | "%future added value";
export type AnalyticsPathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    path?: PathRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsPathWhereInput> | null;
    AND?: Array<AnalyticsPathWhereInput> | null;
    NOR?: Array<AnalyticsPathWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type PathRelationWhereInput = {
    have?: PathWhereInput | null;
    haveNot?: PathWhereInput | null;
    exists?: boolean | null;
};
export type PathWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    contentItemList?: ArrayWhereInput | null;
    author?: UserRelationWhereInput | null;
    learnCount?: NumberWhereInput | null;
    published?: BooleanWhereInput | null;
    description?: StringWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    analytics?: AnalyticsPathRelationWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    draft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathWhereInput> | null;
    AND?: Array<PathWhereInput> | null;
    NOR?: Array<PathWhereInput> | null;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type UserRelationWhereInput = {
    have?: UserWhereInput | null;
    haveNot?: UserWhereInput | null;
    exists?: boolean | null;
};
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type InstructionCardRelationWhereInput = {
    have?: InstructionCardWhereInput | null;
    haveNot?: InstructionCardWhereInput | null;
    exists?: boolean | null;
};
export type InstructionCardWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    path?: unknown | null;
    sources?: ArrayWhereInput | null;
    seq?: NumberWhereInput | null;
    optional?: BooleanWhereInput | null;
    pathDraft?: PathDraftRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<InstructionCardWhereInput> | null;
    AND?: Array<InstructionCardWhereInput> | null;
    NOR?: Array<InstructionCardWhereInput> | null;
};
export type PathDraftRelationWhereInput = {
    have?: PathDraftWhereInput | null;
    haveNot?: PathDraftWhereInput | null;
    exists?: boolean | null;
};
export type PathDraftWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    original?: PathRelationWhereInput | null;
    instructionCards?: InstructionCardRelationWhereInput | null;
    coverImage?: FileWhereInput | null;
    membership?: MembershipRelationWhereInput | null;
    title?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    description?: StringWhereInput | null;
    isSeries?: BooleanWhereInput | null;
    estimate?: NumberWhereInput | null;
    target?: StringWhereInput | null;
    benefits?: ArrayWhereInput | null;
    prerequisites?: ArrayWhereInput | null;
    progressing?: BooleanWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<PathDraftWhereInput> | null;
    AND?: Array<PathDraftWhereInput> | null;
    NOR?: Array<PathDraftWhereInput> | null;
};
export type MembershipRelationWhereInput = {
    have?: MembershipWhereInput | null;
    haveNot?: MembershipWhereInput | null;
    exists?: boolean | null;
};
export type MembershipWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    level?: StringWhereInput | null;
    role?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<MembershipWhereInput> | null;
    AND?: Array<MembershipWhereInput> | null;
    NOR?: Array<MembershipWhereInput> | null;
};
export type RoleRelationWhereInput = {
    have?: RoleWhereInput | null;
    haveNot?: RoleWhereInput | null;
    exists?: boolean | null;
};
export type RoleWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    name?: StringWhereInput | null;
    users?: UserRelationWhereInput | null;
    roles?: RoleRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<RoleWhereInput> | null;
    AND?: Array<RoleWhereInput> | null;
    NOR?: Array<RoleWhereInput> | null;
};
export type AnalyticsPathRelationWhereInput = {
    have?: AnalyticsPathWhereInput | null;
    haveNot?: AnalyticsPathWhereInput | null;
    exists?: boolean | null;
};
export type BaseHorizontalPathList2QueryVariables = {
    where?: AnalyticsPathWhereInput | null;
    order?: Array<AnalyticsPathOrder> | null;
    first?: number | null;
};
export type BaseHorizontalPathList2QueryResponse = {
    readonly analyticsPaths: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly path: {
                    readonly id: string;
                    readonly objectId: string;
                    readonly " $fragmentRefs": FragmentRefs<"PathItemView2_Fragment">;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type BaseHorizontalPathList2Query = {
    readonly response: BaseHorizontalPathList2QueryResponse;
    readonly variables: BaseHorizontalPathList2QueryVariables;
};



/*
query BaseHorizontalPathList2Query(
  $where: AnalyticsPathWhereInput
  $order: [AnalyticsPathOrder!]
  $first: Int
) {
  analyticsPaths(where: $where, order: $order, first: $first) {
    count
    edges {
      node {
        id
        objectId
        path {
          id
          objectId
          ...PathItemView2_Fragment
        }
      }
    }
  }
}

fragment PathItemView2_Fragment on Path {
  id
  objectId
  title
  description
  estimate
  isSeries
  published
  createdAt
  instructionCards(order: [seq_ASC, createdAt_ASC]) {
    edges {
      node {
        id
        objectId
        sources {
          __typename
          ... on ContentItem {
            id
            objectId
            content {
              id
              objectId
              thumbURL
              type
              createdAt
            }
          }
          ... on Path {
            id
            objectId
            createdAt
            coverImage {
              url
            }
          }
          ... on Node {
            __isNode: __typename
            id
          }
        }
      }
    }
  }
  analytics {
    emojiTotalCount
    emojiCountMap
    id
  }
  membership {
    id
    objectId
  }
  coverImage {
    url
  }
  author {
    id
    objectId
    name
    originProfileURL
    profileImage {
      url
    }
    ...UserProfileImageFragment
  }
}

fragment UserProfileImageFragment on User {
  originProfileURL
  profileImage {
    url
  }
  name
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v3 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "FileInfo",
  "kind": "LinkedField",
  "name": "coverImage",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaseHorizontalPathList2Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AnalyticsPathConnection",
        "kind": "LinkedField",
        "name": "analyticsPaths",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalyticsPathEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalyticsPath",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Path",
                    "kind": "LinkedField",
                    "name": "path",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PathItemView2_Fragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BaseHorizontalPathList2Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AnalyticsPathConnection",
        "kind": "LinkedField",
        "name": "analyticsPaths",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AnalyticsPathEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AnalyticsPath",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Path",
                    "kind": "LinkedField",
                    "name": "path",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isSeries",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "published",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "order",
                            "value": [
                              "seq_ASC",
                              "createdAt_ASC"
                            ]
                          }
                        ],
                        "concreteType": "InstructionCardConnection",
                        "kind": "LinkedField",
                        "name": "instructionCards",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InstructionCardEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "InstructionCard",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "sources",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v5/*: any*/),
                                          (v6/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Content",
                                            "kind": "LinkedField",
                                            "name": "content",
                                            "plural": false,
                                            "selections": [
                                              (v5/*: any*/),
                                              (v6/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "thumbURL",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "type",
                                                "storageKey": null
                                              },
                                              (v7/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "ContentItem",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v5/*: any*/),
                                          (v6/*: any*/),
                                          (v7/*: any*/),
                                          (v9/*: any*/)
                                        ],
                                        "type": "Path",
                                        "abstractKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v5/*: any*/)
                                        ],
                                        "type": "Node",
                                        "abstractKey": "__isNode"
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "instructionCards(order:[\"seq_ASC\",\"createdAt_ASC\"])"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AnalyticsPath",
                        "kind": "LinkedField",
                        "name": "analytics",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emojiTotalCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emojiCountMap",
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Membership",
                        "kind": "LinkedField",
                        "name": "membership",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "originProfileURL",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FileInfo",
                            "kind": "LinkedField",
                            "name": "profileImage",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8cf650192b7539b23c77deba6fccc09f",
    "id": null,
    "metadata": {},
    "name": "BaseHorizontalPathList2Query",
    "operationKind": "query",
    "text": "query BaseHorizontalPathList2Query(\n  $where: AnalyticsPathWhereInput\n  $order: [AnalyticsPathOrder!]\n  $first: Int\n) {\n  analyticsPaths(where: $where, order: $order, first: $first) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        path {\n          id\n          objectId\n          ...PathItemView2_Fragment\n        }\n      }\n    }\n  }\n}\n\nfragment PathItemView2_Fragment on Path {\n  id\n  objectId\n  title\n  description\n  estimate\n  isSeries\n  published\n  createdAt\n  instructionCards(order: [seq_ASC, createdAt_ASC]) {\n    edges {\n      node {\n        id\n        objectId\n        sources {\n          __typename\n          ... on ContentItem {\n            id\n            objectId\n            content {\n              id\n              objectId\n              thumbURL\n              type\n              createdAt\n            }\n          }\n          ... on Path {\n            id\n            objectId\n            createdAt\n            coverImage {\n              url\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n  analytics {\n    emojiTotalCount\n    emojiCountMap\n    id\n  }\n  membership {\n    id\n    objectId\n  }\n  coverImage {\n    url\n  }\n  author {\n    id\n    objectId\n    name\n    originProfileURL\n    profileImage {\n      url\n    }\n    ...UserProfileImageFragment\n  }\n}\n\nfragment UserProfileImageFragment on User {\n  originProfileURL\n  profileImage {\n    url\n  }\n  name\n}\n"
  }
};
})();
(node as any).hash = 'dcc5760f22c16711c6ab6c8e417d58b7';
export default node;
