import React from 'react';
import {
  StyleSheet,
  View,
  Image,
  ViewStyle,
  TextStyle,
  ImageStyle,
  ImageSourcePropType,
} from 'react-native';
import { Flex } from '@ant-design/react-native';
import { graphql, useFragment } from 'react-relay';
import PreviewImage from '../PreviewImage';
import LFText from '../typo/LFText';
import { colors } from '../../constants/styleGuide';
import { ContentPreview2Fragment$key } from '../../__generated__/ContentPreview2Fragment.graphql';
import LFWhiteSpace from '../LFWhiteSpace';
import _ from 'lodash';
import getPlaceholderContentImageNameUsingSeed from '../../helper/getPlaceholderContentImageNameUsingSeed';
import dayjs from 'dayjs';
import { useDeviceType } from '../../hooks/deviceType';
export interface ContentPreviewProps {
  title?: string | null;
  contentFrgmt: ContentPreview2Fragment$key;
  style?: ViewStyle;
  titleStyle?: TextStyle;
  hideType?: boolean;
}
const ContentPreview2: React.FC<ContentPreviewProps> = ({
  title,
  contentFrgmt,
  style,
  titleStyle,
  hideType,
  children,
}) => {
  const content = useFragment(
    graphql`
      fragment ContentPreview2Fragment on Content {
        __typename
        id
        objectId
        type
        thumbURL
        title
        createdAt
      }
    `,
    contentFrgmt
  );

  const typeInfo = {
    VIDEO: {
      title: 'Video',
      thumbSize: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
      },
    },
    BOOK: {
      title: 'Book',
      thumbSize: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
      },
    },
    LINK: {
      title: 'LINK',
      thumbSize: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
      },
    },
  }[(content.type || 'LINK') as 'VIDEO' | 'BOOK' | 'LINK'];

  return (
    <SimplePreview
      style={style}
      thumbImageSource={
        content.thumbURL
          ? { uri: content.thumbURL || '' }
          : require(`../../assets/images/${getPlaceholderContentImageNameUsingSeed(
              {
                seed: dayjs((content.createdAt || 0) as number).valueOf(),
              }
            )}`)
      }
      tagTitle={
        hideType ? null : typeInfo.title === 'LINK' ? 'POST' : typeInfo.title
      }
      title={title ?? content.title}
      titleStyle={titleStyle}
      thumbStyle={typeInfo.thumbSize}
    >
      {children}
    </SimplePreview>
  );
};

export const SimplePreview: React.FC<{
  title?: string | null;
  style?: ViewStyle;
  titleStyle?: TextStyle;
  thumbStyle?: ImageStyle;
  thumbImageSource?: ImageSourcePropType;
  tagTitle?: string | null;
  simpleMode?: boolean;
}> = ({
  style,
  title,
  titleStyle,
  thumbStyle,
  thumbImageSource,
  tagTitle,
  children,
}) => {
  const deviceType = useDeviceType();
  return (
    <Flex direction="column" align="stretch" style={style}>
      <LFText
        accessibilityRole="heading"
        aria-level={3}
        numberOfLines={2}
        style={[
          deviceType === 'MOBILE' ? styles.titleForMobile : styles.titleForPC,
          titleStyle,
        ]}
      >
        {title}
      </LFText>
      <LFWhiteSpace size={10} />
      {Boolean(thumbImageSource) && (
        <View style={[thumbStyle, { position: 'relative' }]}>
          <View style={styles.imgWrapper}>
            <Image
              accessibilityLabel=""
              source={thumbImageSource || { uri: '' }}
              style={[thumbStyle, { width: '102%' }]}
            />
            {tagTitle === 'Video' && (
              <Image
                accessibilityLabel="영상 재생 모양 아이콘"
                source={require('../../assets/images/playButton@3x.png')}
                style={styles.imgStyle}
              />
            )}
          </View>
          {tagTitle ? (
            <View style={styles.tagWrapper}>
              <LFText style={styles.tagTextStyle}>{tagTitle}</LFText>
            </View>
          ) : null}
        </View>
      )}
    </Flex>
  );
};

export const ContentPreviewWrapView: React.FC<{
  style?: ViewStyle;
}> = ({ style, children }) => (
  <Flex
    direction="row"
    align="stretch"
    style={[
      {
        flex: 1,
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: colors.BG_2,
        padding: 8,
      },
      style,
    ]}
  >
    {children}
  </Flex>
);
export default ContentPreview2;

const styles = StyleSheet.create({
  titleForMobile: {
    height: 50,
    justifyContent: 'flex-start',
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },

  titleForPC: {
    height: 50,
    justifyContent: 'flex-start',
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },

  imgWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },

  imgStyle: {
    position: 'absolute',
    width: 40,
    height: 40,
  },

  tagWrapper: {
    padding: 4,
    position: 'absolute',

    zIndex: 10,
    opacity: 0.9,
    borderRadius: 4,
    backgroundColor: colors.SECONDARY_BLACK,
  },

  tagTextStyle: {
    textAlign: 'left',
    fontSize: 10,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    color: colors.TEXT_WHITE,
  },
});
