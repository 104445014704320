import { Image, StyleSheet, View, ViewStyle } from 'react-native';
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { ContentItemView3_Fragment$key } from '../__generated__/ContentItemView3_Fragment.graphql';
import { colors } from '../constants/styleGuide';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import LFText from './typo/LFText';
import LFWhiteSpace from './LFWhiteSpace';
import ContentPreview2 from './InstructionCard/ContentPreview2';
import UserProfileImage from './UserProfileImage';
import _ from 'lodash';
import BookMarkButton from './BookMarkButton';
import { secToHHMMSS } from './TimeNote';
import BookMarkButtonForPreview from './BookMarkButtonForPreview';
import LFLink from './LFLink';
import { useHistory } from 'react-router-dom';
import { EMOJIS } from './ActivityStreamItemView';
import { useDeviceType } from '../hooks/deviceType';

const BOOK_MARK_REACTION = 'bookmark';

export interface IContentItemView3 {
  simpleMode?: boolean;
  numberOfNotes?: number;
  contentItemFragment: ContentItemView3_Fragment$key | null | undefined;
  style?: ViewStyle;
  scraped?: { status: boolean; reactionId?: string };
  onScrape?: () => void;
  disableMode?: boolean;
}
const ContentItemView3: React.FC<IContentItemView3> = ({
  simpleMode = false,
  numberOfNotes,
  contentItemFragment,
  style,
  scraped,
  onScrape,
  disableMode = false,
}) => {
  const history = useHistory();
  const contentItem = useFragment(
    graphql`
      fragment ContentItemView3_Fragment on ContentItem {
        id
        objectId
        title
        published
        analytics {
          viewCount
          emojiTotalCount
          emojiCountMap
        }
        content {
          type
          link
          thumbURL
          ...ContentPreview2Fragment
          analytics {
            viewCount
            contentItemCount
            emojiTotalCount
            emojiCountMap
            contentItemAuthors {
              ... on User {
                id
                objectId
                ...UserProfileImageFragment
              }
            }
          }
        }
        author {
          objectId
          name
          originProfileURL
          ...UserProfileImageFragment
        }
        noteData {
          ... on Element {
            value
          }
        }
      }
    `,
    contentItemFragment || null
  );

  const contentItemEmojiCountMapExtractedBookMark = _.chain(
    contentItem?.analytics?.emojiCountMap as {
      [key in EMOJIS]: number;
    }
  )
    .map((v, k) => ({ [k]: v }))
    .reduce((prev, curr) => {
      return _.has(curr, 'bookmark') ? { ...prev } : { ...prev, ...curr };
    }, {})
    .value();
  const emojiCountMap = contentItemEmojiCountMapExtractedBookMark;
  const emojiTotalCount = _.reduce(
    contentItemEmojiCountMapExtractedBookMark,
    (prev, curr) => prev + curr,
    0
  );

  const deviceType = useDeviceType();
  return (
    <View style={[styles.container, style]}>
      {/* <FlippedCorner /> */}
      <LFLink
        disabled={disableMode}
        onPress={() => {
          history.push(`/u/${contentItem?.author?.objectId}`);
        }}
        style={{
          flexDirection: 'row',
          paddingTop: 16,
          paddingHorizontal: 16,
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <UserProfileImage
          userFrgmt={contentItem?.author}
          size={24}
          style={{ borderWidth: 1, borderColor: colors.BORDER_10 }}
        />
        <LFWhiteSpace direction="row" size="xs" />
        <LFText
          numberOfLines={1}
          style={
            deviceType === 'MOBILE'
              ? styles.authorNameForMobile
              : styles.authorNameForPC
          }
        >
          {contentItem?.author?.name}
        </LFText>
        <LFText
          style={[
            deviceType === 'MOBILE'
              ? styles.authorNameForMobile
              : styles.authorNameForPC,
            { flexShrink: 0 },
          ]}
        >
          의 학습노트
        </LFText>
      </LFLink>
      <WhiteSpace size="lg" />

      {contentItem?.content ? (
        <ContentPreview2
          title={contentItem.title}
          titleStyle={{ paddingHorizontal: 16 }}
          contentFrgmt={contentItem?.content}
          published={
            contentItem.published == null ? true : contentItem.published
          }
        />
      ) : null}

      {/* 스탬프 섹션 */}
      <Flex direction="column" align="start" style={{ marginTop: 'auto' }}>
        <Flex
          align="start"
          direction="column"
          style={{ flex: 1, width: '100%' }}
          // style={[
          //   thumbURL
          //     ? { flex: 1, width: '100%', marginLeft: 5 }
          //     : { flex: 1, width: '100%', marginTop: 29 },
          // ]}
        >
          <LFWhiteSpace size="sm" direction="column" />
          <LFText
            style={
              deviceType === 'MOBILE'
                ? styles.stampCountForMobile
                : styles.stampCountForPC
            }
          >
            ⏱ 핵심 스탬프
            <LFWhiteSpace direction="row" size={3} />
            <LFText
              style={
                deviceType === 'MOBILE'
                  ? styles.highlightCountNumForMobile
                  : styles.highlightCountNumForPC
              }
            >
              {contentItem?.noteData?.length || 0}
            </LFText>
            개
          </LFText>
          {simpleMode ? null : (
            <View style={{ width: '100%' }}>
              <LFWhiteSpace size={'sm'} direction="column" />
              <View style={{ height: 60, width: '100%' }}>
                {contentItem?.noteData
                  ?.slice(0, numberOfNotes)
                  .map(({ value: n }, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Flex
                          style={{
                            paddingHorizontal: 16,
                            width: '100%',
                          }}
                        >
                          <View style={styles.timeLineWrapper}>
                            <LFText style={styles.timeLineText}>
                              {secToHHMMSS(n.time)}
                            </LFText>
                          </View>
                          <LFWhiteSpace direction="row" size={5} />
                          <LFText
                            numberOfLines={1}
                            style={styles.timeStampText}
                          >
                            {n.note}
                          </LFText>
                        </Flex>
                        <LFWhiteSpace size="xs" />
                      </React.Fragment>
                    );
                  })}
                <LFWhiteSpace size={'xs'} direction="column" />
              </View>
            </View>
          )}
        </Flex>
      </Flex>

      <Flex style={{ padding: 16 }}>
        {Boolean(emojiTotalCount) && (
          <Flex direction="row">
            {_.map(emojiCountMap, (_, key) => (
              <View style={styles.emojiCircle} key={key}>
                <LFText style={{ fontSize: 10 }}>{key}</LFText>
              </View>
            ))}
            <LFWhiteSpace direction="row" size={'xs'} />
            <LFText style={styles.additionalInfo}>
              {/* 이모지 리액션{' '} */}
              <LFText style={{ color: colors.TEXT_80, fontSize: 12 }}>
                {emojiTotalCount.toLocaleString('ko-KR')}
              </LFText>
            </LFText>
          </Flex>
        )}
        <LFText style={[styles.additionalInfo, { marginLeft: 'auto' }]}>
          {onScrape ? (
            <BookMarkButtonForPreview
              disableMode={disableMode}
              targetClassName="ContentItem"
              targetId={contentItem?.objectId || ''}
              onCompleted={onScrape}
              scraped={scraped}
            />
          ) : (
            <BookMarkButton
              disableMode={disableMode}
              targetClassName="ContentItem"
              targetId={contentItem?.objectId || ''}
            />
          )}
        </LFText>
      </Flex>
    </View>
  );
};

export default ContentItemView3;

function FlippedCorner() {
  return (
    <View style={{ position: 'relative', top: 0, right: 0 }}>
      <View
        style={[
          styles.attachedCornerBox,
          {
            borderRightColor: colors.BORDER_10,
            borderRightWidth: 0.5,
            top: 19.5,
            right: -0.5,
          },
        ]}
      />
      <View
        style={[
          styles.attachedCornerBox,
          {
            borderTopColor: colors.BORDER_10,
            borderTopWidth: 0.5,
            top: -0.5,
            right: 19.5,
          },
        ]}
      />
      <Image
        source={require('../assets/images/cut@3x.png')}
        style={styles.flippedCornerAsset}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderRadius: 16,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: colors.BORDER_10,
    backgroundColor: colors.BG_WHITE,
    flex: 1,
    width: '100%',
    // borderTopRightRadius: 35,
  },

  authorNameForMobile: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },

  authorNameForPC: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },

  additionalInfo: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },

  emojiCircle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.BORDER_WHITE,
    backgroundColor: '#d48000',
    alignItems: 'center',
    justifyContent: 'center',
  },

  attachedCornerBox: {
    position: 'absolute',
    width: 20,
    height: 20,
    backgroundColor: colors.BG_WHITE,
    borderStyle: 'solid',
  },

  flippedCornerAsset: {
    position: 'absolute',
    top: -1,
    right: -1,
    width: 20,
    height: 20,
  },

  stampCountForMobile: {
    paddingHorizontal: 16,
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    color: colors.SECONDARY_BLACK,
  },

  stampCountForPC: {
    paddingHorizontal: 16,
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    color: colors.SECONDARY_BLACK,
  },

  highlightCountNumForMobile: {
    color: colors.PRIMARY_100,
    fontWeight: '700',
    fontSize: 14,
  },

  highlightCountNumForPC: {
    color: colors.PRIMARY_100,
    fontWeight: '700',
    fontSize: 16,
  },

  timeLineWrapper: {
    alignItems: 'center',
    width: 45,
    borderRadius: 4,
    backgroundColor: colors.PRIMARY_10,
  },

  timeLineText: {
    padding: 4,
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.PRIMARY_100,
  },

  timeStampText: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
});
