import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';

interface QueryParamObjectProp {
  key: string;
  value: string | null;
}

const useQueryStringChanger = () => {
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const mutateQueryString = (inputQueryParamObjs: QueryParamObjectProp[]) => {
    const queryParamObj = Object.fromEntries(urlSearchParams);
    const newQueryParamObj = _.chain(inputQueryParamObjs)
      .filter(({ value }) => Boolean(value))
      .reduce(
        (acc, { key, value }) => ({ ...acc, ...{ [key]: value } }),
        queryParamObj
      )
      .value();
    const newQueryParamString = _.reduce(
      newQueryParamObj,
      (acc, v, k) => {
        const prefix = acc ? '&' : '?';
        return acc + prefix + `${k}=${v}`;
      },
      ''
    );
    history.replace(location.pathname + newQueryParamString);
  };

  const removeQueryString = ({ key }: { key: string }) => {
    const queryParamObj = Object.fromEntries(urlSearchParams);
    if (queryParamObj[key]) {
      const queryParamString = _.reduce(
        queryParamObj,
        (acc, v, k) => {
          const prefix = acc ? '&' : '?';
          return acc + (k === key ? '' : `${prefix}${k}=${v}`);
        },
        ''
      );
      history.replace(location.pathname + queryParamString);
    }
  };
  return { mutateQueryString, removeQueryString };
};

export default useQueryStringChanger;
