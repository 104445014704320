import { StyleSheet, View } from 'react-native';
import React, { Suspense, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useAuth } from '../../hooks/auth';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';
import LFLink from '../../components/LFLink';
import PromotionItemView from '../../components/PromotionItemView';
import DotPagination from '../../components/DotPagination';
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { HomeMainTabQuery } from '../../__generated__/HomeMainTabQuery.graphql';
import { colors } from '../../constants/styleGuide';
import PositionSelector2 from './PositionSelector2';
import Hr from '../../components/Hr';
import _ from 'lodash';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import LinearGradient from 'react-native-linear-gradient';
import { InfinitePaginationButton } from '../../components/PaginationButton';
import HomeMainTabSkeleton from '../../components/skeleton/HomeMainTabSkeleton';
import { useDeviceType } from '../../hooks/deviceType';
import { useToggle } from 'ahooks';
import BaseHomeSectionList from '../../components/BaseHomeSectionList';
import {
  HomeSectionOrder,
  HomeSectionWhereInput,
} from '../../__generated__/BaseHomeSectionListPaginationFragmentQuery.graphql';

const HOME_SECTION_PAGINATION_COUNT = 2;

const HomeMainTab = () => {
  const { user } = useAuth();
  const history = useHistory();
  const isPromoSlidingRef = useRef(false);
  const [currentPromotionIdx, setCurrentPromotionIdx] = useState(0);

  const { homePromotions } = useLazyLoadQuery<HomeMainTabQuery>(
    graphql`
      query HomeMainTabQuery {
        homePromotions(order: order_ASC) {
          edges {
            node {
              id
              objectId
              location
              ...PromotionItemViewFragment
            }
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' }
  );
  const promotions = homePromotions.edges?.map((edge) => edge?.node);

  const homeSectionWhere: HomeSectionWhereInput = {
    published: {
      equalTo: true,
    },
  };
  const homeSectionOrder: HomeSectionOrder[] = [
    'priority_ASC',
    'updatedAt_DESC',
  ];
  const slider = useRef(null);
  const deviceType = useDeviceType();

  const [progressingPathsOpen, { toggle: toggleProgressingPaths }] =
    useToggle(false);
  const [recentViewedNotesOpen, { toggle: toggleRecentViewedNotes }] =
    useToggle(false);

  return (
    <>
      <View
        style={{
          alignItems: 'stretch',
          backgroundColor: colors.PRIMARY_100,
        }}
      >
        <Slider
          ref={slider}
          speed={500}
          infinite
          slidesToShow={1}
          slidesToScroll={1}
          swipeToSlide
          arrows={false}
          onSwipe={() => (isPromoSlidingRef.current = true)}
          afterChange={(currenSlide) => {
            isPromoSlidingRef.current = false;
            setCurrentPromotionIdx(currenSlide);
          }}
          initialSlide={0}
        >
          {promotions?.map((promotion) => (
            <LFLink
              to={promotion?.location as H.LocationDescriptor}
              key={promotion?.objectId}
              onPress={() => {
                isPromoSlidingRef.current
                  ? undefined
                  : history.push(promotion?.location as H.LocationDescriptor);
              }}
            >
              <PromotionItemView promotionFragment={promotion || null} />
            </LFLink>
          ))}
        </Slider>
        {deviceType === 'MOBILE' ? (
          <DotPagination
            style={{ position: 'absolute', bottom: 24, left: 24 }}
            total={promotions?.length || 0}
            active={currentPromotionIdx}
          />
        ) : (
          <InfinitePaginationButton
            currentPromoCardIdx={currentPromotionIdx}
            totalNumOfPromoCard={promotions?.length}
            onPrevClick={() => slider?.current?.slickPrev()}
            onNextClick={() => slider?.current?.slickNext()}
            style={{ position: 'absolute', bottom: 8, right: 8 }}
          />
        )}
      </View>
      <View>
        <Hr lineWidth={8} color={colors.BG_3} style={styles.hr} />
      </View>
      <PositionSelector2
        justify={'between'}
        style={
          deviceType === 'MOBILE'
            ? {
                padding: 24,
              }
            : {
                paddingTop: 20,
                paddingRight: 72,
                paddingBottom: 20,
                paddingLeft: 72,
              }
        }
        scrolling={false}
        onPressPositionItem={(positionText) => {
          history.push({
            pathname: '/notes',
            search: `position=${positionText}`,
          });
        }}
        data={[
          {
            text: '개발자',
            defaultImageSource: require('../../assets/images/newPositionDeveloper@3x.png'),
            imageAlt: '개발자 콘텐츠 탐색',
          },
          {
            text: 'P.O/기획자',
            defaultImageSource: require('../../assets/images/newPositionPO@3x.png'),
            imageAlt: 'P.O/기획자 콘텐츠 탐색',
          },
          {
            text: '디자이너',
            defaultImageSource: require('../../assets/images/newPositionDesigner@3x.png'),
            imageAlt: '디자이너 콘텐츠 탐색',
          },
          {
            text: 'D.S/D.A',
            defaultImageSource: require('../../assets/images/newPositionDS@3x.png'),
            imageAlt: 'D.S/D.A 콘텐츠 탐색',
          },
          {
            text: '마케터',
            defaultImageSource: require('../../assets/images/newPositionMarketer@3x.png'),
            imageAlt: '마케터 콘텐츠 탐색',
          },
        ]}
      />

      <LFWhiteSpace size={'sm'} direction="column" />

      <View style={styles.bottomSheetContainerBody}>
        {/* HTML 코드가 아래 위치할수록 zIndex 우선순위 상승 */}
        {/* bottomSheetContainerBody의 자식이면 그 위쪽으로 존재하는 요소들을 덮을 수 있게 됩니다. */}
        {/* 한편, bottomSheetContainerBody 내에서 자식끼리도 위치에 따라 zIndex 우선 순위가 결정 */}
        {/* 그렇기에 LinearGradient가 아래 위치한 HomeSectionTitle과 겹쳐도 z축 상에서 우선순위가 더 낮기 때문에 밑에 깔리게 됩니다 */}
        {/* 결론: position: absolute를 사용하면 위치 계산 시 다른 요소의 영향을 받지 않게 되지만 코드 위치에 따른 z축 우선순위는 유지 */}
        <LinearGradient
          style={styles.bgLinearGradient}
          colors={[colors.BG_WHITE, colors.BG_2]}
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
        />
        <Hr lineWidth={8} color={colors.BG_3} style={styles.hr} />
        {/* {user && (
          <>
            <>
              <HomeSectionTitle
                right={
                  <ToggleButton
                    isActive={progressingPathsOpen}
                    onPress={toggleProgressingPaths}
                    style={{ position: 'relative' }}
                  />
                }
                title="💪🏼러닝패스 이어하기!"
                subTitle="오늘도 이어서 해볼까요?"
                testID="home-learning-contents-more:HomeScreen"
                to="/me/in-progress-path"
              />
              {progressingPathsOpen && (
                <Suspense fallback={<ActivityIndicator />}>
                  <ProgressingPaths />
                </Suspense>
              )}
            </>
            <LFWhiteSpace size="xl" />
            <>
              <HomeSectionTitle
                right={
                  <ToggleButton
                    isActive={recentViewedNotesOpen}
                    onPress={toggleRecentViewedNotes}
                    style={{ position: 'relative' }}
                  />
                }
                title="👀최근 본 학습노트예요"
                subTitle="학습노트 복습은 국룰이지!"
                to="/me/recent"
              />
              {recentViewedNotesOpen && (
                <Suspense fallback={<ActivityIndicator />}>
                  <RecentViewedNotes />
                </Suspense>
              )}
            </>
          </>
        )} */}

        <Suspense
          fallback={<HomeMainTabSkeleton style={{ marginLeft: -16 }} />}
        >
          <BaseHomeSectionList
            where={homeSectionWhere}
            order={homeSectionOrder}
            first={HOME_SECTION_PAGINATION_COUNT}
          />
        </Suspense>
      </View>
    </>
  );
};

export default HomeMainTab;

const styles = StyleSheet.create({
  bottomSheetContainerBody: {
    backgroundColor: colors.BG_2,
    paddingHorizontal: 16,
    paddingTop: 24,
  },
  homeSectionFallBack: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 25,
    marginBottom: 25,
  },
  bgLinearGradient: {
    height: 138,
    position: 'absolute',
    top: -32,
    left: 0,
    right: 0,
  },
  hr: {
    position: 'absolute',
    top: -8,
    left: 0,
    right: 0,
  },
});
