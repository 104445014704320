import React, { useCallback, useMemo, useState } from 'react';
import { Flex, ActivityIndicator } from '@ant-design/react-native';
import {
  ScrollView,
  StyleSheet,
  View,
  TouchableOpacity,
  SectionList,
} from 'react-native';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  usePaginationFragment,
} from 'react-relay';
import { useActionSheet } from '@expo/react-native-action-sheet';
import _ from 'lodash';
import { MyContentItemScreenContentItemQuery } from '../__generated__/MyContentItemScreenContentItemQuery.graphql';
import { MyContentItemScreenProfileContentItemsPaginationQuery } from '../__generated__/MyContentItemScreenProfileContentItemsPaginationQuery.graphql';
import NavBar, { HistoryBackButton } from '../components/NavBar';
import { useAuth } from '../hooks/auth';
import { UpdateContentItemInput } from '../__generated__/MyContentItemScreenContentItemUpdateMutation.graphql';
import {
  DeleteContentItemInput,
  MyContentItemScreenContentItemDeleteMutation,
} from '../__generated__/MyContentItemScreenContentItemDeleteMutation.graphql';
import NoItemFromProfileMenuView from '../components/NoItemFromProfileMenuView';
import { MyContentItemScreenContentItemPaginationFragment$key } from '../__generated__/MyContentItemScreenContentItemPaginationFragment.graphql';
import ListitemContentItem from '../components/ListItemContentItem';
import LFLink from '../components/LFLink';
const MyContentItemScreen = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { userObjectId: userObjectIdFromURL } =
    useParams<{ userObjectId: string }>();
  const currentUserObjectId = userObjectIdFromURL || user?.objectId;
  const location = useLocation<{ tabIdx: number }>();
  const { tabIdx } = location.state || { tabIdx: 0 };
  const isMe = currentUserObjectId === user?.objectId;
  const { showActionSheetWithOptions } = useActionSheet();

  // contentItem 공개/비공개
  const [commitUpdateContentItem, isUpdateContentItem] = useMutation(
    graphql`
      mutation MyContentItemScreenContentItemUpdateMutation(
        $input: UpdateContentItemInput!
      ) {
        updateContentItem(input: $input) {
          clientMutationId
          contentItem {
            id
            objectId
            title
            published
          }
        }
      }
    `
  );

  // contentItem 삭제
  const [commitDeleteContentItem, isDeleteContentItem] =
    useMutation<MyContentItemScreenContentItemDeleteMutation>(
      graphql`
        mutation MyContentItemScreenContentItemDeleteMutation(
          $input: DeleteContentItemInput!
          $connections: [String!]!
        ) {
          deleteContentItem(input: $input) {
            contentItem {
              id @deleteEdge(connections: $connections)
              objectId
              title
            }
          }
        }
      `
    );

  const contentItemsQueryRef =
    useLazyLoadQuery<MyContentItemScreenContentItemQuery>(
      graphql`
        query MyContentItemScreenContentItemQuery(
          $first: Int
          $after: String
          $userObjectId: ID
        ) {
          ...MyContentItemScreenContentItemPaginationFragment
        }
      `,
      {
        userObjectId: currentUserObjectId,
        first: 10,
      },
      {
        fetchPolicy: 'store-and-network',
      }
    );

  const contentItemPagination = usePaginationFragment<
    MyContentItemScreenProfileContentItemsPaginationQuery,
    MyContentItemScreenContentItemPaginationFragment$key
  >(
    graphql`
      fragment MyContentItemScreenContentItemPaginationFragment on Query
      @refetchable(
        queryName: "MyContentItemScreenProfileContentItemsPaginationQuery"
      ) {
        contentItems(
          first: $first
          after: $after
          where: { author: { have: { objectId: { equalTo: $userObjectId } } } }
          order: updatedAt_DESC
        ) @connection(key: "ContentItem_contentItems") {
          __id
          edges {
            node {
              __typename
              id
              objectId
              published
              content {
                __typename
                id
                objectId
              }
              ...ListItemContentItem_Fragment
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
          }
        }
      }
    `,
    contentItemsQueryRef
  );

  const myContentItemSection = contentItemPagination.data?.contentItems.edges
    ?.length
    ? [
        {
          data: _.chunk(
            _.map(
              contentItemPagination.data.contentItems.edges,
              (e) => e?.node
            ),
            2
          ),
        },
      ]
    : [];

  const sections = myContentItemSection;

  return (
    <View style={styles.container}>
      <NavBar title="내 학습노트" left={<HistoryBackButton />} />
      <SectionList
        contentContainerStyle={{ flexGrow: 1 }}
        ListEmptyComponent={
          <NoItemFromProfileMenuView
            title="내가 만든 학습노트가 없어요."
            description="만들면서 기억에 큰 도움이 되요."
            onPress={() => history.push({ pathname: '/new-note' })}
            btnText="학습노트 만들기"
          />
        }
        sections={sections}
        keyExtractor={(item) => {
          return _.reduce(
            item,
            (sum, i) => {
              return sum + i?.objectId;
            },
            ''
          );
        }}
        renderItem={(info) => {
          return (
            <Flex
              direction="row"
              align="stretch"
              style={{
                marginHorizontal: -8,
                marginBottom: 16,
                paddingHorizontal: 16,
              }}
            >
              {_.map(_.castArray(info.item), (item) => {
                if (!item?.objectId) {
                  console.log('### invalid :', item, info.index);
                  return <View></View>;
                } else if (item?.__typename === 'ContentItem') {
                  return (
                    <LFLink
                      testID="content-item:MyContentItemScreen"
                      style={{ width: '50%', padding: 5 }}
                      onPress={() => {
                        console.log(item);
                        history.push(
                          `/note/${item?.content?.objectId}/${item?.objectId}`
                        );
                      }}
                    >
                      <ListitemContentItem
                        contentItemFrgmt={item}
                        onPressMore={
                          !isMe
                            ? undefined
                            : () => {
                                showActionSheetWithOptions(
                                  {
                                    options: [
                                      item?.published
                                        ? '비공개로 변경'
                                        : '공개로 변경',
                                      '삭제',
                                      '취소',
                                    ],
                                    destructiveButtonIndex: 1,
                                    cancelButtonIndex: 2,
                                  },
                                  (idx) => {
                                    // console.log('idx:', idx, edge?.node?.objectId);
                                    if (idx === 0) {
                                      if (item) {
                                        commitUpdateContentItem({
                                          variables: {
                                            input: {
                                              id: item.objectId,
                                              fields: {
                                                published: !item.published,
                                              },
                                            },
                                          },
                                        });
                                      }
                                    } else if (idx === 1) {
                                      commitDeleteContentItem({
                                        variables: {
                                          input: {
                                            id: item?.objectId,
                                          } as DeleteContentItemInput,
                                          connections: [
                                            contentItemPagination.data
                                              .contentItems.__id,
                                          ],
                                        },
                                        // 수동으로 업데이트 하는 예시
                                        // updater: (store) => {
                                        //   const deleteReviewPayload =
                                        //     store.getRootField(
                                        //       'deleteContentItem'
                                        //     );
                                        //   if (!deleteReviewPayload) {
                                        //     return;
                                        //   }
                                        //   const deletedReviewId =
                                        //     deleteReviewPayload
                                        //       .getLinkedRecord('contentItem')
                                        //       .getValue('id');
                                        //   // const reviewsConnection ;// to do get connection
                                        //   const reviewsConnection = store.get(
                                        //     'client:root:__ContentItem_contentItems_connection(order:"updatedAt_DESC",where:{"author":{"have":{"objectId":{"equalTo":"1zXdCgsbhC"}}}})'
                                        //   );

                                        //   ConnectionHandler.deleteNode(
                                        //     reviewsConnection,
                                        //     deletedReviewId
                                        //   );
                                        // },
                                      });
                                    }
                                  }
                                );
                              }
                        }
                      />
                    </LFLink>
                  );
                }
                return <View />;
              })}
            </Flex>
          );
        }}
        onEndReached={() => {
          if (contentItemPagination && contentItemPagination.hasNext) {
            contentItemPagination.loadNext(10);
          }
        }}
        onEndReachedThreshold={0.1}
        ListFooterComponent={() => {
          return (
            <>
              {contentItemPagination?.isLoadingNext && (
                <Flex align="center" justify="center" style={{ height: 100 }}>
                  <ActivityIndicator />
                </Flex>
              )}
              <View style={{ height: 40 }} />
            </>
          );
        }}
      />
    </View>
  );
};

// 컨텐츠 아이템 UI 컴포넌트
export default MyContentItemScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
  },
});
