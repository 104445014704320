import _ from 'lodash';

export const resizedImageURL = (
  url: string | undefined,
  width: number,
  height?: number,
  fallbackURL?: string | null
): string | undefined => {
  if (_.isString(url)) {
    const splittedArray = url.split('/');
    if (splittedArray.length) {
      // splittedArray[splittedArray.length-1] = `` _.last(splittedArray)
      const ext = _.last(_.last(splittedArray)?.split('.'));
      splittedArray.splice(
        splittedArray.length - 1,
        0,
        `${width}x${height || width}`,
        ext || 'png'
      );
      return splittedArray.join('/');
    }
  }
  return url || fallbackURL || undefined;
};
