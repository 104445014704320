import { Flex } from '@ant-design/react-native';
import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Image, StyleSheet, ViewStyle } from 'react-native';
import { useHistory } from 'react-router';
import LFButton from '../../components/LFButton';
import LFLink from '../../components/LFLink';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import NavBar from '../../components/NavBar2';
import LFText from '../../components/typo/LFText';
import { colors } from '../../constants/styleGuide';

const ErrorScreen: React.FC<{
  navTitle?: string;
  title: string;
  description?: string;
  leftButtonOptions?: {
    title?: string;
    onPress: () => void;
  };
  rightButtonOptions?: {
    title?: string;
    onPress: () => void;
  };
  statusCode?: '404';
  iconType?: 'warning' | 'content' | 'network';
  style?: ViewStyle;
}> = ({
  navTitle = '',
  title,
  description,
  leftButtonOptions,
  rightButtonOptions,
  statusCode,
  iconType = 'warning',
  style,
}) => {
  const history = useHistory();
  const jumpToHomeScreen = () => {
    history.push('/');
  };
  return (
    <Flex style={[styles.container, style]} direction="column" align="stretch">
      <NavBar title={navTitle} />
      {statusCode === '404' ? (
        <Helmet>
          <meta name="render:status_code" content="404" />
        </Helmet>
      ) : null}
      <Flex
        direction="column"
        style={{ flex: 1, paddingHorizontal: 24, backgroundColor: colors.BG_2 }}
        align="center"
        justify="center"
      >
        <LFText style={styles.title}>{title}</LFText>
        <LFWhiteSpace size="sm" />
        <Image
          accessibilityLabel=""
          style={styles.image}
          source={
            iconType === 'content'
              ? require('../../assets/images/errorScreen/errorWarnning2@3x.png')
              : iconType === 'network'
              ? require('../../assets/images/errorScreen/errorNetwork@3x.png')
              : require('../../assets/images/errorScreen/errorWarnning@3x.png') //default
          }
        />
        <LFText style={styles.description}>{description}</LFText>
        <LFWhiteSpace size="lg" />
        <Flex direction="row" style={{ alignSelf: 'stretch' }}>
          <LFButton
            style={{ flex: 1, backgroundColor: colors.PRIMARY_10 }}
            onPress={
              leftButtonOptions?.onPress
                ? leftButtonOptions?.onPress
                : () => {
                    history.goBack();
                  }
            }
            titleTextStyle={{ color: colors.PRIMARY_100 }}
          >
            {leftButtonOptions?.title || '이전 페이지'}
          </LFButton>
          <LFWhiteSpace direction="row" size="sm" />
          <LFButton
            style={{ flex: 1 }}
            // to={rightButtonOptions?.onPress ? undefined : '/'}
            onPress={
              rightButtonOptions?.onPress
                ? rightButtonOptions.onPress
                : jumpToHomeScreen
            }
          >
            {rightButtonOptions?.title || '런핏 홈'}
          </LFButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ErrorScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  image: {
    height: 140,
    width: 140,
  },
  title: {
    fontSize: 24,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 36,
    letterSpacing: 0,
    color: colors.TEXT_100,
  },
  description: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    color: colors.TEXT_60,
    textAlign: 'center',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
