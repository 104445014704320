/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HomeSectionView_Fragment = {
    readonly id: string;
    readonly objectId: string;
    readonly title: string | null;
    readonly subTitle: string | null;
    readonly priority: number | null;
    readonly targetClassName: string | null;
    readonly to: string | null;
    readonly where: unknown | null;
    readonly count: number;
    readonly period: number | null;
    readonly order: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly manuallyPickedObjectIds: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly " $refType": "HomeSectionView_Fragment";
};
export type HomeSectionView_Fragment$data = HomeSectionView_Fragment;
export type HomeSectionView_Fragment$key = {
    readonly " $data"?: HomeSectionView_Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"HomeSectionView_Fragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "type": "Element",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeSectionView_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetClassName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "where",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "period",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "order",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "manuallyPickedObjectIds",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HomeSection",
  "abstractKey": null
};
})();
(node as any).hash = '7077b83055d25c3ced09eae56a2ba829';
export default node;
