// rfce 를 입력하면 자동완성 (초기값)
import React from 'react';
import { Image, StyleSheet, ViewStyle } from 'react-native';
import { colors } from '../constants/styleGuide';
import LFLink from './LFLink';

interface ToggleButton {
  isActive: boolean;
  onPress: (currentState: boolean) => void;
  style?: ViewStyle;
}

function ToggleButton({ isActive, onPress, style }: ToggleButton) {
  return (
    <LFLink
      style={[styles.extendButton, style]}
      onPress={() => onPress(!isActive)}
    >
      <Image
        accessibilityLabel={isActive ? '접기' : '펼치기'}
        style={{ width: 24, height: 24 }}
        source={
          isActive
            ? require('../assets/images/bottomArrow24@3x.png')
            : require('../assets/images/topArrow24@3x.png')
        }
      />
    </LFLink>
  );
}

export default ToggleButton;

const styles = StyleSheet.create({
  extendButton: {
    padding: 7,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.BG_WHITE,

    shadowColor: 'rgba(66, 73, 89, 0.15)',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: colors.BORDER_10,
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
