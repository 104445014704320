import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import Flex, { FlexProps } from '@ant-design/react-native/lib/flex/Flex';
import { colors } from '../constants/styleGuide';

const TipBox: React.FC<FlexProps> = ({ style, children, ...restProps }) => {
  return (
    <Flex style={[styles.container, style]} {...restProps}>
      {children}
    </Flex>
  );
};

export default TipBox;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.SECONDARY_WHITE,
    shadowColor: 'rgba(58, 69, 99, 0.2)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    borderRadius: 12,
    padding: 8,
  },
});
