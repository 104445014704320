/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ProfileScreenMembershipQueryVariables = {
    profileUserId: string;
    loginUserId: string;
    isMe: boolean;
};
export type ProfileScreenMembershipQueryResponse = {
    readonly memberships?: {
        readonly count: number;
    };
    readonly membershipStatuses?: {
        readonly count: number;
    };
};
export type ProfileScreenMembershipQuery = {
    readonly response: ProfileScreenMembershipQueryResponse;
    readonly variables: ProfileScreenMembershipQueryVariables;
};



/*
query ProfileScreenMembershipQuery(
  $profileUserId: ID!
  $loginUserId: ID!
  $isMe: Boolean!
) {
  memberships(where: {author: {have: {objectId: {equalTo: $profileUserId}}}}) @skip(if: $isMe) {
    count
  }
  membershipStatuses(where: {AND: [{member: {have: {objectId: {equalTo: $loginUserId}}}}, {status: {equalTo: "valid"}}, {membership: {have: {author: {have: {objectId: {equalTo: $profileUserId}}}}}}]}) @skip(if: $isMe) {
    count
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMe"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loginUserId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "profileUserId"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "profileUserId"
              }
            ],
            "kind": "ObjectValue",
            "name": "objectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "have"
      }
    ],
    "kind": "ObjectValue",
    "name": "author"
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v5 = [
  {
    "condition": "isMe",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "fields": (v3/*: any*/),
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "MembershipConnection",
        "kind": "LinkedField",
        "name": "memberships",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "equalTo",
                                    "variableName": "loginUserId"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "objectId"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "have"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "member"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "AND.0"
                  },
                  {
                    "kind": "Literal",
                    "name": "AND.1",
                    "value": {
                      "status": {
                        "equalTo": "valid"
                      }
                    }
                  },
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": (v3/*: any*/),
                            "kind": "ObjectValue",
                            "name": "have"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "membership"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "AND.2"
                  }
                ],
                "kind": "ListValue",
                "name": "AND"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "MembershipStatusConnection",
        "kind": "LinkedField",
        "name": "membershipStatuses",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileScreenMembershipQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProfileScreenMembershipQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "e0402e85a068a4f3a003bbb454dc2964",
    "id": null,
    "metadata": {},
    "name": "ProfileScreenMembershipQuery",
    "operationKind": "query",
    "text": "query ProfileScreenMembershipQuery(\n  $profileUserId: ID!\n  $loginUserId: ID!\n  $isMe: Boolean!\n) {\n  memberships(where: {author: {have: {objectId: {equalTo: $profileUserId}}}}) @skip(if: $isMe) {\n    count\n  }\n  membershipStatuses(where: {AND: [{member: {have: {objectId: {equalTo: $loginUserId}}}}, {status: {equalTo: \"valid\"}}, {membership: {have: {author: {have: {objectId: {equalTo: $profileUserId}}}}}}]}) @skip(if: $isMe) {\n    count\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd83ccf2a8965264ae54d429255ea6428';
export default node;
