/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateInstructionCardFieldsInput = {
    ACL?: ACLInput | null;
    description?: string | null;
    author?: UserPointerInput | null;
    path?: unknown | null;
    sources?: Array<unknown | null> | null;
    seq?: number | null;
    optional?: boolean | null;
    pathDraft?: PathDraftPointerInput | null;
};
export type ACLInput = {
    users?: Array<UserACLInput> | null;
    roles?: Array<RoleACLInput> | null;
    public?: PublicACLInput | null;
};
export type UserACLInput = {
    userId: string;
    read: boolean;
    write: boolean;
};
export type RoleACLInput = {
    roleName: string;
    read: boolean;
    write: boolean;
};
export type PublicACLInput = {
    read: boolean;
    write: boolean;
};
export type UserPointerInput = {
    link?: string | null;
    createAndLink?: CreateUserFieldsInput | null;
};
export type CreateUserFieldsInput = {
    ACL?: ACLInput | null;
    username: string;
    password: string;
    email?: string | null;
    emailVerified?: boolean | null;
    authData?: unknown | null;
    introduce?: string | null;
    category?: string | null;
    name?: string | null;
    originProfileURL?: string | null;
    description?: string | null;
    tags?: Array<unknown | null> | null;
    jobTitle?: string | null;
    createdAtOfLastSeenNotification?: unknown | null;
    profileImage?: FileInput | null;
};
export type FileInput = {
    file?: unknown | null;
    upload?: unknown | null;
    unlink?: boolean | null;
};
export type PathDraftPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathDraftFieldsInput | null;
};
export type CreatePathDraftFieldsInput = {
    ACL?: ACLInput | null;
    original: PathPointerInput;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    description?: string | null;
    isSeries?: boolean | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    progressing?: boolean | null;
};
export type PathPointerInput = {
    link?: string | null;
    createAndLink?: CreatePathFieldsInput | null;
};
export type CreatePathFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    tags?: Array<unknown | null> | null;
    contentItemList?: Array<unknown | null> | null;
    author?: UserPointerInput | null;
    learnCount?: number | null;
    published?: boolean | null;
    description?: string | null;
    instructionCards?: InstructionCardRelationInput | null;
    coverImage?: FileInput | null;
    membership?: MembershipPointerInput | null;
    analytics?: AnalyticsPathPointerInput | null;
    estimate?: number | null;
    target?: string | null;
    benefits?: Array<unknown | null> | null;
    prerequisites?: Array<unknown | null> | null;
    isSeries?: boolean | null;
    draft?: PathDraftPointerInput | null;
};
export type InstructionCardRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateInstructionCardFieldsInput> | null;
};
export type CreateInstructionCardFieldsInput = {
    ACL?: ACLInput | null;
    description?: string | null;
    author?: UserPointerInput | null;
    path?: unknown | null;
    sources?: Array<unknown | null> | null;
    seq?: number | null;
    optional?: boolean | null;
    pathDraft?: PathDraftPointerInput | null;
};
export type MembershipPointerInput = {
    link?: string | null;
    createAndLink?: CreateMembershipFieldsInput | null;
};
export type CreateMembershipFieldsInput = {
    ACL?: ACLInput | null;
    title?: string | null;
    author?: UserPointerInput | null;
    level?: string | null;
    role?: RolePointerInput | null;
};
export type RolePointerInput = {
    link?: string | null;
    createAndLink?: CreateRoleFieldsInput | null;
};
export type CreateRoleFieldsInput = {
    ACL?: ACLInput | null;
    name?: string | null;
    users?: UserRelationInput | null;
    roles?: RoleRelationInput | null;
};
export type UserRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateUserFieldsInput> | null;
};
export type RoleRelationInput = {
    add?: Array<string> | null;
    remove?: Array<string> | null;
    createAndAdd?: Array<CreateRoleFieldsInput> | null;
};
export type AnalyticsPathPointerInput = {
    link?: string | null;
    createAndLink?: CreateAnalyticsPathFieldsInput | null;
};
export type CreateAnalyticsPathFieldsInput = {
    ACL?: ACLInput | null;
    emojiTotalCount?: number | null;
    emojiCountMap?: unknown | null;
    path?: PathPointerInput | null;
    viewCount?: number | null;
    commentCount?: number | null;
};
export type PathEditorScreenUpdateInstructionCardMutationVariables = {
    fields: UpdateInstructionCardFieldsInput;
    objectId: string;
};
export type PathEditorScreenUpdateInstructionCardMutationResponse = {
    readonly updateInstructionCard: {
        readonly instructionCard: {
            readonly seq: number | null;
            readonly description: string | null;
            readonly id: string;
            readonly objectId: string;
            readonly optional: boolean | null;
            readonly sources: ReadonlyArray<({
                readonly __typename: "ContentItem";
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly content: {
                    readonly id: string;
                    readonly title: string | null;
                    readonly objectId: string;
                    readonly thumbURL: string | null;
                    readonly type: string | null;
                    readonly " $fragmentRefs": FragmentRefs<"ContentPreviewFragment">;
                } | null;
                readonly author: {
                    readonly objectId: string;
                    readonly id: string;
                    readonly name: string | null;
                } | null;
            } | {
                readonly __typename: "Content";
                readonly id: string;
                readonly objectId: string;
                readonly thumbURL: string | null;
                readonly title: string | null;
                readonly link: string | null;
                readonly type: string | null;
                readonly " $fragmentRefs": FragmentRefs<"ContentPreviewFragment">;
            } | {
                readonly __typename: "Path";
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly description: string | null;
                readonly author: {
                    readonly objectId: string;
                    readonly name: string | null;
                } | null;
            } | {
                readonly __typename: "Quiz";
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly options: ReadonlyArray<{
                    readonly value?: unknown;
                } | null> | null;
                readonly correctIndexes: ReadonlyArray<{
                    readonly value?: unknown;
                } | null> | null;
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            }) | null> | null;
        };
    } | null;
};
export type PathEditorScreenUpdateInstructionCardMutation = {
    readonly response: PathEditorScreenUpdateInstructionCardMutationResponse;
    readonly variables: PathEditorScreenUpdateInstructionCardMutationVariables;
};



/*
mutation PathEditorScreenUpdateInstructionCardMutation(
  $fields: UpdateInstructionCardFieldsInput!
  $objectId: ID!
) {
  updateInstructionCard(input: {id: $objectId, fields: $fields}) {
    instructionCard {
      seq
      description
      id
      objectId
      optional
      sources {
        __typename
        ... on ContentItem {
          id
          objectId
          title
          content {
            id
            title
            objectId
            thumbURL
            type
            ...ContentPreviewFragment
          }
          author {
            objectId
            id
            name
          }
        }
        ... on Content {
          id
          objectId
          thumbURL
          title
          link
          type
          ...ContentPreviewFragment
        }
        ... on Path {
          id
          objectId
          title
          description
          author {
            objectId
            name
            id
          }
        }
        ... on Quiz {
          id
          objectId
          title
          options {
            __typename
            ... on Element {
              value
            }
            ... on Node {
              __isNode: __typename
              id
            }
          }
          correctIndexes {
            __typename
            ... on Element {
              value
            }
            ... on Node {
              __isNode: __typename
              id
            }
          }
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
    }
  }
}

fragment ContentPreviewFragment on Content {
  __typename
  id
  objectId
  type
  thumbURL
  link
  title
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fields"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objectId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "fields",
        "variableName": "fields"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "objectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seq",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "optional",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbURL",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "ContentPreviewFragment"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v4/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v15 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v16 = [
  (v15/*: any*/)
],
v17 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v18 = [
  (v7/*: any*/),
  (v15/*: any*/),
  (v17/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PathEditorScreenUpdateInstructionCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateInstructionCardPayload",
        "kind": "LinkedField",
        "name": "updateInstructionCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructionCard",
            "kind": "LinkedField",
            "name": "instructionCard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sources",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Content",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v8/*: any*/),
                          (v5/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "type": "ContentItem",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v9/*: any*/),
                      (v8/*: any*/),
                      (v14/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "type": "Content",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Path",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "options",
                        "plural": true,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "correctIndexes",
                        "plural": true,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "Quiz",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PathEditorScreenUpdateInstructionCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateInstructionCardPayload",
        "kind": "LinkedField",
        "name": "updateInstructionCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructionCard",
            "kind": "LinkedField",
            "name": "instructionCard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sources",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Content",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v8/*: any*/),
                          (v5/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v7/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "type": "ContentItem",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v9/*: any*/),
                      (v8/*: any*/),
                      (v14/*: any*/),
                      (v10/*: any*/)
                    ],
                    "type": "Content",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v12/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Path",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "options",
                        "plural": true,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "correctIndexes",
                        "plural": true,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "Quiz",
                    "abstractKey": null
                  },
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb004957a5f2b6006c1662f1fb27f47d",
    "id": null,
    "metadata": {},
    "name": "PathEditorScreenUpdateInstructionCardMutation",
    "operationKind": "mutation",
    "text": "mutation PathEditorScreenUpdateInstructionCardMutation(\n  $fields: UpdateInstructionCardFieldsInput!\n  $objectId: ID!\n) {\n  updateInstructionCard(input: {id: $objectId, fields: $fields}) {\n    instructionCard {\n      seq\n      description\n      id\n      objectId\n      optional\n      sources {\n        __typename\n        ... on ContentItem {\n          id\n          objectId\n          title\n          content {\n            id\n            title\n            objectId\n            thumbURL\n            type\n            ...ContentPreviewFragment\n          }\n          author {\n            objectId\n            id\n            name\n          }\n        }\n        ... on Content {\n          id\n          objectId\n          thumbURL\n          title\n          link\n          type\n          ...ContentPreviewFragment\n        }\n        ... on Path {\n          id\n          objectId\n          title\n          description\n          author {\n            objectId\n            name\n            id\n          }\n        }\n        ... on Quiz {\n          id\n          objectId\n          title\n          options {\n            __typename\n            ... on Element {\n              value\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n          correctIndexes {\n            __typename\n            ... on Element {\n              value\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ContentPreviewFragment on Content {\n  __typename\n  id\n  objectId\n  type\n  thumbURL\n  link\n  title\n}\n"
  }
};
})();
(node as any).hash = 'bf361a502a715d6db83237dfb0aae504';
export default node;
