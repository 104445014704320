import { StyleSheet } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import TopRoundFlex from '../components/TopRoundFlex';
import { ActivityIndicator, Flex } from '@ant-design/react-native';
import { HistoryBackButton } from '../components/NavBar2';
import LFText from '../components/typo/LFText';
import LFWhiteSpace from '../components/LFWhiteSpace';
import { colors } from '../constants/styleGuide';
import LFLink from '../components/LFLink';
import { graphql, useMutation } from 'react-relay';
import { InterestingSelectorScreenUserUpdateMutation } from '../__generated__/InterestingSelectorScreenUserUpdateMutation.graphql';
import { UpdateUserInput } from '../__generated__/ProfileEditorScreenUserUpdateMutation.graphql';
import { useAuth } from '../hooks/auth';
import { POSITIONS } from './PositionSelectorScreen';
import { USER_MAX_TAGS } from './ProfileEditorScreen';
import _ from 'lodash';

const tags = [
  '프론트엔드',
  '백엔드',
  '데이터베이스',
  '데브옵스',
  '모바일 개발',
  '서비스기획',
  '협업/조직관리',
  '마케팅',
  '트랜드',
  '업무생산성',
  '스타트업',
  'UI/UX',
  '그래픽디자인',
  '브랜드디자인',
  '영상/모션그래픽',
  '캐릭터디자인',
  '통계',
  '머신러닝',
  '데이터인프라',
  '데이터 분석',
  'SQL',
  '브랜드마케팅',
  '그로스해킹',
  '콘텐츠',
  '카피라이팅',
];

const MIN_ACTIVE_TAG_COUNT = 1;

const InterestingSelectorScreen = () => {
  const { user } = useAuth();
  const [commitLoginUserInfoUpdate, isLoginUserInfoUpdateInFlight] =
    useMutation<InterestingSelectorScreenUserUpdateMutation>(
      graphql`
        mutation InterestingSelectorScreenUserUpdateMutation(
          $input: UpdateUserInput!
        ) {
          updateUser(input: $input) {
            user {
              id
              objectId
              name
              jobTitle
              description
              originProfileURL
              tags {
                ... on Element {
                  value
                }
              }
              profileImage {
                url
                __typename
              }
            }
          }
        }
      `
    );

  const [isFinish, setIsFinish] = useState(false);

  const { positionId } = useParams<{ positionId: string }>();
  const history = useHistory();
  const location = useLocation();

  const goBackToLoginAccessScreen = () => {
    history.push(location.state?.from || { pathname: '/' });
  };

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const onTagPress = (tag: string) => {
    selectedTags.includes(tag)
      ? setSelectedTags((prev) => prev.filter((item) => item !== tag))
      : selectedTags.length < USER_MAX_TAGS &&
        setSelectedTags((prev) => [...prev, tag]);
  };
  useEffect(() => {
    selectedTags.length >= MIN_ACTIVE_TAG_COUNT
      ? setIsFinish(true)
      : setIsFinish(false);
  }, [selectedTags]);

  const onFinish = () => {
    const jobTitle =
      POSITIONS.find((position) => position.id === positionId)?.title || '';

    const tags = _.take(
      [
        ...new Set([
          ...(user?.tags
            ? user?.tags?.map((tag: { value: string }) => tag.value)
            : []),
          ...selectedTags,
          jobTitle,
        ]),
      ],
      USER_MAX_TAGS
    );

    const updateInput: UpdateUserInput = {
      id: user?.objectId,
      fields: {
        jobTitle,
        tags,
      },
    };
    commitLoginUserInfoUpdate({
      variables: {
        input: updateInput,
      },
      onCompleted: (response) => {
        console.log(response);
        goBackToLoginAccessScreen();
      },
      onError: (e) => {
        console.log(
          '유저 하는 일, 관심사 반영 실패(in InterestingSelecorScreen.tsx)',
          e
        );
      },
    });
  };
  return (
    <TopRoundFlex direction="column" align="center" style={styles.container}>
      <Flex justify="between" style={{ alignSelf: 'stretch' }}>
        <HistoryBackButton style={{ margin: -8 }} />
        <LFText onPress={goBackToLoginAccessScreen} style={styles.laterText}>
          다음에 할게요
        </LFText>
      </Flex>
      <LFWhiteSpace direction="column" size="xl" />
      <LFWhiteSpace direction="column" size="xs" />
      <LFText style={styles.descriptionOne}>
        👋 관심사에 맞게 학습콘텐츠를 추천해 드려요.
      </LFText>
      <LFWhiteSpace direction="column" size="xl" />
      <LFText style={styles.title}>관심사를 알려주세요!</LFText>
      <LFWhiteSpace size="sm" direction="column" />
      <LFText style={styles.descriptionTwo}>
        프로필 수정에서 언제든지 바꿀 수 있어요. (10개까지 선택 가능)
      </LFText>
      <LFWhiteSpace direction="column" size="xl" />
      <LFWhiteSpace direction="column" size="lg" />
      <Flex justify="center" wrap="wrap" style={styles.tags}>
        {tags.map((tag) => {
          const active = selectedTags.includes(tag);
          return (
            <LFLink
              key={tag}
              onPress={() => onTagPress(tag)}
              style={active ? styles.activeTag : styles.tag}
            >
              <LFText style={active ? styles.activeTagText : styles.tagText}>
                {`#${tag}`}
              </LFText>
            </LFLink>
          );
        })}
      </Flex>
      <LFWhiteSpace direction="column" size="xl" />
      <LFWhiteSpace direction="column" size="lg" />
      <LFLink
        onPress={onFinish}
        style={[
          isFinish
            ? styles.buttonContainerActive
            : styles.buttonContainerDisable,
          { marginTop: 'auto ' },
        ]}
      >
        {isLoginUserInfoUpdateInFlight ? (
          <ActivityIndicator color={'gray'} />
        ) : (
          <LFText
            style={
              isFinish ? styles.buttonTextActive : styles.buttonTextDisable
            }
          >
            맞춤형 학습, 런핏 시작하기
          </LFText>
        )}
      </LFLink>
    </TopRoundFlex>
  );
};

export default InterestingSelectorScreen;

const styles = StyleSheet.create({
  container: {
    paddingTop: 12,
    paddingHorizontal: 16,
    paddingBottom: 24,
    flex: 1,
  },
  laterText: {
    fontFamily: 'Pretendard',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.TEXT_60,
  },
  descriptionOne: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 40,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
  descriptionTwo: {
    opacity: 0.6,
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
  buttonContainerDisable: {
    backgroundColor: colors.BUTTON_DISABLE,
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingVertical: 16,
    borderRadius: 8,
  },
  buttonContainerActive: {
    backgroundColor: colors.PRIMARY_100,
    alignSelf: 'stretch',
    alignItems: 'center',
    paddingVertical: 16,
    borderRadius: 8,
  },
  buttonTextDisable: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.BORDER_40,
  },
  buttonTextActive: {
    ontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_WHITE,
  },
  tags: { margin: -4 },
  tag: {
    borderRadius: 100,
    backgroundColor: colors.BG_2,
    paddingVertical: 8,
    paddingHorizontal: 16,
    margin: 4,
  },
  tagText: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  activeTag: {
    borderRadius: 100,
    backgroundColor: colors.PRIMARY_10,
    paddingVertical: 8,
    paddingHorizontal: 16,
    margin: 4,
  },
  activeTagText: {
    fontSize: 14,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.PRIMARY_100,
  },
});
