import _ from 'lodash';
import React from 'react';
import { Pressable, PressableProps, ViewStyle, Text } from 'react-native';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';
import { Link, useHistory } from 'react-router-dom';
import * as H from 'history';

const isHeadlessChrome = navigator.userAgent.indexOf('HeadlessChrome') > 0;

export type LFLinkTo = string | H.LocationDescriptorObject<any> | undefined;
type LFLinkProps = PressableProps & {
  style?: ViewStyle;
  to?: LFLinkTo;
  replace?: boolean;
  dataSet?: { [key: string]: string };
};
const LFLink: React.FC<LFLinkProps> = ({
  children,
  to,
  onPress,
  style,
  replace,
  dataSet,
  ...rest
}) => {
  const history = useHistory();
  return (
    // TouchableOpacity에게 box-only를 적용해야합니다.
    // box only란? 클릭 되었을 때 자식은 무시되고 부모만 인식되도록 하는 것
    // 링크: https://reactnative.dev/docs/view#pointerevents
    // TODO: pointerEvents type 에러 해결
    <Pressable
      //@ts-ignore
      dataSet={dataSet}
      //@ts-ignore
      href={to?.pathname || to}
      pointerEvents={'box-only'}
      {...rest}
      accessibilityRole={isHeadlessChrome ? 'link' : 'button'}
      onPress={(e) => {
        // onPress를 우선시 합니다.
        if (_.isFunction(onPress)) {
          onPress(e);
        } else if (to) {
          replace ? history.replace(to) : history.push(to);
        }
      }}
      style={(state) => (state.pressed ? [style, { opacity: 0.5 }] : style)}
    >
      {children}
    </Pressable>
  );
};

export default LFLink;
