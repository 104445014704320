/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TagScreenQueryVariables = {
    tag?: string | null;
};
export type TagScreenQueryResponse = {
    readonly tagDescriptions: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly subtitle: string | null;
                readonly description: string | null;
                readonly titleColor: string | null;
                readonly subtitleColor: string | null;
                readonly bgColor: string | null;
                readonly coverImage: {
                    readonly url: string;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type TagScreenQuery = {
    readonly response: TagScreenQueryResponse;
    readonly variables: TagScreenQueryVariables;
};



/*
query TagScreenQuery(
  $tag: String
) {
  tagDescriptions(where: {tag: {equalTo: $tag}}) {
    edges {
      node {
        id
        objectId
        title
        subtitle
        description
        titleColor
        subtitleColor
        bgColor
        coverImage {
          url
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tag"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "tag"
              }
            ],
            "kind": "ObjectValue",
            "name": "tag"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "TagDescriptionConnection",
    "kind": "LinkedField",
    "name": "tagDescriptions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagDescriptionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TagDescription",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subtitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "titleColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subtitleColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bgColor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FileInfo",
                "kind": "LinkedField",
                "name": "coverImage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TagScreenQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TagScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "814e77f3405150c047b77d812060e006",
    "id": null,
    "metadata": {},
    "name": "TagScreenQuery",
    "operationKind": "query",
    "text": "query TagScreenQuery(\n  $tag: String\n) {\n  tagDescriptions(where: {tag: {equalTo: $tag}}) {\n    edges {\n      node {\n        id\n        objectId\n        title\n        subtitle\n        description\n        titleColor\n        subtitleColor\n        bgColor\n        coverImage {\n          url\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '01f6d2f2b5b26909e88a4a43eb4727fa';
export default node;
