/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PathEditorScreenInstructionCardDeleteMutationVariables = {
    id: string;
};
export type PathEditorScreenInstructionCardDeleteMutationResponse = {
    readonly deleteInstructionCard: {
        readonly instructionCard: {
            readonly id: string;
            readonly objectId: string;
            readonly sources: ReadonlyArray<({
                readonly __typename: "Quiz";
                readonly id: string;
                readonly objectId: string;
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            }) | null> | null;
        };
    } | null;
};
export type PathEditorScreenInstructionCardDeleteMutation = {
    readonly response: PathEditorScreenInstructionCardDeleteMutationResponse;
    readonly variables: PathEditorScreenInstructionCardDeleteMutationVariables;
};



/*
mutation PathEditorScreenInstructionCardDeleteMutation(
  $id: ID!
) {
  deleteInstructionCard(input: {id: $id}) {
    instructionCard {
      id
      objectId
      sources {
        __typename
        ... on Quiz {
          id
          objectId
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "type": "Quiz",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PathEditorScreenInstructionCardDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteInstructionCardPayload",
        "kind": "LinkedField",
        "name": "deleteInstructionCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructionCard",
            "kind": "LinkedField",
            "name": "instructionCard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sources",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PathEditorScreenInstructionCardDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteInstructionCardPayload",
        "kind": "LinkedField",
        "name": "deleteInstructionCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructionCard",
            "kind": "LinkedField",
            "name": "instructionCard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "sources",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d9629244baf6cd28363023d0257f973",
    "id": null,
    "metadata": {},
    "name": "PathEditorScreenInstructionCardDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PathEditorScreenInstructionCardDeleteMutation(\n  $id: ID!\n) {\n  deleteInstructionCard(input: {id: $id}) {\n    instructionCard {\n      id\n      objectId\n      sources {\n        __typename\n        ... on Quiz {\n          id\n          objectId\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '11a5c77d4c24d5702ed40be2e0ea4ee3';
export default node;
