import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import BasePathList from '../components/BasePathList';
import NavBar from '../components/NavBar2';
import { colors } from '../constants/styleGuide';
import { useHistory, useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { UserPathListScreenUserQuery } from '../__generated__/UserPathListScreenUserQuery.graphql';
import { useAuth } from '../hooks/auth';
import EmptyList from '../components/EmptyList';
import LFWhiteSpace from '../components/LFWhiteSpace';
import { AnalyticsPathWhereInput } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';

const UserPathListScreen = () => {
  const history = useHistory();
  const { userObjectId } = useParams<{ userObjectId: string }>();
  const { user } = useLazyLoadQuery<UserPathListScreenUserQuery>(
    graphql`
      query UserPathListScreenUserQuery($userObjectId: ID!) {
        user(id: $userObjectId) {
          id
          name
        }
      }
    `,
    { userObjectId },
    {
      fetchPolicy: 'store-and-network',
    }
  );
  const { user: loginUser } = useAuth();
  const isMe = loginUser?.objectId === userObjectId;

  const where: AnalyticsPathWhereInput = {
    path: {
      have: {
        author: { have: { objectId: { equalTo: userObjectId } } },
      },
    },
  };
  if (isMe === false) {
    where.path!.have!.published = { equalTo: true };
  }
  return (
    <View style={styles.container}>
      <NavBar
        title={isMe ? '내 러닝패스' : `${user.name}님의 러닝패스`}
        sticky
      />
      <BasePathList
        ListEmptyComponent={
          <EmptyList
            style={{
              // @ts-ignore
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              justifyContent: 'center',
              zIndex: -1,
            }}
          />
        }
        style={{ paddingHorizontal: 16, paddingTop: 16 }}
        where={where}
        order={['updatedAt_DESC']}
        onPressItem={(objectId) => {
          history.push(`/path/${objectId}`);
        }}
      />
    </View>
  );
};

export default UserPathListScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.BG_WHITE,
  },
});
