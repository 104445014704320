import { Image, StyleSheet, View, ViewStyle } from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import LFLink from './LFLink';
import _ from 'lodash';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Flex } from '@ant-design/react-native';
import { usePrevious } from 'ahooks';
import {
  AnalyticsContentItemOrder,
  AnalyticsContentItemWhereInput,
  BaseHorizontalContentItemListQuery,
} from '../__generated__/BaseHorizontalContentItemListQuery.graphql';
import ContentItemView3, { IContentItemView3 } from './ContentItemView3';
import { colors } from '../constants/styleGuide';
import { PaginationButton } from './PaginationButton';
import LFWhiteSpace from './LFWhiteSpace';
import { useDeviceType } from '../hooks/deviceType';
import { useAuth } from '../hooks/auth';
import {
  BaseHorizontalContentItemListMyBookMarkReactionsQuery,
  ReactionWhereInput,
} from '../__generated__/BaseHorizontalContentItemListMyBookMarkReactionsQuery.graphql';

interface IBaseHorizontalContentItemList {
  where: AnalyticsContentItemWhereInput; // 데이터 조건
  order: AnalyticsContentItemOrder[]; // 데이터 순서
  first: number; // 데이터 갯수
  onPressItem?: (contentId: string, contentItemId: string) => void; // 각 아이템 눌릴 때 action
  onPressItemTo?: (contentId: string, contentItemId: string) => string; // 각 아이템 눌릴 때 넘어갈 link(랜더트론용)
  onChangeCount?: (count: number) => void; // fetch 후 count를 외부로 전달
  onScrollStart?: () => void;
  onScrollEnd?: () => void;
  gap?: number; // 각 아이템의 간격
  style?: React.CSSProperties; // 리스트에 대한 style
  itemStyle?: ViewStyle; // 각 아이템에 대한 style
  itemProp?: Partial<IContentItemView3>;
  paginationButton?: boolean; // 페이지네이션 버튼 노출
}

const BaseHorizontalContentItemList: React.FC<IBaseHorizontalContentItemList> =
  ({
    where,
    order,
    first,
    onPressItem,
    onPressItemTo,
    onChangeCount,
    onScrollStart,
    onScrollEnd,
    gap = 0,
    style,
    itemStyle,
    itemProp,
    paginationButton = true,
  }) => {
    const { user } = useAuth();
    const { analyticsContentItems } =
      useLazyLoadQuery<BaseHorizontalContentItemListQuery>(
        graphql`
          query BaseHorizontalContentItemListQuery(
            $where: AnalyticsContentItemWhereInput
            $order: [AnalyticsContentItemOrder!]
            $first: Int
          ) {
            analyticsContentItems(where: $where, order: $order, first: $first) {
              count
              edges {
                node {
                  id
                  objectId
                  contentItem {
                    id
                    objectId
                    content {
                      id
                      objectId
                    }
                    ...ContentItemView3_Fragment
                  }
                }
              }
            }
          }
        `,
        { where, first, order }
      );

    useEffect(() => {
      _.isFunction(onChangeCount) && onChangeCount(analyticsContentItems.count);
    }, [analyticsContentItems.count]);

    const contentItemNodes =
      analyticsContentItems.edges?.map((edge) => edge?.node?.contentItem) || [];
    const gapAfterContainer = style?.paddingRight || style?.padding;
    const scrollContainerRef = useRef<HTMLElement | null>(null);
    const [isSliding, setIsSliding] = useState(false);
    const previousIsSliding = usePrevious(isSliding);
    const deviceType = useDeviceType();
    // BaseHorizontalContentItemList 컴포넌트가 리렌더링 될 때마다 가로 스크롤 위치 초기화
    // useEffect(() => {
    //   // 예외: isSliding 값 수정으로인한 리렌더링 시엔 가로스크롤 위치 초기화x
    //   if (isSliding !== previousIsSliding) return;
    //   setTimeout(() => {
    //     if (scrollContainerRef.current) {
    //       scrollContainerRef.current.scrollTo({ left: 0 });
    //     }
    //   }, 100);
    // });

    const [queryArgs, setQueryArgs] = useState({
      options: { fetchKey: 0 },
    });

    const refetch = useCallback(() => {
      setQueryArgs((prev) => ({
        options: {
          fetchKey: (prev?.options.fetchKey ?? 0) + 1,
        },
      }));
    }, []);

    useEffect(() => {
      refetch();
    }, []);

    const myBookMarkReactionsWhere: ReactionWhereInput = {
      author: {
        have: {
          objectId: {
            equalTo: user?.objectId || '',
          },
        },
      },
      type: {
        equalTo: 'bookmark',
      },
      targetClassName: {
        equalTo: 'ContentItem',
      },
    };
    const { myBookMarkReactions } =
      useLazyLoadQuery<BaseHorizontalContentItemListMyBookMarkReactionsQuery>(
        graphql`
          query BaseHorizontalContentItemListMyBookMarkReactionsQuery(
            $where: ReactionWhereInput!
            $skip: Boolean!
          ) {
            myBookMarkReactions: reactions(where: $where, first: 1000)
              @skip(if: $skip) {
              edges {
                node {
                  id
                  objectId
                  contentItem {
                    id
                    objectId
                  }
                }
              }
            }
          }
        `,
        { where: myBookMarkReactionsWhere, skip: !Boolean(user?.objectId) },
        {
          fetchPolicy: 'store-and-network',
          fetchKey: queryArgs.options.fetchKey,
        }
      );

    return (
      <>
        {paginationButton && deviceType === 'PC' && (
          <View>
            <PaginationButton
              scrollRef={scrollContainerRef}
              numberOfNote={contentItemNodes.length}
              isNote
            />
            <LFWhiteSpace size={'md'} />
          </View>
        )}
        <ScrollContainer
          innerRef={scrollContainerRef}
          onStartScroll={() => {
            setIsSliding(true);
            _.isFunction(onScrollStart) && onScrollStart();
          }}
          onEndScroll={() => {
            setTimeout(() => {
              setIsSliding(false);
              _.isFunction(onScrollEnd) && onScrollEnd();
            }, 0);
          }}
          style={style}
        >
          <Flex align="stretch">
            {contentItemNodes.map((contentItemNode, index) => {
              const bookMarkReaction = _.find(
                myBookMarkReactions?.edges,
                (edge) =>
                  edge?.node?.contentItem?.objectId ===
                  contentItemNode?.objectId
              );
              const scraped = {
                status: Boolean(bookMarkReaction),
                reactionId: bookMarkReaction?.node?.objectId,
              };
              return (
                <LFLink
                  key={contentItemNode?.objectId}
                  style={[
                    itemStyle,
                    {
                      marginRight:
                        contentItemNodes.length === index + 1 ? 0 : gap,
                    },
                  ]}
                  onPress={
                    _.isFunction(onPressItem) && contentItemNode?.objectId
                      ? () => {
                          !isSliding &&
                            onPressItem(
                              contentItemNode?.content?.objectId || '',
                              contentItemNode?.objectId
                            );
                        }
                      : undefined
                  }
                  to={
                    // isSlidingRef에서 isSliding(state)으로 변경한 이유:
                    // LFLink의 to prop의 경우 함수가 아니라 string type
                    // 초기 값이 false인 isSlidingRef를 토대로 to를 설정하면 무조건 ''가 전달됨(isSlidingRef가 수정되어도)
                    _.isFunction(onPressItemTo) &&
                    contentItemNode?.objectId &&
                    !isSliding
                      ? onPressItemTo(
                          contentItemNode?.content?.objectId || '',
                          contentItemNode?.objectId
                        )
                      : ''
                  }
                >
                  <ContentItemView3
                    contentItemFragment={contentItemNode}
                    disableMode={isSliding}
                    scraped={scraped}
                    onScrape={refetch}
                    {...itemProp}
                  />
                </LFLink>
              );
            })}
            {/* 
          스크롤 가능한 UI 컨테이너에서 끝쪽 margin, padding은 스크롤 컨테이너 내부에서 적용되지 않는 점을 알게 되어
          별도로 element를 두어 paddingRight, marginRight의 역할을 대신하도록 조치했습니다.
          링크: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout 
        */}
            {gapAfterContainer && (
              <Flex style={{ width: Number(gapAfterContainer), height: 1 }} />
            )}
          </Flex>
        </ScrollContainer>
      </>
    );
  };

export default BaseHorizontalContentItemList;

const styles = StyleSheet.create({
  paginationLeftButton: {
    width: 36,
    height: 32,
    opacity: 0.8,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },

  paginationRightButton: {
    width: 36,
    height: 32,
    opacity: 0.8,
    backgroundColor: colors.SECONDARY_BLACK,
    shadowColor: '#42495926.4000198',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 1,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
