import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Ionicons } from '@expo/vector-icons';
import { Button, Flex, WingBlank } from '@ant-design/react-native';
import { EmojiStampItem } from './EmojiReactionListScreen';
import LFText from '../components/typo/LFText';
interface EmojiStampSelectorScreenProps {
  onPressClose: () => void;
  onPressSave: (data: number) => void;
  defaultValue: number;
  emojiTypes: EmojiStampItem[];
}

const EmojiStampSelectorScreen: React.FC<EmojiStampSelectorScreenProps> = ({
  onPressClose,
  onPressSave,
  defaultValue,
  emojiTypes,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(defaultValue);
  return (
    <View style={styles.container}>
      <WingBlank size="lg">
        <Flex justify="between" style={styles.headerContainer}>
          <LFText style={styles.headerText}>피드백 선택</LFText>
          <TouchableOpacity
            onPress={onPressClose}
            style={styles.headerCloseButton}
          >
            <Ionicons size={24} name="close" color="black" />
          </TouchableOpacity>
        </Flex>
        <View style={styles.listContainer}>
          {emojiTypes.map((e, index) => {
            const isSelected = selectedIndex === index;
            return (
              <TouchableOpacity
                key={e.type}
                style={styles.listItem}
                onPress={() => {
                  onPressSave(index);
                }}
              >
                <View
                  style={[
                    styles.radioOuterCircle,
                    isSelected ? { borderColor: '#FFDB6C' } : null,
                  ]}
                >
                  {isSelected && <View style={styles.radioInnerCircle} />}
                </View>
                <LFText style={styles.radioLabel}>{e.display}</LFText>
              </TouchableOpacity>
            );
          })}
        </View>
      </WingBlank>
      {/* <Button
        style={styles.confirmButton}
        onPress={() => {
          onPressSave(selectedIndex);
        }}
      >
        완료
      </Button> */}
    </View>
  );
};

export default EmojiStampSelectorScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    borderBottomWidth: 1,
    paddingHorizontal: 8,
    borderBottomColor: 'rgb(229, 229, 229)',
  },
  headerText: { fontSize: 20, fontWeight: 'bold' },
  headerCloseButton: { padding: 16, marginRight: -16 },
  listContainer: {
    marginVertical: -8,
    paddingHorizontal: 8,
    paddingVertical: 16,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 8,
  },
  radioOuterCircle: {
    width: 24,
    height: 24,
    borderRadius: 1000,
    borderWidth: 2,
    borderColor: '#D6D6D6',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  radioInnerCircle: {
    backgroundColor: '#FFDB6C',
    width: '100%',
    height: '100%',
    borderRadius: 1000,
  },
  radioLabel: { marginLeft: 10, fontSize: 16, fontWeight: 'normal' },
  confirmButton: {
    backgroundColor: 'rgb(255,219,111)',
    borderWidth: 0,
    borderRadius: 0,
  },
});
