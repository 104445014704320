import { Flex } from '@ant-design/react-native';
import _ from 'lodash';
import React from 'react';
import {
  GestureResponderEvent,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  ActivityIndicator,
  Image,
} from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from '../constants/styleGuide';
import { useAuth } from '../hooks/auth';
import { LoginScreenAccessAction } from '../screens/LoginScreen';
import LFLink, { LFLinkTo } from './LFLink';
import LFText from './typo/LFText';

export interface LFButtonProps {
  style?: ViewStyle;
  titleTextStyle?: TextStyle;
  onPress?: (event: GestureResponderEvent) => void;
  testID?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onlyLoginUser?: boolean;
  to?: LFLinkTo;
  type?: 'check';
  replace?: boolean;
}
const LFButton: React.FC<LFButtonProps> = ({
  children,
  titleTextStyle = {},
  onPress,
  testID,
  style,
  disabled,
  isLoading,
  onlyLoginUser,
  type,
  to,
  replace,
  ...rest
}) => {
  const childrenView =
    typeof children === 'string' ? (
      <LFText
        style={[
          styles.titleText,
          disabled ? null : styles.primaryTitleText,
          titleTextStyle,
        ]}
      >
        {children}
      </LFText>
    ) : (
      children
    );
  const { user } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();
  return (
    <LFLink
      // TODO: pointerEvents type 에러 해결
      // @ts-ignore
      pointerEvents="box-only"
      to={to}
      onPress={(e) => {
        if (onlyLoginUser && !user) {
          history.push('/login', {
            from: pathname,
            accessAction:
              'clickCompleteButtonOnNote' as LoginScreenAccessAction,
          });
        } else {
          if (_.isFunction(onPress)) {
            onPress(e);
          } else if (to) {
            replace ? history.replace(to) : history.push(to);
          }
        }
      }}
      testID={testID}
      disabled={disabled || isLoading}
      style={[
        styles.wrap,
        disabled ? styles.disabledWrap : styles.primaryWrap,
        style,
      ]}
      {...rest}
    >
      {isLoading ? (
        <ActivityIndicator color={'gray'} />
      ) : (
        <Flex direction="row">
          {type === 'check' ? (
            <Image
              style={{
                width: 24,
                height: 24,
                marginRight: 4,
              }}
              source={
                disabled
                  ? require('../assets/images/icons/icon24CheckGray@3x.png')
                  : require('../assets/images/icons/icon24Check@3x.png')
              }
            />
          ) : null}
          {childrenView}
        </Flex>
      )}
      {/* <LFText> {disabled ? '비활성' : '활성'}</LFText> */}
    </LFLink>
  );
};

export default LFButton;

const styles = StyleSheet.create({
  wrap: {
    paddingVertical: 10,
    // borderWidth: 1,
    // borderColor: '#ccc',
    // backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    minHeight: 56,
  },
  disabledWrap: {
    backgroundColor: colors.BUTTON_DISABLE,
  },
  primaryWrap: {
    backgroundColor: colors.PRIMARY_100,
  },
  titleText: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.BORDER_40,
  },
  primaryTitleText: {
    color: colors.TEXT_WHITE,
  },
});
