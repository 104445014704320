import { WithTheme } from '@ant-design/react-native/lib/style';
import _ from 'lodash';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
export interface WhiteSpaceProps {
  style?: StyleProp<ViewStyle>;
  /** LF 기본 사이즈 xs 4, sm 8, md 12, lg 16, xl 24, xxl 32, xxxl 40 */
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | number;
  direction?: 'row' | 'column';
}
class LFWhiteSpace extends React.Component<WhiteSpaceProps, any> {
  static defaultProps = {
    size: 'md',
    direction: 'column',
  };

  render() {
    const { size, style, direction } = this.props;
    return (
      <WithTheme>
        {(x, theme) => {
          const spaceSize = _.isNumber(size)
            ? size
            : theme[`v_spacing_${size}`];
          return (
            <View
              style={[
                {
                  [direction === 'row' ? 'width' : 'height']: spaceSize,
                },
                style,
              ]}
            />
          );
        }}
      </WithTheme>
    );
  }
}

export default LFWhiteSpace;
