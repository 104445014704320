import { StyleSheet, Image, TextInput, View } from 'react-native';
import React, { useRef, useState } from 'react';
import { Flex } from '@ant-design/react-native';
import { TextInputProps } from '@ant-design/react-native/lib/input-item';
import { colors } from '../constants/styleGuide';
import LFLink from './LFLink';
import Hr from './Hr';
import LFWhiteSpace from './LFWhiteSpace';
import LFText from './typo/LFText';

interface LFTextInput extends TextInputProps {
  clearButtonSize?: number;
  errorMessage?: string | null;
  valid?: (valid: boolean) => void;
}
const LFTextInput: React.FC<LFTextInput> = ({
  style,
  placeholderTextColor,
  clearButtonSize = 24,
  errorMessage,
  onChangeText,
  ...restProps
}) => {
  const inputRef = useRef<TextInput>(null);
  const [focused, setFocused] = useState(false);
  const [currentValue, setCurrentValue] = useState('');
  const internalOnchangeText = (text: string) => {
    onChangeText && onChangeText(text);
    setCurrentValue(text);
  };
  return (
    <Flex direction="column" align="stretch">
      <Flex direction="row" align="center">
        <TextInput
          ref={inputRef}
          {...restProps}
          style={[styles.input, style]}
          placeholderTextColor={placeholderTextColor || colors.TEXT_30}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChangeText={internalOnchangeText}
        />

        <LFLink
          onPress={() => {
            inputRef.current?.clear();
            internalOnchangeText('');
          }}
        >
          <Image
            style={{
              height: clearButtonSize,
              width: clearButtonSize,
            }}
            source={require('../assets/images/closeIconBlack@3x.png')}
          />
        </LFLink>
      </Flex>
      <LFWhiteSpace size="md" />
      <Hr
        color={
          errorMessage
            ? colors.RED
            : focused
            ? colors.PRIMARY_100
            : currentValue
            ? colors.SECONDARY_BLACK
            : colors.BORDER_40
        }
        lineWidth={3}
      />
      {errorMessage ? (
        <LFText style={styles.errorMessage}>{errorMessage}</LFText>
      ) : null}
    </Flex>
  );
};

export default LFTextInput;

const styles = StyleSheet.create({
  errorMessage: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.RED,
    marginTop: 4,
  },
  input: {
    flex: 1,
    height: 33,
    marginRight: 10,
    paddingVertical: 12,
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
});
