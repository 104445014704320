/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import NotificationPaginationQuery from "./NotificationPaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type NotificationScreenPaginationQuery = {
    readonly notifications: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly objectId: string;
                readonly id: string;
                readonly createdAt: unknown;
                readonly from: {
                    readonly name: string | null;
                    readonly objectId: string;
                } | null;
                readonly sources: ReadonlyArray<({
                    readonly __typename: "Reaction";
                    readonly id: string;
                    readonly objectId: string;
                    readonly targetClassName: string | null;
                    readonly createdAt: unknown;
                    readonly type: string | null;
                    readonly path: {
                        readonly objectId: string;
                        readonly title: string | null;
                    } | null;
                    readonly contentItem: {
                        readonly title: string | null;
                        readonly objectId: string;
                        readonly content: {
                            readonly title: string | null;
                            readonly objectId: string;
                            readonly thumbURL: string | null;
                        } | null;
                    } | null;
                    readonly author: {
                        readonly objectId: string;
                        readonly id: string;
                        readonly name: string | null;
                    } | null;
                } | {
                    readonly __typename: "Comment";
                    readonly id: string;
                    readonly objectId: string;
                    readonly targetClassName: string | null;
                    readonly createdAt: unknown;
                    readonly text: string | null;
                    readonly path: {
                        readonly id: string;
                        readonly objectId: string;
                        readonly title: string | null;
                    } | null;
                    readonly contentItem: {
                        readonly id: string;
                        readonly objectId: string;
                        readonly title: string | null;
                        readonly content: {
                            readonly id: string;
                            readonly objectId: string;
                            readonly title: string | null;
                            readonly thumbURL: string | null;
                        } | null;
                    } | null;
                    readonly author: {
                        readonly objectId: string;
                        readonly id: string;
                        readonly name: string | null;
                    } | null;
                    readonly parent: {
                        readonly id: string;
                        readonly objectId: string;
                    } | null;
                } | {
                    /*This will never be '%other', but we need some
                    value in case none of the concrete values match.*/
                    readonly __typename: "%other";
                }) | null> | null;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "NotificationScreenPaginationQuery";
};
export type NotificationScreenPaginationQuery$data = NotificationScreenPaginationQuery;
export type NotificationScreenPaginationQuery$key = {
    readonly " $data"?: NotificationScreenPaginationQuery$data;
    readonly " $fragmentRefs": FragmentRefs<"NotificationScreenPaginationQuery">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "notifications"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetClassName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbURL",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": NotificationPaginationQuery
    }
  },
  "name": "NotificationScreenPaginationQuery",
  "selections": [
    {
      "alias": "notifications",
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": "createdAt_DESC"
        }
      ],
      "concreteType": "NotificationConnection",
      "kind": "LinkedField",
      "name": "__pagination_notifications_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Notification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "from",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "sources",
                  "plural": true,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v2/*: any*/),
                        (v1/*: any*/),
                        (v6/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Path",
                          "kind": "LinkedField",
                          "name": "path",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContentItem",
                          "kind": "LinkedField",
                          "name": "contentItem",
                          "plural": false,
                          "selections": [
                            (v7/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Content",
                              "kind": "LinkedField",
                              "name": "content",
                              "plural": false,
                              "selections": [
                                (v7/*: any*/),
                                (v1/*: any*/),
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v9/*: any*/)
                      ],
                      "type": "Reaction",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v2/*: any*/),
                        (v1/*: any*/),
                        (v6/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "text",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Path",
                          "kind": "LinkedField",
                          "name": "path",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v1/*: any*/),
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContentItem",
                          "kind": "LinkedField",
                          "name": "contentItem",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v1/*: any*/),
                            (v7/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Content",
                              "kind": "LinkedField",
                              "name": "content",
                              "plural": false,
                              "selections": [
                                (v2/*: any*/),
                                (v1/*: any*/),
                                (v7/*: any*/),
                                (v8/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Comment",
                          "kind": "LinkedField",
                          "name": "parent",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "Comment",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__pagination_notifications_connection(order:\"createdAt_DESC\")"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = 'dad7a8a4bc8d8e5a12b57c70a833d04f';
export default node;
