import React, { useCallback, useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import LFLink from './LFLink';
import { Ionicons } from '@expo/vector-icons';
import { WingBlank } from '@ant-design/react-native';
import {
  ConnectionHandler,
  graphql,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay';
import {
  CreateReactionFieldsInput,
  ReactionButtonCreateReactionMutation,
} from '../__generated__/ReactionButtonCreateReactionMutation.graphql';
import { ReactionButtonQuery } from '../__generated__/ReactionButtonQuery.graphql';
import { useAuth } from '../hooks/auth';
import _, { create } from 'lodash';
import { ReactionButtonDeleteReactionMutation } from '../__generated__/ReactionButtonDeleteReactionMutation.graphql';
import { SelectorStoreUpdater } from 'relay-runtime';
import { useHistory, useLocation } from 'react-router-dom';
import LFText from './typo/LFText';
import { LoginScreenAccessAction } from '../screens/LoginScreen';

interface ReactionButtonProps {
  dataSet?: { [key: string]: string };
  style?: ViewStyle;
  targetClassName: 'Path' | 'ContentItem';
  targetId: string;
  type: '👍' | '🌱' | '🤷‍♂️' | '👀' | '😉';
  display?: string;
}

export const reactionButtonQuery = graphql`
  query ReactionButtonQuery(
    $toWhere: ReactionWhereInput!
    $myWhere: ReactionWhereInput!
  ) {
    countResult: reactions(where: $toWhere) {
      count
    }
    myReactionResult: reactions(where: $myWhere) {
      count
      edges {
        node {
          id
          objectId
          path {
            id
            objectId
          }
          contentItem {
            id
            objectId
          }
          author {
            id
            objectId
          }
          createdAt
        }
      }
    }
  }
`;

const ReactionButton: React.FC<ReactionButtonProps> = ({
  dataSet,
  style,
  targetClassName,
  targetId,
  type,
  display,
}) => {
  const isClicked = false;
  const { user } = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2]
  >({
    // 함수의 params를 typing : https://www.typescriptlang.org/docs/handbook/utility-types.html#parameterstype
    fetchPolicy: 'network-only',
    fetchKey: 0,
  });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      //@ts-ignore
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'store-and-network',
    }));
  }, [setRefreshedQueryOptions]);

  const {
    countResult: { count },
    myReactionResult,
  } = useLazyLoadQuery<ReactionButtonQuery>(
    reactionButtonQuery,
    {
      toWhere: {
        targetClassName: {
          equalTo: targetClassName,
        },
        [_.lowerFirst(targetClassName)]: {
          have: {
            objectId: {
              equalTo: targetId,
            },
          },
        },
        type: {
          equalTo: type,
        },
      },
      myWhere: {
        //@ts-ignore
        targetClassName: {
          equalTo: targetClassName,
        },
        [_.lowerFirst(targetClassName)]: {
          have: {
            objectId: {
              equalTo: targetId,
            },
          },
        },
        author: {
          have: {
            objectId: {
              equalTo: user?.objectId,
            },
          },
        },
        type: {
          equalTo: type,
        },
      },
    },
    refreshedQueryOptions
  );
  const myReaction = user
    ? _.get(_.first(myReactionResult?.edges), 'node')
    : null;

  //relay mutation
  const [commitCreateReaction, isCommitCreateReactionInFlight] =
    useMutation<ReactionButtonCreateReactionMutation>(graphql`
      mutation ReactionButtonCreateReactionMutation(
        # $connections: [String!]!
        $fields: CreateReactionFieldsInput!
      ) {
        createReaction(input: { fields: $fields }) {
          clientMutationId
          reaction {
            id
            objectId
            path {
              id
              objectId
            }
            contentItem {
              id
              objectId
            }
          }
        }
      }
    `);
  const [commitDeleteReaction, isCommitDeleteReactionInFlight] =
    useMutation<ReactionButtonDeleteReactionMutation>(
      graphql`
        mutation ReactionButtonDeleteReactionMutation($id: ID!) {
          deleteReaction(input: { id: $id }) {
            reaction {
              id
              objectId
            }
          }
        }
      `
    );

  const deleteUpdater: SelectorStoreUpdater = (store) => {
    const connectionRecord = store.get(
      'client:root:reactions'
      // 'client:root:reactions(where:{"author":{"have":{}},"path":{"have":{"objectId":{"equalTo":"TFczFZGxr7"}}},"targetClassName":{"equalTo":"Path"}})'
    );
    console.log('### CONN RECORD', connectionRecord);
    console.log(connectionRecord?.getValue('count'));
    connectionRecord?.setValue(
      _.max(connectionRecord?.getValue('count') - 1, 0),
      'count'
    );
  };
  const createUpdater: SelectorStoreUpdater = (store) => {
    const connectionRecord = store.get(
      'client:root:reactions'
      // 'client:root:reactions(where:{"author":{"have":{}},"path":{"have":{"objectId":{"equalTo":"TFczFZGxr7"}}},"targetClassName":{"equalTo":"Path"}})'
    );
    console.log('### CONN RECORD', connectionRecord);
    console.log(connectionRecord?.getValue('count'));
    connectionRecord?.setValue(
      connectionRecord?.getValue('count') + 1,
      'count'
    );

    // const id = `client:new_comment`;
    // const newReactionRecord = store.create(id, 'Reaction');

    // // Create new edge
    // const newEdge = ConnectionHandler.createEdge(
    //   store,
    //   connectionRecord,
    //   newReactionRecord,
    //   'ReactionEdge' /* GraphQl Type for edge */
    // );
    // ConnectionHandler.insertEdgeAfter(
    //   connectionRecord,
    //   newEdge,
    // );
  };

  const onPress = () => {
    if (!user) {
      history.push('/login', {
        from: pathname,
        accessAction: 'clickReactionButton' as LoginScreenAccessAction,
      });
    }
    if (myReaction) {
      commitDeleteReaction({
        variables: {
          id: myReaction.id,
        },
        onCompleted: () => {
          refresh();
        },
        // updater: deleteUpdater,
        // optimisticUpdater: deleteUpdater,
      });
    } else {
      const fields: CreateReactionFieldsInput | null = {
        type,
        [_.lowerFirst(targetClassName)]: {
          link: targetId,
        },
        targetClassName: targetClassName,
      };

      // const optimisticResponse:ReactionButtonCreateReactionMutationResponse =
      if (fields) {
        commitCreateReaction({
          variables: {
            fields: fields,
            // connections: [
            //   'client:root:reactions(where:{"author":{"have":{}},"path":{"have":{"objectId":{"equalTo":"TFczFZGxr7"}}},"targetClassName":{"equalTo":"Path"}}):edges:0',
            // ],
          },
          onCompleted: () => {
            refresh();
          },
          // optimisticUpdater: createUpdater,
          // updater: createUpdater,
        });
      }
    }
  };

  return targetClassName === 'Path' ? (
    <LFLink
      testID={`learningpath-emoji:ReactionButton#type=${type}`}
      style={[styles.reactionItem, style]}
      onPress={onPress}
      disabled={
        isCommitCreateReactionInFlight || isCommitDeleteReactionInFlight
      }
    >
      <LFText style={styles.reactionEmoji}>{type}</LFText>
      <LFText style={styles.reactionLabel}>{display}</LFText>
      <LFText
        style={[styles.reactionCount, myReaction ? { color: '#236EFF' } : null]}
      >
        {count}
      </LFText>
    </LFLink>
  ) : (
    <LFLink
      dataSet={dataSet}
      testID="it-helps-btn:ReactionButton"
      style={[{ flexDirection: 'row', alignItems: 'center' }, style]}
      onPress={onPress}
      disabled={
        isCommitCreateReactionInFlight || isCommitDeleteReactionInFlight
      }
    >
      {/* {isCommitCreateReactionInFlight ? <LFText>저장중</LFText> : null} */}
      <Ionicons
        size={24}
        name={myReaction ? 'heart-sharp' : 'heart-outline'}
        color={myReaction ? '#FA544A' : '#26282C'}
      />
      <WingBlank size="sm" />
      <LFText style={styles.btnText}>도움돼요</LFText>
      <WingBlank size="sm" />
      <LFText>{count}</LFText>
    </LFLink>
  );
};

const styles = StyleSheet.create({
  btnText: {
    fontWeight: 'bold',
  },
  reactionItem: { alignItems: 'center', flex: 1 },
  reactionEmoji: { fontSize: 32 },
  reactionLabel: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 18,

    textAlign: 'center',
    color: 'rgba(26, 27, 30, 0.6)',
  },
  reactionCount: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 24,
    textAlign: 'center',
  },
});

export default ReactionButton;
