/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ContentItemOrder = "ACL_ASC" | "ACL_DESC" | "analytics_ASC" | "analytics_DESC" | "author_ASC" | "author_DESC" | "content_ASC" | "content_DESC" | "createdAt_ASC" | "createdAt_DESC" | "description_ASC" | "description_DESC" | "id_ASC" | "id_DESC" | "md_ASC" | "md_DESC" | "noteData_ASC" | "noteData_DESC" | "objectId_ASC" | "objectId_DESC" | "published_ASC" | "published_DESC" | "tags_ASC" | "tags_DESC" | "title_ASC" | "title_DESC" | "updatedAt_ASC" | "updatedAt_DESC" | "%future added value";
export type ContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    description?: StringWhereInput | null;
    author?: UserRelationWhereInput | null;
    content?: ContentRelationWhereInput | null;
    noteData?: ArrayWhereInput | null;
    tags?: ArrayWhereInput | null;
    published?: BooleanWhereInput | null;
    md?: StringWhereInput | null;
    analytics?: AnalyticsContentItemRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentItemWhereInput> | null;
    AND?: Array<ContentItemWhereInput> | null;
    NOR?: Array<ContentItemWhereInput> | null;
};
export type IdWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type SelectInput = {
    query: SubqueryInput;
    key: string;
};
export type SubqueryInput = {
    className: string;
    where: unknown;
};
export type DateWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ObjectWhereInput = {
    equalTo?: KeyValueInput | null;
    notEqualTo?: KeyValueInput | null;
    in?: Array<KeyValueInput | null> | null;
    notIn?: Array<KeyValueInput | null> | null;
    lessThan?: KeyValueInput | null;
    lessThanOrEqualTo?: KeyValueInput | null;
    greaterThan?: KeyValueInput | null;
    greaterThanOrEqualTo?: KeyValueInput | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type KeyValueInput = {
    key: string;
    value: unknown;
};
export type StringWhereInput = {
    equalTo?: string | null;
    notEqualTo?: string | null;
    lessThan?: string | null;
    lessThanOrEqualTo?: string | null;
    greaterThan?: string | null;
    greaterThanOrEqualTo?: string | null;
    in?: Array<string | null> | null;
    notIn?: Array<string | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    text?: TextInput | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type TextInput = {
    search: SearchInput;
};
export type SearchInput = {
    term: string;
    language?: string | null;
    caseSensitive?: boolean | null;
    diacriticSensitive?: boolean | null;
};
export type UserRelationWhereInput = {
    have?: UserWhereInput | null;
    haveNot?: UserWhereInput | null;
    exists?: boolean | null;
};
export type UserWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    username?: StringWhereInput | null;
    password?: StringWhereInput | null;
    email?: StringWhereInput | null;
    emailVerified?: BooleanWhereInput | null;
    authData?: ObjectWhereInput | null;
    introduce?: StringWhereInput | null;
    category?: StringWhereInput | null;
    name?: StringWhereInput | null;
    originProfileURL?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    jobTitle?: StringWhereInput | null;
    createdAtOfLastSeenNotification?: DateWhereInput | null;
    profileImage?: FileWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<UserWhereInput> | null;
    AND?: Array<UserWhereInput> | null;
    NOR?: Array<UserWhereInput> | null;
};
export type BooleanWhereInput = {
    equalTo?: boolean | null;
    notEqualTo?: boolean | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ArrayWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    containedBy?: Array<unknown | null> | null;
    contains?: Array<unknown | null> | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type FileWhereInput = {
    equalTo?: unknown | null;
    notEqualTo?: unknown | null;
    lessThan?: unknown | null;
    lessThanOrEqualTo?: unknown | null;
    greaterThan?: unknown | null;
    greaterThanOrEqualTo?: unknown | null;
    in?: Array<unknown | null> | null;
    notIn?: Array<unknown | null> | null;
    exists?: boolean | null;
    matchesRegex?: string | null;
    options?: string | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type ContentRelationWhereInput = {
    have?: ContentWhereInput | null;
    haveNot?: ContentWhereInput | null;
    exists?: boolean | null;
};
export type ContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    title?: StringWhereInput | null;
    thumbURL?: StringWhereInput | null;
    type?: StringWhereInput | null;
    link?: StringWhereInput | null;
    description?: StringWhereInput | null;
    tags?: ArrayWhereInput | null;
    rawMetadata?: ObjectWhereInput | null;
    analytics?: AnalyticsContentRelationWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<ContentWhereInput> | null;
    AND?: Array<ContentWhereInput> | null;
    NOR?: Array<ContentWhereInput> | null;
};
export type AnalyticsContentRelationWhereInput = {
    have?: AnalyticsContentWhereInput | null;
    haveNot?: AnalyticsContentWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsContentWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    content?: ContentRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    contentItemCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    contentItemAuthors?: ArrayWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentWhereInput> | null;
    AND?: Array<AnalyticsContentWhereInput> | null;
    NOR?: Array<AnalyticsContentWhereInput> | null;
};
export type NumberWhereInput = {
    equalTo?: number | null;
    notEqualTo?: number | null;
    lessThan?: number | null;
    lessThanOrEqualTo?: number | null;
    greaterThan?: number | null;
    greaterThanOrEqualTo?: number | null;
    in?: Array<number | null> | null;
    notIn?: Array<number | null> | null;
    exists?: boolean | null;
    inQueryKey?: SelectInput | null;
    notInQueryKey?: SelectInput | null;
};
export type AnalyticsContentItemRelationWhereInput = {
    have?: AnalyticsContentItemWhereInput | null;
    haveNot?: AnalyticsContentItemWhereInput | null;
    exists?: boolean | null;
};
export type AnalyticsContentItemWhereInput = {
    objectId?: IdWhereInput | null;
    createdAt?: DateWhereInput | null;
    updatedAt?: DateWhereInput | null;
    ACL?: ObjectWhereInput | null;
    contentItem?: ContentItemRelationWhereInput | null;
    viewCount?: NumberWhereInput | null;
    emojiTotalCount?: NumberWhereInput | null;
    emojiCountMap?: ObjectWhereInput | null;
    commentCount?: NumberWhereInput | null;
    id?: IdWhereInput | null;
    OR?: Array<AnalyticsContentItemWhereInput> | null;
    AND?: Array<AnalyticsContentItemWhereInput> | null;
    NOR?: Array<AnalyticsContentItemWhereInput> | null;
};
export type ContentItemRelationWhereInput = {
    have?: ContentItemWhereInput | null;
    haveNot?: ContentItemWhereInput | null;
    exists?: boolean | null;
};
export type RendertronContentItemListScreenQueryVariables = {
    where: ContentItemWhereInput;
    order?: Array<ContentItemOrder> | null;
    skip: number;
    first: number;
};
export type RendertronContentItemListScreenQueryResponse = {
    readonly contentItems: {
        readonly count: number;
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly title: string | null;
                readonly content: {
                    readonly objectId: string;
                    readonly id: string;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type RendertronContentItemListScreenQuery = {
    readonly response: RendertronContentItemListScreenQueryResponse;
    readonly variables: RendertronContentItemListScreenQueryVariables;
};



/*
query RendertronContentItemListScreenQuery(
  $where: ContentItemWhereInput!
  $order: [ContentItemOrder!]
  $skip: Int!
  $first: Int!
) {
  contentItems(where: $where, skip: $skip, first: $first, order: $order) {
    count
    edges {
      node {
        id
        objectId
        title
        content {
          objectId
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      },
      {
        "kind": "Variable",
        "name": "skip",
        "variableName": "skip"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "ContentItemConnection",
    "kind": "LinkedField",
    "name": "contentItems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContentItemEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RendertronContentItemListScreenQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RendertronContentItemListScreenQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "6bde2e01bb57871e230899cd46921892",
    "id": null,
    "metadata": {},
    "name": "RendertronContentItemListScreenQuery",
    "operationKind": "query",
    "text": "query RendertronContentItemListScreenQuery(\n  $where: ContentItemWhereInput!\n  $order: [ContentItemOrder!]\n  $skip: Int!\n  $first: Int!\n) {\n  contentItems(where: $where, skip: $skip, first: $first, order: $order) {\n    count\n    edges {\n      node {\n        id\n        objectId\n        title\n        content {\n          objectId\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cf7b9ddc64fc514dd0e095a787edec72';
export default node;
