import { StyleSheet } from 'react-native';
import React from 'react';
import HomeSectionTitle from './HomeSectionTitle';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';
import { HomeSectionView_Fragment$key } from '../../__generated__/HomeSectionView_Fragment.graphql';
import URLON from 'urlon';

import {
  AnalyticsPathOrder,
  AnalyticsPathWhereInput,
  HomeSectionViewAnalyticsPathsQuery,
  UserWhereInput,
} from '../../__generated__/HomeSectionViewAnalyticsPathsQuery.graphql';
import {
  AnalyticsContentItemOrder,
  AnalyticsContentItemWhereInput,
  HomeSectionViewAnalyticsContentItemsQuery,
} from '../../__generated__/HomeSectionViewAnalyticsContentItemsQuery.graphql';
import _ from 'lodash';
import {
  HomeSectionViewUserQuery,
  UserOrder,
} from '../../__generated__/HomeSectionViewUserQuery.graphql';
import PathItemView2 from '../../components/PathItemView2';
import BaseHorizontalPathList2 from '../../components/BaseHorizontalPathList2';
import BaseHorizontalCuratorItemList from '../../components/BaseHorizontalCuratorItemList';
import CuratorItemView from '../../components/CuratorItemView';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import BaseHorizontalContentItemList from '../../components/BaseHorizontalContentItemList';
import ContentItemView3 from '../../components/ContentItemView3';
import { useDeviceType } from '../../hooks/deviceType';

interface IHomeSection {
  fragmentKey: HomeSectionView_Fragment$key | null;
}

const HomeSectionView: React.FC<IHomeSection> = ({ fragmentKey }) => {
  const homeSection = useFragment(
    graphql`
      fragment HomeSectionView_Fragment on HomeSection {
        id
        objectId
        title
        subTitle
        priority
        targetClassName
        to
        where
        count
        period
        order {
          ... on Element {
            value
          }
        }
        manuallyPickedObjectIds {
          ... on Element {
            value
          }
        }
      }
    `,
    fragmentKey
  );

  const periodWhere =
    !['Path', 'ContentItem'].includes(homeSection?.targetClassName || '') ||
    !homeSection?.period
      ? {}
      : {
          updatedAt: {
            greaterThanOrEqualTo: new Date(
              Date.now() - 1000 * 60 * 60 * 24 * (homeSection?.period || 1)
            ),
          },
        };

  const skipPathQuery = homeSection?.targetClassName !== 'Path';
  const analyticsPathWhere: AnalyticsPathWhereInput = skipPathQuery
    ? {}
    : homeSection?.manuallyPickedObjectIds?.length
    ? ({
        path: {
          have: {
            objectId: {
              in: homeSection?.manuallyPickedObjectIds.map(
                (item) => item?.value
              ),
            },
            published: {
              equalTo: true,
            },
          },
        },
      } as AnalyticsPathWhereInput)
    : homeSection?.where
    ? (_.merge(periodWhere, homeSection?.where) as AnalyticsPathWhereInput)
    : {};
  const analyticsPathOrder: AnalyticsPathOrder[] = skipPathQuery
    ? []
    : homeSection?.order?.map((item) => item?.value)
    ? (homeSection?.order?.map((item) => item?.value) as AnalyticsPathOrder[])
    : [];

  // const { analyticsPaths } =
  //   useLazyLoadQuery<HomeSectionViewAnalyticsPathsQuery>(
  //     graphql`
  //       query HomeSectionViewAnalyticsPathsQuery(
  //         $skip: Boolean!
  //         $where: AnalyticsPathWhereInput!
  //         $order: [AnalyticsPathOrder!]
  //         $first: Int!
  //       ) {
  //         analyticsPaths(where: $where, order: $order, first: $first)
  //           @skip(if: $skip) {
  //           edges {
  //             node {
  //               id
  //               objectId
  //               updatedAt
  //               path {
  //                 id
  //                 objectId
  //                 title
  //                 updatedAt
  //               }
  //             }
  //           }
  //         }
  //       }
  //     `,
  //     {
  //       skip: skipPathQuery,
  //       where: analyticsPathWhere,
  //       order: analyticsPathOrder,
  //       first: homeSection?.count || 1,
  //     }
  //   );

  const skipContentItemQuery = homeSection?.targetClassName !== 'ContentItem';
  const analyticsContentItemWhere: AnalyticsContentItemWhereInput =
    skipContentItemQuery
      ? {}
      : homeSection?.manuallyPickedObjectIds?.length
      ? ({
          contentItem: {
            have: {
              objectId: {
                in: homeSection?.manuallyPickedObjectIds.map(
                  (item) => item?.value
                ),
              },
              published: {
                equalTo: true,
              },
            },
          },
        } as AnalyticsContentItemWhereInput)
      : homeSection?.where
      ? (_.merge(
          periodWhere,
          homeSection?.where || {}
        ) as AnalyticsContentItemWhereInput)
      : {};
  const analyticsContentItemOrder: AnalyticsContentItemOrder[] =
    skipContentItemQuery
      ? []
      : homeSection?.order?.map((item) => item?.value)
      ? (homeSection?.order?.map(
          (item) => item?.value
        ) as AnalyticsContentItemOrder[])
      : [];
  // const { analyticsContentItems } =
  //   useLazyLoadQuery<HomeSectionViewAnalyticsContentItemsQuery>(
  //     graphql`
  //       query HomeSectionViewAnalyticsContentItemsQuery(
  //         $skip: Boolean!
  //         $where: AnalyticsContentItemWhereInput!
  //         $order: [AnalyticsContentItemOrder!]
  //         $first: Int!
  //       ) {
  //         analyticsContentItems(where: $where, order: $order, first: $first)
  //           @skip(if: $skip) {
  //           edges {
  //             node {
  //               id
  //               objectId
  //               updatedAt
  //               contentItem {
  //                 id
  //                 objectId
  //                 title
  //               }
  //             }
  //           }
  //         }
  //       }
  //     `,
  //     {
  //       skip: skipContentItemQuery,
  //       where: analyticsContentItemWhere,
  //       order: analyticsContentItemOrder,
  //       first: homeSection?.count || 1,
  //     }
  //   );

  const skipUserQuery =
    homeSection?.targetClassName !== 'User' ||
    !Boolean(homeSection?.manuallyPickedObjectIds?.length);
  const userWhere: UserWhereInput = {
    objectId: {
      in: (homeSection?.manuallyPickedObjectIds || []).map(
        (item) => item?.value as string | null
      ),
    },
  };

  const userOrder: UserOrder[] = skipUserQuery
    ? []
    : homeSection?.order?.map((item) => item?.value)
    ? (homeSection?.order?.map((item) => item?.value) as UserOrder[])
    : [];

  // const { users } = useLazyLoadQuery<HomeSectionViewUserQuery>(
  //   graphql`
  //     query HomeSectionViewUserQuery(
  //       $skip: Boolean!
  //       $where: UserWhereInput!
  //       $order: [UserOrder!]
  //       $first: Int!
  //     ) {
  //       users(where: $where, order: $order, first: $first) @skip(if: $skip) {
  //         edges {
  //           node {
  //             id
  //             objectId
  //           }
  //         }
  //       }
  //     }
  //   `,
  //   {
  //     skip: skipUserQuery,
  //     where: userWhere,
  //     order: userOrder,
  //     first: homeSection?.count || 1,
  //   }
  // );

  const to =
    homeSection?.targetClassName === 'User'
      ? undefined
      : homeSection?.manuallyPickedObjectIds?.length
      ? {
          pathname:
            homeSection.targetClassName === 'Path'
              ? '/paths/curation'
              : '/notes/curation',
          search:
            'where=' +
            URLON.stringify(
              homeSection.targetClassName === 'Path'
                ? analyticsPathWhere
                : analyticsContentItemWhere
            ).replaceAll('&', '*') +
            `&title=${homeSection.title}`,
        }
      : homeSection?.to ?? undefined;

  const deviceType = useDeviceType();
  const baseHorizontalListStyle: React.CSSProperties = {
    marginLeft: -16,
    marginRight: -16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 28,
  };
  return (
    <>
      <HomeSectionTitle
        accessibilityRole="heading"
        accessibilityLevel={2}
        // arrow={Boolean(to)}
        to={to}
        title={homeSection?.title}
        subTitle={homeSection?.subTitle}
      />
      {deviceType === 'MOBILE' ? (
        <LFWhiteSpace size={'xl'} />
      ) : (
        <LFWhiteSpace size={'xs'} />
      )}
      {!Boolean(skipContentItemQuery) && (
        <BaseHorizontalContentItemList
          style={baseHorizontalListStyle}
          where={analyticsContentItemWhere}
          order={analyticsContentItemOrder}
          first={homeSection?.count || 1}
          onPressItemTo={(contentId, contentItemId) =>
            `/note/${contentId}/${contentItemId}`
          }
          gap={8}
          itemStyle={{ width: deviceType === 'MOBILE' ? 210 : 240 }}
          itemProp={{ numberOfNotes: 2 }}
        />
      )}
      {!Boolean(skipPathQuery) && (
        <BaseHorizontalPathList2
          style={baseHorizontalListStyle}
          gap={8}
          itemStyle={{ width: deviceType === 'MOBILE' ? 343 : 340 }}
          where={analyticsPathWhere}
          order={analyticsPathOrder}
          first={homeSection?.count || 1}
        />
      )}
      {!Boolean(skipUserQuery) && (
        <BaseHorizontalCuratorItemList
          gap={8}
          style={baseHorizontalListStyle}
          itemStyle={{ width: deviceType === 'MOBILE' ? 343 : 340 }}
          where={userWhere}
          order={userOrder}
          first={homeSection?.count || 1}
        />
      )}
      <LFWhiteSpace size={28} />
    </>
  );
};

export default HomeSectionView;

const styles = StyleSheet.create({});
