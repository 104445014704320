/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BookMarkButtonForPreviewDeleteReactionMutationVariables = {
    id: string;
};
export type BookMarkButtonForPreviewDeleteReactionMutationResponse = {
    readonly deleteReaction: {
        readonly reaction: {
            readonly id: string;
            readonly objectId: string;
            readonly contentItem: {
                readonly id: string;
                readonly objectId: string;
            } | null;
        };
    } | null;
};
export type BookMarkButtonForPreviewDeleteReactionMutation = {
    readonly response: BookMarkButtonForPreviewDeleteReactionMutationResponse;
    readonly variables: BookMarkButtonForPreviewDeleteReactionMutationVariables;
};



/*
mutation BookMarkButtonForPreviewDeleteReactionMutation(
  $id: ID!
) {
  deleteReaction(input: {id: $id}) {
    reaction {
      id
      objectId
      contentItem {
        id
        objectId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeleteReactionPayload",
    "kind": "LinkedField",
    "name": "deleteReaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Reaction",
        "kind": "LinkedField",
        "name": "reaction",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookMarkButtonForPreviewDeleteReactionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookMarkButtonForPreviewDeleteReactionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "bc8a04bfeb0ca4dc7fad7893cef05540",
    "id": null,
    "metadata": {},
    "name": "BookMarkButtonForPreviewDeleteReactionMutation",
    "operationKind": "mutation",
    "text": "mutation BookMarkButtonForPreviewDeleteReactionMutation(\n  $id: ID!\n) {\n  deleteReaction(input: {id: $id}) {\n    reaction {\n      id\n      objectId\n      contentItem {\n        id\n        objectId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '04f76e8cb427029db65af4e5a21e7c8e';
export default node;
