/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MembershipPricingCardFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly duration: number | null;
    readonly price: number | null;
    readonly author: {
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
    } | null;
    readonly membership: {
        readonly title: string | null;
    } | null;
    readonly " $refType": "MembershipPricingCardFragment";
};
export type MembershipPricingCardFragment$data = MembershipPricingCardFragment;
export type MembershipPricingCardFragment$key = {
    readonly " $data"?: MembershipPricingCardFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MembershipPricingCardFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembershipPricingCardFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Membership",
      "kind": "LinkedField",
      "name": "membership",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MembershipPricing",
  "abstractKey": null
};
})();
(node as any).hash = 'c85a0c69cae8e282122a26ed936a4ac6';
export default node;
