import { useScroll } from 'ahooks';
import { useEffect } from 'react';

const useScrollEffect = (effect: (scroll: number) => void) => {
  const { top } = useScroll();
  useEffect(() => {
    effect(top);
  }, [top]);
};

export default useScrollEffect;
