import { StyleSheet, View, Image } from 'react-native';
import React from 'react';
import LFWhiteSpace from './LFWhiteSpace';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { colors } from '../constants/styleGuide';
import LFText from './typo/LFText';
import BottomDrawerModal from './BottomDrawerModal';
import { Flex } from '@ant-design/react-native';
import { useLocation } from 'react-router-dom';
import LFLink from './LFLink';
import { LoginScreenAccessAction } from '../screens/LoginScreen';

const TITLES = [
  `나만의 학습 기록을 남기고,\n관리하고 싶다면!`,
  `다양한 전문가의 멤버십 전용\n콘텐츠를 보고 싶다면!`,
  `나의 학습 내용을 공유하고,\n수익을 올리고 싶다면!`,
];

const SuggestionLogInSignUpModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  noAgainId: string;
  scrollable: boolean;
}> = ({ visible, onClose, noAgainId, scrollable }) => {
  const location = useLocation();
  return (
    <BottomDrawerModal
      visible={visible}
      noAgainId={noAgainId}
      onClose={onClose}
      scrollable={scrollable}
      noAgainButtonTestID="popup2-no-again-button:SuggestionLoginSignUpModal"
      closeButtonTestID="popup2-close-button:SuggestionLoginSignUpModal"
    >
      <LFText style={styles.header}>
        {[
          `👋 다양한 `,
          <LFText style={styles.highlightText}>인사이트</LFText>,
          `를\n읽어보고 계신가요?`,
        ]}
      </LFText>
      <LFWhiteSpace size="xl" direction="column" />
      {TITLES.map((title, index) => {
        const last = index === TITLES.length - 1;
        return (
          <React.Fragment key={title}>
            <Flex style={styles.section} align="start">
              <Image
                accessibilityLabel=""
                source={require('../assets/images/iconBlueCheck24@3x.png')}
                style={styles.icon}
              />
              <LFWhiteSpace direction="row" size="md" />
              <LFText style={styles.title}>{title}</LFText>
            </Flex>
            {!last && <LFWhiteSpace size="md" direction="column" />}
          </React.Fragment>
        );
      })}
      <LFWhiteSpace size="xl" direction="column" />
      <LFText style={styles.message}>
        {`3초 회원가입/로그인으로,\n런핏의 커뮤니티에 여러분도 함께하세요.`}
      </LFText>
      <LFWhiteSpace size="xl" direction="column" />
      <LFLink
        testID="popup2-goto-signup:SuggestionLoginSignUpModal"
        style={styles.confirmButton}
        to={{
          pathname: '/login',
          state: {
            from: location,
            accessAction: 'viewMoreThanTwo' as LoginScreenAccessAction,
          },
        }}
      >
        <LFText style={styles.confirmButtonTitle}>3초 만에 가입하기</LFText>
      </LFLink>
      <LFWhiteSpace size="xl" direction="column" />
    </BottomDrawerModal>
  );
};

export default SuggestionLogInSignUpModal;

const styles = StyleSheet.create({
  container: {},
  header: {
    fontSize: 24,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 36,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.SECONDARY_BLACK,
  },
  highlightText: { color: colors.PRIMARY_100 },
  section: {
    borderRadius: 16,
    backgroundColor: colors.BG_2,
    padding: 12,
    marginHorizontal: 16,
  },
  icon: { width: 24, height: 24 },
  title: {
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
  message: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_60,
  },
  confirmButton: {
    paddingVertical: 16,
    borderRadius: 8,
    backgroundColor: colors.PRIMARY_100,
    shadowColor: 'rgba(101, 112, 152, 0.2)',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
  },
  confirmButtonTitle: {
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_WHITE,
  },
});
