import { Image, StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import { Flex } from '@ant-design/react-native';
import { graphql, useLazyLoadQuery } from 'react-relay';
import _ from 'lodash';
import { TagScreenQuery } from '../__generated__/TagScreenQuery.graphql';
import LFText, { LFBoldText } from '../components/typo/LFText';
import { colors } from '../constants/styleGuide';
import BasePathList from '../components/BasePathList';
import LFWhiteSpace from '../components/LFWhiteSpace';
import SectionTitle from '../components/SectionTitle';
import BaseContentItemList from '../components/BaseContentItemList';
import URLON from 'urlon';
import { AnalyticsPathWhereInput } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';
import { AnalyticsContentItemWhereInput } from '../__generated__/BaseContentItemListPaginationFragmentQuery.graphql';
import NavBar from '../components/NavBar2';
import { useParams } from 'react-router-dom';
import EmptyList from '../components/EmptyList';
import LFMetaTags from '../components/LFMetaTags';

const TagScreen = () => {
  const { tag } = useParams<{ tag: string }>();
  const analyticsPathWhere: AnalyticsPathWhereInput = {
    path: {
      have: {
        tags: {
          contains: [tag],
        },
        published: {
          equalTo: true,
        },
      },
    },
  };
  const analyticsContentItemWhere: AnalyticsContentItemWhereInput = {
    contentItem: {
      have: {
        tags: {
          contains: [tag],
        },
        published: {
          equalTo: true,
        },
      },
    },
  };
  const [pathsCount, setPathsCount] = useState<number>(0);
  const [contentItemsCount, setContentItemsCount] = useState<number>(0);
  const { tagDescriptions } = useLazyLoadQuery<TagScreenQuery>(
    graphql`
      query TagScreenQuery($tag: String) {
        tagDescriptions(where: { tag: { equalTo: $tag } }) {
          edges {
            node {
              id
              objectId
              title
              subtitle
              description
              titleColor
              subtitleColor
              bgColor
              coverImage {
                url
              }
            }
          }
        }
      }
    `,
    {
      tag,
    }
  );
  const tagDesc = _.first(tagDescriptions.edges)?.node;
  const encodedTag = encodeURIComponent(tag);
  return (
    <View style={styles.container}>
      <LFMetaTags
        title={
          tagDesc?.title
            ? `${tagDesc.title} | Learnfit`
            : `#${tag} 관련 러닝패스와 학습노트 | Learnfit`
        }
        description={
          tagDesc?.description ??
          `#${tag} 관련 러닝패스와 학습노트를 확인해보세요.`
        }
        canonicalLink={`https://learnfit.ai/tag/${encodedTag}`}
      />
      <NavBar title={'#' + tag} sticky />
      {tagDesc ? (
        <>
          <Flex
            direction="column"
            align="start"
            style={[
              styles.blueCover,
              tagDesc?.bgColor
                ? {
                    backgroundColor: tagDesc?.bgColor,
                  }
                : null,
            ]}
          >
            <Flex
              direction="row"
              justify="between"
              align="start"
              style={{ alignSelf: 'stretch' }}
            >
              <LFText
                accessibilityRole="heading"
                style={[
                  styles.title,
                  tagDesc?.titleColor ? { color: tagDesc?.titleColor } : null,
                ]}
              >
                {tagDesc?.title}
              </LFText>
              {/* <Image
            source={require('../assets/images/icons/closeIcon@3x.png')}
            style={{ width: 24, height: 24 }}
          /> */}
            </Flex>
            <LFText
              accessibilityRole="heading"
              aria-level={2}
              style={[
                styles.subtitle,
                tagDesc?.subtitleColor
                  ? { color: tagDesc?.subtitleColor }
                  : null,
              ]}
            >
              {tagDesc?.subtitle}
            </LFText>
            <LFWhiteSpace size="lg" />
            <Image
              accessibilityLabel={`${tagDesc.title} 대표 이미지`}
              source={{ uri: tagDesc?.coverImage?.url }}
              style={styles.cover}
            />
            <Flex style={styles.descriptionWrap}>
              <LFText accessibilityRole="paragraph" style={styles.description}>
                {tagDesc?.description}
              </LFText>
            </Flex>
          </Flex>
          <LFWhiteSpace size="sm" style={{ backgroundColor: colors.BG_2 }} />
        </>
      ) : null}
      <View style={{ padding: 16 }}>
        <SectionTitle
          accessibilityRole="heading"
          accessibilityLevel={2}
          // title={`${tag}에 대한 러닝패스`}
          title={[<LFBoldText>{tag}</LFBoldText>, '에 대한 러닝패스']}
          arrow={pathsCount > 4}
          count={pathsCount > 4 ? pathsCount : undefined}
          to={
            pathsCount > 4
              ? {
                  pathname: '/paths/curation',
                  search:
                    'where=' +
                    URLON.stringify(analyticsPathWhere).replace('&', '*') +
                    `&title=${tag} 러닝패스`,
                }
              : undefined
          }
        />
        <LFWhiteSpace size="md" />
        <BasePathList
          where={analyticsPathWhere}
          order={['createdAt_DESC']}
          first={4}
          onPressItemTo={(pathObjectId) => `/path/${pathObjectId}`}
          noLoadNext
          onChangeCount={setPathsCount}
          ListEmptyComponent={
            <EmptyList message="해당하는 러닝패스가 없습니다." />
          }
        />
        <LFWhiteSpace size="xxl" />
        <SectionTitle
          accessibilityRole="heading"
          accessibilityLevel={2}
          // title={`${tag}에 대한 학습노트`}
          title={[<LFBoldText>{tag}</LFBoldText>, '에 대한 학습노트']}
          arrow={contentItemsCount > 4}
          count={contentItemsCount > 4 ? contentItemsCount : undefined}
          to={
            contentItemsCount > 4
              ? {
                  pathname: '/notes/curation',
                  search:
                    'where=' +
                    URLON.stringify(analyticsContentItemWhere).replace(
                      '&',
                      '*'
                    ) +
                    `&title=${tag} 학습노트`,
                }
              : undefined
          }
        />
        <LFWhiteSpace size="md" />
        <BaseContentItemList
          where={analyticsContentItemWhere}
          order={['createdAt_DESC']}
          first={4}
          onPressItemTo={(contentItemId, contentId) =>
            `/note/${contentId}/${contentItemId}`
          }
          noLoadNext
          onChangeCount={setContentItemsCount}
          ListEmptyComponent={
            <EmptyList message="해당하는 학습노트가 없습니다." />
          }
        />
      </View>
    </View>
  );
};

export default TagScreen;

const styles = StyleSheet.create({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 40,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_WHITE,
  },
  subtitle: {
    opacity: 0.6,
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 25.2,
    letterSpacing: 0.21,
    color: '#ffffff',
  },
  description: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 28.8,
    letterSpacing: 0.24,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  blueCover: {
    paddingTop: 48,
    paddingHorizontal: 24,
    backgroundColor: colors.PRIMARY_100,
  },
  descriptionWrap: {
    backgroundColor: 'white',
    padding: 24,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    alignSelf: 'stretch',
    marginHorizontal: -24,
    marginTop: 16,
  },
  container: {
    alignItems: 'stretch',
    backgroundColor: 'white',
  },
  cover: {
    width: 200,
    height: 200,
    alignSelf: 'center',
  },
});
