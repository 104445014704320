/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InstructionCardSourceContentItemQueryVariables = {
    id: string;
    empty: boolean;
};
export type InstructionCardSourceContentItemQueryResponse = {
    readonly selectedContentItem?: {
        readonly id: string;
        readonly objectId: string;
        readonly __typename: string;
        readonly title: string | null;
        readonly published: boolean | null;
        readonly author: {
            readonly id: string;
            readonly name: string | null;
        } | null;
        readonly content: {
            readonly __typename: string;
            readonly id: string;
            readonly objectId: string;
            readonly " $fragmentRefs": FragmentRefs<"ContentPreviewFragment">;
        } | null;
    };
};
export type InstructionCardSourceContentItemQuery = {
    readonly response: InstructionCardSourceContentItemQueryResponse;
    readonly variables: InstructionCardSourceContentItemQueryVariables;
};



/*
query InstructionCardSourceContentItemQuery(
  $id: ID!
  $empty: Boolean!
) {
  selectedContentItem: contentItem(id: $id) @skip(if: $empty) {
    id
    objectId
    __typename
    title
    published
    author {
      id
      name
    }
    content {
      __typename
      id
      objectId
      ...ContentPreviewFragment
    }
  }
}

fragment ContentPreviewFragment on Content {
  __typename
  id
  objectId
  type
  thumbURL
  link
  title
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "empty"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InstructionCardSourceContentItemQuery",
    "selections": [
      {
        "condition": "empty",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "selectedContentItem",
            "args": (v2/*: any*/),
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContentPreviewFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InstructionCardSourceContentItemQuery",
    "selections": [
      {
        "condition": "empty",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "selectedContentItem",
            "args": (v2/*: any*/),
            "concreteType": "ContentItem",
            "kind": "LinkedField",
            "name": "contentItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbURL",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "link",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "35dc3948b9969544e1cebf728415af26",
    "id": null,
    "metadata": {},
    "name": "InstructionCardSourceContentItemQuery",
    "operationKind": "query",
    "text": "query InstructionCardSourceContentItemQuery(\n  $id: ID!\n  $empty: Boolean!\n) {\n  selectedContentItem: contentItem(id: $id) @skip(if: $empty) {\n    id\n    objectId\n    __typename\n    title\n    published\n    author {\n      id\n      name\n    }\n    content {\n      __typename\n      id\n      objectId\n      ...ContentPreviewFragment\n    }\n  }\n}\n\nfragment ContentPreviewFragment on Content {\n  __typename\n  id\n  objectId\n  type\n  thumbURL\n  link\n  title\n}\n"
  }
};
})();
(node as any).hash = '4195b1cc0fe820420248f4977f1d9a21';
export default node;
