/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ItemSelectorScreen_Fragment = {
    readonly id: string;
    readonly objectId: string;
    readonly title: string | null;
    readonly description: string | null;
    readonly content: {
        readonly id: string;
        readonly objectId: string;
        readonly type: string | null;
        readonly title: string | null;
        readonly description: string | null;
        readonly thumbURL: string | null;
        readonly link: string | null;
    } | null;
    readonly author: {
        readonly objectId: string;
        readonly name: string | null;
    } | null;
    readonly noteData: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly tags: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly " $refType": "ItemSelectorScreen_Fragment";
};
export type ItemSelectorScreen_Fragment$data = ItemSelectorScreen_Fragment;
export type ItemSelectorScreen_Fragment$key = {
    readonly " $data"?: ItemSelectorScreen_Fragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemSelectorScreen_Fragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "type": "Element",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemSelectorScreen_Fragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbURL",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "link",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "noteData",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ContentItem",
  "abstractKey": null
};
})();
(node as any).hash = '6a8026a20c3eefe6add50f28849824f0';
export default node;
