/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReplyFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly createdAt: unknown;
    readonly text: string | null;
    readonly author: {
        readonly id: string;
        readonly objectId: string;
        readonly name: string | null;
        readonly " $fragmentRefs": FragmentRefs<"UserProfileImageFragment">;
    } | null;
    readonly parent: {
        readonly id: string;
        readonly objectId: string;
        readonly text: string | null;
    } | null;
    readonly " $refType": "ReplyFragment";
};
export type ReplyFragment$data = ReplyFragment;
export type ReplyFragment$key = {
    readonly " $data"?: ReplyFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"ReplyFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReplyFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileImageFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();
(node as any).hash = 'fb7042c9038c62af199ec32c9f5d0fa4';
export default node;
