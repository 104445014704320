import { StyleSheet, ViewStyle } from 'react-native';
import React, { useEffect, ReactNode, useRef, useState } from 'react';
import { AnalyticsPathOrder } from '../__generated__/BasePathListPaginationFragmentQuery.graphql';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  AnalyticsPathWhereInput,
  BaseHorizontalPathListQuery,
} from '../__generated__/BaseHorizontalPathListQuery.graphql';
import LFLink from './LFLink';
import _ from 'lodash';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Flex } from '@ant-design/react-native';
import { PathItemView_Fragment$key } from '../__generated__/PathItemView_Fragment.graphql';

interface PathItemElementProp {
  (fragment: PathItemView_Fragment$key | null): ReactNode;
}

interface IBaseHorizontalPathList {
  where: AnalyticsPathWhereInput; // 데이터 조건
  order: AnalyticsPathOrder[]; // 데이터 순서
  first: number; // 데이터 갯수
  onPressItem?: (pathId: string) => void; // 각 아이템 눌릴 때 action
  onPressItemTo?: (pathId: string) => string; // 각 아이템 눌릴 때 넘어갈 link(랜더트론용)
  onChangeCount?: (count: number) => void; // fetch 후 count를 외부로 전달
  gap?: number; // 각 아이템의 간격
  style?: React.CSSProperties; // 리스트에 대한 style
  itemStyle?: ViewStyle; // 각 아이템에 대한 style
  itemElement: PathItemElementProp; // 아이템 컴포넌트(prop은 fragment key)
}

const BaseHorizontalPathList: React.FC<IBaseHorizontalPathList> = ({
  where,
  order,
  first,
  onPressItem,
  onPressItemTo,
  onChangeCount,
  gap = 0,
  style,
  itemStyle,
  itemElement,
}) => {
  const { analyticsPaths } = useLazyLoadQuery<BaseHorizontalPathListQuery>(
    graphql`
      query BaseHorizontalPathListQuery(
        $where: AnalyticsPathWhereInput
        $order: [AnalyticsPathOrder!]
        $first: Int
      ) {
        analyticsPaths(where: $where, order: $order, first: $first) {
          count
          edges {
            node {
              id
              objectId
              path {
                id
                objectId
                ...PathItemView_Fragment
              }
            }
          }
        }
      }
    `,
    { where, first, order }
  );

  useEffect(() => {
    _.isFunction(onChangeCount) && onChangeCount(analyticsPaths.count);
  }, [analyticsPaths.count]);

  const pathNodes = analyticsPaths.edges?.map((edge) => edge?.node?.path) || [];
  const gapAfterContainer = style?.paddingRight || style?.padding;
  const scrollContainerRef = useRef<HTMLElement | null>(null);
  const [isSliding, setIsSliding] = useState(false);

  // BaseHorizontalPathList 컴포넌트가 리렌더링 될 때마다 가로 스크롤 위치 초기화
  useEffect(() => {
    setTimeout(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo({ left: 0 });
      }
    }, 100);
  }, [where]);
  return (
    <ScrollContainer
      innerRef={scrollContainerRef}
      onStartScroll={() => {
        setIsSliding(true);
      }}
      onEndScroll={() => {
        setTimeout(() => {
          setIsSliding(false);
        }, 0);
      }}
      style={style}
    >
      <Flex>
        {pathNodes.map((pathNode, index) => (
          <LFLink
            style={[
              itemStyle,
              { marginRight: pathNodes.length === index + 1 ? 0 : gap },
            ]}
            onPress={
              _.isFunction(onPressItem) && pathNode?.objectId
                ? () => {
                    !isSliding && onPressItem(pathNode.objectId);
                  }
                : undefined
            }
            to={
              // isSlidingRef에서 isSliding(state)으로 변경한 이유:
              // LFLink의 to prop의 경우 함수가 아니라 string type
              // 초기 값이 false인 isSlidingRef를 토대로 to를 설정하면 무조건 ''가 전달됨(isSlidingRef가 수정되어도)
              _.isFunction(onPressItemTo) && pathNode?.objectId && !isSliding
                ? onPressItemTo(pathNode.objectId)
                : ''
            }
          >
            {itemElement(pathNode || null)}
          </LFLink>
        ))}
        {/* 
          스크롤 가능한 UI 컨테이너에서 끝쪽 margin, padding은 스크롤 컨테이너 내부에서 적용되지 않는 점을 알게 되어
          별도로 element를 두어 paddingRight, marginRight의 역할을 대신하도록 조치했습니다.
          링크: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout 
        */}
        {gapAfterContainer && (
          <Flex style={{ width: Number(gapAfterContainer), height: 1 }} />
        )}
      </Flex>
    </ScrollContainer>
  );
};

export default React.memo(BaseHorizontalPathList);

const styles = StyleSheet.create({});
