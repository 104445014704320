import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { Flex } from '@ant-design/react-native';
import NavBar from '../../components/NavBar2';
import LFMetaTags from '../../components/LFMetaTags';
import LFText from '../../components/typo/LFText';
import { useAuth } from '../../hooks/auth';
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { MembershipStatusScreenQuery } from '../../__generated__/MembershipStatusScreenQuery.graphql';
import _ from 'lodash';
import MembershipStatusCard from './MembershipStatusCard';
import MembershipPaymentItemView, {
  MembershipPaymentItemHeaderView,
} from './MembershipPaymentItemView';
import LFButton from '../../components/LFButton';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import { colors } from '../../constants/styleGuide';
import LFLink from '../../components/LFLink';
import { MembershipStatusScreenValidQuery } from '../../__generated__/MembershipStatusScreenValidQuery.graphql';

const MembershipStatusScreen = () => {
  const { user: loginUser } = useAuth();
  const history = useHistory();
  const { userObjectId: ownerUserObjectId } =
    useParams<{ userObjectId: string }>();

  const { membershipPayments, membershipStatuses, owner } =
    useLazyLoadQuery<MembershipStatusScreenQuery>(
      graphql`
        query MembershipStatusScreenQuery(
          $ownerUserObjectId: ID!
          $loginUserID: ID!
        ) {
          membershipStatuses(
            where: {
              membership: {
                have: {
                  author: {
                    have: { objectId: { equalTo: $ownerUserObjectId } }
                  }
                }
              }
              member: { have: { objectId: { equalTo: $loginUserID } } }
            }
          ) {
            edges {
              node {
                id
                objectId
                status
                ...MembershipStatusCardFragment
              }
            }
          }
          membershipPayments(
            where: {
              membershipPricing: {
                have: {
                  membership: {
                    have: {
                      author: {
                        have: { objectId: { equalTo: $ownerUserObjectId } }
                      }
                    }
                  }
                }
              }
              member: { have: { objectId: { equalTo: $loginUserID } } }
              status: { notEqualTo: "initial" }
            }
            order: updatedAt_DESC
          ) {
            edges {
              node {
                id
                objectId
                ...MembershipPaymentItemViewFragment
              }
            }
          }
          owner: user(id: $ownerUserObjectId) {
            id
            objectId
            name
            createdAt
            updatedAt
            description
            originProfileURL
            jobTitle
            tags {
              ... on Element {
                value
              }
            }
            profileImage {
              url
            }
          }
        }
      `,
      {
        ownerUserObjectId,
        loginUserID: loginUser?.objectId,
      },
      {
        fetchPolicy: 'store-and-network',
      }
    );

  const BuyMembershipButton = ({
    valid,
    onPress,
  }: {
    valid?: boolean;
    onPress: () => void;
  }) => (
    <LFButton
      disabled={valid}
      onPress={onPress}
      style={valid ? { backgroundColor: '#7c7c7c' } : {}}
    >
      <View style={{ alignItems: 'center' }}>
        <LFText style={{ color: colors.TEXT_WHITE, fontSize: 16 }}>
          멤버십 가입하기
        </LFText>
        {valid && (
          <LFText style={{ color: colors.TEXT_WHITE, fontSize: 16 }}>
            (기존 멤버십 만료 후 구매 가능합니다.)
          </LFText>
        )}
      </View>
    </LFButton>
  );

  return (
    <Flex direction="column" align="stretch" style={{ flex: 1 }}>
      <LFMetaTags
        title={'멤버십 상세내역'}
        description={'멤버십 상태를 확인해보세요.'}
      />
      <NavBar title={`${owner.name}님 멤버십 가입내역`} />
      <Flex
        direction="column"
        align="stretch"
        style={{ flex: 1, paddingHorizontal: 16 }}
      >
        <LFWhiteSpace />
        <LFText style={styles.sectionTitle}>이용중 멤버십</LFText>
        <LFWhiteSpace />
        {_.map(membershipStatuses.edges, (e) => {
          const membershipValid = e?.node?.status === 'valid';
          return e?.node ? (
            <React.Fragment key={e.node.objectId}>
              <MembershipStatusCard membershipStatusFrgmt={e?.node} />
              <LFWhiteSpace />
              <BuyMembershipButton
                onPress={() => {
                  history.push(`/u/${ownerUserObjectId}/membership`);
                }}
                valid={membershipValid}
              />
            </React.Fragment>
          ) : null;
        })}
        {_.size(membershipStatuses.edges) === 0 ? (
          <Flex align="center" justify="center" style={{ padding: 16 }}>
            <LFText>{owner.name} 님의 멤버십에 가입한 내역이 없습니다.</LFText>
          </Flex>
        ) : null}
        <LFWhiteSpace size="xl" />
        <LFText style={styles.sectionTitle}>결제내역</LFText>
        <LFWhiteSpace />

        <MembershipPaymentItemHeaderView />
        {_.map(membershipPayments.edges, (e) => {
          return e?.node ? (
            <>
              <MembershipPaymentItemView membershipPaymentFrgmt={e.node} />
            </>
          ) : null;
        })}
        {_.size(membershipPayments.edges) === 0 ? (
          <Flex align="center" justify="center" style={{ padding: 16 }}>
            <LFText>결제하신 내역이 없습니다.</LFText>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default MembershipStatusScreen;

const styles = StyleSheet.create({
  sectionTitle: {
    size: 25,
    fontWeight: 'bold',
  },
});
