import React, { Suspense, useState } from 'react';
import { Card, Flex, WhiteSpace } from '@ant-design/react-native';
import {
  Linking,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
  Image,
  Text,
} from 'react-native';
import HyperLink from 'react-native-hyperlink';
import SourcePreview from '../../screens/TraversalScreen/SourcePreview';
import _ from 'lodash';
import { colors } from '../../constants/styleGuide';
import { useHistory } from 'react-router-dom';
import LFText from '../typo/LFText';
import {
  graphqlElementsToNumberArray,
  graphqlElementsToStringArray,
} from '../../helper/typeTransform';
import Quiz from '../Quiz';
import { graphql, useFragment } from 'react-relay';
import { InstructionCardFragment$key } from '../../__generated__/InstructionCardFragment.graphql';
import ContentPreview, { SimplePreview } from './ContentPreview';
import { ContentPreviewFragment$key } from '../../__generated__/ContentPreviewFragment.graphql';
import TypeBadge from './TypeBadge';
import ContentItemAuthorSummary from './ContentItemAuthorSummary';
import LFWhiteSpace from '../LFWhiteSpace';
import PathPreview from './PathPreview';
import { useLearningHistoryMutation } from '../../hooks/learningHistoryState';
import LFLink from '../LFLink';
import { useAuth } from '../../hooks/auth';
import { Ionicons } from '@expo/vector-icons';

const InstructionCard: React.FC<{
  // card: any;
  cardFrgmt: InstructionCardFragment$key;
  style: ViewStyle;
  contentContainerStyle?: ViewStyle;
  highlight?: boolean;
  learningHistoriesConnectionId: string;
  learningStatus?: string | null;
}> = ({
  cardFrgmt,
  style,
  highlight,
  contentContainerStyle,
  learningHistoriesConnectionId,
  learningStatus,
}) => {
  const history = useHistory();

  const { user: loginUser } = useAuth();

  const card = useFragment(
    graphql`
      fragment InstructionCardFragment on InstructionCard {
        id
        objectId
        description
        optional
        sources {
          __typename
          ... on ContentItem {
            ...ContentItemAuthorSummaryFragment
            id
            objectId
            title
            published
            content {
              __typename
              id
              objectId
              type
              link
              ...ContentPreviewFragment
            }
            author {
              objectId
              name
            }
            tags {
              ... on Element {
                value
              }
            }
          }
          ... on Path {
            id
            objectId
            title
            description
            published
            author {
              objectId
              name
            }
            ...PathPreviewFragment
          }
          ... on Quiz {
            id
            objectId
            ...QuizFragment
          }
        }
      }
    `,
    cardFrgmt
  );
  const [commitLearningHistory, isMutatingLearningHistory] =
    useLearningHistoryMutation({
      connections: [learningHistoriesConnectionId],
      quizId:
        _.first(card.sources)?.__typename === 'Quiz'
          ? _.get(_.first(card.sources), 'objectId')
          : null,
      contentItemId:
        _.first(card.sources)?.__typename === 'ContentItem'
          ? _.get(_.first(card.sources), 'objectId')
          : null,
    });

  const [isTextExpand, setIsTextExpand] = useState(false);

  const item = card.sources && card.sources[0];
  const sourceTypeName = item?.__typename;

  // console.log('###item');
  // console.log(card);
  // console.log(item?.title);
  // console.log(item?.published);
  return (
    <Flex
      direction="column"
      align="stretch"
      justify="start"
      style={[
        {
          padding: 16,
        },
        sourceTypeName === 'Path'
          ? {
              flex: 1,
              borderRadius: 12,
              backgroundColor: colors.SECONDARY_WHITE,
              shadowColor: '#42495926',
              // shadowColor: '#42495926.4000198',
              shadowOffset: {
                width: 0,
                height: 1,
              },
              shadowRadius: 2,
              shadowOpacity: 1,
              padding: 16,
            }
          : null,
        !item
          ? {
              backgroundColor: colors.BG_2,
              shadowColor: undefined,
              shadowOffset: undefined,
              shadowRadius: undefined,
            }
          : null,
        ,
        // highlight
        //   ? {
        //       shadowColor: 'rgba(101, 112, 152, 0.2)',
        //       shadowOffset: {
        //         width: 0,
        //         height: 6,
        //       },
        //       shadowRadius: 10,
        //       shadowOpacity: 1,
        //       borderStyle: 'solid',
        //       borderWidth: 1,
        //       borderColor: colors.PRIMARY_100,
        //     }
        //   : null,
        style,
      ]}
    >
      <Flex
        direction="column"
        align="stretch"
        justify="start"
        style={[contentContainerStyle]}
      >
        <TypeBadge type={sourceTypeName} />
        <WhiteSpace size="xs" />
        <Flex justify="between">
          <LFText
            numberOfLines={isTextExpand ? 0 : 5}
            style={styles.itemHeadDesc}
            selectable
            accessibilityRole="paragraph"
          >
            {card.description}
          </LFText>
          {/* {card.description ? (
            <LFLink
              onPress={() => setIsTextExpand(!isTextExpand)}
              style={[isTextExpand && { alignSelf: 'flex-end' }]}
            >
              {isTextExpand ? (
                <Ionicons
                  name="chevron-up-outline"
                  size={24}
                  color="black"
                  style={{ paddingTop: 30 }}
                />
              ) : (
                <Ionicons
                  name="chevron-down-outline"
                  size={24}
                  color="black"
                  style={{ paddingTop: 30 }}
                />
              )}
            </LFLink>
          ) : null} */}
        </Flex>
        <WhiteSpace size="sm" />
        <HyperLink
          onPress={(url) => {
            Linking.canOpenURL(url)
              .then(() => {
                window.open(url, '_blank')?.focus();
              })
              .catch(() => {
                console.log('열 수 없는 URL');
              });
          }}
          linkStyle={{ color: '#2F80ED' }}
        >
          {/* <LFText style={styles.itemHeadDesc} selectable>
            {card.description}
          </LFText> */}
        </HyperLink>

        {
          //Quiz
          sourceTypeName === 'Quiz' ? (
            <Quiz
              quizFrgmt={card?.sources[0]}
              onSuccess={() => {
                commitLearningHistory('done');
              }}
              alreadySuccess={learningStatus === 'done'}
            />
          ) : //ContentItem
          sourceTypeName === 'ContentItem' ? (
            item?.published === false ? (
              <>
                <SimplePreview title="⚠️ 학습노트가 작성자에 의해 비공개로 전환되었습니다." />
                {loginUser && item.author?.objectId === loginUser?.objectId ? (
                  <LFLink
                    to={`/note/${item.content?.objectId}/${item.objectId}`}
                  >
                    <LFText
                      style={{
                        textDecorationLine: 'underline',
                        color: colors.PRIMARY_100,
                      }}
                    >
                      노트 수정하기
                    </LFText>
                  </LFLink>
                ) : null}
              </>
            ) : item?.content?.__typename === 'Content' ? (
              <>
                <WhiteSpace />
                <LFLink
                  testID={`learningpath-content:InstructionCard#objectId:${
                    card.sources[0].objectId
                  }#optional:${card.optional ?? false}`}
                  style={[
                    highlight
                      ? {
                          padding: 50,
                          shadowColor: 'rgba(101, 112, 152, 0.2)',
                          shadowOffset: {
                            width: 0,
                            height: 6,
                          },
                          shadowRadius: 10,
                          shadowOpacity: 1,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          borderColor: colors.PRIMARY_100,
                        }
                      : null,
                    {
                      flexDirection: 'column',
                      alignItems: 'stretch',
                      borderRadius: 12,
                      backgroundColor: colors.BG_2,
                      padding: 16,
                    },
                  ]}
                  onPress={() => {
                    const item = _.first(card.sources);
                    console.log(item);
                    // if (item?.content.type === 'VIDEO') {
                    history.push(
                      `/note/${item.content.objectId}/${item.objectId}`,
                      {
                        // parentPathObjectId: path.objectId,
                      }
                    );
                    // }
                    // else {
                    //   const url = item?.content?.link;
                    //   if (url) {
                    //     Linking.canOpenURL(url)
                    //       .then(() => {
                    //         window.open(url, '_blank')?.focus();
                    //       })
                    //       .catch(() => {
                    //         console.log('열 수 없는 URL');
                    //       });
                    //     commitLearningHistory('done');
                    //   }
                    // }
                  }}
                >
                  <Suspense
                    fallback={
                      <ContentItemAuthorSummary
                        contentItemFrgmt={item}
                        skipQuery={true}
                      />
                    }
                  >
                    <ContentItemAuthorSummary contentItemFrgmt={item} />
                    <LFWhiteSpace />
                    <LFText
                      style={{ fontSize: 16, fontWeight: 'bold' }}
                      numberOfLines={2}
                      accessibilityRole={'heading'}
                      aria-level={2}
                    >
                      학습노트 제목: {item.title}
                    </LFText>
                    <LFWhiteSpace />
                  </Suspense>
                  <ContentPreview
                    contentFrgmt={item?.content as ContentPreviewFragment$key}
                    fullMode={false}
                    titleStyle={{ fontSize: 12, lineHeight: 14 }}
                    // title={card.sources[0].title}
                  />
                  <LFWhiteSpace size="lg" />
                  <LFText numberOfLines={1} style={{ fontSize: 12 }}>
                    {item.tags
                      ?.map((itemTag) => {
                        return '#' + itemTag?.value;
                      })
                      .join(' ')}
                  </LFText>
                </LFLink>
              </>
            ) : null
          ) : //Path
          sourceTypeName === 'Path' ? (
            <>
              <WhiteSpace />
              {/* <LFText
                style={styles.itemHeadDesc}
                selectable
                accessibilityRole="paragraph"
              >
                {card.description}
              </LFText> */}
              <LFLink
                testID={`learningpath-content:InstructionCard#optional:${
                  card.optional ?? false
                }`}
                onPress={() => {
                  history.push(`/path/${item?.objectId}`);
                }}
              >
                <PathPreview
                  pathFrgmt={item}
                  style={{ padding: 16 }}
                  titleStyle={{ fontSize: 16, fontWeight: 'bold' }}
                />
              </LFLink>
            </>
          ) : null
        }
      </Flex>
    </Flex>
  );
};

export default InstructionCard;

const styles = StyleSheet.create({
  itemHeadDesc: {
    paddingLeft: 8,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 28.8,
    letterSpacing: 0.24,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
});
