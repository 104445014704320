import { Ionicons } from '@expo/vector-icons';
import { graphql } from 'babel-plugin-relay/macro';
import _ from 'lodash';
import React, { Suspense, useCallback, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../hooks/auth';
import { NotificationNavButtonQuery } from '../__generated__/NotificationNavButtonQuery.graphql';
import LFLink from './LFLink';

const NotificationNavButton: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();

  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2]
  >({
    fetchPolicy: 'store-and-network',
    fetchKey: 0,
  });

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev) => ({
      //@ts-ignore
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: 'store-and-network',
    }));
  }, [setRefreshedQueryOptions]);

  React.useEffect(() => {
    const unListen = history.listen((location, action) => {
      refresh();
    });
    return () => unListen();
  }, []);
  const { notifications, me } = useLazyLoadQuery<NotificationNavButtonQuery>(
    graphql`
      query NotificationNavButtonQuery(
        $where: NotificationWhereInput
        $skip: Boolean!
        $userId: ID!
      ) {
        notifications(order: createdAt_DESC, where: $where) @skip(if: $skip) {
          edges {
            node {
              createdAt
            }
          }
        }
        me: user(id: $userId) @skip(if: $skip) {
          objectId
          id
          createdAtOfLastSeenNotification
        }
      }
    `,
    {
      skip: user?.objectId ? false : true,
      // 다음 where 조건은 없어도 된다. CLP 에서 이미 접근 제어
      where: {
        to: {
          have: {
            objectId: {
              equalTo: user?.objectId,
            },
          },
        },
      },
      userId: user?.objectId,
    },
    refreshedQueryOptions
    // {
    //   fetchPolicy: 'store-and-network'
    // }
  );

  // const
  const latestNotification = _.get(_.first(notifications?.edges), 'node');
  const hasUnread = !latestNotification //최신 알림이 없으면
    ? false
    : !me?.createdAtOfLastSeenNotification // 내가 봤던게 하나도 없으면
    ? true
    : //@ts-ignore
    me.createdAtOfLastSeenNotification < latestNotification.createdAt // 내가 봤던 이후 알림이 있으면
    ? true
    : false;

  return <NotificationButton hasUnread={hasUnread} />;
};

const NotificationButton: React.FC<{
  hasUnread?: boolean;
}> = ({ hasUnread = false }) => {
  const history = useHistory();
  return (
    <LFLink
      style={{ padding: 8 }}
      testID="header-notification"
      onPress={() => {
        history.push('/notification');
      }}
    >
      <View style={{ position: 'relative' }}>
        <Image
          accessibilityLabel="알림 확인하기"
          source={require('../assets/images/alarmIcon@3x.png')}
          style={{ width: 24, height: 24 }}
        />
        {hasUnread ? <View style={styles.newMark} /> : null}
      </View>
    </LFLink>
  );
};

const NotificationNavButtonWithSuspense: React.FC = () => {
  const { user } = useAuth();
  return user ? (
    <Suspense fallback={<NotificationButton />}>
      <NotificationNavButton />
    </Suspense>
  ) : (
    <NotificationButton />
  );
};

export default NotificationNavButtonWithSuspense;

const styles = StyleSheet.create({
  newMark: {
    width: 4,
    height: 4,
    position: 'absolute',
    right: 0,
    borderRadius: 50,
    backgroundColor: '#FA544A',
  },
});
