import { DependencyList, useEffect } from 'react';
import { useAuth } from './auth';
import Parse from 'parse';

interface TrackRecentHistoryParams {
  targetFieldName: 'path' | 'contentItem';
  targetClassObjectId?: string;
  // targetObjectAuthorId?: string;
}

export const useTrackRecentHistoryEffect = (
  params: TrackRecentHistoryParams,
  dep?: any[]
) => {
  const { user, sessionToken } = useAuth();
  useEffect(() => {
    if (
      user &&
      user.objectId &&
      params.targetClassObjectId &&
      params.targetFieldName
    ) {
      Parse.Cloud.run('trackRecentHistory', params, {
        sessionToken: sessionToken,
      });
    }
  }, [user?.objectId || 'NOT_YEY_LOGIN', ...(dep || [])]);
};
