import { StyleSheet, View, ViewStyle } from 'react-native';
import React from 'react';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import LFWhiteSpace from './LFWhiteSpace';
import LFText, { LFHyperLink } from './typo/LFText';
import { colors } from '../constants/styleGuide';
import { graphql, useLazyLoadQuery } from 'react-relay';
import _ from 'lodash';
import UserProfileImage from './UserProfileImage';
import { useHistory } from 'react-router-dom';
import CountOfPathsAndContentItems from '../screens/ProfileScreen/CountOfPathsAndContentItems';
import { CuratorItemViewQuery } from '../__generated__/CuratorItemViewQuery.graphql';
import ContentItemView3 from './ContentItemView3';
import LFLink from './LFLink';
import BaseHorizontalContentItemList from './BaseHorizontalContentItemList';
import {
  AnalyticsContentItemWhereInput,
  AnalyticsContentItemOrder,
} from '../__generated__/BaseHorizontalContentItemListQuery.graphql';
import { useAuth } from '../hooks/auth';
import { useDeviceType } from '../hooks/deviceType';

const CuratorItemView: React.FC<{
  userObjectId: string;
  style?: ViewStyle;
  onScrollStart?: () => void;
  onScrollEnd?: () => void;
  disableMode?: boolean;
}> = ({
  userObjectId,
  style,
  onScrollStart,
  onScrollEnd,
  disableMode = false,
}) => {
  const { user: homeUser } = useLazyLoadQuery<CuratorItemViewQuery>(
    graphql`
      query CuratorItemViewQuery($userObjectId: ID!) {
        user(id: $userObjectId) {
          id
          objectId
          username
          name
          jobTitle
          description
          ...UserProfileImageFragment
        }
      }
    `,
    { userObjectId }
  );

  const analyticsContentItemWhere: AnalyticsContentItemWhereInput = {
    contentItem: {
      have: {
        author: {
          have: {
            objectId: {
              equalTo: userObjectId,
            },
          },
        },
        published: {
          equalTo: true,
        },
        objectId: {
          exists: true,
        },
      },
    },
  };

  const analyticsContentItemOrder: AnalyticsContentItemOrder[] = [
    'emojiCountMap_DESC',
    'createdAt_DESC',
  ];

  const history = useHistory();

  const { user } = useAuth();
  const deviceType = useDeviceType();
  return (
    <View style={[styles.container, style]}>
      {/* 유저 프로필 영역 */}
      <LFLink
        disabled={disableMode}
        onPress={() => history.push(`/u/${homeUser.objectId}`)}
      >
        <Flex>
          <View style={styles.userProfileImage}>
            <UserProfileImage size={80} userFrgmt={homeUser} />
          </View>

          <LFWhiteSpace direction="row" size="sm" />
          <View>
            <LFText style={styles.userName}>{homeUser.name}</LFText>
            <Flex align="center">
              <LFText style={styles.commonNameSectionStyle}>
                {homeUser.username ? `@${homeUser.username.slice(0, 10)}` : ''}
              </LFText>
              {Boolean(homeUser.jobTitle) && (
                <>
                  <LFWhiteSpace size="xs" direction="row" />
                  <LFText style={styles.commonNameSectionStyle}>/</LFText>
                  <LFWhiteSpace size="xs" direction="row" />
                  <LFText style={styles.commonNameSectionStyle}>
                    {homeUser.jobTitle}
                  </LFText>
                </>
              )}
            </Flex>
          </View>
        </Flex>
      </LFLink>
      <LFWhiteSpace size="sm" />
      <View>
        <LFHyperLink>
          <LFText style={styles.userDescription} numberOfLines={2}>
            {homeUser.description}
          </LFText>
        </LFHyperLink>
      </View>
      <WhiteSpace size="lg" />
      <CountOfPathsAndContentItems
        userObjectId={homeUser.objectId}
        style={{ marginTop: 'auto' }}
        isMe={user?.objectId === homeUser.objectId}
      />

      <LFWhiteSpace size="lg" direction={'column'} />

      {/* 학습노트 영역 */}
      <BaseHorizontalContentItemList
        first={10}
        where={analyticsContentItemWhere}
        order={analyticsContentItemOrder}
        gap={8}
        style={{
          marginLeft: -16,
          marginRight: -16,
          paddingLeft: 16,
          paddingRight: 16,
        }}
        onScrollStart={onScrollStart}
        onScrollEnd={onScrollEnd}
        itemStyle={deviceType === 'MOBILE' ? { width: 210 } : { width: 240 }}
        itemProp={{ simpleMode: true }}
        onPressItemTo={(contentId, contentItemId) =>
          `/note/${contentId}/${contentItemId}`
        }
        paginationButton={false}
      />
    </View>
  );
};

export default CuratorItemView;
const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    position: 'relative',
    padding: 16,
    width: '100%',
    backgroundColor: colors.BG_4,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    borderRadius: 16,
    // shadowColor: 'rgba(58, 69, 99, 0.2)',
    // shadowOffset: {
    //   width: 0,
    //   height: 4,
    // },
    // shadowRadius: 10,
    // shadowOpacity: 1,
    flex: 1,
  },

  commonNameSectionStyle: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  userProfileImage: {
    borderRadius: 100,
    borderStyle: 'solid',
    shadowColor: 'rgba(66, 73, 89, 0.15)',
    shadowOffset: {
      width: 0,
      height: 1.1,
    },
    shadowRadius: 2.3,
    shadowOpacity: 1,
    borderWidth: 0.6,
    borderColor: colors.BORDER_10,
    backgroundColor: colors.SECONDARY_WHITE,
  },
  userName: {
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_DARK,
  },

  userDescription: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
});
