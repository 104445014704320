import React from 'react';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import { useHistory } from 'react-router-dom';
import LFLink from './LFLink';
import LFText from './typo/LFText';
import LFWhiteSpace from './LFWhiteSpace';

interface FooterProp {
  style?: ViewStyle;
}

const openNewTab = (url: string) => {
  window.open(url);
};
const Footer: React.FC<FooterProp> = ({ style }) => {
  const history = useHistory();

  return (
    <View style={[{ borderTopWidth: 1, borderColor: '#dfe1e4' }, style]}>
      <View style={{ margin: 25 }}>
        <LFText style={styles.highLightTxt}>
          런핏에 피드백을 남겨주세요 🙇‍♂️{'    '}
          <LFText
            onPress={() =>
              openNewTab(
                'https://laivdata.notion.site/laivdata/5297afee33fa46fe95a797ca992f37f0'
              )
            }
            style={{ textDecorationLine: 'underline' }}
          >
            이동하기
          </LFText>
        </LFText>
        <LFWhiteSpace direction="column" size="sm" />

        <Flex>
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ width: 'fit-content' }}
              onPress={() => {
                window.location.href =
                  'https://laivdata.atlassian.net/wiki/external/225574993/MzdhMWU1OWJjZWJhNDJiNjkyMmQ2OTQ1ODkwMzhkYWE?atlOrigin=eyJpIjoiYTkyZDdlZmY1NjU5NDc4Mjk3Zjc4ZGE4ZGJmYTVjNzQiLCJwIjoiYyJ9';
              }}
            >
              <LFText
                style={{ fontSize: 12, color: '#787878' }}
                testID="banner-introduce-service:HomeScreen"
                onPress={() => {
                  history.push('/about');
                }}
              >
                서비스 소개
              </LFText>
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ width: 'fit-content' }}
              onPress={() =>
                (window.location.href =
                  'https://laivdata.notion.site/0cf58397349a4329945c82be49383a97')
              }
            >
              <LFText style={{ fontSize: 12, color: '#787878' }}>채용</LFText>
            </TouchableOpacity>
          </View>
        </Flex>

        <LFWhiteSpace size="xs" />

        <Flex>
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ width: 'fit-content' }}
              onPress={() => {
                openNewTab(
                  'https://laivdata.atlassian.net/wiki/external/225574993/MzdhMWU1OWJjZWJhNDJiNjkyMmQ2OTQ1ODkwMzhkYWE?atlOrigin=eyJpIjoiYTkyZDdlZmY1NjU5NDc4Mjk3Zjc4ZGE4ZGJmYTVjNzQiLCJwIjoiYyJ9'
                );
              }}
            >
              <LFText style={{ fontSize: 12, color: '#787878' }}>
                개인정보 처리방침
              </LFText>
            </TouchableOpacity>
          </View>

          <View style={{ flex: 1 }}>
            <LFLink style={{ width: 'fit-content' }} to={'/newsletter'}>
              <LFText style={{ fontSize: 12, color: '#787878' }}>
                뉴스레터
              </LFText>
            </LFLink>
          </View>
        </Flex>

        <LFWhiteSpace size="xs" />

        <Flex>
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ width: 'fit-content' }}
              onPress={() =>
                openNewTab(
                  'https://laivdata.atlassian.net/wiki/external/225738764/MDBjZTJhYzlmZmJlNDc3YmExMzA1N2NiMjA4OGM5Y2I?atlOrigin=eyJpIjoiNjY1MmJiZGFmNTAyNGI2NTkyM2E3MjY2Mjg4YjE0NWMiLCJwIjoiYyJ9'
                )
              }
            >
              <LFText style={{ fontSize: 12, color: '#787878' }}>
                서비스 약관
              </LFText>
            </TouchableOpacity>
          </View>
          <View style={{ flex: 1 }}>
            <TouchableOpacity
              style={{ width: 'fit-content' }}
              onPress={() =>
                openNewTab(
                  'https://laivdata.notion.site/laivdata/c887200904f842d296dc10bd4b87a3ae'
                )
              }
            >
              <LFText style={{ fontSize: 12, color: '#787878' }}>
                큐레이터 가이드
              </LFText>
            </TouchableOpacity>
          </View>
        </Flex>

        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />

        <View>
          <LFText style={styles.highLightTxt}>
            라이브데이터(주) 사업자 정보
          </LFText>

          <WhiteSpace size="lg" />

          {/* '(주) 라이브데이터 사업자 정보' 하단부터 서비스 약관까지 들여쓰기를 위해 marginLeft 추가 */}
          <View>
            <LFText style={styles.description}>
              사업자 등록번호 : 804-86-01711 | 통신판매업: 2022-서울서초-2105
            </LFText>

            <LFText style={[styles.description]}>
              대표 박수정 |{' '}
              <a
                href="mailto:learnfit@laivdata.com"
                style={{ color: '#262626' }}
              >
                learnfit@laivdata.com
              </a>{' '}
              | 서울특별시 서초구 서초대로54길 29-30, 4층(서초동, 이레빌딩)
            </LFText>

            <LFText style={styles.description}>
              <a href="tel:070-4647-4674" style={{ color: '#262626' }}>
                070-4647-4674
              </a>
            </LFText>
          </View>
        </View>

        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />

        <View>
          <LFText style={{ fontSize: 12, color: '#787878' }}>
            LA.IV DATA Co., Ltd.
          </LFText>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  serviceIntroBtn: {
    flex: 1,
    padding: 15,
    borderWidth: 1,
    borderColor: '#dfe1e4',
    backgroundColor: 'white',
    textAlign: 'center',
    borderRadius: 40,
  },
  highLightTxt: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#262626',
  },
  description: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#262626',
    wordBreak: 'keep-all',
    lineHeight: 18,
  },
});

export default Footer;
