import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { WhiteSpace, Flex } from '@ant-design/react-native';
import _ from 'lodash';
import React from 'react';
import {
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
  StyleSheet,
} from 'react-native';
import { useFragment } from 'react-relay';
import { graphql } from 'relay-runtime';
import { ListItemPath_Fragment$key } from '../__generated__/ListItemPath_Fragment.graphql';
import PreviewImage from './PreviewImage';
import LFText from './typo/LFText';

interface ListItemPathProps {
  style?: ViewStyle;
  pathFrgmt: ListItemPath_Fragment$key;
  onPressMore?: () => void;
  wrapperStyle?: ViewStyle;
}

// 러닝패스 아이템 UI 컴포넌트
const ListItemPath: React.FC<ListItemPathProps> = ({
  style,
  pathFrgmt,
  onPressMore,
}) => {
  const path = useFragment(
    graphql`
      fragment ListItemPath_Fragment on Path {
        id
        objectId
        title
        learnCount
        published
        author {
          id
          objectId
          name
        }
        tags {
          ... on Element {
            value
          }
        }
        contentItemList {
          ... on ContentItem {
            id
            objectId
            title
            content {
              id
              objectId
              thumbURL
            }
          }
        }
        instructionCards(order: updatedAt_DESC) {
          edges {
            node {
              id
              objectId
              description
              sources {
                __typename
                ... on ContentItem {
                  id
                  objectId
                  title
                  content {
                    id
                    title
                    objectId
                    thumbURL
                    type
                  }
                  author {
                    objectId
                    name
                  }
                  # ...ContentItemView_Fragment
                }
                ... on Content {
                  id
                  objectId
                  thumbURL
                  title
                  link
                  type
                }
                ... on Path {
                  id
                  objectId
                  title
                  description
                  author {
                    objectId
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    pathFrgmt
  );
  const isPublished = path.published;
  const cardsWithContent = path.instructionCards?.edges?.filter((edge) => {
    // content or contentItem이 존재하지 않는 instruction card를 걸러냅니다.
    return edge?.node?.sources && edge?.node?.sources?.length > 0;
  });
  return (
    <View key={path.id} style={[pathItemStyles.container, style]}>
      <View style={pathItemStyles.subContainer}>
        <LFText
          style={
            isPublished
              ? pathItemStyles.title
              : [pathItemStyles.title, { color: '#787878' }]
          }
          numberOfLines={3}
        >
          {path?.title}
        </LFText>
        <WhiteSpace />
        <Flex justify="start" align="center">
          <LFText
            numberOfLines={1}
            style={pathItemStyles.authorText}
          >{`${path?.author?.name}`}</LFText>
        </Flex>
      </View>
      <WhiteSpace />
      <View
        style={
          isPublished
            ? pathItemStyles.imageContainer
            : [pathItemStyles.imageContainer, { opacity: 0.35 }]
        }
      >
        {_.map(_.range(4), (idx) => {
          const card = _.nth(cardsWithContent, idx);
          const source = _.first(card?.node?.sources);
          const thumbURL =
            source?.__typename === 'ContentItem'
              ? source.content?.thumbURL
              : source?.__typename === 'Content'
              ? source?.thumbURL
              : null;
          return (
            <PreviewImage
              key={idx}
              style={pathItemStyles.image}
              source={{ uri: thumbURL || '' }}
            />
          );
        })}
        <View style={pathItemStyles.siderContainer}>
          <LFText style={pathItemStyles.siderText}>
            {cardsWithContent?.length}
          </LFText>
          <Ionicons name="git-branch" size={24} color="white" />
        </View>
      </View>
      {onPressMore && (
        <>
          <WhiteSpace />
          <Flex justify="between">
            <Flex align="center">
              <FontAwesome
                name={isPublished ? 'unlock' : 'lock'}
                size={12}
                color="black"
              />
              <LFText style={{ marginLeft: 5, fontSize: 12 }}>
                {isPublished ? '공개' : '비공개'}
              </LFText>
            </Flex>
            <TouchableOpacity onPress={onPressMore}>
              <Ionicons
                name="ellipsis-horizontal-sharp"
                size={12}
                color="black"
              />
            </TouchableOpacity>
          </Flex>
        </>
      )}
    </View>
  );
};

export default ListItemPath;

const pathItemStyles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 14,
    backgroundColor: '#FFF',
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,

    elevation: 12,
  },
  subContainer: { flex: 1 },
  imageContainer: {
    width: '100%',
    height: 0,
    paddingBottom: '55%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
  },
  image: { width: '50%', height: 0, paddingBottom: '27.5%' },
  playIcon: {
    position: 'absolute',
    width: 40,
    height: 40,
    left: 0,
    bottom: 0,
  },
  siderContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '40%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  siderText: { color: 'white', fontSize: 24 },
  tags: { flexDirection: 'row', flexWrap: 'wrap', margin: -2 },
  tagWrapper: {
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderWidth: 1,
    borderRadius: 13,
    margin: 2,
  },
  tagText: { fontSize: 14, color: '#295ECC' },
  title: { fontSize: 14, fontWeight: 'bold', color: '#000000' },
  authorImage: {
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: 8,
    borderWidth: 1,
    borderColor: 'rgb(239, 239, 239)',
  },
  authorText: { fontSize: 12, color: '#787878' },
  placeHolderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
  },
  placeHolderText: { color: '#C7C9CD', fontWeight: 'bold', fontSize: 16 },
});
