import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { MembershipStatusCardFragment$key } from '../../__generated__/MembershipStatusCardFragment.graphql';
import { Flex } from '@ant-design/react-native';
import UserProfileImage from '../../components/UserProfileImage';
import LFText from '../../components/typo/LFText';
import dayjs from 'dayjs';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import _ from 'lodash';

const MembershipStatusCard: React.FC<{
  membershipStatusFrgmt: MembershipStatusCardFragment$key | null;
}> = ({ membershipStatusFrgmt }) => {
  const membershipStatus = useFragment(
    graphql`
      fragment MembershipStatusCardFragment on MembershipStatus {
        id
        objectId
        status
        expiredDate
        membership {
          id
          objectId
          title
          author {
            id
            objectId
            name
            ...UserProfileImageFragment
          }
        }
      }
    `,
    membershipStatusFrgmt
  );
  const membership = membershipStatus?.membership;
  const expiredDayjs = dayjs(membershipStatus?.expiredDate as string);
  const restDays = expiredDayjs.diff(dayjs(), 'days');
  const isExpired = membershipStatus?.status === 'expired';
  return (
    <Flex direction="column" align="start" style={styles.container}>
      <Flex direction="row" justify="between" style={{ alignSelf: 'stretch' }}>
        <Flex direction="row">
          <UserProfileImage size={40} userFrgmt={membership?.author || null} />
          <LFText>
            {membership?.author?.name} 님의{' '}
            <LFText style={{ fontWeight: 'bold' }}>{membership?.title} </LFText>
            멤버십
          </LFText>
        </Flex>
        <View style={styles.statusBadge}>
          <LFText>{isExpired ? '만료' : '이용중'}</LFText>
        </View>
      </Flex>
      <LFWhiteSpace />
      <LFText>
        유효기간 : {_.max([0, restDays])}일 남음 (
        {expiredDayjs.subtract(1, 'days').format('LL')}
        까지)
      </LFText>
    </Flex>
  );
};

export default MembershipStatusCard;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: 'white',
    borderRadius: 16,
  },
  statusBadge: {
    padding: 8,
    borderRadius: 8,
    borderColor: 'gray',
    borderWidth: 1,
  },
});
