import {
  WhiteSpace,
  Flex,
  Modal,
  ActivityIndicator,
} from '@ant-design/react-native';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
  useLayoutEffect,
  useCallback,
} from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  ViewStyle,
  useWindowDimensions,
  Linking,
  TouchableWithoutFeedback,
  Dimensions,
  TextInput,
} from 'react-native';
import ReactPlayer from 'react-player';
import { secToHHMMSS } from '../../components/TimeNote';
// import NavBar, { HistoryBackButton } from '../../components/NavBar';
import {
  fetchQuery,
  graphql,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from 'react-relay';
import {
  NotePlayerScreenContentItemMutation,
  UpdateContentItemInput,
} from '../../__generated__/NotePlayerScreenContentItemMutation.graphql';
import { NotePlayerScreenContentItemQuery } from '../../__generated__/NotePlayerScreenContentItemQuery.graphql';
import { Ionicons } from '@expo/vector-icons';
import { useAuth } from '../../hooks/auth';
import Hr from '../../components/Hr';
import { useActionSheet } from '@expo/react-native-action-sheet';
import ItemListForContent from './ItemListForContent';
import SuspenseFallbackScreen from '../SuspenseFallbackScreen';
import NoteInput, { NoteInputHandle } from './NoteInput';
import HyperLink from 'react-native-hyperlink';
import { NotePlayerScreenContentItemTitleMutation } from '../../__generated__/NotePlayerScreenContentItemTitleMutation.graphql';
import ShareLinkModalButton from '../../components/ShareLinkModalButton';
import ReactionButton from '../../components/ReactionButton';
import LFLink from '../../components/LFLink';
import LFMetaTags from '../../components/LFMetaTags';
import { useTrackRecentHistoryEffect } from '../../hooks/useTrackRecentHistoryEffect';
import { graphqlElementsToStringArray } from '../../helper/typeTransform';
import LFButton from '../../components/LFButton';
import { useLearningHistoryMutation } from '../../hooks/learningHistoryState';
import dayjs from 'dayjs';
import NavBar2, { MoreButton } from '../../components/NavBar2';
import LFText from '../../components/typo/LFText';
import InfoBoxNotPublishedContent from '../../components/InfoBoxNotPublishedContent';
import { StepType, TourProvider, useTour } from '@reactour/tour';
import { useViewCountEffect } from '../../hooks/viewCountEffect';
import ErrorScreen from '../ErrorScreen/ErrorScreen';
import SuggestionLogInSignUpModal from '../../components/SuggestionLogInSignUpModal';
import { usePathnameChangeCount } from '../../hooks/useLocalStorageCount';
import { NotePlayerScreenContentItemDeleteMutation } from '../../__generated__/NotePlayerScreenContentItemDeleteMutation.graphql';
import RecommendedContentsRelatedWithNote from '../../components/RecommendedContentsRelatedWithNote';
import { colors } from '../../constants/styleGuide';
import BookMarkButton from '../../components/BookMarkButton';
import { LoginScreenAccessAction } from '../LoginScreen';
import Comments, {
  CommentsLabel,
  CommentsList,
} from '../../components/Comment/Comments';
import { CommentWhereInput } from '../../__generated__/CommentsQuery.graphql';
import { CommentControls, CommentForm } from '../../components/Comment/Comment';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import {
  CreateCommentFieldsInput,
  NotePlayerScreenCommentMutation,
  NotePlayerScreenCommentMutationResponse,
} from '../../__generated__/NotePlayerScreenCommentMutation.graphql';
import { useUpdateEffect } from 'ahooks';
import {
  NotePlayerScreenDeleteCommentMutation,
  NotePlayerScreenDeleteCommentMutationResponse,
} from '../../__generated__/NotePlayerScreenDeleteCommentMutation.graphql';
import {
  NotePlayerScreenUpdateCommentMutation,
  NotePlayerScreenUpdateCommentMutationResponse,
  UpdateCommentFieldsInput,
} from '../../__generated__/NotePlayerScreenUpdateCommentMutation.graphql';
import MultilineGrowingTextInput from '../../components/MultilineGrowingTextInput';
import { NotePlayerScreenContentItemTitleAndMDMutation } from '../../__generated__/NotePlayerScreenContentItemTitleAndMDMutation.graphql';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import markdownToTxt from 'markdown-to-txt';

const useIsValidUrl = (url: string) => {
  const isValidUrl = useMemo(() => {
    try {
      new URL(url);
    } catch (e) {
      return false;
    }
    return true;
  }, [url]);
  return isValidUrl;
};

export interface AfterAction {
  type: 'submitNote';
  payload: {
    note: string;
    time: number;
  };
}

const VIDEO_RATIO = 9 / 16;
const NotePlayerScreenWithoutTour = () => {
  //도움돼요 영역 클릭 여부 상태 관리
  // screen & component size
  const windowDimensions = useWindowDimensions();
  //사이즈 레이아웃 관련
  const wrapperPadding = 0;

  const layoutMode =
    windowDimensions.width >= 1014 ? 'two-column' : 'one-column';
  const maxScrollWidth = layoutMode === 'two-column' ? 400 : undefined;
  const previewHeight =
    layoutMode === 'two-column'
      ? Math.floor((windowDimensions.width - 400) * VIDEO_RATIO)
      : windowDimensions.width * VIDEO_RATIO - wrapperPadding;
  ////
  const { showActionSheetWithOptions } = useActionSheet();
  // player 관련
  const playerRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(true);
  const [playerState, setPlayerState] = useState<{
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  } | null>(null);

  const [isEditMode, setIsEditMode] = useState(true);
  const [selectedNoteIdx, setSelectedNoteIdx] = useState<number>(-1);

  const noteInputRef = useRef<NoteInputHandle>(null);

  // router 관련
  const history = useHistory();
  const location =
    useLocation<{ afterAction: AfterAction; parentPathObjectId: string }>();
  const { pathname, search } = location;

  const afterAction = location?.state?.afterAction as AfterAction;
  const parentPathObjectId = location?.state?.parentPathObjectId;

  const url = new URLSearchParams(decodeURIComponent(search)).get('url') || '';
  const timeStampInQueryString = new URLSearchParams(search).get('timestamp');

  const [isVisibleContentItemModal, setIsVisibleContentItemModal] =
    useState(false);
  const [
    isVisibleMyContentItemSelectorModal,
    setIsVisibleMyContentItemSelectorModal,
  ] = useState(false);
  const { user } = useAuth();

  const { contentId, contentItemId } =
    useParams<{ contentId: string; contentItemId: string }>();
  // const [cntItemReqState, cntItemReqCmd] = useRequest((id) => {
  //   const q = new Parse.Query('ContentItem');
  //   q.include('author');
  //   q.include('content');
  //   return q.get(id).then((result) => result.toJSON());
  // });

  useTrackRecentHistoryEffect({
    targetFieldName: 'contentItem',
    targetClassObjectId: contentItemId,
  });

  useViewCountEffect({
    className: 'ContentItem',
    objectId: contentItemId,
  });
  useViewCountEffect({
    className: 'Content',
    objectId: contentId,
  });

  // const [learningHistory, { updateLearningHistory }] = useLearningHistoryState({
  //   targetFieldName: 'contentItem',
  //   targetClassObjectId: contentItemId,
  // });

  useEffect(() => {
    // effect
    if (afterAction && afterAction.type === 'submitNote') {
      onSubmitNote({
        time: afterAction.payload.time,
        note: afterAction.payload.note,
      });
    }
    return () => {
      // cleanup
    };
  }, [afterAction]);

  const {
    content,
    contentItem,
    contentItems: { edges: myContentItems },
    learningHistories,
  } = useLazyLoadQuery<NotePlayerScreenContentItemQuery>(
    graphql`
      query NotePlayerScreenContentItemQuery(
        $contentItemId: ID!
        $contentId: ID!
        $userObjectId: ID!
      ) {
        content(id: $contentId) {
          type
          thumbURL
          link
          title
          id
          objectId
          tags {
            ... on Element {
              value
            }
          }
        }
        contentItem(id: $contentItemId) {
          id
          objectId
          title
          published
          createdAt
          updatedAt
          content {
            type
            thumbURL
            link
            title
            tags {
              ... on Element {
                value
              }
            }
          }
          noteData {
            __typename
            ... on Element {
              __typename
              value
            }
          }
          author {
            objectId
            name
          }
          tags {
            ... on Element {
              value
            }
          }
          md
          ...RecommendedContentsRelatedWithNote_Fragment
        }
        contentItems(
          where: {
            AND: [
              { content: { have: { objectId: { equalTo: $contentId } } } }
              { author: { have: { objectId: { equalTo: $userObjectId } } } }
            ]
          }
        ) {
          edges {
            node {
              id
              objectId
              published
              title
              author {
                objectId
                name
              }
            }
          }
        }
        learningHistories(
          where: {
            author: { have: { objectId: { equalTo: $userObjectId } } }
            contentItem: { have: { objectId: { equalTo: $contentItemId } } }
          }
          first: 1
        ) {
          __id
          edges {
            node {
              id
              objectId
              status
              path {
                id
                objectId
              }
              contentItem {
                id
                objectId
              }
              createdAt
              updatedAt
            }
          }
        }
      }
    `,
    {
      contentItemId: contentItemId,
      contentId: contentId,
      userObjectId: user?.objectId || 'NOUSER',
    }
  );

  // 해당 컴포넌트의 자식 컴포넌트인 RecommendedContentsRelatedWithNote 컴포넌트는
  // 영상이 재생되는 도중에 1초 단위로 리렌더링 됩니다.
  // prop이 동일하면 리렌더링되지 않는 속성을 이용하여 리렌더링을 방지하고자
  // useMemo를 사용해서 contentItemFragment가 contentItemId가 동일하면 유지되도록 했습니다.
  const contentItemFragment = useMemo(() => contentItem, [contentItemId]);

  const learningHistory = _.first(learningHistories.edges)?.node;
  const [commitLearningHistory, isMutatingLearningHistory] =
    useLearningHistoryMutation({
      contentItemId: contentItemId,
      learningHistoryId: learningHistory?.objectId,
      connections: [learningHistories.__id],
      parentPathId: parentPathObjectId,
    });

  const [commitUpdateContentItem, isUpdateContentItemInFlight] =
    useMutation<NotePlayerScreenContentItemMutation>(graphql`
      mutation NotePlayerScreenContentItemMutation(
        $input: UpdateContentItemInput!
      ) @raw_response_type {
        updateContentItem(input: $input) {
          # clientMutationId
          contentItem {
            id
            noteData {
              ... on Element {
                value
              }
            }
            published
            author {
              id
              objectId
            }
          }
        }
      }
    `);

  const [
    commitUpdateContentItemTitleAndMD,
    isUpdateContentItemTitleAndMDInFlight,
  ] = useMutation<NotePlayerScreenContentItemTitleAndMDMutation>(graphql`
    mutation NotePlayerScreenContentItemTitleAndMDMutation(
      $input: UpdateContentItemInput!
    ) {
      updateContentItem(input: $input) {
        clientMutationId
        contentItem {
          id
          title
          author {
            id
            objectId
          }
          md
        }
      }
    }
  `);

  const [commitDeleteContentItem, isDeleteContentItemInFlight] =
    useMutation<NotePlayerScreenContentItemDeleteMutation>(graphql`
      mutation NotePlayerScreenContentItemDeleteMutation($id: ID!) {
        deleteContentItem(input: { id: $id }) {
          contentItem {
            id
            objectId
            title
          }
        }
      }
    `);

  const [commitCreateContentItem, isCreateContentItemInFight] =
    useMutation(graphql`
      mutation NotePlayerScreenCreateContentItemMutation(
        $inputFields: CreateContentItemFieldsInput!
      ) {
        createContentItem(input: { fields: $inputFields }) {
          clientMutationId
          contentItem {
            id
            objectId
            author {
              id
              objectId
            }
          }
        }
      }
    `);

  // 사용자 선택/입력 관련
  const [autoPlay, setAutoPlay] = useState(false);
  const scrollViewRef = useRef<ScrollView>(null);
  const [isNoteInputFocused, setIsNoteInputFocused] = useState(false);
  const [typedUrl, setTypedUrl] = useState(url);
  const initialTypedText =
    afterAction?.type === 'submitNote' ? afterAction?.payload?.note : '';
  const [typedText, setTypedText] = useState(initialTypedText);
  const isValidUrl = useIsValidUrl(url);
  // const [timeForNote, setTimeForNote] = useState<null | number>(null);

  const onFocusNoteInput = () => {
    setIsNoteInputFocused(true);
    // if (!currentNoteId) {
    //   onNewNote();
    // }
  };

  const onBlurNoteInput = () => {
    setIsNoteInputFocused(false);
    // setPlaying(true);
  };

  const onProgress = (state) => {
    setPlayerState(state);
  };

  const seekTo = (sec) => {
    playerRef.current?.seekTo(sec, 'seconds');
    if (autoPlay && !playing) {
      setPlaying(true);
    }
  };

  const onSubmitUrlEdit = () => {
    history.push({
      pathname,
      search: `?url=${typedUrl}`,
    });
  };

  const onSubmitNote = (noteSnippet?: { time: number; note: string }) => {
    // noteInputRef.current?.blur();

    console.log(noteSnippet);

    if (isMyContentItem) {
      if (contentItemId) {
        const value: UpdateContentItemInput = {
          id: contentItemId,
          fields: {
            noteData: _.sortBy(
              [..._.map(contentItem.noteData, (n) => n?.value), noteSnippet],
              'time'
            ),
          },
        };
        // const optimisticResponse: NotePlayerScreenContentItemMutationRawResponse =
        // {
        //   updateContentItem: {
        //     contentItem: {
        //       id: contentItem.id,
        //       noteData: [
        //         {
        //           id:'ㄴㄴ',
        //           value: 'sss',
        //           __isNode: true,
        //           __typename: 'Element',
        //         },
        //       ],
        //       // noteData: _.map(value.fields?.noteData, (v) => ({
        //       //   __typename: 'Element',
        //       //   value: v,
        //       // })),
        //     },
        //   },
        // };
        // console.log('###optimisticResponse', optimisticResponse);
        commitUpdateContentItem({
          variables: { input: value },
          onCompleted: (response) => {
            console.log(response);
            noteInputRef.current?.reset(noteSnippet?.note);

            history.replace(`/note/${contentId}/${contentItemId}`, {}); // for clear state, 새로 고침에서 afterAction 중복 생성 방지
            // history.push(`/item/${contentItemId}`);
          },
          onError: (error) => {
            console.log(error);
          },
          //optimisticUpdater는 동작 안함 // 일단 진행 중단
          // optimisticUpdater: (store) => {
          //   // Get the record for the Feedback object
          //   console.log('###update');
          //   const contentItem = store.get(contentItemId);

          //   // Read the current value for the like_count
          //   const currentNoteData = contentItem?.getValue('noteData');
          //   console.log(currentNoteData, currentNoteData);
          //   // Optimistically increment the like_count by 1
          //   // feedbackRecord.setValue((currentLikeCount ?? 0) + 1, 'like_count');

          //   // Optimistically set viewer_does_like to true
          //   // feedbackRecord.setValue(true, 'viewer_does_like');
          // },
          // optimisticResponse,
        });
      }
    } else {
      if (user) {
        if (myContentItems?.length === 0) {
          // 노트를 하나 생성하고
          // 생성한 노트로 push 하고 afterAction
          // alert(
          //   'TODO://[미구현 상황] 이 영상에 대한 나의 기존 노트가 없으니 하나 생성후 거기에 추가되도록 함'
          // );

          commitCreateContentItem({
            variables: {
              inputFields: {
                title: content.title,
                content: {
                  link: content.objectId,
                },
                tags: _.map(contentItem.tags, (tag) => tag?.value || ''),
              },
            },
            onCompleted: (response) => {
              console.log(response);
              if (response?.createContentItem?.contentItem?.objectId) {
                history.replace(
                  `/note/${contentId}/${response?.createContentItem?.contentItem?.objectId}`,
                  {
                    afterAction: {
                      type: 'submitNote',
                      payload: noteSnippet,
                    },
                  }
                );
              }
              // alert(1);
            },
            onError: (e) => {
              console.log(e);
            },
          });
          // } else if (myContentItems.length === 1) {
          //   history.push(
          //     `/note/${contentId}/${myContentItems[0]?.node?.objectId}`,
          //     {
          //       afterAction: {
          //         type: 'submitNote',
          //         payload: noteSnippet,
          //       },
          //     }
          //   );
        } else if (myContentItems.length >= 1) {
          // setIsVisibleMyContentItemSelectorModal(true);
          showActionSheetWithOptions(
            {
              title: '작성하신 내용이 추가될 노트를 선택해 주세요.',
              options: [
                ..._.map(
                  myContentItems,
                  (e) => e?.node?.title || '(제목 없음)'
                ),
                '새 노트에 추가',
                <Ionicons name="close-outline" size={24} color="black" />,
              ],
              cancelButtonIndex: myContentItems.length + 1,
            },
            (idx) => {
              if (idx < myContentItems.length) {
                // 기존 노트
                history.push(
                  `/note/${contentId}/${myContentItems[idx]?.node?.objectId}`,
                  {
                    afterAction: {
                      type: 'submitNote',
                      payload: noteSnippet,
                    },
                  }
                );
              } else if (idx == myContentItems.length) {
                // 새노트
                commitCreateContentItem({
                  variables: {
                    inputFields: {
                      title: content.title,
                      content: {
                        link: content.objectId,
                      },
                      tags: _.map(contentItem.tags, (tag) => tag?.value || ''),
                    },
                  },
                  onCompleted: (response) => {
                    console.log(response);
                    if (response?.createContentItem?.contentItem?.objectId) {
                      history.replace(
                        `/note/${contentId}/${response?.createContentItem?.contentItem?.objectId}`,
                        {
                          afterAction: {
                            type: 'submitNote',
                            payload: noteSnippet,
                          },
                        }
                      );
                    }
                    // alert(1);
                  },
                  onError: (e) => {
                    console.log(e);
                  },
                });
              }
            }
          );
          // alert(
          //   'TODO://[미구현 상황] 이 영상에 대한 내 노트가 여러개일 경우 여러개 중에 하나를 고르도록 하고 거기에 내용 추가'
          // );

          // 여러개 중에 하나를 고르도록 하고 고른 note로
          // 1. history push (w/ AfterAction)
        }
      } else {
        // 비 로그인
        // alert(
        //   'TODO://[미구현 상황] 로그인(회원가입) 화면으로 돌리고 로그인 상황과 동일하게 처리'
        // );
        history.push('/login', {
          from: {
            pathname,
          },
          accessAction: 'submitTimeStampOnNote' as LoginScreenAccessAction,
          afterAction: {
            type: 'submitNote',
            payload: noteSnippet,
          } as AfterAction,
        });
      }
    }
  };

  const isMyContentItem = user?.objectId === contentItem.author?.objectId;

  useEffect(() => {
    if (!isMyContentItem) {
      setSelectedNoteIdx(-1);
    }
  }, [isMyContentItem]);
  const isPressSendBtnPending =
    isCreateContentItemInFight || isUpdateContentItemInFlight;
  const reactPlayer = (
    <ReactPlayer
      onReady={() => {
        timeStampInQueryString && seekTo(timeStampInQueryString);
      }}
      muted
      ref={playerRef}
      url={content.link}
      width="100%"
      height={previewHeight}
      controls={true}
      onPlay={() => setPlaying(true)}
      onPause={() => setPlaying(false)}
      stopOnUnmount={true}
      playing={playing}
      onProgress={onProgress}
      progressInterval={250}
      // onReady={() => setPlaying(true)}
    />
  );
  //
  const navBar = (
    <NavBar2
      style={{ marginHorizontal: -16 }}
      right={
        <>
          <ShareLinkModalButton
            url={`${window.origin}${pathname}`}
            title=""
            style={{ padding: 8 }}
          />
          {isMyContentItem && (
            <MoreButton
              isLoading={isUpdateContentItemInFlight}
              options={[
                {
                  title: contentItem.published
                    ? '비공개로 변경'
                    : '공개로 변경',
                  onPress: () => {
                    commitUpdateContentItem({
                      variables: {
                        input: {
                          id: contentItemId,
                          fields: {
                            published: !contentItem.published,
                          },
                        },
                      },
                    });
                  },
                },

                {
                  title: '삭제',
                  onPress: () => {
                    Modal.alert(
                      '선택하신 학습노트 1개를 정말로 삭제하시겠습니까?',
                      '',
                      [
                        {
                          text: '취소',
                          onPress: () => {},
                          style: 'cancel',
                        },

                        {
                          text: '삭제',
                          onPress: () =>
                            commitDeleteContentItem({
                              variables: {
                                id: contentItemId,
                              },
                              onCompleted: () => {
                                history.goBack();
                              },
                            }),
                          style: 'destructive',
                        },
                      ]
                    );
                  },
                },
              ]}
              destructiveButtonIndex={1}
            />
          )}
        </>
      }
    />
  );
  const { isOpen, setIsOpen } = useTour();
  useEffect(() => {
    setTimeout(() => {
      // setIsOpen(true);
    }, 500);
  }, []);

  const [visitCount, setVisitCount] = usePathnameChangeCount({
    key: 'TraversalAndNoteplayer',
  });

  const resetVisitCount = () => {
    setVisitCount(0);
  };

  const [showSuggestionLogInSignUpModal, setShowSuggestionLogInSignUpModal] =
    useState(visitCount >= 2);

  useEffect(() => {
    if (visitCount && user) resetVisitCount();
    if (visitCount >= 2 && !user) setShowSuggestionLogInSignUpModal(true);
  }, [visitCount]);

  const bookMarkButtonStyle = useMemo(() => ({ padding: 8 }), []);

  const CommentsQuery = require('../../__generated__/CommentsQuery.graphql.ts');
  const environment = useRelayEnvironment();
  const [refreshedQueryOptions, setRefreshedCommentsQueryOptions] = useState<
    Parameters<typeof useLazyLoadQuery>[2] | null
  >(null);
  const [isCommentsRefreshing, setIsCommentsRefreshing] = useState(false);

  const commentsRefresh = useCallback(() => {
    if (isCommentsRefreshing) {
      return;
    }
    setIsCommentsRefreshing(true);
    fetchQuery(environment, CommentsQuery, { where: commentsWhere }).subscribe({
      complete: () => {
        setIsCommentsRefreshing(false);
        setRefreshedCommentsQueryOptions((prev) => ({
          fetchKey: ((prev?.fetchKey ?? 0) as number) + 1,
          fetchPolicy: 'store-only',
        }));
      },
      error: () => {
        setIsCommentsRefreshing(false);
      },
    });
  }, [contentItem?.objectId]);

  useUpdateEffect(() => {
    commentsRefresh();
  }, [contentItem?.objectId]);

  const commentsWhere = useMemo<CommentWhereInput>(
    () => ({
      targetClassName: {
        equalTo: 'ContentItem',
      },
      contentItem: {
        have: {
          objectId: {
            equalTo: contentItem?.objectId || '',
          },
        },
      },
      parent: {
        haveNot: {
          objectId: {
            exists: true,
          },
        },
      },
    }),
    [contentItem?.objectId || '']
  );

  const onCommentFormFocus = () => {
    !user &&
      history.push({
        pathname: '/login',
        state: {
          from: location,
          accessAction: 'tryWritingComment' as LoginScreenAccessAction,
        },
      });
  };

  const onPressCommentAvatar = (userId: string) => {
    history.push({ pathname: `/u/${userId}` });
  };

  const [newCommentText, setNewCommentText] = useState('');

  const validateCommentText = (text: string) => {
    return newCommentText.trim().length !== 0;
  };

  const [commitCreateComment, isCommitCreateCommentInFlight] =
    useMutation<NotePlayerScreenCommentMutation>(graphql`
      mutation NotePlayerScreenCommentMutation(
        $fields: CreateCommentFieldsInput!
        $clientMutationId: String!
      ) {
        createComment(
          input: { fields: $fields, clientMutationId: $clientMutationId }
        ) {
          clientMutationId
          comment {
            id
            objectId
            text
            author {
              id
              objectId
            }
            contentItem {
              id
              objectId
            }
            targetClassName
          }
        }
      }
    `);

  const createComment = ({ fields }: { fields: CreateCommentFieldsInput }) =>
    new Promise<NotePlayerScreenCommentMutationResponse>(
      (onSuccess, onFail) => {
        commitCreateComment({
          variables: {
            clientMutationId: 'commentCreateId',
            fields,
          },
          onCompleted: (res, rej) => {
            console.log(res);
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: (error) => {
            onFail(error);
          },
        });
      }
    );
  const [commitUpdateComment, isCommitUpdateCommentInFlight] =
    useMutation<NotePlayerScreenUpdateCommentMutation>(
      graphql`
        mutation NotePlayerScreenUpdateCommentMutation(
          $objectId: ID!
          $fields: UpdateCommentFieldsInput!
        ) {
          updateComment(input: { id: $objectId, fields: $fields }) {
            clientMutationId
            comment {
              id
              objectId
              text
            }
          }
        }
      `
    );

  const updateComment = (id: string, fields: UpdateCommentFieldsInput) =>
    new Promise<NotePlayerScreenUpdateCommentMutationResponse>(
      (onSuccess, onFail) => {
        commitUpdateComment({
          variables: {
            objectId: id,
            fields,
          },
          onCompleted: (res, rej) => {
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: onFail,
        });
      }
    );

  const [commitDeleteComment, isCommitDeleteCommentInFlight] =
    useMutation<NotePlayerScreenDeleteCommentMutation>(
      graphql`
        mutation NotePlayerScreenDeleteCommentMutation($id: ID!) {
          deleteComment(input: { id: $id }) {
            comment {
              id
              objectId
            }
          }
        }
      `
    );

  const deleteComment = (id: string) =>
    new Promise<NotePlayerScreenDeleteCommentMutationResponse>(
      (onSuccess, onFail) =>
        commitDeleteComment({
          variables: {
            id,
          },
          onCompleted: (res, rej) => {
            if (res) {
              onSuccess(res);
              return;
            }
            onFail(rej);
          },
          onError: onFail,
        })
    );

  const ldJson = useMemo(
    () => ({
      headline: contentItem?.title || '',
      dateModified: dayjs(contentItem.updatedAt as Date).format(),
      datePublished: dayjs(contentItem.createdAt as Date).format(),
    }),
    [contentItemId]
  );

  const tags = useMemo(
    () => graphqlElementsToStringArray(contentItem.tags),
    [contentItemId]
  );

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [noteTitleAndMD, setNoteTitleAndMD] = useState({
    title: contentItem.title,
    md: contentItem.md,
  });
  const contentItemDescription = contentItem.md
    ? markdownToTxt(contentItem.md).replaceAll(`\n`, '')
    : `${contentItem.author?.name} 님의 "${contentItem.content?.title}"에 대한 학습 노트`;

  return (
    <Flex direction="row" align="stretch">
      {showSuggestionLogInSignUpModal && (
        <SuggestionLogInSignUpModal
          visible={showSuggestionLogInSignUpModal}
          noAgainId={'content-login-signup-suggestion-v1'}
          onClose={() => {
            setShowSuggestionLogInSignUpModal(false);
            resetVisitCount();
          }}
          scrollable={false}
        />
      )}
      <LFMetaTags
        type="article"
        title={`${contentItem.title} | 학습노트 - Learnfit`}
        description={contentItemDescription}
        authorName={contentItem.author?.name}
        authorId={contentItem.author?.objectId}
        objectId={contentItem.objectId}
        tags={tags}
        ldJson={ldJson}
        image={contentItem.content?.thumbURL}
      />
      {layoutMode === 'two-column' ? (
        <>
          {/* {navBar} */}
          {reactPlayer}
        </>
      ) : undefined}
      <ScrollView
        onLayout={(_) => {
          if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ y: 0, animated: false });
          }
        }}
        ref={scrollViewRef}
        onLayout={() => {
          if (scrollViewRef.current) {
            scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: false });
          }
        }}
        bounces={false}
        style={[
          styles.container,
          {
            height: windowDimensions.height,
            minWidth: layoutMode === 'two-column' ? 400 : undefined,
            maxWidth: layoutMode === 'two-column' ? 400 : undefined,
          },
        ]}
        contentContainerStyle={{ paddingHorizontal: 16 }}
      >
        {navBar}
        {layoutMode === 'one-column' && (
          <>
            <View
              style={{
                position: isOpen ? undefined : 'sticky',
                top: 0,
                zIndex: 10,
                marginHorizontal: -16,
              }}
            >
              {reactPlayer}
            </View>
            {!isNoteInputFocused && (
              <>
                <WhiteSpace />
                <LFText style={styles.sourceText} numberOfLines={1}>
                  <Ionicons name="logo-youtube" size={14} />
                  {' YouTube, '}
                  {contentItem.content.title}
                </LFText>
              </>
            )}
          </>
        )}
        <NoteInput
          style={{
            position: 'sticky',
            top: layoutMode === 'two-column' || isOpen ? 0 : previewHeight,
            // bottom: layoutMode === 'one-column' ? undefined : 0,
            zIndex: 10,
            backgroundColor: 'white',
            display: selectedNoteIdx > -1 ? 'flex' : 'none',
          }}
          editSource={
            contentItem.noteData && selectedNoteIdx > -1
              ? contentItem.noteData[selectedNoteIdx]?.value
              : undefined
          }
          playedSeconds={Math.floor(playerState?.playedSeconds || 0)}
          duration={playerRef.current?.getDuration() || 0}
          isPendingSubmit={isPressSendBtnPending}
          onSubmitEditing={(data: any) => {
            // 수정
            // onSubmitNote(data);
            const resultNoteData = [...contentItem.noteData];
            resultNoteData[selectedNoteIdx] = {
              value: data,
            };
            commitUpdateContentItem({
              variables: {
                input: {
                  id: contentItem.objectId,
                  fields: {
                    noteData: _.sortBy(
                      _.map(resultNoteData, (x) => x.value),
                      'time'
                    ), //나중에는 서버에서 저장시 정렬하도록 //TODO://
                  },
                } as UpdateContentItemInput,
              },
              onCompleted: (response) => {
                // console.log(response);
                setSelectedNoteIdx(-1);
              },
              onError: (error) => {
                console.log(error);
              },
            });
          }}
          onPressTime={(newTime) => {
            playerRef.current?.seekTo(newTime);
            // if (!playing) setPlaying(true);
          }}
          onPressDelete={() => {
            const resultNoteData = [...contentItem.noteData];
            resultNoteData.splice(selectedNoteIdx, 1); //Imperative
            commitUpdateContentItem({
              variables: {
                input: {
                  id: contentItem.objectId,
                  fields: {
                    noteData: _.map(resultNoteData, (x) => x.value),
                  },
                } as UpdateContentItemInput,
              },
              onCompleted: (response) => {
                // console.log(response);
                setSelectedNoteIdx(-1);
              },
              onError: (error) => {
                console.log(error);
              },
            });
          }}
          onPressCancelEdit={() => {
            setSelectedNoteIdx(-1);
          }}
        />
        <NoteInput
          dataSet={{ tour: 'step-4' }}
          ref={noteInputRef}
          style={{
            position: isOpen ? undefined : 'sticky',
            top: layoutMode === 'two-column' || isOpen ? 0 : previewHeight,
            // bottom: layoutMode === 'one-column' ? undefined : 0,
            zIndex: 10,
            backgroundColor: 'white',
            display: selectedNoteIdx > -1 ? 'none' : 'flex',
          }}
          playedSeconds={Math.floor(playerState?.playedSeconds || 0)}
          duration={playerRef.current?.getDuration() || 0}
          onFocus={onFocusNoteInput}
          onBlur={onBlurNoteInput}
          isPendingSubmit={isPressSendBtnPending}
          onSubmitEditing={(data: any) => onSubmitNote(data)}
          onPressTime={(newTime) => {
            playerRef.current?.seekTo(newTime);
            // if (!playing) setPlaying(true);
          }}
        />
        {learningHistory ? (
          <>
            <WhiteSpace size="lg" />
            <Flex
              justify="center"
              align="center"
              style={{
                borderRadius: 8,
                backgroundColor: '#F0F1F3',
                padding: 8,
                alignSelf: 'flex-start',
              }}
            >
              <LFText style={{ fontSize: 14, fontWeight: '700' }}>
                ✔ 학습 완료{' '}
                <LFText style={{ fontSize: 12, fontWeight: '400' }}>
                  ({dayjs(learningHistory.updatedAt).fromNow()})
                </LFText>
              </LFText>
            </Flex>
          </>
        ) : null}

        <Flex justify="between">
          <View>
            <Flex direction="row">
              <LFText style={{ fontSize: 14 }}>
                <LFLink
                  testID="curator-profile-link:NotePlayerScreen"
                  onPress={() => {
                    history.push(`/u/${contentItem?.author?.objectId}`);
                  }}
                >
                  <LFText style={{ fontWeight: '500' }}>
                    {isMyContentItem ? '나' : contentItem?.author?.name}
                  </LFText>
                </LFLink>
                <LFText style={{ color: '#888' }}>
                  {isMyContentItem ? '' : '님'}의 학습노트
                </LFText>
              </LFText>
              <LFLink
                testID="another-content-item-of-this-video-btn:NotePlayerScreen"
                onPress={() => {
                  setIsVisibleContentItemModal(true);
                  // showActionSheetWithOptions(
                  //   {
                  //     title: '이 영상의 다른 학습노트',
                  //     options: [
                  //       ..._.map(
                  //         contentItems.edges,
                  //         (e) => e?.node?.author?.name + ' ' + e?.node?.title
                  //       ),
                  //       '닫기',
                  //     ],
                  //     cancelButtonIndex: contentItems.edges.length,
                  //   },
                  //   (idx) => {
                  //     location.replace('/');
                  //   }
                  // );
                }}
              >
                <Ionicons
                  dataSet={{ tour: 'step-3' }}
                  name="chevron-down-sharp"
                  size={18}
                  color="black"
                  style={{ padding: 20, paddingLeft: 5 }}
                />
              </LFLink>
            </Flex>
          </View>
          <Flex>
            <ReactionButton
              dataSet={{ tour: 'step-2' }}
              type="👍"
              targetClassName="ContentItem"
              targetId={contentItem.objectId}
            />
            <BookMarkButton
              targetClassName="ContentItem"
              targetId={contentItem.objectId}
              style={bookMarkButtonStyle}
            />
          </Flex>
        </Flex>

        <LFText
          // onChangeText={(text: any) => setHashTagTxt(text)}
          style={styles.title}
          selectable
          accessibilityRole="heading"
        >
          {contentItem.title}
          {isMyContentItem ? (
            <Ionicons
              testID="content-item-change-title-btn:NotePlayerScreen"
              name="pencil"
              color="black"
              size={18}
              style={{ marginLeft: 10 }}
              selectable={false}
              onPress={() => setIsEditModalOpen(true)}
            />
          ) : null}
        </LFText>
        <InfoBoxNotPublishedContent
          onPublishButtonPress={(status) =>
            commitUpdateContentItem({
              variables: {
                input: {
                  id: contentItemId,
                  fields: {
                    published: status,
                  },
                },
              },
            })
          }
          published={contentItem.published}
        />
        <ScrollView
          horizontal
          contentContainerStyle={{ flexDirection: 'row' }}
          showsHorizontalScrollIndicator={false}
        >
          {_.map(contentItem?.tags, ({ value }) => (
            <LFText key={value} style={[styles.hashTagText, { marginLeft: 5 }]}>
              #{value}
            </LFText>
          ))}
        </ScrollView>

        <WhiteSpace />
        {/* <WhiteSpace /> */}
        {contentItem.md && (
          <>
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
              {contentItem.md || ''}
            </ReactMarkdown>
            <WhiteSpace />
          </>
        )}
        {/* <Flex direction="row" justify="end" style={{ paddingHorizontal: 16 }}>
            <Checkbox
              checked={autoPlay}
              onChange={(e) => setAutoPlay(e.target.checked)}
            >
              이동 시 자동 재생
            </Checkbox>
          </Flex> */}
        {/* <LFText style={{ color: '#333', fontSize: 13 }}> ℹ️ PC의 경우 n 키를 누르면 입력창이 focus 됩니다.</LFText> */}
        {/* <WhiteSpace /> */}
        {/* <LFText>{playing ? 'play!!' : 'no!'}</LFText>
          <LFText>{JSON.stringify(currentNote)}</LFText>
          <LFText>{JSON.stringify(playerState)}</LFText> */}
        <View style={{ marginVertical: -2 }}>
          {_.map(contentItem.noteData, ({ value: note }, idx) => {
            const first = idx === 0;
            return (
              <NoteItemView
                dataSet={first ? { tour: 'step-1' } : undefined}
                key={'' + note.time + note.note + idx}
                style={{
                  marginVertical: 2,
                }}
                // status={
                //   note.time <= playerState?.playedSeconds ? 'played' : 'none'
                // }
                // selected={currentNoteId === note.id}
                // key={idx}
                selected={idx === selectedNoteIdx}
                onPressTime={() => {
                  seekTo(note.time);
                  history.replace(
                    `/note/${contentId}/${contentItemId}?timestamp=${note.time}`
                  );
                }}
                {...note}
                onPress={() => {
                  isMyContentItem && setSelectedNoteIdx(idx);
                }}
                // onPressNote={() => {
                //   onSelectNote(note);
                // }}
                // editable={false}
                // onPressDeleteButton={() => {
                //   onDeleteNote(note);
                // }}
              />
            );
          })}
        </View>
        {learningHistory ? null : (
          <>
            <WhiteSpace size="xl" />
            <LFButton
              testID="content-item-done:NotePlayerScreen"
              // @ts-ignore
              dataSet={{ tour: 'step-5' }}
              onPress={() => {
                commitLearningHistory('done').then(() => {
                  // alert('완료 처리 성공');
                });
              }}
              isLoading={isMutatingLearningHistory}
              onlyLoginUser={true}
            >
              ✔ 학습 완료
            </LFButton>
          </>
        )}
        {/* <View
            style={[
              styles.inputInnerWrap,
              // { position: 'sticky', bottom: 0, zIndex: 10 },
            ]}
          >
            <TextInput
              ref={noteInputRef}
              style={styles.textInput}
              value={typedText}
              // onChangeText={setTypedText}
              // onFocus={onFocusNoteInput}
              // onBlur={onBlurNoteInput}
              //  onSubmitEditing={onSubmitNote}
            />
            <Button
              type="ghost"
              // disabled={!noteText}
              style={styles.noteInputButton}
              // onPress={onSubmitNote}
            >
              전송
            </Button>
          </View> */}
        <WhiteSpace size="xl" />
        <WhiteSpace size="xl" />

        <View style={styles.recommndedContentsAndCommentsContainer}>
          <LFWhiteSpace size={32} />
          <Comments
            where={commentsWhere}
            style={{ paddingHorizontal: 16 }}
            refreshedQueryOptions={refreshedQueryOptions || {}}
          >
            <CommentsLabel />
            <LFWhiteSpace size="sm" />
            <CommentForm
              value={newCommentText}
              placeholder="댓글을 남겨주세요!"
              onFocus={onCommentFormFocus}
              onChangeText={setNewCommentText}
              controls={
                <CommentControls
                  onPressCancelButton={() => {
                    setNewCommentText('');
                  }}
                  disabled={
                    !validateCommentText(newCommentText) ||
                    isCommitCreateCommentInFlight
                  }
                  submitButtonStyle={
                    validateCommentText(newCommentText)
                      ? {
                          backgroundColor: '#236EFF1A',
                        }
                      : {}
                  }
                  onPressSubbmitButton={() => {
                    const fields = {
                      text: newCommentText.trim(),
                      contentItem: {
                        link: contentItem.objectId,
                      },
                      targetClassName: 'ContentItem',
                    };
                    createComment({ fields }).then((_) => {
                      setNewCommentText('');
                      commentsRefresh();
                    });
                  }}
                  submitButtonTextStyle={
                    validateCommentText(newCommentText)
                      ? {
                          color: '#236EFF',
                          fontWeight: '600',
                        }
                      : {}
                  }
                />
              }
            />
            <LFWhiteSpace size="xl" />
            <CommentsList
              onCreate={createComment}
              onDelete={deleteComment}
              onEdit={updateComment}
              afterMutation={commentsRefresh}
              contentAuthorId={contentItem.author?.objectId || ''}
              targetClassName="contentItem"
              onFocus={onCommentFormFocus}
              onPressAvatar={onPressCommentAvatar}
            />
          </Comments>

          <Suspense fallback={<ActivityIndicator />}>
            {/* 입력한 학습노트와 관련된 러닝패스, 학습노트를 추천해주는 컴포넌트 */}
            <RecommendedContentsRelatedWithNote
              contentItemFragment={contentItemFragment}
            />
          </Suspense>
        </View>
        {/* <PathListOfContentItem contentItemId={contentItemId} />
        <WhiteSpace size="xl" />
        <PathListOfContent contentId={content.objectId} />
        <WhiteSpace size="xl" /> */}
        <Modal
          popup
          visible={isVisibleContentItemModal}
          animationType="slide-up"
          onClose={() => {
            setIsVisibleContentItemModal(false);
          }}
        >
          <ModalWrapWithHeader
            title="이 영상에 대한 다른 학습노트를 살펴보세요."
            onClose={() => setIsVisibleContentItemModal(false)}
          >
            <ItemListForContent
              contentId={contentId}
              currentContentItemId={contentItemId}
              onPress={(contentItemId) => {
                history.replace(`/note/${contentId}/${contentItemId}`);
                setIsVisibleContentItemModal(false);
              }}
            />
          </ModalWrapWithHeader>
        </Modal>
        <Modal
          title="수정"
          transparent
          maskClosable
          onClose={() => setIsEditModalOpen(false)}
          visible={isEditModalOpen}
          footer={[
            {
              text: '취소',
              onPress: () => {
                setNoteTitleAndMD({
                  title: contentItem.title,
                  md: contentItem.md,
                });
              },
            },
            {
              text: '확인',
              onPress: () => {
                commitUpdateContentItemTitleAndMD({
                  variables: {
                    input: {
                      id: contentItem.objectId,
                      fields: noteTitleAndMD,
                    },
                  },
                  onCompleted: (res) => {
                    console.log(res);
                  },
                });
              },
            },
          ]}
        >
          <ScrollView
            style={{ maxHeight: Dimensions.get('screen').height - 300 }}
          >
            <LFText style={styles.editModalInputLabel}>제목</LFText>
            <LFWhiteSpace size={'xs'} />
            <TextInput
              style={styles.editModalForm}
              value={noteTitleAndMD.title || undefined}
              onChangeText={(text) =>
                setNoteTitleAndMD((prev) => ({ ...prev, title: text }))
              }
            />
            <LFWhiteSpace />
            <LFText style={styles.editModalInputLabel}>설명</LFText>
            <LFWhiteSpace size={'xs'} />
            <MultilineGrowingTextInput
              style={[styles.editModalForm, { minHeight: 200 }]}
              value={noteTitleAndMD.md || undefined}
              onChangeText={(text) =>
                setNoteTitleAndMD((prev) => ({ ...prev, md: text }))
              }
            />
          </ScrollView>
        </Modal>
        {/* <Modal
        popup
        visible={isVisibleMyContentItemSelectorModal}
        animationType="slide-up"
        onClose={() => {
          setIsVisibleMyContentItemSelectorModal(false);
        }}
      >
        <ModalWrapWithHeader
          title="작성하신 내용이 추가될 노트를 선택해 주세요."
          onClose={() => setIsVisibleMyContentItemSelectorModal(false)}
        >
          <ItemListForContent
            myContentItemSelectorMode
            myContentItemTitle="이 영상에 대한 나의 노트"
            contentId={contentId}
            currentContentItemId={contentItemId}
            onPress={(contentItemId) => {
              // history.replace(`/note/${contentId}/${contentItemId}`);
              // setIsVisibleContentItemModal(false);
            }}
          />
        </ModalWrapWithHeader>
      </Modal> */}
      </ScrollView>
    </Flex>
  );
};

const PathListOfContentItem = ({ contentItemId }) => {
  return (
    <View>
      <LFText style={{ fontSize: 14 }}>이 노트가 포함된 Path</LFText>
      <LFText>...(준비중)</LFText>
    </View>
  );
};
const PathListOfContent = ({ contentId }) => {
  return (
    <View>
      <LFText style={{ fontSize: 14 }}>이 영상이 포함된 Path</LFText>
      <LFText>...(준비중)</LFText>
    </View>
  );
};

const ModalWrapWithHeader: React.FC<{ title: string; onClose: () => void }> = ({
  title,
  children,
  onClose,
}) => {
  return (
    <Flex
      align="stretch"
      justify="start"
      direction="column"
      style={{ height: 300 }}
    >
      <Flex direction="row" style={{ padding: 16 }}>
        <Flex style={{ flex: 1 }}>
          <LFText style={{ fontWeight: '500', fontSize: 16 }}>{title}</LFText>
        </Flex>
        <TouchableOpacity
          onPress={() => {
            onClose();
          }}
        >
          <Ionicons name="close-outline" size={24} color="black" />
        </TouchableOpacity>
      </Flex>
      <Hr />
      <Suspense fallback={<SuspenseFallbackScreen style={{ flex: 1 }} />}>
        {children}
      </Suspense>
    </Flex>
  );
};

const NoteItemView: React.FC<{
  dataSet?: { [key: string]: string };
  time: number;
  note: string;
  style?: ViewStyle;
  selected: boolean;
  onPressTime?: (time?: number) => void;
  onPress?: () => void;
}> = ({ time, note, style, onPressTime, onPress, selected, dataSet }) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View
        style={[
          {
            // shadowOffset: { width: 4, height: 10 },
            // shadowOpacity: 0.05,
            // shadowColor: '#000',
            // shadowRadius: 16,
            borderBottomColor: '#eee',
            borderBottomWidth: 1,
            paddingVertical: 16,
            backgroundColor: selected ? '#ccc' : 'white',
            // borderRadius: 16,
            marginHorizontal: -16,
            paddingHorizontal: 16,
          },
          style,
        ]}
      >
        <HyperLink
          onPress={(url, text) =>
            Linking.canOpenURL(url)
              .then(() => {
                window.open(url, '_blank')?.focus();
              })
              .catch(() => {
                console.log('열 수 없는 URL');
              })
          }
          linkStyle={{ color: '#2F80ED' }}
        >
          <LFText accessibilityRole="paragraph">
            <LFLink
              testID="timestamp:NoteInput"
              onPress={() => onPressTime && onPressTime(time)}
            >
              <LFText
                // @ts-ignore
                dataSet={dataSet}
                style={{
                  color: '#295ECC',
                  fontWeight: 'bold',
                  marginRight: 10,
                }}
              >
                {secToHHMMSS(time)}
              </LFText>
            </LFLink>

            <LFText style={{}} selectable>
              {note}
            </LFText>
          </LFText>
        </HyperLink>
      </View>
    </TouchableWithoutFeedback>
  );
};

const NoteTimeButton: React.FC<any> = ({ onPress, children }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 3,
        height: 30,
        paddingHorizontal: 7,
        justifyContent: 'center',
      }}
    >
      {typeof children === 'string' ? (
        <LFText style={{ fontFamily: 'space-mono' }}>{children}</LFText>
      ) : (
        children
      )}
    </TouchableOpacity>
  );
};

export default function () {
  return (
    <TourProvider steps={steps} scrollSmooth>
      <NotePlayerScreenWithoutTour />
    </TourProvider>
  );
}

const steps: StepType[] = [
  {
    selector: '[data-tour="step-1"]',
    content: <LFText>시간을 터치하면 플레이어가 이동해요.</LFText>,
  },
  {
    selector: '[data-tour="step-2"]',
    content: <LFText>{`하트를 눌러 작성자에게 고마움을\n전달해요.`}</LFText>,
  },
  {
    selector: '[data-tour="step-3"]',
    content: (
      <LFText>{`같은 영상으로 만든 다른 노트가\n있는지 찾아봐요.`}</LFText>
    ),
  },
  {
    selector: '[data-tour="step-4"]',
    content: (
      <LFText>{`여기에 입력하면 같은 영상으로\n내 노트가 만들어져요.`}</LFText>
    ),
  },
  {
    selector: '[data-tour="step-5"]',
    content: (
      <LFText>{`학습이 끝나면 완료 버튼을 눌러\n마이페이지에 보관해요!`}</LFText>
    ),
  },
];

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
  },
  linkInput: {
    flex: 1,
    height: 33,
    borderBottomWidth: 1,
    marginRight: 10,
    borderBottomColor: '#D1D1D1',
  },
  title: {
    fontSize: 18,
    fontWeight: '500',
  },
  sourceText: {
    fontSize: 14,
    color: '#999',
  },
  hashTagText: {
    fontSize: 14,
    color: '#2F80ED',
  },
  scrollContainerStyle: {
    paddingBottom: 20,
    // paddingHorizontal: 16
  },

  noteInputButton: {
    marginLeft: 10,
    height: '100%',
  },
  noteTimeBtn: {
    height: 30,
    padding: 5,
    fontSize: 14,
  },
  recommndedContentsAndCommentsContainer: {
    marginHorizontal: -16,
    zIndex: 1000,
    backgroundColor: colors.BG_WHITE,
    borderTopWidth: 1,
    borderTopColor: colors.BG_4,
  },
  commentFormButtonContainer: { marginLeft: 'auto', marginTop: 8 },
  commentFormButton: {
    borderRadius: 8,
    backgroundColor: '#F0F1F6',
    paddingVertical: 8,
    paddingHorizontal: 24,
  },
  commentFormButtonCancelText: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    textAlign: 'center',
    color: '#1A1B1E',
  },
  commentFormButtonSubmitText: {
    fontSize: 14,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    color: '#1A1B1E4D',
  },
  editModalForm: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderColor: 'rgba(26, 27, 30, 0.1)',
    borderWidth: 1,
    borderRadius: 12,
    minHeight: 50,
  },
  editModalInputLabel: {
    fontSize: 16,
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: 24,
    color: '#1A1B1E',
  },
});
