import React from 'react';
import {
  View,
  StyleSheet,
  SectionList,
  TouchableOpacity,
  RefreshControl,
  ActivityIndicator,
} from 'react-native';
import {
  useLazyLoadQuery,
  graphql,
  useMutation,
  usePaginationFragment,
} from 'react-relay';
import { useAuth } from '../hooks/auth';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import NoItemFromProfileMenuView from '../components/NoItemFromProfileMenuView';
import { UpdatePathFieldsInput } from '../__generated__/MyPathScreenPathUpdateMutation.graphql';
import { DeletePathInput } from '../__generated__/MyPathScreenPathDeleteMutation.graphql';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { MyPathScreenMainPathQuery } from '../__generated__/MyPathScreenMainPathQuery.graphql';
import { MyPathsPaginationQuery } from '../__generated__/MyPathsPaginationQuery.graphql';
import { MyPathScreenPathPaginationQuery$key } from '../__generated__/MyPathScreenPathPaginationQuery.graphql';
import ListItemPath from '../components/ListItemPath';
import LFLink from '../components/LFLink';
import NavBar2 from '../components/NavBar2';

const MyPathScreen = () => {
  const history = useHistory();
  const { user } = useAuth();
  const currentUserObjectId = user?.objectId;
  const { showActionSheetWithOptions } = useActionSheet();

  // path 공개 비공개 mutation
  const [commitUpdatePathItem, isUpdatePathItemInFlight] = useMutation(
    graphql`
      mutation MyPathScreenPathUpdateMutation($input: UpdatePathInput!) {
        updatePath(input: $input) {
          path {
            id
            objectId
            published
          }
        }
      }
    `
  );
  // path 삭제 mutation

  const [commitDeletePathItem, isDeletePathItem] = useMutation(
    graphql`
      mutation MyPathScreenPathDeleteMutation(
        $input: DeletePathInput!
        $connections: [String!]!
      ) {
        deletePath(input: $input) {
          path {
            id @deleteEdge(connections: $connections)
            objectId
          }
        }
      }
    `
  );

  const pathsRef = useLazyLoadQuery<MyPathScreenMainPathQuery>(
    graphql`
      query MyPathScreenMainPathQuery(
        $count: Int
        $after: String
        $userObjectId: ID
      ) {
        ...MyPathScreenPathPaginationQuery
      }
    `,
    { count: 10, userObjectId: currentUserObjectId },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const pathPagination = usePaginationFragment<
    MyPathsPaginationQuery,
    MyPathScreenPathPaginationQuery$key
  >(
    graphql`
      fragment MyPathScreenPathPaginationQuery on Query
      @refetchable(queryName: "MyPathsPaginationQuery") {
        paths(
          first: $count
          after: $after
          where: { author: { have: { objectId: { equalTo: $userObjectId } } } }
          order: updatedAt_DESC
        ) @connection(key: "Path_paths") {
          __id
          edges {
            node {
              __typename
              id
              objectId
              title
              updatedAt
              published
              ...ListItemPath_Fragment
            }
          }
        }
      }
    `,
    pathsRef
  );

  const myPathSection = pathPagination.data?.paths.edges?.length
    ? [
        {
          title: '러닝패스',
          data: _.chunk(
            _.map(pathPagination.data?.paths.edges, (e) => ({
              ...e?.node,
              __typename: 'Path',
            })),
            2
          ),
        },
      ]
    : [];
  const sections = myPathSection;

  return (
    <View style={styles.container}>
      <NavBar2 title="내 러닝패스" right={<></>} />
      <WhiteSpace size="lg" />
      <SectionList
        ListEmptyComponent={
          <NoItemFromProfileMenuView
            title="내가 만든 러닝패스가 없어요."
            description="나의 커리큘럼이 누군가에겐 큰 도움이 돼요."
            onPress={() => {
              history.push({ pathname: '/path-edit' });
            }}
            btnText="러닝패스 만들기"
          />
        }
        contentContainerStyle={{ paddingHorizontal: 16, flexGrow: 1 }}
        keyExtractor={(item) => {
          return _.reduce(
            _.castArray(item),
            (sum, item) => {
              return sum + item.objectId;
            },
            ''
          );
        }}
        sections={sections}
        renderItem={(info) => {
          return (
            <Flex
              direction="row"
              align="stretch"
              style={{
                marginHorizontal: -8,
                marginBottom: 16,
              }}
            >
              {_.map(_.castArray(info.item), (item) => {
                if (!item.objectId) {
                  console.log('### invalid :', item, info.index);
                  return <View></View>;
                } else if (item.__typename === 'Path') {
                  return (
                    <LFLink
                      testID="learningpath:MyPathScreen"
                      style={{
                        width: '50%',
                        paddingHorizontal: 8,
                      }}
                      onPress={() => {
                        history.push(`/path/${item.objectId}`);
                      }}
                    >
                      <ListItemPath
                        onPressMore={() => {
                          showActionSheetWithOptions(
                            {
                              options: [
                                item?.published
                                  ? '비공개로 변경'
                                  : '공개로 변경',
                                '편집',
                                '삭제',
                                '취소',
                              ],
                              cancelButtonIndex: 3,
                              destructiveButtonIndex: 2,
                            },
                            (idx) => {
                              // console.log('idx:', idx);
                              switch (idx) {
                                case 0:
                                  console.log('Case 0');
                                  const value: UpdatePathFieldsInput = {
                                    id: item?.objectId,
                                    fields: {
                                      published: item?.published ? false : true,
                                    },
                                  };
                                  commitUpdatePathItem({
                                    variables: { input: value },
                                    onCompleted: (response) => {
                                      console.log(response);
                                    },
                                    onError: (error) => {
                                      // console.log(error);
                                    },
                                  });
                                  return;
                                case 1:
                                  history.push({
                                    pathname: `/path-edit/${item?.objectId}`,
                                  });
                                  return;
                                // TODO: 러닝패스 더보기 버튼 actionsheet에서 삭제누르면 바로 안 없어지고 브라우저 리프레시해야 삭제됨
                                case 2:
                                  commitDeletePathItem({
                                    variables: {
                                      input: {
                                        id: item?.objectId,
                                      } as DeletePathInput,
                                      connections: [
                                        pathPagination.data.paths.__id,
                                      ],
                                    },
                                  });
                                  return;
                                default:
                              }
                            }
                          );
                        }}
                        pathFrgmt={item}
                      />
                    </LFLink>
                  );
                } else {
                  return null;
                }
              })}
            </Flex>
          );
        }}
        refreshing={true}
        refreshControl={
          <RefreshControl refreshing={true} onRefresh={() => {}} />
        }
        onEndReached={() => {
          if (pathPagination && pathPagination.hasNext) {
            pathPagination.loadNext(10);
          }
        }}
        onEndReachedThreshold={0.1}
        ListFooterComponent={() => {
          return (
            <>
              {pathPagination?.isLoadingNext && (
                <Flex align="center" justify="center" style={{ height: 100 }}>
                  <ActivityIndicator />
                </Flex>
              )}
              <View style={{ height: 40 }} />
            </>
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: { fontWeight: 'bold', fontSize: 16, lineHeight: 26, color: '#262626' },
  subScreenModal: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    overflow: 'hidden',
  },
});

export default MyPathScreen;
