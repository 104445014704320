import Parse from 'parse';
import Constants from 'expo-constants';
import { FetchFunction } from 'relay-runtime';
import _ from 'lodash';
import md5 from 'react-native-md5';

const GRAPHQL_SERVER_URL =
  Constants.manifest?.extra?.REACT_APP_PARSE_SERVER_URL.replace(
    /\/api$/,
    '/graphql'
  );
const SERVER_APP_ID = Constants.manifest?.extra?.REACT_APP_PARSE_APP_ID;
const fetchGraphQL: FetchFunction = async (
  params,
  variables,
  cacheConfig,
  uploadables
) => {
  console.debug(
    `fetching query ${params.name} with ${JSON.stringify(variables)}`
  );
  // console.log(cacheConfig, uploadables);

  // parse-server 기본 headers
  const headers: any = {
    'X-Parse-Application-Id': SERVER_APP_ID,
    //TODO: installation id 넣기
  };

  // 현재 사용자 sesstionToken
  const currentUser = Parse.User.current();
  if (currentUser) {
    headers['X-Parse-Session-Token'] = currentUser.getSessionToken();
  }
  let body: any;
  if (uploadables) {
    body = body = new FormData();
    body.append(
      'operations',
      JSON.stringify({
        query: params.text,
        variables,
      })
    );

    const keys = _.keys(uploadables);
    const map = _.reduce(
      keys,
      (result, v, idx) => {
        result[idx.toString()] = [v];
        return result;
      },
      {} as {
        [key: string]: string[];
      }
    );
    body.append('map', JSON.stringify(map));

    _.each(keys, (v, idx) => {
      let uploadable = new File(
        [uploadables[v]],
        md5.hex_md5(uploadables[v].name),
        { type: uploadables[v].type, lastModified: uploadables[v].lastModified }
      );
      body.append(idx.toString(), uploadable);
    });
  } else {
    headers['Content-Type'] = 'application/json';
    body = JSON.stringify({
      query: params.text,
      variables,
    });
  }

  const result = await fetch(GRAPHQL_SERVER_URL, {
    method: 'POST',
    headers,
    body: body,
  }).then(async (response) => {
    if (response.status === 400) {
      const res = await response.json();
      console.log(res);
      throw new Parse.Error(res.code, res.error);
      // throw 'hihihi';
      // return {
      //   data: {},
      //   errors: [res],
      // };

      //TODO: file 관련 mutation 에러일 경우 에러로 취급되어야 함
    }
    return response.json();
  });

  return result;
};

export default fetchGraphQL;
