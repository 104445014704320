import { StyleSheet, Image, View } from 'react-native';
import React from 'react';
import LFWhiteSpace from './LFWhiteSpace';
import LFText from './typo/LFText';
import { Flex } from '@ant-design/react-native';
import { colors } from '../constants/styleGuide';
import { ReactElement } from 'react-markdown/lib/react-markdown';

const OnBoardingCard: React.FC<{
  title: string | ReactElement;
  subtitle: string | ReactElement;
  body: ReactElement;
}> = ({ title, subtitle, body }) => {
  return (
    <View>
      <Flex direction="column" align="center" style={{ marginHorizontal: 24 }}>
        <LFText style={styles.subtitle}>{subtitle}</LFText>
        <LFWhiteSpace size={'lg'} />
        <LFText style={styles.title}>{title}</LFText>
        <LFWhiteSpace size={'lg'} />
        {body}
      </Flex>
    </View>
  );
};

export default OnBoardingCard;

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_100,
  },
  title: {
    textAlign: 'center',
    fontSize: 29.8,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 39.7,
    letterSpacing: 0,
    color: colors.SECONDARY_BLACK,
    minHeight: 80,
  },
  primary: {
    color: colors.PRIMARY_100,
  },
  noAgain: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  next: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.PRIMARY_100,
  },
});
('');
