/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SearchScreenContentItemQueryVariables = {
    count?: number | null;
    after?: string | null;
    searchText?: string | null;
};
export type SearchScreenContentItemQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"SearchScreenContentItemPaginationQuery">;
};
export type SearchScreenContentItemQuery = {
    readonly response: SearchScreenContentItemQueryResponse;
    readonly variables: SearchScreenContentItemQueryVariables;
};



/*
query SearchScreenContentItemQuery(
  $count: Int
  $after: String
  $searchText: String
) {
  ...SearchScreenContentItemPaginationQuery
}

fragment SearchScreenContentItemPaginationQuery on Query {
  contentItems(first: $count, after: $after, where: {AND: [{published: {equalTo: true}}, {OR: [{title: {matchesRegex: $searchText}}, {author: {have: {name: {matchesRegex: $searchText}}}}]}]}, order: updatedAt_DESC) {
    count
    edges {
      node {
        objectId
        content {
          objectId
          id
        }
        ...SearchScreenContentItemView_Fragment
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment SearchScreenContentItemView_Fragment on ContentItem {
  id
  objectId
  title
  description
  content {
    id
    objectId
    type
    title
    description
    thumbURL
    link
  }
  author {
    objectId
    name
    id
  }
  noteData {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v3 = [
  {
    "kind": "Variable",
    "name": "matchesRegex",
    "variableName": "searchText"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "updatedAt_DESC"
  },
  {
    "fields": [
      {
        "items": [
          {
            "kind": "Literal",
            "name": "AND.0",
            "value": {
              "published": {
                "equalTo": true
              }
            }
          },
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "fields": (v3/*: any*/),
                        "kind": "ObjectValue",
                        "name": "title"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "OR.0"
                  },
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "fields": (v3/*: any*/),
                                "kind": "ObjectValue",
                                "name": "name"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "have"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "author"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "OR.1"
                  }
                ],
                "kind": "ListValue",
                "name": "OR"
              }
            ],
            "kind": "ObjectValue",
            "name": "AND.1"
          }
        ],
        "kind": "ListValue",
        "name": "AND"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SearchScreenContentItemQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SearchScreenContentItemPaginationQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SearchScreenContentItemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ContentItemConnection",
        "kind": "LinkedField",
        "name": "contentItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContentItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContentItem",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Content",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbURL",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "noteData",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "type": "Element",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v6/*: any*/)
                        ],
                        "type": "Node",
                        "abstractKey": "__isNode"
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "where",
          "order"
        ],
        "handle": "connection",
        "key": "ContentItem_contentItems",
        "kind": "LinkedHandle",
        "name": "contentItems"
      }
    ]
  },
  "params": {
    "cacheID": "e34ec23a0d7e78038fccca6dadcbb85a",
    "id": null,
    "metadata": {},
    "name": "SearchScreenContentItemQuery",
    "operationKind": "query",
    "text": "query SearchScreenContentItemQuery(\n  $count: Int\n  $after: String\n  $searchText: String\n) {\n  ...SearchScreenContentItemPaginationQuery\n}\n\nfragment SearchScreenContentItemPaginationQuery on Query {\n  contentItems(first: $count, after: $after, where: {AND: [{published: {equalTo: true}}, {OR: [{title: {matchesRegex: $searchText}}, {author: {have: {name: {matchesRegex: $searchText}}}}]}]}, order: updatedAt_DESC) {\n    count\n    edges {\n      node {\n        objectId\n        content {\n          objectId\n          id\n        }\n        ...SearchScreenContentItemView_Fragment\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SearchScreenContentItemView_Fragment on ContentItem {\n  id\n  objectId\n  title\n  description\n  content {\n    id\n    objectId\n    type\n    title\n    description\n    thumbURL\n    link\n  }\n  author {\n    objectId\n    name\n    id\n  }\n  noteData {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c1da6cb8cb496fa56d4a0e55c00b91d1';
export default node;
