import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import NavBar, { HistoryBackButton } from '../components/NavBar';
import { useLazyLoadQuery, graphql, usePaginationFragment } from 'react-relay';
import { useAuth } from '../hooks/auth';
import _ from 'lodash';
import { useHistory } from 'react-router';
import NoItemFromProfileMenuView from '../components/NoItemFromProfileMenuView';
import ListItem404 from './ListItem404';
import ListitemContentItem from '../components/ListItemContentItem';
import ListItemPath from '../components/ListItemPath';
import { RecentHistoryScreenQuery } from '../__generated__/RecentHistoryScreenQuery.graphql';
import { RecentHistoryPaginationQuery } from '../__generated__/RecentHistoryPaginationQuery.graphql';
import { RecentHistoryScreenPaginationFragment$key } from '../__generated__/RecentHistoryScreenPaginationFragment.graphql';
import { Flex } from '@ant-design/react-native';
import LFLink from '../components/LFLink';

const RecentHistoryScreen = () => {
  const history = useHistory();
  const { user } = useAuth();
  const currentUserObjectId = user?.objectId;
  const recentHistoryQueryRef = useLazyLoadQuery<RecentHistoryScreenQuery>(
    graphql`
      query RecentHistoryScreenQuery(
        $userObjectId: ID!
        $first: Int
        $after: String
      ) {
        ...RecentHistoryScreenPaginationFragment
      }
    `,
    {
      userObjectId: currentUserObjectId,
      first: 20,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );

  const recentHistoryPagination = usePaginationFragment<
    RecentHistoryPaginationQuery,
    RecentHistoryScreenPaginationFragment$key
  >(
    graphql`
      fragment RecentHistoryScreenPaginationFragment on Query
      @refetchable(queryName: "RecentHistoryPaginationQuery") {
        recentHistories(
          first: $first
          after: $after
          order: updatedAt_DESC
          where: { author: { have: { objectId: { equalTo: $userObjectId } } } }
        ) @connection(key: "pagination_recentHistories") {
          edges {
            node {
              id
              objectId
              path {
                objectId
                __typename
                ...ListItemPath_Fragment
              }
              contentItem {
                objectId
                __typename
                content {
                  objectId
                }
                ...ListItemContentItem_Fragment
              }
            }
          }
        }
      }
    `,
    recentHistoryQueryRef
  );

  return (
    <View style={styles.container}>
      <NavBar title="최근" left={<HistoryBackButton />} right={<></>} />
      <FlatList
        contentContainerStyle={{ flexGrow: 1, padding: 8 }}
        ListEmptyComponent={
          <NoItemFromProfileMenuView
            title="조회한 컨텐츠가 없어요."
            description="가벼운 마음으로 둘러봐도 좋아요."
            onPress={() =>
              history.push({ pathname: '/', state: { tabIdx: 1 } })
            }
            btnText="러닝패스 둘러보기"
          />
        }
        keyExtractor={(item, idx) => {
          return item?.node?.id || idx.toString();
        }}
        onEndReached={() => {
          if (recentHistoryPagination.hasNext) {
            recentHistoryPagination.loadNext(20);
          }
        }}
        ListFooterComponent={() => {
          return recentHistoryPagination?.isLoadingNext ? (
            <Flex align="center" justify="center" style={{ height: 100 }}>
              <ActivityIndicator color={'gray'} />
            </Flex>
          ) : null;
        }}
        data={recentHistoryPagination.data.recentHistories.edges}
        numColumns={2}
        renderItem={(info) => {
          const node = info.item?.node;
          const item = node?.contentItem || node?.path;
          if (item) {
            if (item.__typename === 'Path') {
              return (
                <LFLink
                  testID="learningpath:RecentHistoryScreen"
                  style={{ width: '50%' }}
                  onPress={() => {
                    history.push(`/path/${item.objectId}`);
                  }}
                >
                  <ListItemPath
                    style={{ margin: 8 }}
                    onPressMore={undefined}
                    //@ts-ignore
                    pathFrgmt={item}
                  />
                </LFLink>
              );
            } else if (item.__typename === 'ContentItem') {
              return (
                <LFLink
                  testID="content-item:RecentHistoryScreen"
                  style={{ width: '50%' }}
                  onPress={() => {
                    history.push(
                      //@ts-ignore
                      `/note/${item?.content.objectId}/${item?.objectId}`
                    );
                  }}
                >
                  <ListitemContentItem
                    style={{ margin: 8 }}
                    //@ts-ignore
                    contentItemFrgmt={item}
                    onPressMore={undefined}
                  />
                </LFLink>
              );
            }
          }
          return (
            <View style={{ width: '50%', padding: 8, minHeight: 150 }}>
              <ListItem404 />
            </View>
          );
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: { fontWeight: 'bold', fontSize: 16, lineHeight: 26, color: '#262626' },
  subScreenModal: {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    overflow: 'hidden',
  },
});

export default RecentHistoryScreen;
