import { StyleSheet, View, ViewStyle } from 'react-native';
import React from 'react';
import { colors } from '../../constants/styleGuide';
import LFText from '../../components/typo/LFText';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import LFLink from '../../components/LFLink';

export type Tab = '홈' | '학습노트' | '러닝패스';
interface IHomeTabSelector {
  currentTab: Tab;
  onSelectTab: (tab: Tab) => void;
  style?: ViewStyle;
}
const HomeTabSelector: React.FC<IHomeTabSelector> = ({
  currentTab,
  onSelectTab,
  style,
}) => {
  const data: { text: Tab; bottomBorderWidth: number }[] = [
    {
      text: '홈',
      bottomBorderWidth: 63,
    },
    {
      text: '학습노트',
      bottomBorderWidth: 63,
    },
    {
      text: '러닝패스',
      bottomBorderWidth: 63,
    },
  ];
  return (
    <View style={[{ flexDirection: 'row', paddingVertical: 8 }, style]}>
      {data.map((item) => (
        <LFLink
          key={item.text}
          style={{ alignItems: 'center', flex: 1 }}
          onPress={() => onSelectTab(item.text)}
        >
          <LFText
            style={[
              styles.text,
              item.text === currentTab && { color: colors.TEXT_100 },
            ]}
          >
            {item.text}
          </LFText>
          <LFWhiteSpace size={2} direction="column" />
          <View
            style={[
              { width: item.text === currentTab ? item.bottomBorderWidth : 0 },
              styles.bottomBorder,
            ]}
          />
        </LFLink>
      ))}
    </View>
  );
};

export default HomeTabSelector;

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 27,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.TEXT_60,
  },
  bottomBorder: {
    height: 3,
    borderRadius: 100,
    backgroundColor: colors.SECONDARY_BLACK,
  },
});
