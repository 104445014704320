import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import {
  CountOfPathsAndContentItemsQuery,
  PathWhereInput,
  ContentItemWhereInput,
} from '../../__generated__/CountOfPathsAndContentItemsQuery.graphql';
import LFText from '../../components/typo/LFText';
import { Flex } from '@ant-design/react-native';
import { colors } from '../../constants/styleGuide';
import LFWhiteSpace from '../../components/LFWhiteSpace';
import LFLink, { LFLinkTo } from '../../components/LFLink';
import _ from 'lodash';

const CountOfPathsAndContentItems: React.FC<{
  userObjectId: string;
  isMe?: boolean;
  style?: ViewStyle;
  pathsCountTo?: LFLinkTo;
  notesCountTo?: LFLinkTo;
}> = ({ userObjectId, isMe = false, style, pathsCountTo, notesCountTo }) => {
  const pathWhere: PathWhereInput = {
    AND: [{ author: { have: { objectId: { equalTo: userObjectId } } } }],
  };
  const contentItemWhere: ContentItemWhereInput = {
    AND: [{ author: { have: { objectId: { equalTo: userObjectId } } } }],
  };

  if (isMe === false) {
    pathWhere.published = { equalTo: true };
    contentItemWhere.published = { equalTo: true };
  }

  const { paths, contentItems } =
    useLazyLoadQuery<CountOfPathsAndContentItemsQuery>(
      graphql`
        query CountOfPathsAndContentItemsQuery(
          $pathWhere: PathWhereInput!
          $contentItemWhere: ContentItemWhereInput!
        ) {
          paths(where: $pathWhere) {
            count
          }
          contentItems(where: $contentItemWhere) {
            count
          }
        }
      `,
      { pathWhere, contentItemWhere },
      { fetchPolicy: 'store-and-network' }
    );

  return (
    <Flex align="center" style={style}>
      <LFLink
        testID="mypage-path-number:CountOfPathsAndContentItems"
        to={pathsCountTo ?? ''}
        style={{ flexDirection: 'row' }}
      >
        <LFText style={styles.titleText}>러닝패스</LFText>
        <LFWhiteSpace direction="row" size="xs" />
        <LFText style={styles.countText}>{paths?.count || 0}</LFText>
      </LFLink>
      <LFWhiteSpace direction="row" size="sm" />
      <View style={styles.line}></View>
      <LFWhiteSpace direction="row" size="sm" />
      <LFLink
        testID="mypage-note-number:CountOfPathsAndContentItemst"
        to={notesCountTo ?? ''}
        style={{ flexDirection: 'row' }}
      >
        <LFText style={styles.titleText}>학습노트</LFText>
        <LFWhiteSpace direction="row" size="xs" />
        <LFText style={styles.countText}>{contentItems?.count || 0}</LFText>
      </LFLink>
    </Flex>
  );
};

export default CountOfPathsAndContentItems;

const styles = StyleSheet.create({
  titleText: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_80,
  },
  countText: {
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  line: {
    width: 1,
    height: 12,
    backgroundColor: colors.BORDER_20,
  },
});
