/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeletePathInput = {
    id: string;
    clientMutationId?: string | null;
};
export type MyPathScreenPathDeleteMutationVariables = {
    input: DeletePathInput;
    connections: Array<string>;
};
export type MyPathScreenPathDeleteMutationResponse = {
    readonly deletePath: {
        readonly path: {
            readonly id: string;
            readonly objectId: string;
        };
    } | null;
};
export type MyPathScreenPathDeleteMutation = {
    readonly response: MyPathScreenPathDeleteMutationResponse;
    readonly variables: MyPathScreenPathDeleteMutationVariables;
};



/*
mutation MyPathScreenPathDeleteMutation(
  $input: DeletePathInput!
) {
  deletePath(input: $input) {
    path {
      id
      objectId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MyPathScreenPathDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeletePathPayload",
        "kind": "LinkedField",
        "name": "deletePath",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Path",
            "kind": "LinkedField",
            "name": "path",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MyPathScreenPathDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeletePathPayload",
        "kind": "LinkedField",
        "name": "deletePath",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Path",
            "kind": "LinkedField",
            "name": "path",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2d0cba2c8ed7af30b0ae2b0ac395aa7",
    "id": null,
    "metadata": {},
    "name": "MyPathScreenPathDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation MyPathScreenPathDeleteMutation(\n  $input: DeletePathInput!\n) {\n  deletePath(input: $input) {\n    path {\n      id\n      objectId\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e7d8bbde02449a792b6c452961cfef13';
export default node;
