/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PathEditorScreenPathDeleteMutationVariables = {
    id: string;
};
export type PathEditorScreenPathDeleteMutationResponse = {
    readonly deletePath: {
        readonly path: {
            readonly id: string;
            readonly objectId: string;
            readonly draft: {
                readonly id: string;
                readonly objectId: string;
            } | null;
        };
    } | null;
};
export type PathEditorScreenPathDeleteMutation = {
    readonly response: PathEditorScreenPathDeleteMutationResponse;
    readonly variables: PathEditorScreenPathDeleteMutationVariables;
};



/*
mutation PathEditorScreenPathDeleteMutation(
  $id: ID!
) {
  deletePath(input: {id: $id}) {
    path {
      id
      objectId
      draft {
        id
        objectId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "DeletePathPayload",
    "kind": "LinkedField",
    "name": "deletePath",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Path",
        "kind": "LinkedField",
        "name": "path",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PathDraft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PathEditorScreenPathDeleteMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PathEditorScreenPathDeleteMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "0a2a0f64534fec82c6ae5491f99cabd9",
    "id": null,
    "metadata": {},
    "name": "PathEditorScreenPathDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PathEditorScreenPathDeleteMutation(\n  $id: ID!\n) {\n  deletePath(input: {id: $id}) {\n    path {\n      id\n      objectId\n      draft {\n        id\n        objectId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0d6e18d5bd628ea6b35d97e9c8af84a0';
export default node;
