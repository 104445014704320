import * as React from 'react';
import { useState, useContext, createContext, useEffect } from 'react';
import Parse from 'parse';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { authViewerQuery } from '../__generated__/authViewerQuery.graphql';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// type Auth = {
//   user: Parse.User | undefined;
//   roleNames: string[];
//   isCurriculumMaster: boolean;
//   login?: (id: string, pw: string) => Promise<Parse.User<Parse.Attributes>>;
//   logOut?: () => Promise<Parse.User<Parse.Attributes>>;
// };

interface LearnFitGoogleLoginParams {
  googleId: string;
  tokenId: string;
  email: string;
  name: string;
  originProfileURL: string;
  profileImage?: {
    url: string;
  };
}

interface AuthContextInterface {
  user?: any;
  loginWithGoogle: (params: LearnFitGoogleLoginParams) => Promise<any>;
  logout: () => void;
  sessionToken?: string;
}
const authContext = createContext<AuthContextInterface>({
  loginWithGoogle: (params: LearnFitGoogleLoginParams) =>
    Promise.reject('not initialized yet'),
  logout: () => {},
});

export const useAuth = () => {
  return useContext(authContext);
};

export const AuthProvider: React.FC = ({ children }) => {
  const value = useProviderAuthValue();
  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

const parseUser2JSON = (user?: Parse.User) => (user ? user.toJSON() : null);
const useProviderAuthValue = () => {
  const currentUser = Parse.User.current();
  const [user, setUser] = useState(parseUser2JSON(currentUser));
  const sendDataToGTM = useGTMDispatch();
  useEffect(() => {
    if (user) {
      const documentCookie = document.cookie as string;
      const cookieUserId = documentCookie
        .split('; ')
        .find((row) => row.startsWith('userId'))
        ?.split('=')[1];
      if (cookieUserId) return;
      document.cookie = `userId=${user.objectId};`;
      sendDataToGTM({
        event: 'userIdCookieUpdate',
      });
    } else {
      const documentCookie = document.cookie as string;
      const cookieUserId = documentCookie
        .split('; ')
        .find((row) => row.startsWith('userId'))
        ?.split('=')[1];
      document.cookie = 'userId=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      if (!cookieUserId) return;
      sendDataToGTM({
        event: 'userIdCookieUpdate',
      });
    }
    // 참조한 MDN 링크:
    // https://developer.mozilla.org/ko/docs/Web/API/Document/cookie#:~:text=WHATWG%20DOM%20Storage.-,You%20can%20delete%20a%20cookie%20by%20simply%20updating%20its%20expiration%20time%20to%20zero.,-Keep%20in%20mind
  }, [user]);
  const [roleNames, setRoleNames] = useState<string[]>([]);
  const [isCurriculumMaster, setIsCurriculumMaster] = useState<boolean>(false);

  const viewerQueryRef = useLazyLoadQuery<authViewerQuery>(
    graphql`
      query authViewerQuery($skip: Boolean = false) {
        viewer @skip(if: $skip) {
          sessionToken
          user {
            id
            objectId
            name
            description
            originProfileURL
            jobTitle
            tags {
              ... on Element {
                value
              }
            }
            profileImage {
              url
            }
          }
        }
      }
    `,
    {
      skip: !user?.objectId,
    },
    {
      fetchPolicy: 'store-and-network',
    }
  );
  const loginWithGoogle = ({
    googleId,
    tokenId,
    email,
    name,
    originProfileURL,
  }: LearnFitGoogleLoginParams) => {
    const user = new Parse.User();
    user.set({
      email,
      name,
      originProfileURL,
    });
    return user
      .linkWith('google', {
        authData: {
          id: googleId,
          id_token: tokenId,
        },
      })
      .then((user) => {
        const parsedUser = parseUser2JSON(user);
        setUser(parsedUser);
        return parsedUser;
      });
  };
  const logout = () => {
    return Parse.User.logOut().finally(() => {
      setUser(null);
      // setRoleNames([]);
    });
  };
  return {
    user: viewerQueryRef?.viewer?.user || user,
    sessionToken:
      currentUser?.getSessionToken() || viewerQueryRef.viewer?.sessionToken,
    // roleNames,
    // isCurriculumMaster,
    loginWithGoogle,
    logout,
  } as const;
};

export const PrivateRoute: React.FC<{ path: string }> = ({
  children,
  ...rest
}) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
              // 코드 설명: login.tsx에서 location안의 From을 참고함으로써
              // login 성공 후 원래 가려던 페이지로 가는 것
            }}
          />
        );
      }}
    />
  );
};
