import _ from 'lodash';
import {
  ImageStyle,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import NavBar, { HistoryBackButton } from '../components/NavBar2';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import LFText from '../components/typo/LFText';
import { Flex, WhiteSpace, Modal } from '@ant-design/react-native';
import PreviewImage from '../components/PreviewImage';
import Footer from '../components/Footer';
import LFButton from '../components/LFButton';
import dayjs from 'dayjs';
import { useMobileDimensions } from '../hooks/useMobileDimensions';
import MembershipTerms from '../components/MembershipTerms';
import MembershipPrivacyPolicy from '../components/MembershipPrivacyPolicy';
import { loadTossPayments } from '@tosspayments/payment-sdk';

import Constants from 'expo-constants';
import { useAuth } from '../hooks/auth';
import { MembershipPlanAndPricingScreenAuthorQuery } from '../__generated__/MembershipPlanAndPricingScreenAuthorQuery.graphql';
import { MembershipPlanAndPricingScreenMembershipPricingQuery } from '../__generated__/MembershipPlanAndPricingScreenMembershipPricingQuery.graphql';
import { MembershipPlanAndPricingScreenPaymentMutation } from '../__generated__/MembershipPlanAndPricingScreenPaymentMutation.graphql';
import MembershipPricingCard from '../components/MembershipPricingCard';
import LFLink from '../components/LFLink';
import LFWhiteSpace from '../components/LFWhiteSpace';
import { LoginScreenAccessAction } from './LoginScreen';
import { MembershipPlanAndPricingScreenStatusesQuery } from '../__generated__/MembershipPlanAndPricingScreenStatusesQuery.graphql';
interface FormFieldsProp {
  name: FieldProp;
  phoneNumber: FieldProp;
  email: FieldProp;
  membershipTerms: FieldProp;
  membershipPrivacyPolicy: FieldProp;
}

interface FieldProp {
  value: string | boolean;
  error: { status: boolean; message?: string | undefined };
}

const MembershipPlanAndPricingScreen = () => {
  const { user: loginUser } = useAuth();
  const history = useHistory();
  const location = useLocation<{
    from?: Location;
  }>();
  const from = location?.state?.from;
  const loginUserId = loginUser?.objectId || '';
  const membershipOwnerId = location.pathname.split('/')[2] || '';
  const { membershipStatuses } =
    useLazyLoadQuery<MembershipPlanAndPricingScreenStatusesQuery>(
      graphql`
        query MembershipPlanAndPricingScreenStatusesQuery(
          $loginUserId: ID!
          $membershipOwnerId: ID!
          $status: String!
        ) {
          membershipStatuses(
            where: {
              member: { have: { objectId: { equalTo: $loginUserId } } }
              membership: {
                have: {
                  author: {
                    have: { objectId: { equalTo: $membershipOwnerId } }
                  }
                }
              }
              status: { equalTo: $status }
            }
          ) {
            count
          }
        }
      `,
      { loginUserId, membershipOwnerId, status: 'valid' }
    );

  // 동작: membershipStatuses.count가 0보다 크면 유저가 해당 멤버십에 대해 아직 유효하다는 것을 의미하므로 접근을 방지하기 위해 goBack 호출
  // *정책상 이미 멤버십에 가입되어 있을 시 추가 결제 불가능
  Boolean(membershipStatuses.count || 0) && history.goBack();

  const { width, height } = useMobileDimensions(); // modal 사이즈 결정에 사용

  const urlSearchParams = new URLSearchParams(location.search);
  const pricingId = urlSearchParams.get('pricingId');

  const orderIdRef = useRef<string>();

  const [commitCreatePaymentMutation, isCreatePaymentInFlight] =
    useMutation<MembershipPlanAndPricingScreenPaymentMutation>(graphql`
      mutation MembershipPlanAndPricingScreenPaymentMutation(
        $userObjectId: ID!
        $pricingId: ID!
        $orderId: String!
        $price: Float!
      ) {
        createMembershipPayment(
          input: {
            fields: {
              member: { link: $userObjectId }
              membershipPricing: { link: $pricingId }
              orderId: $orderId
              price: $price
            }
          }
        ) {
          membershipPayment {
            id
            objectId
            status
          }
        }
      }
    `);
  useEffect(() => {
    if (pricingId && loginUser) {
      orderIdRef.current = `${
        loginUser.objectId
      }_${pricingId}_${new Date().getTime()}`;
      // commitCreatePaymentMutation({
      //   variables: {
      //     userObjectId: loginUser?.objectId,
      //     pricingId: pricingId,
      //   },
      //   onCompleted: (response) => {
      //     console.log(response);
      //     const search =
      //       '?' +
      //       new URLSearchParams({
      //         pricingId: pricingId,
      //         orderId:
      //           response.createMembershipPayment?.membershipPayment?.objectId,
      //       }).toString();
      //     history.replace('/login', {
      //       from: {
      //         ...location,
      //         search,
      //       },
      //     });
      //   },
      // });
    }
  }, [pricingId, loginUser]);

  const [paymentFormValue, setPaymentFormValue] = useState<FormFieldsProp>({
    name: {
      value: '',
      error: {
        status: true,
        message: '한글과 영문 대 소문자를 사용하세요.(특수기호, 공백사용불가)',
      },
    },
    phoneNumber: { value: '', error: { status: true } },
    email: {
      value: '',
      error: { status: true, message: '이메일 형식으로 입력하세요.' },
    },
    membershipTerms: { value: false, error: { status: true } },
    membershipPrivacyPolicy: { value: false, error: { status: true } },
  });
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const isFormValid = _.chain(paymentFormValue)
      .every((item: FieldProp) => item.error.status === false)
      .value();
    setIsValid(isFormValid);
  }, [paymentFormValue]);

  const [modalState, setModalState] = useState<
    'membershipTerms' | 'membershipPrivacyPolicy' | 'empty'
  >('empty');

  const { userObjectId } = useParams<{ userObjectId: string }>();
  const { user } = useLazyLoadQuery<MembershipPlanAndPricingScreenAuthorQuery>(
    graphql`
      query MembershipPlanAndPricingScreenAuthorQuery($userObjectId: ID!) {
        user(id: $userObjectId) {
          id
          objectId
          name
          createdAt
          updatedAt
          description
          originProfileURL
          jobTitle
          tags {
            ... on Element {
              value
            }
          }
          profileImage {
            url
          }
        }
      }
    `,
    {
      userObjectId,
    },
    { fetchPolicy: 'store-and-network' }
  );
  const { membershipPricings } =
    useLazyLoadQuery<MembershipPlanAndPricingScreenMembershipPricingQuery>(
      graphql`
        query MembershipPlanAndPricingScreenMembershipPricingQuery(
          $authorObjectId: ID!
        ) {
          membershipPricings(
            where: {
              author: { have: { objectId: { equalTo: $authorObjectId } } }
              disabled: { notEqualTo: true }
            }
          ) {
            edges {
              node {
                ...MembershipPricingCardFragment
                id
                objectId
                price
                duration

                membership {
                  id
                  objectId
                  title
                  level
                }
              }
            }
          }
        }
      `,
      {
        authorObjectId: userObjectId,
      }
    );
  const selectedPricing = _.find(
    membershipPricings.edges,
    (e) => e?.node?.objectId === pricingId
  )?.node;

  const onPressMakePayment = () => {
    if (selectedPricing?.price && orderIdRef.current) {
      loadTossPayments(
        Constants.manifest?.extra?.REACT_APP_TOSS_CLIENT_KEY
      ).then((tossPayments) => {
        // alert(orderIdRef.current);
        if (orderIdRef.current && selectedPricing.price) {
          commitCreatePaymentMutation({
            variables: {
              userObjectId: loginUser?.objectId,
              pricingId: selectedPricing.objectId,
              orderId: orderIdRef.current,
              price: selectedPricing.price,
            },
            onCompleted: (response) => {
              console.log(response);
              if (response?.createMembershipPayment) {
                tossPayments.requestPayment('카드', {
                  amount: selectedPricing?.price || 0,
                  // TODO: client 혹은 api에서 결제 건을 생성해서 해당 id를 사용해야한다.
                  orderId: orderIdRef.current || 'invalid',
                  orderName: `${user.name}님의 멤버십 ${selectedPricing?.duration}일 이용권`,
                  customerName: loginUser.name,
                  successUrl:
                    Constants.manifest?.extra?.REACT_APP_PARSE_SERVER_URL.replace(
                      '/api',
                      `/payment-success?authorId=${user.objectId}&fromPathname=${from?.pathname}`
                    ),
                  failUrl:
                    Constants.manifest?.extra?.REACT_APP_PARSE_SERVER_URL.replace(
                      '/api',
                      `/payment-fail?authorId=${user.objectId}&fromPathname=${from?.pathname}`
                    ),
                });
              }
            },
          });
        }
      });
    }
  };
  return (
    <ScrollView>
      <NavBar
        left={<HistoryBackButton />}
        title={`${user.name || ''}님 멤버십`}
        right={<></>}
      />
      {!pricingId ? (
        <View style={styles.container}>
          <LFWhiteSpace />
          <LFText>{`${
            user.name || ''
          }님이 운영하고 있는 멤버십입니다.\n멤버십 이용권을 구매하면 해당 멤버십 전용 콘텐츠를 이용권 기간동안 학습 할 수 있습니다.`}</LFText>
          <LFWhiteSpace />
          <Flex
            align="center"
            style={[
              styles.badgeItem,
              {
                backgroundColor: 'rgba(35, 110, 255, 0.2)',
                paddingRight: 6,
                alignSelf: 'flex-start',
              },
            ]}
          >
            <View style={styles.badgeLogo}>
              <PreviewImage
                accessibilityLabel=""
                style={styles.badgeImage as StyleProp<ImageStyle>}
                source={require('../assets/images/free-lf-logo.png')}
              />
            </View>
            <LFText style={[styles.badgeLabel, { color: '#236EFF' }]}>
              멤버십
            </LFText>
          </Flex>
          <WhiteSpace />

          <View style={{ flexDirection: 'column', marginBottom: -16 }}>
            {_.map(membershipPricings.edges, (e, list) => {
              const pricing = e?.node;
              const search =
                '?' +
                new URLSearchParams({
                  pricingId: _.toString(pricing?.objectId),
                }).toString();
              return (
                <React.Fragment key={e?.node?.id}>
                  <LFLink
                    testID="membership-card:MembershipPlanAndPricingScreen"
                    onPress={() => {
                      if (!loginUser) {
                        history.push('/login', {
                          from: {
                            ...location,
                            search,
                          },
                          accessAction:
                            'clickMembershipPriceButton' as LoginScreenAccessAction,
                        });
                        return;
                      }
                      history.push({
                        search,
                        state: location?.state,
                      });
                    }}
                  >
                    <MembershipPricingCard fragment={pricing || null} />
                  </LFLink>
                  <LFWhiteSpace />
                </React.Fragment>
              );
            })}
          </View>
        </View>
      ) : (
        <View style={{ padding: 16 }}>
          <LFText style={{ color: '#236EFF' }}>
            결제에 필요한 최소한의 정보를 입력해주세요
          </LFText>
          <WhiteSpace size="sm" />
          <TouchableOpacity
            onPress={() => {
              history.push({
                search: '',
                state: location?.state,
              });
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderWidth: 1,
              padding: 8,
              borderColor: '#236EFF',
            }}
          >
            <View>
              <LFText
                style={{ fontSize: 15 }}
              >{`${selectedPricing?.duration}일 이용권`}</LFText>
              <LFText style={{ fontSize: 18, fontWeight: 'bold' }}>
                {`${selectedPricing?.price?.toLocaleString('en-US')}원`}
              </LFText>
            </View>
            <LFText style={{ color: '#236EFF' }}>변경</LFText>
          </TouchableOpacity>
          <WhiteSpace size="xs" />
          {/* <LFText style={{ color: '#787878' }}>
            {`이용기한: ${dayjs(Date.now()).format(
              'YYYY[년] MM[월] DD[일]'
            )} ~ ${dayjs(
              Date.now() +
                1000 * 60 * 60 * 24 * 30 * selectedMembershipPlan.month
            ).format('YYYY[년] MM[월] DD[일]')} 자정`}
          </LFText> */}
          <WhiteSpace size="xl" />
          <LFText>이름</LFText>
          <WhiteSpace size="xs" />
          <TextInput
            onChangeText={(text) => {
              // 공백, 특수문자를 검사하는 정규 표현식
              const myRegExp = /[^A-Z|a-z|0-9|ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
              const isFail = text === '' ? true : myRegExp.test(text);
              setPaymentFormValue((prev) => ({
                ...prev,
                name: {
                  value: text,
                  error: {
                    ...paymentFormValue.name.error,
                    status: isFail ? true : false,
                  },
                },
              }));
            }}
            value={paymentFormValue.name.value as string}
            style={{ padding: 5, borderWidth: 1 }}
          ></TextInput>
          <WhiteSpace size="xs" />
          {paymentFormValue.name.error.status &&
            paymentFormValue.name.value !== '' && (
              <LFText style={{ fontSize: 10, color: 'red' }}>
                {paymentFormValue.name.error.message}
              </LFText>
            )}
          <WhiteSpace />
          <LFText>휴대폰 번호</LFText>
          <WhiteSpace size="xs" />
          <TextInput
            keyboardType="number-pad"
            onChangeText={(text) => {
              // 숫자만 포함된 문자열인지 검사하는 정규 표현식
              const myRegExp = /^\d+$/;
              const isPass = myRegExp.test(text);
              (isPass || text === '') &&
                setPaymentFormValue((prev) => ({
                  ...prev,
                  phoneNumber: {
                    value: text,
                    error: {
                      status: text !== '' && text.length === 11 ? false : true,
                    },
                  },
                }));
            }}
            value={paymentFormValue.phoneNumber.value as string}
            placeholder="-는 빼고 입력해주세요."
            style={{ padding: 5, borderWidth: 1 }}
          ></TextInput>
          <WhiteSpace />

          <LFText>청구 이메일</LFText>
          <WhiteSpace size="xs" />
          <TextInput
            keyboardType={'email-address'}
            onChangeText={(text) => {
              // 이메일 구조인지 검사하는 정규 표현식(@포함, .com 등 붙어있어야합니다)
              const myRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
              const isPass = myRegExp.test(text);
              setPaymentFormValue((prev) => ({
                ...prev,
                email: {
                  value: text,
                  error: {
                    ...paymentFormValue.email.error,
                    status: isPass ? false : true,
                  },
                },
              }));
            }}
            value={paymentFormValue.email.value as string}
            placeholder="sample@example.com"
            style={{ padding: 5, borderWidth: 1 }}
          ></TextInput>
          {paymentFormValue.email.error.status &&
            paymentFormValue.email.value !== '' && (
              <>
                <WhiteSpace size="xs" />
                <LFText style={{ fontSize: 10, color: 'red' }}>
                  {paymentFormValue.email.error.message}
                </LFText>
              </>
            )}

          <WhiteSpace size="xl" />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: 'max-content',
              }}
              onPress={() => {
                setPaymentFormValue((prev) => ({
                  ...prev,
                  membershipTerms: {
                    value: !prev.membershipTerms.value,
                    error: { status: !prev.membershipTerms.error.status },
                  },
                }));
              }}
            >
              <View
                style={{
                  width: 15,
                  height: 15,
                  borderWidth: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: paymentFormValue.membershipTerms.value
                    ? '#236EFF'
                    : 'white',
                  marginRight: 5,
                }}
              >
                {paymentFormValue.membershipTerms.value && (
                  <LFText
                    style={{
                      fontWeight: 'bold',
                      fontSize: 12,
                      color: '#FFFFFF',
                    }}
                  >
                    V
                  </LFText>
                )}
              </View>
              <LFText>(필수)이용약관에 동의합니다.</LFText>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: 'max-content',
              }}
              onPress={() => {
                setModalState('membershipTerms');
              }}
            >
              <LFText style={{ fontWeight: 'bold', color: '#236EFF' }}>
                보기
              </LFText>
            </TouchableOpacity>
          </View>
          <WhiteSpace size="xs" />

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: 'max-content',
              }}
              onPress={() => {
                setPaymentFormValue((prev) => ({
                  ...prev,
                  membershipPrivacyPolicy: {
                    value: !prev.membershipPrivacyPolicy.value,
                    error: {
                      status: !prev.membershipPrivacyPolicy.error.status,
                    },
                  },
                }));
              }}
            >
              <View
                style={{
                  width: 15,
                  height: 15,
                  borderWidth: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: paymentFormValue.membershipPrivacyPolicy
                    .value
                    ? '#236EFF'
                    : 'white',
                  marginRight: 5,
                }}
              >
                {paymentFormValue.membershipPrivacyPolicy.value && (
                  <LFText
                    style={{
                      fontWeight: 'bold',
                      fontSize: 12,
                      color: '#FFFFFF',
                    }}
                  >
                    V
                  </LFText>
                )}
              </View>
              <LFText>(필수)개인정보취급방침에 동의합니다.</LFText>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: 'max-content',
              }}
              onPress={() => {
                setModalState('membershipPrivacyPolicy');
              }}
            >
              <LFText style={{ fontWeight: 'bold', color: '#236EFF' }}>
                보기
              </LFText>
            </TouchableOpacity>
          </View>

          <WhiteSpace size="xl" />
          <LFButton
            testID="payment-button:MembershipPlanAndPricingScreen"
            isLoading={isCreatePaymentInFlight}
            disabled={!isValid}
            onPress={onPressMakePayment}
          >
            결제하기
          </LFButton>
          {/* <TouchableWithoutFeedback onPress={()=>{}}>{' '}</TouchableWithoutFeedback> */}
        </View>
      )}

      <Footer />
      <Modal
        style={{ width: width - 32 }}
        visible={modalState !== 'empty'}
        transparent
        maskClosable={true}
        onClose={() => {
          setModalState('empty');
        }}
        title={
          modalState === 'membershipTerms'
            ? '이용약관'
            : modalState === 'membershipPrivacyPolicy'
            ? '개인정보처리방침'
            : null
        }
        footer={[
          {
            text: '확인',
            onPress: () => {
              console.log('ok');
            },
          },
        ]}
      >
        <ScrollView style={{ height: height * 0.7 }}>
          {modalState === 'membershipTerms' ? (
            <MembershipTerms />
          ) : modalState === 'membershipPrivacyPolicy' ? (
            <MembershipPrivacyPolicy />
          ) : null}
        </ScrollView>
      </Modal>
    </ScrollView>
  );
};

export default MembershipPlanAndPricingScreen;

const styles = StyleSheet.create({
  container: { padding: 16 },
  badgeItem: {
    padding: 4,
    backgroundColor: '#F0F1F6',
    borderRadius: 100,
  },
  badgeLabel: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    lineHeight: 16,
    // transform이 ts 에러 발생시킴
    // @ts-ignore
    textFransform: 'uppercase',
    color: '#1A1B1E',
  },
  badgeLogo: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 2,
  },
  badgeImage: {
    width: 10,
    height: 10,
  },
});
