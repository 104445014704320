import React from 'react';
import { Dimensions, ViewStyle } from 'react-native';
import SkeletonContent from 'react-native-skeleton-content';
import { ICustomViewStyle } from 'react-native-skeleton-content/lib/Constants';
import { colors } from '../../constants/styleGuide';
import { useAuth } from '../../hooks/auth';
import { useDeviceType } from '../../hooks/deviceType';
import useTurnOnOffScrollMode from '../../hooks/turnOnOffScrollMode';

interface IHomeMainTabSkeleton {
  style?: ViewStyle;
}

const HomeMainTabSkeleton: React.FC<IHomeMainTabSkeleton> = ({ style }) => {
  // useTurnOnOffScrollMode((on, off) => {
  //   off();
  // }, []);
  const deviceType = useDeviceType();
  const deviceWidth =
    deviceType === 'PC' ? 720 : Dimensions.get('screen').width;
  const { user: loggedIn } = useAuth();
  const getDimensionCompatibleWithDevice = (
    mobileDimension: number | string,
    pcDimension: number | string
  ) => (deviceType === 'MOBILE' ? mobileDimension : pcDimension);

  const firstSectionTitleSentenceStyle: ICustomViewStyle = {
    key: 'sectionTitle1',
    width: getDimensionCompatibleWithDevice('100%', '40%'),
    height: 24,
    marginBottom: 8,
  };
  const secondSectionTitleSentenceStyle: ICustomViewStyle = {
    key: 'sectionTitle2',
    width: getDimensionCompatibleWithDevice('65%', '33%'),
    height: 24,
  };

  const progressingPathItemStyle: ICustomViewStyle = {
    width: 332,
    height: 150,
    borderRadius: 12,
    backgroundColor: colors.BG_4,
  };

  const noteItemStyle: ICustomViewStyle = {
    borderRadius: 12,
    backgroundColor: colors.BG_4,
    width: getDimensionCompatibleWithDevice(210, 240),
    height: getDimensionCompatibleWithDevice(394, 425),
  };
  const pathItemStyle: ICustomViewStyle = {
    width: getDimensionCompatibleWithDevice(343, 340),
    height: 609,
    borderRadius: 16,
    backgroundColor: colors.BG_4,
  };

  const homeMainTabSkeletonLayout: ICustomViewStyle[] = [
    {
      key: 'progressingSectionTitle',
      width: '100%',
      marginBottom: 24,
      children: [
        firstSectionTitleSentenceStyle,
        secondSectionTitleSentenceStyle,
      ],
    },
    {
      key: 'progressingPaths',
      width: deviceWidth - 16,
      marginBottom: 56,
      overflow: 'hidden',
      flexDirection: 'row',
      children: [1, 2, 3].map((number, index, arr) => ({
        key: `progressingPath${number}`,
        ...progressingPathItemStyle,
        marginRight: index + 1 === arr.length ? 0 : 6.5,
      })),
    },
    {
      key: 'recentViewedNotesSectionTitle',
      width: '100%',
      marginBottom: 24,
      children: [
        firstSectionTitleSentenceStyle,
        secondSectionTitleSentenceStyle,
      ],
    },
    {
      key: 'recentViewedNotes',
      width: deviceWidth - 16,
      marginBottom: 56,
      flexDirection: 'row',
      overflow: 'hidden',
      children: [1, 2, 3, 4].map((number, index, arr) => ({
        key: `recentViewedNote${number}`,
        ...noteItemStyle,
        marginRight: index + 1 === arr.length ? 0 : 10,
      })),
    },
    {
      key: 'newNotesSectionTitle',
      width: '100%',
      marginBottom: 24,
      children: [
        firstSectionTitleSentenceStyle,
        secondSectionTitleSentenceStyle,
      ],
    },
    {
      key: 'newNotes',
      width: deviceWidth - 16,
      marginBottom: 56,
      flexDirection: 'row',
      overflow: 'hidden',
      children: [1, 2, 3, 4].map((number, index, arr) => ({
        key: `newNote${number}`,
        ...noteItemStyle,
        marginRight: index + 1 === arr.length ? 0 : 10,
      })),
    },

    {
      key: 'topTierPathsSections',
      width: '100%',
      marginBottom: 24,
      children: [
        firstSectionTitleSentenceStyle,
        secondSectionTitleSentenceStyle,
      ],
    },
    {
      key: 'topTierPaths',
      width: deviceWidth - 16,
      marginBottom: 56,
      flexDirection: 'row',
      overflow: 'hidden',
      children: [1, 2, 3, 4].map((number, index, arr) => ({
        key: `topTierPath${number}`,
        ...pathItemStyle,
        marginRight: index + 1 === arr.length ? 0 : 8,
      })),
    },
  ];

  return (
    <SkeletonContent
      containerStyle={[
        { flex: 1, width: deviceWidth, paddingHorizontal: 16 },
        style,
      ]}
      isLoading
      // layout={
      //   loggedIn
      //     ? homeMainTabSkeletonLayout
      //     : homeMainTabSkeletonLayout.filter(
      //         (layoutItem) =>
      //           ![
      //             'progressingSectionTitle',
      //             'progressingPaths',
      //             'recentViewedNotesSectionTitle',
      //             'recentViewedNotes',
      //           ].includes(String(layoutItem.key))
      //       )
      // }
      layout={homeMainTabSkeletonLayout.filter(
        (layoutItem) =>
          ![
            'progressingSectionTitle',
            'progressingPaths',
            'recentViewedNotesSectionTitle',
            'recentViewedNotes',
          ].includes(String(layoutItem.key))
      )}
    />
  );
};

export default HomeMainTabSkeleton;
