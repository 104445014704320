import { Flex, WhiteSpace } from '@ant-design/react-native';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { Ionicons } from '@expo/vector-icons';
import _ from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { isMobile, isDesktop } from 'react-device-detect';

import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import LFLink from '../../components/LFLink';
import { secToHHMMSS } from '../../components/TimeNote';
import LFText from '../../components/typo/LFText';

export interface NoteInputProps {
  dataSet?: { [key: string]: string };
  style: ViewStyle;
  duration: number;
  onFocus?: () => void;
  onBlur?: () => void;
  isPendingSubmit: boolean;
  onSubmitEditing: (data: any) => void;
  onPressTime: (time: number) => void;
  onPressDelete?: () => void;
  onPressCancelEdit?: () => void;
  playedSeconds: number;
  editSource?: {
    time: number;
    note: string;
  };
}

export interface NoteInputHandle {
  reset: (matchedTypedText?: string) => void;
}
const NoteInput = React.forwardRef<NoteInputHandle, NoteInputProps>(
  (
    {
      dataSet,
      style,
      onFocus,
      onBlur,
      isPendingSubmit = false,
      onSubmitEditing,
      onPressTime,
      duration,
      playedSeconds,
      editSource,
      onPressDelete,
      onPressCancelEdit,
    },
    ref
  ) => {
    const [timeForNote, setTimeForNote] = useState<null | number>(null);
    const noteInputRef = useRef<TextInput>(null);
    const [textInputHeight, setTextInputHeight] = useState(undefined);
    const [typedText, setTypedText] = useState('');
    const { showActionSheetWithOptions } = useActionSheet();

    const submitCurrentTypedText = () => {
      onSubmitEditing({
        time: timeForNote,
        note: typedText,
      });
    };

    useEffect(() => {
      if (editSource) {
        setTypedText(editSource.note);
        setTimeForNote(editSource.time);
      }
    }, [editSource]);

    useImperativeHandle(
      ref,
      () => ({
        reset: (matchedTypedText) => {
          if (matchedTypedText === typedText) {
            setTypedText('');
            setTimeForNote(null);
            setTextInputHeight(undefined);
          }
        },
      }),
      [setTypedText, setTimeForNote, typedText, setTextInputHeight]
    );

    return (
      <Flex
        direction="column"
        align="stretch"
        style={[
          {
            shadowColor: '#000',
            shadowOpacity: 0.1,
            shadowRadius: 5,
            shadowOffset: { height: 3, width: 0 },
            marginHorizontal: -16,
            paddingHorizontal: 16,
          },
          style,
        ]}
      >
        <Flex direction="row" style={{ marginHorizontal: -10 }}>
          <LFLink
            testID="timestamp-minus-3sec:NoteInput"
            // style={styles.emojiBtn}
            style={{
              width: 30,
              height: 30,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              setTimeForNote((v) => {
                const gap = 3;
                if (_.isNull(v)) {
                  return v;
                } else {
                  const newTime = v - gap < 0 ? 0 : v - gap;
                  _.isFunction(onPressTime) && onPressTime(newTime);
                  onPressTime(newTime);
                  return newTime;
                }
              });
            }}
            disabled={timeForNote === null}
          >
            {/* <Ionicons
          name="play-back-circle-outline"
          size={24}
          color={timeForNote === null ? '#ccc' : 'black'}
        /> */}
            <LFText style={{ color: timeForNote === null ? '#ccc' : 'black' }}>
              -3
            </LFText>
          </LFLink>
          <LFLink
            testID="timestamp-plus-3sec:NoteInput"
            // style={styles.emojiBtn}
            style={{
              width: 30,
              height: 30,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              setTimeForNote((v) => {
                const gap = 3;
                if (_.isNull(v)) {
                  return v;
                } else {
                  const newTime = v + gap > duration ? duration : v + gap;
                  onPressTime(newTime);
                  return Math.floor(newTime);
                }
              });
            }}
            disabled={timeForNote === null}
          >
            <LFText style={{ color: timeForNote === null ? '#ccc' : 'black' }}>
              +3
            </LFText>
            {/* <Ionicons
          name="play-forward-circle-outline"
          size={24}
          color={timeForNote === null ? '#ccc' : 'black'}
        /> */}
          </LFLink>
          {editSource ? (
            <TouchableOpacity
              style={styles.emojiBtn}
              onPress={() => {
                showActionSheetWithOptions(
                  {
                    options: ['삭제', '취소'],
                    destructiveButtonIndex: 0,
                    cancelButtonIndex: 1,
                  },
                  (idx) => {
                    if (idx === 0) {
                      onPressDelete();
                    }
                  }
                );
              }}
            >
              <Ionicons name="ellipsis-horizontal" size={24} color="black" />
            </TouchableOpacity>
          ) : (
            <LFText style={{ fontSize: 20 }}>
              {_.map(['🤩', '🧐', '🤔', '😵‍💫', '🤯'], (emoji) => {
                return (
                  <LFLink
                    testID="emoji-timestamp:NoteInput"
                    style={styles.emojiBtn}
                    onPress={() => {
                      onSubmitEditing({
                        time: playedSeconds,
                        note: emoji,
                      });
                    }}
                  >
                    <LFText>{emoji}</LFText>
                  </LFLink>
                );
              })}
            </LFText>
          )}
        </Flex>
        <Flex align="start" direction="row">
          {editSource ? (
            <LFLink
              style={{ marginTop: 3, marginRight: 5 }}
              onPress={() => {
                onPressCancelEdit();
              }}
            >
              <Ionicons name="close-circle-sharp" size={30} color="gray" />
            </LFLink>
          ) : null}
          <LFLink
            testID="current-timestamp:NoteInput"
            onPress={() => {
              onPressTime(timeForNote || 0);
            }}
            disabled={timeForNote === null}
          >
            <LFText
              style={{
                marginRight: 10,
                marginTop: 10,
                color: timeForNote !== null ? '#295ECC' : '#ccc',
                fontWeight: timeForNote !== null ? '500' : 'normal',
              }}
            >
              {timeForNote !== null
                ? secToHHMMSS(timeForNote)
                : secToHHMMSS(Math.floor(playedSeconds || 0))}
            </LFText>
          </LFLink>
          {/*  <View
        style={{
          width: 32,
          height: 32,
          backgroundColor: '#f0f0f0',
          borderRadius: 16,
          marginRight: 16,
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          alignSelf: 'flex-start',
        }}
      >
        {user?.originProfileURL ? (
          <Image
            source={{ uri: user?.originProfileURL }}
            style={{ width: 32, height: 32 }}
            resizeMode="cover"
          />
        ) : (
          // <Ionicons name="person" size={20} color="#aaa" />
          <LFText>
            <Ionicons name="person" size={20} color="#aaa" />
          </LFText>
        )} 
        
      </View>
        */}

          <TextInput
            //@ts-ignore
            dataSet={dataSet}
            ref={noteInputRef}
            style={[
              styles.textInput,
              textInputHeight
                ? {
                    height: textInputHeight,
                    maxHeight: textInputHeight > 100 ? 100 : textInputHeight, // 사이즈가 다시 줄지 않는 버그 해결용 임시
                  }
                : {},
            ]}
            multiline
            onContentSizeChange={(e) => {
              setTextInputHeight(e.nativeEvent.contentSize.height);
            }}
            value={typedText}
            onKeyPress={(e) => {
              if (e.nativeEvent.key == 'Enter' && isDesktop) {
                if (!e.nativeEvent.shiftKey) {
                  // prevent default behavior
                  e.preventDefault();
                  submitCurrentTypedText();
                }
              }
            }}
            onChangeText={(text) => {
              setTimeForNote((x) => {
                if (text.length) {
                  return _.isNull(x) ? Math.floor(playedSeconds || 0) : x;
                } else {
                  return null;
                }
              });
              setTypedText(text);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            // placeholder="나만의 학습노트를 작성해보세요."
            placeholder="기억 하고 싶은 내용을 작성해보세요."
            onSubmitEditing={() => {}}
            editable={!isPendingSubmit}
          ></TextInput>
          {typedText ? (
            <LFLink
              testID="content-item-input-send-btn:NoteInput"
              style={{
                position: 'absolute',
                right: -5,
                top: -5,
                paddingVertical: 9,
                paddingHorizontal: 13,
              }}
              onPress={submitCurrentTypedText}
            >
              <View
                pointerEvents="none"
                style={{
                  height: 30,
                  width: 30,
                  borderRadius: 15,
                  backgroundColor: '#2F80ED',

                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isPendingSubmit ? (
                  <ActivityIndicator color="white" />
                ) : editSource ? (
                  <Ionicons
                    name="ios-checkmark-sharp"
                    size={15}
                    color="white"
                  />
                ) : (
                  <Ionicons
                    name="ios-send"
                    size={15}
                    color="white"
                    style={{ marginLeft: 2 }}
                  />
                )}
              </View>
            </LFLink>
          ) : null}
        </Flex>
        <WhiteSpace />
      </Flex>
    );
  }
);

export default NoteInput;

const styles = StyleSheet.create({
  emojiBtn: {
    padding: 10,
  },
  textInput: {
    // borderColor: '#ccc',
    // borderWidth: 1,

    paddingVertical: 10,
    borderRadius: 18,
    // maxHeight: 100,
    minHeight: 40,
    flex: 1,
    paddingHorizontal: 10,
    paddingRight: 40,
    backgroundColor: '#F2F2F2',
  },
});
