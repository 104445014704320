/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MembershipPlanAndPricingScreenStatusesQueryVariables = {
    loginUserId: string;
    membershipOwnerId: string;
    status: string;
};
export type MembershipPlanAndPricingScreenStatusesQueryResponse = {
    readonly membershipStatuses: {
        readonly count: number;
    };
};
export type MembershipPlanAndPricingScreenStatusesQuery = {
    readonly response: MembershipPlanAndPricingScreenStatusesQueryResponse;
    readonly variables: MembershipPlanAndPricingScreenStatusesQueryVariables;
};



/*
query MembershipPlanAndPricingScreenStatusesQuery(
  $loginUserId: ID!
  $membershipOwnerId: ID!
  $status: String!
) {
  membershipStatuses(where: {member: {have: {objectId: {equalTo: $loginUserId}}}, membership: {have: {author: {have: {objectId: {equalTo: $membershipOwnerId}}}}}, status: {equalTo: $status}}) {
    count
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loginUserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipOwnerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "equalTo",
                        "variableName": "loginUserId"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "objectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "have"
              }
            ],
            "kind": "ObjectValue",
            "name": "member"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": [
                              {
                                "kind": "Variable",
                                "name": "equalTo",
                                "variableName": "membershipOwnerId"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "objectId"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "have"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "author"
                  }
                ],
                "kind": "ObjectValue",
                "name": "have"
              }
            ],
            "kind": "ObjectValue",
            "name": "membership"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "status"
              }
            ],
            "kind": "ObjectValue",
            "name": "status"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "MembershipStatusConnection",
    "kind": "LinkedField",
    "name": "membershipStatuses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipPlanAndPricingScreenStatusesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MembershipPlanAndPricingScreenStatusesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "db92496043f63b82144578dd82840ea8",
    "id": null,
    "metadata": {},
    "name": "MembershipPlanAndPricingScreenStatusesQuery",
    "operationKind": "query",
    "text": "query MembershipPlanAndPricingScreenStatusesQuery(\n  $loginUserId: ID!\n  $membershipOwnerId: ID!\n  $status: String!\n) {\n  membershipStatuses(where: {member: {have: {objectId: {equalTo: $loginUserId}}}, membership: {have: {author: {have: {objectId: {equalTo: $membershipOwnerId}}}}}, status: {equalTo: $status}}) {\n    count\n  }\n}\n"
  }
};
})();
(node as any).hash = '045c23409343e2fd141c1ef93de2bfad';
export default node;
