/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PromotionItemViewFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly badges: ReadonlyArray<{
        readonly value?: unknown;
    } | null> | null;
    readonly title: string | null;
    readonly subtitle: string | null;
    readonly titleColor: string | null;
    readonly subtitleColor: string | null;
    readonly bgColor: string | null;
    readonly image: {
        readonly url: string;
    } | null;
    readonly PCModeImage: {
        readonly url: string;
    } | null;
    readonly " $refType": "PromotionItemViewFragment";
};
export type PromotionItemViewFragment$data = PromotionItemViewFragment;
export type PromotionItemViewFragment$key = {
    readonly " $data"?: PromotionItemViewFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"PromotionItemViewFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromotionItemViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "objectId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "badges",
      "plural": true,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "type": "Element",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "titleColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitleColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bgColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FileInfo",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FileInfo",
      "kind": "LinkedField",
      "name": "PCModeImage",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HomePromotion",
  "abstractKey": null
};
})();
(node as any).hash = '9d8c47683bd1ab8519e752b73302a430';
export default node;
