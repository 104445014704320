import React, { useState } from 'react';
import {
  TextInput,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
} from 'react-native';
import { Flex, WhiteSpace, Toast, Modal } from '@ant-design/react-native';
import 'antd-mobile/lib/popover/style/css';
import 'antd-mobile/lib/icon/style/css';
import { useAuth } from '../hooks/auth';
import _ from 'lodash';
import NavBar, { HistoryBackButton } from '../components/NavBar';
import { useHistory, useParams } from 'react-router';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import LFLink from '../components/LFLink';
import { useBrowserTitleUpdateEffect } from '../hooks/browserTitle';
import { Ionicons } from '@expo/vector-icons';
import {
  ProfileEditorScreenUserUpdateMutation,
  UpdateUserInput,
} from '../__generated__/ProfileEditorScreenUserUpdateMutation.graphql';
import { ProfileEditorScreenViewerQuery } from '../__generated__/ProfileEditorScreenViewerQuery.graphql';
import HashEditorScreen from './HashEditorScreen';
import { HashItem, HashList } from '../components/HashList';
import { useMobileDimensions } from '../hooks/useMobileDimensions';
import FileInput from '../components/FileInput';
import { Uploadable, UploadableMap } from 'relay-runtime';
import { resizedImageURL } from '../helper/resizedImageURL';
import LFText from '../components/typo/LFText';

export const USER_MAX_TAGS = 10;

const ProfileEditorScreen = () => {
  const modalHeight = useMobileDimensions().height - 80;
  const { user } = useAuth();
  const history = useHistory();
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const [isHashEditorVisible, setIsHashEditorVisible] =
    useState<boolean>(false);
  const currentUserObjectId = user?.objectId;
  const {
    viewer: { user: curUser },
  } = useLazyLoadQuery<ProfileEditorScreenViewerQuery>(
    graphql`
      query ProfileEditorScreenViewerQuery {
        viewer {
          user {
            id
            objectId
            name
            description
            originProfileURL
            jobTitle
            tags {
              ... on Element {
                value
              }
            }
            profileImage {
              url
              __typename
            }
          }
        }
      }
    `,
    {
      userObjectId: currentUserObjectId,
    }
  );
  // 하단 hook들은 위의 'useLazyLoadQuery'를 받는 'curUser'값을 초기값으로 하는 상태관리코드여서 'useLazyLoadQuery' 하단에 위치해야합니다.
  const [name, setName] = useState(curUser.name || '');
  const [jobTitle, setJobTitle] = useState(curUser.jobTitle || '');

  // tags를 관리하는 state입니다.
  // graphQL이 주는 tags 데이터는 [{value: '...'}] || undefined입니다.
  // 그래서 아래처럼 초기값 설정했습니다.
  const [tags, setTags] = useState(
    curUser.tags?.map((tag: any) => tag.value) || []
  );
  const [description, setDescription] = useState(curUser.description || '');

  useBrowserTitleUpdateEffect(`Profile - ${curUser.name}`);

  const [commitProfileInfoUpdate, isProfileInfoUpdateInFlight] =
    useMutation<ProfileEditorScreenUserUpdateMutation>(
      graphql`
        mutation ProfileEditorScreenUserUpdateMutation(
          $input: UpdateUserInput!
        ) {
          updateUser(input: $input) {
            user {
              id
              objectId
              name
              jobTitle
              description
              originProfileURL
              tags {
                ... on Element {
                  value
                }
              }
              profileImage {
                url
                __typename
              }
            }
          }
        }
      `
    );

  const onSave = () => {
    const updateInput: UpdateUserInput = {
      id: currentUserObjectId,
      fields: {
        name: name,
        jobTitle: jobTitle,
        description: description,
        tags: tags,
      },
    };

    const uploadables: UploadableMap | undefined =
      profileImageFile && updateInput.fields
        ? {
            'variables.input.fields.profileImage.upload': profileImageFile,
          }
        : undefined;

    // TODO: file 을 업로드하기 위해서는 fetchGraphQL 함수를 수정해야한다. (업로드일 경우 form 형태로...)
    // https://github.com/facebook/relay/issues/1844#issuecomment-316893590
    commitProfileInfoUpdate({
      variables: {
        input: updateInput,
      },
      onCompleted: (response) => {
        console.log(response);
        history.push('/me');
      },
      onError: (e) => {
        console.log(e);
      },
      uploadables: uploadables,
    });
  };

  const onCloseTag = (tag: any) => {
    setTags((prev) => prev.filter((item: any) => item !== tag));
  };

  return (
    <ScrollView style={styles.contentContainer}>
      {/* 현재 프로필 페이지에서는 프로필 영역과 네비게이션 영역 구분이 없고 특정 색상이 입혀졌기에 해당 스타일 적용을 위해 'style' prop 활용 */}
      <NavBar
        left={<HistoryBackButton />}
        right={
          isProfileInfoUpdateInFlight ? (
            <ActivityIndicator color={'gray'} />
          ) : (
            <TouchableOpacity
              onPress={() => {
                // 10개 이상 태그입력 시 저장이 안되고 alert 창이 발생
                tags?.length <= USER_MAX_TAGS
                  ? onSave()
                  : Toast.info('태그는 최대 10개까지만 입력 가능합니다.', 2);
              }}
            >
              <LFText>저장</LFText>
            </TouchableOpacity>
          )
        }
        title="프로필 수정"
        style={{ borderBottomWidth: 1, marginBottom: 28 }}
      />

      <Flex
        direction="column"
        justify="center"
        style={{ paddingHorizontal: 16 }}
      >
        <View
          style={{
            width: 80,
            height: 80,
          }}
        >
          {profileImageFile ? (
            <img
              style={{ borderRadius: 40 }}
              src={URL.createObjectURL(profileImageFile)}
            />
          ) : (
            <Image
              accessibilityLabel=""
              style={styles.userImage}
              source={{
                uri: resizedImageURL(
                  curUser.profileImage?.url,
                  200,
                  200,
                  curUser.originProfileURL
                ),
              }}
            />
          )}
        </View>
        <WhiteSpace size="md" />
        <FileInput
          accept="image/*"
          onChange={(file) => {
            setProfileImageFile(file);
          }}
        >
          <LFText style={{ color: '#2F6EE9' }}>프로필 사진 변경</LFText>
        </FileInput>
      </Flex>

      <Flex
        direction="column"
        align="start"
        style={{ flex: 1, paddingHorizontal: 16 }}
      >
        <WhiteSpace size="md" />

        <LFText style={styles.categoryText}>이름</LFText>
        <WhiteSpace size="lg" />
        <TextInput
          editable={!isProfileInfoUpdateInFlight}
          placeholder="이름 입력"
          onChangeText={setName}
          value={name}
          style={styles.categoryTextInput}
        />

        <WhiteSpace size="xl" />
        <WhiteSpace size="lg" />

        <LFText style={styles.categoryText}>직군</LFText>
        <WhiteSpace size="lg" />
        <TextInput
          editable={!isProfileInfoUpdateInFlight}
          placeholder="직군 입력"
          onChangeText={setJobTitle}
          value={jobTitle}
          style={styles.categoryTextInput}
        />

        <WhiteSpace size="xl" />
        <WhiteSpace size="lg" />

        <LFText style={styles.categoryText}>자기소개</LFText>
        <WhiteSpace size="lg" />
        <TextInput
          editable={!isProfileInfoUpdateInFlight}
          placeholder="자기소개 입력"
          onChangeText={setDescription}
          value={description}
          style={styles.categoryTextInput}
        />

        <WhiteSpace size="xl" />
        <WhiteSpace size="lg" />

        <LFText style={styles.categoryText}>관심분야</LFText>
        <WhiteSpace size="lg" />

        <TouchableOpacity
          style={{ width: '100%' }}
          onPress={() => {
            setIsHashEditorVisible(true);
          }}
          disabled={isProfileInfoUpdateInFlight}
          //@ts-ignore
          pointerEvents={'box-only'}
        >
          <TextInput
            editable={false}
            placeholder="#태그 입력(최대 10개)"
            style={[styles.categoryTextInput, { fontWeight: 'normal' }]}
          />
        </TouchableOpacity>
        <WhiteSpace size="lg" />
        <HashList>
          {tags.map((t) => (
            <HashItem
              key={t}
              title={t}
              onPressClose={() => {
                onCloseTag(t);
              }}
            />
          ))}
        </HashList>
        {/* <Flex direction="row" wrap="wrap">
          {_.map(tags, (tag, key) => {
            return (
              <View key={key} style={styles.tagView}>
                <LFText>#{tag}</LFText>
                <LFLink
                  onPress={() => {
                    onCloseTag(tag);
                  }}
                >
                  <Ionicons name="close" size={24} color="black" />
                </LFLink>
              </View>
            );
          })}
        </Flex> */}
        <WhiteSpace size="lg" />
        <Modal
          popup
          visible={isHashEditorVisible}
          animationType="slide-up"
          style={[styles.subScreenModal, { height: modalHeight }]}
        >
          <HashEditorScreen
            defaultHashes={tags}
            onPressClose={() => {
              setIsHashEditorVisible(false);
            }}
            onPressSave={(result) => {
              setIsHashEditorVisible(false);
              setTags(result);
            }}
          />
        </Modal>
      </Flex>
    </ScrollView>
  );
};

export default ProfileEditorScreen;

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  userImage: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    borderRadius: 40,
  },
  categoryText: {
    fontSize: 13,
  },
  categoryTextInput: {
    width: '100%',
    borderBottomWidth: 1,
    borderColor: '#E5E5E5',
    fontSize: 17,
    fontWeight: '700',
  },
  tagView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginRight: 8,
    padding: 10,
    borderRadius: 8,
    backgroundColor: '#F0F1F3',
  },
  subScreenModal: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    overflow: 'hidden',
    height: '90%',
    // position: 'sticky',
  },
});
