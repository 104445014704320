import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import React from 'react';
import { Flex } from '@ant-design/react-native';
import _ from 'lodash';
import LFWhiteSpace from './LFWhiteSpace';
import { colors } from '../constants/styleGuide';

const DotPagination: React.FC<{
  style?: ViewStyle;
  total: number;
  active: number;
  activeDotStyle?: ViewStyle;
  defaultDotStyle?: ViewStyle;
}> = ({ style, total, active, activeDotStyle, defaultDotStyle }) => {
  return (
    <Flex style={style}>
      {_.map(_.range(total), (value) => {
        const isLast = total - 1 === value;
        const isActiveNode = active === value;
        return (
          <React.Fragment key={'idx_' + value}>
            <View
              key={value}
              style={[
                styles.defaultDot,
                defaultDotStyle,
                ...(isActiveNode ? [styles.activeDot, activeDotStyle] : []),
              ]}
            />
            {isLast ? null : <LFWhiteSpace direction="row" size={'xs'} />}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export default DotPagination;

const styles = StyleSheet.create({
  defaultDot: {
    width: 6,
    opacity: 0.5,
    height: 6,
    backgroundColor: colors.SECONDARY_WHITE,
    borderRadius: 150,
  },
  activeDot: {
    width: 20,
    opacity: 1,
  },
});
