import {
  Image,
  ImageStyle,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import React, { useRef } from 'react';
import { Flex, WhiteSpace } from '@ant-design/react-native';
import LFWhiteSpace from './LFWhiteSpace';
import LFText from './typo/LFText';
import { colors } from '../constants/styleGuide';
import { graphql, useFragment } from 'react-relay';
import PreviewImage from './PreviewImage';
import dayjs from 'dayjs';
import _ from 'lodash';
import { PathItemView2_Fragment$key } from '../__generated__/PathItemView2_Fragment.graphql';
import UserProfileImage from './UserProfileImage';
import BookMarkButton from './BookMarkButton';
import ScrollContainer from 'react-indiana-drag-scroll';
import LFLink from './LFLink';
import { useHistory } from 'react-router-dom';
import BookMarkButtonForPreview from './BookMarkButtonForPreview';
import getPathImagenameUsingTimestamp from '../helper/getPathImagenameUsingTimestamp';
import getPlaceholderContentImageNameUsingSeed from '../helper/getPlaceholderContentImageNameUsingSeed';
import { EMOJIS } from './ActivityStreamItemView';

const PathItemView2: React.FC<{
  pathFragment: PathItemView2_Fragment$key | null;
  style?: ViewStyle;
  scraped?: { status: boolean; reactionId?: string };
  onScrape?: () => void;
  onScrollStart?: () => void;
  onScrollEnd?: () => void;
}> = ({
  pathFragment,
  style,
  scraped,
  onScrape,
  onScrollStart,
  onScrollEnd,
}) => {
  const path = useFragment(
    graphql`
      fragment PathItemView2_Fragment on Path {
        id
        objectId
        title
        description
        estimate
        isSeries
        published
        createdAt
        instructionCards(order: [seq_ASC, createdAt_ASC]) {
          edges {
            node {
              id
              objectId
              sources {
                __typename
                ... on ContentItem {
                  id
                  objectId
                  content {
                    id
                    objectId
                    thumbURL
                    type
                    createdAt
                  }
                }
                ... on Path {
                  id
                  objectId
                  createdAt
                  coverImage {
                    url
                  }
                }
              }
            }
          }
        }
        analytics {
          emojiTotalCount
          emojiCountMap
        }
        membership {
          id
          objectId
        }
        coverImage {
          url
        }
        author {
          id
          objectId
          name
          originProfileURL
          profileImage {
            url
          }
          ...UserProfileImageFragment
        }
      }
    `,
    pathFragment || null
  );
  const history = useHistory();
  const sources = _.chain(path?.instructionCards.edges)
    .map((e) => e?.node)
    .map((c) => _.head(c?.sources))
    .filter(
      (el) => el?.__typename === 'ContentItem' || el?.__typename === 'Path'
    )
    .value();

  const pathEmojiCountMapExtractedBookMark = _.chain(
    path?.analytics?.emojiCountMap as {
      [key in EMOJIS]: number;
    }
  )
    .map((v, k) => ({ [k]: v }))
    .reduce((prev, curr) => {
      return _.has(curr, 'bookmark') ? { ...prev } : { ...prev, ...curr };
    }, {})
    .value();
  const pathInfo = {
    createAt: path?.createdAt,
    objectId: path?.objectId,
    thumbURLOfPath: path?.coverImage?.url,
    title: path?.title,
    authorObjId: path?.author?.objectId,
    authorName: path?.author?.name,
    description: path?.description,
    estimate: path?.estimate,
    numberOfNotes: path?.instructionCards.edges?.length,
    emojiTotalCount: _.reduce(
      pathEmojiCountMapExtractedBookMark,
      (prev, curr) => curr + prev,
      0
    ),
    emojiCountMap: pathEmojiCountMapExtractedBookMark,
    isMembership: path?.membership ?? false,
    isSeries: path?.isSeries ?? false,
  };

  const isSlidingRef = useRef(false);
  const BOOK_MARK_REACTION = 'bookmark';
  const BG_COLORS_FOR_PATH_IMAGE = [
    'rgb(200, 172, 99)',
    'rgb(217, 165, 65)',
    'rgb(176, 195, 55)',
    'rgb(95, 173, 76)',
    'rgb(3, 182, 139)',
    'rgb(64, 164, 220)',
    'rgb(83, 124, 228)',
    'rgb(71, 84, 199)',
    'rgb(123, 109, 206)',
    'rgb(156, 107, 194)',
  ];

  const indexForPathImageColor =
    dayjs(path?.createdAt as string).valueOf() %
    BG_COLORS_FOR_PATH_IMAGE.length;

  return (
    <View style={[styles.featuredPathContainer, style]}>
      {/* 러닝패스 이미지 영역 */}
      <View
        style={[
          styles.featuredPathThumbnailContainer,
          { backgroundColor: BG_COLORS_FOR_PATH_IMAGE[indexForPathImageColor] },
        ]}
      >
        {/* 유저 프로필 이미지 & 이름 */}
        <Flex>
          <UserProfileImage
            userFrgmt={path?.author}
            size={24}
            style={{ borderWidth: 1, borderColor: colors.BORDER_10 }}
          />
          <LFWhiteSpace size="xs" direction="row" />
          <Flex style={{ flex: 1 }}>
            <LFLink
              onPress={() => history.push(`u/${pathInfo?.authorObjId}`)}
              style={{ flexDirection: 'row' }}
            >
              <LFText
                numberOfLines={1}
                style={[styles.featuredPathAuthorName, { flexShrink: 1 }]}
              >
                {pathInfo?.authorName}
              </LFText>
              <LFWhiteSpace size="xs" direction="row" />
              <LFText
                style={[styles.featuredPathAuthorName, { flexShrink: 0 }]}
              >
                러닝패스
              </LFText>
            </LFLink>
          </Flex>
        </Flex>

        <LFWhiteSpace size="md" direction={'column'} />

        {/* 이미지 위의 타이틀 */}
        <Flex align="start">
          {Boolean(pathInfo?.title) ? (
            <LFText style={styles.titleOnImage} numberOfLines={2}>
              {pathInfo?.title}
            </LFText>
          ) : (
            <LFText
              style={[styles.titleOnImage, { color: colors.TEXT_60 }]}
              numberOfLines={2}
            >
              제목을 입력해 주세요.
            </LFText>
          )}
        </Flex>

        <LFWhiteSpace size="md" direction={'column'} />

        {/* 이미지 위의 학습노트 */}
        {sources.length ? (
          <ScrollContainer
            style={{
              marginBottom: -32,
              marginLeft: -24,
              marginRight: -24,
              paddingLeft: 24,
              paddingBottom: 20,
            }}
            horizontal
            onStartScroll={() => {
              isSlidingRef.current = true;
              _.isFunction(onScrollStart) && onScrollStart();
            }}
            onEndScroll={() => {
              setTimeout(() => {
                isSlidingRef.current = false;
                _.isFunction(onScrollEnd) && onScrollEnd();
              }, 0);
            }}
          >
            <Flex style={{ alignSelf: 'stretch' }}>
              {_.map(sources, (source, idx) => {
                const imageSourceAndTagLabel = {
                  imageSource:
                    source?.__typename === 'ContentItem'
                      ? source.content?.thumbURL
                        ? { uri: source.content?.thumbURL || '' }
                        : require(`../assets/images/${getPlaceholderContentImageNameUsingSeed(
                            {
                              seed: dayjs(
                                (source.content?.createdAt || 0) as number
                              ).valueOf(),
                            }
                          )}`)
                      : source?.__typename === 'Path'
                      ? source.coverImage?.url
                        ? { uri: source.coverImage.url }
                        : require(`../assets/images/${getPathImagenameUsingTimestamp(
                            {
                              seed: dayjs(
                                (source.createdAt || 0) as number
                              ).valueOf(),
                            }
                          )}`)
                      : '',
                  tagLabel:
                    source?.__typename === 'ContentItem'
                      ? source.content?.type === 'LINK'
                        ? 'POST'
                        : source.content?.type
                      : source?.__typename === 'Path'
                      ? 'PATH'
                      : '',
                };
                return (
                  // 이미지
                  <View style={{ position: 'relative' }} key={idx}>
                    <Flex align="stretch" style={styles.contentItem}>
                      <Image
                        key={idx}
                        source={imageSourceAndTagLabel.imageSource}
                        style={styles.contentItemImage}
                      />
                    </Flex>
                    <View style={styles.tagWrapper}>
                      <LFText style={styles.tagText}>
                        {imageSourceAndTagLabel.tagLabel}
                      </LFText>
                    </View>
                  </View>
                );
              })}
            </Flex>
          </ScrollContainer>
        ) : (
          <View style={{ width: 210, height: 102 }} />
        )}
      </View>

      <LFWhiteSpace size={33} direction="column" />

      <View style={{ paddingHorizontal: 24, flex: 1 }}>
        <Flex align="center">
          <Flex
            align="center"
            style={[
              styles.badgeItem,
              {
                backgroundColor: pathInfo.isMembership
                  ? colors.PRIMARY_20
                  : colors.SECONDARY_Y_100,
                paddingRight: 6,
              },
            ]}
          >
            <View style={styles.badgeLogo}>
              <PreviewImage
                style={styles.badgeImage as StyleProp<ImageStyle>}
                source={require('../assets/images/free-lf-logo.png')}
              />
            </View>
            <LFText
              style={[
                styles.badgeLabel,
                pathInfo.isMembership ? { color: colors.TEXT_DARK } : null,
              ]}
            >
              {pathInfo.isMembership ? '멤버십' : '무료'}
            </LFText>
          </Flex>
          {pathInfo.isSeries && (
            <View
              style={[
                styles.badgeItem,
                {
                  marginLeft: 4,
                  borderRadius: 4,
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                },
              ]}
            >
              <LFText style={styles.badgeLabel}>시리즈</LFText>
            </View>
          )}
          {!path?.published && (
            <>
              <LFWhiteSpace size="xs" direction="row" />
              <View style={styles.privateBadgeContainer}>
                <LFText style={styles.privateBadgeText}>비공개</LFText>
              </View>
            </>
          )}
        </Flex>

        <WhiteSpace size="sm" />

        <View>
          <LFText numberOfLines={1} style={styles.featuredPathTitle}>
            {pathInfo?.title}
          </LFText>
        </View>

        <WhiteSpace size="sm" />

        {/* 디스크립션 영역 */}

        <Flex>
          <LFText numberOfLines={2} style={styles.descriptionText}>
            {pathInfo.description}
          </LFText>
        </Flex>

        <WhiteSpace size="lg" />

        {/* 학습 시간 & 학습노트 수 영역 */}

        <Flex direction="column" align="center" style={styles.subInfoWrapper}>
          {pathInfo.estimate && (
            <>
              <Flex style={styles.subInfoItem} align="center">
                <LFText style={styles.subInfoLeftText}>학습 시간</LFText>
                <View style={styles.dottedLine} />
                <LFText numberOfLines={1} style={styles.subInfoRightText}>
                  <LFText style={{ color: colors.PRIMARY_100 }}>
                    {pathInfo.estimate}
                  </LFText>
                  시간
                </LFText>
              </Flex>
              <LFWhiteSpace size="sm" />
            </>
          )}

          <Flex style={styles.subInfoItem} align="center">
            <LFText style={styles.subInfoLeftText}>학습 콘텐츠</LFText>
            <View style={styles.dottedLine} />
            <LFText style={styles.subInfoRightText}>
              <LFText style={{ color: colors.PRIMARY_100 }}>
                {pathInfo?.numberOfNotes ?? 0}
              </LFText>
              개
            </LFText>
          </Flex>
        </Flex>
        <LFWhiteSpace size="sm" />

        {/* 러닝패스 보기 버튼 영역 */}

        <LFLink to={`/path/${pathInfo.objectId}`} style={styles.pathViewButton}>
          <LFText style={styles.pathViewBtnText}>러닝패스 보기</LFText>
        </LFLink>

        {/*  이모지 & 북마크 영역  */}

        <LFWhiteSpace size="lg" />

        <Flex align="center">
          {Boolean(pathInfo.emojiTotalCount) && (
            <Flex direction="row">
              {_.map(pathInfo.emojiCountMap, (_, key) => (
                <View style={styles.emojiCircle} key={key}>
                  <LFText style={{ fontSize: 10 }}>{key}</LFText>
                </View>
              ))}
              <LFWhiteSpace direction="row" size={'xs'} />
              <LFText style={styles.additionalInfo}>
                <LFText style={{ color: colors.TEXT_80 }}>
                  {pathInfo.emojiTotalCount?.toLocaleString('ko-KR')}
                </LFText>
              </LFText>
            </Flex>
          )}
          <LFText style={[styles.additionalInfo, { marginLeft: 'auto' }]}>
            {onScrape ? (
              <BookMarkButtonForPreview
                targetClassName="Path"
                targetId={pathInfo?.objectId || ''}
                onCompleted={onScrape}
                scraped={scraped}
              />
            ) : (
              <BookMarkButton
                targetClassName="Path"
                targetId={pathInfo?.objectId || ''}
              />
            )}
          </LFText>
        </Flex>
        <LFWhiteSpace size="lg" />
      </View>
    </View>
  );
};

export default PathItemView2;
const styles = StyleSheet.create({
  featuredPathContainer: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    shadowColor: 'rgba(58, 69, 99, 0.2)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    flex: 1,
  },
  featuredPathThumbnailContainer: {
    alignSelf: 'stretch',
    position: 'relative',
    padding: 24,
    paddingBottom: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  featuredPathThumbnail: {
    width: '100%',
    paddingBottom: '56.25%',
    height: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  featuredPathAuthorName: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_WHITE,
    textShadowColor: '#42495926.4000198',
    textShadowOffset: {
      width: 0,
      height: 1,
    },
    textShadowRadius: 2,
  },
  badgeItem: {
    backgroundColor: colors.BG_3,
    padding: 4,
    borderRadius: 100,
    alignSelf: 'flex-start',
  },
  badgeImage: {
    width: 10,
    height: 10,
  },
  badgeLogo: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 2,
  },
  badgeLabel: {
    fontSize: 10,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    // transform이 ts 에러 발생시킴
    // @ts-ignore
    textTransform: 'uppercase',
  },

  featuredPathTitle: {
    wordBreak: 'keep-all',
    fontSize: 16,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  privateBadgeContainer: {
    borderRadius: 100,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.BORDER_20,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  privateBadgeText: {
    fontSize: 10,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_100,
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  additionalInfo: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  emojiCircle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: colors.BORDER_WHITE,
    backgroundColor: colors.SECONDARY_Y_100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  subInfoWrapper: {
    marginTop: 'auto',
    padding: 12,
    borderRadius: 8,
    backgroundColor: colors.BG_2,
  },
  subInfoItem: {
    width: '100%',
  },
  subInfoLeftText: {
    width: 67,
    flexShrink: 0,
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 18,
    letterSpacing: 0,
    color: colors.TEXT_60,
  },
  subInfoRightText: {
    width: 45,
    flexShrink: 0,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 16.7,
    letterSpacing: 0,
    textAlign: 'right',
    color: colors.SECONDARY_BLACK,
  },

  dottedLine: {
    flex: 1,
    flexShrink: 1,
    height: 1,
    borderStyle: 'dashed',
    borderWidth: 1,
    borderRadius: 1,
    borderColor: colors.BORDER_40,
  },
  titleOnImage: {
    wordBreak: 'keep-all',
    fontSize: 30,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 40,
    height: 83,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_WHITE,
    textShadowColor: '#3a456333.00000cc',
    textShadowOffset: {
      width: 0,
      height: 4,
    },
    textShadowRadius: 10,
  },
  contentItem: {
    justifyContent: 'center',
    marginRight: 8,
    width: 210,
    height: 120,
    overflow: 'hidden',
    borderRadius: 8,
    shadowColor: 'rgba(101, 112, 152, 0.2)',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 10,
    shadowOpacity: 1,
    backgroundColor: colors.BG_WHITE,
  },
  contentItemImage: {
    width: '101%',
    height: 0,
    paddingBottom: '56.8%',
    borderRadius: 8,
    // borderWidth: 1,
    // backgroundColor: '#00000019.8000066',
    // borderStyle: 'solid',
    // borderColor: colors.BORDER_10,
    shadowColor: 'rgba(0, 0, 0, 0.25)',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  tagWrapper: {
    padding: 4,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 10,
    opacity: 0.9,
    borderRadius: 4,
    backgroundColor: colors.SECONDARY_BLACK,
  },
  tagText: {
    fontSize: 10,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_WHITE,
  },
  pathViewButton: {
    paddingVertical: 12,
    borderRadius: 8,
    backgroundColor: colors.PRIMARY_10,
  },
  pathViewBtnText: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.PRIMARY_100,
  },
});
