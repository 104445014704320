/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TraversalScreenPathNodeQueryVariables = {
    pathId: string;
};
export type TraversalScreenPathNodeQueryResponse = {
    readonly path: {
        readonly id: string;
        readonly objectId: string;
        readonly title: string | null;
        readonly learnCount: number | null;
        readonly published: boolean | null;
        readonly author: {
            readonly id: string;
            readonly objectId: string;
            readonly name: string | null;
        } | null;
        readonly tags: ReadonlyArray<{
            readonly value?: unknown;
        } | null> | null;
        readonly contentItemList: ReadonlyArray<{
            readonly id?: string;
            readonly objectId?: string;
            readonly title?: string | null;
            readonly content?: {
                readonly id: string;
                readonly objectId: string;
                readonly thumbURL: string | null;
            } | null;
            readonly " $fragmentRefs": FragmentRefs<"ContentItemView_Fragment">;
        } | null> | null;
    };
};
export type TraversalScreenPathNodeQuery = {
    readonly response: TraversalScreenPathNodeQueryResponse;
    readonly variables: TraversalScreenPathNodeQueryVariables;
};



/*
query TraversalScreenPathNodeQuery(
  $pathId: ID!
) {
  path(id: $pathId) {
    id
    objectId
    title
    learnCount
    published
    author {
      id
      objectId
      name
    }
    tags {
      __typename
      ... on Element {
        value
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    contentItemList {
      __typename
      ... on ContentItem {
        id
        objectId
        title
        content {
          id
          objectId
          thumbURL
        }
        ...ContentItemView_Fragment
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
  }
}

fragment ContentItemView_Fragment on ContentItem {
  id
  objectId
  title
  description
  content {
    id
    objectId
    type
    title
    description
    thumbURL
    link
  }
  author {
    objectId
    name
    originProfileURL
    profileImage {
      url
    }
    id
  }
  noteData {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  tags {
    __typename
    ... on Element {
      value
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pathId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "pathId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "learnCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "Element",
  "abstractKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbURL",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v13 = [
  (v11/*: any*/),
  (v9/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TraversalScreenPathNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Path",
        "kind": "LinkedField",
        "name": "path",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "contentItemList",
            "plural": true,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Content",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ContentItemView_Fragment"
                  }
                ],
                "type": "ContentItem",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TraversalScreenPathNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Path",
        "kind": "LinkedField",
        "name": "path",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "contentItemList",
            "plural": true,
            "selections": [
              (v11/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Content",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "originProfileURL",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileInfo",
                        "kind": "LinkedField",
                        "name": "profileImage",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "noteData",
                    "plural": true,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "type": "ContentItem",
                "abstractKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76a732cf1457e4be9047a7d082d3e4c4",
    "id": null,
    "metadata": {},
    "name": "TraversalScreenPathNodeQuery",
    "operationKind": "query",
    "text": "query TraversalScreenPathNodeQuery(\n  $pathId: ID!\n) {\n  path(id: $pathId) {\n    id\n    objectId\n    title\n    learnCount\n    published\n    author {\n      id\n      objectId\n      name\n    }\n    tags {\n      __typename\n      ... on Element {\n        value\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    contentItemList {\n      __typename\n      ... on ContentItem {\n        id\n        objectId\n        title\n        content {\n          id\n          objectId\n          thumbURL\n        }\n        ...ContentItemView_Fragment\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment ContentItemView_Fragment on ContentItem {\n  id\n  objectId\n  title\n  description\n  content {\n    id\n    objectId\n    type\n    title\n    description\n    thumbURL\n    link\n  }\n  author {\n    objectId\n    name\n    originProfileURL\n    profileImage {\n      url\n    }\n    id\n  }\n  noteData {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  tags {\n    __typename\n    ... on Element {\n      value\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '8a87d30f9bb7d95d561388df66bafde4';
export default node;
