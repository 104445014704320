/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MembershipPlanAndPricingScreenMembershipPricingQueryVariables = {
    authorObjectId: string;
};
export type MembershipPlanAndPricingScreenMembershipPricingQueryResponse = {
    readonly membershipPricings: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly objectId: string;
                readonly price: number | null;
                readonly duration: number | null;
                readonly membership: {
                    readonly id: string;
                    readonly objectId: string;
                    readonly title: string | null;
                    readonly level: string | null;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"MembershipPricingCardFragment">;
            } | null;
        } | null> | null;
    };
};
export type MembershipPlanAndPricingScreenMembershipPricingQuery = {
    readonly response: MembershipPlanAndPricingScreenMembershipPricingQueryResponse;
    readonly variables: MembershipPlanAndPricingScreenMembershipPricingQueryVariables;
};



/*
query MembershipPlanAndPricingScreenMembershipPricingQuery(
  $authorObjectId: ID!
) {
  membershipPricings(where: {author: {have: {objectId: {equalTo: $authorObjectId}}}, disabled: {notEqualTo: true}}) {
    edges {
      node {
        ...MembershipPricingCardFragment
        id
        objectId
        price
        duration
        membership {
          id
          objectId
          title
          level
        }
      }
    }
  }
}

fragment MembershipPricingCardFragment on MembershipPricing {
  id
  objectId
  duration
  price
  author {
    id
    objectId
    name
  }
  membership {
    title
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "authorObjectId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "equalTo",
                    "variableName": "authorObjectId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "objectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "have"
          }
        ],
        "kind": "ObjectValue",
        "name": "author"
      },
      {
        "kind": "Literal",
        "name": "disabled",
        "value": {
          "notEqualTo": true
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MembershipPlanAndPricingScreenMembershipPricingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MembershipPricingConnection",
        "kind": "LinkedField",
        "name": "membershipPricings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipPricingEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipPricing",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Membership",
                    "kind": "LinkedField",
                    "name": "membership",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MembershipPricingCardFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MembershipPlanAndPricingScreenMembershipPricingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MembershipPricingConnection",
        "kind": "LinkedField",
        "name": "membershipPricings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MembershipPricingEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipPricing",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Membership",
                    "kind": "LinkedField",
                    "name": "membership",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c4eaa6453fb825efea70aca3ba03aa3",
    "id": null,
    "metadata": {},
    "name": "MembershipPlanAndPricingScreenMembershipPricingQuery",
    "operationKind": "query",
    "text": "query MembershipPlanAndPricingScreenMembershipPricingQuery(\n  $authorObjectId: ID!\n) {\n  membershipPricings(where: {author: {have: {objectId: {equalTo: $authorObjectId}}}, disabled: {notEqualTo: true}}) {\n    edges {\n      node {\n        ...MembershipPricingCardFragment\n        id\n        objectId\n        price\n        duration\n        membership {\n          id\n          objectId\n          title\n          level\n        }\n      }\n    }\n  }\n}\n\nfragment MembershipPricingCardFragment on MembershipPricing {\n  id\n  objectId\n  duration\n  price\n  author {\n    id\n    objectId\n    name\n  }\n  membership {\n    title\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '00e3f215bc5ed0d1dcf28026c195f48a';
export default node;
