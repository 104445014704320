import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import LFText from '../components/typo/LFText';

const BookmarkletScreen = () => {
  return (
    <View style={{ padding: 30, alignItems: 'center' }}>
      <LFText>
        ⬇️⬇️ 아래{' '}
        <LFText style={{ fontWeight: 'bold' }}>"LearnFit 노트"</LFText> 링크를
        북마크바로 드래그 앤 드롭 하세요. ⬇️⬇️
      </LFText>
      <br />
      <View
        style={{
          borderWidth: 1,
          borderColor: 'gray',
          borderRadius: 5,
          padding: 5,
        }}
      >
        <a
          style={{
            fontWeight: 'bold',
            fontSize: 20,
          }}
          href="javascript: (()=>{ location.href = 'https://www.learnfit.ai/new-note?url=%27+encodeURIComponent(location.href);%20})();"
          ICON="data:image/png;base64,iVBORw0K………5ErkJggg=="
        >
          LearnFit 노트
        </a>
      </View>
      <br />
      <LFText>
        🧐 유튜브를 보다가 북마크를 누르면 바로 런핏 노트 작성으로 이동합니다.
      </LFText>
    </View>
  );
};

export default BookmarkletScreen;

const styles = StyleSheet.create({});
