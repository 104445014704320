/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type InstructionCardFragment = {
    readonly id: string;
    readonly objectId: string;
    readonly description: string | null;
    readonly optional: boolean | null;
    readonly sources: ReadonlyArray<({
        readonly __typename: "ContentItem";
        readonly id: string;
        readonly objectId: string;
        readonly title: string | null;
        readonly published: boolean | null;
        readonly content: {
            readonly __typename: string;
            readonly id: string;
            readonly objectId: string;
            readonly type: string | null;
            readonly link: string | null;
            readonly " $fragmentRefs": FragmentRefs<"ContentPreviewFragment">;
        } | null;
        readonly author: {
            readonly objectId: string;
            readonly name: string | null;
        } | null;
        readonly tags: ReadonlyArray<{
            readonly value?: unknown;
        } | null> | null;
        readonly " $fragmentRefs": FragmentRefs<"ContentItemAuthorSummaryFragment">;
    } | {
        readonly __typename: "Path";
        readonly id: string;
        readonly objectId: string;
        readonly title: string | null;
        readonly description: string | null;
        readonly published: boolean | null;
        readonly author: {
            readonly objectId: string;
            readonly name: string | null;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"PathPreviewFragment">;
    } | {
        readonly __typename: "Quiz";
        readonly id: string;
        readonly objectId: string;
        readonly " $fragmentRefs": FragmentRefs<"QuizFragment">;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }) | null> | null;
    readonly " $refType": "InstructionCardFragment";
};
export type InstructionCardFragment$data = InstructionCardFragment;
export type InstructionCardFragment$key = {
    readonly " $data"?: InstructionCardFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"InstructionCardFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InstructionCardFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optional",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "sources",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Content",
              "kind": "LinkedField",
              "name": "content",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "link",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ContentPreviewFragment"
                }
              ],
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "tags",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "value",
                      "storageKey": null
                    }
                  ],
                  "type": "Element",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ContentItemAuthorSummaryFragment"
            }
          ],
          "type": "ContentItem",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v4/*: any*/),
            (v2/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PathPreviewFragment"
            }
          ],
          "type": "Path",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuizFragment"
            }
          ],
          "type": "Quiz",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InstructionCard",
  "abstractKey": null
};
})();
(node as any).hash = '13b7da5f2c7e3d8e5b8c9ff4c8a4a769';
export default node;
