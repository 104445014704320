import { StyleSheet, View, Image, ViewStyle, TextProps } from 'react-native';
import React from 'react';
import { Flex } from '@ant-design/react-native';
import _ from 'lodash';
import LFLink, { LFLinkTo } from '../../components/LFLink';
import LFText from '../../components/typo/LFText';
import { colors } from '../../constants/styleGuide';
import LFWhiteSpace from '../../components/LFWhiteSpace';

const HomeSectionTitle: React.FC<{
  accessibilityRole?: TextProps['accessibilityRole'];
  accessibilityLevel?: TextProps['accessibilityLevel'];
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  to?: LFLinkTo;
  arrow?: boolean;
  count?: number | string;
  style?: ViewStyle;
  testID?: string;
  right?: React.ReactNode;
}> = ({
  accessibilityRole,
  accessibilityLevel,
  title,
  subTitle,
  to,
  arrow,
  count,
  style,
  testID,
  right,
}) => {
  return (
    <View testID={testID} style={style}>
      <Flex justify="start" align="center">
        <LFLink to={to} disabled={!to}>
          <LFText
            aria-level={accessibilityLevel}
            accessibilityRole={accessibilityRole}
            numberOfLines={1}
            style={styles.title}
          >
            {title}
          </LFText>
          {subTitle && (
            <>
              <LFWhiteSpace size="sm" direction="column" />
              <LFText style={styles.subTitle}>{subTitle}</LFText>
            </>
          )}
        </LFLink>
        <Flex
          direction="row"
          style={{
            marginLeft: 'auto',
            alignItems: 'center',
          }}
        >
          {!_.isNative(count) ? (
            <LFText style={{ color: colors.TEXT_60, fontSize: 14 }}>
              {count}
            </LFText>
          ) : null}
          {arrow ? (
            <Image
              accessibilityLabel="관련 페이지로 이동하기"
              source={require('../../assets/images/sectionTitleArrow24@3x.png')}
              style={styles.arrow}
            />
          ) : null}
          {right ? right : null}
        </Flex>
      </Flex>
    </View>
  );
};

export default HomeSectionTitle;

const styles = StyleSheet.create({
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 26.4,
    height: 26,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.SECONDARY_BLACK,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: colors.TEXT_60,
  },
  arrow: { width: 24, height: 24 },
});
